import useHasExperiment from '../useHasExperiment';

import { useIsOnParticipateAllowlist } from './useIsOnParticipateAllowlist';

export function useIsLegacyAPITokenManagementEnabled() {
  const isParticipateAllowlisted = useIsOnParticipateAllowlist();
  const isLegacyAPIManagementEnabled = useHasExperiment(
    'nov_2023_legacy_api_management',
    'treatment',
  );
  return isParticipateAllowlisted || isLegacyAPIManagementEnabled;
}
