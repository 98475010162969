import { type MouseEvent, useState } from 'react';
import { Button } from '@cbhq/cds-web/buttons';
import { TextInput } from '@cbhq/cds-web/controls';
import { VStack } from '@cbhq/cds-web/layout';
import { Modal, ModalBody, ModalFooter, ModalProps } from '@cbhq/cds-web/overlays';
import { TextBody, TextLabel2 } from '@cbhq/cds-web/typography';

import { useTokens } from ':cloud/contexts/LegacyTokensContext';
import { IAPIToken } from ':cloud/types/ts_types';
import { CloudModalHeader } from ':cloud/widgets/sharedcomponents';

interface RevokeTokenDialogProps
  extends Omit<ModalProps, 'children' | 'className' | 'onRequestClose'> {
  token: IAPIToken;
  closeFn: () => void;
}

export function RevokeTokenDialog({ token, closeFn, ...props }: RevokeTokenDialogProps) {
  const { revokeAPIToken, loadAllAPITokens, setTokenNotifier } = useTokens();

  const [invalidCodeError, setInvalidCodeError] = useState<boolean>(false);
  const [tokenNameInput, setTokenNameInput] = useState<string>('');

  const handleSubmit = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();

    if (await revokeAPIToken(token)) {
      await loadAllAPITokens();
      setTokenNotifier({
        type: 'success',
        variant: 'positive',
        message: 'API Access Token revoked.',
      });
      closeFn();
    } else {
      setInvalidCodeError(true);
    }
  };

  return (
    <Modal
      {...props}
      onRequestClose={(): void => {
        closeFn();
      }}
      testID="revoke-token-modal"
      hideDividers
    >
      <CloudModalHeader title="Revoke API access token" />
      <ModalBody>
        <VStack gap={2}>
          <TextBody as="p">Enter the token name to revoke access to {token.name}.</TextBody>
          <form>
            <TextInput
              label="Token Name"
              testID="revoke-token-dialog-token-input"
              value={tokenNameInput}
              variant={invalidCodeError ? 'negative' : 'foregroundMuted'}
              helperText={invalidCodeError ? 'Incorrect name' : ''}
              onChange={(e: any): void => {
                if (e.target.value !== token.name) {
                  setInvalidCodeError(true);
                } else {
                  setInvalidCodeError(false);
                }
                setTokenNameInput(e.target.value);
              }}
            />
          </form>
          <TextLabel2 as="p" color="negative" spacingBottom={1}>
            Warning: This token will be permanently and irreversibly invalidated, and will stop
            working shortly after it is revoked.
          </TextLabel2>
        </VStack>
      </ModalBody>
      <ModalFooter
        primaryAction={
          <Button
            type="submit"
            variant="negative"
            testID="revoke-token-button"
            onPress={handleSubmit}
            disabled={tokenNameInput !== token.name}
            block
          >
            Revoke access
          </Button>
        }
      />
    </Modal>
  );
}
