import { ReactElement, useEffect, useState } from 'react';
import { VStack } from '@cbhq/cds-web/layout';

import Loading from ':cloud/assets/Protocols/Logos/loading.svg';
import { ProtocolKey } from ':cloud/types/ts_types';
import { formatProtocolKey, getIconAlt } from ':cloud/utils/assets';

interface ProtocolIconProps {
  protocolKey: ProtocolKey;
  size?: number;
  className?: string;
}

export function ProtocolIcon({
  protocolKey,
  size = 32,
  className,
}: ProtocolIconProps): ReactElement {
  const [logoSrc, setLogoSrc] = useState({ default: Loading });

  useEffect(() => {
    const formattedProtocolKey = formatProtocolKey(protocolKey);
    import(`:cloud/assets/Protocols/Logos/${formattedProtocolKey}.svg`)
      .then((module) => {
        setLogoSrc(module);
      })
      .catch(() => {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.error(`Logo for ${formattedProtocolKey} not found`);
        }
      });
  }, [protocolKey]);

  return (
    <VStack alignItems="center" justifyContent="center">
      <img
        src={logoSrc.default}
        width={size}
        height={size}
        alt={`${getIconAlt(protocolKey)} logo`}
        className={className}
      />
    </VStack>
  );
}
