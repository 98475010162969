import { useMemo } from 'react';
import { Icon } from '@cbhq/cds-web/icons';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { Tooltip } from '@cbhq/cds-web/overlays';
import { Tag } from '@cbhq/cds-web/tag/Tag';
import { TextBody, TextHeadline, TextTitle1 } from '@cbhq/cds-web/typography';

import { useAccountCreditsBalances } from ':cloud/hooks/useAccountCreditsBalances';
import { currencyFormatter } from ':cloud/utils/formatters/currency';
import { CLOUD_DISCORD } from ':cloud/utils/links';

import { RIGHT_RAIL_MIN_WIDTH } from './constants';
import { PricingProps } from './types';

const DEFAULT_PRICING_DESCRIPTION = 'MONTHLY ACTIVE WALLETS';

type PricingLandingTemplateProps = {
  specs: PricingProps[];
  productTag?: 'Preview' | 'Enterprise';
  pricingDescription?: string;
  displayRemainingCredits?: boolean;
};

export function PricingLandingTemplate({
  specs,
  productTag,
  pricingDescription = DEFAULT_PRICING_DESCRIPTION,
  displayRemainingCredits = false,
}: PricingLandingTemplateProps) {
  const { accountCreditsRemaining, additionalCredits } = useAccountCreditsBalances();

  const creditsRemaining = useMemo(() => {
    let totalCredits = accountCreditsRemaining;

    if (additionalCredits?.additional_paymaster) {
      totalCredits += additionalCredits.additional_paymaster;
    }
    return totalCredits !== 0 ? currencyFormatter(totalCredits) : '-';
  }, [accountCreditsRemaining, additionalCredits]);

  return (
    <VStack
      height="min-content"
      minWidth={RIGHT_RAIL_MIN_WIDTH}
      spacingHorizontal={7}
      spacingVertical={5}
      gap={5}
      borderColor="line"
      borderRadius="roundedLarge"
    >
      <HStack justifyContent="space-between">
        <TextTitle1 as="h2">Pricing</TextTitle1>
        {productTag === 'Preview' && <Tag colorScheme="green">Preview</Tag>}
        {productTag === 'Enterprise' && <Tag colorScheme="gray">Enterprise</Tag>}
      </HStack>
      <TextBody as="p" color="foregroundMuted">
        {pricingDescription}
      </TextBody>
      {specs.map(({ billingUnit, price, discount, tooltipContent }) => {
        return (
          <HStack key={billingUnit} justifyContent="space-between">
            <TextHeadline as="h1">{billingUnit}</TextHeadline>
            {/* Conditionally rendering price, discount, and the "Get in touch" link */}
            <HStack alignItems="center" gap={tooltipContent ? 1 : 0}>
              <TextBody as="p">
                {price !== 'Free' && price !== 'Get in touch' ? (
                  <>
                    <TextBody as={discount ? 's' : 'span'}>{price}</TextBody> {discount}
                  </>
                ) : price === 'Get in touch' ? (
                  <a
                    href={CLOUD_DISCORD}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: '#0000EE' }}
                  >
                    {price}
                  </a>
                ) : (
                  price
                )}
              </TextBody>
              {tooltipContent && (
                <Tooltip content={tooltipContent}>
                  <Icon name="info" size="s" color="foreground" />
                </Tooltip>
              )}
            </HStack>
          </HStack>
        );
      })}
      {displayRemainingCredits && (
        <HStack borderedTop spacingTop={5} justifyContent="space-between">
          <TextHeadline as="h1">Credits remaining</TextHeadline>
          <TextBody as="p">{creditsRemaining}</TextBody>
        </HStack>
      )}
    </VStack>
  );
}
