import { css } from '@linaria/core';
import _every from 'lodash/every';
import _first from 'lodash/first';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _keys from 'lodash/keys';
import type {
  GridColumn as GridColumnProps,
  ResponsiveProps,
  ResponsivePropsDevices,
} from '@cbhq/cds-web';
import { Box, Grid, GridColumn, VStack } from '@cbhq/cds-web/layout';
import { spacing } from '@cbhq/cds-web/tokens';
import { TextBody, TextTitle1 } from '@cbhq/cds-web/typography';

import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { Eth2Pod, ISectionSchema, IWidgetSchema } from ':cloud/types/ts_types';
import { excludeWidget } from ':cloud/widgets/charts/ChartUtils';
import { renderer } from ':cloud/widgets/clusters/schemaWidget/renderer';
import { Layout } from ':cloud/widgets/layout';
import { TextLabelGray } from ':cloud/widgets/sharedcomponents';
import { CloudErrorBoundary } from ':cloud/widgets/sharedcomponents/CloudErrorBoundary';

import { ProviderRegionHeadline } from './ProviderRegionHeadline';
import { GenerateMetricsSwitcherProps } from './types';

const noMarginStyle = css`
  margin-bottom: 0;
`;

const titleTagStyle = css`
  line-height: 1.67;
  border: 1px solid var(--line-heavy);
  padding: ${spacing[2]};
`;

const topInfoWrapperResponsiveConfig: ResponsiveProps = {
  phone: {
    spacingTop: 5,
    spacingBottom: 3,
  },
  tablet: {
    spacing: 5,
  },
  desktop: {
    spacing: 5,
  },
};

const headerResponsiveConfig: ResponsiveProps = {
  phone: {
    spacingBottom: 7,
  },
  tablet: {
    spacingBottom: 5,
  },
  desktop: {
    spacingBottom: 5,
  },
};

function MetricsGridErrorFallback(): React.ReactElement {
  return (
    <Box bordered borderRadius="roundedSmall" role="alert">
      <TextBody as="div">
        Something went wrong. Try refreshing your browser to view the charts.
      </TextBody>
    </Box>
  );
}

const MUItoCDSResponsiveMap = {
  phone: 'options.xsGridSpacing',
  tablet: 'options.mdGridSpacing',
  desktop: 'options.lgGridSpacing',
};

type WidgetResponsiveColSpanProps = Record<ResponsivePropsDevices, GridColumnProps>;

// Workaround until CDS adds colSpan to GridColumn's responsiveConfig (currently a bug)
function getWidgetResponsiveColSpan(widget: IWidgetSchema): WidgetResponsiveColSpanProps {
  const getValue = (device: string): number | undefined =>
    _get(widget, MUItoCDSResponsiveMap[device]) as GridColumnProps | undefined;
  const responsiveValues = Object.keys(MUItoCDSResponsiveMap).reduce(
    (acc, key, i, arr) => {
      const value = getValue(key);

      // if there's no value, set it to the next closest value
      if (!value) {
        acc[key] = getValue(arr[i - 1]) || getValue(arr[i + 1]) || 12;
      } else {
        acc[key] = value;
      }
      return acc;
    },
    { phone: 12, tablet: 12, desktop: 12 } as WidgetResponsiveColSpanProps,
  );
  return responsiveValues;
}

type DynamicGridProps = Omit<GenerateMetricsProps, 'widgetSchema' | 'artifacts' | 'setTab'> & {
  section: ISectionSchema;
};

function DynamicGrid({ metricsData, section, configuration }: DynamicGridProps) {
  const { isPhone, isTablet } = useSimpleBreakpoints();
  const clientKeySource = section?.options?.clientKeySource;

  if (!metricsData || _isEmpty(metricsData) || !clientKeySource) {
    return null;
  }

  const eth2ClientMetrics = metricsData?.[clientKeySource]?.map(
    (b) => b[_first(_keys(b)) as string] as Eth2Pod,
  ) as Eth2Pod[];

  return (
    <>
      {eth2ClientMetrics.map((pod: Eth2Pod): JSX.Element => {
        return (
          <Box display="block" key={pod.region}>
            {pod && <ProviderRegionHeadline pod={pod} title={section.options.regionTitle} />}
            <VStack bordered={section.options.isWrapper} borderRadius="roundedSmall" spacing={3}>
              <Grid columns={12} gap={section.options.isWrapper ? 4 : 3} alignItems="center">
                {section.widgets.map((widget: IWidgetSchema, idx: number) => {
                  return (
                    <GridColumn
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${widget.widget}-${idx}`}
                      colSpan={
                        getWidgetResponsiveColSpan(widget)[
                          isPhone ? 'phone' : isTablet ? 'tablet' : 'desktop'
                        ]
                      }
                    >
                      <CloudErrorBoundary
                        name="MetricsGrid"
                        fallback={<MetricsGridErrorFallback />}
                      >
                        {renderer(
                          widget,
                          configuration,
                          section.options.isDynamic || false,
                          undefined,
                          metricsData,
                          pod,
                        )}
                      </CloudErrorBoundary>
                    </GridColumn>
                  );
                })}
              </Grid>
            </VStack>
          </Box>
        );
      })}
    </>
  );
}

type LayoutComponentProps = {
  layout?: string;
  noMargin?: boolean;
  children: any;
};

function LayoutComponent({ layout, noMargin, children }: LayoutComponentProps) {
  switch (layout) {
    case 'MainContainer':
      return (
        <Layout.MainContainer className={noMargin ? noMarginStyle : ''}>
          {children}
        </Layout.MainContainer>
      );
    case 'TopInfo':
      return (
        <Layout.MainContainer responsiveConfig={topInfoWrapperResponsiveConfig}>
          {children}
        </Layout.MainContainer>
      );
    case 'ContentWrapper':
    default:
      return (
        <Layout.ContentWrapper className={noMargin ? noMarginStyle : ''}>
          {children}
        </Layout.ContentWrapper>
      );
  }
}

type GenerateMetricsProps = Pick<
  GenerateMetricsSwitcherProps,
  'metricsData' | 'artifacts' | 'widgetSchema' | 'configuration'
>;

function GenerateMetrics({
  metricsData,
  widgetSchema,
  configuration,
  artifacts,
}: GenerateMetricsProps) {
  const { isPhone, isTablet } = useSimpleBreakpoints();

  const headerRenderer = (section: ISectionSchema): React.ReactElement | null => {
    switch (section.options.headerType) {
      case 'withTag':
        return (
          <Grid justifyContent="space-between" alignItems="baseline">
            <TextTitle1 as="h2">{section.name}</TextTitle1>
            <TextLabelGray as="span" className={titleTagStyle}>
              {section.options.tag}
            </TextLabelGray>
          </Grid>
        );
      default:
        return section.name ? (
          <Box display="block" responsiveConfig={headerResponsiveConfig}>
            <TextTitle1 as="h2" className={section.name}>
              {section.name}
              {section.options.subTitle && (
                <TextLabelGray as="span" spacingHorizontal={1}>
                  {section.options.subTitle}
                </TextLabelGray>
              )}
            </TextTitle1>
          </Box>
        ) : null;
    }
  };

  function isSectionTitleVisible(section: ISectionSchema): boolean {
    const allWidgetsHasConditions = _every(
      section.widgets,
      (w: IWidgetSchema) => !_isEmpty(w.conditional),
    );
    if (!allWidgetsHasConditions) {
      return true;
    }
    const allWidgetsExcluded = _every(section.widgets, (w: IWidgetSchema) =>
      excludeWidget(w.conditional, configuration, artifacts, metricsData),
    );
    return !allWidgetsExcluded;
  }

  return widgetSchema?.sections?.map(
    (section: ISectionSchema, idx: number) =>
      (
        <LayoutComponent
          layout={section.options.layout}
          noMargin={section.options.noMargin}
          // eslint-disable-next-line react/no-array-index-key
          key={`${section.name}-${idx}`}
        >
          {section.options.hideIfEmpty
            ? isSectionTitleVisible(section) && headerRenderer(section)
            : headerRenderer(section)}
          <CloudErrorBoundary name="DynamicGrid" fallback={<MetricsGridErrorFallback />}>
            {section.options.isDynamic ? (
              <DynamicGrid
                metricsData={metricsData}
                section={section}
                configuration={configuration}
              />
            ) : (
              <Grid
                bordered={section.options.isWrapper}
                borderRadius="roundedSmall"
                gap={section.options.isWrapper ? 0 : 3}
                columns={12}
              >
                {section.widgets.map((widget: IWidgetSchema, widgetIdx: number) => (
                  <GridColumn
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${widget.widget}-${widgetIdx}`}
                    colSpan={
                      getWidgetResponsiveColSpan(widget)[
                        isPhone ? 'phone' : isTablet ? 'tablet' : 'desktop'
                      ]
                    }
                    justifyContent="space-around"
                  >
                    <CloudErrorBoundary name="MetricsGrid" fallback={<MetricsGridErrorFallback />}>
                      {renderer(widget, configuration, false, artifacts, metricsData, undefined)}
                    </CloudErrorBoundary>
                  </GridColumn>
                ))}
              </Grid>
            )}
          </CloudErrorBoundary>
        </LayoutComponent>
      ) || null,
  );
}

/** being used in both TopMetrics and ClusterGenerateDetails to render dynamic charts */
export default GenerateMetrics;
