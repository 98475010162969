import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useToggler } from '@cbhq/cds-common';
import { UiIconName } from '@cbhq/cds-icons';
import { Button } from '@cbhq/cds-web/buttons';
import { Switch } from '@cbhq/cds-web/controls';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { palette } from '@cbhq/cds-web/tokens';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

import { useAppState } from ':cloud/contexts/AppStateContext';
import { EventName, logClick, useLogViewOnMount } from ':cloud/init/clientAnalytics/logging';
import { useCreateGasPolicy } from ':cloud/queries/Base/useCreateGasPolicy';
import {
  useUpdateAccountAbstractionStatus,
  useUpdateGasPolicy,
} from ':cloud/queries/Base/useUpdateGasPolicy';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { IPlatformToken, WhitelistedAddressAndMethod } from ':cloud/types/ts_types';
import { getKeyByValue, isValidGasAmount, isValidTxnAmount } from ':cloud/utils/paymaster';
import { useQueryParam } from ':cloud/utils/routes';
import { scrollToElement } from ':cloud/utils/scrollToElement';
import { PaymasterRequirementsSection } from ':cloud/widgets/base/PaymasterRequirementsSection';

import { LIMIT_CYCLE_MAP, maxGasErrorMessage, maxUserOpGasErrorMessage } from './constants';
import DisableAccountAbstractionWarningModal from './DisableAccountAbstractionWarningModal';
import PaymasterAllowlistModal from './PaymasterAllowlistModal';
import { PaymasterAllowlistSection } from './PaymasterAllowlistSection';
import { PaymasterCustomizationSection } from './PaymasterCustomizationSection';
import { PaymasterGlobalLimitSection } from './PaymasterGlobalLimitSection';
import { PaymasterPerUserLimitSection } from './PaymasterPerUserLimitSection';
import { PaymasterPerUserOpLimitSection } from './PaymasterPerUserOpLimitSection';

type PaymasterSectionWrapperProps = {
  title: string;
  description: string;
  iconName: UiIconName;
  children?: ReactNode;
  actionElement?: ReactNode;
  testID?: string;
};

function PaymasterSectionWrapper({
  title,
  description,
  iconName,
  children,
  actionElement,
  testID,
}: PaymasterSectionWrapperProps) {
  return (
    <VStack bordered borderRadius="rounded" spacing={4} testID={testID}>
      <HStack justifyContent="space-between" alignItems="center">
        <HStack gap={2} alignItems="center">
          <Box
            dangerouslySetBackground={palette.foreground}
            height={24}
            width={24}
            borderRadius="roundedFull"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Icon name={iconName} size="s" color="primaryForeground" />
          </Box>
          <VStack>
            <TextHeadline as="p">{title}</TextHeadline>
            <TextBody as="p" color="foregroundMuted">
              {description}
            </TextBody>
          </VStack>
        </HStack>
        {actionElement}
      </HStack>
      {children}
    </VStack>
  );
}

type PaymasterV2Props = {
  token: IPlatformToken;
  isTestnet: boolean;
};

export function PaymasterV2({ token, isTestnet }: PaymasterV2Props) {
  const { activeOrg } = useGetUser();
  const { selectedProject } = useAppState();
  const gasPolicy = token?.policies?.[0];
  const toast = useToast();

  const sectionQueryParam = useQueryParam('section');

  const [warningModalVisible, { toggleOn: showWarningModal, toggleOff: hideWarningModal }] =
    useToggler();
  const [allowlistModalVisible, { toggleOn: showAllowlistModal, toggleOff: hideAllowlistModal }] =
    useToggler();

  const [maxGlobalGasUsd, setMaxGlobalGasUsd] = useState(
    JSON.stringify(gasPolicy?.maxGlobalGasUsd) || '0',
  );
  const [maxGasPerAddrUsd, setMaxGasPerAddrUsd] = useState(
    JSON.stringify(gasPolicy?.maxGasPerAddrUsd) || '0',
  );
  const [maxGasPerUserOpUsd, setMaxGasPerUserOpUsd] = useState(
    JSON.stringify(gasPolicy?.maxGasPerUserOperationUsd) || '0',
  );
  const [maxTxnPerAddr, setMaxTxnPerAddr] = useState(gasPolicy?.maxTxnPerAddr || '0');

  const initialWhitelistedAddressesAndMethods = useMemo(() => {
    return gasPolicy?.contractAllowlist || [];
  }, [gasPolicy?.contractAllowlist]);

  const [whitelistedAddressAndMethods, setWhitelistedAddressAndMethods] = useState<
    WhitelistedAddressAndMethod[]
  >(initialWhitelistedAddressesAndMethods);

  const initialPerAddressLimitCycle = useMemo(() => {
    if (gasPolicy?.intervalType && getKeyByValue(LIMIT_CYCLE_MAP, gasPolicy?.intervalType)) {
      return getKeyByValue(LIMIT_CYCLE_MAP, gasPolicy?.intervalType);
    }
    return Object.keys(LIMIT_CYCLE_MAP)[0];
  }, [gasPolicy?.intervalType]);

  const [perAddressLimitCycle, setPerAddressLimitCycle] = useState(initialPerAddressLimitCycle);
  const [numUnsavedAllowlistChanges, setNumUnsavedAllowlistChanges] = useState<number>(0);

  const createGasPolicy = useCreateGasPolicy({
    organizationId: activeOrg?.organizationId || '',
    projectId: selectedProject?.id || '',
    tokenId: token?.id,
    onError: (errorMessage) => {
      toast.show(errorMessage, { variant: 'negative' });
    },
  });

  const updateGasPolicy = useUpdateGasPolicy({
    organizationId: activeOrg?.organizationId || '',
    projectId: selectedProject?.id || '',
    previousPolicy: gasPolicy,
  });

  const updateAccountAbstractionSuccessMsg = useMemo(() => {
    if (gasPolicy?.enabled) {
      return 'Paymaster & Bundler disabled';
    }
    return 'Paymaster & Bundler enabled';
  }, [gasPolicy]);

  const updateAccountAbstractionStatus = useUpdateAccountAbstractionStatus({
    organizationId: activeOrg?.organizationId || '',
    projectId: selectedProject?.id || '',
    successMsg: updateAccountAbstractionSuccessMsg,
  });

  const handleAccountAbstractionStatusChange = useCallback(() => {
    if (!gasPolicy) {
      createGasPolicy.mutate();
      return;
    }
    const policy = { ...gasPolicy, enabled: !gasPolicy?.enabled };
    if (!policy.enabled) {
      showWarningModal();
      return;
    }
    updateAccountAbstractionStatus.mutate({ policy });
  }, [createGasPolicy, gasPolicy, showWarningModal, updateAccountAbstractionStatus]);

  const handleDisableAccountAbstraction = useCallback(() => {
    if (!gasPolicy) {
      throw new Error('Unable to disable Paymaster & Bundler. Missing required information.');
    }
    const policy = { ...gasPolicy, enabled: !gasPolicy?.enabled };
    updateAccountAbstractionStatus.mutate({ policy });
  }, [gasPolicy, updateAccountAbstractionStatus]);

  const handleTogglePerUserMax = useCallback(() => {
    if (!gasPolicy) {
      return;
    }

    const newGasPolicy = {
      ...gasPolicy,
      perAddrMaxEnabled: !gasPolicy?.perAddrMaxEnabled,
    };
    updateGasPolicy.mutate({ policy: newGasPolicy });
  }, [gasPolicy, updateGasPolicy]);

  const handleTogglePerUserOpMax = useCallback(() => {
    if (!gasPolicy) {
      return;
    }
    const newGasPolicy = {
      ...gasPolicy,
      maxGasPerUserOperationEnabled: !gasPolicy?.maxGasPerUserOperationEnabled,
    };
    updateGasPolicy.mutate({ policy: newGasPolicy });
  }, [gasPolicy, updateGasPolicy]);

  const handleMaxGlobalGasUsdChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (isValidGasAmount(event.target.value)) {
      setMaxGlobalGasUsd(event.target.value);
    }
  }, []);

  const handleMaxGasPerAddressChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (isValidGasAmount(event.target.value)) {
      setMaxGasPerAddrUsd(event.target.value);
    }
  }, []);

  const handleMaxGasPerUserOpChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (isValidGasAmount(event.target.value)) {
      setMaxGasPerUserOpUsd(event.target.value);
    }
  }, []);

  const handleMaxTxnPerAddressChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (isValidTxnAmount(event.target.value)) {
      setMaxTxnPerAddr(event.target.value);
    }
  }, []);

  const handleAllowlistAddPress = useCallback((whitelist: WhitelistedAddressAndMethod[]) => {
    logClick(EventName.paymaster_add_contract_allowlist_click);
    setWhitelistedAddressAndMethods(whitelist);
    setNumUnsavedAllowlistChanges((prev) => prev + 1);
  }, []);

  const userMaxGasError = useMemo(() => {
    if (gasPolicy?.maxGlobalGasUsd && gasPolicy?.maxGlobalGasUsd < parseFloat(maxGasPerAddrUsd)) {
      return maxGasErrorMessage;
    }
    return '';
  }, [gasPolicy?.maxGlobalGasUsd, maxGasPerAddrUsd]);

  const userOpMaxGasError = useMemo(() => {
    if (gasPolicy?.maxGlobalGasUsd && gasPolicy?.maxGlobalGasUsd < parseFloat(maxGasPerUserOpUsd)) {
      return maxUserOpGasErrorMessage;
    }
    return '';
  }, [gasPolicy?.maxGlobalGasUsd, maxGasPerUserOpUsd]);

  const globalMaxGasError = useMemo(() => {
    if (
      gasPolicy?.perAddrMaxEnabled &&
      gasPolicy?.maxGasPerAddrUsd &&
      gasPolicy?.maxGasPerAddrUsd > parseFloat(maxGlobalGasUsd)
    ) {
      return maxGasErrorMessage;
    }
    if (
      gasPolicy?.maxGasPerUserOperationEnabled &&
      gasPolicy?.maxGasPerUserOperationUsd &&
      gasPolicy?.maxGasPerUserOperationUsd > parseFloat(maxGlobalGasUsd)
    ) {
      return maxUserOpGasErrorMessage;
    }
    return '';
  }, [
    gasPolicy?.maxGasPerAddrUsd,
    gasPolicy?.maxGasPerUserOperationEnabled,
    gasPolicy?.maxGasPerUserOperationUsd,
    gasPolicy?.perAddrMaxEnabled,
    maxGlobalGasUsd,
  ]);

  useLogViewOnMount(EventName.paymaster_config_page_view);

  useEffect(() => {
    if (sectionQueryParam === 'allowlist') {
      showAllowlistModal();
    } else if (sectionQueryParam === 'gasLimit') {
      scrollToElement('paymaster-limit-section');
    } else if (sectionQueryParam === 'sponsor') {
      scrollToElement('paymaster-sponsor-section');
    }
  }, [sectionQueryParam, showAllowlistModal]);

  return (
    <VStack spacingTop={4} gap={6}>
      {warningModalVisible && gasPolicy && (
        <DisableAccountAbstractionWarningModal
          onRequestClose={hideWarningModal}
          onConfirm={handleDisableAccountAbstraction}
        />
      )}
      {allowlistModalVisible && gasPolicy && (
        <PaymasterAllowlistModal
          onRequestClose={hideAllowlistModal}
          onAdd={handleAllowlistAddPress}
          whitelistedAddressAndMethods={whitelistedAddressAndMethods}
        />
      )}
      <HStack justifyContent="space-between" alignItems="center" gap={2} width="100%">
        <TextHeadline as="h1">Enable Paymaster</TextHeadline>
        <Switch
          disabled={createGasPolicy.isLoading || updateAccountAbstractionStatus.isLoading}
          onChange={handleAccountAbstractionStatusChange}
          checked={!!gasPolicy?.enabled}
        />
      </HStack>

      <PaymasterSectionWrapper
        testID="paymaster-allowlist-section"
        iconName="smartContract"
        title="Contract allowlist"
        description="Restrict gas sponsorship to a list of smart contracts. Optionally add specific functions of the contract to allowlist."
        actionElement={<Button onPress={showAllowlistModal}>Add</Button>}
      >
        {gasPolicy && (
          <PaymasterAllowlistSection
            whitelistedAddressAndMethods={whitelistedAddressAndMethods}
            setWhitelistedAddressAndMethods={setWhitelistedAddressAndMethods}
            numChangesUnsaved={numUnsavedAllowlistChanges}
            setNumChangesUnsaved={setNumUnsavedAllowlistChanges}
            gasPolicy={gasPolicy}
          />
        )}
      </PaymasterSectionWrapper>

      <PaymasterSectionWrapper
        iconName="globe"
        title="Global limit"
        description="Set the maximum amount you are willing to sponsor globally."
        testID="paymaster-limit-section"
      >
        {gasPolicy && (
          <PaymasterGlobalLimitSection
            maxGlobalGasUsd={maxGlobalGasUsd}
            onMaxGlobalGasChange={handleMaxGlobalGasUsdChange}
            maxGasError={globalMaxGasError}
            gasPolicy={gasPolicy}
            isTestnet={isTestnet}
            setMaxGasPerAddrUsd={setMaxGasPerAddrUsd}
            setMaxGasPerUserOpUsd={setMaxGasPerUserOpUsd}
          />
        )}
      </PaymasterSectionWrapper>
      <PaymasterSectionWrapper
        iconName="profile"
        title="Per user limit"
        description="Set the maximum amount you are willing to sponsor per user."
        actionElement={
          <Switch
            onChange={handleTogglePerUserMax}
            checked={gasPolicy?.perAddrMaxEnabled}
            disabled={updateGasPolicy?.isLoading}
          />
        }
      >
        {gasPolicy && gasPolicy?.perAddrMaxEnabled && (
          <PaymasterPerUserLimitSection
            maxGasPerAddrUsd={maxGasPerAddrUsd}
            maxTxnPerAddr={maxTxnPerAddr}
            onMaxGasPerAddressChange={handleMaxGasPerAddressChange}
            onMaxTxnPerAddressChange={handleMaxTxnPerAddressChange}
            perAddressLimitCycle={perAddressLimitCycle}
            onPerAddressLimitCycleChange={setPerAddressLimitCycle}
            maxGasError={userMaxGasError}
            gasPolicy={gasPolicy}
          />
        )}
      </PaymasterSectionWrapper>
      <PaymasterSectionWrapper
        iconName="transactions"
        title="Per User Operation limit"
        description="Set the maximum amount you are willing to sponsor per User Operation."
        actionElement={
          <Switch
            onChange={handleTogglePerUserOpMax}
            checked={gasPolicy?.maxGasPerUserOperationEnabled}
            disabled={updateGasPolicy?.isLoading}
          />
        }
      >
        {gasPolicy && !!gasPolicy?.maxGasPerUserOperationEnabled && (
          <PaymasterPerUserOpLimitSection
            maxGasPerUserOpUsd={maxGasPerUserOpUsd}
            onMaxGasPerUserOpChange={handleMaxGasPerUserOpChange}
            maxGasError={userOpMaxGasError}
            gasPolicy={gasPolicy}
          />
        )}
      </PaymasterSectionWrapper>
      <PaymasterSectionWrapper
        iconName="profile"
        title="Customization"
        description="Set what your users see in their transaction screen."
        testID="paymaster-sponsor-section"
      >
        {gasPolicy && <PaymasterCustomizationSection gasPolicy={gasPolicy} />}
      </PaymasterSectionWrapper>
      <PaymasterSectionWrapper
        iconName="smartContract"
        title="Requirements"
        description="Restrict your sponsorship on wallets with at least one of the selected onchain IDs and/or attestations."
      >
        {gasPolicy && <PaymasterRequirementsSection gasPolicy={gasPolicy} />}
      </PaymasterSectionWrapper>
    </VStack>
  );
}
