import { ColorScheme } from '@cbhq/cds-common/types';
import { TableCell } from '@cbhq/cds-web/tables';
import { Tag } from '@cbhq/cds-web/tag/Tag';

import { OrganizationUser } from ':cloud/types/ts_types';

type StatusCellProps = { manageUsers?: boolean; user: OrganizationUser };

function getColorScheme(user: OrganizationUser): ColorScheme {
  switch (user.status) {
    case 'active':
      return 'green';
    case 'invited':
      return 'yellow';
    default:
      return 'gray';
  }
}

function getTagContent(user: OrganizationUser): string | false {
  switch (user.status) {
    case 'active':
      return 'Active';
    case 'invited':
      return 'Pending Invitation';
    default:
      // empty tag
      return false;
  }
}

export function StatusCell({ manageUsers, user }: StatusCellProps) {
  return (
    <TableCell alignItems={manageUsers ? undefined : 'flex-end'}>
      <Tag colorScheme={getColorScheme(user)}>{getTagContent(user)}</Tag>
    </TableCell>
  );
}
