import { useCookie } from '@coinbase/cookie-manager';
import {
  ActionType,
  AnalyticsEventImportance,
  ComponentType,
  logEvent,
} from '@cbhq/client-analytics';
import { getIsLocalDev } from '@cbhq/session-manager-auth';

import config from ':cloud/config';
import { CloudThemeProvider } from ':cloud/utils/CloudThemeProvider';
import { AppRoute } from ':cloud/utils/routes';
import { LocalLogin } from ':cloud/widgets/sharedcomponents/LocalLogin';

const SIGNUP_PATH = '/signup';
// `/create-account` is the new path our marketing pages use for Signup
const CREATE_ACCOUNT_PATH = '/create-account';

/**
 * EXPECTED BEHAVIOR FOR A USER WITHOUT A VALID SESSION
 *
 * coinbase.com/cloud & cloud.coinbase.com redirect to the marketing logged out landing page, and are indexed by search engines.
 * portal.cloud.coinbase.com & ANY dev/staging urls (even those starting with `portal`) redirect to the login page. We maintain this
 *   inconsistency bc the Signup/Signin buttons in the dev/staging marketing pages point to prod which is even more confusing).
 *
 */
export function UnauthenticatedApp() {
  const [, setLoginStateCookie] = useCookie('login-state');

  // if anything but localhost
  if (!getIsLocalDev()) {
    if (window.location.pathname !== AppRoute.Home) {
      /*
        window.location.pathname does not include query params so
        you need to combine with window.location.search
      */
      const pathWithSearchParams = `${window.location.pathname}${window.location.search}`;
      setLoginStateCookie(pathWithSearchParams);
    }

    // this path directs to the new Unified Signup flow regardless of the user's experiment exposure
    if (window.location.pathname === SIGNUP_PATH) {
      window.location.href = `${config.apiBaseURL}/${SIGNUP_PATH}`;
      return null;
    }

    if (window.location.pathname === CREATE_ACCOUNT_PATH) {
      logEvent(
        'cloud_signup_redirect',
        {
          action: ActionType.measurement,
          componentType: ComponentType.page,
        },
        AnalyticsEventImportance.high,
      );
      window.location.href = `${config.apiBaseURL}/${SIGNUP_PATH}`;
      return null;
    }

    if (
      window.location.hostname.startsWith('portal') ||
      // see EXPECTED BEHAVIOR comment above
      config.isStaging() ||
      config.isDevelopment()
    ) {
      window.location.href = config.loginURL;
      return null;
    }

    window.location.href = config.loggedOutLandingPage;
    return null;
  }

  return (
    <CloudThemeProvider>
      <LocalLogin />
    </CloudThemeProvider>
  );
}
