import { useMemo } from 'react';
import { styled } from '@linaria/react';
import { Box } from '@cbhq/cds-web/layout';
import { palette } from '@cbhq/cds-web/tokens';

import { ClusterStatusColor } from ':cloud/utils/clusters';

const STATUS_ICON_SIZE = 8;
const STATUS_ICON_PADDING = STATUS_ICON_SIZE * 0.25;

const StyledStatusIcon = styled.div`
  width: ${STATUS_ICON_SIZE}px;
  height: ${STATUS_ICON_SIZE}px;
  border-radius: ${STATUS_ICON_SIZE}px;
  position: relative;
  top: ${STATUS_ICON_PADDING}px;
`;

const StyledPulseRing = styled.div`
  animation: pulseRing 1.75s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  width: ${STATUS_ICON_SIZE * 2}px;
  height: ${STATUS_ICON_SIZE * 2}px;
  border-radius: ${STATUS_ICON_SIZE}px;
  position: absolute;
  top: 0;
  left: -${STATUS_ICON_PADDING}px;

  @keyframes pulseRing {
    0% {
      transform: scale(0.33);
    }
    80%,
    100% {
      opacity: 0;
    }
  }
`;

const paddingStyle = { padding: '2px' };

type StatusIndicatorProps = { status: { color: ClusterStatusColor } };

export function StatusIndicator({ status }: StatusIndicatorProps) {
  const backgroundColorStyle = useMemo(
    () => ({ backgroundColor: palette[status.color] }),
    [status.color],
  );
  return (
    <Box display="inline-block" position="relative" style={paddingStyle}>
      {/* don't show pulse animation if status is failed */}
      {!['negative'].includes(status.color) && <StyledPulseRing style={backgroundColorStyle} />}
      <StyledStatusIcon style={backgroundColorStyle} />
    </Box>
  );
}
