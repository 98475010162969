import { useMemo } from 'react';
import { CellSpacing } from '@cbhq/cds-common';
import { ListCell } from '@cbhq/cds-web/cells';
import { NavigationIcon } from '@cbhq/cds-web/icons';
import { Pictogram, SpotIcon } from '@cbhq/cds-web/illustrations';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { Pressable } from '@cbhq/cds-web/system';
import { TextHeadline } from '@cbhq/cds-web/typography';

import {
  NavItemChild,
  NavItemChildWithPictogram,
  NavItemChildWithSpotIcon,
  ParentNavItem,
} from ':cloud/config/navigation';

import { Flyout } from '../sharedcomponents/Flyout/Flyout';

type SidebarFlyoutItemProps = Omit<NavItemChild, 'id'>;

// Type guards since there can be two different types of icons
function isNavItemChildWithPictogram(
  item: SidebarFlyoutItemProps,
): item is NavItemChildWithPictogram {
  return item.iconType === 'pictogram';
}
function isNavItemChildWithSpotIcon(
  item: SidebarFlyoutItemProps,
): item is NavItemChildWithSpotIcon {
  return item.iconType === 'spotIcon';
}

/** Nav item component within the flyout menu */
export function SidebarFlyoutItem(props: SidebarFlyoutItemProps) {
  const innerSpacing = useMemo((): CellSpacing => ({ spacingStart: 2, spacingVertical: 0 }), []);
  const outerSpacing = useMemo((): CellSpacing => ({ spacingHorizontal: 2 }), []);

  const media = useMemo(() => {
    if (isNavItemChildWithPictogram(props)) {
      return <Pictogram name={props.icon} dimension="48x48" scaleMultiplier={0.5} />;
    }
    if (isNavItemChildWithSpotIcon(props)) return <SpotIcon name={props.icon} dimension="24x24" />;
    return undefined;
  }, [props]);

  const handlePress = () => {
    props.onPress?.();
  };

  return (
    <Pressable
      background="primaryWash"
      transparentWhileInactive
      as="button"
      onPress={handlePress}
      width="100%"
      borderRadius="rounded"
    >
      <ListCell
        title={props.title}
        description={props.detail}
        media={media}
        multiline={!!props.detail}
        compact
        innerSpacing={innerSpacing}
        outerSpacing={outerSpacing}
      />
    </Pressable>
  );
}

type SidebarItemWithFlyoutProps = {
  parentNavItem: ParentNavItem;
  flyoutContent: (JSX.Element | null)[];
  parentActive?: boolean;
};
/**
 * SidebarItem variant that renders a sidebar item with a flyout menu on hover.
 *
 * @param parentNavItem - The parent navigation item data.
 * @param childNavItems - Nav items to display in the flyout menu.
 * @param parentActive - Whether the parent item is active.
 */
export function SidebarItemWithFlyoutMenu({
  parentNavItem,
  flyoutContent,
  parentActive,
}: SidebarItemWithFlyoutProps) {
  const { icon, title, onPress, collapsed } = parentNavItem;

  const handleParentPress = () => {
    onPress?.();
  };

  if (flyoutContent.length === 0) {
    return null;
  }

  return (
    <Flyout
      flyoutId={title}
      gap={1}
      placement="right-start"
      testID="sidebar-flyout-item"
      content={
        <VStack spacingVertical={1} width="100%">
          {flyoutContent}
        </VStack>
      }
    >
      <Pressable
        background="primaryWash"
        borderRadius="roundedFull"
        transparentWhileInactive={!parentActive}
        as="button"
        onPress={handleParentPress}
        width="100%"
      >
        <HStack
          key={`sidebar-item--${parentNavItem.title}`}
          testID={`sidebar-item--${parentNavItem.id}`}
          width="100%"
        >
          <HStack
            alignItems="center"
            gap={2}
            justifyContent="flex-start"
            spacing={2}
            testID="sidebar-item-primary"
          >
            <NavigationIcon active={parentActive} name={icon} />
            {!collapsed && (
              <TextHeadline color={parentActive ? 'primary' : 'foreground'} as="span">
                {title}
              </TextHeadline>
            )}
          </HStack>
        </HStack>
      </Pressable>
    </Flyout>
  );
}
