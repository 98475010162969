import { useContext, useEffect, useMemo } from 'react';

import { FeatureFlagContext } from ':cloud/contexts/FeatureFlagContext';
import { GenericProviderProps } from ':cloud/types/ts_types';
import { setFeatureFlagsInLocalStorage } from ':cloud/utils/localStorageFeatureFlags';
import { SessionContext } from ':cloud/utils/SessionProvider';

export default function FeatureFlagProvider({ children }: GenericProviderProps) {
  const { sessionResponse } = useContext(SessionContext);

  const featureFlagSet = useMemo(
    () => new Set(sessionResponse?.features ?? []),
    [sessionResponse?.features],
  );

  useEffect(() => {
    // To support using feature flags outside of React components, also store in localStorage
    setFeatureFlagsInLocalStorage(sessionResponse?.features);
  }, [sessionResponse?.features]);

  return (
    <FeatureFlagContext.Provider value={featureFlagSet}>{children}</FeatureFlagContext.Provider>
  );
}
