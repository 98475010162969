import { useQuery } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { ProductDetails, ProductTypeCOMS, Project } from ':cloud/types/ts_types';

type ProductDetailsResponse = {
  productDetails: ProductDetails;
};

async function fetchProductDetails(
  organizationId: string,
  projectId: string,
  productType: string,
): Promise<ProductDetails> {
  const path = `/organization-management/v1/organizations/${organizationId}/projects/${projectId}/products:findOrCreate`;
  const { data } = await APIClientBase.post<ProductDetailsResponse>(path, {
    productType,
  });
  return data?.productDetails;
}

export function useFindOrCreateProductDetails(
  project: Project,
  productType: ProductTypeCOMS,
): { isLoading: boolean; productDetails?: ProductDetails } {
  const { isLoading, data } = useQuery({
    queryKey: [QueryKey.productDetails, project.organizationId, project.id, productType],
    queryFn: async () => {
      return fetchProductDetails(project.organizationId, project.id, productType);
    },
    staleTime: Infinity,
    enabled: !!project && !!productType,
  });

  return {
    isLoading,
    productDetails: data,
  };
}
