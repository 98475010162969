import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { CoinbaseWebhook } from ':cloud/types/ts_types';

const errorMsg = 'An error occurred updating your Webhook. Please retry';

async function updateWebhook(
  orgId: string,
  projectId: string,
  webhookId: string,
  webhook: CoinbaseWebhook,
): Promise<CoinbaseWebhook | undefined> {
  const path = `webhook/v1/organizations/${orgId}/projects/${projectId}/webhooks/${webhookId}`;
  const body = {
    webhook: {
      ...webhook,
      name: `organizations/${orgId}/projects/${projectId}/webhooks/${webhookId}`,
    },
  };

  const response = await APIClientBase.put<CoinbaseWebhook>(path, body);

  return response.data;
}

type UpdateWebhookMutate = {
  orgId: string;
  projectId: string;
  webhookId: string;
  webhook: CoinbaseWebhook;
};

export function useUpdateWebhook(
  onSuccess?: (webhook: CoinbaseWebhook) => void,
  onCloseModal?: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ orgId, projectId, webhookId, webhook }: UpdateWebhookMutate) =>
      updateWebhook(orgId, projectId, webhookId, webhook),
    onSuccess: async (updatedWebhook) => {
      if (!updatedWebhook) return;
      queryClient.setQueryData([QueryKey.webhooks, updatedWebhook.uuid?.hex], updatedWebhook);
      await queryClient.invalidateQueries([QueryKey.webhooks]);
      onSuccess?.(updatedWebhook);
      onCloseModal?.();
    },
    meta: {
      errorMsg,
    },
  });
}
