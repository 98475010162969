import _map from 'lodash/map';

import { IMetricData, IMetricsRoot, ProtocolKey } from ':cloud/types/ts_types';
import { parseMetricValue } from ':cloud/utils/metrics';
import { BlockUnit } from ':cloud/widgets/charts/BlockUnit';

interface ClusterTabMetricsProps {
  data: IMetricData;
  metrics: IMetricsRoot;
  protocolKey: ProtocolKey;
}

export function ClusterTabMetrics({ data, metrics, protocolKey }: ClusterTabMetricsProps) {
  return (
    <>
      {_map(data, (_, key) => {
        const { value, unit, label } = parseMetricValue(key, metrics, protocolKey);
        return <BlockUnit label={label} value={value} unit={unit} alignLeft />;
      })}
    </>
  );
}
