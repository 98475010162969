import { useCallback, useEffect, useMemo, useState } from 'react';
import { useToggler } from '@cbhq/cds-common';
import { Switch } from '@cbhq/cds-web/controls/Switch';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { Pressable } from '@cbhq/cds-web/system';
import { palette } from '@cbhq/cds-web/tokens';
import { TextBody, TextCaption, TextHeadline } from '@cbhq/cds-web/typography';

import { useAppState } from ':cloud/contexts/AppStateContext';
import { useFindOrCreateToken } from ':cloud/hooks/useFindOrCreateToken';
import { useCreateGasPolicy } from ':cloud/queries/Base/useCreateGasPolicy';
import { useGetRemainingPaymasterBalance } from ':cloud/queries/Base/useGetRemainingPaymasterBalance';
import {
  useUpdateAccountAbstractionStatus,
  useUpdateGasPolicy,
} from ':cloud/queries/Base/useUpdateGasPolicy';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { GasPolicy } from ':cloud/types/ts_types';

import DisableAccountAbstractionWarningModal from './DisableAccountAbstractionWarningModal';
import { GasPolicyForm } from './GasPolicyForm';

export function PaymasterSection() {
  const [isGasPolicyFormOpen, setIsGasPolicyFormOpen] = useState(true);
  const { activeOrg } = useGetUser();
  const { selectedProject } = useAppState();
  const { token } = useFindOrCreateToken(
    activeOrg?.organizationId,
    selectedProject?.id,
    selectedProject?.type,
    selectedProject?.isTestnet,
  );

  const [remainingGasCredits, setRemainingGasCredits] = useState<number | undefined>();
  const [hasFetchedCredits, setHasFetchedCredits] = useState(false);
  const getRemainingPaymasterBalance = useGetRemainingPaymasterBalance({
    tokenId: token?.id,
    isTestnet: token?.isTestnet || false,
    onSuccess: setRemainingGasCredits,
  });
  const [warningModalVisible, { toggleOn: showWarningModal, toggleOff: hideWarningModal }] =
    useToggler();

  useEffect(() => {
    if (
      token?.id &&
      !remainingGasCredits &&
      !hasFetchedCredits &&
      !getRemainingPaymasterBalance.isError
    ) {
      getRemainingPaymasterBalance.mutate();
      setHasFetchedCredits(true);
    }
  }, [getRemainingPaymasterBalance, hasFetchedCredits, remainingGasCredits, token?.id]);

  const toast = useToast();

  const gasPolicy = token?.policies?.[0];

  const updateGasPolicy = useUpdateGasPolicy({
    organizationId: activeOrg?.organizationId || '',
    projectId: selectedProject?.id || '',
  });

  const createGasPolicy = useCreateGasPolicy({
    organizationId: activeOrg?.organizationId || '',
    projectId: selectedProject?.id || '',
    tokenId: token?.id,
    onError: (errorMessage) => {
      toast.show(errorMessage, { variant: 'negative' });
    },
  });

  const updateAccountAbstractionSuccessMsg = useMemo(() => {
    if (gasPolicy?.enabled) {
      return 'Paymaster & Bundler disabled';
    }
    return 'Paymaster & Bundler enabled';
  }, [gasPolicy]);

  const updateAccountAbstractionStatus = useUpdateAccountAbstractionStatus({
    organizationId: activeOrg?.organizationId || '',
    projectId: selectedProject?.id || '',
    successMsg: updateAccountAbstractionSuccessMsg,
  });

  const handleAccountAbstractionStatusChange = useCallback(() => {
    if (!gasPolicy) {
      createGasPolicy.mutate();
      return;
    }
    const policy = { ...gasPolicy, enabled: !gasPolicy?.enabled };
    if (!policy.enabled) {
      showWarningModal();
      return;
    }
    updateAccountAbstractionStatus.mutate({ policy });
  }, [createGasPolicy, gasPolicy, showWarningModal, updateAccountAbstractionStatus]);

  const handleDisableAccountAbstraction = useCallback(() => {
    if (!gasPolicy) {
      throw new Error('Unable to disable Paymaster & Bundler. Missing required information.');
    }
    const policy = { ...gasPolicy, enabled: !gasPolicy?.enabled };
    if (!policy.enabled) {
      setIsGasPolicyFormOpen(false);
    }
    updateAccountAbstractionStatus.mutate({ policy });
  }, [gasPolicy, updateAccountAbstractionStatus]);

  const handlePublishGasPolicy = useCallback(
    (policy: GasPolicy) => {
      updateGasPolicy.mutate({ policy });
    },
    [updateGasPolicy],
  );

  const handleCancel = useCallback(() => {
    setIsGasPolicyFormOpen(false);
  }, []);

  const handleToggleGasPolicyFormPress = useCallback(() => {
    if (gasPolicy) {
      setIsGasPolicyFormOpen((prev) => !prev);
      return;
    }

    createGasPolicy.mutate();
    setIsGasPolicyFormOpen(true);
  }, [createGasPolicy, gasPolicy]);

  return (
    <VStack bordered borderRadius="rounded" spacing={4} gap={1}>
      {warningModalVisible && gasPolicy && (
        <DisableAccountAbstractionWarningModal
          onRequestClose={hideWarningModal}
          onConfirm={handleDisableAccountAbstraction}
        />
      )}
      <HStack justifyContent="space-between">
        <HStack alignItems="center" gap={2}>
          <Box
            dangerouslySetBackground={palette.foreground}
            height={24}
            width={24}
            borderRadius="roundedFull"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Icon name="smartContract" size="s" color="primaryForeground" />
          </Box>
          <VStack>
            <TextHeadline as="h1">Enable Paymaster</TextHeadline>
            <TextBody as="p" color="foregroundMuted">
              Set a gas policy for global and per user maximums
            </TextBody>
          </VStack>
        </HStack>

        <HStack alignItems="center" zIndex={2}>
          <Switch
            disabled={createGasPolicy.isLoading || updateAccountAbstractionStatus.isLoading}
            onChange={handleAccountAbstractionStatusChange}
            checked={!!gasPolicy?.enabled}
          />
          <Pressable
            background="transparent"
            onPress={handleToggleGasPolicyFormPress}
            noScaleOnPress
          >
            <Box width={40} height="100%" alignItems="center" justifyContent="flex-end">
              <Icon
                color="foreground"
                name={isGasPolicyFormOpen ? 'caretUp' : 'caretDown'}
                size="s"
              />
            </Box>
          </Pressable>
        </HStack>
      </HStack>
      {isGasPolicyFormOpen && gasPolicy && (
        <VStack spacingHorizontal={8} spacingTop={6}>
          <TextCaption as="p">Gas policy</TextCaption>
          <GasPolicyForm
            policy={gasPolicy}
            onCancel={handleCancel}
            onPublishGasPolicy={handlePublishGasPolicy}
          />
        </VStack>
      )}
    </VStack>
  );
}
