import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { GasPolicyResponse } from ':cloud/types/service_proto';
import { GasPolicy } from ':cloud/types/ts_types';

const createGasPolicyErrorMsg = 'An error occurred enabling Paymaster & Bundler.';
const createGasPolicySuccessMsg = 'Paymaster & Bundler activated.';

async function createGasPolicy(
  organizationId?: string,
  projectId?: string,
  tokenId?: string,
): Promise<GasPolicy> {
  if (!organizationId || !projectId || !tokenId) {
    throw new Error('Missing required information. Cannot create gas policy.');
  }

  const path = `/apikeys/v1/organizations/${organizationId}/projects/${projectId}/policies`;

  const body = {
    tokenId,
  };

  const { data } = await APIClientBase.post<GasPolicyResponse>(path, body);

  return data?.policy;
}

type CreateGasPolicyProps = {
  organizationId?: string;
  projectId: string;
  tokenId: string;
  onSuccess?: (policy: GasPolicy) => void;
  onError?: (errorMessage: string) => void;
};

export function useCreateGasPolicy({
  organizationId,
  projectId,
  tokenId,
  onSuccess,
  onError,
}: CreateGasPolicyProps) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => createGasPolicy(organizationId, projectId, tokenId),
    onSuccess: async (policy: GasPolicy) => {
      onSuccess?.(policy);
      await queryClient.invalidateQueries([QueryKey.gasPolicy, tokenId]);
      await queryClient.invalidateQueries([QueryKey.tokens, organizationId, projectId]);
    },
    onError: (error: AxiosError) => {
      if (error?.response?.status === 429) {
        onError?.(
          'Your organization has already been granted the paymaster credit. Please use the original project for now.',
        );
        return;
      }
      onError?.(createGasPolicyErrorMsg);
    },
    meta: {
      successMsg: createGasPolicySuccessMsg,
    },
  });
}
