import { Button } from '@cbhq/cds-web/buttons';
import { SelectOption } from '@cbhq/cds-web/controls';
import { Dropdown } from '@cbhq/cds-web/dropdown';

import { baseConfig } from '../product/configurations/baseConfig';

type BaseNetworkDropdownProps = {
  selectedNetwork: string;
  setSelectedNetwork: (network: string) => void;
};

const content = baseConfig?.networkOptions?.map((option) => (
  <SelectOption key={option.value} value={option.label} title={option.label} />
));

export function BaseNetworkDropdown({
  selectedNetwork,
  setSelectedNetwork,
}: BaseNetworkDropdownProps) {
  return (
    <Dropdown
      value={selectedNetwork}
      onChange={setSelectedNetwork}
      content={content}
      enableMobileModal
    >
      <Button compact variant="secondary" endIcon="caretDown">
        {selectedNetwork}
      </Button>
    </Dropdown>
  );
}
