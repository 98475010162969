import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@cbhq/cds-web/buttons';
import { SelectOption } from '@cbhq/cds-web/controls';
import { Dropdown } from '@cbhq/cds-web/dropdown';
import { Icon } from '@cbhq/cds-web/icons';
import { Divider, VStack } from '@cbhq/cds-web/layout';
import { TextHeadline } from '@cbhq/cds-web/typography';

import { useAppState } from ':cloud/contexts/AppStateContext';
import { TabTypeId, useNavigationContext } from ':cloud/contexts/NavigationContext';
import { useCreateProjectAndPushRoute } from ':cloud/hooks/useCreateProjectAndPushRoute';
import { useGetProject } from ':cloud/hooks/useGetProject';
import { useGetProjects } from ':cloud/queries/Projects/useGetProjects';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { truncateMiddle } from ':cloud/utils/common';
import { AppRoute } from ':cloud/utils/routes';

const PROJECT_SELECTOR_LABEL_MAX_CHARS = 16;
const PROJECT_SELECTOR_DROPDOWN_MAX_CHARS = 25;
const PROJECT_SELECTOR_CELL_HEIGHT = 58;
const PROJECT_SELECTOR_WIDTH = 310;

export function ProjectSelector() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { selectedProject, setSelectedProject } = useAppState();
  const { activeTab } = useNavigationContext();
  const history = useHistory();
  const createProject = useCreateProjectAndPushRoute();
  const { activeOrg } = useGetUser();
  const { projects } = useGetProjects(activeOrg?.organizationId || '');
  const foundProject = useGetProject(selectedProject?.id || '');
  const truncatedSelectedProjectName = truncateMiddle(
    selectedProject?.name || '',
    PROJECT_SELECTOR_LABEL_MAX_CHARS,
    0,
    '...',
  );

  const handleSelectChange = useCallback(
    (newValue) => {
      if (newValue === 'create') {
        createProject();
        return;
      }
      const newSelection = projects.find((project) => project.id === newValue);
      if (newSelection) {
        setSelectedProject(newSelection);
        // redirect to project dashboard, unless on api keys page
        if (activeTab !== ('api' as TabTypeId)) {
          history.push(`${AppRoute.Projects.Home}/${newSelection.id}`);
        }
      }
    },
    [activeTab, createProject, history, projects, setSelectedProject],
  );

  const toggleDropdown = useCallback(() => setIsDropdownOpen((prev) => !prev), []);

  const content = useMemo(
    () => (
      <VStack width={PROJECT_SELECTOR_WIDTH} spacingTop={2} position="relative">
        {projects.map((project) => {
          const truncatedProjectName = truncateMiddle(
            project.name,
            PROJECT_SELECTOR_DROPDOWN_MAX_CHARS,
            0,
            '...',
          );

          return (
            <SelectOption
              value={project.id}
              key={project.id}
              title={truncatedProjectName}
              minHeight={PROJECT_SELECTOR_CELL_HEIGHT}
            />
          );
        })}
        {projects.length === 0 && (
          <SelectOption
            value="no-projects"
            title={
              <TextHeadline as="p" overflow="wrap" align="center">
                {`You don't currently have any projects. Create one below.`}
              </TextHeadline>
            }
            disabled
            minHeight={PROJECT_SELECTOR_CELL_HEIGHT * 2}
          />
        )}
        {/* removing for now but may be re-instated in the future */}
        {/* {projects.length > 5 && (
          <SelectOption
            value="see-all"
            key="see-all"
            title={
              <TextHeadline color="primary" as="p">
                See all
              </TextHeadline>
            }
            minHeight={PROJECT_SELECTOR_CELL_HEIGHT}
          />
        )} */}
        <VStack
          position="sticky"
          bottom={0}
          left={0}
          height={PROJECT_SELECTOR_CELL_HEIGHT}
          overflow="hidden"
        >
          <Divider />
          <SelectOption
            value="create"
            key="create"
            accessory={<Icon size="xs" color="primary" name="add" />}
            title={
              <TextHeadline color="primary" as="p">
                Create new project
              </TextHeadline>
            }
            shouldOverflow={false}
            minHeight={PROJECT_SELECTOR_CELL_HEIGHT}
          />
        </VStack>
      </VStack>
    ),
    [projects],
  );

  useEffect(() => {
    // setting first project on global state if one isn't already set
    if (!selectedProject && projects.length) {
      setSelectedProject(projects[0]);
    }
  }, [projects, selectedProject, setSelectedProject]);

  useEffect(() => {
    if (foundProject) {
      setSelectedProject(foundProject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- preventing re-render cycle
  }, [foundProject]);

  return (
    <Dropdown
      value={selectedProject?.id}
      onChange={handleSelectChange}
      content={content}
      width={PROJECT_SELECTOR_WIDTH}
      onCloseMenu={toggleDropdown}
      onOpenMenu={toggleDropdown}
    >
      <VStack justifyContent="center" width="100%">
        <Button
          variant="secondary"
          transparent
          endIcon={isDropdownOpen ? 'caretUp' : 'caretDown'}
          flush="start"
          compact
        >
          <TextHeadline as="p" noWrap numberOfLines={1}>
            {truncatedSelectedProjectName || 'Create a project ...'}
          </TextHeadline>
        </Button>
      </VStack>
    </Dropdown>
  );
}
