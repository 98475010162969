import _camelCase from 'lodash/camelCase';
import _isArray from 'lodash/isArray';
import _isObject from 'lodash/isObject';
import _reduce from 'lodash/reduce';

type FlattenObj = Record<string, string>;
// flatten will flatten js objects into a single depth obj
export function flatten(object: any, prefix = ''): FlattenObj {
  return Object.keys(object as Record<string, unknown>).reduce((prev, element) => {
    return object[element] && typeof object[element] === 'object' && !Array.isArray(element)
      ? { ...prev, ...flatten(object[element], `${prefix}${element}.`) }
      : { ...prev, ...{ [`${prefix}${element}`]: object[element] } };
  }, {});
}

const excludeConfigKeys = new Set([
  'activeValidator',
  'billingId',
  'clusterType',
  'genesis',
  'name',
  'region',
  'scale',
  'withBaker',
  'extendedClusters',
  'upgradeExtendedClusters',
  'resourceIndex',
  'chain',
  'networkId',
  'activeInNetwork',
  'needsToken',
  'network',
  'needsSecondary',
  'livenessProbeEnabled',
  'needsNonUSSecondary',
  'runInitTools',
  'addresses',
  'mode',
]);

export function shouldExcludeKey(key: string): boolean {
  return excludeConfigKeys.has(key) || key.startsWith('_');
}

export function mapKeysToCamelCase(obj: unknown[]): unknown[];
export function mapKeysToCamelCase(obj: object): object;
export function mapKeysToCamelCase<T>(obj: T): T;
export function mapKeysToCamelCase<T>(obj: T): object | unknown[] | T {
  if (!_isObject(obj)) {
    return obj;
  }
  if (_isArray(obj)) {
    const xyz = obj.map((v) => mapKeysToCamelCase<typeof obj>(v));
    return xyz;
  }
  return _reduce(
    obj,
    (r, v, k) => {
      return {
        ...r,
        [_camelCase(k)]: mapKeysToCamelCase(v),
      };
    },
    {},
  );
}

export function getFormattedId(id: string, numChars = 4): string {
  const firstChars = id.slice(0, numChars);
  const lastChars = id.slice(-numChars);
  return `${firstChars}***${lastChars}`;
}

/*
 * @param  {string} str         String to be truncated
 * @param  {number} frontLen    Number of characters to be remained in front.
 * @param  {number} backLen     Number of characters to be remained at the back.
 * @param  {string} truncateStr String that is replaced the truncated portion
 * @return {string}             Truncated string. Defaults to '&hellip;' if unspecified.
 */
export function truncateMiddle(
  str: string,
  frontLen: number,
  backLen: number,
  truncateStr = '',
): string {
  if (str === null) {
    return '';
  }
  const strLen = str.length;
  // Setting default values
  const frontLenNum = Number(frontLen);
  const backLenNum = Number(backLen);
  if (
    (frontLenNum === 0 && backLen === 0) ||
    frontLenNum >= strLen ||
    backLenNum >= strLen ||
    frontLenNum + backLenNum >= strLen
  ) {
    return str;
  }
  if (backLenNum === 0) {
    return str.slice(0, frontLenNum) + truncateStr;
  }
  return str.slice(0, frontLenNum) + truncateStr + str.slice(strLen - backLenNum);
}

export function toSnakeCase(str: string) {
  return str.replace(/([A-Z])/g, '_$1').toLowerCase();
}
