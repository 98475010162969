import { useMemo } from 'react';
import { Button } from '@cbhq/cds-web/buttons';
import { HStack, Spacer, VStack } from '@cbhq/cds-web/layout';
import {
  Table,
  TableBody,
  TableCell,
  TableCellFallback,
  TableHeader,
  TableRow,
} from '@cbhq/cds-web/tables';
import { TextBody, TextHeadline, TextTitle3 } from '@cbhq/cds-web/typography';

import { useSelectedProjectAPIKeys } from ':cloud/hooks/useSelectedProjectAPIKeys';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { getFormattedId } from ':cloud/utils/common';
import { APIKeyTableEmptyState } from ':cloud/widgets/access/api/APIKeyTableEmptyState';
import { MobileAPIKeyTableEmptySearchState } from ':cloud/widgets/access/api/MobileAPIKeyTableEmptySearchState';
import { ClipboardIcon } from ':cloud/widgets/sharedcomponents';

type APIKeyTableLoadingStateProps = { isViewOnly: boolean };

function APIKeyTableLoadingState({ isViewOnly }: APIKeyTableLoadingStateProps) {
  return (
    <>
      {[...Array(3).keys()].map((e) => (
        <TableRow key={e}>
          <TableCellFallback title />
          <TableCellFallback title />
          {!isViewOnly && <TableCellFallback end="image" width={100} />}
        </TableRow>
      ))}
    </>
  );
}

type MobileAPIKeyTableProps = {
  onCreatePress: (projectFilter?: string) => void;
  onKeyDetailPress: (keyId: string) => void;
};

export function MobileCoinbaseAPIKeyTable({
  onCreatePress,
  onKeyDetailPress,
}: MobileAPIKeyTableProps) {
  const {
    user: { isOwnerOrAdmin },
  } = useGetUser();

  const { projectKeys, isKeysLoading } = useSelectedProjectAPIKeys();

  const isViewOnly = !isOwnerOrAdmin;
  const isInLoadingState = isKeysLoading;
  const isInEmptyState = Array.isArray(projectKeys) && !projectKeys?.length;
  const isPortfolioInEmptyState = Array.isArray(projectKeys) && !projectKeys?.length;

  const tokenTableContents = projectKeys?.map((key, idx) => {
    const splitName = key.name.split('apiKeys/');
    const keyId = splitName[splitName.length - 1];
    const formattedKeyId = getFormattedId(splitName.length ? keyId : '');
    return (
      <TableRow key={key.nickname || idx}>
        <TableCell>
          <TextHeadline as="p" spacingVertical={3}>
            {key.nickname || ''}
          </TextHeadline>
        </TableCell>
        <TableCell>
          <HStack gap={1}>
            <VStack background="primaryWash" spacingHorizontal={1}>
              <TextBody as="p" mono>
                {formattedKeyId}
              </TextBody>
            </VStack>
            <ClipboardIcon text={keyId} color="foreground" />
          </HStack>
        </TableCell>
        {!isViewOnly && (
          <TableCell>
            <Button transparent flush="start" onPress={() => onKeyDetailPress(keyId)}>
              Manage
            </Button>
          </TableCell>
        )}
      </TableRow>
    );
  });

  const isPortfolioWithSearchInEmptyState = tokenTableContents.length === 0;

  const tableBody = useMemo(() => {
    if (isInEmptyState && !isInLoadingState) {
      return (
        <TableRow>
          <TableCell colSpan={3}>
            <APIKeyTableEmptyState
              hasProjects
              isViewOnly={isViewOnly}
              onCreatePress={onCreatePress}
              isTradeTable
            />
          </TableCell>
        </TableRow>
      );
    }
    if (isInLoadingState) {
      return <APIKeyTableLoadingState isViewOnly={isViewOnly} />;
    }
    if (!isPortfolioWithSearchInEmptyState) {
      return tokenTableContents;
    }

    return <MobileAPIKeyTableEmptySearchState isViewOnly={isViewOnly} />;
  }, [
    isInEmptyState,
    isInLoadingState,
    isPortfolioWithSearchInEmptyState,
    isViewOnly,
    onCreatePress,
    tokenTableContents,
  ]);

  return (
    <VStack gap={2}>
      <VStack gap={2}>
        <VStack gap={3}>
          <TextTitle3 as="p">Configure your API keys</TextTitle3>
          <TextBody as="p" color="foregroundMuted">
            An API key is a unique identifier that allows other apps and services to access specific
            functions and data. You can create and configure your API keys here.
          </TextBody>
        </VStack>
        {!isViewOnly && (
          <VStack justifyContent="center">
            <Button onPress={() => onCreatePress()}>Create API key</Button>
          </VStack>
        )}
      </VStack>
      <Spacer />

      <Table bordered tableLayout="auto">
        <TableHeader>
          {!isPortfolioInEmptyState && (
            <TableRow>
              <TableCell title="Nickname" />
              <TableCell title="Key ID" />
              {!isViewOnly && <TableCell title="" alignItems="center" width={100} />}
            </TableRow>
          )}
        </TableHeader>
        <TableBody>{tableBody}</TableBody>
      </Table>
    </VStack>
  );
}
