import { memo, useCallback } from 'react';
import startCase from 'lodash/startCase';
import { Button } from '@cbhq/cds-web/buttons';
import { IconButton } from '@cbhq/cds-web/buttons/IconButton';
import { DotStatusColor } from '@cbhq/cds-web/dots/DotStatusColor';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { TableCell, TableRow } from '@cbhq/cds-web/tables';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

import config from ':cloud/config';
import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { EventName, logClick } from ':cloud/init/clientAnalytics/logging';
import { useGetInvoiceDownload } from ':cloud/queries/BillingQueries/useGetInvoiceDownload';
import { BillingHistoryItem as BillingHistoryItemType } from ':cloud/types/ts_types';
import { formatDateUTC } from ':cloud/utils/date';
import { handleDownload } from ':cloud/utils/files';
import { currencyFormatter } from ':cloud/utils/formatters/currency';

type BillingHistoryItemProps = BillingHistoryItemType & {
  orgId: string;
};

function BillingHistoryItem({
  status,
  billingPeriodStartTime,
  orgId,
  stripeInvoiceId,
  amount,
}: BillingHistoryItemProps) {
  const startDate = new Date(billingPeriodStartTime);
  const month = formatDateUTC(startDate, 'MMMM');
  const year = formatDateUTC(startDate, 'yyyy');

  const getInvoiceDownload = useGetInvoiceDownload(orgId);
  const { isPhone } = useSimpleBreakpoints();

  const handleDownloadInvoice = useCallback(() => {
    getInvoiceDownload.mutate(stripeInvoiceId, {
      onSuccess(data) {
        handleDownload(data.downloadUri);
      },
    });
    logClick(EventName.invoice_download);
  }, [stripeInvoiceId, getInvoiceDownload]);

  // Hide pending invoices in production
  // This occurs during a ~60min window after a new invoice is created
  // We need the production flag b/c dev/staging invoices never progress past PENDING state
  if (status === 'PENDING' && config.isProduction()) {
    return null;
  }

  return (
    <TableRow>
      <TableCell>
        <VStack>
          <TextHeadline as="p">{month}</TextHeadline>
          <TextBody as="p" color="foregroundMuted">
            {year}
          </TextBody>
        </VStack>
      </TableCell>
      <TableCell width={isPhone ? '25%' : '162px'} title={currencyFormatter(parseFloat(amount))} />
      <TableCell width={isPhone ? '25%' : '162px'}>
        <HStack alignItems="center">
          <DotStatusColor size="xs" variant={status === 'FAILED' ? 'negative' : 'positive'} />
          <TextBody as="p" spacingStart={2}>
            {startCase(status.toLowerCase())}
          </TextBody>
        </HStack>
      </TableCell>
      <TableCell width={isPhone ? '25%' : '142px'}>
        {isPhone ? (
          <IconButton
            name="download"
            onPress={handleDownloadInvoice}
            loading={getInvoiceDownload.isLoading}
          />
        ) : (
          <Button
            variant="secondary"
            onPress={handleDownloadInvoice}
            loading={getInvoiceDownload.isLoading}
          >
            Download
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
}

export default memo(BillingHistoryItem);
