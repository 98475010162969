import { styled } from '@linaria/react';
import { HStack } from '@cbhq/cds-web/layout';
import { Pressable } from '@cbhq/cds-web/system';
import { spacing } from '@cbhq/cds-web/tokens';

import { ReactComponent as CaretIcon } from ':cloud/assets/Actions/carrot-down-circled.svg';

const StyledCaretIcon = styled(CaretIcon)`
  width: ${spacing[3]};
  height: ${spacing[3]};
  transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transform: rotate(180deg);
  &.collapsed {
    transform: rotate(0deg);
  }
`;

interface CollapseLinkProps {
  children: React.ReactNode;
  onPress: () => void;
  isCollapsed?: boolean;
}

export function CollapseLink({ children, isCollapsed = false, onPress }: CollapseLinkProps) {
  return (
    <Pressable background="transparent" onPress={onPress}>
      <HStack alignItems="center" gap={1}>
        {children}
        <StyledCaretIcon aria-hidden className={isCollapsed ? 'collapsed' : ''} />
      </HStack>
    </Pressable>
  );
}
