import { createContext, memo, useCallback, useContext, useMemo } from 'react';

import useLocalStorage, { SetValue } from ':cloud/hooks/useLocalStorage';
import { Project } from ':cloud/types/ts_types';

type AppStateContextType = {
  isDarkMode: boolean;
  toggleDarkMode: () => void;
  selectedProject: Project | null;
  setSelectedProject: SetValue<Project | null>;
};

function noop() {}

const AppStateContext = createContext<AppStateContextType>({
  isDarkMode: false,
  toggleDarkMode: noop,
  selectedProject: null,
  setSelectedProject: noop,
});

export const AppStateProvider = memo(function AppStateProvider({ children }) {
  const [isDarkMode, setIsDarkMode] = useLocalStorage('dark-mode', false);
  const [selectedProject, setSelectedProject] = useLocalStorage<Project | null>(
    'selected-project',
    null,
  );

  const toggleDarkMode = useCallback(() => {
    setIsDarkMode((prevIsDarkMode) => !prevIsDarkMode);
  }, [setIsDarkMode]);

  const contextData: AppStateContextType = useMemo(
    () => ({
      isDarkMode,
      toggleDarkMode,
      selectedProject,
      setSelectedProject,
    }),
    [isDarkMode, selectedProject, setSelectedProject, toggleDarkMode],
  );

  return <AppStateContext.Provider value={contextData}>{children}</AppStateContext.Provider>;
});

export function useAppState() {
  return useContext(AppStateContext);
}
