import { useState } from 'react';
import { VStack } from '@cbhq/cds-web/layout';

import { VerificationsOverview } from ':cloud/pages/Verifications/VerificationsOverview';
import { VerificationsPlayground } from ':cloud/pages/Verifications/VerificationsPlayground';
import { Layout } from ':cloud/widgets/layout';
import { MAIN_CONTAINER_MIN_WIDTH } from ':cloud/widgets/product/constants';
import { UnroutedTopNavTabs } from ':cloud/widgets/sharedcomponents/UnroutedTopNavTabs';

const tabs = [
  { id: 'overview', label: 'Overview' },
  { id: 'playground', label: 'Playground' },
];

export function VerificationsLanding() {
  const [activeTab, setActiveTab] = useState('overview');

  return (
    <VStack width="100%">
      <UnroutedTopNavTabs
        value={activeTab}
        tabs={tabs}
        onChange={setActiveTab}
        spacingHorizontal={5}
        width="100%"
      />
      <Layout enableMaxWidth={false}>
        <Layout.MainContainer
          minWidth={MAIN_CONTAINER_MIN_WIDTH}
          spacingHorizontal={0}
          fullWidth
          spacingTop={0}
        >
          {activeTab === 'overview' ? <VerificationsOverview /> : <VerificationsPlayground />}
        </Layout.MainContainer>
      </Layout>
    </VStack>
  );
}
