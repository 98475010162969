import { useEffect } from 'react';
import { ActionType, ComponentType, logEvent } from '@cbhq/client-analytics';

export enum EventName {
  // waas create project flow
  waas_cta = 'waas_cta',
  create_project = 'create_project',
  generate_api_key = 'generate_api_key',
  waas_sdk = 'waas_sdk',
  waas_docs = 'waas_docs',

  // general product landing page flow
  product_cta = 'product_cta',

  left_nav = 'left_nav',
  invoice_download = 'invoice_download',

  // v2
  dashboard_create_new_project = 'dashboard_create_new_project',
  project_card_click = 'project_card_click',
  project_page_start_waas_click = 'project_page_start_waas_click',
  project_page_start_waas_consumer_click = 'project_page_start_waas_consumer_click',
  project_page_start_staking_click = 'project_page_start_staking_click',
  project_page_start_base_click = 'project_page_start_base_click',
  v2_dashboard_view = 'v2_dashboard_view',
  v2_project_overview_view = 'v2_project_overview_view',
  v2_monitor_page_view = 'v2_monitor_page_view',
  v2_manage_page_view = 'v2_manage_page_view',

  // earnings
  earnings_page_view = 'earnings_page_view',

  // billing
  billing_page_view = 'billing_page_view',
  billing_usage_tab_viewed = 'billing_usage_tab_viewed',
  billing_add_payment_method = 'billing_add_payment_method',

  // api keys
  trade_key_create_click = 'trade_key_create_click',
  general_key_create_click = 'general_key_create_click',
  trade_key_table_view = 'trade_key_table_view',
  general_key_table_view = 'general_key_table_view',
  api_key_success_modal_view = 'api_key_success_modal_view',
  create_api_key_click = 'create_api_key_click',
  api_key_empty_state_view = 'api_key_empty_state_view',

  access_page_view = 'access_page_view',
  api_keys_page_view = 'api_keys_page_view',
  teams_page_view = 'teams_page_view',

  wallets_page_view = 'wallets_page_view',
  embedded_wallets_page_view = 'embedded_wallets_page_view',
  embedded_wallets_learn_more_click = 'embedded_wallets_learn_more_click',

  // paymaster
  node_page_view = 'node_page_view',
  paymaster_page_view = 'paymaster_page_view',
  paymaster_config_page_view = 'paymaster_config_page_view',
  paymaster_configuration_tab_click = 'paymaster_configuration_tab_click',
  paymaster_log_tab_click = 'paymaster_log_tab_click',
  paymaster_playground_tab_click = 'paymaster_playground_tab_click',
  paymaster_playground_run_click = 'paymaster_playground_run_click',
  paymaster_logs_download_click = 'paymaster_logs_download_click',
  paymaster_add_contract_allowlist_click = 'paymaster_add_contract_allowlist_click',
  bundler_playground_run_click = 'bundler_playground_run_click',
  base_playground_run_click = 'base_playground_run_click',
  paymaster_update_global_gas_limit = 'paymaster_update_global_gas_limit',
  paymaster_update_user_limit = 'paymaster_update_user_limit',
  paymaster_update_user_op_limit = 'paymaster_update_user_op_limit',
  paymaster_update_requirements = 'paymaster_update_requirements',
  paymaster_sponsor_name_update = 'paymaster_sponsor_name_update',

  paymaster_getting_started_guide_view = 'paymaster_getting_started_guide_view',
  paymaster_playground_step_click = 'paymaster_playground_step_click',
  paymaster_contract_step_click = 'paymaster_contract_step_click',
  paymaster_gas_step_click = 'paymaster_gas_step_click',
  paymaster_sponsor_step_click = 'paymaster_sponsor_step_click',
  paymaster_payment_step_click = 'paymaster_payment_step_click',
  paymaster_transaction_step_click = 'paymaster_transaction_step_click',

  platform_api_page_view = 'platform_api_page_view',
  boat_page_view = 'boat_page_view',
  boat_page_external_click = 'boat_page_external_click',

  indexers_page_view = 'indexers_page_view',
  cdp_listBalances_run_click = 'cdp_listBalances_run_click',
  cdp_listBalanceDetails_run_click = 'cdp_listBalanceDetails_run_click',
  cdp_listBalanceHistories_run_click = 'cdp_listBalanceHistories_run_click',
  cdp_listAddressTransactions_run_click = 'cdp_listAddressTransactions_run_click',

  // webhooks
  create_webhook_click = 'create_webhook_click',
  webhook_page_view = 'webhook_page_view',
  webhook_empty_state_view = 'webhook_empty_state_view',
}

export function logClick(event: EventName, additionalProperties?: Record<string, unknown>) {
  logEvent(event, {
    action: ActionType.click,
    componentType: ComponentType.button,
    ...additionalProperties,
  });
}

export function useLogViewOnMount(
  event: EventName,
  additionalProperties?: Record<string, unknown>,
) {
  return useEffect(() => {
    logEvent(event, {
      action: ActionType.view,
      componentType: ComponentType.page,
      ...additionalProperties,
    });
  }, [additionalProperties, event]);
}
