import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _capitalize from 'lodash/capitalize';
import { Icon } from '@cbhq/cds-web/icons';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { TextHeadline, TextLabel2, TextTitle1 } from '@cbhq/cds-web/typography';
import { ProgressBar } from '@cbhq/cds-web/visualizations/ProgressBar';

import { NewClusterContextType } from ':cloud/contexts/NewClusterContext';
import { useGetProtocolDisplayName } from ':cloud/queries/WorkflowQueries/useGetProtocolDisplayName';
import { useGetProtocolNetworkChain } from ':cloud/queries/WorkflowQueries/useGetWorkflows';
import { MetricRegionKey } from ':cloud/types/ts_types';
import { getClusterRegion } from ':cloud/utils/clusters';

import { ProtocolIcon } from './ProtocolIcon';
import { TextLabelGray } from './TextLabelGray';

interface ClusterInterstitialProps {
  overrideStatuses?: string[];
  progressTimer?: number;
  runProgress?: boolean;
  formData: NewClusterContextType['formData'];
}

/* only used in Participate */
export function ClusterInterstitial({
  progressTimer = 7,
  runProgress = false,
  formData,
  overrideStatuses,
}: ClusterInterstitialProps) {
  const { displayName } = useGetProtocolDisplayName(formData?.protocolKey || '');

  const statuses = useMemo(() => {
    return (
      overrideStatuses || [
        'Configuring Server',
        'Setting up Cluster',
        'Adding Configuration',
        'Loading Configuration',
        'Spinning Up Cluster',
      ]
    );
  }, [overrideStatuses]);

  const [currentStatus, setCurrentStatus] = useState(statuses[0]);
  const [progress, setProgress] = useState(0);

  const incrementProgressBar = useCallback(() => {
    const increment = 1 / statuses.length;
    setProgress((currProgress) => currProgress + increment);
  }, [statuses.length]);

  const { networkChain } = useGetProtocolNetworkChain(formData?.protocolKey as string);
  const nodeBreakdown = [
    formData?.region && {
      label: getClusterRegion(formData.region as MetricRegionKey).display,
      value: 'Region',
    },
    networkChain && {
      label: _capitalize(networkChain),
      value: 'Network',
    },
  ].filter(Boolean) as { label: string; value: string }[];

  const incrementDelay = (progressTimer * 1000) / statuses.length;

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  useEffect(() => {
    function nextTimer(): void {
      /* don't retrieve last status from closure */
      setCurrentStatus((currStatus) => statuses[statuses.indexOf(currStatus) + 1]);
      incrementProgressBar();
      timer.current = setTimeout(nextTimer, incrementDelay);
    }
    timer.current = runProgress ? setTimeout(nextTimer, incrementDelay) : null;
    // TODO(arti.villa):  https://github.com/DefinitelyTyped/DefinitelyTyped/issues/31065
    // @ts-expect-error Arti to add guard clause
    setTimeout(() => clearTimeout(timer.current), progressTimer * 1000);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <VStack width="100%" gap={2}>
      <VStack
        borderRadius="roundedSmall"
        spacing={10}
        width="100%"
        borderColor="lineHeavy"
        alignItems="center"
      >
        {formData?.protocolKey && (
          <VStack alignItems="center">
            <TextLabel2 color="foregroundMuted" as="p" spacingBottom={3}>
              {displayName}
            </TextLabel2>
            <ProtocolIcon protocolKey={formData.protocolKey} size={80} />
            <TextTitle1 as="h1" spacingTop={3} spacingBottom={7} spacingHorizontal={0}>
              {formData.name}
            </TextTitle1>
          </VStack>
        )}
        <VStack as="ul" maxWidth={320} width="100%">
          {nodeBreakdown.map(({ label, value }) => (
            <HStack key={label} justifyContent="space-between" spacingBottom={1} gap={5}>
              <HStack alignItems="center" gap={1}>
                <Icon name="checkmark" size="s" color="primary" />
                <TextHeadline as="span">{label}</TextHeadline>
              </HStack>
              <TextLabelGray>{value}</TextLabelGray>
            </HStack>
          ))}
        </VStack>
      </VStack>
      {runProgress && (
        <>
          <ProgressBar weight="heavy" progress={progress} />
          <TextLabel2 as="p" spacingTop={2}>{`Status: ${currentStatus}...`}</TextLabel2>
        </>
      )}
    </VStack>
  );
}
