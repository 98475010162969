import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useIsCOMSWriteEnabled } from ':cloud/hooks/featureFlags/useIsCOMSWriteEnabled';
import { QueryKey } from ':cloud/queries/types';
import { APIClientBase, APIClientRM } from ':cloud/state/Api';
import { SwitchMemberResponse } from ':cloud/types/service_proto';
import {
  OrganizationRole,
  OrganizationRoleCOMS,
  OrganizationRoleCOMSEnum,
} from ':cloud/types/ts_types';

async function switchMemberRoleLEGACY(
  organizationId: string,
  userId: string,
  role: OrganizationRole,
): Promise<SwitchMemberResponse> {
  const path = `/v1/organizations/${organizationId}/users`;
  const requestBody = {
    userId,
    role,
    organizationId,
  };
  const { data } = await APIClientRM.put<SwitchMemberResponse>(path, requestBody);
  return data;
}

async function switchMemberRole(
  organizationId: string,
  userId: string,
  role: OrganizationRoleCOMS,
): Promise<SwitchMemberResponse> {
  const path = `/organization-management/v1/organizations/${organizationId}/users`;
  const requestBody = {
    userId,
    role,
  };
  const { data } = await APIClientBase.put<SwitchMemberResponse>(path, requestBody);
  return data;
}

export function useSwitchMemberRole(organizationId: string, memberEmail: string, userId: string) {
  const shouldUseCOMSEndpoint = useIsCOMSWriteEnabled();

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (invitedRole: OrganizationRole) => {
      if (shouldUseCOMSEndpoint) {
        return switchMemberRole(organizationId, userId, OrganizationRoleCOMSEnum[invitedRole]);
      }
      return switchMemberRoleLEGACY(organizationId, userId, invitedRole);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKey.organizations]);
    },
    meta: {
      errorMsg: `Role cannot be changed`,
      successMsg: `Member ${memberEmail} updated`,
    },
  });
}
