/* eslint-disable react/no-array-index-key */
import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useToggler } from '@cbhq/cds-common/hooks/useToggler';
import { Button, ButtonGroup, IconButton } from '@cbhq/cds-web/buttons';
import { Collapsible } from '@cbhq/cds-web/collapsible/Collapsible';
import { TextInput } from '@cbhq/cds-web/controls';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { ThemeProvider } from '@cbhq/cds-web/system';
import { Tag } from '@cbhq/cds-web/tag/Tag';
import { palette } from '@cbhq/cds-web/tokens';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { useFindOrCreateProductDetails } from ':cloud/queries/Projects/useFindOrCreateProductDetails';
import { useUpdateProductDetailsWithMessage } from ':cloud/queries/Projects/useUpdateProductDetails';
import { ProductDetails, ProductTypeMap, Project } from ':cloud/types/ts_types';
import { validateDomain } from ':cloud/widgets/onramp/domainValidation';
import { ERROR_SUCCESS_MESSAGES } from ':cloud/widgets/waasConsumer/common';

type ProductDetailsSectionProps = {
  project: Project;
};

const CUSTOM_PALETTE_NAV_ICON = {
  foreground: 'gray0' as const,
} as const;

const productType = ProductTypeMap.cbpay;

export function DomainAllowlistSection({ project }: ProductDetailsSectionProps) {
  const [domains, setDomains] = useState<string[]>(['']);
  const { productDetails } = useFindOrCreateProductDetails(project, productType);
  const { isPhone } = useSimpleBreakpoints();

  const [isFormEditable, { toggle }] = useToggler();
  useEffect(() => {
    if (productDetails?.cbpay?.domainAllowlist) {
      setDomains(
        productDetails.cbpay.domainAllowlist.length ? productDetails.cbpay.domainAllowlist : [''],
      );
    }
  }, [productDetails?.cbpay?.domainAllowlist]);

  const updateProductDetails = useUpdateProductDetailsWithMessage(ERROR_SUCCESS_MESSAGES, {
    updateMask: 'domainAllowlist',
  });
  const isValidForm = useMemo(() => {
    return domains.every((domain) => validateDomain(domain));
  }, [domains]);

  const updateWalletDisplayName = useCallback(
    (updatedDetails: string[], onSettled) => {
      if (!updatedDetails) {
        return;
      }

      const newProductDetails = {
        cbpay: {
          ...productDetails?.cbpay,
          domainAllowlist: domains.map((domain) => domain.trim()).filter(Boolean),
        },
      } as ProductDetails;

      const mutation = updateProductDetails;
      mutation.mutate({ project, productType, productDetails: newProductDetails }, { onSettled });
    },
    [productDetails?.cbpay, domains, updateProductDetails, project],
  );

  const handleRemoveDomain = useCallback(
    (index: number) => {
      setDomains(domains.filter((_, i) => i !== index));
      if (domains.length === 1) {
        setDomains(['']);
      }
    },
    [domains],
  );

  const trySubmitFormData = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      updateWalletDisplayName(domains, () => {
        toggle();
      });
    },
    [updateWalletDisplayName, domains, toggle],
  );

  return (
    <VStack bordered borderRadius="rounded" spacing={4}>
      <HStack alignItems="center" gap={2}>
        {!isPhone && (
          <Box
            dangerouslySetBackground={palette.foreground}
            height={24}
            width={24}
            borderRadius="roundedFull"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ThemeProvider palette={CUSTOM_PALETTE_NAV_ICON}>
              <Icon name="chainLink" size="s" color="primaryForeground" />
            </ThemeProvider>
          </Box>
        )}
        <VStack gap={2} flexGrow={1}>
          <VStack>
            <TextHeadline as="h1">Domain allowlist</TextHeadline>
            <TextBody as="p" color="foregroundMuted">
              Required if you plan to embed Coinbase Onramp in an iFrame or use the redirectUrl
              parameter.
            </TextBody>
          </VStack>
        </VStack>
        {!isFormEditable && (
          <Button variant="primary" onPress={toggle} transparent>
            Edit
          </Button>
        )}
      </HStack>
      <Collapsible collapsed={!isFormEditable}>
        <form style={{ width: '100%' }} onSubmit={trySubmitFormData}>
          <Box spacingStart={isPhone ? 0 : 3} offsetHorizontal={isPhone ? 3 : 0}>
            <Box as="ul" flexDirection="column">
              <TextBody as="li" color="foregroundMuted">
                Domains must use: <Tag colorScheme="gray">https://</Tag>
              </TextBody>
              <TextBody as="li" color="foregroundMuted">
                Chrome extensions must use: <Tag colorScheme="gray">chrome-extension://</Tag>
              </TextBody>
            </Box>
          </Box>
          {domains.map((domain, index) => (
            <VStack
              gap={isPhone ? 0 : 3}
              spacingStart={isPhone ? 0 : 8}
              spacingTop={3}
              spacingEnd={10}
              key={`onramp-domain-${index}`}
              position="relative"
            >
              <TextInput
                onChange={(e) => {
                  const newDomains = [...domains];
                  newDomains[index] = e.target.value;
                  setDomains(newDomains);
                }}
                placeholder="Enter domain"
                maxLength={300}
                variant={domains[index] && !validateDomain(domains[index]) ? 'negative' : undefined}
                helperText={
                  domains[index] && !validateDomain(domains[index])
                    ? 'Please enter a valid domain'
                    : ''
                }
                value={domain}
              />
              <Box position="absolute" right={-4} spacingTop={1}>
                <IconButton
                  name="trashCan"
                  accessibilityLabel="Update settings"
                  variant="secondary"
                  transparent
                  onPress={() => handleRemoveDomain(index)}
                />
              </Box>
            </VStack>
          ))}
          <HStack spacingStart={isPhone ? 0 : 6} spacingTop={2} alignItems="flex-start">
            <Button
              transparent
              onPress={() => setDomains([...domains, ''])}
              compact
              disabled={domains.length > 10}
            >
              + Add new domain
            </Button>
            {domains.length > 10 && (
              <HStack justifyContent="center" alignItems="center" spacingTop={1}>
                <Icon name="info" size="s" color="foregroundMuted" spacingEnd={1} />
                <TextBody as="p" color="foregroundMuted">
                  You’ve reached the maximum number of domains.
                </TextBody>
              </HStack>
            )}
          </HStack>
          <HStack justifyContent="flex-end" gap={2} spacingTop={isPhone ? 5 : 0}>
            <ButtonGroup>
              <Button variant="secondary" onPress={toggle}>
                Cancel
              </Button>
              <Button type="submit" disabled={!isValidForm}>
                Save
              </Button>
            </ButtonGroup>
          </HStack>
        </form>
      </Collapsible>
    </VStack>
  );
}
