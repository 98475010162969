import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';

async function switchOrg(organizationId: string): Promise<boolean> {
  const path = `/organization-management/v1/customers/organizations/${organizationId}/current`;
  const { data } = await APIClientBase.post<boolean>(path, { organizationId });
  return data;
}

const excludedKeys = [QueryKey.internal, QueryKey.workflows, QueryKey.experiments, QueryKey.self];
const keysToInvalidate = Object.values(QueryKey).filter((key) => !excludedKeys.includes(key));

export function useSwitchOrganization() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (orgId: string) => {
      return switchOrg(orgId);
    },
    onSuccess: async () => {
      /** invalidate self prior to other invalidations, order matters */
      await queryClient.invalidateQueries([QueryKey.self]);
      await queryClient.refetchQueries([QueryKey.self]);
      await Promise.all(keysToInvalidate.map(async (key) => queryClient.invalidateQueries([key])));
    },
    meta: {
      errorMsg: `Organization could not be changed`,
    },
  });
}
