import { useCallback, useRef } from 'react';
// taken from https://gist.github.com/reecelucas/2f510e6b8504008deaaa52732202d2da

/**
 * Usage:
 * const [blockScroll, allowScroll] = useScrollBlock();
 */
export function useScrollBlock() {
  const scrollBlocked = useRef(false);
  const { body, documentElement } = document;

  const blockScroll = useCallback(() => {
    if (!body?.style || scrollBlocked.current) {
      return;
    }

    const scrollBarWidth = window.innerWidth - documentElement.clientWidth;
    const bodyPaddingRight =
      parseInt(window.getComputedStyle(body).getPropertyValue('padding-right')) || 0;

    /**
     * 1. Fixes a bug in iOS and desktop Safari whereby setting
     *    `overflow: hidden` on the documentElement/body does not prevent scrolling.
     * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
     *    scroll if an `overflow-x` style is also applied to the body.
     */
    documentElement.style.position = 'relative'; /* [1] */
    documentElement.style.overflow = 'hidden'; /* [2] */
    body.style.position = 'relative'; /* [1] */
    body.style.overflow = 'hidden'; /* [2] */
    body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

    scrollBlocked.current = true;
  }, [body, documentElement.clientWidth, documentElement.style]);

  const allowScroll = useCallback(() => {
    if (!body?.style || !scrollBlocked.current) {
      return;
    }

    documentElement.style.position = '';
    documentElement.style.overflow = '';
    body.style.position = '';
    body.style.overflow = '';
    body.style.paddingRight = '';

    scrollBlocked.current = false;
  }, [body.style, documentElement.style]);

  return { blockScroll, allowScroll };
}
