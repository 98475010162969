import { logError } from ':cloud/init/bugsnag/logging';
import { APIClientBase } from ':cloud/state/Api';

export function generateJsonFile(obj: Record<string, any>, filename?: string) {
  if (typeof obj !== 'object' || !obj) return;
  const fileData = JSON.stringify(obj, null, 3);
  const blob = new Blob([fileData], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = `${filename || 'file'}.json`;
  link.href = url;
  link.click();
}

export function handleDownload(url: string) {
  const a = document.createElement('a');
  a.href = url;
  a.download = url.split('/').pop() as string;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  setTimeout(() => URL.revokeObjectURL(url), 100); // Delay revocation to ensure download starts
}

export async function downloadPDF(path: string): Promise<boolean> {
  try {
    const { data } = await APIClientBase.get(path);
    const byteArray = Uint8Array.from(atob(data.data), (c) => c.charCodeAt(0));
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const downloadURL = URL.createObjectURL(blob);
    handleDownload(downloadURL);
  } catch (error) {
    logError(error, { context: 'downloadPDF' });
    return false;
  }
  return true;
}

export async function downloadCSV(path: string): Promise<boolean> {
  try {
    const { data } = await APIClientBase.get(path);
    const csvData = atob(data.data);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const downloadURL = URL.createObjectURL(blob);
    handleDownload(downloadURL);
  } catch (error) {
    logError(error, { context: 'downloadCSV' });
    return false;
  }
  return true;
}
