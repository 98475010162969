import { Checkbox } from '@cbhq/cds-web/controls';
import { TextBody } from '@cbhq/cds-web/typography';

interface CheckboxWidgetProps {
  schema: any;
  disabled: boolean;
  id: string;
  label?: string;
  onChange: (checked: boolean) => void;
  readonly: boolean;
  required: boolean;
  value: boolean;
}

export function CheckboxWidget({
  disabled,
  id,
  label,
  onChange,
  readonly,
  required,
  value,
  schema,
}: CheckboxWidgetProps) {
  return (
    <>
      <Checkbox
        id={id}
        disabled={disabled || readonly}
        color="primary"
        required={required}
        checked={value}
        onChange={(e) => onChange(e.target.checked)}
      >
        {label}
      </Checkbox>
      {Boolean(schema?.description) && <TextBody as="p">{schema?.description}</TextBody>}
    </>
  );
}
