import { useQuery } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { ClientRecordNonSensitive, GetOAuthClientResponse } from ':cloud/types/service_proto';

async function fetchOAuthClients(organizationId: string): Promise<ClientRecordNonSensitive[]> {
  const path = `/apikeys/v1/organizations/${organizationId}/oauthClients`;
  const { data } = await APIClientBase.get<GetOAuthClientResponse>(path);
  return data.oauthClients.map((client) => client.clientRecordNonSensitive);
}

export function useGetOAuthClients(organizationId = ''): {
  isLoading: boolean;
  clients: ClientRecordNonSensitive[];
} {
  const { isLoading, data } = useQuery({
    queryKey: [QueryKey.oauth, organizationId],
    queryFn: async () => fetchOAuthClients(organizationId),
  });

  return {
    isLoading,
    clients: data || [],
  };
}
