import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { ProtocolKey } from ':cloud/types/ts_types';
import { ClusterHexed } from ':cloud/widgets/clusters/Participate/ClusterHexed';
import { HelpPanel } from ':cloud/widgets/help/HelpPanel';
import { Layout } from ':cloud/widgets/layout';
import { ReinforcedSecurity } from ':cloud/widgets/sidebar/ReinforcedSecurity';

interface CreateClusterSideRailProps {
  protocolKey: ProtocolKey;
}

export function CreateClusterSideRail({ protocolKey }: CreateClusterSideRailProps) {
  const isReviewPane = useLocation().pathname.includes('/review');

  const labels = useMemo(() => ['right rail', 'create', protocolKey], [protocolKey]);

  return (
    <>
      <Layout.ContentWrapper>
        <ClusterHexed protocolKey={protocolKey} />
      </Layout.ContentWrapper>
      <Layout.ContentWrapper>
        <ReinforcedSecurity />
      </Layout.ContentWrapper>
      {!isReviewPane && (
        <Layout.ContentWrapper>
          <HelpPanel labels={labels} />
        </Layout.ContentWrapper>
      )}
    </>
  );
}
