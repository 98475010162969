import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useEdwardsKey } from ':cloud/hooks/featureFlags/useEdwardsKey';
import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { CreatePlatformAPIKeyResponse } from ':cloud/types/service_proto';
import { IPlatformAPIKey } from ':cloud/types/ts_types';

const errorMsg = 'An error occurred creating your API key. Please retry';

async function createKey(
  orgId: string,
  userUuid: string,
  projectId: string,
  nickname: string,
  isEdwardsKeyEnabled: boolean,
  keyType?: string,
  scopes?: string[],
  allowedIps?: string[],
  proofToken?: string,
): Promise<CreatePlatformAPIKeyResponse | undefined> {
  const path = `/apikeys/v1/organizations/${orgId}/apiKeys`;
  const body = {
    parent: `organizations/${orgId}`,
    apiKey: {
      principal: userUuid,
      principalType: 'USER',
      projectId,
      nickname,
      keyType,
      scopes,
      allowedIps,
      cryptographicAlgorithm: isEdwardsKeyEnabled ? 'ED25519' : 'ECDSA',
    },
  };

  const additionalHeaders = proofToken
    ? {
        headers: {
          'second-factor-proof-token': proofToken,
        },
      }
    : {};

  const { data } = await APIClientBase.post<CreatePlatformAPIKeyResponse>(
    path,
    body,
    additionalHeaders,
  );

  return data;
}

type CreateAPIKeyMutate = {
  userUuid: string;
  projectId: string;
  nickname: string;
  keyType?: string;
  scopes?: string[];
  allowedIps?: string[];
  proofToken?: string;
};

export function useCreateApiKey(orgId = '', onSuccess?: (token: IPlatformAPIKey) => void) {
  const queryClient = useQueryClient();
  const isEdwardsKeyEnabled = useEdwardsKey();

  return useMutation({
    mutationFn: async ({
      userUuid,
      projectId,
      nickname,
      keyType,
      scopes,
      allowedIps,
      proofToken,
    }: CreateAPIKeyMutate) =>
      createKey(
        orgId,
        userUuid,
        projectId,
        nickname,
        isEdwardsKeyEnabled,
        keyType,
        scopes,
        allowedIps,
        proofToken,
      ),
    onSuccess: async (newlyCreatedKey) => {
      if (!newlyCreatedKey) return;
      queryClient.setQueryData([QueryKey.apiKeys, newlyCreatedKey.name], newlyCreatedKey);
      await queryClient.invalidateQueries([QueryKey.apiKeys]);
      onSuccess?.(newlyCreatedKey);
    },
    meta: {
      errorMsg,
    },
  });
}
