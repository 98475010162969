import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientCOMSLegacy } from ':cloud/state/Api';
import { RemoveMemberResponse } from ':cloud/types/service_proto';

async function removeInvitedMember(orgId: string, email: string): Promise<RemoveMemberResponse> {
  const path = `organization-management/v1/organizations/${orgId}/invite`;

  const { data } = await APIClientCOMSLegacy.delete<RemoveMemberResponse>(path, {
    data: { email },
  });
  return data;
}
export function useRemoveInvitedMember(orgId = '', memberEmail = '') {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => removeInvitedMember(orgId, memberEmail),
    onSuccess: async () => queryClient.invalidateQueries([QueryKey.organizations]),
    meta: {
      errorMsg: `Remove ${memberEmail} failed`,
      successMsg: `Invite to ${memberEmail} canceled`,
    },
  });
}
