import { useNavigationContext } from ':cloud/contexts/NavigationContext';
import { getProjectProducts } from ':cloud/hooks/useProjectProducts';
import { useGetAccountItems } from ':cloud/queries/BillingQueries/useGetAccountItems';
import { useGetProducts } from ':cloud/queries/BillingQueries/useGetProducts';
import { useGetProjects } from ':cloud/queries/Projects/useGetProjects';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { ProductType } from ':cloud/types/ts_types';
import { defaultDashboardConfig, productCharts } from ':cloud/widgets/monitoring/monitoringConfig';

function pluckDashboardCharts(projectProducts: ProductType[]) {
  if (!projectProducts.length) return [];
  const charts = projectProducts.map((product) => {
    return productCharts[product] ? productCharts[product].charts : [];
  });

  return charts.flat();
}

/**
 * Returns a Dashboard for the project currently being viewed along with range getters and setters.
 * In the future™, this will become useDashboards, which will return a list of dashboards
 * for the current project from the backend.
 */
export function useGetDashboard() {
  const { activeOrg } = useGetUser();
  const { projects } = useGetProjects(activeOrg?.organizationId);
  const { accountItems } = useGetAccountItems(activeOrg?.organizationId);
  const { projectId } = useNavigationContext();
  const project = projects.find((p) => p.id === projectId);
  const { products } = useGetProducts();

  if (!project) return {};

  const projectProducts = getProjectProducts(accountItems, products, project);
  const projectProductTypes = projectProducts.map((p) => p.serviceId);

  const charts = pluckDashboardCharts(projectProductTypes as ProductType[]);
  const dashboard = { ...defaultDashboardConfig, charts };

  return { dashboard };
}
