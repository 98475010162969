import { FormEvent, useMemo, useState } from 'react';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {
  SetupIntent,
  StripeElementsOptions,
  StripeError,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from '@cbhq/cds-web/buttons';
import { Box, VStack } from '@cbhq/cds-web/layout';

import { rgbaToHexA } from ':cloud/brand/colors';
import { useAppState } from ':cloud/contexts/AppStateContext';
import { logError } from ':cloud/init/bugsnag/logging';
import { useGetAccount } from ':cloud/queries/BillingQueries/useGetAccount';
import { useGetPaymentSetupSecret } from ':cloud/queries/BillingQueries/useGetStripeSecret';
import { QueryKey } from ':cloud/queries/types';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { APIClientBase } from ':cloud/state/Api';
import { getStripe } from ':cloud/utils/getStripe';
import { DisclaimerText } from ':cloud/widgets/billing/DisclaimerText';

import { LoadingSpinner } from '../sharedcomponents';

import { getCdsPaletteForStripe } from './utils';

const STRIPE_LOCALE = 'en';

const stripePromise = getStripe();

const PAYMENT_ELEMENT_OPTIONS: StripePaymentElementOptions = {
  terms: { card: 'never' },
  layout: {
    type: 'tabs',
    defaultCollapsed: false,
  },
  wallets: {
    applePay: 'never',
    googlePay: 'never',
  },
  business: {
    name: 'Coinbase Crypto Services, LLC',
  },
};

function getReturnUrl(context: string, returnRoute: string): string {
  const currentAppHost = window.location.origin;
  const contextParam = context ? `?context=${encodeURIComponent(context)}` : '';

  return `${currentAppHost}${returnRoute}${contextParam}`;
}

const customStyle = { gap: 8 };

function SetupForm({
  onSuccess,
  onProcessing,
  onRequiresAction,
  onRequiresPaymentMethod,
  onError,
  context = '',
  onCancel,
  showDisclaimer = true,
  returnRoute,
  submitActionLabel = 'Save',
  cancelActionLabel = 'Cancel',
  actionsInline = false,
  children,
  isLoading,
  parentComponentName,
  clearError,
}: PaymentMethodIntakeProps) {
  const { activeOrg } = useGetUser();
  const { account } = useGetAccount(activeOrg?.organizationId);
  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();
  const [submitError, setSubmitError] = useState();
  const [isIntentSetupLoading, setIsIntentSetupLoading] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    // Don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    // clears previous error
    clearError?.();
    setSubmitError(undefined);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    setIsIntentSetupLoading(true);
    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // eslint-disable-next-line camelcase -- naming set by Stripe
        return_url: getReturnUrl(context, returnRoute),
      },
      // not all payment methods require redirects (see setupIntent switch below)
      redirect: 'if_required',
    });

    if (error) {
      // This point will only be reached if there is an immediate error when confirming the
      // payment.
      // eslint-disable-next-line no-console
      console.log('An error occured validating payment method', error.message);
      logError(error, { context: `${parentComponentName}-PaymentMethodIntake` });
      // card errors are shown in a banner at the top of the form
      // validation errors are shown under each field
      if (error.type === 'card_error' || error.type === 'invalid_request_error') {
        onError?.(error.message, error);
        setSubmitError(submitError);
      }
    } else if (setupIntent) {
      /**
       * When a non-redirect based payment method is successfully confirmed,
       * stripe.confirmPayment will resolve with a {paymentIntent} object.
       *
       * Otherwise they will flow through the redirect process & be sent to the
       * passed-in `return_url`.
       */
      switch (setupIntent.status) {
        case 'succeeded':
          onSuccess?.(setupIntent);
          void APIClientBase.post(`/billing/v1/accounts/${account?.id}:saveDefaultPaymentMethod`, {
            // eslint-disable-next-line camelcase -- naming set by Stripe
            stripe_payment_method: setupIntent.payment_method,
          });
          await queryClient.invalidateQueries([
            QueryKey.billing,
            'payment-setup-secret',
            activeOrg?.organizationId,
          ]);
          break;
        case 'processing':
          onProcessing?.(setupIntent);
          break;
        case 'requires_action':
          onRequiresAction?.(setupIntent);
          break;
        case 'requires_payment_method':
          onRequiresPaymentMethod?.(setupIntent);
          break;
        default:
          // eslint-disable-next-line no-console
          console.error('Unhandled setupIntent status', setupIntent.status);
      }
    } else {
      // Customer will be redirected to `return_url`. For some payment
      // methods like iDEAL, customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      // So let this flow through.
    }
    setIsIntentSetupLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      {children || (
        <>
          <VStack gap={3} minHeight={250}>
            <PaymentElement options={PAYMENT_ELEMENT_OPTIONS} />
          </VStack>
          <VStack gap={2} spacingTop={5}>
            {showDisclaimer && <DisclaimerText />}
            <Box flexDirection={actionsInline ? 'row-reverse' : 'column'} style={customStyle}>
              <Button type="submit" disabled={!stripe} loading={isLoading || isIntentSetupLoading}>
                {submitActionLabel}
              </Button>
              {onCancel && (
                <Button variant="secondary" onPress={onCancel}>
                  {cancelActionLabel}
                </Button>
              )}
            </Box>
          </VStack>
        </>
      )}
    </form>
  );
}

type StripeCallbackHandlerProps = {
  /** Occurs when the saving the payment method succeeds. */
  onSuccess?: (setupIntent: SetupIntent) => void;

  /**
   * Occurs when the payment method is loading or has delayed notification.
   *
   * Next step: Wait for the payment method to save successfully or fail.
   */
  onProcessing?: (setupIntent: SetupIntent) => void;

  /**
   * Occurs when the payment method is rejected/user has no payment method on file.
   *
   * Next step: Ask the user to try again, or try another payment method.
   */
  onRequiresPaymentMethod?: (setupIntent: SetupIntent) => void;

  /**
   * Occurs when the payment method requires additional action, e.g. ACH microdeposits
   * which requires the user to wait for microdeposits to show up in their bank account
   * and then verify the amounts with Stripe.
   *
   * Next step: Ask the user to complete the required action.
   */
  onRequiresAction?: (setupIntent: SetupIntent) => void;
  onError?: (errorMessage: string | undefined, rawError: StripeError) => void;
};

type PaymentMethodIntakeProps = {
  onCancel?: React.MouseEventHandler<HTMLInputElement>;
  /** Used to pass state that's eventually returned through the redirect process. */
  context?: string;
  /** If users are redirected away from the app for Stripe payment purposes
   * (e.g. bank account verification), this is where they will be brought back
   * to afterwards, along with whatever `context` is passed along.
   * */
  returnRoute: string;
  showDisclaimer?: boolean;
  submitActionLabel?: string;
  cancelActionLabel?: string;
  children?: React.ReactNode;
  isLoading?: boolean;
  /** If the CTAs should be stacked or inline. */
  actionsInline?: boolean;
  /** For debugging purposes. */
  parentComponentName: string;
  clearError?: () => void;
} & StripeCallbackHandlerProps;

export function PaymentMethodIntake(props: PaymentMethodIntakeProps) {
  const { activeOrg } = useGetUser();
  const {
    secret,
    error: secretError,
    isLoading: isLoadingStripeSecret,
  } = useGetPaymentSetupSecret(activeOrg?.organizationId);

  const { isDarkMode } = useAppState();
  const spectrum = useMemo(() => (isDarkMode ? 'dark' : 'light'), [isDarkMode]);

  const cdsAliasPalette = useMemo(() => {
    return getCdsPaletteForStripe(spectrum);
  }, [spectrum]);

  const cdsInteractablePalette = useMemo(() => {
    return {
      interactableHoveredBackground: isDarkMode
        ? rgbaToHexA('rgb(15, 16, 18)')
        : rgbaToHexA('rgb(250, 250, 250)'),
    };
  }, [isDarkMode]);

  const cdsPalette: Record<string, string> = { ...cdsAliasPalette, ...cdsInteractablePalette };

  const inputBoxShadow = useMemo(() => `0 0 0 1px ${cdsPalette.primary}`, [cdsPalette.primary]);

  const options: StripeElementsOptions = useMemo(
    () => ({
      clientSecret: secret,
      locale: STRIPE_LOCALE,
      appearance: {
        theme: 'stripe',
        variables: {
          borderRadius: '8px',
          colorBackground: cdsPalette.background,
          colorDanger: cdsPalette.negative,
          colorPrimary: cdsPalette.primary,
          colorText: cdsPalette.foreground,
          fontFamily:
            'CoinbaseSans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol',
          fontWeightBold: '500',
          fontWeightNormal: '400',
          spacingGridColumn: '24px',
          spacingGridRow: '24px',
          tabIconColor: cdsPalette.foreground,
          tabIconSelectedColor: cdsPalette.primary,
          logoColor: isDarkMode ? 'dark' : 'light',
          blockLogoColor: isDarkMode ? 'dark' : 'light',
          tabLogoColor: isDarkMode ? 'dark' : 'light',
          tabLogoSelectedColor: isDarkMode ? 'dark' : 'light',
        },
        rules: {
          '.Label': {
            fontSize: '14px',
            fontWeight: '500',
            color: cdsPalette.foreground,
          },
          '.Error': {
            fontSize: '14px',
            color: cdsPalette.negative,
          },
          '.Input': {
            borderColor: cdsPalette.lineHeavy,
            fontSize: '14px',
            backgroundColor: cdsPalette.background,
            color: cdsPalette.foreground,
            transition: 'box-shadow .2s ease-in-out',
          },
          '.Input:focus': {
            borderColor: cdsPalette.primary,
            boxShadow: inputBoxShadow,
            outline: cdsPalette.primary,
          },
          '.Input:hover': {
            backgroundColor: cdsPalette.interactableHoveredBackground,
          },
          '.Input--invalid': {
            borderColor: cdsPalette.negative,
            boxShadow: `0 0 0 0px ${cdsPalette.negative}`,
            color: cdsPalette.negative,
          },
          '.Tab': {
            backgroundColor: cdsPalette.background,
            borderColor: cdsPalette.line,
            borderRadius: '16px',
            boxShadow: 'none',
            color: cdsPalette.foreground,
            transition: 'box-shadow .2s ease-in-out',
          },
          '.Tab:hover': {
            backgroundColor: cdsPalette.interactableHoveredBackground,
            borderColor: cdsPalette.line,
            color: cdsPalette.foreground,
          },
          '.Tab:focus': {
            backgroundColor: cdsPalette.background,
            borderRadius: '16px',
            boxShadow: 'none',
            color: cdsPalette.foreground,
          },
          '.Tab--selected': {
            boxShadow: inputBoxShadow,
          },
          '.Tab--selected:hover': {
            backgroundColor: cdsPalette.interactableHoveredBackground,
          },
          '.Tab--selected:focus': {
            borderColor: cdsPalette.primary,
            boxShadow: inputBoxShadow,
            color: cdsPalette.primary,
          },
          '.TabIcon--selected': {
            fill: cdsPalette.primary,
          },
          '.PickerItem': {
            backgroundColor: cdsPalette.background,
            borderColor: cdsPalette.line,
            boxShadow: 'none',
            color: cdsPalette.foreground,
          },
        },
      },
      fonts: [],
    }),
    [
      secret,
      isDarkMode,
      cdsPalette.background,
      cdsPalette.foreground,
      cdsPalette.line,
      cdsPalette.negative,
      cdsPalette.primary,
      cdsPalette.lineHeavy,
      cdsPalette.interactableHoveredBackground,
      inputBoxShadow,
    ],
  );

  if (isLoadingStripeSecret) {
    return <LoadingSpinner />;
  }

  if (secretError) {
    return <div>An error occurred setting up payment intake. Please try again later.</div>;
  }

  const { children, ...paymentProps } = props;
  return (
    <Elements stripe={stripePromise} options={options}>
      <SetupForm {...paymentProps}>{children}</SetupForm>
    </Elements>
  );
}
