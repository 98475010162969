import { useQuery } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { GetAccountCreditsResponse } from ':cloud/types/service_proto';
import { AccountCredit } from ':cloud/types/ts_types';

async function fetchAccountCredits(orgId?: string): Promise<GetAccountCreditsResponse> {
  const { data } = await APIClientBase.get<GetAccountCreditsResponse>(
    `/billing/v1/accounts/${orgId}/credits`,
  );
  return data;
}

export function useGetAccountCredits(orgId?: string): {
  isLoading: boolean;
  accountCredits: AccountCredit[];
} {
  const { isLoading, data } = useQuery({
    queryKey: [QueryKey.accountCredits, orgId],
    queryFn: async () => fetchAccountCredits(orgId),
    staleTime: Infinity,
    enabled: !!orgId,
  });

  return {
    isLoading,
    accountCredits: data?.accountCredits || [],
  };
}
