import { Button } from '@cbhq/cds-web/buttons';
import { Icon } from '@cbhq/cds-web/icons';
import { VStack } from '@cbhq/cds-web/layout';
import { TextHeadline } from '@cbhq/cds-web/typography';

type ChartErrorFallbackProps = {
  resetErrorBoundary?: () => void;
};

export function ChartErrorFallback({ resetErrorBoundary }: ChartErrorFallbackProps) {
  return (
    <VStack height="100vh" justifyContent="center" alignItems="center">
      <VStack gap={3} alignItems="center">
        <Icon name="warning" size="l" color="negative" />
        <TextHeadline align="center" as="h3">
          Can&apos;t load data
        </TextHeadline>
        <Button variant="secondary" onPress={resetErrorBoundary}>
          Retry
        </Button>
      </VStack>
    </VStack>
  );
}
