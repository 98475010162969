import { useGetPaymentMethod } from ':cloud/queries/BillingQueries/useGetPaymentMethod';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';

function useHasPaymentMethod(): boolean {
  const { activeOrg } = useGetUser();
  const orgId = activeOrg?.organizationId;
  const { paymentMethod } = useGetPaymentMethod(orgId);

  return !!paymentMethod?.type;
}

export default useHasPaymentMethod;
