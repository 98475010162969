import { BoxProps, GridColumn, VStack } from '@cbhq/cds-web/layout';

import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';

export type MainContainerProps = BoxProps & {
  className?: string;
  fullWidth?: boolean;
};

export function MainContainer({
  children,
  className = '',
  fullWidth = false,
  ...props
}: MainContainerProps) {
  const { isPhone, isDesktop } = useSimpleBreakpoints();

  const colSpan = !isDesktop || fullWidth ? 12 : 8;

  return (
    <GridColumn colSpan={colSpan} className={className}>
      <VStack
        width="100%"
        spacingBottom={5}
        spacingTop={props?.spacingTop ? props?.spacingTop : 5}
        spacingHorizontal={isPhone ? 5 : 7}
        {...props}
      >
        {children}
      </VStack>
    </GridColumn>
  );
}
