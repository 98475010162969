import { useEffect } from 'react';

import { useTokens } from ':cloud/contexts/LegacyTokensContext';

function useLoadedLegacyAPITokens() {
  const { APITokens, isLoadingTokens, loadAllAPITokens, tokenNotifier } = useTokens();

  useEffect(() => {
    if (
      APITokens === undefined &&
      !isLoadingTokens &&
      (!tokenNotifier || tokenNotifier.type !== 'error')
    ) {
      void loadAllAPITokens();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [APITokens, isLoadingTokens, tokenNotifier]);

  return APITokens;
}

export default useLoadedLegacyAPITokens;
