import { useQuery } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { APIClientRM } from ':cloud/state/Api';
import { GetResourceResponse } from ':cloud/types/service_proto';
import { ICluster } from ':cloud/types/ts_types';

async function fetchResources(fetchAll = false): Promise<GetResourceResponse> {
  const path = fetchAll ? '/v1/resources?all=true' : '/v1/resources';
  const { data } = await APIClientRM.get<GetResourceResponse>(path);
  return data;
}

function generateResourcesQuery(orgId) {
  return {
    queryKey: [QueryKey.resources, orgId],
    queryFn: async () => fetchResources(false),
    staleTime: Infinity,
    retry: 3,
    enabled: !!orgId,
  };
}

function generateResourcesResponse(
  data: ICluster[] | undefined,
  predicateFn: (n: ICluster) => boolean,
) {
  if (!data) return [];
  return (
    data
      .filter(predicateFn)
      // alphabetize by protocol
      .sort((a: ICluster, b: ICluster) => a.type.localeCompare(b.type)) || []
  );
}

export function useGetResources(predicateFn: (n: ICluster) => boolean = () => true): {
  isLoading: boolean;
  resources: ICluster[];
} {
  const { activeOrg } = useGetUser();
  const { isLoading, data } = useQuery(generateResourcesQuery(activeOrg?.organizationId));

  const resources = generateResourcesResponse(data?.resources, predicateFn);

  return {
    resources,
    isLoading,
  };
}
