import { useMemo } from 'react';
import { Banner } from '@cbhq/cds-web/banner/Banner';
import { Button, ButtonGroup } from '@cbhq/cds-web/buttons';
import { Grid, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextDisplay3, TextTitle4 } from '@cbhq/cds-web/typography';

import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { CLOUD_FORUM, COINBASE_APP_DOCS_LINK } from ':cloud/utils/links';
import { onExternalLinkPress } from ':cloud/utils/onExternalLinkPress';
import { Layout } from ':cloud/widgets/layout';
import { FULL_PAGE_MAX_WIDTH, MAIN_CONTAINER_MIN_WIDTH } from ':cloud/widgets/product/constants';
import { KeyFeatureItem, KeyFeaturesSection } from ':cloud/widgets/product/KeyFeaturesSection';

const keyFeatures: KeyFeatureItem[] = [
  {
    iconName: 'transferSend',
    iconType: 'pictogram',
    title: 'Transfer',
    description: 'Deposit and withdraw using your payment method of choice (e.g. bank account).',
  },
  {
    iconName: 'walletDeposit',
    iconType: 'pictogram',
    title: 'Track',
    description:
      'Track credits and debits in your account. Includes trade fills, buy, sell, fiat deposits, and withdrawals.',
  },
  {
    iconName: 'fast',
    iconType: 'pictogram',
    title: 'Get notified',
    description: 'Receiving notifications when payments arrive.',
  },
];

export function CoinbaseAppLanding() {
  const { isDesktop } = useSimpleBreakpoints();

  const headerResponsiveTemplateColumns = useMemo(() => {
    return isDesktop ? '2fr 1fr' : '1fr';
  }, [isDesktop]);

  return (
    <Layout enableMaxWidth={false}>
      <Layout.MainContainer minWidth={MAIN_CONTAINER_MIN_WIDTH} spacingHorizontal={0} fullWidth>
        <VStack>
          <VStack>
            <VStack
              flexGrow={1}
              width="100%"
              maxWidth={FULL_PAGE_MAX_WIDTH}
              spacingHorizontal={9}
              spacingBottom={9}
            >
              <Banner
                title="Sign in with Coinbase is now Coinbase App"
                variant="informational"
                startIcon="info"
                showDismiss
              >
                <div />
              </Banner>
            </VStack>
          </VStack>
          <HStack borderedBottom>
            <Grid
              maxWidth={FULL_PAGE_MAX_WIDTH}
              spacing={9}
              gap={4}
              templateColumns={headerResponsiveTemplateColumns}
            >
              <VStack justifyContent="center">
                <TextDisplay3 as="h1">
                  SIWC is Coinbase App. New name, same great functionality.
                </TextDisplay3>
                <TextTitle4 as="h2" color="foregroundMuted" spacingTop={1} spacingBottom={7}>
                  A secure, fast, and convenient way for individuals and developers to transfer and
                  track their activity on Coinbase.
                </TextTitle4>

                <ButtonGroup>
                  <Button
                    variant="primary"
                    endIcon="diagonalUpArrow"
                    accessibilityLabel="View docs + opens in new window"
                    onPress={onExternalLinkPress(COINBASE_APP_DOCS_LINK)}
                  >
                    Get started
                  </Button>
                  <Button
                    variant="secondary"
                    endIcon="diagonalUpArrow"
                    accessibilityLabel="View Discord + opens in new window"
                    onPress={onExternalLinkPress(CLOUD_FORUM)}
                  >
                    Discord channel
                  </Button>
                </ButtonGroup>
              </VStack>
            </Grid>
          </HStack>
          <KeyFeaturesSection features={keyFeatures} />
        </VStack>
      </Layout.MainContainer>
    </Layout>
  );
}
