import { Pictogram } from '@cbhq/cds-web/illustrations';
import { VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

export default function OAuthTableEmptyState() {
  return (
    <VStack
      testID="api-key-table-empty-state"
      justifyContent="center"
      alignItems="center"
      gap={1}
      width="100%"
      spacingVertical={10}
    >
      <VStack width={358} gap={5}>
        <VStack gap={3} alignItems="center">
          <Pictogram name="priceTracking" scaleMultiplier={1} />
          <TextHeadline as="p" align="center">
            No OAuth clients found
          </TextHeadline>
          <TextBody as="p" color="foregroundMuted" align="center">
            It takes less than a minute to create one.
          </TextBody>
        </VStack>
      </VStack>
    </VStack>
  );
}
