import { useCallback, useState } from 'react';
import { Button } from '@cbhq/cds-web/buttons';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { Link, TextTitle1, TextTitle4 } from '@cbhq/cds-web/typography';

import { ReactComponent as CoinbaseLogo } from ':cloud/assets/Brand/coinbase-logo.svg';
import { logError } from ':cloud/init/bugsnag/logging';
import {
  logAttemptMetric,
  logSuccessMetric,
  useLogViewMetricOnMount,
} from ':cloud/init/clientAnalytics/metricsLogging';
import { APIClientBase } from ':cloud/state/Api';
import { CLOUD_HELP, CLOUD_TOS } from ':cloud/utils/links';
import { openUrlInNewWindow } from ':cloud/utils/openUrlInNewWindow';

type TermsOfServiceProps = {
  onSuccess: () => void;
  onError: () => void;
};

const ACCEPT_TOS_PATH = `/organization-management-session/v1/accept-tos`;

export function CloudTOSFlow({ onSuccess, onError }: TermsOfServiceProps) {
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  useLogViewMetricOnMount('accept_tos');

  const acceptTOS = useCallback(async () => {
    logAttemptMetric('accept_tos');

    setLoading(true);

    try {
      const res: Response = await APIClientBase.post(ACCEPT_TOS_PATH);

      if (res.status === 200) {
        logSuccessMetric('accept_tos');
        onSuccess();
        return;
      }

      logError(new Error(`Unexpected ${res.status} from the /accept-tos endpoint`), {
        context: 'accept_tos',
      });

      toast.show('There was an error accepting the Terms of Service. Please try again.', {
        variant: 'negative',
        action: { label: 'Help', onPress: () => openUrlInNewWindow(CLOUD_HELP) },
      });
    } catch (e) {
      logError(e, { context: 'accept_tos' });
      onError();
    } finally {
      setLoading(false);
    }
  }, [onError, onSuccess, toast]);

  return (
    <VStack height="100vh" justifyContent="center" alignItems="center" spacing={4} background>
      <VStack
        bordered
        borderRadius="rounded"
        spacingVertical={8}
        spacingHorizontal={6}
        minWidth={360}
        maxWidth={450}
      >
        <Box spacingBottom={10}>
          <CoinbaseLogo />
        </Box>
        <TextTitle1 as="h1" spacingBottom={1}>
          Coinbase Developer Platform Terms of Service
        </TextTitle1>
        <TextTitle4 as="p" color="foregroundMuted" spacingBottom={6}>
          Please accept the updated Terms of Service, Privacy Policy and Cookie Policy{' '}
          <Link to={CLOUD_TOS} underline={false} openInNewWindow>
            here
          </Link>
          .
        </TextTitle4>
        <Button onPress={acceptTOS} loading={loading}>
          Accept terms
        </Button>
      </VStack>
    </VStack>
  );
}
