import { useMemo } from 'react';

import { useGetProducts } from ':cloud/queries/BillingQueries/useGetProducts';
import { ProductVariant } from ':cloud/types/ts_types';

/**
 * Get the product variants for a given service ID. e.g. 'exchange'
 */
export function useGetProductVariants(serviceId: string): {
  isLoading: boolean;
  productVariants: ProductVariant[];
} {
  const { products, isLoading } = useGetProducts();
  const currentProduct = useMemo(
    () => products.find((product) => product.serviceId === serviceId),
    [products, serviceId],
  );
  const productVariants = useMemo(() => currentProduct?.variants || [], [currentProduct]);

  return {
    isLoading,
    productVariants: productVariants || [],
  };
}
