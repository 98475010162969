import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextLabel2, TextLegal } from '@cbhq/cds-web/typography';

import { Eth2Pod } from ':cloud/types/ts_types';
import { getProviderIconSrc } from ':cloud/utils/assets';

interface ProviderRegionHeadlineProps {
  title?: string;
  pod: Eth2Pod;
}

export function ProviderRegionHeadline({ title = 'Client', pod }: ProviderRegionHeadlineProps) {
  return (
    <HStack justifyContent="space-between" alignItems="baseline">
      <TextLabel2 as="p" spacingBottom={3}>
        {title}
      </TextLabel2>
      <VStack>
        <HStack justifyContent="space-between" alignItems="center" spacingBottom={1}>
          <Box spacingHorizontal={3}>
            <TextLegal as="p" align="end">
              {pod.region}
            </TextLegal>
          </Box>
          <Box
            display="inline-block"
            borderRadius="rounded"
            bordered
            borderColor="lineHeavy"
            spacing={1}
            width={40}
            height={40}
          >
            <img
              src={getProviderIconSrc(pod.kubernetes_cluster_id)}
              alt={pod.kubernetes_cluster_id}
              width="100%"
            />
          </Box>
        </HStack>
      </VStack>
    </HStack>
  );
}
