import { palette as CDSPalette } from '@cbhq/cds-web/tokens';

import { colors } from ':cloud/brand/colors';

export const lightTheme = {
  hljs: {
    display: 'block',
    position: 'relative',
    overflowX: 'auto',
    background: colors.gray80,
    lineHeight: 1.7,
    margin: 0,
    padding: 12,
    color: CDSPalette.background,
  },
};

export const darkTheme = {
  hljs: {
    display: 'block',
    position: 'relative',
    overflowX: 'auto',
    background: colors.gray80,
    lineHeight: 1.7,
    margin: 0,
    padding: 12,
    color: CDSPalette.background,
  },
  'hljs-keyword': {
    color: colors.teal15,
  },
  'hljs-operator': {
    color: colors.teal15,
  },
  'hljs-pattern-match': {
    color: colors.teal15,
  },
  'hljs-pattern-match .hljs-constructor': {
    color: '#61aeee',
  },
  'hljs-function': {
    color: '#61aeee',
  },
  'hljs-function .hljs-params': {
    color: '#A6E22E',
  },
  'hljs-function .hljs-params .hljs-typing': {
    color: '#FD971F',
  },
  'hljs-module-access .hljs-module': {
    color: '#7e57c2',
  },
  'hljs-constructor': {
    color: '#e2b93d',
  },
  'hljs-constructor .hljs-string': {
    color: '#9CCC65',
  },
  'hljs-comment': {
    color: colors.pink20,
    fontStyle: 'italic',
  },
  'hljs-quote': {
    color: colors.pink20,
    fontStyle: 'italic',
  },
  'hljs-doctag': {
    color: '#c678dd',
  },
  'hljs-formula': {
    color: '#c678dd',
  },
  'hljs-section': {
    color: '#e06c75',
  },
  'hljs-name': {
    color: '#e06c75',
  },
  'hljs-selector-tag': {
    color: '#e06c75',
  },
  'hljs-deletion': {
    color: '#e06c75',
  },
  'hljs-subst': {
    color: '#e06c75',
  },
  'hljs-literal': {
    color: '#56b6c2',
  },
  'hljs-string': {
    color: colors.green20,
  },
  'hljs-regexp': {
    color: colors.green20,
  },
  'hljs-addition': {
    color: colors.green20,
  },
  'hljs-attribute': {
    color: colors.green20,
  },
  'hljs-meta-string': {
    color: colors.green20,
  },
  'hljs-built_in': {
    color: colors.yellow30,
  },
  'hljs-class .hljs-title': {
    color: colors.yellow30,
  },
  'hljs-attr': {
    color: CDSPalette.background,
  },
  'hljs-variable': {
    color: colors.orange15,
  },
  'hljs-template-variable': {
    color: colors.orange15,
  },
  'hljs-type': {
    color: colors.orange15,
  },
  'hljs-selector-class': {
    color: colors.orange15,
  },
  'hljs-selector-attr': {
    color: colors.orange15,
  },
  'hljs-selector-pseudo': {
    color: colors.orange15,
  },
  'hljs-number': {
    color: colors.orange15,
  },
  'hljs-symbol': {
    color: '#61aeee',
  },
  'hljs-bullet': {
    color: '#61aeee',
  },
  'hljs-link': {
    color: '#61aeee',
    textDecoration: 'underline',
  },
  'hljs-meta': {
    color: '#61aeee',
  },
  'hljs-selector-id': {
    color: '#61aeee',
  },
  'hljs-title': {
    color: '#61aeee',
  },
  'hljs-emphasis': {
    fontStyle: 'italic',
  },
  'hljs-strong': {
    fontWeight: 'bold',
  },
};
