import { useCallback, useState } from 'react';
import { noop } from '@cbhq/cds-utils';
import { Button } from '@cbhq/cds-web/buttons';
import { ListCell } from '@cbhq/cds-web/cells';
import { TextInput } from '@cbhq/cds-web/controls';
import { Icon } from '@cbhq/cds-web/icons';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { Modal, ModalBody, ModalFooter, ModalHeader, Tooltip } from '@cbhq/cds-web/overlays';
import { TextHeadline, TextLabel1 } from '@cbhq/cds-web/typography';

import { isValidURI } from ':cloud/pages/OAuthCreateClient';
import { useUpdateWebhook } from ':cloud/queries/WebhookQueries/useUpdateWebhook';
import { CoinbaseWebhook } from ':cloud/types/ts_types';
import { addressTooltipContent } from ':cloud/widgets/webhooks/CoinbaseWebhookCreateModal';

import { getEventType, getNetwork, validateEthereumAddress } from '../helpers';

type CoinbaseWebhookEditFieldsModalProps = {
  webhook: CoinbaseWebhook;
  organizationId: string;
  projectId: string;
  onRequestClose: () => void;
  onSubmit: (updatedWebhook: CoinbaseWebhook) => void;
};

export function CoinbaseWebhookEditFieldsModal({
  webhook,
  organizationId,
  projectId,
  onRequestClose,
  onSubmit,
}: CoinbaseWebhookEditFieldsModalProps) {
  const [webhookURL, setWebhookURL] = useState(webhook.notificationUri);
  const [contractAddress, setContractAddress] = useState(webhook.eventFilters[0].contractAddress);
  const [fromAddress, setFromAddress] = useState(webhook.eventFilters[0].fromAddress);
  const [toAddress, setToAddress] = useState(webhook.eventFilters[0].toAddress);
  const [selectedNetwork] = useState(webhook.network);
  const [selectedEvent] = useState(webhook.eventType);
  const [urlError, setUrlError] = useState('');
  const [contractAddressError, setContractAddressError] = useState('');
  const [fromAddressError, setFromAddressError] = useState('');
  const [toAddressError, setToAddressError] = useState('');

  const updateWebhookMutation = useUpdateWebhook(onSubmit);

  const validateUrl = (url: string) => {
    if (url.length > 120) {
      setUrlError('URL must be less than 120 characters');
      return;
    }

    if (!isValidURI(url)) {
      setUrlError('Please enter a valid URL');
    } else {
      setUrlError('');
    }
  };

  const handleOnChangeURL = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const url = event.target.value.trim();
    setWebhookURL(url);
    validateUrl(url);
  }, []);

  const handleOnChangeContractAddress = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const address = event.target.value.trim();
      setContractAddress(address);
      if (address && !validateEthereumAddress(address)) {
        setContractAddressError(
          'Please enter a valid Ethereum address (40 hexadecimal characters starting with 0x)',
        );
      } else {
        setContractAddressError('');
      }
    },
    [],
  );

  const handleOnChangeFromAddress = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const address = event.target.value.trim();
    setFromAddress(address);
    if (address && !validateEthereumAddress(address)) {
      setFromAddressError(
        'Please enter a valid Ethereum address (40 hexadecimal characters starting with 0x)',
      );
    } else {
      setFromAddressError('');
    }
  }, []);

  const handleOnChangeToAddress = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const address = event.target.value.trim();
    setToAddress(address);
    if (address && !validateEthereumAddress(address)) {
      setToAddressError(
        'Please enter a valid Ethereum address (40 hexadecimal characters starting with 0x)',
      );
    } else {
      setToAddressError('');
    }
  }, []);

  const handleSubmit = useCallback(() => {
    validateUrl(webhookURL);

    if (urlError) {
      return;
    }

    const updatedWebhook: CoinbaseWebhook = {
      ...webhook,
      network: selectedNetwork,
      eventType: selectedEvent,
      notificationUri: webhookURL,
      eventFilters: [
        {
          contractAddress,
          fromAddress,
          toAddress,
        },
      ],
    };

    updateWebhookMutation.mutate({
      orgId: organizationId,
      projectId,
      webhookId: webhook.uuid?.hex || '',
      webhook: updatedWebhook,
    });
  }, [
    webhookURL,
    urlError,
    webhook,
    selectedNetwork,
    selectedEvent,
    contractAddress,
    fromAddress,
    toAddress,
    updateWebhookMutation,
    organizationId,
    projectId,
  ]);

  return (
    <Modal onRequestClose={onRequestClose || noop} visible width={480}>
      <ModalHeader title="Edit Webhook Details" />
      <ModalBody>
        <VStack gap={2}>
          <TextInput
            label="Webhook URL"
            required
            placeholder="http://example.com/"
            value={webhookURL}
            onChange={handleOnChangeURL}
            helperText={urlError}
            variant={urlError ? 'negative' : undefined}
          />
          <ListCell description="Network Type" detail={getNetwork(webhook.network)} />
          <ListCell description="Event Type" detail={getEventType(webhook.eventType)} />
          <HStack alignItems="center" gap={0.5}>
            <TextHeadline as="h4">Addresses</TextHeadline>
            <Tooltip content={addressTooltipContent}>
              <Icon name="info" size="s" color="foregroundMuted" />
            </Tooltip>
          </HStack>
          <TextLabel1 as="p">
            At least one of the following addresses is required to create a webhook
          </TextLabel1>
          <TextInput
            label="Contract Address"
            required
            placeholder="0xdeadbeef"
            value={contractAddress}
            onChange={handleOnChangeContractAddress}
            helperText={contractAddressError}
            variant={contractAddressError ? 'negative' : undefined}
          />
          <TextInput
            label="From Address"
            required
            placeholder="0xdeadbeef"
            value={fromAddress}
            onChange={handleOnChangeFromAddress}
            helperText={fromAddressError}
            variant={fromAddressError ? 'negative' : undefined}
          />
          <TextInput
            label="To Address"
            required
            placeholder="0xdeadbeef"
            value={toAddress}
            onChange={handleOnChangeToAddress}
            helperText={toAddressError}
            variant={toAddressError ? 'negative' : undefined}
          />
        </VStack>
      </ModalBody>
      <ModalFooter
        secondaryAction={
          <Button variant="secondary" onPress={onRequestClose}>
            Cancel
          </Button>
        }
        primaryAction={
          <Button
            type="submit"
            onPress={handleSubmit}
            testID="submit"
            disabled={
              !webhookURL ||
              (!contractAddress && !fromAddress && !toAddress) ||
              updateWebhookMutation.isLoading
            }
          >
            Save
          </Button>
        }
      />
    </Modal>
  );
}
