import { useCallback, useMemo, useState } from 'react';
import { useToggler } from '@cbhq/cds-common/hooks/useToggler';
import { useAlert } from '@cbhq/cds-common/overlays/useAlert';
import { VStack } from '@cbhq/cds-web/layout';
import { Alert } from '@cbhq/cds-web/overlays';
import { useToast } from '@cbhq/cds-web/overlays/useToast';

import useApiKeysForUser from ':cloud/hooks/useApiKeysForUser';
import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { EventName, logClick, useLogViewOnMount } from ':cloud/init/clientAnalytics/logging';
import { logSuccessMetric } from ':cloud/init/clientAnalytics/metricsLogging';
import { useCreateApiKey } from ':cloud/queries/ApiKeyQueries/useCreateApiKey';
import { useGetApiKeys } from ':cloud/queries/ApiKeyQueries/useGetApiKeys';
import { useUpdateApiKey } from ':cloud/queries/ApiKeyQueries/useUpdateApiKey';
import { useGetProjects } from ':cloud/queries/Projects/useGetProjects';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { CoinbaseAPIKey, EditCoinbaseAPIKey, IPlatformAPIKey } from ':cloud/types/ts_types';
import { APIKeyCompleteTwoFactorModal } from ':cloud/widgets/access/api/APIKeyCompleteTwoFactorModal';
import { APIKeyDeleteModal } from ':cloud/widgets/access/api/APIKeyDeleteModal';
import { APIKeyDetailModal } from ':cloud/widgets/access/api/APIKeyDetailModal';
import { APIKeySuccessModal } from ':cloud/widgets/access/api/APIKeySuccessModal';
import { APIKeyTwoFactorModal } from ':cloud/widgets/access/api/APIKeyTwoFactorModal';
import { CoinbaseAPIKeyCreateModal } from ':cloud/widgets/access/api/CoinbaseAPIKeyCreateModal';
import { CoinbaseAPIKeyEditModal } from ':cloud/widgets/access/api/CoinbaseAPIKeyEditModal';
import { CoinbaseAPIKeyTable } from ':cloud/widgets/access/api/CoinbaseAPIKeyTable';
import { MobileCoinbaseAPIKeyTable } from ':cloud/widgets/access/api/MobileCoinbaseAPIKeyTable';
import { API_KEY_PER_USER_LIMIT } from ':cloud/widgets/access/constants';
import { getKeyId } from ':cloud/widgets/access/helpers';
import { Layout } from ':cloud/widgets/layout';
import { MAIN_CONTAINER_MIN_WIDTH } from ':cloud/widgets/product/constants';
import { CloudErrorBoundary } from ':cloud/widgets/sharedcomponents';

function APIKeysContent(): JSX.Element {
  useLogViewOnMount(EventName.api_keys_page_view);

  const toast = useToast();
  const { isPhone } = useSimpleBreakpoints();
  const alert = useAlert();
  const [createModalVisible, { toggleOn: toggleCreateModalOn, toggleOff: toggleCreateModalOff }] =
    useToggler(false);
  const [
    snippetModalVisible,
    { toggleOn: toggleSnippetModalOn, toggleOff: toggleSnippetModalOff },
  ] = useToggler(false);
  const [deleteModalVisible, { toggleOn: toggleDeleteModalOn, toggleOff: toggleDeleteModalOff }] =
    useToggler(false);
  const [editModalVisible, { toggleOn: toggleEditModalOn, toggleOff: toggleEditModalOff }] =
    useToggler(false);

  const [selectedKey, setSelectedKey] = useState();
  const [createdKey, setCreatedKey] = useState<IPlatformAPIKey | undefined>();
  const {
    activeOrg,
    user: { userUuid },
  } = useGetUser();
  const { projects } = useGetProjects(activeOrg?.organizationId);
  const selfKeys = useApiKeysForUser();

  const createApiKeyMutation = useCreateApiKey(activeOrg?.organizationId);
  const [twoFactorModalVisible, { toggleOn: twoFactorModalOn, toggleOff: twoFactorModalOff }] =
    useToggler();
  const [
    completeTwoFactorModalVisible,
    { toggleOn: completeTwoFactorModalOn, toggleOff: completeTwoFactorModalOff },
  ] = useToggler(false);
  const [
    apiKeyDetailModalVisible,
    { toggleOn: apiKeyDetailModalOn, toggleOff: apiKeyDetailModalOff },
  ] = useToggler(false);

  const [detailAPIKeyId, setDetailAPIKeyId] = useState('');
  const editKeyMutation = useUpdateApiKey(activeOrg?.organizationId);

  const [coinbaseAPIKey, setCoinbaseAPIKey] = useState<CoinbaseAPIKey>();
  const [editedCoinbaseAPIKey, setEditedCoinbaseAPIKey] = useState<EditCoinbaseAPIKey>();

  const createAPIKeyWithoutTwoFA = useCallback(
    (apiKey: CoinbaseAPIKey) => {
      if (apiKey?.projectId) {
        createApiKeyMutation.mutate(
          {
            userUuid,
            ...apiKey,
          },
          {
            onSuccess: (key) => {
              setCreatedKey(key);
              setCoinbaseAPIKey(undefined);
              /* only open snippet on success */
              toggleSnippetModalOn();
            },
            onError: () => {
              setCoinbaseAPIKey(undefined);
            },
          },
        );
      }
    },
    [createApiKeyMutation, toggleSnippetModalOn, userUuid],
  );

  const handleCreateCoinbaseAPIKey = useCallback(
    (apiKey: CoinbaseAPIKey, requireTwoFA = false) => {
      toggleCreateModalOff();
      setCoinbaseAPIKey(apiKey);

      if (requireTwoFA) {
        completeTwoFactorModalOn();
        return;
      }

      createAPIKeyWithoutTwoFA(apiKey);
    },
    [completeTwoFactorModalOn, createAPIKeyWithoutTwoFA, toggleCreateModalOff],
  );

  const editCoinbaseAPIKeyWithoutTwoFA = useCallback(
    (apiKey: CoinbaseAPIKey) => {
      /* if the editedCoinbaseAPIKey has a nickname we are updating the entire key,
      if it has an enabled property we are updating the status of they key */
      if (selectedKey && (apiKey?.nickname || apiKey?.enabled)) {
        editKeyMutation.mutate(
          {
            tokenId: selectedKey,
            nickname: apiKey.nickname,
            scopes: apiKey.scopes,
            allowedIps: apiKey.allowedIps,
            /* if the apiKey has an enabled property we are only updating status */
            updateMask: apiKey.enabled !== undefined ? 'enabled' : 'nickname,scopes,allowedIps',
            enabled: apiKey.enabled,
          },
          {
            onSuccess: () => {
              setCoinbaseAPIKey(undefined);
            },
            onError: () => {
              setEditedCoinbaseAPIKey(undefined);
            },
          },
        );
      }
      setEditedCoinbaseAPIKey(undefined);
    },
    [editKeyMutation, selectedKey],
  );

  const handleEditCoinbaseAPIKey = useCallback(
    (apiKey: CoinbaseAPIKey, requireTwoFA = false) => {
      setEditedCoinbaseAPIKey(apiKey);
      toggleEditModalOff();
      if (requireTwoFA) {
        completeTwoFactorModalOn();
        return;
      }
      editCoinbaseAPIKeyWithoutTwoFA(apiKey);
    },
    [completeTwoFactorModalOn, editCoinbaseAPIKeyWithoutTwoFA, toggleEditModalOff],
  );

  const { keys } = useGetApiKeys(activeOrg?.organizationId);
  const detailAPIKey = useMemo(() => {
    return keys.find((key) => {
      const { keyId: derivedKeyId } = getKeyId(key.name);
      return derivedKeyId === detailAPIKeyId;
    });
  }, [detailAPIKeyId, keys]);

  const projectSelfKeys = useMemo(() => {
    return selfKeys.map((key) => {
      const matchingProject = projects.find((p) => p.id === key.projectId);
      return {
        ...key,
        projectName: matchingProject ? matchingProject.name : '',
      };
    });
  }, [projects, selfKeys]);

  const handleCloseAlert = alert.close;

  const handleCreateAPIKeyPress = useCallback(() => {
    logClick(EventName.create_api_key_click);
    if (projectSelfKeys?.length >= API_KEY_PER_USER_LIMIT) {
      alert.open(
        <Alert
          title="Key limit reached"
          body={`Each account can hold a maximum of ${API_KEY_PER_USER_LIMIT} keys.`}
          pictogram="warning"
          visible
          onRequestClose={handleCloseAlert}
          onPreferredActionPress={handleCloseAlert}
          preferredActionLabel="Got it"
        />,
      );

      return;
    }

    toggleCreateModalOn();
  }, [projectSelfKeys?.length, alert, handleCloseAlert, toggleCreateModalOn]);

  const handleDeleteAPIKeyPress = useCallback(
    (keyId) => {
      setSelectedKey(keyId);
      toggleDeleteModalOn();
    },
    [toggleDeleteModalOn],
  );

  const handleEditAPIKeyPress = useCallback(
    (keyId) => {
      setSelectedKey(keyId);
      toggleEditModalOn();
    },
    [toggleEditModalOn],
  );

  const handleTwoFactorCreateSuccess = useCallback(
    (proofToken?: string) => {
      const apiKey = coinbaseAPIKey;
      if (apiKey?.projectId) {
        createApiKeyMutation.mutate(
          {
            userUuid,
            ...apiKey,
            proofToken,
          },
          {
            onSuccess: (key) => {
              setCreatedKey(key);
              setCoinbaseAPIKey(undefined);
              /* only open snippet on success */
              logSuccessMetric('cdp_api_key_create');
              toggleSnippetModalOn();
            },
          },
        );
      }
    },
    [coinbaseAPIKey, createApiKeyMutation, toggleSnippetModalOn, userUuid],
  );

  const handleTwoFactorEditSuccess = useCallback(
    (proofToken?: string) => {
      const apiKey = editedCoinbaseAPIKey;
      /* if the apiKey has a nickname we are updating the entire key,
      if it has an enabled property we are updating the status of they key */
      if (selectedKey && (apiKey?.nickname || apiKey?.enabled)) {
        editKeyMutation.mutate({
          tokenId: selectedKey,
          nickname: apiKey.nickname,
          scopes: apiKey.scopes,
          allowedIps: apiKey.allowedIps,
          proofToken,
          /* if the apiKey has an enabled property we are only updating status */
          updateMask: apiKey.enabled !== undefined ? 'enabled' : 'nickname,scopes,allowedIps',
          enabled: apiKey.enabled,
        });
      }
      setEditedCoinbaseAPIKey(undefined);
    },
    [editKeyMutation, editedCoinbaseAPIKey, selectedKey],
  );

  const handleStatusChange = useCallback(
    (tokenId, status, requireTwoFA = false) => {
      if (requireTwoFA) {
        setSelectedKey(tokenId);
        setEditedCoinbaseAPIKey({
          enabled: true,
        });

        completeTwoFactorModalOn();
        return;
      }
      editKeyMutation.mutate({ tokenId, enabled: status, updateMask: 'enabled' });
    },
    [completeTwoFactorModalOn, editKeyMutation],
  );

  const handleTwoFactorFailure = useCallback(
    (error) => {
      if (coinbaseAPIKey?.projectId) {
        setCoinbaseAPIKey(undefined);
      }
      if (editedCoinbaseAPIKey?.nickname || editedCoinbaseAPIKey?.enabled) {
        setEditedCoinbaseAPIKey(undefined);
      }
      if (error.messsage) {
        toast.show(
          'An error occurred while creating your API key. Please wait 30 seconds and try again.',
          {
            variant: 'negative',
          },
        );
      }
    },
    [
      coinbaseAPIKey?.projectId,
      editedCoinbaseAPIKey?.enabled,
      editedCoinbaseAPIKey?.nickname,
      toast,
    ],
  );

  const handleViewAPIKeyDetailPress = useCallback(
    (keyId: string) => {
      setDetailAPIKeyId(keyId);
      apiKeyDetailModalOn();
    },
    [apiKeyDetailModalOn],
  );

  const handleAPIKeyDetailClose = useCallback(() => {
    setDetailAPIKeyId('');
    apiKeyDetailModalOff();
  }, [apiKeyDetailModalOff]);

  const handleAPIKeyDetailDeletePress = useCallback(
    (keyId: string) => {
      setDetailAPIKeyId('');
      apiKeyDetailModalOff();
      handleDeleteAPIKeyPress(keyId);
    },
    [apiKeyDetailModalOff, handleDeleteAPIKeyPress],
  );

  const handleAPIKeyDetailEditPress = useCallback(
    (keyId: string) => {
      setDetailAPIKeyId('');
      apiKeyDetailModalOff();
      handleEditAPIKeyPress(keyId);
    },
    [apiKeyDetailModalOff, handleEditAPIKeyPress],
  );

  const twoFactorModalTitle = useMemo(() => {
    return coinbaseAPIKey?.projectId ? 'Create API key' : 'Edit API key';
  }, [coinbaseAPIKey?.projectId]);

  return (
    <>
      {apiKeyDetailModalVisible && detailAPIKey && (
        <APIKeyDetailModal
          onStatusChangePress={handleStatusChange}
          onRequestClose={handleAPIKeyDetailClose}
          onDeletePress={handleAPIKeyDetailDeletePress}
          onEditPress={handleAPIKeyDetailEditPress}
          apiKey={detailAPIKey}
        />
      )}
      {completeTwoFactorModalVisible && (
        <APIKeyCompleteTwoFactorModal
          onRequestClose={completeTwoFactorModalOff}
          onSubmit={twoFactorModalOn}
          title={twoFactorModalTitle}
        />
      )}
      {twoFactorModalVisible && (
        <APIKeyTwoFactorModal
          onSuccess={
            coinbaseAPIKey?.projectId ? handleTwoFactorCreateSuccess : handleTwoFactorEditSuccess
          }
          onRequestClose={twoFactorModalOff}
          onFailure={handleTwoFactorFailure}
          title={twoFactorModalTitle}
        />
      )}

      {createModalVisible && (
        <CoinbaseAPIKeyCreateModal
          onSubmit={handleCreateCoinbaseAPIKey}
          onRequestClose={toggleCreateModalOff}
        />
      )}

      {snippetModalVisible && (
        <APIKeySuccessModal
          onRequestClose={toggleSnippetModalOff}
          isLoading={createApiKeyMutation.isLoading}
          isErrored={createApiKeyMutation.isError}
          createdKey={createdKey}
        />
      )}

      {editModalVisible && selectedKey && (
        <CoinbaseAPIKeyEditModal
          onRequestClose={toggleEditModalOff}
          keyId={selectedKey}
          onSubmit={handleEditCoinbaseAPIKey}
        />
      )}

      {deleteModalVisible && (
        <APIKeyDeleteModal
          onRequestClose={toggleDeleteModalOff}
          onSuccess={toggleCreateModalOff}
          keyId={selectedKey}
        />
      )}

      <VStack gap={8}>
        {!isPhone && (
          <CoinbaseAPIKeyTable
            onCreatePress={handleCreateAPIKeyPress}
            onDeletePress={handleDeleteAPIKeyPress}
            onKeyDetailPress={handleViewAPIKeyDetailPress}
            onEditPress={handleEditAPIKeyPress}
            onStatusChangePress={handleStatusChange}
          />
        )}
        {isPhone && (
          <MobileCoinbaseAPIKeyTable
            onCreatePress={handleCreateAPIKeyPress}
            onKeyDetailPress={handleViewAPIKeyDetailPress}
          />
        )}
      </VStack>
    </>
  );
}

export function APIKeys() {
  return (
    <Layout>
      <Layout.MainContainer fullWidth minWidth={MAIN_CONTAINER_MIN_WIDTH}>
        <CloudErrorBoundary name="ApiKeyTable" isComponentLevel>
          <APIKeysContent />
        </CloudErrorBoundary>
      </Layout.MainContainer>
    </Layout>
  );
}
