import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NavigationIcon } from '@cbhq/cds-web/icons';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { TabNavigation } from '@cbhq/cds-web/tabs';
import { TextCaption } from '@cbhq/cds-web/typography';

import { mobileNavItems } from ':cloud/config/navigation';
import useViewportDimensions from ':cloud/hooks/useViewportDimensions';

export function MobileTabNav() {
  const { width } = useViewportDimensions();
  const [activeTab, setActiveTab] = useState<string>('dashboard');
  const history = useHistory();

  // this is a hack that's necessary b/c for some reason the tab container
  // has a max width set on it, but we want it to fill the screen
  const tabWidth = useMemo(() => {
    if (!width) return '52px';
    return width * 0.14;
  }, [width]);

  const tabs = useMemo(() => {
    return mobileNavItems.map((item) => {
      const isActive = item.id === activeTab;
      return {
        ...item,
        label: (
          <VStack
            alignItems="center"
            justifyContent="center"
            gap={0.5}
            spacingHorizontal={0.5}
            spacingVertical={4}
            width={tabWidth}
          >
            <NavigationIcon size="l" name={item.icon} active={isActive} />
            <TextCaption transform="capitalize" as="p" color={isActive ? 'primary' : 'foreground'}>
              {item.label}
            </TextCaption>
          </VStack>
        ),
      };
    });
  }, [activeTab, tabWidth]);

  const onTabChange = useCallback(
    (tabId) => {
      const destinationRoute = tabs.find((tab) => tab.id === tabId)?.to;
      if (destinationRoute) {
        setActiveTab(tabId);
        history.replace(destinationRoute);
      }
    },
    [history, tabs],
  );

  return (
    <HStack
      position="fixed"
      bottom={0}
      background="background"
      width="100%"
      justifyContent="space-evenly"
      borderedTop
      zIndex={1}
    >
      <TabNavigation
        alignItems="center"
        justifyContent="space-evenly"
        variant="secondary"
        value={activeTab}
        //  @ts-expect-error -- CDS types are incorrect
        tabs={tabs}
        onChange={onTabChange}
        width="100%"
      />
    </HStack>
  );
}
