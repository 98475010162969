import { Dispatch, SetStateAction, useCallback } from 'react';
import { Button } from '@cbhq/cds-web/buttons';
import { ListCell } from '@cbhq/cds-web/cells';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@cbhq/cds-web/overlays';
import { Link, TextBody, TextCaption, TextLegal } from '@cbhq/cds-web/typography';

import { useGetProductVariants } from ':cloud/hooks/useGetProductVariants';
import { PaymentMethod } from ':cloud/types/service_proto';
import { ProductVariant } from ':cloud/types/ts_types';
import { currencyFormatter } from ':cloud/utils/formatters/currency';
import { MARKET_DATA_TERMS_OF_USE } from ':cloud/utils/links';
import { PaymentMethodDisplayer } from ':cloud/widgets/billing/PaymentMethodDisplayer';

function formatPlanPrice(plan: ProductVariant) {
  return currencyFormatter(+plan.price);
}

export function formatPlanTitle(plan: ProductVariant) {
  if (plan.price === '0') {
    return 'Free tier';
  }
  return `${formatPlanPrice(plan)}/mo`;
}

type ExchangePlanModalProps = {
  visible: boolean;
  currentPlan: ProductVariant;
  selectedPlan: ProductVariant;
  handleSelectPlan: (plan: ProductVariant) => void;
  handleSubmitPlan: () => void;
  onRequestClose: () => void;
  paymentMethod?: PaymentMethod;
  isLoading?: boolean;
  modalStep: 'options' | 'summary';
  setModalStep: Dispatch<SetStateAction<'options' | 'summary'>>;
};

export function ExchangePlanModal({
  visible,
  currentPlan,
  selectedPlan,
  handleSelectPlan,
  handleSubmitPlan,
  onRequestClose,
  paymentMethod,
  isLoading,
  modalStep,
  setModalStep,
}: ExchangePlanModalProps) {
  const { productVariants: allPlans } = useGetProductVariants('exchange');

  const handleSubmitPlanOptions = useCallback(() => {
    setModalStep('summary');
  }, [setModalStep]);

  const handleCloseModal = useCallback(() => {
    onRequestClose();
    setModalStep('options');
  }, [onRequestClose, setModalStep]);

  return (
    <Modal visible={visible} onRequestClose={handleCloseModal} width={450}>
      <ModalHeader title="Change plan" />
      {modalStep === 'options' && (
        <ModalContentPlanOptions
          {...{
            currentPlan,
            selectedPlan,
            allPlans,
            handleSelectPlan,
            onRequestClose,
            onSubmit: handleSubmitPlanOptions,
            paymentMethod,
          }}
        />
      )}
      {modalStep === 'summary' && paymentMethod && (
        <ModalContentPlanSummary
          {...{
            selectedPlan,
            handleSelectPlan,
            onRequestClose: handleCloseModal,
            onSubmit: handleSubmitPlan,
            paymentMethod,
            isLoading,
          }}
        />
      )}
    </Modal>
  );
}

type ModalContentPlanOptionsProps = {
  currentPlan: ProductVariant;
  selectedPlan: ProductVariant;
  allPlans: ProductVariant[];
  handleSelectPlan: (plan: ProductVariant) => void;
  onSubmit: () => void;
  paymentMethod?: PaymentMethod;
};

function ModalContentPlanOptions({
  selectedPlan,
  allPlans,
  handleSelectPlan,
  onSubmit,
  paymentMethod,
}: ModalContentPlanOptionsProps) {
  return (
    <>
      <ModalBody>
        <VStack>
          {allPlans.map((plan) => (
            <ListCell
              key={plan.price}
              title={formatPlanTitle(plan)}
              description={`up to ${plan.meters[0].unit} subscriptions per product channel`}
              selected={plan.price === selectedPlan.price}
              onPress={() => handleSelectPlan(plan)}
              disabled={!paymentMethod && plan.price !== '0'}
            />
          ))}
        </VStack>
      </ModalBody>
      <ModalFooter primaryAction={<Button onPress={onSubmit}>Select plan</Button>} />
    </>
  );
}

type ModalContentPlanSummaryProps = {
  selectedPlan: ProductVariant;
  onRequestClose: () => void;
  onSubmit: () => void;
  paymentMethod: PaymentMethod;
  isLoading?: boolean;
};

function ModalContentPlanSummary({
  selectedPlan,
  onSubmit,
  onRequestClose,
  paymentMethod,
  isLoading = false,
}: ModalContentPlanSummaryProps) {
  return (
    <>
      <ModalBody>
        <VStack gap={3} spacingVertical={2}>
          <VStack>
            <TextCaption as="p" color="foregroundMuted" spacingStart={3}>
              Plan
            </TextCaption>
            <ListCell
              title={`${selectedPlan.meters[0].unit} subscriptions per product channel`}
              description="Billed on the 1st of every month"
              detail={`${formatPlanPrice(selectedPlan)}/mo`}
            />
          </VStack>
          <VStack gap={1}>
            <TextCaption as="p" color="foregroundMuted" spacingStart={3}>
              Payment method
            </TextCaption>
            <Box spacingHorizontal={0.5} display="block">
              {paymentMethod ? (
                <PaymentMethodDisplayer paymentMethod={paymentMethod} />
              ) : (
                <TextBody as="p" spacingStart={2}>
                  No payment method on file
                </TextBody>
              )}
            </Box>
          </VStack>
          <TextLegal as="p" color="foregroundMuted" spacingHorizontal={3}>
            By accessing the Exchange Market Data API, you agree to be bound by the{' '}
            <Link variant="legal" to={MARKET_DATA_TERMS_OF_USE}>
              Market Data Terms of Use
            </Link>
            . Coinbase Crypto Services, LLC, is solely acting as the billing and payment agent for
            your paid Websocket Market Data Subscription. All existing terms between you and
            Coinbase Inc. and its affiliates (as applicable) shall remain in full force and effect.
          </TextLegal>
        </VStack>
      </ModalBody>
      <ModalFooter
        primaryAction={
          <Button onPress={onSubmit} loading={isLoading}>
            Confirm
          </Button>
        }
        secondaryAction={
          <Button onPress={onRequestClose} variant="secondary">
            Cancel
          </Button>
        }
      />
    </>
  );
}
