import { useMemo } from 'react';
import { Button, ButtonGroup } from '@cbhq/cds-web/buttons';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Box, Grid, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextDisplay2, TextTitle3, TextTitle4 } from '@cbhq/cds-web/typography';

import { ReactComponent as EmbeddedWalletsImg } from ':cloud/assets/Products/EmbeddedWalletCard.svg';
import { useAppState } from ':cloud/contexts/AppStateContext';
import { useHasEmbeddedWalletsCoinbaseManagedAuth } from ':cloud/hooks/featureFlags/useHasEmbeddedWalletsCoinbaseManagedAuth';
import { EventName, useLogViewOnMount } from ':cloud/init/clientAnalytics/logging';
import { EMBEDDED_WALLETS_DISCORD_LINK, EMBEDDED_WALLETS_DOCS_LINK } from ':cloud/utils/links';
import { onExternalLinkPress } from ':cloud/utils/onExternalLinkPress';
import { Layout } from ':cloud/widgets/layout';
import { FULL_PAGE_MAX_WIDTH, MAIN_CONTAINER_MIN_WIDTH } from ':cloud/widgets/product/constants';
import { CopyProjectIdSection } from ':cloud/widgets/product/CopyProjectIdSection';
import { PricingLandingTemplate } from ':cloud/widgets/product/PricingLandingTemplate';
import { CoinbaseAuthSection } from ':cloud/widgets/waasConsumer/CoinbaseAuthSection';
import { UpdateDomainComponent } from ':cloud/widgets/waasConsumer/UpdateDomainComponent';

import { PricingProps } from '../../widgets/product/types';

const pricing: PricingProps[] = [
  {
    billingUnit: 'Up to 1,000',
    price: 'Free',
  },
  {
    billingUnit: 'Up to 10,000',
    price: '$99/month',
    discount: 'Free for a limited time',
  },
  {
    billingUnit: 'Up to 50,000',
    price: '$499/month',
    discount: 'Free for a limited time',
  },
  {
    billingUnit: '50,000+',
    price: 'Get in touch', // TODO: Add link to contact us
  },
];

export function WaasConsumerLanding() {
  const title = 'Fully customizable wallets';
  const description =
    'A browser-based SDK to create, backup and use onchain wallets. Backed by the security of Coinbase.';

  const { isDesktop } = useBreakpoints();
  const responsiveTemplateColumns = useMemo(() => {
    return isDesktop ? '1fr 3fr' : '1fr';
  }, [isDesktop]);

  const responsiveTemplateHeaderColumns = useMemo(() => {
    return isDesktop ? '1fr 1fr' : '1fr';
  }, [isDesktop]);

  const { selectedProject } = useAppState();
  const allowEuaOnboarding = useHasEmbeddedWalletsCoinbaseManagedAuth();

  useLogViewOnMount(EventName.embedded_wallets_page_view);

  return (
    <Layout enableMaxWidth={false}>
      <Layout.MainContainer minWidth={MAIN_CONTAINER_MIN_WIDTH} spacingHorizontal={0} fullWidth>
        <Box display="block" width="100%">
          <VStack>
            <HStack borderedBottom>
              <HStack maxWidth={FULL_PAGE_MAX_WIDTH}>
                <Grid
                  maxWidth={FULL_PAGE_MAX_WIDTH}
                  gap={9}
                  spacing={9}
                  templateColumns={responsiveTemplateHeaderColumns}
                >
                  <VStack>
                    <TextDisplay2 as="h1">{title}</TextDisplay2>
                    <TextTitle4 as="h2" color="foregroundMuted" spacingTop={5} spacingBottom={7}>
                      {description}
                    </TextTitle4>

                    <ButtonGroup>
                      <Button
                        variant="primary"
                        endIcon="diagonalUpArrow"
                        onPress={onExternalLinkPress(EMBEDDED_WALLETS_DOCS_LINK)}
                        accessibilityLabel="View docs + opens in new window"
                      >
                        View docs
                      </Button>
                      <Button
                        variant="secondary"
                        endIcon="diagonalUpArrow"
                        onPress={onExternalLinkPress(EMBEDDED_WALLETS_DISCORD_LINK)}
                        accessibilityLabel="View docs + opens in new window"
                      >
                        Discord channel
                      </Button>
                    </ButtonGroup>
                  </VStack>
                  <PricingLandingTemplate specs={pricing} />
                </Grid>
              </HStack>
            </HStack>
            {selectedProject && (
              <VStack borderedBottom>
                <VStack maxWidth={FULL_PAGE_MAX_WIDTH} spacing={10} gap={5}>
                  <HStack alignItems="center" gap={2}>
                    <TextTitle3 as="h1">Configuration</TextTitle3>
                  </HStack>
                  <CopyProjectIdSection
                    projectId={selectedProject.id}
                    description="Your project ID is required to authenticate your account."
                  />
                  {allowEuaOnboarding && <CoinbaseAuthSection project={selectedProject} />}
                  <UpdateDomainComponent project={selectedProject} />
                </VStack>
              </VStack>
            )}
            <VStack borderedTop spacing={10}>
              <VStack maxWidth={FULL_PAGE_MAX_WIDTH} gap={5} flexGrow={1} width="100%">
                <HStack alignItems="center" gap={2}>
                  <TextTitle3 as="h1">Getting started</TextTitle3>
                </HStack>
                <Grid gap={4} width="100%" templateColumns={responsiveTemplateColumns}>
                  <EmbeddedWalletsImg />
                  <VStack gap={1} alignItems="flex-start">
                    <TextTitle3 as="h1">Quickstart</TextTitle3>
                    <TextBody as="p" color="foregroundMuted" spacingBottom={2}>
                      Add Embedded Wallets to your app for easy wallet creation
                    </TextBody>
                    <Button
                      variant="secondary"
                      endIcon="diagonalUpArrow"
                      onPress={onExternalLinkPress(EMBEDDED_WALLETS_DOCS_LINK)}
                      accessibilityLabel="Read more + opens in new window"
                    >
                      View guide
                    </Button>
                  </VStack>
                </Grid>
              </VStack>
            </VStack>
          </VStack>
        </Box>
      </Layout.MainContainer>
    </Layout>
  );
}
