import { TextBody } from '@cbhq/cds-web/typography';
import { HTMLNonHeadingTextTags, TextProps } from '@cbhq/cds-web/typography/TextProps';

interface TextLabelGrayProps extends TextProps {
  as?: HTMLNonHeadingTextTags;
}

export function TextLabelGray({ children, as = 'p', className, ...rest }: TextLabelGrayProps) {
  return (
    <TextBody
      as={as}
      color="foregroundMuted"
      transform="capitalize"
      className={className}
      {...rest}
    >
      {children}
    </TextBody>
  );
}
