import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { ClientDeleteType, DeleteOAuthClientResponse } from ':cloud/types/service_proto';

const errorMsg = 'An error occurred deleting the OAuth client. Please retry';

async function deleteOAuthClient(
  orgId: string,
  clientId: string,
): Promise<DeleteOAuthClientResponse | undefined> {
  const path = `/apikeys/v1/organizations/${orgId}/oauthClients/${clientId}`;
  const { data } = await APIClientBase.delete<DeleteOAuthClientResponse>(path);
  return data;
}

export function useDeleteOAuthClient(orgId = '', onSuccess?: (clientId: string) => void) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ clientId }: { clientId: string; deleteType: ClientDeleteType }) =>
      deleteOAuthClient(orgId, clientId),
    onSuccess: async (deletedClient) => {
      if (!deletedClient) return;
      await queryClient.invalidateQueries([QueryKey.oauth, orgId]);
      onSuccess?.(deletedClient.clientId);
    },
    meta: {
      errorMsg,
    },
  });
}
