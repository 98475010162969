import { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { Button } from '@cbhq/cds-web/buttons';
import { TextInput } from '@cbhq/cds-web/controls';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { Link, TextBody, TextCaption, TextDisplay1, TextHeadline } from '@cbhq/cds-web/typography';

import config from ':cloud/config';

// Entry-gateway session-manager cookie
const UNIFIED_SESSION_MANAGER_COOKIE = 'unified-session-manager-cookie';

// Entry-gateway mounted path for initiating unified login flow
const ENTRY_GATEWAY_LOGIN_PATH = '/login';

// Global session cookie prefix set by unified login
const GSSC_COOKIE =
  config.environmentName === 'production' ? 'cb-gssc' : `cb-gssc_${config.environmentName}`;

// Entry-gateway login-session cookie
const LOGIN_SESSION_COOKIE = 'login-session';

// Stolen from: https://www.w3schools.com/js/js_cookies.asp
function getCookie(cname: string) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let c of ca) {
    while (c.startsWith(' ')) {
      c = c.substring(1);
    }
    if (c.startsWith(name)) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

// Conditionally mount this as "/login", which is the route that EGW uses to
// initiate the login flow
export function LocalLogin() {
  const [sessionCookie, setSessionCookie] = useState(getCookie(UNIFIED_SESSION_MANAGER_COOKIE));
  const [gsscCookie, setGsscCookie] = useState(getCookie(GSSC_COOKIE));
  const [loginSessionCookie, setLoginSessionCookie] = useState(getCookie(LOGIN_SESSION_COOKIE));

  useEffect(function setPageTitle() {
    const originalTitle = document.title;
    document.title = 'Local dev - login';
    return () => {
      document.title = originalTitle;
    };
  });

  const handleSessionCookieChanged: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => setSessionCookie(e.target.value),
    [],
  );

  const handleGsscCookieChanged: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => setGsscCookie(e.target.value),
    [],
  );

  const handleLoginSessionCookieChanged: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => setLoginSessionCookie(e.target.value),
    [],
  );

  const handleSignin = useCallback(() => {
    document.cookie = `${UNIFIED_SESSION_MANAGER_COOKIE}=${sessionCookie}; path=/; domain=localhost; Secure`;
    document.cookie = `${GSSC_COOKIE}=${gsscCookie}; path=/; domain=localhost;`;
    document.cookie = `${LOGIN_SESSION_COOKIE}=${loginSessionCookie}; path=/; domain=localhost;`;
    // [ROUT-868]: Support a redirect URL
    window.location.assign('/');
  }, [sessionCookie, gsscCookie, loginSessionCookie]);

  return (
    <Box spacing={4} minWidth="100vw" minHeight="100vh" background="background">
      <VStack gap={2}>
        <TextDisplay1 as="h1" spacingBottom={2}>
          Local dev signin
        </TextDisplay1>

        <TextBody as="p" spacingBottom={2}>
          In order to login for local development, youll need to login to a deployed environment and
          copy your session cookie.
        </TextBody>

        <TextHeadline as="h2">Steps</TextHeadline>

        <VStack spacing={2} spacingTop={0}>
          <TextBody as="p" spacingBottom={1}>
            1) Log in at{' '}
            <Link
              href={`${config.deployedHost}${ENTRY_GATEWAY_LOGIN_PATH}`}
              target="_blank"
              rel="noreferrer"
            >
              {`${config.deployedHost}${ENTRY_GATEWAY_LOGIN_PATH}` ??
                'Hmm, missing the DEPLOYED_HOST ENV VAR'}
            </Link>
          </TextBody>

          <TextBody as="p">
            2) In the Chrome debugger, find and copy your{' '}
            <TextCaption as="span">{UNIFIED_SESSION_MANAGER_COOKIE}</TextCaption> and{' '}
            <TextCaption as="span">{GSSC_COOKIE}</TextCaption> cookies.
          </TextBody>

          <VStack gap={1} spacing={2}>
            <TextInput
              value={gsscCookie}
              onChange={handleGsscCookieChanged}
              label={GSSC_COOKIE}
              compact
            />
            <TextInput
              value={sessionCookie}
              onChange={handleSessionCookieChanged}
              label={UNIFIED_SESSION_MANAGER_COOKIE}
              compact
            />
            <TextInput
              value={loginSessionCookie}
              onChange={handleLoginSessionCookieChanged}
              label={LOGIN_SESSION_COOKIE}
              compact
            />
            <Button compact onPress={handleSignin}>
              Signin
            </Button>
          </VStack>
        </VStack>
      </VStack>
    </Box>
  );
}
