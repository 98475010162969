import { createContext, Dispatch, RefObject, SetStateAction, useContext } from 'react';
import { DropdownRefProps } from '@cbhq/cds-web/dropdown';

import { NavItem, NavItemChild } from ':cloud/config/navigation';

export type TabTypeId =
  | 'api'
  | 'legacy-api'
  | 'billing'
  | 'earnings'
  | 'organization'
  | 'new-organization'
  | 'protocols'
  | 'clusters'
  | 'organizations'
  | 'teams'
  | 'overview'
  | 'monitor'
  | 'manage'
  | 'details'
  | 'usage';

export type TabType = {
  id: TabTypeId;
  label: string;
};

type NavigationContextType = {
  activePage: (NavItem | NavItemChild) & { parentId?: string };
  setActivePage: Dispatch<SetStateAction<NavItem | NavItemChild>>;
  activeTab: TabTypeId | undefined;
  projectId: string | undefined;
  notificationRef: RefObject<DropdownRefProps>;
};

export const NavigationContext = createContext<NavigationContextType | undefined>(undefined);

export function useNavigationContext(): NavigationContextType {
  const context = useContext(NavigationContext);
  if (context === undefined) {
    throw new Error('useNavigationContext must be used within a NavigationProvider');
  }

  return context;
}
