import { Box, BoxProps, GridColumn, VStack } from '@cbhq/cds-web/layout';

import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';

export type SidebarContainerProps = BoxProps & {
  className?: string;
  sticky?: boolean;
  top?: number;
  noTop?: boolean;
};

export function SidebarContainer({
  children,
  className,
  sticky,
  top = 2,
  noTop = false,
  ...props
}: SidebarContainerProps) {
  const { isDesktop, isPhone } = useSimpleBreakpoints();

  return (
    <GridColumn
      height="100vh"
      colSpan={isDesktop ? 4 : 12}
      borderedStart={isDesktop}
      borderedTop={!isDesktop}
    >
      <VStack
        width="100%"
        background="transparent"
        spacingTop={noTop ? 0 : 7}
        spacingBottom={5}
        spacingHorizontal={isPhone ? 5 : 7}
        className={className}
        {...props}
      >
        {sticky && isDesktop ? (
          <Box position="sticky" top={top}>
            {children}
          </Box>
        ) : (
          children
        )}
      </VStack>
    </GridColumn>
  );
}
