import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';

type SimpleBreakpointsRecord = {
  isPhone: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

export function useSimpleBreakpoints(): SimpleBreakpointsRecord {
  const device = useBreakpoints();

  return {
    isPhone: (device.isPhone || device.isPhoneLandscape) ?? false,
    isTablet: (device.isTablet || device.isTabletLandscape) ?? false,
    isDesktop: (device.isDesktop || device.isDesktopLarge || device.isExtraWide) ?? false,
  };
}
