import { format, fromUnixTime } from 'date-fns';
import * as locales from 'date-fns/locale';
import { toZonedTime } from 'date-fns-tz';

import { logError } from ':cloud/init/bugsnag/logging';

type Locale = locales.Locale;
type LocaleCollection = Record<string, Locale>;

/** Converts the specified `utcDate` into local timezone using the specified format string. */
export function formatDate(utcDate: Date, formatStr: string): string {
  const defaultTimeZone = 'UTC';
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || defaultTimeZone;

  const localTZ: Locale = (locales as LocaleCollection)[timeZone] || locales.enUS;

  try {
    const localDate: Date = toZonedTime(utcDate, timeZone);
    return format(localDate, formatStr, { locale: localTZ });
  } catch (error) {
    logError(error, {
      context: 'formatDate',
    });

    return utcDate.toISOString();
  }
}

export function formatDateUTC(date: Date, formatStr: string): string {
  const dateToUTCDate = toZonedTime(date, 'Etc/UTC');
  return format(dateToUTCDate, formatStr);
}

export function getFormattedDateExtended(date: Date): string {
  return formatDate(date, 'MMM d, yyyy');
}

export function getFormattedTime(date: Date): string {
  return formatDate(date, 'h:mm a');
}

// eslint-disable-next-line import/no-unused-modules
export function getFormattedDateTimeUTC(date: Date): string {
  return `${formatDateUTC(date, 'MM/dd/yyyy')}, ${formatDateUTC(date, 'h:mm a')} UTC`;
}

/* users local timezone with no GMT-X appended */
export function getFormattedTimeZoneLocal(date: Date): string {
  return formatDate(date, 'MM/dd/yyyy, h:mma');
}

function formatUnixDate(unixDate: number, formatStr: string): string {
  const defaultTimeZone = 'UTC';
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || defaultTimeZone;

  const localTZ: Locale = (locales as LocaleCollection)[timeZone] || locales.enUS;

  try {
    const localDate: Date = toZonedTime(fromUnixTime(unixDate), timeZone);
    return format(localDate, formatStr, { locale: localTZ });
  } catch (error) {
    logError(error, {
      context: 'formatUnixDate',
    });

    return new Date(unixDate * 1000).toISOString();
  }
}

export function getChartDateTime(date: number, formatStr = 'MMM d h:mma'): string {
  return formatUnixDate(date, formatStr);
}

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export function getShortDate(date: Date) {
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  return `${month} ${day}`;
}

export function getWeekRange(date: Date) {
  const currentDayOfWeek = date.getDay();

  const startDate = new Date(date);
  startDate.setDate(date.getDate() - currentDayOfWeek);

  const endDate = new Date(date);
  endDate.setDate(date.getDate() + (6 - currentDayOfWeek));

  const formattedStartDate = `${monthNames[startDate.getMonth()]} ${startDate.getDate()}`;
  const formattedEndDate = `${monthNames[endDate.getMonth()]} ${endDate.getDate()}`;

  return `${formattedStartDate} - ${formattedEndDate}`;
}

export function getMonthRange(date: Date) {
  const startDate = new Date(date);
  startDate.setMonth(date.getMonth() - 1);

  if (startDate.getMonth() === date.getMonth()) {
    startDate.setDate(0);
  }

  const formattedStartDate = `${monthNames[startDate.getMonth()]} ${startDate.getDate()}`;
  const formattedEndDate = `${monthNames[date.getMonth()]} ${date.getDate()}`;

  return `${formattedStartDate} - ${formattedEndDate}`;
}
