import { useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { DotStatusColor } from '@cbhq/cds-web/dots/DotStatusColor';
import { Box, Grid, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextLegal } from '@cbhq/cds-web/typography';

import { cdsAliasToCssVar, getCDSVariantFromWorkflow } from ':cloud/brand/colors';
import { ChartWidgetType } from ':cloud/types/helper';
import { parseNewMetricValue } from ':cloud/utils/metrics';
import { twoColumnGrid } from ':cloud/utils/responsiveConfigs';
import PercentageBar from ':cloud/widgets/charts/PercentageBar';
import { TextLabelGray } from ':cloud/widgets/sharedcomponents';

function formatGWEIToETH(gweiVal: BigNumber): string {
  if (gweiVal.toString() === 'NaN') {
    return '--';
  }
  const ONE_GWEI = 10 ** 9; // == 1 ETH
  return gweiVal
    .dividedBy(ONE_GWEI)
    .integerValue(BigNumber.ROUND_FLOOR)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function StakeBarWidget({ sources, options, metricsData }: ChartWidgetType) {
  const metricOptions = options.sources.metrics;
  const stakeSource = sources[0];
  const depositSource = sources[1];

  const stakeColorVariant = useMemo(() => {
    return getCDSVariantFromWorkflow(metricOptions[stakeSource.key].color);
  }, [metricOptions, stakeSource.key]);

  const depositColorVariant = useMemo(() => {
    return getCDSVariantFromWorkflow(metricOptions[depositSource.key].color);
  }, [metricOptions, depositSource.key]);

  const stakingBalanceVal = useMemo(
    () =>
      ((metricsData &&
        (parseNewMetricValue(metricsData[stakeSource.key], stakeSource.type) as BigNumber)) ||
        new BigNumber(0)) as BigNumber,
    [metricsData, stakeSource.key, stakeSource.type],
  );
  const pendingDepositVal = useMemo(
    () =>
      ((metricsData &&
        (parseNewMetricValue(metricsData[depositSource.key], depositSource.type) as BigNumber)) ||
        new BigNumber(0)) as BigNumber,
    [depositSource.key, depositSource.type, metricsData],
  );
  const data = useMemo(
    () => [
      {
        count: stakingBalanceVal,
        color: cdsAliasToCssVar(stakeColorVariant),
      },
      {
        count: pendingDepositVal,
        color: cdsAliasToCssVar(depositColorVariant),
      },
      {
        count: stakingBalanceVal.plus(pendingDepositVal),
        color: cdsAliasToCssVar('line'),
        total: true,
      },
    ],
    [pendingDepositVal, stakingBalanceVal, stakeColorVariant, depositColorVariant],
  );
  return (
    <VStack gap={3} width="100%">
      <PercentageBar data={data} />
      <Grid gap={3} responsiveConfig={twoColumnGrid}>
        <HStack gap={3}>
          <Box spacingTop={0.5}>
            {/* @ts-expect-error -- can *technically* be any valid cds alias color */}
            <DotStatusColor variant={stakeColorVariant} />
          </Box>
          <VStack>
            <TextLegal as="p" mono>
              {formatGWEIToETH(stakingBalanceVal)} {metricOptions[stakeSource.key].unit}
            </TextLegal>
            <TextLabelGray>Staking Balance</TextLabelGray>
          </VStack>
        </HStack>
        <HStack gap={3}>
          <Box spacingTop={0.5}>
            {/* @ts-expect-error -- can *technically* be any valid cds alias color */}
            <DotStatusColor variant={depositColorVariant} />
          </Box>
          <VStack>
            <TextLegal as="p" mono>
              {formatGWEIToETH(pendingDepositVal)} {metricOptions[depositSource.key].unit}
            </TextLegal>
            <TextLabelGray>Pending Deposits</TextLabelGray>
          </VStack>
        </HStack>
      </Grid>
    </VStack>
  );
}
