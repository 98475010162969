import { useMemo } from 'react';

import { useAppState } from ':cloud/contexts/AppStateContext';
import { useGetApiKeys } from ':cloud/queries/ApiKeyQueries/useGetApiKeys';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { IPlatformAPIKey } from ':cloud/types/ts_types';

export function useSelectedProjectAPIKeys(): {
  projectKeys: IPlatformAPIKey[];
  isKeysLoading: boolean;
} {
  const { activeOrg } = useGetUser();
  const { selectedProject } = useAppState();
  const { keys, isLoading } = useGetApiKeys(activeOrg?.organizationId);
  const projectKeys = useMemo(() => {
    return keys
      ?.filter((key) => key.projectId === selectedProject?.id)
      .map((key) => {
        return {
          ...key,
          projectName: selectedProject?.name,
        };
      });
  }, [keys, selectedProject?.id, selectedProject?.name]);

  return {
    projectKeys: projectKeys || [],
    isKeysLoading: isLoading,
  };
}
