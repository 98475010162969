import { useMemo } from 'react';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { ProtocolKey } from ':cloud/types/ts_types';
import { formatDateUTC } from ':cloud/utils/date';
import {
  defaultChartProps,
  defaultYAxisProps,
  defaultYAxisTickFormatter,
} from ':cloud/widgets/earnings/chartDefaults';
import { EarningsChartTooltip } from ':cloud/widgets/earnings/EarningsChartTooltip';
import { EarningsRange } from ':cloud/widgets/earnings/types';

type ProtocolRewardsChartProps = {
  rewards: Record<string, { totalEarnedNativeUnit: string }>;
  ticker: string;
  protocol: ProtocolKey;
  selectedPeriod: EarningsRange;
};

const CHART_COLOR = 'rgb(var(--blue10))';
const CHART_STROKE_COLOR = 'rgb(var(--blue80))';

// prevent the tooltip dot from being cut off at top corners
const defaultAreaChartProps = { margin: { top: 3, right: 3 } };
// add padding to the top of the chart
const CHART_TOP_PADDING_FACTOR = 1.1;

export function ProtocolRewardsChart({ rewards, protocol, ticker }: ProtocolRewardsChartProps) {
  const { isPhone } = useSimpleBreakpoints();
  const data = useMemo(
    () =>
      Object.entries(rewards).map(([date, { totalEarnedNativeUnit }]) => ({
        date: formatDateUTC(date as unknown as Date, 'd MMM'),
        tooltipDate: formatDateUTC(date as unknown as Date, 'EEE, MMM d, yyyy'),
        ethereum: totalEarnedNativeUnit,
      })),
    [rewards],
  );

  const maxYAxisValue = useMemo(
    () => Math.max(...data.map((item) => parseFloat(item[protocol]))),
    [data, protocol],
  );
  const yAxisDomain = useMemo(() => [0, maxYAxisValue * CHART_TOP_PADDING_FACTOR], [maxYAxisValue]);

  return (
    <ResponsiveContainer width="100%" height="100%" key={protocol}>
      {/* @ts-expect-error TS not picking up overflow="visible" */}
      <AreaChart data={data} {...defaultChartProps} {...defaultAreaChartProps} overflow="visible">
        <XAxis dataKey="date" tickLine={false} minTickGap={isPhone ? 50 : 300} />
        <YAxis
          width={45}
          tickFormatter={defaultYAxisTickFormatter}
          domain={yAxisDomain}
          {...defaultYAxisProps}
        />
        <Tooltip content={<EarningsChartTooltip />} />
        <Area
          type="monotone"
          dataKey={protocol}
          stroke={CHART_STROKE_COLOR}
          strokeWidth={2}
          fillOpacity={1}
          fill={CHART_COLOR}
          unit={ticker}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
