/**
 * Page keys are used by CCA to group individual page urls.
 * This way, `/project`, `/project/:id` and `/project/:id/settings` can be grouped together under a `project` page key and analyzed as unit.
 * The url path is run against the pageKeyRegex to identify which page key the url path should be grouped under.
 *
 * NB: if you update this file, you must add a corresponding entry in the dev and prod config so that DataDog can pick it up in the following urls. Regex's added to those files should be in Go regex format.
 * https://config.cbhq.net/development/data/analytics-service-development?q=cloud_console_web_page_key_mapping_regex
 * https://config.cbhq.net/production/data/analytics-service-production?q=cloud_console_web_page_key_mapping_regex
 */

export const pageKeyRegex = {
  teams: /\/access\/teams(.*)/,
  api: /\/access\/api(.*)/,
  legacyApi: /\/access\/legacy-api(.*)/,
  access: /\/access(.*)/,

  products: /\/products(.*)/,

  projects: /\/projects(.*)/,

  organization: /\/settings\/organization(.*)/,
  billing: /\/settings\/billing(.*)/,
  settings: /\/settings(.*)/,

  participate: /\/participate(.*)/,
  root: /^\/{1}$/,
};
