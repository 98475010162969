import { UiIconName } from '@cbhq/cds-icons';
import { SpotIconName } from '@cbhq/cds-illustrations';
import { SpotRectangle } from '@cbhq/cds-web/illustrations';
import { HStack } from '@cbhq/cds-web/layout';

import OptimismLogo from ':cloud/assets/ThirdPartyLogos/optimism.png';
import PerennialLogo from ':cloud/assets/ThirdPartyLogos/perennial.png';
import VolmexLogo from ':cloud/assets/ThirdPartyLogos/volmex.png';
import {
  CLOUD_DISCORD,
  EAS_SCAN_DOCS_LINK,
  EAS_SCAN_LINK,
  VERIFICATIONS_DOCS_LINK,
  VERIFICATIONS_GITHUB_LINK,
  VERIFICATIONS_LINK,
} from ':cloud/utils/links';
import { onExternalLinkPress } from ':cloud/utils/onExternalLinkPress';
import { KeyFeatureItem } from ':cloud/widgets/product/KeyFeaturesSection';

export const keyFeatures: KeyFeatureItem[] = [
  {
    iconName: 'coinbaseOneTrusted',
    title: 'Ensure Reliability',
    description:
      'Using a Base Node, you can deploy contracts directly on Base, which prevents rate limiting.',
  },
  {
    iconName: 'coinbaseOneFiat',
    title: 'No-Cost Solution',
    description: "This product offering is free and deploying the contracts doesn't cost any gas.",
  },
  {
    iconName: 'supportChat',
    title: 'Deep Support',
    description:
      'We have a Discord, a Quickstart guide, and a Playground - all to help you integrate better!',
  },
];

export const heroContent = {
  // prevent "Real Interactions" from being split into two lines"
  heading: <>Real People. Real Wallets. Real&nbsp;Interactions.</>,
  subheading:
    'Verifications is the link that lets you verify over 500,000+ connected wallets and Coinbase accounts of specific attributes',
  primaryCTA: {
    children: 'View docs',
    onPress: onExternalLinkPress(VERIFICATIONS_DOCS_LINK),
    accessibilityLabel: 'View docs + opens in new window',
  },
  secondaryCTA: {
    children: 'Github',
    onPress: onExternalLinkPress(VERIFICATIONS_GITHUB_LINK),
    accessibilityLabel: 'Github + opens in new window',
  },
  rightRailContent: (
    <HStack spacingBottom={3} alignItems="center" alignContent="center" width="100%">
      <SpotRectangle name="login" scaleMultiplier={1.6} />
    </HStack>
  ),
};

export const whatCanYouVerifyContent = [
  {
    title: 'Coinbase account',
    description: 'Verify the user has a verified trading Coinbase account',
    icon: 'idVerification' as SpotIconName,
    count: '340k+',
  },
  {
    title: 'Country',
    description: "Verify the user's country of residence on Coinbase",
    icon: 'internationalExchangeProduct' as SpotIconName,
    count: '145k+',
  },
  {
    title: 'Coinbase One membership',
    description: 'Verify that the user is a Coinbase One member',
    icon: 'coinbaseOneProduct' as SpotIconName,
    count: '5k+',
    tag: 'New',
  },
];

export const verificationsVisionContent = [
  {
    title: 'Optimism',
    description:
      'Uses Verifications to provide genuine users 0.05 ETH gas rebate when deploying on the Superchain',
    media: OptimismLogo,
  },
  {
    title: 'Perennial',
    description:
      'Uses Verifications to let users trade perpetuals with leverage in a secure and compliant manner',
    media: PerennialLogo,
  },
  {
    title: 'Volmex Finance',
    description:
      'Uses Verifications to let verified users access volatility indices for Bitcoin, Ethereum, and tradable products',
    media: VolmexLogo,
  },
];

export const resourcesContent = [
  {
    title: 'Verifications Docs',
    description: 'View developer docs',
    icon: 'smartContract' as UiIconName,
    to: VERIFICATIONS_LINK,
  },
  {
    title: 'Github',
    description: 'View repository',
    icon: 'blog' as UiIconName,
    to: VERIFICATIONS_GITHUB_LINK,
  },
  {
    title: 'Discord',
    description: 'Join verifications channel',
    icon: 'discordLogo' as UiIconName,
    to: CLOUD_DISCORD,
  },
  {
    title: 'EAS Scan',
    description: 'View EAS activity',
    icon: 'ethereum' as UiIconName,
    to: EAS_SCAN_LINK,
  },
  {
    title: 'EAS Docs',
    description: 'Learn more about EAS',
    icon: 'document' as UiIconName,
    to: EAS_SCAN_DOCS_LINK,
  },
];
