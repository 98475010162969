import { useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button } from '@cbhq/cds-web/buttons';
import { Box } from '@cbhq/cds-web/layout';

import { useGetProtocolDisplayName } from ':cloud/queries/WorkflowQueries/useGetProtocolDisplayName';
import { ICluster } from ':cloud/types/ts_types';
import { statuses } from ':cloud/utils/clusters';
import { AlertConfirmation } from ':cloud/widgets/sharedcomponents';

interface DestroyClusterProps {
  cluster: ICluster;
  redirectOnDestroy: string;
  onHandleDestroy: () => void;
}

export function DestroyCluster({
  cluster,
  onHandleDestroy,
  redirectOnDestroy,
}: DestroyClusterProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const { displayName } = useGetProtocolDisplayName(cluster.type);

  const deleteCopy = `This action will destroy your ${displayName} cluster permanently. It cannot be recovered. To confirm you want it destroyed, enter the name of the cluster below.`;

  const toObj = useMemo(
    () => ({
      pathname: redirectOnDestroy,
      state: {
        type: statuses.BEING_DELETED,
        name: cluster.name,
      },
    }),
    [redirectOnDestroy, cluster.name],
  );

  if (redirect) {
    return <Redirect to={toObj} />;
  }
  return (
    <div>
      <Box width={248}>
        <Button variant="negative" onPress={() => setIsOpen(true)}>
          Destroy cluster
        </Button>
      </Box>
      <AlertConfirmation
        visible={isOpen}
        title="Destroy cluster"
        inputLabel="Cluster name"
        body={deleteCopy}
        variant="negative"
        typeConfirm={cluster.name}
        preferredActionLabel="Destroy cluster"
        onDismissActionPress={() => setIsOpen(false)}
        onPreferredActionPress={() => {
          onHandleDestroy();
          setIsOpen(false);
          setRedirect(true);
        }}
      />
    </div>
  );
}
