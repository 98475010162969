import { Checkbox } from '@cbhq/cds-web/controls';
import { VStack } from '@cbhq/cds-web/layout';
import { TextBody } from '@cbhq/cds-web/typography/TextBody';

import { IAPITokenPermType, IAPITokenPermTypeLabelMap } from ':cloud/types/ts_types';

interface PermsCheckboxesProps {
  description?: string;
  enabledPermissions: Set<IAPITokenPermType>;
  label: string;
  open: boolean;
  permissions: IAPITokenPermType[];
  serviceName: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPermissions: React.Dispatch<React.SetStateAction<IAPITokenPermType | null>>;
}

/* only used in LegacyAPITokenManagement */
export function PermsCheckboxes({
  description,
  enabledPermissions,
  label,
  open,
  permissions,
  serviceName,
  setOpen,
  setPermissions,
}: PermsCheckboxesProps) {
  return (
    <>
      <Checkbox
        testID={`${serviceName}-open`}
        checked={open}
        onChange={(): void => {
          setOpen(!open);
          // we were open, now we're closing, so we're resetting the form
          if (open) {
            setPermissions(null);
          }
        }}
      >
        {label}
      </Checkbox>

      <VStack spacingStart={5}>
        {description && (
          <TextBody as="p" color="foregroundMuted">
            {description}
          </TextBody>
        )}
        <VStack spacingTop={1}>
          {open &&
            permissions.map((permission: IAPITokenPermType) => (
              <Checkbox
                key={permission}
                testID={`${serviceName}-${permission}-box`}
                checked={enabledPermissions.has(permission)}
                onChange={(): void => setPermissions(permission)}
              >
                {IAPITokenPermTypeLabelMap[permission]}
              </Checkbox>
            ))}
        </VStack>
      </VStack>
    </>
  );
}
