import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import {
  CreateOrUpdateProductDetailsRequest,
  CreateOrUpdateProductDetailsResponse,
} from ':cloud/types/service_proto';
import { ProductDetails, ProductTypeCOMS } from ':cloud/types/ts_types';

// Updates product details for a given product scoped to a project in COMS.
async function updateProductDetails(
  organizationId: string,
  projectId: string,
  productType: ProductTypeCOMS,
  productDetails: ProductDetails,
  payload?: Record<string, any>,
): Promise<CreateOrUpdateProductDetailsResponse> {
  const path = `/organization-management/v1/organizations/${organizationId}/projects/${projectId}/products`;

  const requestBody = {
    productType,
    productDetails,
    ...payload,
  };

  const { data } = await APIClientBase.put<CreateOrUpdateProductDetailsResponse>(path, requestBody);
  return data;
}

type MessagesType = {
  errorMsg: string;
  successMsg: string;
};

export function useUpdateProductDetailsWithMessage(
  messages?: MessagesType,
  payload?: Record<string, any>,
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      project,
      productType,
      productDetails,
    }: CreateOrUpdateProductDetailsRequest) => {
      return updateProductDetails(
        project.organizationId,
        project.id,
        productType,
        productDetails,
        payload,
      );
    },
    onSuccess: async (_, { project, productType }) => {
      await queryClient.invalidateQueries([
        QueryKey.productDetails,
        project.organizationId,
        project.id,
        productType,
      ]);
    },
    meta: {
      ...(messages ?? {}),
    },
  });
}
