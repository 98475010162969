import { useCallback, useMemo, useState } from 'react';
import _capitalize from 'lodash/capitalize';
import { Button } from '@cbhq/cds-web/buttons';
import { SearchInput } from '@cbhq/cds-web/controls';
import { Switch } from '@cbhq/cds-web/controls/Switch';
import { SpotRectangle } from '@cbhq/cds-web/illustrations';
import { HStack, Spacer, VStack } from '@cbhq/cds-web/layout';
import {
  Table,
  TableBody,
  TableCell,
  TableCellFallback,
  TableHeader,
  TableRow,
} from '@cbhq/cds-web/tables';
import { TextBody, TextHeadline, TextTitle3 } from '@cbhq/cds-web/typography';

import { useSelectedProjectAPIKeys } from ':cloud/hooks/useSelectedProjectAPIKeys';
import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { EventName, useLogViewOnMount } from ':cloud/init/clientAnalytics/logging';
import { useGetTradePortfolios } from ':cloud/queries/ApiKeyQueries/useGetTradePortfolios';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { getFormattedTimeZoneLocal } from ':cloud/utils/date';
import { APIKeyTableEmptySearchState } from ':cloud/widgets/access/api/APIKeyTableEmptySearchState';
import { SELECT_OPTION_WIDTH } from ':cloud/widgets/access/constants';
import { ClipboardIcon } from ':cloud/widgets/sharedcomponents';

import { getKeyId, getPermissionsFromScopes, getPortfolioIdFromScopes } from '../helpers';

type APIKeyTableLoadingStateProps = { isViewOnly: boolean };

function APIKeyTableLoadingState({ isViewOnly }: APIKeyTableLoadingStateProps) {
  return (
    <>
      {[...Array(3).keys()].map((e) => (
        <TableRow key={e}>
          <TableCellFallback title />
          <TableCellFallback title />
          <TableCellFallback title />
          <TableCellFallback title />
          <TableCellFallback title />
          {!isViewOnly && <TableCellFallback end="image" width={100} />}
        </TableRow>
      ))}
    </>
  );
}

type APIKeyTableEmptyStateProps = {
  onCreatePress: () => void;
};

function APIKeyTableEmptyState({ onCreatePress }: APIKeyTableEmptyStateProps) {
  useLogViewOnMount(EventName.api_key_empty_state_view);

  return (
    <VStack
      testID="api-key-table-empty-state"
      justifyContent="center"
      alignItems="center"
      gap={1}
      width="100%"
      spacingVertical={10}
    >
      <VStack width={358} gap={5}>
        <VStack gap={3} alignItems="center">
          <SpotRectangle name="apiKey" scaleMultiplier={1} />
          <TextHeadline as="p" align="center">
            No API keys found
          </TextHeadline>
          <TextBody as="p" color="foregroundMuted" align="center">
            It takes less than a minute to create one.
          </TextBody>
        </VStack>
        <Button variant="secondary" onPress={onCreatePress}>
          Create API key
        </Button>
      </VStack>
    </VStack>
  );
}

function generateAdvancedSettingsCell(
  permissions: string[],
  portfolioName: string,
  allowedIps?: string[],
) {
  if (
    !permissions.includes('trade') &&
    !permissions.includes('transfer') &&
    !allowedIps?.length &&
    (!portfolioName || portfolioName === 'Default')
  ) {
    return '- -';
  }
  const allowsIpsString = allowedIps?.length ? `(${allowedIps.join(', ')})` : '';
  const formattedPermissions = permissions.map(_capitalize);

  return (
    <VStack spacingVertical={1}>
      <TextBody as="p" color="foregroundMuted">
        {`Portfolio: ${portfolioName} ${allowsIpsString}`}
      </TextBody>
      <TextBody as="p" color="foregroundMuted">
        {formattedPermissions.join(', ')}
      </TextBody>
    </VStack>
  );
}

type APIKeyTableProps = {
  onCreatePress: () => void;
  onDeletePress: (keyName: string) => void;
  onKeyDetailPress: (keyId: string) => void;
  onEditPress: (keyName: string) => void;
  onStatusChangePress: (keyId: string, status: boolean, requireTwoFA?: boolean) => void;
};

export function CoinbaseAPIKeyTable({
  onCreatePress,
  onKeyDetailPress,
  onStatusChangePress,
}: APIKeyTableProps) {
  const [searchValue, setSearchValue] = useState('');
  const {
    user: { isOwnerOrAdmin },
  } = useGetUser();

  const { isDesktop } = useSimpleBreakpoints();

  const { portfolios } = useGetTradePortfolios();
  const { projectKeys, isKeysLoading } = useSelectedProjectAPIKeys();

  useLogViewOnMount(EventName.trade_key_table_view);

  const isViewOnly = !isOwnerOrAdmin;
  const isInLoadingState = isKeysLoading;
  const isInEmptyState = Array.isArray(projectKeys) && !projectKeys?.length;

  const handleStatusChange = useCallback(
    (keyId: string, status: boolean, scopes?: string[]) => {
      const permissions = getPermissionsFromScopes(scopes || []);
      const requireTwoFA =
        status && (permissions.includes('trade') || permissions.includes('transfer'));
      return () => onStatusChangePress?.(keyId, status, requireTwoFA);
    },
    [onStatusChangePress],
  );

  const tokenTableContents = useMemo(() => {
    return projectKeys
      ?.filter((key) => key?.nickname?.toLowerCase().includes(searchValue.toLowerCase()))
      ?.map((key, idx) => {
        const { keyId, formattedKeyId } = getKeyId(key.name);
        const createdDateTime = getFormattedTimeZoneLocal(new Date(key.createTime));
        const portfolioId = getPortfolioIdFromScopes(key?.scopes || []);
        const portfolioName = portfolios.find((p) => p?.uuid === portfolioId)?.name || '';
        const permissions = getPermissionsFromScopes(key?.scopes || []);
        const advancedSettings = generateAdvancedSettingsCell(
          permissions,
          portfolioName,
          key.allowedIps,
        );

        return (
          <TableRow key={key.nickname || idx} testID={`trade-api-key-${keyId}`}>
            <TableCell>
              <TextHeadline as="p" spacingVertical={3}>
                {key.nickname || ''}
              </TextHeadline>
            </TableCell>

            <TableCell>
              <HStack gap={1} alignItems="center">
                <VStack background="primaryWash" spacingHorizontal={1}>
                  <TextBody as="p" mono>
                    {formattedKeyId}
                  </TextBody>
                </VStack>
                <ClipboardIcon text={keyId} iconSize="s" stopPropagation />
              </HStack>
            </TableCell>

            {isDesktop && <TableCell title={createdDateTime} width={159} />}

            {isDesktop && <TableCell>{advancedSettings}</TableCell>}

            {isDesktop && (
              <TableCell>
                <Switch
                  onChange={handleStatusChange(keyId, !key.enabled, key?.scopes)}
                  checked={key.enabled}
                >
                  {key.enabled ? 'Enabled' : 'Disabled'}
                </Switch>
              </TableCell>
            )}

            {!isViewOnly && (
              <TableCell>
                <Button transparent flush="start" onPress={() => onKeyDetailPress(keyId)}>
                  Configure
                </Button>
              </TableCell>
            )}
          </TableRow>
        );
      });
  }, [
    projectKeys,
    searchValue,
    portfolios,
    isDesktop,
    handleStatusChange,
    isViewOnly,
    onKeyDetailPress,
  ]);

  const isProjectWithSearchInEmptyState = tokenTableContents.length === 0;

  const tableBody = useMemo(() => {
    if (isInEmptyState && !isInLoadingState) {
      return (
        <TableRow>
          <TableCell colSpan={8}>
            <APIKeyTableEmptyState onCreatePress={onCreatePress} />
          </TableCell>
        </TableRow>
      );
    }
    if (isInLoadingState) {
      return <APIKeyTableLoadingState isViewOnly={isViewOnly} />;
    }
    if (isProjectWithSearchInEmptyState) {
      return <APIKeyTableEmptySearchState isViewOnly={isViewOnly} />;
    }
    return tokenTableContents;
  }, [
    isInEmptyState,
    isInLoadingState,
    isProjectWithSearchInEmptyState,
    isViewOnly,
    onCreatePress,
    tokenTableContents,
  ]);

  return (
    <VStack gap={2}>
      <HStack width="100%" justifyContent="space-between">
        <VStack width="66%" gap={3} spacingBottom={3}>
          <TextTitle3 as="p">Configure your API keys</TextTitle3>
          <TextBody as="p" color="foregroundMuted">
            An API key is a unique identifier that allows other apps and services to access specific
            functions and data. You can create and configure your API keys here.
          </TextBody>
        </VStack>
      </HStack>

      <HStack gap={4} justifyContent="space-between">
        <SearchInput
          width={SELECT_OPTION_WIDTH}
          value={searchValue}
          onChangeText={setSearchValue}
          placeholder="Search API keys"
        />
        {!isViewOnly && (
          <VStack justifyContent="center">
            <Button onPress={onCreatePress}>Create API key</Button>
          </VStack>
        )}
      </HStack>
      <Spacer />
      <Table bordered tableLayout="auto">
        <TableHeader>
          <TableRow>
            <TableCell title="Nickname" />
            <TableCell title="Key ID" />
            {isDesktop && <TableCell title="Generated" width={159} />}
            {isDesktop && <TableCell title="API restrictions" />}
            {isDesktop && <TableCell title="Status" width={145} />}
            {!isViewOnly && <TableCell title="" alignItems="center" width={100} />}
          </TableRow>
        </TableHeader>
        <TableBody>{tableBody}</TableBody>
      </Table>
    </VStack>
  );
}
