import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from '@cbhq/cds-web/icons/Icon';
import { Box } from '@cbhq/cds-web/layout';
import { Tooltip } from '@cbhq/cds-web/overlays/Tooltip/Tooltip';
import { Pressable } from '@cbhq/cds-web/system/Pressable';

import { useGetOrganization } from ':cloud/queries/OrganizationQueries/useGetOrganization';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { AppRoute } from ':cloud/utils/routes';
import { AvatarItem } from ':cloud/widgets/sharedcomponents/AvatarItem';

const SLICE_INDEX = 5;

export function TeamAvatars() {
  const history = useHistory();
  const { activeOrg } = useGetUser();
  const { activeUsers, orgHasMultipleUsers } = useGetOrganization(activeOrg?.organizationId);

  const handleManagePress = useCallback(() => {
    history.push(AppRoute.Team.Home);
  }, [history]);

  /* avatars are absolute positioned, dynamically calculate container width if greater than 5 items */
  return (
    <Box width={(activeUsers.length < 5 ? activeUsers.length + 1 : 5) * 20 + 30} height={40}>
      <Box position="relative">
        {activeUsers.slice(0, SLICE_INDEX).map((user, index) => {
          return (
            <Tooltip content={`${user.name} ${user.familyName}`} key={user.id}>
              <AvatarItem
                initial={user?.name?.[0]?.toUpperCase()}
                avatarColor={user.avatarColor}
                avatarUrl={user.avatarUrl}
                index={index}
                key={user.id}
              />
            </Tooltip>
          );
        })}
        {orgHasMultipleUsers && (
          <Box
            bordered
            borderColor="transparent"
            borderRadius="roundedLarge"
            position="absolute"
            background="backgroundAlternate"
            left={20 * (activeUsers.length > 5 ? 4 : activeUsers.length)}
            width={32}
            height={32}
            justifyContent="center"
            alignItems="center"
          >
            <Pressable
              background="transparent"
              onPress={handleManagePress}
              height="100%"
              width="100%"
            >
              <Box justifyContent="center" width="100%">
                <Icon name="add" size="s" color="foreground" />
              </Box>
            </Pressable>
          </Box>
        )}
      </Box>
    </Box>
  );
}
