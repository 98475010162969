import { ProductType } from ':cloud/types/ts_types';
import { Dashboard, DashboardChart, DashboardRange } from ':cloud/widgets/monitoring/types';

/* eslint-disable camelcase */

export const defaultChart: Record<'default_api_requests', { charts: DashboardChart[] }> = {
  default_api_requests: {
    charts: [
      {
        title: 'Total API requests',
        metricName: 'cloud.api-keys.request',
        aggregationMethod: 'sum',
        groupBy: ['target_service'],
        chartType: 'bar',
        unit: 'requests',
        filterBy: [],
      },
    ],
  },
};

export const productCharts: Record<ProductType, { charts: DashboardChart[] }> = {
  staking_api: {
    charts: [
      {
        title: 'Total API requests',
        metricName: 'RequestMade',
        aggregationMethod: 'sum',
        groupBy: [],
        chartType: 'area',
        unit: 'requests',
        filterBy: [],
      },
      {
        title: 'Total API requests by response code',
        metricName: 'RequestMade',
        aggregationMethod: 'sum',
        groupBy: ['Status'],
        chartType: 'bar',
        unit: 'requests',
        filterBy: [],
      },
      {
        title: 'Total API requests by endpoint',
        metricName: 'RequestMade',
        aggregationMethod: 'sum',
        groupBy: ['Path'],
        chartType: 'bar',
        unit: 'requests',
        filterBy: [],
      },
    ],
  },
  waas_consumer: {
    charts: [
      {
        title: 'API requests',
        metricName: 'RequestMade',
        aggregationMethod: 'sum',
        groupBy: ['Status'],
        chartType: 'bar',
        unit: 'requests',
        filterBy: [],
      },
      {
        title: 'Wallets created',
        metricName: 'WalletCreated',
        aggregationMethod: 'sum',
        groupBy: [],
        chartType: 'area',
        unit: 'wallets',
        filterBy: [],
      },
    ],
  },
  rat: {
    charts: [],
  },
  base: {
    charts: [
      {
        title: 'Total RPC requests',
        metricName: 'cloud.rpc-proxy.request',
        aggregationMethod: 'sum',
        groupBy: [],
        chartType: 'bar',
        unit: 'requests',
        filterBy: [],
      },
      {
        title: 'Sponsored ETH',
        metricName: 'cloud.rpc-proxy.paymaster.sponsored-eth',
        aggregationMethod: 'sum',
        groupBy: [],
        chartType: 'bar',
        unit: 'sponsored gas',
        filterBy: [],
      },
      {
        title: 'Sponsored USD',
        metricName: 'cloud.rpc-proxy.paymaster.sponsored-usd',
        aggregationMethod: 'sum',
        groupBy: [],
        chartType: 'bar',
        unit: 'Gas sponsored (USD)',
        filterBy: [{ name: 'network', value: 'base' }],
      },
      {
        title: 'Sponsored User Operations',
        metricName: 'cloud.rpc-proxy.paymaster.sponsored-op',
        aggregationMethod: 'sum',
        groupBy: [],
        chartType: 'bar',
        unit: 'User Ops sponsored',
        filterBy: [{ name: 'network', value: 'base' }],
      },
      {
        title: 'Sponsored Smart Wallets (Day)',
        metricName: 'cloud.rpc-proxy.paymaster.active-wallet.daily',
        aggregationMethod: 'sum',
        groupBy: [],
        chartType: 'bar',
        unit: 'Unique wallets sponsored',
        filterBy: [{ name: 'network', value: 'base' }],
      },
      {
        title: 'Sponsored Smart Wallets (Week)',
        metricName: 'cloud.rpc-proxy.paymaster.active-wallet.weekly',
        aggregationMethod: 'sum',
        groupBy: [],
        chartType: 'bar',
        unit: 'Unique wallets sponsored',
        filterBy: [{ name: 'network', value: 'base' }],
      },
      {
        title: 'Unique wallets sponsoreds (Month)',
        metricName: 'cloud.rpc-proxy.paymaster.active-wallet.monthly',
        aggregationMethod: 'sum',
        groupBy: [],
        chartType: 'bar',
        unit: 'Unique wallets sponsored',
        filterBy: [{ name: 'network', value: 'base' }],
      },
    ],
  },
  participate: {
    charts: [],
  },
  cbpay: {
    charts: [
      {
        title: 'Onramp transaction count',
        metricName: 'transaction.count',
        aggregationMethod: 'sum',
        groupBy: [],
        chartType: 'bar',
        unit: 'transactions',
        filterBy: [],
      },
      {
        title: 'Onramp volume',
        metricName: 'transaction.volume',
        aggregationMethod: 'sum',
        groupBy: [],
        chartType: 'bar',
        unit: 'volume',
        filterBy: [],
      },
      {
        title: 'Onramp MTUs',
        metricName: 'onramp.mtu',
        aggregationMethod: 'sum',
        groupBy: [],
        chartType: 'line',
        unit: 'users',
        filterBy: [],
      },
      {
        title: 'Onramp Conversion Rate',
        metricName: 'onramp.conversion',
        aggregationMethod: 'sum',
        groupBy: [],
        chartType: 'line',
        unit: '%',
        filterBy: [],
      },
      /*
      {
        title: 'Geographic Distribution of Onramp Transactions',
        metricName: 'transaction.CountryCode',
        aggregationMethod: 'sum',
        groupBy: [],
        chartType: 'pie',
        unit: 'users',
        filterBy: [],
      },
      */
    ],
  },
  exchange: {
    charts: [],
  },
};

export const defaultDashboardConfig: Pick<Dashboard, 'title' | 'range'> = {
  title: 'Project activity',
  range: 30,
};

// For use with the chart type selector, once it's enabled
// export const CHART_TYPES = ['line', 'bar', 'area'];

export const CHART_COLORS = [
  'rgb(var(--indigo50))',
  'rgb(var(--blue80))',
  'rgb(var(--orange50))',
  'rgb(var(--pink70))',
  'rgb(var(--pink50))',
  'rgb(var(--purple60))',
];

export const RANGE: Record<string, DashboardRange> = {
  day: 1,
  week: 7,
  month: 30,
};

// this is a typeguard func to check that a range is a valid DashboardRange
export function validateDashboardRange(range: any): DashboardRange {
  if (Object.values(RANGE).includes(range)) {
    return range as DashboardRange;
  }
  throw new Error(`Invalid range: ${range}`);
}

export const RANGE_OPTIONS = [
  { value: RANGE.day, title: 'Last 24 hours' },
  { value: RANGE.week, title: 'Last 7 days' },
  { value: RANGE.month, title: 'Last 30 days' },
];

/* INTERVALS FOR DIFFERENT RANGES, in minutes
- DAY - show bars for every 30 minutes or 60 minutes
- Week - show bars every 8 * 60 minutes or 24 * 60 min (every 8 hours or day)
- Month - show bars for every 24 * 60 min (every day)
*/
export const RANGE_INTERVAL_MAP = {
  area: {
    [RANGE.day]: 30,
    [RANGE.week]: 8 * 60,
    [RANGE.month]: 24 * 60,
  },
  bar: {
    [RANGE.day]: 60,
    [RANGE.week]: 24 * 60,
    [RANGE.month]: 24 * 60,
  },
  line: {
    [RANGE.day]: 30,
    [RANGE.week]: 8 * 60,
    [RANGE.month]: 24 * 60,
  },
  pie: {
    [RANGE.day]: 30,
    [RANGE.week]: 8 * 60,
    [RANGE.month]: 24 * 60,
  },
};
