import { useCallback, useMemo, useState } from 'react';
import { Button, ButtonGroup } from '@cbhq/cds-web/buttons';
import { TextInput } from '@cbhq/cds-web/controls';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Box, Grid, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextDisplay3, TextTitle4 } from '@cbhq/cds-web/typography';

import { ReactComponent as FaucetImg } from ':cloud/assets/Products/Faucet.svg';
import { useFaucet } from ':cloud/queries/Faucet/useFaucet';
import { FaucetResponse } from ':cloud/types/ts_types';
import { onExternalLinkPress } from ':cloud/utils/onExternalLinkPress';
import { Layout } from ':cloud/widgets/layout';
import { FULL_PAGE_MAX_WIDTH, MAIN_CONTAINER_MIN_WIDTH } from ':cloud/widgets/product/constants';
import { LoadingSpinner } from ':cloud/widgets/sharedcomponents';

function getBasescanURL(txHash: string) {
  return `https://sepolia.basescan.org/tx/${txHash}`;
}

export function Faucet() {
  const { isDesktop } = useBreakpoints();
  const responsiveTemplateColumns = useMemo(() => {
    return isDesktop ? '2fr 1fr' : '1fr';
  }, [isDesktop]);

  const [address, setAddress] = useState('');
  const [txnHash, setTxnHash] = useState<FaucetResponse>();

  const faucetMutation = useFaucet({ onRequestComplete: setTxnHash });

  const handleOnChangeAddress = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value.trim());
  }, []);

  const handleClaim = useCallback(() => {
    faucetMutation.mutate({ address, verified: false });
  }, [address, faucetMutation]);

  return (
    <Layout enableMaxWidth={false}>
      <Layout.MainContainer
        minWidth={MAIN_CONTAINER_MIN_WIDTH}
        spacingHorizontal={0}
        spacingTop={0}
        fullWidth
      >
        <Box display="block" width="100%" minWidth="100%">
          <VStack alignItems="center">
            <HStack>
              <Grid
                maxWidth={FULL_PAGE_MAX_WIDTH}
                templateColumns={responsiveTemplateColumns}
                gap={9}
                spacing={9}
              >
                <VStack justifyContent="center" alignItems="center">
                  <FaucetImg />
                  <TextDisplay3 as="h1">Get free Base Sepolia ETH</TextDisplay3>
                  <VStack justifyContent="center" alignItems="center" maxWidth={600}>
                    <TextTitle4 align="center" as="h2" color="foregroundMuted" spacingTop={1}>
                      You can request 0.5 Base Sepolia ETH every 7 days.
                    </TextTitle4>
                    <TextTitle4 align="center" as="h2" color="foregroundMuted" spacingBottom={4}>
                      To prevent spam and abuse, the address must have a balance of at least 0.001
                      ETH on Ethereum mainnet.
                    </TextTitle4>
                  </VStack>
                  {txnHash && !faucetMutation.isLoading ? (
                    <>
                      <TextTitle4 as="h2" color="foregroundMuted" spacingTop={1} spacingBottom={7}>
                        Claim successful!
                      </TextTitle4>
                      <ButtonGroup>
                        <Button
                          variant="primary"
                          endIcon="diagonalUpArrow"
                          onPress={onExternalLinkPress(getBasescanURL(txnHash.txHash))}
                          accessibilityLabel="View on Etherscan"
                        >
                          View on Basescan
                        </Button>
                      </ButtonGroup>
                    </>
                  ) : (
                    <>
                      <TextInput
                        label="Address"
                        required
                        placeholder="0x..."
                        value={address}
                        onChange={handleOnChangeAddress}
                      />
                      <br />
                      <ButtonGroup>
                        {faucetMutation.isLoading ? (
                          <LoadingSpinner />
                        ) : (
                          <Button
                            variant="primary"
                            accessibilityLabel="View docs + opens in new window"
                            onPress={handleClaim}
                          >
                            Claim
                          </Button>
                        )}
                      </ButtonGroup>
                    </>
                  )}
                </VStack>
              </Grid>
            </HStack>
          </VStack>
        </Box>
      </Layout.MainContainer>
    </Layout>
  );
}
