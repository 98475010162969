import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import _isBoolean from 'lodash/isBoolean';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { GetUserResponse } from ':cloud/types/service_proto';
import {
  CurrentOrganization,
  LegacyUser,
  Organization,
  User,
  UserDerivedProperties,
} from ':cloud/types/ts_types';
import { CustomNetworkError } from ':cloud/utils/errors';
import { SessionContext } from ':cloud/utils/SessionProvider';

async function fetchUser(): Promise<GetUserResponse> {
  const path = `/organization-management/v1/self`;
  const { data } = await APIClientBase.get<GetUserResponse>(path);
  return data;
}

export type FormattedUser = Omit<LegacyUser, 'organizations' | 'currentOrganization'> &
  Pick<User, 'displayName' | 'exchangeEnabled'> &
  UserDerivedProperties;

function generateUserResponse({
  data,
  userUuid,
}: {
  data?: GetUserResponse;
  userUuid?: string;
}): FormattedUser {
  const derivedUserProperties = {
    isInternalUser:
      data?.email.endsWith('@bisontrails.co') || data?.email.endsWith('@coinbase.com') || false,
    isOwnerOrAdmin:
      data?.legacyResponse.currentOrganization.role === 'admin' ||
      data?.legacyResponse.currentOrganization.role === 'owner' ||
      false,
    shouldShowDevModeToggle: _isBoolean(data?.legacyResponse.developerModeEnabled) ?? false,
  };

  return {
    userUuid: userUuid || '',

    userId: data?.userId || '',
    email: data?.email || '',
    avatarUrl: data?.avatarUrl || '',
    displayName: data?.displayName || '',
    exchangeEnabled: data?.exchangeEnabled || false,

    familyName: data?.legacyResponse.familyName || '',
    name: data?.legacyResponse.name || '',
    developerModeEnabled: data?.legacyResponse.developerModeEnabled ?? false,

    ...derivedUserProperties,
  };
}

function generateUserQuery() {
  return {
    queryKey: [QueryKey.self],
    queryFn: fetchUser,
    staleTime: 60 * 1000, // 1 minute
    retry: 3,
  };
}

export function useGetUser(): {
  isLoading: boolean;
  user: FormattedUser;
  prunedOrganizations?: Organization[];
  activeOrg?: CurrentOrganization;
} {
  const { sessionResponse } = useContext(SessionContext);
  const { isLoading, data, error, isSuccess } = useQuery(generateUserQuery());
  const user = generateUserResponse({ data, userUuid: sessionResponse?.userUuid });

  if (error) {
    const status = (error as unknown as AxiosError)?.response?.status;
    // 401s indicate expired session and don't need to throw an error
    // user will be shown a modal to re-authenticate
    if (status !== 401) {
      throw new CustomNetworkError('Error from useGetUser', status as number);
    }
  }
  if (isSuccess && !data?.currentOrganization?.organizationId) {
    /* App will hault to loading state if we can't resolve current organization */
    throw new CustomNetworkError("Can't resolve current organization", 404);
  }

  return {
    isLoading,
    user,
    prunedOrganizations: data?.legacyResponse.organizations,
    activeOrg: data?.legacyResponse.currentOrganization,
  };
}
