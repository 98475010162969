import { useMemo } from 'react';
import { Button } from '@cbhq/cds-web/buttons';
import { VStack } from '@cbhq/cds-web/layout';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@cbhq/cds-web/overlays';
import { TextBody, TextLabel1 } from '@cbhq/cds-web/typography';

import { WhitelistedAddressAndMethod } from ':cloud/types/ts_types';

type PaymasterViewAllowlistModalProps = {
  onRequestClose: () => void;
  onRemovePress: () => void;
  whitelistItem: WhitelistedAddressAndMethod;
};
export function PaymasterViewAllowlistModal({
  onRemovePress,
  onRequestClose,
  whitelistItem,
}: PaymasterViewAllowlistModalProps) {
  const whitelistMethods = useMemo(() => {
    if (whitelistItem?.methods?.length) {
      return whitelistItem.methods?.join(', ');
    }
    return 'All functions';
  }, [whitelistItem.methods]);

  return (
    <Modal onRequestClose={onRequestClose} visible>
      <ModalHeader title="Details" />
      <ModalBody>
        <VStack maxWidth={600} spacing={1}>
          <TextLabel1 as="p" spacingBottom={1}>
            Name
          </TextLabel1>
          <TextBody as="p" spacingBottom={4}>
            {whitelistItem?.name || '--'}
          </TextBody>
          <TextLabel1 as="p" spacingBottom={1}>
            Contract Address
          </TextLabel1>
          <TextBody as="p" spacingBottom={4}>
            {whitelistItem.address}
          </TextBody>
          <TextLabel1 as="p" spacingBottom={1}>
            Functions
          </TextLabel1>
          <TextBody as="p">{whitelistMethods}</TextBody>
        </VStack>
      </ModalBody>
      <ModalFooter
        primaryAction={
          <Button onPress={onRemovePress} variant="negative">
            Remove
          </Button>
        }
      />
    </Modal>
  );
}
