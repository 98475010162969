import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { CreatePlatformTokenResponse } from ':cloud/types/service_proto';

async function createToken(
  organizationId?: string,
  projectId?: string,
  isTestnet?: boolean,
): Promise<CreatePlatformTokenResponse | undefined> {
  const path = `/apikeys/v1/organizations/${organizationId}/projects/${projectId}/tokens`;

  const { data } = await APIClientBase.post<CreatePlatformTokenResponse>(path, { isTestnet });

  return data;
}

export function useCreateToken({ organizationId = '', projectId = '', isTestnet = false }) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => createToken(organizationId, projectId, isTestnet),
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKey.tokens, organizationId, projectId]);
    },
  });
}
