import _capitalize from 'lodash/capitalize';
import { Payload, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { Icon } from '@cbhq/cds-web/icons';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { TextBody } from '@cbhq/cds-web/typography';

import { wholeOrFormatDecimals } from ':cloud/widgets/earnings/utils';

type EarningsChartTooltipProps = {
  active?: boolean;
  payload?: TooltipPayload[];
  label?: string;
};

// TODO: revise ticks cropping once we have access to an active API
const TOOLTIP_COLOR = 'rgb(var(--indigo50))';
type TooltipPayload = Payload<ValueType, string>;
export function EarningsChartTooltip({ active, payload }: EarningsChartTooltipProps) {
  if (active && payload && payload.length > 0) {
    return (
      <VStack
        background="background"
        spacing={3}
        gap={1}
        borderRadius="rounded"
        borderColor="line"
        elevation={2}
      >
        {payload.map(({ name, value, unit, payload: pointPayload }) => {
          return (
            <VStack gap={1} justifyContent="space-between" key={name}>
              {/* eg. Fri, Aug 29, 2024 */}
              <TextBody as="p" mono color="foregroundMuted">
                {pointPayload?.tooltipDate}
              </TextBody>
              {/* eg. Ethereum 18.32 ETH */}
              <HStack alignItems="center" gap={0.5}>
                <Icon name="dot" size="m" dangerouslySetColor={TOOLTIP_COLOR} />
                <TextBody as="p" dangerouslySetColor={TOOLTIP_COLOR}>
                  {`${_capitalize(name)} ${wholeOrFormatDecimals(value, 2)} ${unit}`}
                </TextBody>
              </HStack>
            </VStack>
          );
        })}
      </VStack>
    );
  }

  return null;
}
