import { useMemo, useState } from 'react';

import { NewClusterContext } from ':cloud/contexts/NewClusterContext';
import { GenericProviderProps, IFormDataType } from ':cloud/types/ts_types';

export default function NewClusterProvider({ children }: GenericProviderProps) {
  const [formData, setFormData] = useState<IFormDataType>();
  const [formBtnRef, setFormBtnRef] = useState<HTMLButtonElement | undefined>(undefined);
  const [isDeploying, setIsDeploying] = useState<boolean>(false);
  const [workflowVersion, setWorkflowVersion] = useState<string | undefined>(undefined);

  const reset = (): void => {
    setFormData(undefined);
    setIsDeploying(false);
    setWorkflowVersion(undefined);
  };

  const value = useMemo(() => {
    return {
      setIsDeploying,
      formBtnRef,
      formData,
      workflowVersion,
      isDeploying,
      reset,
      setFormBtnRef,
      setFormData,
      setWorkflowVersion,
    };
  }, [formBtnRef, formData, isDeploying, workflowVersion]);

  return <NewClusterContext.Provider value={value}>{children}</NewClusterContext.Provider>;
}
