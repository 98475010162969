import { useCallback, useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useToggler } from '@cbhq/cds-common/hooks/useToggler';
import { VStack } from '@cbhq/cds-web/layout';
import { Alert, Modal, ModalBody, ModalHeader } from '@cbhq/cds-web/overlays';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { Pressable } from '@cbhq/cds-web/system';
import { TextHeadline } from '@cbhq/cds-web/typography';

import { useAppState } from ':cloud/contexts/AppStateContext';
import { useIsOnParticipateAllowlist } from ':cloud/hooks/featureFlags/useIsOnParticipateAllowlist';
import { useAccountCreditsBalances } from ':cloud/hooks/useAccountCreditsBalances';
import useLocalStorage from ':cloud/hooks/useLocalStorage';
import { EventName, logClick, useLogViewOnMount } from ':cloud/init/clientAnalytics/logging';
import { useDeletePaymentMethod } from ':cloud/queries/BillingQueries/useDeletePaymentMethod';
import { useGetAccountItems } from ':cloud/queries/BillingQueries/useGetAccountItems';
import { useGetPaymentMethod } from ':cloud/queries/BillingQueries/useGetPaymentMethod';
import { useGetProjects } from ':cloud/queries/Projects/useGetProjects';
import { QueryKey } from ':cloud/queries/types';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { useQueryParam } from ':cloud/utils/routes';
import { CreditBanner } from ':cloud/widgets/base/CreditBanner';
import { PaymentMethodIntake } from ':cloud/widgets/billing/PaymentMethodIntake';
import { Layout } from ':cloud/widgets/layout';
import { PAYMENT_METHOD_CREDIT_TYPE } from ':cloud/widgets/product/constants';
import BillingHistorySection from ':cloud/widgets/settings/billing/BillingHistorySection';
import BillingPaymentSection from ':cloud/widgets/settings/billing/BillingPaymentSection';
import BillingStatsSection from ':cloud/widgets/settings/billing/BillingStatsSection';
import { EmptyState } from ':cloud/widgets/settings/billing/EmptyState';
import { CloudErrorBoundary } from ':cloud/widgets/sharedcomponents';

function BillingPageContent() {
  const isOnParticipateAllowlist = useIsOnParticipateAllowlist();

  const [warningModalVisible, { toggleOn: showWarningModal, toggleOff: hideWarningModal }] =
    useToggler();
  const [
    replacePaymentMethodModalVisible,
    { toggleOn: showReplacePaymentMethodModal, toggleOff: hideReplacePaymentMethodModal },
  ] = useToggler();
  const toast = useToast();

  const viewQueryParam = useQueryParam('view');

  const { activeOrg } = useGetUser();
  const orgId = activeOrg?.organizationId;
  const { accountItems } = useGetAccountItems(orgId);
  const { projects } = useGetProjects(orgId);

  const { selectedProject } = useAppState();
  const [completedSteps, setCompletedSteps] = useLocalStorage(
    `paymasterCompletedSteps-${selectedProject?.id}`,
    [''],
  );

  const { paymentMethod, isLoading: isLoadingPaymentMethod } = useGetPaymentMethod(orgId);
  const { accountCreditsRemaining, accountCreditTypes, additionalCredits } =
    useAccountCreditsBalances();
  const deletePaymentMethod = useDeletePaymentMethod(activeOrg?.organizationId);

  const hasSubscriptionData = useMemo(() => {
    return accountItems.length > 0 || projects.length > 0 || isOnParticipateAllowlist;
  }, [accountItems, projects, isOnParticipateAllowlist]);

  const queryClient = useQueryClient();

  const handleStripeSuccess = useCallback(async () => {
    const successMessage =
      !paymentMethod && !accountCreditTypes.includes(PAYMENT_METHOD_CREDIT_TYPE)
        ? 'Payment method successfully added. You earned $500 credits'
        : 'Payment method successfully added.';

    toast.show(successMessage, {
      variant: 'positive',
    });
    await queryClient.invalidateQueries({ queryKey: [QueryKey.billing, 'paymentMethod', orgId] });
    await queryClient.invalidateQueries({ queryKey: [QueryKey.accountCredits, orgId] });
    hideReplacePaymentMethodModal();
    logClick(EventName.billing_add_payment_method);
    if (!completedSteps.includes('payment')) {
      setCompletedSteps(completedSteps?.concat('payment'));
    }
  }, [
    paymentMethod,
    accountCreditTypes,
    toast,
    queryClient,
    orgId,
    hideReplacePaymentMethodModal,
    completedSteps,
    setCompletedSteps,
  ]);

  /**
   * Handles errors that happen _after_ Stripe submits the payment method
   * (i.e. card denials, not entry validation errors.)
   */
  const handleStripeError = useCallback(
    (intent) => {
      if (intent.last_setup_error?.message) {
        toast.show(intent.last_setup_error.message, {
          variant: 'negative',
        });
      }
    },
    [toast],
  );

  const handleStripePaymentMethodError = useCallback(
    (errorMessage: string | undefined) => {
      if (errorMessage) {
        toast.show(errorMessage, {
          variant: 'negative',
        });
      }
    },
    [toast],
  );

  const handlePressReplacePaymentMethod = useCallback(() => {
    showReplacePaymentMethodModal();
  }, [showReplacePaymentMethodModal]);

  const handleDeletePaymentMethod = useCallback(() => {
    deletePaymentMethod.mutate(paymentMethod?.externalId || '');
  }, [deletePaymentMethod, paymentMethod?.externalId]);

  const handlePressDeletePaymentMethod = useCallback(() => {
    showWarningModal();
  }, [showWarningModal]);

  useEffect(() => {
    if (viewQueryParam === 'payment') {
      showReplacePaymentMethodModal();
    }
  }, [showReplacePaymentMethodModal, viewQueryParam]);

  return !hasSubscriptionData ? (
    <EmptyState />
  ) : (
    <>
      {replacePaymentMethodModalVisible && (
        <Modal
          visible
          onRequestClose={hideReplacePaymentMethodModal}
          width={600}
          shouldCloseOnEscPress={false}
        >
          <ModalHeader title="Add payment method" />
          <ModalBody>
            <VStack spacingBottom={5}>
              <PaymentMethodIntake
                onCancel={hideReplacePaymentMethodModal}
                returnRoute="/settings/billing"
                parentComponentName="Billing"
                actionsInline
                onSuccess={handleStripeSuccess}
                onRequiresPaymentMethod={handleStripeError}
                onError={handleStripePaymentMethodError}
              />
            </VStack>
          </ModalBody>
        </Modal>
      )}
      {warningModalVisible && (
        <Alert
          visible
          pictogram="warning"
          title="Are you sure?"
          body="Without a payment method on file, usage in excess of credits may result in interruption of service."
          onRequestClose={hideWarningModal}
          onPreferredActionPress={handleDeletePaymentMethod}
          preferredActionVariant="negative"
          preferredActionLabel="Delete"
          dismissActionLabel="Keep method"
        />
      )}
      {/* eligible for $500 if user hasn't added a payment method or received the credit yet  */}
      {!paymentMethod &&
        !accountCreditTypes.includes(PAYMENT_METHOD_CREDIT_TYPE) &&
        !isLoadingPaymentMethod && (
          <CreditBanner
            spacingHorizontal={7}
            spacingTop={0}
            spacingBottom={5}
            title="Get $500 in credits"
            description="Just add a payment method. This ensures uninterrupted service beyond your credit balance."
            primaryAction={
              <Pressable background="transparent" onPress={handlePressReplacePaymentMethod}>
                <TextHeadline as="p" color="primary">
                  Add a payment method
                </TextHeadline>
              </Pressable>
            }
          />
        )}
      <VStack>
        <BillingStatsSection
          accountCreditsRemaining={accountCreditsRemaining}
          additionalCredits={additionalCredits}
        />
        <BillingPaymentSection
          isLoadingPaymentMethod={isLoadingPaymentMethod}
          onDeletePaymentMethod={handlePressDeletePaymentMethod}
          onReplacePaymentMethod={handlePressReplacePaymentMethod}
          paymentMethod={paymentMethod}
        />
        <BillingHistorySection />
      </VStack>
    </>
  );
}

export function BillingPage() {
  useLogViewOnMount(EventName.billing_page_view);
  return (
    <Layout enableMaxWidth={false}>
      <Layout.MainContainer spacingHorizontal={0} fullWidth>
        <CloudErrorBoundary name="BillingPageContent">
          <BillingPageContent />
        </CloudErrorBoundary>
      </Layout.MainContainer>
    </Layout>
  );
}
