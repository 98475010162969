import _filter from 'lodash/filter';
import _findKey from 'lodash/findKey';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _keyBy from 'lodash/keyBy';
import _last from 'lodash/last';
import _map from 'lodash/map';
import _mapValues from 'lodash/mapValues';
import _merge from 'lodash/merge';
import _omit from 'lodash/omit';
import _reduce from 'lodash/reduce';
import _round from 'lodash/round';
import _sumBy from 'lodash/sumBy';
import { PaletteForeground } from '@cbhq/cds-common';
import { zIndex } from '@cbhq/cds-common/tokens/zIndex';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { Divider } from '@cbhq/cds-web/layout/Divider';
import { TextBody, TextHeadline, TextLabel2 } from '@cbhq/cds-web/typography';

import { EM_DASH } from ':cloud/brand/constants';
import {
  Eth2Pod,
  IClusterArtifacts,
  IConditionalOption,
  IConfiguration,
  IMetricData,
  IMetricDataType,
  IMetricsRoot,
  IWidgetSchema,
  MetricValue,
  SchemaSourceType,
} from ':cloud/types/ts_types';
import { getChartDateTime } from ':cloud/utils/date';
import { bytesToGigaBit, parseNewMetricValue } from ':cloud/utils/metrics';
import { ChartConfig } from ':cloud/widgets/clusters/schemaWidget/types';
import { TextLabelGray } from ':cloud/widgets/sharedcomponents';

function getXAxisTickLabels(data: IChartPointType[] = []): undefined | string[] {
  // TODO(olivier.suritz): Extra checks should not be necessary if TypeScript does its job.
  if (data == null || data.length < 2) {
    return undefined;
  }
  return [data[0].timestamp, data[data.length - 1].timestamp];
}

function getMetricsByQueryKey(
  metrics: IMetricsRoot,
  queryKey: string,
  formatValue?: (data: any) => any,
  formatQueryKeyValue?: (data: any) => any,
) {
  /* handle edge cases when several data properties from prometheus are missing */
  const rawData = metrics.data?.[queryKey];
  if (!rawData || rawData.length === 0) {
    return undefined;
  }
  const { value, values } = rawData[0];
  const computedData = value ? [value] : values;
  if (!computedData) {
    return undefined;
  }

  /* eslint-disable camelcase */
  const graphKeyMapper = {
    success: '2XX',
    clientErr: '4XX',
    serverErr: '5XX',
    total: 'XXX',
    latency: 'latency',
    cpu_usage_percent: 'cpu_usage_percent',
    uptime: 'uptime_48h_12h',
    votes: 'votes',
    proposals: 'proposals',
    mempool_size: 'mempool_size',
    transactions_per_second: 'transactions_per_second',
    blocks_per_second: 'blocks_per_second',
    rewards: 'rewards',
    attestations: 'attestations',
    aggregations: 'aggregations',
  };
  /* eslint-enable camelcase */
  const graphKey: string = _findKey(graphKeyMapper, (k) => queryKey.includes(k)) || queryKey;
  const formatPointData = (data: string) => {
    if (data === 'NaN') {
      return 0;
    }
    if (typeof formatQueryKeyValue === 'function') {
      return formatQueryKeyValue(data) as number;
    }
    return parseInt(data, 10);
  };
  if (typeof formatValue === 'function') {
    return formatValue(computedData[0][1]) as number;
  }
  return computedData?.map((pointData: any) => ({
    unixTimestamp: pointData[0],
    timestamp: pointData?.[0] ? getChartDateTime(pointData[0]) : undefined,
    [graphKey]: formatPointData(pointData[1]),
  }));
}

type RadialColumnProps = { children: React.ReactNode };

function RadialColumn({ children }: RadialColumnProps) {
  return (
    <VStack justifyContent="space-between" height={135} width={88}>
      {children}
    </VStack>
  );
}

// TODO(arti.villa):  fix return type to IChartPointType[]
function getGraphMetricsByKey(
  metrics: IMetricData,
  chartKey: string | undefined,
  queryKey: string | undefined,
  formatter?: (string: string) => any,
): any[] | undefined {
  if (!chartKey) {
    return undefined;
  }
  const queryKeyVal = queryKey || chartKey;
  if (!metrics?.[queryKeyVal]?.[0]?.values) {
    return undefined;
  }
  const rawData = metrics?.[queryKeyVal]?.[0]?.values;
  const formatterImpl = formatter || parseFloat;
  return rawData?.map((pointData: MetricValue) => ({
    unixTimestamp: pointData[0],
    timestamp: pointData?.[0] ? getChartDateTime(pointData[0]) : undefined,
    [chartKey]: pointData[1] === 'NaN' ? 0 : formatterImpl(pointData[1]),
  }));
}

function getTooltipConfig(options: any, sources: IWidgetSchema['sources']) {
  const metricOptions = options.sources.metrics;
  const toolTipPayload = sources
    .filter((source: SchemaSourceType) => metricOptions[source.key].tooltip)
    .map((source: SchemaSourceType) => ({
      name: metricOptions[source.key].tooltip.label,
      unit: metricOptions[source.key].tooltip.unit,
      dataKey: source.key,
    }));
  return _keyBy(toolTipPayload, 'dataKey');
}

function getFormattedDataValue(
  dataObj: any,
  formatValue?: (a: string) => number,
): number | undefined {
  if (!dataObj || !_has(dataObj, 'value[1]')) {
    return undefined;
  }

  return formatValue ? formatValue(dataObj.value[1]) : _round(dataObj.value[1], 2);
}

function getMetricValueByEthClient(
  metricsData: IMetricData,
  metricDataKey: string,
  matchesIdentifierProps: object,
  formatValue: ValueParser,
): undefined | number {
  if (!_has(metricsData, metricDataKey)) {
    return undefined;
  }
  const data: IMetricDataType[] | null = metricsData?.[metricDataKey];
  if (!data) {
    return undefined;
  }

  if (data.length === 1) {
    return getFormattedDataValue(data[0], formatValue);
  }
  if (data.length > 1) {
    const obj = _filter(data, { metric: matchesIdentifierProps });
    if (metricDataKey.startsWith('disk_used') && obj.length >= 2) {
      /* sum the disk used mount volumes when you get multiple validators or beacons in the same region */
      const volume = _sumBy(obj, (volumeObj: any) => Number(volumeObj.value[1]));
      return formatValue ? formatValue(volume) : _round(volume, 2);
    }
    return getFormattedDataValue(obj[0], formatValue);
  }

  return undefined;
}

type ValueParser = (value: string | number) => number;

function getSourceFormatter(sourceType: string): ValueParser {
  return (x: string | number) => {
    switch (sourceType) {
      case 'bytes':
        return bytesToGigaBit(parseInt(x as string, 10));
      case 'percent':
        return Number.parseFloat(x as string) * 100;
      case 'int':
      case 'number':
      default:
        return parseInt(x as string, 10);
    }
  };
}

/* deriveValueFromMetrics is a specialized value for metric source queries  */
function deriveValueFromMetrics(
  sourceInput: SchemaSourceType,
  configuration: IConfiguration,
  isDynamic = false,
  metricsData: IMetricData | undefined = undefined,
  pod: Eth2Pod | undefined = undefined,
): number | string | undefined {
  if (!metricsData) {
    return undefined;
  }
  if (configuration?.eth2Environment && isDynamic) {
    /* override for eth2 radial, peer and validator count charts */
    return getMetricValueByEthClient(
      metricsData,
      sourceInput.key,
      { region: pod?.region },
      getSourceFormatter(sourceInput.type),
    );
  }
  return parseNewMetricValue(metricsData[sourceInput.key], sourceInput.type) as string;
}

function deriveValueFromSource(
  sourceInput: SchemaSourceType,
  configuration: IConfiguration,
  artifacts: IClusterArtifacts,
  metricsData?: IMetricData,
  pod?: Eth2Pod,
  isDynamic = false,
): number | string | undefined {
  switch (sourceInput.source) {
    case 'metrics':
      return deriveValueFromMetrics(sourceInput, configuration, isDynamic, metricsData, pod);
    case 'artifacts':
      return _get(artifacts, sourceInput.key) as number | string;
    case 'configuration':
      // TODO: Temp hack to get specific node count
      if (sourceInput.key === 'topology.node_count') {
        // Maintain compatibility with non-upgraded resources whose workflows have been updated
        return configuration.topology
          ? _reduce(configuration.topology, (acc, cluster) => acc + cluster.scale, 0)
          : configuration.replicaCount;
      }
      return _get(configuration, sourceInput.key) as number | string;
    default:
      return undefined;
  }
}

function excludeWidget(
  conditional: IConditionalOption,
  configuration: IConfiguration,
  artifacts: IClusterArtifacts,
  metricsData?: IMetricData,
): boolean {
  const value =
    conditional?.source?.source === 'metrics'
      ? deriveValueFromMetrics(conditional.source, configuration, false, metricsData)
      : deriveValueFromSource(conditional.source, configuration, artifacts);
  return value !== conditional.value;
}

function getChartDisplayValue(data: any, dataKey: string): number | string {
  if (!data) {
    return '--';
  }
  const arr = _map(data, (o) => parseFloat(o[dataKey]));
  // TODO(arti.villa):  update _last to take in compute function from workflows
  const result = _last(arr.filter(Boolean));
  if (result) {
    return result < 1 ? _round(result, 2) : _round(result, 0);
  }
  return '--';
}

const tickStyles = {
  fontFamily: 'var(--label2-font-family)',
  fontSize: 'var(--label2-font-size)',
  fontWeight: 'var(--label2-font-weight)',
  letterSpacing: 'var(--label2-letter-spacing)',
  lineheight: 'var(--label2-line-height)',
  fill: 'var(--foregroundMuted)',
  fontVariant: 'all-small-caps',
};

const xAxisProps = {
  dataKey: 'timestamp',
  height: 40,
  tickLine: false,
  tickMargin: 18,
};

const yAxisProps = {
  angle: -90,
  axisLine: { stroke: 'none' },
  stroke: 'var(--foregroundMuted)',
  orientation: 'left' as 'left' | 'right' | undefined,
  tick: { ...tickStyles },
  tickCount: 3,
  tickLine: false,
  tickMargin: 24,
};

const tooltipProps = {
  allowEscapeViewBox: { x: true, y: true },
  wrapperStyle: { zIndex: zIndex.overlays.tooltip },
};

interface CustomTooltipProps {
  active: boolean;
  payload?: any;
  customPayload?: any;
  mapPayloadKeys: any;
  isWide?: boolean;
}

interface AxisProp {
  name: string;
  value?: string;
  unit: string;
}

type AxisProps = Record<string, AxisProp>;

function CustomTooltip({ active = false, payload = [], mapPayloadKeys }: CustomTooltipProps) {
  if (active && payload && payload.length > 0) {
    const { era, timestamp, placeholder = false, ...axis } = payload[0].payload;
    const axisValues = _mapValues(_omit(axis, ['unixTimestamp', 'idx', 'era']), (r) => ({
      value: _round(r?.raw ?? r, 2),
    }));
    const axisProps: AxisProps = _merge(axisValues, mapPayloadKeys);

    return (
      <VStack
        background="background"
        justifyContent="space-between"
        minHeight={109}
        width={177}
        spacing={3}
        borderColor="lineHeavy"
      >
        <Box flexWrap="wrap" justifyContent="space-between">
          {Object.entries(axisProps).map(([k, { name, value = '--', unit }]) => (
            <VStack spacingVertical={1} key={k}>
              <TextHeadline as="p" color={placeholder ? 'foregroundMuted' : undefined}>
                {placeholder ? EM_DASH : value}
                {placeholder ? null : unit}
              </TextHeadline>
              <TextLabelGray>{name}</TextLabelGray>
            </VStack>
          ))}
        </Box>
        {Boolean(timestamp) && (
          <TextBody as="span" spacingTop={0.5}>
            {timestamp}
          </TextBody>
        )}
        {Boolean(era) && <TextLabel2 as="span">Era #{era}</TextLabel2>}
      </VStack>
    );
  }
  return null;
}

interface CustomLegendProp {
  payload: ChartConfig[];
  withDivider?: boolean;
}

function CustomLegend({ payload = [], withDivider = true }: CustomLegendProp) {
  return (
    <div>
      {withDivider && <Divider direction="horizontal" color="line" spacingVertical={4} />}
      <Box
        flexWrap="wrap"
        spacingHorizontal={1}
        justifyContent="center"
        alignItems="center"
        height={88}
      >
        {payload.map(({ color, value }) => (
          <HStack alignItems="center" spacingHorizontal={1} key={color} gap={0.5}>
            <Icon name="dot" size="s" color={color as PaletteForeground} />
            <TextLabelGray as="span">{value}</TextLabelGray>
          </HStack>
        ))}
      </Box>
    </div>
  );
}

interface CustomXAxisTickProps {
  x?: number;
  y?: number;
  payload: { value: string | number };
  width?: number;
  lineheight?: number;
  ticks: (string | number)[];
}

// CustomXAxisTick takes strings and splits into two lines (first two words, remaining words)
function CustomXAxisTick({
  x,
  y,
  payload,
  width = 50,
  lineheight = 14,
  ticks,
}: CustomXAxisTickProps) {
  const shiftTick = ticks && ticks[1] === payload.value;
  const chunks = String(payload.value).split(' ');
  const terms = [chunks.slice(0, 2).join(' '), chunks.slice(2)];
  const tspans = terms.map((s, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <tspan key={`${s}-${i}`} x={shiftTick ? -30 : 0} y={lineheight * i}>
      {s}
    </tspan>
  ));
  return (
    <g transform={`translate(${x},${y})`}>
      <text width={width} height="auto" {...tickStyles}>
        {tspans}
      </text>
    </g>
  );
}

export interface IChartPointType {
  uptime?: string;
  placeholder?: boolean;
  idx?: number;
  unixTimestamp: Date;
  timestamp: string;
}

export {
  CustomLegend,
  CustomTooltip,
  CustomXAxisTick,
  deriveValueFromMetrics,
  deriveValueFromSource,
  excludeWidget,
  getChartDisplayValue,
  getGraphMetricsByKey,
  getMetricsByQueryKey,
  getTooltipConfig,
  getXAxisTickLabels,
  RadialColumn,
  tooltipProps,
  xAxisProps,
  yAxisProps,
};
