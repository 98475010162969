import { useCallback } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { VStack } from '@cbhq/cds-web/layout';
import { TextTitle1 } from '@cbhq/cds-web/typography';

import { useNewCluster } from ':cloud/contexts/NewClusterContext';
import { logError } from ':cloud/init/bugsnag/logging';
import { useCreateResource } from ':cloud/queries/ResourceQueries/useCreateResource';
import WithElements from ':cloud/widgets/billing/WithElements';
import { Layout } from ':cloud/widgets/layout';
import { ClusterInterstitial } from ':cloud/widgets/sharedcomponents';

import InvoicePaymentDisplayer from './InvoicePaymentDisplayer';

function CreateClusterBilling() {
  const newCluster = useNewCluster();
  const location = useLocation();
  const protocolNameFromPath = location.pathname.split('/')[3];
  const createNetworkMutation = useCreateResource(newCluster?.formData, newCluster.workflowVersion);

  const launchCluster = useCallback(async () => {
    newCluster.setIsDeploying(true);

    return new Promise<void>((resolve, reject) => {
      createNetworkMutation.mutate(undefined, {
        onSuccess: () => {
          newCluster.setIsDeploying(false);
          resolve();
        },
        onError: (err) => {
          logError(err, { context: 'create_cluster_billing' });
          newCluster.setIsDeploying(false);
          reject(err);
        },
      });
    });
  }, [createNetworkMutation, newCluster]);

  if (!newCluster.formData) {
    const previousPage = `/participate/new/${protocolNameFromPath}`;
    return <Redirect to={previousPage} />;
  }

  return (
    <div>
      <Layout.ContentWrapper>
        <VStack maxWidth={700}>
          <TextTitle1 spacingVertical={5} as="h2">
            Review &amp; pay
          </TextTitle1>
          {newCluster.formData?.name && newCluster.formData?.protocolKey && (
            <ClusterInterstitial formData={newCluster.formData} />
          )}
        </VStack>
      </Layout.ContentWrapper>
      <VStack spacingTop={10}>
        <TextTitle1 as="h2" spacingBottom={1}>
          Payment details
        </TextTitle1>
        <InvoicePaymentDisplayer
          displaySubmitAction={launchCluster}
          displaySubmitLabel="Launch cluster"
          entrySubmitAction={launchCluster}
          entrySubmitLabel="Save & launch cluster"
          initialView="display"
          enableClusterSummaryRef={false}
          entryCancelText=""
        />
      </VStack>
    </div>
  );
}

export default WithElements<any>(CreateClusterBilling);
