import axios, { AxiosError, type AxiosInstance, AxiosResponse } from 'axios';

import config from ':cloud/config';
import { logError } from ':cloud/init/bugsnag/logging';
import { mapKeysToCamelCase } from ':cloud/utils/common';

const SHARED_API_SETTINGS = {
  headers: {
    'content-type': 'application/json',
  },
  withCredentials: true,
};

/**
 * Our default API client for all requests.
 */
export const APIClientBase: AxiosInstance = axios.create({
  baseURL: config.apiBaseURL,
  ...SHARED_API_SETTINGS,
});

APIClientBase.interceptors.response.use((response) =>
  mapKeysToCamelCase<AxiosResponse<any, any>>(response),
);

export const APIClientBaseRPC: AxiosInstance = axios.create({
  baseURL: config.developerAPIURL,
  ...SHARED_API_SETTINGS,
});

export const APIClientPortfolio: AxiosInstance = axios.create({
  baseURL: config.apiPortfolioURL,
  ...SHARED_API_SETTINGS,
});

/**
 * FOR LEGACY COMS READ RESPONSES ONLY
 *
 * Every COMS read endpoint has a legacyResponse field that contains the old
 * RM response shape alongside the new data shape in the main object. This
 * can be used to migrate in multiple phases.
 *
 * For all COMS write endpoints or any COMS read endpoints that use the NEW
 * data shapes, use APIClientBase.
 */
export const APIClientCOMSLegacy: AxiosInstance = axios.create({
  baseURL: config.apiBaseURL,
  ...SHARED_API_SETTINGS,
});

type COMSResponse = {
  [key: string]: any;
  legacyResponse: any;
};

export type COMSErrorResponse = {
  code: string;
  message: string;
  [key: string]: any;
};

APIClientCOMSLegacy.interceptors.response.use(
  (response: AxiosResponse<COMSResponse>) => {
    const { legacyResponse } = response.data;
    const camelCaseResponse = mapKeysToCamelCase<AxiosResponse<any>>(legacyResponse);
    return { ...response, data: camelCaseResponse };
  },
  async (error: AxiosError<COMSErrorResponse>) => {
    logError(error, { context: 'api_client', severity: 'error' });
    return Promise.reject(error);
  },
);

/**
 * Used for legacy Resource Manager endpoints only.
 */
export const APIClientRM = axios.create({
  baseURL: config.resourceManagerServer,
  ...SHARED_API_SETTINGS,
});
