import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';

import { useGetDashboard } from ':cloud/hooks/useGetDashboard';
import { defaultDashboardConfig } from ':cloud/widgets/monitoring/monitoringConfig';
import { Dashboard, DashboardRange } from ':cloud/widgets/monitoring/types';

type DashboardContextType = {
  dashboard: Dashboard | undefined;
  range: DashboardRange;
  setRange: Dispatch<SetStateAction<DashboardRange>>;
  errorBoundaryKey: number;
  setErrorBoundaryKey: Dispatch<SetStateAction<number>>;
};

const DashboardContext = createContext<DashboardContextType | undefined>(undefined);

export function useDashboardContext() {
  const context = useContext(DashboardContext);
  if (context === undefined) {
    throw new Error('useDashboardContext must be used within a DashboardProvider');
  }
  return context;
}

export function DashboardProvider({ children }) {
  const [range, setRange] = useState<DashboardRange>(defaultDashboardConfig.range);
  const [errorBoundaryKey, setErrorBoundaryKey] = useState(0);
  const { dashboard } = useGetDashboard();

  const providerValue = useMemo(
    () => ({ dashboard, range, setRange, errorBoundaryKey, setErrorBoundaryKey }),
    [dashboard, range, setRange, errorBoundaryKey, setErrorBoundaryKey],
  );

  return <DashboardContext.Provider value={providerValue}>{children}</DashboardContext.Provider>;
}
