import _forEach from 'lodash/forEach';

import {
  ClusterStateType,
  IAdapter,
  ICluster,
  IMetricData,
  IWorkflow,
  Region,
} from ':cloud/types/ts_types';

import { AppRoute } from './routes';

export function filterClusterMetrics(d: any): IMetricData {
  const data = {};

  _forEach(d, (m, k) => {
    if (m && (!m[0] || m[0]?.value)) {
      data[k] = m;
    }
  });

  return data;
}

function isParticipationCluster(cluster: ICluster): boolean {
  return cluster.clusterType === 'participation';
}

function isQTNetwork(network: IWorkflow): boolean {
  return network.clusterType === 'qt';
}

function isParticipationNetwork(network: IWorkflow): boolean {
  return !isQTNetwork(network);
}

export const participateAdapter: IAdapter = {
  clustersFilter: (c: ICluster) => isParticipationCluster(c),
  networksFilter: (n: IWorkflow) => isParticipationNetwork(n),
  networkDetails: (n: IWorkflow) => n,
  hasTokens: () => false,
  clusterRoute: AppRoute.Participate.Home,
  clusterType: 'participation',
};

export function getAdapter(): IAdapter {
  return participateAdapter;
}

export const statuses: Record<ClusterStateType, ClusterStateType> = {
  BEING_CREATED: 'BEING_CREATED',
  CREATE_FAILED: 'CREATE_FAILED',
  CREATE_COMPLETE: 'CREATE_COMPLETE',

  BEING_UPDATED: 'BEING_UPDATED',
  UPDATE_FAILED: 'UPDATE_FAILED',
  UPDATE_COMPLETE: 'UPDATE_COMPLETE',

  DELETE_FAILED: 'DELETE_FAILED',
  BEING_DELETED: 'BEING_DELETED',
  DELETE_COMPLETE: 'DELETE_COMPLETE',
};

export type ClusterStatusColor = 'primary' | 'negative' | 'positive';

interface IClusterStatusLabel {
  status: 'Creating' | 'Failed' | 'Running' | 'Updating' | 'Deleting' | 'Deleted';
  internalStatus?: 'Completed';
  color: ClusterStatusColor;
}

export function getClusterStatus(clusterStatus: ClusterStateType): IClusterStatusLabel {
  const statusDetails: Record<ClusterStateType | string, IClusterStatusLabel> = {
    [statuses.BEING_CREATED]: {
      status: 'Creating',
      color: 'primary',
    },
    [statuses.CREATE_FAILED]: {
      status: 'Failed',
      color: 'negative',
    },
    [statuses.CREATE_COMPLETE]: {
      internalStatus: 'Completed',
      status: 'Running', // TODO BTC-853: Get actual status
      color: 'positive',
    },

    [statuses.BEING_UPDATED]: {
      status: 'Updating',
      color: 'primary',
    },
    [statuses.UPDATE_FAILED]: {
      status: 'Failed',
      color: 'negative',
    },
    [statuses.UPDATE_COMPLETE]: {
      internalStatus: 'Completed',
      status: 'Running', // TODO BTC-853: Get actual status
      color: 'positive',
    },

    [statuses.BEING_DELETED]: {
      status: 'Deleting',
      color: 'primary',
    },
    [statuses.DELETE_FAILED]: {
      status: 'Failed',
      color: 'negative',
    },
    [statuses.DELETE_COMPLETE]: {
      status: 'Deleted',
      color: 'positive',
    },
  };
  return statusDetails[clusterStatus];
}

export function isClusterAvailableToDelete(cluster: ICluster): boolean {
  const invalidStates = new Set(['Creating', 'Updating']);
  const clusterStatus = getClusterStatus(cluster.state).status;
  return !invalidStates.has(clusterStatus);
}

const clusterRegions: Record<string, Pick<Region, 'locale'>> = {
  'ap-northeast-1.aws.001': {
    locale: 'Tokyo',
  },
  'ap-southeast-1.aws.001': {
    locale: 'Singapore',
  },
  'asia-east2.gcp.001': {
    locale: 'Hong Kong',
  },
  'eu-west-1.aws.001': {
    locale: 'Ireland',
  },
  'europe-west3.gcp.001': {
    locale: 'Frankfurt',
  },
  'us-east-2.aws.001': {
    locale: 'Ohio',
  },
  'us-east1.gcp.001': {
    locale: 'South Carolina',
  },
  'us-west-2.aws.001': {
    locale: 'Oregon',
  },
  'us-east4.gcp.001': {
    locale: 'Virginia',
  },
};
const AWS_DEFAULT_COLOR = '#a74282';
const GPC_DEFAULT_COLOR = '#8039ad';
const AZURE_DEFAULT_COLOR = '#2335d8';
const FALLBACK_GROUP = 'Test Group';
const FALLBACK_PROVIDER = 'Test Provider';

export function getClusterRegion(regionKey: string): Region {
  const parts = regionKey.split('.');
  const provider = (parts[1] as Region['provider']) ?? FALLBACK_PROVIDER;
  const groupName = parts[0].replace(/^\d+/g, '').replace(/-/g, ' ') ?? FALLBACK_GROUP;
  const display = `${provider.toUpperCase()} ${groupName}`;

  let color = '';
  const icon = `providers/${provider}.png`;
  const locale = clusterRegions[regionKey]?.locale ?? '';

  switch (provider) {
    case 'aws':
      color = AWS_DEFAULT_COLOR;
      break;
    case 'gcp':
      color = GPC_DEFAULT_COLOR;
      break;
    case 'azure':
    default:
      color = AZURE_DEFAULT_COLOR;
      break;
  }

  return {
    color,
    locale,
    groupName,
    icon,
    display,
    provider,
  };
}

/** protocol engineers deploy clusters with PR versions appended to test changes
 * this functionality should only be limited in dev mode
 */
// TODO: return false if not in dev mode
export function isDeployedByPipelines(resource: ICluster): boolean {
  return resource?.version.includes('0.0.1-PR-');
}

export function isClusterRunning(cluster: ICluster): boolean {
  if (cluster.state === (statuses.CREATE_COMPLETE || statuses.UPDATE_COMPLETE)) {
    return true;
  }
  return false;
}
