import { useMemo } from 'react';
import { Button } from '@cbhq/cds-web/buttons';
// import { SearchInput } from '@cbhq/cds-web/controls';
import { SpotRectangle } from '@cbhq/cds-web/illustrations';
import { HStack, Spacer, VStack } from '@cbhq/cds-web/layout';
import {
  Table,
  TableBody,
  TableCell,
  TableCellFallback,
  TableHeader,
  TableRow,
} from '@cbhq/cds-web/tables';
import { TextBody, TextHeadline, TextTitle3 } from '@cbhq/cds-web/typography';

import { useAppState } from ':cloud/contexts/AppStateContext';
import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { EventName, useLogViewOnMount } from ':cloud/init/clientAnalytics/logging';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { useGetWebhooks } from ':cloud/queries/WebhookQueries/useGetWebhooks';
import { CoinbaseWebhook } from ':cloud/types/ts_types';
import { truncateMiddle } from ':cloud/utils/common';
// import { SELECT_OPTION_WIDTH } from ':cloud/widgets/access/constants';
import { ClipboardIcon } from ':cloud/widgets/sharedcomponents';
import { WebhookTableEmptySearchState } from ':cloud/widgets/webhooks/WebhookTableEmptySearchState';

import { getEventType, getNetwork } from '../helpers';

type WebhookTableLoadingStateProps = { isViewOnly: boolean };

function WebhookTableLoadingState({ isViewOnly }: WebhookTableLoadingStateProps) {
  return (
    <>
      {[...Array(3).keys()].map((e) => (
        <TableRow key={e}>
          <TableCellFallback title />
          <TableCellFallback title />
          <TableCellFallback title />
          <TableCellFallback title />
          <TableCellFallback title />
          {!isViewOnly && <TableCellFallback end="image" width={100} />}
        </TableRow>
      ))}
    </>
  );
}

type WebhookTableEmptyStateProps = {
  onCreatePress: () => void;
};

function WebhookTableEmptyState({ onCreatePress }: WebhookTableEmptyStateProps) {
  useLogViewOnMount(EventName.webhook_empty_state_view);

  return (
    <VStack justifyContent="center" alignItems="center" gap={1} width="100%" spacingVertical={10}>
      <VStack width={358} gap={5}>
        <VStack gap={3} alignItems="center">
          <SpotRectangle name="apiKey" scaleMultiplier={1} />
          <TextHeadline as="p" align="center">
            No webhooks found
          </TextHeadline>
          <TextBody as="p" color="foregroundMuted" align="center">
            It takes less than a minute to create one.
          </TextBody>
        </VStack>
        <Button variant="secondary" onPress={onCreatePress}>
          Create Webhook
        </Button>
      </VStack>
    </VStack>
  );
}

type WebhookTableProps = {
  onCreatePress: () => void;
  onDeletePress: (webhookUUID: string) => void;
  onEditPress: (webhook: CoinbaseWebhook) => void;
};

export function CoinbaseWebhookTable({ onCreatePress, onEditPress }: WebhookTableProps) {
  // const [searchValue, setSearchValue] = useState('');
  const {
    user: { isOwnerOrAdmin },
  } = useGetUser();
  const { selectedProject } = useAppState();

  const { isDesktop } = useSimpleBreakpoints();

  const { data: webhooksData, isLoading: isWebhooksLoading } = useGetWebhooks(
    selectedProject?.organizationId || '',
    selectedProject?.id || '',
  );

  const isInEmptyState = (webhooksData?.webhooks?.length ?? 0) === 0;

  const tokenTableContents = useMemo(() => {
    return (
      webhooksData?.webhooks?.map((webhook) => {
        return (
          <TableRow key={webhook.uuid?.hex}>
            <TableCell>
              <TextHeadline as="p" spacingVertical={3}>
                {webhook.uuid?.hex || ''}
              </TextHeadline>
            </TableCell>

            <TableCell>
              <TextBody as="p">{getNetwork(webhook.network)}</TextBody>
            </TableCell>

            <TableCell>
              <TextBody as="p">{getEventType(webhook.eventType)}</TextBody>
            </TableCell>

            <TableCell>
              <HStack gap={1} alignItems="center">
                <VStack background="primaryWash" spacingHorizontal={1}>
                  <TextBody as="p">
                    {truncateMiddle(webhook.notificationUri, 12, 12, '...')}
                  </TextBody>
                </VStack>
                <ClipboardIcon text={webhook.notificationUri} iconSize="s" stopPropagation />
              </HStack>
            </TableCell>

            <TableCell>
              <HStack gap={2}>
                <Button onPress={() => onEditPress(webhook)} transparent>
                  Configure
                </Button>
              </HStack>
            </TableCell>
          </TableRow>
        );
      }) || []
    );
  }, [webhooksData, onEditPress]);

  const isProjectWithSearchInEmptyState =
    (webhooksData?.webhooks?.length ?? 0) > 0 && tokenTableContents.length === 0;

  const tableBody = useMemo(() => {
    if (isInEmptyState && !isWebhooksLoading) {
      return (
        <TableRow>
          <TableCell colSpan={8}>
            <WebhookTableEmptyState onCreatePress={onCreatePress} />
          </TableCell>
        </TableRow>
      );
    }
    if (isWebhooksLoading) {
      return <WebhookTableLoadingState isViewOnly={!isOwnerOrAdmin} />;
    }
    if (isProjectWithSearchInEmptyState) {
      return <WebhookTableEmptySearchState isViewOnly={!isOwnerOrAdmin} />;
    }
    return tokenTableContents;
  }, [
    isInEmptyState,
    isOwnerOrAdmin,
    isProjectWithSearchInEmptyState,
    isWebhooksLoading,
    onCreatePress,
    tokenTableContents,
  ]);

  return (
    <VStack gap={2}>
      <HStack width="100%" justifyContent="space-between">
        <VStack width="68%" gap={3} spacingBottom={3}>
          <TextTitle3 as="p">Webhook access & configuration</TextTitle3>
          <TextBody as="p" color="foregroundMuted">
            Enable CDP products to send real-time data to your application. You can create and
            configure your webhooks here.
          </TextBody>
        </VStack>
      </HStack>

      <HStack gap={4} justifyContent="space-between">
        {/* to create space */}
        <div />
        {/* <SearchInput
          width={SELECT_OPTION_WIDTH}
          value={searchValue}
          onChangeText={setSearchValue}
          placeholder="Search"
        /> */}
        {isOwnerOrAdmin && (
          <VStack justifyContent="center">
            <Button onPress={onCreatePress}>+ Create Webhook</Button>
          </VStack>
        )}
      </HStack>
      <Spacer />
      <Table bordered tableLayout="auto">
        <TableHeader>
          <TableRow>
            <TableCell title="Webhook UUID" />
            <TableCell title="Network" />
            {isDesktop && <TableCell title="Events" width={159} />}
            {isDesktop && <TableCell title="URL" />}
            {isOwnerOrAdmin && <TableCell title="" alignItems="center" width={100} />}
          </TableRow>
        </TableHeader>
        <TableBody>{tableBody}</TableBody>
      </Table>
    </VStack>
  );
}
