// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ChangeEvent, useCallback } from 'react';
import _isEmpty from 'lodash/isEmpty';
import _partition from 'lodash/partition';
import { TextInput } from '@cbhq/cds-web/controls';
import { Icon } from '@cbhq/cds-web/icons';

interface BaseInputProps {
  disabled: boolean;
  label: string;
  value?: string | number;
  readonly: boolean;
  autofocus: boolean;
  placeholder?: string;
  isPercentage: boolean;
  autoComplete?: string;
  required: boolean;
  onChange: (...args: any[]) => any;
  rawErrors?: string[];
  schema: {
    type?: string;
    minimum?: number;
    maximum?: number;
  };
  formContext?: object;
}

interface InputPropsType {
  type: string;
  step: string;
  min?: number;
  max?: number;
  autocomplete?: string;
}
export function BaseInput({
  disabled,
  label,
  value = '',
  placeholder,
  isPercentage,
  required,
  onChange,
  rawErrors,
  readonly,
  autofocus,
  autoComplete = 'off',
  schema,
  formContext,
  ...props
}: BaseInputProps) {
  const [requiredError, validationErrors] = _partition(rawErrors, (e) => e === 'required');
  const hasError =
    (!_isEmpty(requiredError) && formContext.hasSubmitted) || !_isEmpty(validationErrors);

  const inputProps: InputPropsType = { ...props };
  if (schema.type === 'number') {
    inputProps.type = 'number';
    inputProps.step = 'any';
  } else if (schema.type === 'integer') {
    inputProps.type = 'number';
    inputProps.step = 1;
  } else {
    inputProps.type = schema.type;
  }
  if (schema.minimum) {
    inputProps.min = schema.minimum;
  }
  if (schema.maximum) {
    inputProps.max = schema.maximum;
  }
  if (autoComplete) {
    inputProps.autoComplete = autoComplete;
  }

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      onChange(e.target.value);
    },
    [onChange],
  );

  return (
    <TextInput
      disabled={disabled}
      label={label}
      name={label}
      value={value}
      placeholder={placeholder === label ? '' : placeholder}
      end={isPercentage && <Icon name="percentage" size="s" color="foreground" spacingEnd={2} />}
      required={required}
      onChange={handleOnChange}
      variant={hasError ? 'negative' : 'foregroundMuted'}
      readOnly={readonly}
      autoFocus={autofocus}
      autoComplete={autoComplete}
    />
  );
}
