import { PictogramName, SpotIconName } from '@cbhq/cds-illustrations';
import { SidebarItemProps } from '@cbhq/cds-web/navigation/SidebarItem';

import {
  CLOUD_DOCS,
  CLOUD_HELP,
  COMMERCE_DOCS_LINK,
  EXCHANGE_DOCS_LINK,
  ONRAMP_DOCS_LINK,
  PRIME_DOCS_LINK,
  TRADE_DOCS_LINK,
  VERIFICATIONS_LINK,
} from ':cloud/utils/links';
import { AppRoute } from ':cloud/utils/routes';

/** These nav items have a flyout with children. They don't have landing pages. */
type ParentNavItemId =
  | 'payments-parent'
  | 'wallets-parent'
  | 'trading-parent'
  | 'onchain-tools-parent'
  | 'staking-parent';

/** These nav items are nested beneath parent nav items */
type PlatformAPINavItemId =
  | 'advanced-trade'
  | 'base' // Node
  | 'bundler-paymaster'
  | 'faucet'
  | 'onchainkit'
  | 'participate'
  | 'pay' // Onramp
  | 'pay-docs'
  | 'platform-wallets' // MPC Wallet
  | 'staking'
  | 'trade'
  | 'verifications'
  | 'verifications-docs'
  | 'waas_consumer' // Smart Wallet
  | 'wallet_history';

type ProductAPINavItemId = 'commerce' | 'exchange-docs' | 'exchange' | 'prime' | 'sign-in';

type PrimaryNavItemId =
  | 'access'
  | 'api'
  | 'billing'
  | 'dashboard'
  | 'documentation'
  | 'earnings'
  | 'feedback'
  | 'legacy-api'
  | 'project-dashboard'
  | 'project-settings'
  | 'team';

export type NavItemId =
  | PrimaryNavItemId
  | ParentNavItemId
  | PlatformAPINavItemId
  | ProductAPINavItemId
  | 'projects'
  | 'support'
  | 'webhooks';

export interface NavItem extends SidebarItemProps {
  id: NavItemId;
  pageTitle?: string;
  detail?: string;
}

export type ParentNavItem = NavItem & { childIds: NavItemId[] };

interface NavItemChildBase {
  id: NavItemId;
  pageTitle?: string;
  title: string;
  detail?: string;
  onPress?: () => void;
  to?: string;
  parentId?: string;
}
export interface NavItemChildWithPictogram extends NavItemChildBase {
  iconType: 'pictogram';
  icon: PictogramName;
}
export interface NavItemChildWithSpotIcon extends NavItemChildBase {
  iconType: 'spotIcon';
  icon: SpotIconName;
}

/** Used in Side Nav flyout menus */
export type NavItemChild = NavItemChildWithPictogram | NavItemChildWithSpotIcon;

export const primaryNavItems: NavItem[] = [
  {
    title: 'Dashboard',
    id: 'project-dashboard',
    icon: 'newsfeed',
    to: AppRoute.Projects.DetailsTab,
  },
  {
    title: 'Legacy API keys',
    id: 'legacy-api',
    icon: 'key',
    to: AppRoute.Access.LegacyAPI,
  },
  {
    title: 'API keys',
    id: 'api',
    icon: 'key',
    to: AppRoute.Access.API,
  },
  {
    title: 'Webhooks',
    id: 'webhooks',
    icon: 'perpetualSwap',
    to: AppRoute.Products.Webhooks,
  },
  { title: 'Team', id: 'team', icon: 'peopleGroup', to: AppRoute.Team.Home },
  {
    title: 'Earnings',
    id: 'earnings',
    icon: 'earnProduct',
    to: AppRoute.Earnings.Home,
  },
  {
    title: 'Billing',
    id: 'billing',
    icon: 'invoice',
    to: AppRoute.Billing.Home,
  },
  {
    title: 'Settings',
    id: 'project-settings',
    icon: 'settings',
    to: AppRoute.Settings.Project,
  },
];

export const platformAPINavItems: NavItemChild[] = [
  {
    title: 'Node',
    id: 'base',
    icon: 'baseLogoNavigation',
    iconType: 'pictogram',
    to: AppRoute.Products.Base,
    detail: 'Free mainnet RPC endpoint for Base',
    parentId: 'onchain-tools-parent',
  },
  {
    title: 'Faucet',
    id: 'faucet',
    icon: 'faucetNavigation',
    iconType: 'pictogram',
    to: AppRoute.Products.Faucet,
    detail: 'Get free Base Sepolia ETH',
    parentId: 'onchain-tools-parent',
  },
  {
    title: 'Paymaster',
    id: 'bundler-paymaster',
    icon: 'gasFees',
    iconType: 'pictogram',
    to: AppRoute.Products.BundlerAndPaymaster,
    detail: 'Build custom gasless experiences on Base',
    parentId: 'onchain-tools-parent',
  },
  {
    title: 'Smart Wallet',
    id: 'waas_consumer',
    icon: 'wallet',
    iconType: 'spotIcon',
    to: AppRoute.Products.Wallets,
    detail: 'Let your users create a wallet in seconds',
    parentId: 'wallets-parent',
  },
  {
    title: 'Data',
    id: 'wallet_history',
    pageTitle: 'Data',
    icon: 'decentralizedWeb3',
    iconType: 'pictogram',
    to: AppRoute.Products.Indexers,
    detail: 'Access wallet balances and transactions',
    parentId: 'onchain-tools-parent',
  },
  {
    title: 'Verifications',
    id: 'verifications',
    icon: 'idVerification',
    iconType: 'spotIcon',
    to: AppRoute.Products.Verifications,
    detail: "Verify your users' wallets with Coinbase",
    parentId: 'onchain-tools-parent',
  },
  {
    title: 'Verifications',
    id: 'verifications-docs',
    icon: 'idVerification',
    iconType: 'spotIcon',
    to: VERIFICATIONS_LINK,
    detail: "Verify your users' wallets with Coinbase",
    parentId: 'onchain-tools-parent',
  },
  {
    title: 'Onramp',
    id: 'pay-docs',
    icon: 'paySDKProduct',
    iconType: 'spotIcon',
    to: ONRAMP_DOCS_LINK,
    detail: 'Enable users to fund their wallets',
    parentId: 'payments-parent',
  },
  {
    title: 'Onramp',
    id: 'pay',
    icon: 'paySDKProduct',
    iconType: 'spotIcon',
    to: AppRoute.Products.Onramp,
    detail: 'Enable users to fund their wallets',
    parentId: 'payments-parent',
  },
  {
    title: 'OnchainKit',
    id: 'onchainkit',
    icon: 'easyToUse',
    iconType: 'pictogram',
    to: AppRoute.Products.Boat,
    detail: 'React components for building onchain apps',
    parentId: 'onchain-tools-parent',
  },
  {
    title: 'MPC Wallet',
    id: 'platform-wallets',
    icon: 'walletAsAServiceProduct',
    iconType: 'spotIcon',
    to: AppRoute.Products.PlatformWallets,
    detail: 'Build onchain wallets into your products',
    parentId: 'wallets-parent',
  },
  {
    title: 'Programmatic Staking',
    id: 'staking',
    icon: 'stakingProduct',
    iconType: 'spotIcon',
    to: AppRoute.Products.Staking,
    detail: 'Enable staking rewards for you and your customers',
    parentId: 'staking-parent',
  },
  {
    title: 'Trade',
    id: 'trade',
    icon: 'fiat',
    iconType: 'pictogram',
    to: TRADE_DOCS_LINK,
    detail: 'Add trading and swap functionality to your app',
    parentId: 'trading-parent',
  },
  {
    title: 'Validators',
    id: 'participate',
    icon: 'delegateProduct',
    iconType: 'spotIcon',
    to: AppRoute.Participate.Home,
    detail: 'Secure blockchain staking solutions',
    parentId: 'staking-parent',
  },
];

export const productAPINavItems: NavItem[] = [
  {
    title: 'Coinbase App',
    id: 'sign-in',
    icon: 'coinbase',
    to: AppRoute.Products.App,
    detail:
      'Transfer and track Coinbase account activity. Previously called Sign in with Coinbase.',
  },
  {
    title: 'Coinbase Advanced Trade',
    id: 'advanced-trade',
    icon: 'advancedTradeProduct',
    to: AppRoute.Products.AdvancedTrade,
    detail: 'Automate trading & access market insights',
  },
  {
    title: 'Coinbase Exchange',
    id: 'exchange-docs',
    icon: 'exchangeProduct',
    to: EXCHANGE_DOCS_LINK,
    detail: 'Direct access to our US spot exchange and market data',
  },
  {
    title: 'Coinbase Exchange',
    id: 'exchange',
    icon: 'exchangeProduct',
    to: AppRoute.Products.Exchange,
    detail: 'Direct access to our US spot exchange and market data',
  },
  {
    title: 'Coinbase Prime',
    id: 'prime',
    icon: 'primePoduct',
    to: PRIME_DOCS_LINK,
    detail: 'High-volume trading and custody for institutional traders',
  },
  {
    title: 'Coinbase Commerce',
    id: 'commerce',
    icon: 'commerceProduct',
    to: COMMERCE_DOCS_LINK,
    detail: 'Start accepting crypto payments',
  },
];

export const platformAPIParentNavItems: ParentNavItem[] = [
  {
    title: 'Wallets',
    id: 'wallets-parent',
    icon: 'wallet',
    childIds: ['waas_consumer', 'platform-wallets'],
  },
  {
    title: 'Onchain Tools',
    id: 'onchain-tools-parent',
    icon: 'participateProduct',
    childIds: [
      'base',
      'faucet',
      'bundler-paymaster',
      'wallet_history',
      'verifications',
      'onchainkit',
    ],
  },
  // Waiting on clarification on what child items should be included
  {
    title: 'Trading',
    id: 'trading-parent',
    icon: 'trading',
    childIds: [],
  },
  {
    title: 'Payments',
    id: 'payments-parent',
    icon: 'commerceProduct',
    childIds: ['pay', 'pay-docs'],
  },
  {
    title: 'Staking',
    id: 'staking-parent',
    icon: 'staking',
    childIds: ['staking', 'participate'],
  },
];

const mobileNavKeys = ['project-dashboard', 'api', 'project-settings', 'all-products'];
export const mobileNavItems = primaryNavItems
  .filter((item) => mobileNavKeys.includes(item.id))
  .map((item) => ({
    ...item,
    label: item.title,
  }));

export const mobileHamburgerItems: readonly NavItem[] = [
  { title: 'Support', id: 'support', icon: 'heart', to: CLOUD_HELP },
  {
    title: 'Documentation',
    id: 'documentation',
    icon: 'documentation',
    to: CLOUD_DOCS,
  },
  // { pageTitle: '', title: 'FAQs', icon: 'magnifyingGlass', to: CLOUD_HELP }, // TODO: renable when FAQ link available
  {
    title: 'Send feedback',
    id: 'feedback',
    icon: 'comment',
    to: CLOUD_HELP,
  }, // TODO: add feedback link
];
