import config from ':cloud/config';

export const eventTypesMap = {
  EVENT_TYPE_ERC20_TRANSFER: 'ERC20 Transfer',
  EVENT_TYPE_ERC721_TRANSFER: 'ERC721 Transfer',
};

export const networksMap = {
  'base-mainnet': 'Base Mainnet',
};

export function getRPCEndpointURL(tokenId: string, isTestnet: boolean) {
  if (!tokenId) return 'Loading...';
  return `${config.developerAPIURL}/rpc/v1/${isTestnet ? 'base-sepolia' : 'base'}/${tokenId}`;
}

export function getEventType(eventType: string) {
  return (eventTypesMap[eventType] || eventType) as string;
}

export function getNetwork(network: string) {
  return (networksMap[network] || network) as string;
}

export function validateEthereumAddress(address: string): boolean {
  return /^0x[a-fA-F0-9]{40}$/.test(address);
}
