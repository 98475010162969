import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import _capitalize from 'lodash/capitalize';
import { Button } from '@cbhq/cds-web/buttons';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { Avatar } from '@cbhq/cds-web/media';
import { Tooltip } from '@cbhq/cds-web/overlays/Tooltip/Tooltip';
import { Table, TableBody, TableCell, TableHeader, TableRow } from '@cbhq/cds-web/tables';
import { Tag } from '@cbhq/cds-web/tag/Tag';
import { Link, TextBody, TextHeadline, TextTitle3 } from '@cbhq/cds-web/typography';

import { useGetOrganization } from ':cloud/queries/OrganizationQueries/useGetOrganization';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { useGetUserPermissions } from ':cloud/queries/UserQueries/useGetUserPermissions';
import { OrganizationUser } from ':cloud/types/ts_types';
import { AppRoute } from ':cloud/utils/routes';
import { ActionCell } from ':cloud/widgets/access/components/ActionCell';
import { StatusCell } from ':cloud/widgets/access/components/StatusCell';
import { sortOrganizationUsers } from ':cloud/widgets/access/helpers';

export const MAX_MEMBER_INVITE_LIMIT = 100;
export const inviteWarning = `Maximum of ${MAX_MEMBER_INVITE_LIMIT.toString()} active invitations has been reached`;
const exchangeWarning = 'Your organization can only have 1 user since you are an Exchange user';

interface InviteButtonProps {
  hasInvitedMaxUsers: boolean;
  toggleInviteModalOn: () => void;
  exchangeEnabled: boolean;
}
function InviteButton({
  hasInvitedMaxUsers,
  toggleInviteModalOn,
  exchangeEnabled,
}: InviteButtonProps) {
  if (exchangeEnabled) {
    return (
      <Tooltip content={exchangeWarning} placement="bottom">
        <Button disabled onPress={toggleInviteModalOn}>
          Invite member
        </Button>
      </Tooltip>
    );
  }
  if (hasInvitedMaxUsers) {
    return (
      <Tooltip content={inviteWarning} placement="bottom">
        <Button disabled onPress={toggleInviteModalOn}>
          Invite member
        </Button>
      </Tooltip>
    );
  }
  return (
    <Button disabled={hasInvitedMaxUsers} onPress={toggleInviteModalOn}>
      Invite member
    </Button>
  );
}

export interface AccessTableWrapperProps {
  toggleInviteModalOn: () => void;
  handleMemberAction: (event: string, user: OrganizationUser) => void;
}

export function AccessTableWrapper({
  toggleInviteModalOn,
  handleMemberAction,
}: AccessTableWrapperProps) {
  const history = useHistory();
  const { user: self, activeOrg } = useGetUser();
  const { permissions } = useGetUserPermissions(activeOrg?.organizationId);
  const { organizationUsers, invitedUsers } = useGetOrganization(activeOrg?.organizationId);
  const hasInvitedMaxUsers = invitedUsers?.length >= MAX_MEMBER_INVITE_LIMIT;

  const handleChangeRoute = useCallback(() => {
    history.push(AppRoute.Access.Permissions);
  }, [history]);

  const sortedOrganizationUsers = useMemo(() => {
    return sortOrganizationUsers(organizationUsers, self.email);
  }, [organizationUsers, self.email]);

  const renderAccessTableContents = sortedOrganizationUsers?.map((user) => {
    const signedInUser = self.email === user?.email;
    return (
      <TableRow key={`row--${user?.email}`}>
        <TableCell
          start={
            <Avatar
              alt={user.email}
              size="xl"
              selected={signedInUser}
              dangerouslySetSize={34}
              src={user?.avatarUrl || undefined}
            />
          }
        >
          <TextHeadline as="p">{getDisplayName(user)}</TextHeadline>
          <TextBody color="foregroundMuted" as="p">
            {user.email}
          </TextBody>
        </TableCell>
        <TableCell direction="horizontal" justifyContent="flex-start">
          <TextHeadline spacingEnd={2} spacingVertical={2} as="span">
            {_capitalize(user.role)}
          </TextHeadline>
          {signedInUser ? <Tag colorScheme="blue">YOU</Tag> : null}
        </TableCell>
        <StatusCell manageUsers={permissions.manageUsers} user={user} />
        {permissions.manageUsers && activeOrg?.role ? (
          <ActionCell
            user={user}
            signedInUser={signedInUser}
            signedInUserRole={activeOrg.role}
            handleMemberAction={handleMemberAction}
          />
        ) : undefined}
      </TableRow>
    );
  });

  return (
    <Table variant="default" tableLayout="auto">
      <TableHeader>
        <TableRow>
          <TableCell colSpan={4}>
            <HStack width="100%" justifyContent="space-between" spacingVertical={2}>
              <VStack width="66%" gap={1}>
                <TextTitle3 as="h3">Members</TextTitle3>
                <TextBody as="p" color="foregroundMuted">
                  A list of people in your organization, including their roles. For details, see{' '}
                  <Link onPress={handleChangeRoute} underline={false}>
                    Roles and Permissions
                  </Link>
                </TextBody>
              </VStack>
              {permissions.manageUsers ? (
                <VStack justifyContent="center">
                  <InviteButton
                    {...{
                      hasInvitedMaxUsers,
                      toggleInviteModalOn,
                      exchangeEnabled: self.exchangeEnabled,
                    }}
                  />
                </VStack>
              ) : null}
            </HStack>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell title="Name" />
          <TableCell title="Role" />
          <TableCell title="Status" alignItems={permissions.manageUsers ? undefined : 'flex-end'} />
          {permissions.manageUsers ? <TableCell title="Action" alignItems="flex-end" /> : undefined}
        </TableRow>
      </TableHeader>
      <TableBody>{renderAccessTableContents}</TableBody>
    </Table>
  );
}

function getDisplayName(user): string {
  if (user.status === 'active') return `${user?.name} ${user?.familyName}`;
  return '';
}
