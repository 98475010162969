import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import { useToggler } from '@cbhq/cds-common/hooks/useToggler';
import { Button, ButtonGroup } from '@cbhq/cds-web/buttons';
import { Collapsible } from '@cbhq/cds-web/collapsible/Collapsible';
import { TextInput } from '@cbhq/cds-web/controls';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { Table, TableBody, TableRow } from '@cbhq/cds-web/tables';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

type Inputs = {
  value: string;
};

type EditableValueProps = {
  name: string;
  bordered?: boolean;
  disabled?: boolean;
  validationSchema?: ObjectSchema<any>;
  value?: string;
  onSubmit: (value: string, onSettled?: () => void) => void;
};

export function EditableValueWithButton({
  bordered = false,
  disabled = false,
  validationSchema,
  name,
  value,
  onSubmit,
}: EditableValueProps) {
  const [editableCollapsed, { toggle }] = useToggler(true);
  const defaultValues = { value };

  const {
    register,
    handleSubmit,
    getValues,
    formState: { isSubmitting, errors },
    reset,
  } = useForm<Inputs>({
    defaultValues,
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
  });

  const onFormSubmit: SubmitHandler<Inputs> = useCallback(() => {
    onSubmit(getValues('value'), toggle);
  }, [getValues, onSubmit, toggle]);

  const clearAndCloseForm = useCallback(() => {
    toggle();
    reset();
  }, [reset, toggle]);

  const submitDisabled = isSubmitting || Boolean(Object.keys(errors).length);

  return (
    <Table bordered={bordered} variant="default">
      <TableBody>
        <TableRow disableHoverIndicator>
          <HStack justifyContent="space-between" alignItems="center">
            <VStack>
              <TextHeadline as="h3">{name}</TextHeadline>
              {editableCollapsed && (
                <TextBody as="p" color="foregroundMuted">
                  {value}
                </TextBody>
              )}
            </VStack>

            <HStack alignItems="center" gap={2}>
              {editableCollapsed && (
                <Button variant="secondary" onPress={toggle} disabled={disabled}>
                  Edit
                </Button>
              )}
            </HStack>
          </HStack>
        </TableRow>
        <Collapsible collapsed={editableCollapsed}>
          <form style={{ width: '100%' }} onSubmit={handleSubmit(onFormSubmit)}>
            <VStack gap={3} width="100%" spacingVertical={1}>
              <TextInput
                {...register('value')}
                spellCheck={false}
                defaultValue={getValues('value')}
              />
              {errors.value && (
                <TextBody color="negative" as="p">
                  {errors.value.message}
                </TextBody>
              )}
              <Box alignSelf="flex-end" spacingVertical={1}>
                <ButtonGroup accessibilityLabel="Org actions">
                  <Button variant="secondary" onPress={clearAndCloseForm}>
                    Cancel
                  </Button>
                  <Button type="submit" disabled={submitDisabled}>
                    Save
                  </Button>
                </ButtonGroup>
              </Box>
            </VStack>
          </form>
        </Collapsible>
      </TableBody>
    </Table>
  );
}
