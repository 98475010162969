import {
  ChangeEvent,
  type MouseEvent,
  type SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import _map from 'lodash/map';
import _some from 'lodash/some';
import _values from 'lodash/values';
import { Button } from '@cbhq/cds-web/buttons';
import { TextInput } from '@cbhq/cds-web/controls';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { Modal, ModalBody, ModalFooter, ModalProps } from '@cbhq/cds-web/overlays';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

import { useTokens } from ':cloud/contexts/LegacyTokensContext';
import { IAPIService, IAPIToken, IAPITokenPermType } from ':cloud/types/ts_types';
import { CloudModalHeader } from ':cloud/widgets/sharedcomponents';

import { PermsCheckboxes } from './KeyPermsCheckboxes';

interface CreateAPITokenDialogProps
  extends Omit<ModalProps, 'children' | 'onRequestClose' | 'className'> {
  setNewToken: React.Dispatch<React.SetStateAction<string | undefined>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  APITokens?: IAPIToken[];
  APIServices?: IAPIService[];
  renderServiceLabel: (service: string) => string;
}

function mapToObject(
  rawMap: Map<string, Set<IAPITokenPermType>>,
): Record<string, Set<IAPITokenPermType>> {
  const obj: Record<string, Set<IAPITokenPermType>> = {};
  rawMap.forEach((val, key) => {
    obj[key] = val;
  });
  return obj;
}

/* only used in LegacyAPITokenManagement */
export function CreateTokenDialog({
  setNewToken,
  setOpen,
  APITokens,
  APIServices,
  renderServiceLabel,
  ...props
}: CreateAPITokenDialogProps) {
  const [tokenName, setTokenName] = useState<string>('');

  const [usedTokenNameError, setUsedTokenNameError] = useState<boolean>(false);
  const [noTokenNameError, setNoTokenNameError] = useState<boolean>(false);
  const [missingPerm, setMissingPerm] = useState<boolean>(false);

  const [openPermissions, setOpenPermissions] = useState<Set<string>>(new Set());
  const updateOpenPerms = (serviceName: string, next: SetStateAction<boolean>): void =>
    setOpenPermissions((state) => {
      const nextState = new Set(state);
      const isOpen = typeof next === 'boolean' ? next : next(state.has(serviceName));
      if (isOpen) {
        return nextState.add(serviceName);
      }

      nextState.delete(serviceName);
      return nextState;
    });
  const [enabledPermissions, setEnabledPermissions] = useState<Map<string, Set<IAPITokenPermType>>>(
    new Map(),
  );
  const updateEnabledPermissions = (
    serviceName: string,
    next: SetStateAction<IAPITokenPermType | null>,
  ): void =>
    setEnabledPermissions((state) => {
      const permission = typeof next === 'function' ? next(null) : next;
      const nextState = new Map(state);
      if (!permission) {
        nextState.delete(serviceName);
        return nextState;
      }

      const permissions = new Set(state.get(serviceName));
      if (permissions.has(permission)) {
        permissions.delete(permission);
        if (permissions.size === 0) {
          nextState.delete(serviceName);
          return nextState;
        }
      } else {
        permissions.add(permission);
      }

      return nextState.set(serviceName, permissions);
    });

  const { createAPIToken, loadAllAPITokens } = useTokens();

  const resetForm = (): void => {
    setTokenName('');

    setOpenPermissions(new Set());
    setEnabledPermissions(new Map());

    setMissingPerm(false);
    setNoTokenNameError(false);
    setUsedTokenNameError(false);
  };

  const needsPerms = useCallback(
    (): boolean =>
      enabledPermissions.size === 0 ||
      _some(Array.from(openPermissions), (permission) => !enabledPermissions.get(permission)),
    [openPermissions, enabledPermissions],
  );

  const handleSubmit = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    let error = false;

    if (!tokenName) {
      setNoTokenNameError(true);
      error = true;
    }

    if (needsPerms()) {
      setMissingPerm(true);
      error = true;
    }

    if (error) {
      return;
    }

    const newToken = await createAPIToken({
      name: tokenName,
      services: _map(
        mapToObject(enabledPermissions),
        (permissions, service): IAPIService => ({
          name: service,
          permissions: _values(Array.from(permissions)),
        }),
      ),
    });

    if (newToken !== undefined) {
      resetForm();
      setNewToken(newToken.token);
      setOpen(false);
      await loadAllAPITokens();
    }
  };

  useEffect(() => {
    if (missingPerm && !needsPerms()) {
      setMissingPerm(false);
    }
  }, [openPermissions, enabledPermissions, missingPerm, setMissingPerm, needsPerms]);

  const getTokenNameHelperText = useCallback(() => {
    let text: string;

    if (usedTokenNameError) {
      text = 'Token name already used. Enter new name.';
    } else if (noTokenNameError) {
      text = 'Enter a name.';
    } else {
      text = 'Token name is for your use only (e.g. testing-app-1)';
    }

    return text;
  }, [usedTokenNameError, noTokenNameError]);

  const handleOnRequestClose: () => void = () => {
    resetForm();
    setOpen(false);
  };

  return (
    <Modal {...props} onRequestClose={handleOnRequestClose} hideDividers>
      <CloudModalHeader title="Create API access token" testID="create-token-dialog-title" />
      <ModalBody>
        <TextBody as="p" spacingBottom={2}>
          A single token can incorporate all desired API permissions. If you want multiple tokens,
          you will need to repeat this process.
        </TextBody>

        <form>
          <TextInput
            testID="token-name"
            label="Token Name"
            placeholder="my-api-token"
            helperText={getTokenNameHelperText()}
            value={tokenName}
            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
              e.preventDefault();
              setTokenName(e.currentTarget.value);
              setUsedTokenNameError(
                !!APITokens && !!APITokens.find((t) => t.name === e.currentTarget.value),
              );
              setNoTokenNameError(false);
            }}
            variant={usedTokenNameError || noTokenNameError ? 'negative' : 'foregroundMuted'}
          />

          <TextHeadline as="h3" spacingTop={3}>
            Permissions
          </TextHeadline>
          <HStack gap={1}>
            <TextBody as="p">(Select all that apply)</TextBody>
            {missingPerm && (
              <TextBody color="negative" as="p">
                Select your permissions.
              </TextBody>
            )}
          </HStack>
          {APIServices?.map((service) => (
            <VStack borderedBottom key={service.name} spacingVertical={3}>
              <PermsCheckboxes
                serviceName={service.name}
                label={renderServiceLabel(service.name)}
                permissions={service.permissions}
                enabledPermissions={enabledPermissions.get(service.name) || new Set()}
                setPermissions={(permission: SetStateAction<IAPITokenPermType | null>): void =>
                  updateEnabledPermissions(service.name, permission)
                }
                open={openPermissions.has(service.name)}
                setOpen={(val: SetStateAction<boolean>): void => updateOpenPerms(service.name, val)}
                description={service.description}
              />
            </VStack>
          ))}
          <VStack spacingTop={5}>
            <TextHeadline as="h3">Authentication type</TextHeadline>
            <TextBody as="p">OAuth</TextBody>
          </VStack>
        </form>
      </ModalBody>
      <ModalFooter
        primaryAction={
          <Button
            type="submit"
            onPress={handleSubmit}
            testID="submit"
            disabled={!APIServices || usedTokenNameError}
          >
            Create
          </Button>
        }
        secondaryAction={
          <Button variant="secondary" onPress={handleOnRequestClose}>
            Cancel
          </Button>
        }
      />
    </Modal>
  );
}
