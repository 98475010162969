import _isEmpty from 'lodash/isEmpty';
import { Box, Grid, VStack } from '@cbhq/cds-web/layout';
import { TextTitle1 } from '@cbhq/cds-web/typography';

import { ReactComponent as BlockIcon } from ':cloud/assets/Objects/block.svg';
import { ReactComponent as ArrowUpIcon } from ':cloud/assets/Objects/uptime.svg';
import { useGetMetricKeys } from ':cloud/queries/WorkflowQueries/useGetWorkflows';
import { ICluster, IConfiguration, IMetricsRoot } from ':cloud/types/ts_types';
import { filterClusterMetrics } from ':cloud/utils/clusters';
import { getDyanmicMetricsData, parseMetricValue } from ':cloud/utils/metrics';
import { twoColumnGrid } from ':cloud/utils/responsiveConfigs';
import { BlockUnit } from ':cloud/widgets/charts/BlockUnit';
import { NodeMetricChart } from ':cloud/widgets/charts/NodeMetricChart';
import { Layout } from ':cloud/widgets/layout';
import { LoadingSpinner } from ':cloud/widgets/sharedcomponents';
import { CloudErrorBoundary } from ':cloud/widgets/sharedcomponents/CloudErrorBoundary';

import { ClusterAddresses } from './ClusterAddresses';
import { ClusterSettings } from './ClusterSettings';
import { ClusterTabMetrics } from './ClusterTabMetrics';

interface MetricsTabProps {
  cluster: ICluster;
  configuration: IConfiguration;
  isClusterLoading: boolean;
  metrics: IMetricsRoot;
  nodes?: ICluster['nodes'];
}

function MetricsTab({ cluster, configuration, isClusterLoading, metrics, nodes }: MetricsTabProps) {
  const protocolKey = cluster.type;
  const { metricKeys, isLoading: isLoadingKeys } = useGetMetricKeys(protocolKey);
  const { value: blockHeightValue } = parseMetricValue(
    metricKeys?.blockHeightKey as string,
    metrics,
  );
  const { value: uptimeValue, unit: uptimeUnit } = parseMetricValue(
    metricKeys?.uptimeKey as string,
    metrics,
  );
  const dynamicData = filterClusterMetrics(
    getDyanmicMetricsData(metrics.data, protocolKey, metricKeys),
  );
  const { value: cpuMetric } = parseMetricValue(metricKeys?.cpuUsagePercentKey as string, metrics);

  if (isClusterLoading || isLoadingKeys) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Layout.ContentWrapper>
        <TextTitle1 as="h2" spacingBottom={5}>
          Validator Metrics
        </TextTitle1>
        <CloudErrorBoundary name="ClusterMetricsTab">
          <VStack gap={3}>
            <Grid gap={3} responsiveConfig={twoColumnGrid}>
              <BlockUnit label="block height" value={blockHeightValue} IconComponent={BlockIcon} />
              <BlockUnit
                label="uptime"
                value={uptimeValue}
                IconComponent={ArrowUpIcon}
                unit={uptimeUnit}
              />
            </Grid>
            {cpuMetric && (
              <Box
                display="block"
                borderColor="line"
                borderRadius="roundedSmall"
                spacingVertical={5}
              >
                <NodeMetricChart metrics={metrics} protocolKey={protocolKey} />
              </Box>
            )}
            {!_isEmpty(dynamicData) && (
              <ClusterTabMetrics data={dynamicData} metrics={metrics} protocolKey={protocolKey} />
            )}
          </VStack>
        </CloudErrorBoundary>
      </Layout.ContentWrapper>
      <Layout.ContentWrapper>
        <CloudErrorBoundary name="ClusterSettings">
          <ClusterSettings cluster={cluster} configuration={configuration} nodes={nodes} />
        </CloudErrorBoundary>
      </Layout.ContentWrapper>
      <Layout.ContentWrapper>
        <CloudErrorBoundary name="ClusterAddresses">
          <ClusterAddresses addresses={cluster?.artifacts?.addresses} />
        </CloudErrorBoundary>
      </Layout.ContentWrapper>
    </>
  );
}

export default MetricsTab;
