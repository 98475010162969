import { useCallback, useState } from 'react';
import { noop } from '@cbhq/cds-utils';
import { Button } from '@cbhq/cds-web/buttons';
import { ListCell } from '@cbhq/cds-web/cells';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@cbhq/cds-web/overlays';
import { TextBody } from '@cbhq/cds-web/typography';

import { CoinbaseWebhook } from ':cloud/types/ts_types';
import { truncateMiddle } from ':cloud/utils/common';
import { ClipboardIcon } from ':cloud/widgets/sharedcomponents';
import { CoinbaseWebhookEditFieldsModal } from ':cloud/widgets/webhooks/CoinbaseWebhookEditFieldsModal';

import { getEventType, getNetwork } from '../helpers';

type WebhookEditModalProps = {
  onRequestClose?: () => void;
  onSubmit: (webhook: CoinbaseWebhook) => void;
  onDelete: (webhook: CoinbaseWebhook) => void;
  onSuccess?: () => void;
  webhook: CoinbaseWebhook;
  organizationId: string;
  projectId: string;
};

export function CoinbaseWebhookEditModal({
  onRequestClose,
  onSubmit,
  onDelete,
  webhook,
  organizationId,
  projectId,
}: WebhookEditModalProps) {
  const [isEditFieldsModalVisible, setIsEditFieldsModalVisible] = useState(false);

  const handleEditButtonPress = useCallback(() => {
    setIsEditFieldsModalVisible(true);
  }, []);

  const handleEditFieldsModalClose = useCallback(() => {
    setIsEditFieldsModalVisible(false);
  }, []);

  const handleEditFieldsSubmit = useCallback(
    (updatedWebhook: CoinbaseWebhook) => {
      onSubmit(updatedWebhook);
      setIsEditFieldsModalVisible(false);
      onRequestClose?.();
    },
    [onSubmit, onRequestClose],
  );

  const handleDelete = useCallback(() => {
    onDelete(webhook);
    onRequestClose?.();
  }, [onDelete, webhook, onRequestClose]);

  return (
    <Modal onRequestClose={onRequestClose || noop} visible width={480}>
      {isEditFieldsModalVisible ? (
        <CoinbaseWebhookEditFieldsModal
          webhook={webhook}
          organizationId={organizationId}
          projectId={projectId}
          onRequestClose={handleEditFieldsModalClose}
          onSubmit={handleEditFieldsSubmit}
        />
      ) : (
        <>
          <ModalHeader title="Webhook Details" />
          <ModalBody>
            <VStack gap={2}>
              <ListCell description="Webhook UUID" detail={webhook.uuid?.hex} />
              <ListCell
                description="Webhook URL"
                detail={
                  <HStack alignItems="center">
                    <VStack background="primaryWash" spacingHorizontal={1}>
                      <TextBody as="p">
                        {truncateMiddle(webhook.notificationUri, 12, 12, '...')}
                      </TextBody>
                    </VStack>
                    <ClipboardIcon text={webhook.notificationUri} iconSize="s" stopPropagation />
                  </HStack>
                }
              />
              <ListCell description="Network Type" detail={getNetwork(webhook.network)} />
              <ListCell description="Event Type" detail={getEventType(webhook.eventType)} />
              <ListCell
                description="Contract Address"
                detail={webhook.eventFilters[0].contractAddress}
              />
              <ListCell description="From Address" detail={webhook.eventFilters[0].fromAddress} />
              <ListCell description="To Address" detail={webhook.eventFilters[0].toAddress} />
            </VStack>
          </ModalBody>
          <ModalFooter
            primaryAction={
              <Button variant="negative" onPress={handleDelete}>
                Delete
              </Button>
            }
            secondaryAction={
              <Button variant="secondary" onPress={handleEditButtonPress}>
                Edit
              </Button>
            }
          />
        </>
      )}
    </Modal>
  );
}
