import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useToast } from '@cbhq/cds-web/overlays/useToast';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { FaucetResponse } from ':cloud/types/ts_types';

type FaucetRequest = {
  address: string;
  verified: boolean;
};

const failedClaimMsg = 'Something went wrong. Please try again later.';

async function faucet({ address, verified }: FaucetRequest): Promise<FaucetResponse> {
  const path = `faucet/v1/requestFunds`;

  const requestBody = {
    address,
    verified,
  };
  const { data } = await APIClientBase.post<FaucetResponse>(path, requestBody);

  return data;
}

type FaucetMutate = {
  address: string;
  verified: boolean;
};

type FaucetProps = {
  onRequestComplete: (response: FaucetResponse) => void;
};

export function useFaucet({ onRequestComplete }: FaucetProps) {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ address, verified }: FaucetMutate) => {
      return faucet({ address, verified });
    },
    onSuccess: async (data) => {
      queryClient.setQueryData([QueryKey.faucet], data);
      onRequestComplete?.({ txHash: data.txHash });
    },
    onError: (error: AxiosError<{ message: string }>) => {
      if (
        error?.response?.data?.message === 'address has less than 0.001 ETH on Ethereum mainnet'
      ) {
        toast.show('Address must have at least 0.001 ETH on Ethereum mainnet.', {
          variant: 'negative',
        });
        return;
      }
      if (error?.response?.status === 403) {
        toast.show(`${error?.response?.data?.message}`, {
          variant: 'negative',
        });
        return;
      }
      toast.show(failedClaimMsg, {
        variant: 'negative',
      });
    },
  });
}
