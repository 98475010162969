import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { InviteMemberResponse } from ':cloud/types/service_proto';
import {
  OrganizationRole,
  OrganizationRoleCOMS,
  OrganizationRoleCOMSEnum,
} from ':cloud/types/ts_types';

async function inviteMember(
  orgId: string,
  email: string,
  role: OrganizationRoleCOMS,
): Promise<InviteMemberResponse> {
  const path = `/organization-management/v1/organizations/${orgId}/invite`;
  const { data } = await APIClientBase.post<InviteMemberResponse>(path, {
    email: email.trim(),
    role,
  });
  return data;
}

export function useInviteMember(orgId = '', memberEmail = '', reInvite = false) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (invitedRole: OrganizationRole) =>
      inviteMember(orgId, memberEmail, OrganizationRoleCOMSEnum[invitedRole]),
    onSuccess: async () => {
      /* refresh team members */
      await queryClient.invalidateQueries([QueryKey.organizations]);
      await queryClient.invalidateQueries([QueryKey.permissions]);
    },
    meta: {
      successMsg: reInvite ? `Invite to ${memberEmail} resent` : `Invite to ${memberEmail} sent`,
    },
  });
}
