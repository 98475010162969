import { HStack, VStack } from '@cbhq/cds-web/layout';
import { Tooltip } from '@cbhq/cds-web/overlays/Tooltip/Tooltip';
import { Tag } from '@cbhq/cds-web/tag/Tag';
import { TextHeadline, TextLabel2 } from '@cbhq/cds-web/typography';

import { useGetProtocolDisplayName } from ':cloud/queries/WorkflowQueries/useGetProtocolDisplayName';
import { WithChildren } from ':cloud/types/helper';
import { ICluster } from ':cloud/types/ts_types';
import { getClusterStatus } from ':cloud/utils/clusters';
import { isNodeArchival, isNodeIndexer } from ':cloud/utils/nodes';
import { ProtocolIcon, StatusIndicator } from ':cloud/widgets/sharedcomponents';

interface ClusterStatusTitleProps {
  cluster: ICluster;
  name?: string;
  withIcon?: boolean;
}

/**
 * Displays a cluster health status defined in getClusterStatus(src/utils/clusters)
 * If no children are passed, just displays the cluster name
 */
export function ClusterStatusTitle({
  cluster,
  name,
  withIcon,
  children,
}: ClusterStatusTitleProps & Partial<WithChildren>) {
  const protocolKey = cluster.type;
  const { displayName } = useGetProtocolDisplayName(protocolKey);
  const clusterStatus = getClusterStatus(cluster.state);
  const artifactsCopy = [cluster.artifacts?.version, cluster.artifacts?.network]
    .filter(Boolean)
    .join(' • ');
  const tooltipCopy = `${clusterStatus.status} on ${displayName} ${artifactsCopy}`;
  const showTooltip = clusterStatus.status !== 'Running';
  const isArchival = isNodeArchival(cluster);
  const isIndexer = isNodeIndexer(cluster);

  const statusAndTitle = (
    <>
      <StatusIndicator status={clusterStatus} />
      {/* Bug: cannot truncate label  */}
      <TextLabel2 as="span" spacingStart={1} color="foregroundMuted" overflow="clip">
        {`${clusterStatus.status} on ${displayName}`}
        <span>&nbsp;</span>
        {cluster.artifacts && artifactsCopy}
      </TextLabel2>
    </>
  );

  return (
    <VStack gap={1} borderRadius="rounded">
      <HStack alignItems="center" justifyContent="space-between">
        <HStack spacingVertical={0.5} spacingHorizontal={1} bordered borderRadius="roundedSmall">
          {showTooltip ? <Tooltip content={tooltipCopy}>{statusAndTitle}</Tooltip> : statusAndTitle}
        </HStack>
        <HStack gap={2}>
          {isArchival && <Tag colorScheme="blue">archival</Tag>}
          {isIndexer && <Tag colorScheme="blue">indexer</Tag>}
        </HStack>
      </HStack>
      {children || (
        <HStack alignItems="center" gap={1} spacingTop={2}>
          {withIcon && <ProtocolIcon protocolKey={cluster.type} size={24} />}
          <TextHeadline overflow="break" as="p">
            {name || cluster.name}
          </TextHeadline>
        </HStack>
      )}
    </VStack>
  );
}
