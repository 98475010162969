import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { SpacingScale } from '@cbhq/cds-common';
import { Banner } from '@cbhq/cds-web/banner/Banner';
import { VStack } from '@cbhq/cds-web/layout';
import { Pressable } from '@cbhq/cds-web/system';
import { TextBody } from '@cbhq/cds-web/typography';

import { useAccountCreditsBalances } from ':cloud/hooks/useAccountCreditsBalances';
import { AppRoute } from ':cloud/utils/routes';

import { FULL_PAGE_MAX_WIDTH, PAYMENT_METHOD_CREDIT_TYPE } from '../product/constants';

type CreditBannerProps = {
  title?: string;
  description?: string;
  primaryAction?: JSX.Element;
  spacingHorizontal?: SpacingScale;
  spacingTop?: SpacingScale;
  spacingBottom?: SpacingScale;
};

export function CreditBanner({
  title,
  description,
  primaryAction,
  spacingHorizontal = 9,
  spacingTop = 9,
  spacingBottom = 0,
}: CreditBannerProps) {
  const history = useHistory();
  const handleAddPaymentMethodPress = useCallback(() => {
    history.push(AppRoute.Billing.Home);
  }, [history]);

  const { accountCreditTypes, accountCreditsRemaining } = useAccountCreditsBalances();
  const hasReceivedPaymentMethodCredit = accountCreditTypes.includes(PAYMENT_METHOD_CREDIT_TYPE);

  const bannerTitle = useMemo(() => {
    if (title) {
      return title;
    }
    if (!hasReceivedPaymentMethodCredit && accountCreditsRemaining) {
      return 'Keep scaling & unlock an additional $500 credits by adding a payment method!';
    }
    if (hasReceivedPaymentMethodCredit && accountCreditsRemaining) {
      return 'Keep scaling by adding a payment method';
    }

    return 'Sponsor mainnet transactions by adding a payment method!';
  }, [accountCreditsRemaining, hasReceivedPaymentMethodCredit, title]);

  const bannerDescription = useMemo(() => {
    if (description) {
      return description;
    }
    if (!hasReceivedPaymentMethodCredit && accountCreditsRemaining) {
      return "We'll automatically deduct credits from your invoice. To obtain an extra $500 in credits and ensure uninterrupted service beyond your credit balance, simply";
    }
    if (hasReceivedPaymentMethodCredit && accountCreditsRemaining) {
      return "We'll automatically deduct credits from your invoice. To ensure uninterrupted service beyond your credit balance, simply";
    }

    if (!hasReceivedPaymentMethodCredit) {
      return 'You are currently limited to testnet transactions. To sponsor mainnet transactions and to obtain an extra $500 worth of credits';
    }

    return 'You are currently limited to testnet transactions. To sponsor mainnet transactions ';
  }, [accountCreditsRemaining, description, hasReceivedPaymentMethodCredit]);

  return (
    <VStack>
      <VStack
        flexGrow={1}
        width="100%"
        maxWidth={FULL_PAGE_MAX_WIDTH}
        spacingHorizontal={spacingHorizontal}
        spacingTop={spacingTop}
        spacingBottom={spacingBottom}
      >
        <Banner
          title={bannerTitle}
          variant="promotional"
          startIcon="info"
          primaryAction={primaryAction}
        >
          {bannerDescription}{' '}
          {!primaryAction && (
            <Pressable background="transparent" onPress={handleAddPaymentMethodPress}>
              <TextBody as="span" color="primary">
                add a payment method.
              </TextBody>
            </Pressable>
          )}
        </Banner>
      </VStack>
    </VStack>
  );
}
