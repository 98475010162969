import { INTEGRATED_PRODUCTS } from ':cloud/content/products';
import { ProductItem } from ':cloud/types/ts_types';

import { ProductConfig } from '../types';

const mainConfig = INTEGRATED_PRODUCTS.find((product) => product.productType === 'base');

export const baseConfig: ProductConfig & Partial<ProductItem> = {
  ...mainConfig,
  productName: 'Base Node',
  productNameShort: 'base',
  productType: 'base',
  productVariantId: '482182f9-ef66-4c98-930d-aa631146fe51',
  productTag: 'Preview',
  networkOptions: [
    { label: 'Base Mainnet', value: 'base' },
    { label: 'Base Testnet (Sepolia)', value: 'base-sepolia' },
  ],
};
