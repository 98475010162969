import { useMemo } from 'react';

import { useGetApiKeys } from ':cloud/queries/ApiKeyQueries/useGetApiKeys';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';

function useApiKeysForUser(projectId?: string) {
  const {
    activeOrg,
    user: { userUuid },
  } = useGetUser();
  const { keys } = useGetApiKeys(activeOrg?.organizationId);

  return useMemo(
    () =>
      keys.filter(
        (key) => key.principal === userUuid && (!projectId || key.projectId === projectId),
      ),
    [keys, projectId, userUuid],
  );
}

export default useApiKeysForUser;
