import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { ChangeAccountItemRequest, ChangeAccountItemResponse } from ':cloud/types/service_proto';

async function changeAccountItemVariant(
  { productVariantId, itemId }: ChangeAccountItemRequest,
  orgId: string,
): Promise<ChangeAccountItemResponse> {
  const path = `/billing/v1/accounts/${orgId}/items/${itemId}:changeVariant`;
  const { data } = await APIClientBase.post<ChangeAccountItemResponse>(path, { productVariantId });
  return data;
}

// Updates the canceled_at timestamp on existing account item
// and creates a new account item with the provided productVariantId
// Note: not applicable to plans that only have 1 option (ie, WaaS usage)
export function useChangeAccountItemVariant(orgId = '') {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (changeRequest: ChangeAccountItemRequest) =>
      changeAccountItemVariant(changeRequest, orgId),
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKey.accounts, QueryKey.accountItems, orgId]);
    },
  });
}
