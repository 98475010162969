import { useMemo } from 'react';
import _round from 'lodash/round';
import { Pie, PieChart, ResponsiveContainer } from 'recharts';
import { Box } from '@cbhq/cds-web/layout';
import { palette } from '@cbhq/cds-web/tokens';

interface RadialChartProps {
  size?: number; // diameter
  value?: number;
  unit?: string;
  color: string;
  total?: number;
  radialPercent?: number | undefined;
  positionX?: number;
  positionY?: number;
}

export function RadialChart({
  size = 92,
  value,
  radialPercent,
  total = 100,
  color,
  unit,
  positionX,
  positionY,
}: RadialChartProps) {
  const radialColor = radialPercent || value ? color : palette.lineHeavy;
  const textColor = radialPercent || value ? palette.foreground : palette.lineHeavy;
  let displayPercent = 0;
  let displayText;
  let isUnitVisible = false;
  if (radialPercent && value) {
    displayPercent = radialPercent;
    displayText = value <= 1 ? _round(value, 2) : _round(value, 0);
    isUnitVisible = true;
  }
  if (radialPercent && !value) {
    displayPercent = radialPercent;
    displayText =
      radialPercent && radialPercent <= 1 ? _round(radialPercent, 2) : _round(radialPercent, 0);
    isUnitVisible = true;
  }
  if (value && !displayPercent) {
    displayPercent = (value / total) * 100;
    displayText = value <= 1 ? _round(value, 2) : _round(value, 0);
    isUnitVisible = true;
  }
  if (!value && !displayPercent) {
    displayPercent = 0;
    displayText = '--';
  }
  // order for conditional matters
  if (radialPercent === 0 || value === 0) {
    displayPercent = 0;
    displayText = 0;
    isUnitVisible = true;
  }

  const pieData = useMemo(() => {
    return [
      { value: displayPercent, fill: radialColor },
      { value: 100 - displayPercent, fill: radialColor, opacity: 0.2 },
    ];
  }, [displayPercent, radialColor]);

  return (
    <Box height={size} width={size} display="block">
      <ResponsiveContainer id="radial-chart" height="99%" width="99%">
        <PieChart>
          <Pie
            data-testid="radial-chart-pie"
            dataKey="value"
            data={pieData}
            cx="50%"
            cy="50%"
            startAngle={90}
            endAngle={450} // counter-clockwise, api limitation
            innerRadius="85%"
            outerRadius="100%"
            stroke="none"
          />
          <text
            data-testid="radial-chart-value"
            x={positionX || size / 2}
            y={positionY || size / 2 + 2}
            textAnchor="middle"
            dominantBaseline="middle"
            fill={textColor}
            fontFamily="var(--cds-font-mono)"
          >
            {displayText}
            {isUnitVisible && unit}
          </text>
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
}
