import { useMemo } from 'react';

import { platformAPINavItems, primaryNavItems, productAPINavItems } from ':cloud/config/navigation';
import { useBOAT } from ':cloud/hooks/featureFlags/useBOAT';
import { useFaucet } from ':cloud/hooks/featureFlags/useFaucet';
import { useIsEarningsEnabled } from ':cloud/hooks/featureFlags/useIsEarningsEnabled';
import { useIsOAuthEnabled } from ':cloud/hooks/featureFlags/useIsOAuthEnabled';
import { useIsOnCBPayConsumerAllowlist } from ':cloud/hooks/featureFlags/useIsOnCBPayConsumerAllowlist';
import { useIsOnParticipateAllowlist } from ':cloud/hooks/featureFlags/useIsOnParticipateAllowlist';
import { useIsOnStakingAllowlist } from ':cloud/hooks/featureFlags/useIsOnStakingAllowlist';
import { useIsVerificationsOnboardingEnabled } from ':cloud/hooks/featureFlags/useIsVerificationsOnboardingEnabled';
import { useIsWalletHistoryEnabled } from ':cloud/hooks/featureFlags/useIsWalletHistoryEnabled';
import { useWebhooks } from ':cloud/hooks/featureFlags/useWebhooks';
import { useGetOrganization } from ':cloud/queries/OrganizationQueries/useGetOrganization';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';

import { useIsBasePlaygroundEnabled } from './featureFlags/useIsBasePlaygroundEnabled';
import { useIsExchangeOnboardingEnabled } from './featureFlags/useIsExchangeOnboardingEnabled';
import { useIsLegacyAPITokenManagementEnabled } from './featureFlags/useIsLegacyAPITokenManagementEnabled';

export function useGetNavMenuItems() {
  const { activeOrg } = useGetUser();
  const orgId = activeOrg?.organizationId;
  const { orgHasMultipleUsers } = useGetOrganization(orgId);

  const isOnStakingApiAllowlist = useIsOnStakingAllowlist();
  const isOnParticipateAllowlist = useIsOnParticipateAllowlist();
  const isEarningsEnabled = useIsEarningsEnabled();
  const isLegacyAPIManagementEnabled = useIsLegacyAPITokenManagementEnabled();
  const isOnCBPayConsumerAllowlist = useIsOnCBPayConsumerAllowlist();
  const isOnFaucetAllowlist = useFaucet();
  const isBasePlaygroundEnabled = useIsBasePlaygroundEnabled();
  const isOnBOATAllowlist = useBOAT();
  const isWalletHistoryEnabled = useIsWalletHistoryEnabled();
  const isOAuthEnabled = useIsOAuthEnabled();
  const isExchangeOnboardingEnabled = useIsExchangeOnboardingEnabled();
  const isWebhooksEnabled = useWebhooks();
  const isVerificationsOnboardingEnabled = useIsVerificationsOnboardingEnabled();

  return useMemo(() => {
    const displayedPrimaryNavItems = primaryNavItems.filter((item) => {
      if (
        (item.id === 'webhooks' && !isWebhooksEnabled) ||
        (item.id === 'earnings' && !isEarningsEnabled) ||
        (item.id === 'team' && !orgHasMultipleUsers) ||
        (item.id === 'legacy-api' && !isLegacyAPIManagementEnabled)
      ) {
        return false;
      }
      return true;
    });

    const displayedPlatformAPINavItems = platformAPINavItems.filter((item) => {
      if (
        (item.id === 'participate' && !isOnParticipateAllowlist) ||
        (item.id === 'staking' && !isOnStakingApiAllowlist) ||
        (item.id === 'pay-docs' && isOnCBPayConsumerAllowlist) ||
        (item.id === 'pay' && !isOnCBPayConsumerAllowlist) ||
        (item.id === 'faucet' && !isOnFaucetAllowlist) ||
        (item.id === 'bundler-paymaster' && !isBasePlaygroundEnabled) ||
        (item.id === 'onchainkit' && !isOnBOATAllowlist) ||
        (item.id === 'wallet_history' && !isWalletHistoryEnabled) ||
        // Remove once the jul_2024_verifications_onboarding experiment is resolved
        (item.id === 'verifications-docs' && isVerificationsOnboardingEnabled) ||
        (item.id === 'verifications' && !isVerificationsOnboardingEnabled)
      ) {
        return false;
      }
      return true;
    });

    const displayedProductAPINavItems = productAPINavItems.filter((item) => {
      if (
        (item.id === 'participate' && !isOnParticipateAllowlist) ||
        (item.id === 'staking' && !isOnStakingApiAllowlist) ||
        (item.id === 'exchange' && !isExchangeOnboardingEnabled) ||
        // Remove once the jun_2024_exchange_onboarding experiment is resolved
        (item.id === 'exchange-docs' && isExchangeOnboardingEnabled)
      ) {
        return false;
      }
      return true;
    });

    /**
     * if OAuth tab is enabled we change name of API tab to Access
     */
    if (isOAuthEnabled) {
      const accessNavItem = displayedPrimaryNavItems.find((item) => item.id === 'api');
      if (accessNavItem) {
        accessNavItem.title = 'Access';
      }
    }

    return {
      displayedPrimaryNavItems,
      displayedPlatformAPINavItems,
      displayedProductAPINavItems,
    };
  }, [
    isOAuthEnabled,
    isWebhooksEnabled,
    isEarningsEnabled,
    orgHasMultipleUsers,
    isOnParticipateAllowlist,
    isOnStakingApiAllowlist,
    isLegacyAPIManagementEnabled,
    isOnCBPayConsumerAllowlist,
    isOnFaucetAllowlist,
    isBasePlaygroundEnabled,
    isOnBOATAllowlist,
    isWalletHistoryEnabled,
    isExchangeOnboardingEnabled,
    isVerificationsOnboardingEnabled,
  ]);
}
