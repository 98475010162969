import { useContext } from 'react';

import config from ':cloud/config';
import { FeatureFlagContext } from ':cloud/contexts/FeatureFlagContext';

function useIsFeatureEnabled(name: string): boolean {
  const featureFlagSet = useContext(FeatureFlagContext);
  return featureFlagSet.has(name) || config.isMocksEnabled();
}

export default useIsFeatureEnabled;
