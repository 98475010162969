import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@cbhq/cds-web/buttons';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { TextBody, TextTitle3 } from '@cbhq/cds-web/typography';

import { useOAuthInfo } from ':cloud/contexts/OAuthInfoContext';
import { logError } from ':cloud/init/bugsnag/logging';
import { useCreateOAuthClient } from ':cloud/queries/OAuthClientQueries/useCreateOAuthClient';
import { useGetOAuthClients } from ':cloud/queries/OAuthClientQueries/useGetOAuthClients';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import {
  ApplicationType,
  ClientRecordNonSensitive,
  CreateOAuthClientRequest,
  ServicesOffered,
} from ':cloud/types/service_proto';
import { AppRoute } from ':cloud/utils/routes';
import OAuthOptionalSteps from ':cloud/widgets/access/oauth/OAuthOptionalSteps';
import { OAuthRequiredSteps } from ':cloud/widgets/access/oauth/OAuthRequiredSteps';
import {
  ErrorsState,
  OptionalState,
  RequiredState,
  UploadedFile,
} from ':cloud/widgets/access/oauth/types';
import { Layout } from ':cloud/widgets/layout';

const initialServicesOffered: ServicesOffered = {
  remittances: false,
  financialServices: false,
  gambling: false,
  currencyExchange: false,
};

// https://stackoverflow.com/a/5717133
const urlPattern = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '(([a-z\\d]+(-[a-z\\d]+)*\\.)+[a-z]{2,}|' + // domain name with improved handling
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?' + // port
    '(\\/[\\w%_.~-]*)*' + // path simplified with \w
    '(\\?[;&\\w%_.~-]*)?' + // query string simplified with \w
    '(\\#[\\w_]*)?$', // fragment locator simplified with \w
  'i',
);
export function isValidURI(uri: string): boolean {
  const urlRegex = urlPattern;
  return uri.trim() === '' || urlRegex.test(uri.trim());
}

export function validateRedirectURIs(uris: string): boolean {
  const urlRegex = urlPattern;
  const uriArray = uris.split(/\r?\n/);
  return uriArray.every((uri) => urlRegex.test(uri.trim()));
}

function validateName(input: string): boolean {
  if (!/^[a-zA-Z0-9].*[a-zA-Z0-9]$/.test(input)) {
    return false;
  }

  const hasSpecialCharacters = /[^a-zA-Z0-9 ]/.test(input);
  if (hasSpecialCharacters) {
    return false;
  }

  const spaceCount = (input.match(/ /g) || []).length;
  if (spaceCount > 5) {
    return false;
  }

  const containsCoinbase = /coinbase/i.test(input);
  if (containsCoinbase) {
    return false;
  }

  return true;
}

export const lengthLimits: Partial<Record<keyof ClientRecordNonSensitive, number>> = {
  name: 100,
  developerWebsite: 100,
  notificationsUrl: 100,
  redirectUris: 1000,
  description: 1000,
};

export const lengthErrorMessages: Partial<Record<keyof ClientRecordNonSensitive, string>> = {
  name: 'Application name cannot exceed 100 characters',
  developerWebsite: 'Website URL cannot exceed 100 characters',
  notificationsUrl: 'Notifications URL cannot exceed 100 characters',
  redirectUris: 'Redirect URIs cannot exceed 1000 characters',
  description: 'Description cannot exceed 1000 characters',
};

export function isOverLengthLimit(input: string, limit: number): boolean {
  return input.length > limit;
}

const applicationTypeMap = {
  eCommerce: ApplicationType.ECOMMERCE,
  'Micropayments or Programmable Payments': ApplicationType.MICROPAYMENTS,
  'Bitcoin Wallet': ApplicationType.BITCOIN_WALLET,
  Gaming: ApplicationType.GAMING,
  'Financial Services': ApplicationType.FINANCIAL_SERVICES,
  Other: ApplicationType.OTHER,
};

export function OAuthCreateClient() {
  const history = useHistory();
  const toast = useToast();
  const { activeOrg, user } = useGetUser();
  const { setSensitiveInfo } = useOAuthInfo();
  const { clients } = useGetOAuthClients(activeOrg?.organizationId);
  const {
    mutate: createOAuthClient,
    isLoading,
    isError,
  } = useCreateOAuthClient(activeOrg?.organizationId, {
    onSuccess: (_clientInfo, sensitiveInfo) => {
      try {
        setSensitiveInfo({
          oauthClientID: sensitiveInfo.clientId,
          oauthClientSecret: sensitiveInfo.clientSecret,
        });
      } catch (e) {
        logError(e as Error, { context: 'set_sensitive_client_info' });
      }
      toast.show('Client successfully created.', {
        variant: 'positive',
      });
      history.push(AppRoute.Access.OAuth);
    },
    onError: (createError, variables) => {
      logError(createError, { context: 'create_client' }, { variables: JSON.stringify(variables) });
      toast.show('An error occurred creating the OAuth client. Please retry', {
        variant: 'negative',
      });
    },
  });

  const [requiredState, setRequiredState] = useState<RequiredState>({
    name: '',
    appType: '',
    redirectUris: '',
    servicesOffered: initialServicesOffered,
  });

  const [optionalState, setOptionalState] = useState<OptionalState>({
    developerWebsite: '',
    notificationsUrl: '',
    description: '',
  });

  const [errors, setErrors] = useState<ErrorsState>({
    name: '',
    appType: '',
    redirectUris: '',
    developerWebsite: '',
    notificationsUrl: '',
  });

  const handleRequiredInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setRequiredState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    },
    [],
  );

  const handleOptionalInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setOptionalState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    },
    [],
  );

  const handleSelectChange = useCallback((newValue: string) => {
    setRequiredState((prevState) => ({
      ...prevState,
      appType: newValue as ApplicationType,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      applicationType: '',
    }));
  }, []);

  const handleSwitchChange = useCallback((service: keyof ServicesOffered) => {
    setRequiredState((prevState) => ({
      ...prevState,
      servicesOffered: {
        ...prevState.servicesOffered,
        [service]: !prevState.servicesOffered[service],
      },
    }));
  }, []);

  const [logoFile, setLogoFile] = useState<UploadedFile | null>(null);

  const handleLogoUpload = useCallback((file: UploadedFile) => {
    setLogoFile(file);
  }, []);

  const handleLogoRemove = useCallback(() => {
    setLogoFile(null);
  }, []);

  const handleBackPress = useCallback(() => {
    history.push(AppRoute.Access.OAuth);
  }, [history]);

  const handleSubmit = useCallback(() => {
    // Check for and set errors
    const newErrors: ErrorsState = {
      name: requiredState.name ? '' : 'Application name is required',
      appType: requiredState.appType ? '' : 'Application type is required',
      redirectUris: requiredState.redirectUris ? '' : 'Redirect URI is required',
      developerWebsite:
        !optionalState.developerWebsite || isValidURI(optionalState.developerWebsite)
          ? ''
          : 'Website URL is not valid',
      notificationsUrl:
        !optionalState.notificationsUrl || isValidURI(optionalState.notificationsUrl)
          ? ''
          : 'Notifications URL is not valid',
    };

    if (requiredState.redirectUris && !validateRedirectURIs(requiredState.redirectUris)) {
      newErrors.redirectUris = 'Redirect URI is not valid';
    }

    if (!validateName(requiredState.name)) {
      newErrors.name =
        "Application name must be alphanumeric, start and end with a letter or number, cointain less than 5 spaces, and cannot include 'Coinbase'";
    }

    if (clients.some((client) => client.name === requiredState.name)) {
      newErrors.name = 'Application name already exists';
    }

    // Check and set length limit errors
    const fieldsToCheck = [
      { key: 'name', value: requiredState.name },
      { key: 'developerWebsite', value: optionalState.developerWebsite },
      { key: 'notificationsUrl', value: optionalState.notificationsUrl },
      { key: 'redirectUris', value: requiredState.redirectUris },
      { key: 'description', value: optionalState.description },
    ];

    fieldsToCheck.forEach(({ key, value }) => {
      if (isOverLengthLimit(value, lengthLimits[key])) {
        newErrors[key] = lengthErrorMessages[key];
      }
    });

    setErrors(newErrors);

    // early return if errors exist
    if (Object.values(newErrors).some((e) => e.length > 0)) {
      return;
    }

    const clientRequest: CreateOAuthClientRequest = {
      parent: `organizations/${activeOrg?.organizationId}`,
      clientRecordNonSensitive: {
        name: requiredState.name,
        owner: user.userId,
        redirectUris: requiredState.redirectUris,
        appType: applicationTypeMap[requiredState.appType],
        servicesOffered: requiredState.servicesOffered,
        description: optionalState.description,
        notificationsUrl: optionalState.notificationsUrl,
        developerWebsite: optionalState.developerWebsite,
        icon: logoFile?.bytes || '',
      },
    };

    createOAuthClient(clientRequest);
  }, [
    requiredState,
    optionalState,
    clients,
    activeOrg?.organizationId,
    user.userId,
    logoFile?.bytes,
    createOAuthClient,
  ]);

  return (
    <Layout>
      <Layout.MainContainer fullWidth>
        <VStack spacingBottom={4} gap={4}>
          <TextTitle3 as="h3">Let&apos;s get started</TextTitle3>
          <OAuthRequiredSteps
            requiredState={requiredState}
            errors={errors}
            handleRequiredInputChange={handleRequiredInputChange}
            handleSelectChange={handleSelectChange}
            handleSwitchChange={handleSwitchChange}
          />
          <OAuthOptionalSteps
            optionalState={optionalState}
            errors={errors}
            handleOptionalInputChange={handleOptionalInputChange}
            handleLogoUpload={handleLogoUpload}
            handleLogoRemove={handleLogoRemove}
          />
          <HStack justifyContent="space-between">
            <Button variant="secondary" onPress={handleBackPress}>
              Cancel
            </Button>
            <Button variant="primary" loading={isLoading} onClick={handleSubmit}>
              Create client
            </Button>
          </HStack>
          <HStack justifyContent="flex-end">
            {isError && (
              <TextBody as="p" color="negative">
                An error occured. Please try again later.
              </TextBody>
            )}
          </HStack>
        </VStack>
      </Layout.MainContainer>
    </Layout>
  );
}
