// countries list come froms app/models/concerns/user_international.rb
// @see http://ec.europa.eu/eurostat/statistics-explained/index.php/Glossary:Country_codes
// @note The code UK is used by them for United Kingdom, but the official ISO standard uses GB.
const EUROPEAN_UNION_LIST = [
  'BE', // Belgium
  'BG', // Bulgaria
  'CZ', // Czech Republic
  'DK', // Denmark
  'DE', // Germany
  'EE', // Estonia
  'IE', // Ireland
  'GR', // Greece
  'ES', // Spain
  'FR', // France
  'HR', // Croatia
  'IT', // Italy
  'CY', // Cyprus
  'LV', // Latvia
  'LT', // Lithuania
  'LU', // Luxembourg
  'HU', // Hungary
  'MT', // Malta
  'NL', // Netherlands
  'AT', // Austria
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'SI', // Slovenia
  'SK', // Slovakia
  'FI', // Finland
  'SE', // Sweden
  'GB', // United Kingdom
]; // European Free Trade Association (EFTA)

const EFTA_LIST = [
  'IS', // Iceland
  'LI', // Liechtenstein
  'NO', // Norway
  'CH', // Switzerland
]; // List of newly added Regions that are getting access to SEPA.
// The list is used to disallow Worldpay/Secure3D support,
// make sure they are receive GDPR restrictions,
// and excuse older users from upfront EDP (KYC) and IDV.

const DEC_2018_SUPPORTED_EUROPEAN_COUNTRIES = [
  'AD', // Andorra
  'GG', // Guernsey
  'GI', // Gibraltar
  'IM', // Isle of Man
  'IS', // Iceland
  'LT', // Lithuania
];

const GDPR_COUNTRIES = [
  ...EUROPEAN_UNION_LIST,
  ...EFTA_LIST,
  ...DEC_2018_SUPPORTED_EUROPEAN_COUNTRIES,
]; // Most legal regulation applies to both the EU and the EFTA, so keep a combined list.

const OFFICIAL_EU_COUNTRIES = [...EUROPEAN_UNION_LIST, ...EFTA_LIST];

function isInEU(countryCode: string): boolean {
  return OFFICIAL_EU_COUNTRIES.includes(countryCode.toUpperCase());
}

function isGdprCountry(countryCode: string): boolean {
  return GDPR_COUNTRIES.includes(countryCode.toUpperCase());
}

// eslint-disable-next-line import/no-unused-modules
export { isGdprCountry, isInEU };
