import { History } from 'history';

import { NavItemChild, ParentNavItem } from ':cloud/config/navigation';
import { EventName, logClick } from ':cloud/init/clientAnalytics/logging';
import { openUrlInNewWindow } from ':cloud/utils/openUrlInNewWindow';
import { AppRoute } from ':cloud/utils/routes';

import { SidebarFlyoutItem } from './SidebarItemWithFlyoutMenu';

export function getChildNavItems(
  parent: ParentNavItem,
  allNavItems: NavItemChild[],
  history: History,
): (JSX.Element | null)[] {
  const childNavItems = parent.childIds.map((childId) => {
    const child = allNavItems?.find((navItem) => navItem.id === childId);
    if (!child) return null;

    const handlePress = (childItem: NavItemChild) => {
      const activeRoute = allNavItems.find((item) => item.id === childItem.id);
      logClick(EventName.left_nav, { target: activeRoute?.title });
      if (!activeRoute) {
        return;
      }
      if (activeRoute.to?.startsWith('http')) {
        openUrlInNewWindow(activeRoute.to);
      } else {
        history.push(activeRoute.to || AppRoute.Home);
      }
    };

    return (
      <SidebarFlyoutItem
        key={`sidebar-item--${child.id}`}
        title={child.title}
        detail={child.detail}
        icon={child.icon}
        iconType={child.iconType}
        onPress={() => handlePress(child)}
      />
    );
  });
  return childNavItems;
}
