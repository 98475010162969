import BigNumber from 'bignumber.js';
import { ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { Rewards } from ':cloud/widgets/earnings/types';

/**
 * Custom formatter to format numbers with 2 decimal places for values greater than 0
 * or at least 2 significant figures for values less than 0.
 */
function customFormat(value: BigNumber): string {
  // value > 0 and value < 1
  if (value.isGreaterThan(0) && value.isLessThan(1)) {
    const valueStr = value.toString();
    const [, decimalPart] = valueStr.split('.');
    if (!decimalPart) return '0.00'; // if no decimal parts, return "0.00"

    const leadingZeros = decimalPart.match(/^0*/)?.[0].length ?? 0;
    // calculate the number of significant digits needed
    const significantDigits = decimalPart.length - leadingZeros;
    const roundTo = significantDigits >= 3 ? leadingZeros + 2 : leadingZeros + significantDigits;

    return value.toFixed(roundTo);
  }
  // value > 1
  return value.toFixed(2);
}

/**
  Returns the sum of all rewards in native units and USD to 2 decimal places
  required for high precision numbers.
 */
export function sumEarnings(rewards: Rewards) {
  let totalEarnedNativeUnits = new BigNumber(0);
  let totalUSD = new BigNumber(0);

  Object.values(rewards).forEach((reward) => {
    totalEarnedNativeUnits = totalEarnedNativeUnits.plus(reward.totalEarnedNativeUnit);
    totalUSD = totalUSD.plus(reward.usdUnit);
  });

  return {
    totalEarnedNativeUnits: customFormat(totalEarnedNativeUnits),
    totalUSD: customFormat(totalUSD),
  };
}

// Formats a given value to either a whole number or a decimal representation
export function wholeOrFormatDecimals(value?: ValueType, decimals?: number): string {
  if (value === undefined) {
    return '';
  }
  const parsedDecimal = parseFloat(value.toString());
  return Number.isNaN(parsedDecimal) || parsedDecimal % 1 === 0
    ? value.toString()
    : parsedDecimal.toFixed(decimals);
}
