import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';

import { logError } from ':cloud/init/bugsnag/logging';
import { APIClientBase } from ':cloud/state/Api';
import { CreateCommerceChargeResponse } from ':cloud/types/service_proto';

import { QueryKey } from '../types';

async function createCommerceCharge(orgId?: string): Promise<CreateCommerceChargeResponse> {
  const path = `/billing/v1/accounts/${orgId}:createCommerceCharge`;
  const { data } = await APIClientBase.post<CreateCommerceChargeResponse>(path);

  return data;
}

export function useCreateCommerceCharge(orgId = '') {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => createCommerceCharge(orgId),
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKey.billing, 'commerce-create-charge', orgId]);
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        logError(new Error(error.message), {
          context: 'buy_credits',
        });
      }
    },
  });
}
