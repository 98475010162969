import { useQuery } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { GetAccountItemsResponse } from ':cloud/types/service_proto';
import { AccountItem } from ':cloud/types/ts_types';

async function fetchAccountItems(orgId): Promise<GetAccountItemsResponse> {
  const { data } = await APIClientBase.get<GetAccountItemsResponse>(
    `/billing/v1/accounts/${orgId}/items`,
  );
  return data;
}

function generateAccountItemsQuery({ orgId, enabled }) {
  return {
    queryKey: [QueryKey.accounts, QueryKey.accountItems, orgId],
    queryFn: async () => fetchAccountItems(orgId),
    enabled,
    staleTime: Infinity,
  };
}

export function useGetAccountItems(orgId?: string): {
  isLoading: boolean;
  accountItems: AccountItem[];
} {
  const { isLoading, data } = useQuery(generateAccountItemsQuery({ orgId, enabled: !!orgId }));

  return {
    isLoading,
    accountItems: data?.accountItems || [],
  };
}
