import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { VStack } from '@cbhq/cds-web/layout';
import { TextDisplay2 } from '@cbhq/cds-web/typography';

import { useNewCluster } from ':cloud/contexts/NewClusterContext';
import { CLUSTER_CREATION_TIME } from ':cloud/queries/ResourceQueries/useCreateResource';
import { useGetProtocolDisplayName } from ':cloud/queries/WorkflowQueries/useGetProtocolDisplayName';
import { getAdapter } from ':cloud/utils/clusters';
import { ClusterInterstitial } from ':cloud/widgets/sharedcomponents';

export function Transition() {
  const adapter = getAdapter();
  const { formData, reset } = useNewCluster();
  const { displayName } = useGetProtocolDisplayName(formData?.protocolKey || '');
  const history = useHistory();

  useEffect(() => {
    function endTransition(): void {
      reset();
      history.push(`${adapter.clusterRoute}`);
    }
    /* DEBUG: To halt ClusterInterstitial comment out the next line */
    setTimeout(endTransition, CLUSTER_CREATION_TIME);
  }, [adapter.clusterRoute, history, reset]);

  return (
    <VStack justifyContent="center" alignItems="center">
      <VStack width={700} alignItems="center">
        <VStack spacingHorizontal={3} maxWidth={400}>
          <TextDisplay2 as="h1" align="center" spacingBottom={5}>
            Launching your secure {displayName} Participate Cluster
          </TextDisplay2>
        </VStack>
        <ClusterInterstitial runProgress formData={formData} />
      </VStack>
    </VStack>
  );
}
