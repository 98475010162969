import { useCallback, useMemo, useState } from 'react';

import { StripeActionHandler } from ':cloud/widgets/billing/types';

import DisplayInvoiceView from './DisplayInvoiceView';

interface PaymentDisplayerProps {
  displaySubmitAction: StripeActionHandler;
  displaySubmitLabel: string;
  entryCancelAction?: StripeActionHandler;
  entryCancelText: string;
  entrySubmitAction: StripeActionHandler;
  entrySubmitLabel: string;
  initialView: string;
  enableClusterSummaryRef?: boolean;
}

/**
 * Only used in CreateClusterBilling. Use new PaymentMethodDisplayer component for all other use cases.
 */
function InvoicePaymentDisplayer({
  displaySubmitAction,
  displaySubmitLabel,
  initialView,
  enableClusterSummaryRef,
}: PaymentDisplayerProps) {
  const [viewEntryMode, setViewEntryMode] = useState(initialView === 'entry');

  const toggleViewEntryMode = useCallback((): void => {
    setViewEntryMode(!viewEntryMode);
  }, [viewEntryMode]);

  const renderDisplayInvoice = useMemo(() => {
    return (
      <DisplayInvoiceView
        isLoading={false}
        changeView={toggleViewEntryMode}
        submitAction={displaySubmitAction}
        submitBtnName={displaySubmitLabel}
        enableClusterSummaryRef={enableClusterSummaryRef}
      />
    );
  }, [toggleViewEntryMode, displaySubmitAction, displaySubmitLabel, enableClusterSummaryRef]);

  return renderDisplayInvoice;
}

export default InvoicePaymentDisplayer;
