import { useQueries, UseQueryResult } from '@tanstack/react-query';

import { generateOrganizationQuery } from ':cloud/queries/OrganizationQueries/useGetAllOrganizations';
import {
  FormattedOrganizationResponse,
  generateOrganizationResponse,
} from ':cloud/queries/OrganizationQueries/useGetOrganization';
import { generateProjectsQuery } from ':cloud/queries/Projects/useGetProjects';
import {
  GetOrganization,
  GetOrganizationsResponse,
  GetProjectsResponse,
} from ':cloud/types/service_proto';

type QueriesType = [UseQueryResult<GetOrganizationsResponse>, UseQueryResult<GetProjectsResponse>];

type UseGetAppStartDataProps = {
  userId?: string;
  orgId?: string;
};

/*
 * combines all queries that need to run at initial app load
 * these are run in parallel and will block the app from loading until complete
 */
export function useGetAppStartData({ userId = '', orgId = '' }: UseGetAppStartDataProps): {
  isLoading: boolean;
  organization: FormattedOrganizationResponse;
  projects: GetProjectsResponse;
} {
  const queries: QueriesType = useQueries({
    queries: [
      generateOrganizationQuery({
        userId,
      }),
      generateProjectsQuery({
        organizationId: orgId,
      }),
    ],
  });

  const currentOrgResponse = queries[0]?.data?.find(
    (item) => item.organizationId === orgId,
  ) as GetOrganization;

  return {
    isLoading: queries.some((query) => query.isLoading),
    organization: generateOrganizationResponse(currentOrgResponse?.organizationDetails) || [],
    projects: queries[1]?.data || [],
  };
}
