import { WhitelistedAddressAndMethod } from ':cloud/types/ts_types';

export function hasUpdatedWhitelist(
  prevWhitelist: WhitelistedAddressAndMethod[],
  updatedWhitelist: WhitelistedAddressAndMethod[],
) {
  if (prevWhitelist.length !== updatedWhitelist.length) {
    return true;
  }

  const compareMethods = (methods1?: string[], methods2?: string[]): boolean => {
    if (!methods1 && !methods2) {
      return false;
    }
    if (!methods1 || !methods2 || methods1.length !== methods2.length) {
      return true;
    }
    return methods1.some((method, index) => method !== methods2[index]);
  };

  for (let i = 0; i < prevWhitelist.length; i += 1) {
    const prevItem = prevWhitelist[i];
    const updatedItem = updatedWhitelist[i];

    if (
      prevItem.address !== updatedItem.address ||
      compareMethods(prevItem.methods, updatedItem.methods)
    ) {
      return true;
    }
  }

  return false;
}

export function formatTxnAmount(value: string) {
  if (value === '0') return value;
  /* this removes leading zeros */
  return value.replace(/^0+/, '');
}

export function isValidGasAmount(value: string) {
  if (value === '') return true;
  const regex = /^[0-9]*\.?[0-9]*$/;
  return regex.test(value);
}

export function isValidTxnAmount(value: string) {
  if (value === '') return true;
  if (value.includes('.')) return false;
  const regex = /^\d+$/;
  return regex.test(value);
}

export function isValidContractAddress(address: string) {
  if (!address.length) {
    return true;
  }
  if (!address.startsWith('0x')) {
    return false;
  }

  if (address.length !== 42) {
    return false;
  }
  const hexPattern = /^0x[a-fA-F0-9]{40}$/;
  return hexPattern.test(address);
}

const methodRegex = /(\w+\(\)|\w+\([^()]*\)|0x[a-fA-F0-9]{8})/g;

export function isValidMethodInput(input: string) {
  if (!input.length) {
    return true;
  }

  try {
    const matchArray = input.match(methodRegex);

    if (!matchArray) {
      return false;
    }

    const totalMatchLength =
      matchArray.reduce((sum, match) => sum + match.length, 0) + matchArray.length - 1; // (commas between methods)
    const isFullMatch = totalMatchLength === input.length;

    return isFullMatch;
  } catch (error) {
    return false;
  }
}

export function getContractMethodsFromString(input: string) {
  const matches = input.match(methodRegex);
  if (!matches) {
    return [];
  }
  return matches.map((match) => match.toString());
}

export function getKeyByValue(map: Record<string, string>, targetValue: string) {
  for (const [key, value] of Object.entries(map)) {
    if (value === targetValue) {
      return key;
    }
  }
  return '';
}

export function getWhitelistItemKey(address: string, methods?: string[], name?: string) {
  if (!methods?.length) {
    return address;
  }
  return `${address}-${methods}-${name}`.replace(/\s+/g, '');
}

export function isValidLabel(label: string) {
  const regex = /^[a-zA-Z0-9 ]*$/;
  return regex.test(label);
}
