import { ReactElement } from 'react';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextHeadline } from '@cbhq/cds-web/typography';

import { CodeBlockProps } from '../sharedcomponents/CodeBlock/CodeBlock';

export type QuickstartItemProps = {
  stepNumber: number;
  title: string;
  codeBlock?: ReactElement<CodeBlockProps>;
};

export function QuickstartItem({ stepNumber, title, codeBlock }: QuickstartItemProps) {
  return (
    <VStack gap={2}>
      <HStack gap={2} alignItems="center">
        <Box
          dangerouslySetBackground="var(--foreground)"
          borderRadius="roundedFull"
          alignItems="center"
          justifyContent="center"
          display="flex"
          width={24}
          minWidth={24}
          height={24}
        >
          <TextHeadline as="h1" color="secondary">
            {stepNumber}
          </TextHeadline>
        </Box>
        <TextHeadline as="h1">{title}</TextHeadline>
      </HStack>
      {codeBlock && codeBlock}
    </VStack>
  );
}
