import { Pictogram } from '@cbhq/cds-web/illustrations/Pictogram';
import { VStack } from '@cbhq/cds-web/layout/VStack';
import { TextLabel2 } from '@cbhq/cds-web/typography/TextLabel2';
import { TextTitle3 } from '@cbhq/cds-web/typography/TextTitle3';

import { ErrorPageProps } from ':cloud/pages/ErrorPage';

export function ErrorView({
  errorMsg = 'Something went wrong',
  errorSubheading = 'Oops, we encountered a problem. Please try refreshing.',
}: ErrorPageProps) {
  return (
    <VStack justifyContent="center" alignItems="center" gap={2} height="100%" width="100%">
      <Pictogram name="warning" dimension="48x48" />
      <TextTitle3 as="p" align="center">
        {errorMsg}
      </TextTitle3>
      <TextLabel2 as="p" align="center">
        {errorSubheading}
      </TextLabel2>
    </VStack>
  );
}
