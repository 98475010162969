import { createExperiments } from '@cbhq/experiments';

const experimentConfig = {
  /* eslint-disable camelcase */
  sep_2023_cloud_staking_api_allowlist: ['control', 'treatment'] as const,
  oct_2023_rat_api_key: ['control', 'treatment'] as const,
  nov_2023_legacy_api_management: ['control', 'treatment'] as const,
  nov_2023_base_cloud: ['control', 'treatment'] as const,
  dec_2023_coms_write_endpoints: ['control', 'treatment'] as const,
  feb_2024_base_account_abstraction: ['control', 'treatment'] as const,
  march_2024_staking_api: ['control', 'treatment'] as const,
  mar_2024_participate_allowlist: ['control', 'treatment'] as const,
  mar_2024_multi_product_nav_updates: ['control', 'treatment'] as const,
  mar_2024_earnings_page: ['control', 'treatment'] as const,
  apr_2024_api_key_consolidation: ['control', 'treatment'] as const,
  apr_2024_rat_credit_banner: ['control', 'treatment'] as const,
  apr_2024_waas_cloud_eua: ['control', 'treatment'] as const,
  apr_2024_delete_project: ['control', 'treatment'] as const,
  apr_2024_cbpay_consumer: ['control', 'treatment'] as const,
  apr_2024_faucet: ['control', 'treatment'] as const,
  apr_2024_base_playground: ['control', 'treatment'] as const,
  may_2024_boat: ['control', 'treatment'] as const,
  may_2024_wallet_history: ['control', 'treatment'] as const,
  may_2024_oauth_tab: ['control', 'treatment'] as const,
  may_2024_billing_usage_tab: ['control', 'treatment'] as const,
  jun_2024_exchange_onboarding: ['control', 'treatment'] as const,
  jun_2024_cdp_onramp_metrics: ['control', 'treatment'] as const,
  jun_2024_edwards_api_key: ['control', 'treatment'] as const,
  jun_2024_pay_with_crypto: ['control', 'treatment'] as const,
  jun_2024_paymaster_revamp: ['control', 'treatment'] as const,
  jun_2024_user_switcher: ['control', 'treatment'] as const,
  jul_2024_paymaster_analytics: ['control', 'treatment'] as const,
  jul_2024_paymaster_analytics_percent: ['control', 'treatment'] as const,
  jul_2024_verifications_onboarding: ['control', 'treatment'] as const,
  jul_2024_webhooks: ['control', 'treatment'] as const,
  jul_2024_paymaster_guide: ['control', 'treatment'] as const,
  /* eslint-enable camelcase */
};

export const Experiments = createExperiments(experimentConfig);
