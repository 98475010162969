import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { CellSpacing } from '@cbhq/cds-web';
import { DotStatusColor } from '@cbhq/cds-web/dots/DotStatusColor';
import { VStack } from '@cbhq/cds-web/layout';
import { FullscreenModal } from '@cbhq/cds-web/overlays';
import { Table, TableBody, TableCell, TableHeader, TableRow } from '@cbhq/cds-web/tables';
import { TextBody, TextHeadline, TextTitle3 } from '@cbhq/cds-web/typography';

import CloudLogoWordmark from ':cloud/assets/Brand/CDPLogoWordmark';
import { AppRoute } from ':cloud/utils/routes';
import { DashIcon } from ':cloud/widgets/access/components/ActionCell';

/** User role permissions using boolean array format printed on a static table */
/** Role name format: owner, admin, user */
const permissionRoleChart: [string, boolean, boolean, boolean][] = [
  ['Edit organization name', true, false, false],
  ['View projects', true, true, true],
  ['Create, update & delete projects', true, true, false],
  ['Invite team members', true, true, false],
  ['Manage user privileges', true, true, false],
  ['Remove admin', true, false, false],
  ['Remove user', true, true, false],
  ['Update billing info', true, true, false],
];

const innerSpacing: CellSpacing = { spacingVertical: 3 };

export function PermissionsPage() {
  const history = useHistory();
  const handleClose = useCallback(() => {
    history.push(AppRoute.Access.Teams);
  }, [history]);

  const primaryContent = (
    <VStack gap={5} spacingVertical={6}>
      <VStack gap={1}>
        <TextTitle3 as="h3">Building your organization</TextTitle3>
        <TextBody as="p" spacingBottom={2}>
          Members of your team can be added to your organization to help with various service needs.
          You can add as many members to your organization as needed. Every member you invite to
          your organization will receive an email to create their own Developer Platform account
          using the email address that you used to invite them.
        </TextBody>
        <TextBody as="p">This table shows default permissions available for each role.</TextBody>
      </VStack>
      <Table bordered variant="ruled">
        <TableHeader>
          <TableRow>
            <TableCell width="55%">
              <TextHeadline spacingVertical={3} as="h4" color="foregroundMuted">
                Role name
              </TextHeadline>
            </TableCell>
            <TableCell title="Owner" width="15%" />
            <TableCell title="Admin" width="15%" />
            <TableCell title="User" width="15%" />
          </TableRow>
        </TableHeader>
        <TableBody>
          {permissionRoleChart.map(([rule, ownerAdminEnabled, adminEnabled, userEnabled]) => (
            <TableRow>
              <TableCell>
                <TextHeadline spacingVertical={3} as="h4" color="foregroundMuted">
                  {rule}
                </TextHeadline>
              </TableCell>
              <TableCell>
                {ownerAdminEnabled ? <DotStatusColor variant="primary" size="xs" /> : <DashIcon />}
              </TableCell>
              <TableCell innerSpacing={innerSpacing}>
                {adminEnabled ? <DotStatusColor variant="primary" size="xs" /> : <DashIcon />}
              </TableCell>
              <TableCell>
                {userEnabled ? <DotStatusColor variant="primary" size="xs" /> : <DashIcon />}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </VStack>
  );
  return (
    <FullscreenModal
      visible
      logo={<CloudLogoWordmark />}
      onRequestClose={handleClose}
      primaryContent={primaryContent}
      title="Organization and permissions"
    />
  );
}
