import { useCallback, useEffect, useState } from 'react';
import { Switch } from '@cbhq/cds-web/controls';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { ThemeProvider } from '@cbhq/cds-web/system';
import { palette } from '@cbhq/cds-web/tokens';
import { Link, TextBody, TextHeadline } from '@cbhq/cds-web/typography';

import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { useFindOrCreateProductDetails } from ':cloud/queries/Projects/useFindOrCreateProductDetails';
import { useUpdateProductDetailsWithMessage } from ':cloud/queries/Projects/useUpdateProductDetails';
import { ProductDetails, ProductTypeMap, Project } from ':cloud/types/ts_types';
import { ERROR_SUCCESS_MESSAGES } from ':cloud/widgets/waasConsumer/common';

type ProductDetailsSectionProps = {
  project: Project;
};

const CUSTOM_PALETTE_NAV_ICON = {
  foreground: 'gray0' as const,
} as const;

const productType = ProductTypeMap.cbpay;

export function SecureInitializationSection({ project }: ProductDetailsSectionProps) {
  const [secureInitialization, setSecureInitialization] = useState<boolean>(false);
  const { isPhone } = useSimpleBreakpoints();
  const { productDetails } = useFindOrCreateProductDetails(project, productType);
  useEffect(() => {
    if (productDetails?.cbpay?.secureInitRequired) {
      setSecureInitialization(productDetails.cbpay.secureInitRequired);
    }
  }, [productDetails?.cbpay?.secureInitRequired]);

  const updateProductDetails = useUpdateProductDetailsWithMessage(ERROR_SUCCESS_MESSAGES, {
    updateMask: 'secureInitRequired',
  });

  const handleValueChange = useCallback(() => {
    const currentValue = !secureInitialization;
    const newProductDetails = {
      cbpay: {
        ...productDetails?.cbpay,
        secureInitRequired: currentValue,
      },
    } as ProductDetails;

    const mutation = updateProductDetails;
    mutation.mutate({ project, productType, productDetails: newProductDetails });

    setSecureInitialization(currentValue);
  }, [productDetails?.cbpay, project, secureInitialization, updateProductDetails]);

  return (
    <VStack bordered borderRadius="rounded" spacing={4}>
      <HStack alignItems="center" gap={2}>
        {!isPhone && (
          <Box
            dangerouslySetBackground={palette.foreground}
            height={24}
            width={24}
            borderRadius="roundedFull"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ThemeProvider palette={CUSTOM_PALETTE_NAV_ICON}>
              <Icon name="protection" size="s" color="primaryForeground" />
            </ThemeProvider>
          </Box>
        )}
        <VStack gap={2} flexGrow={1}>
          <VStack>
            <TextHeadline as="h1">Require secure initialization</TextHeadline>
            <TextBody as="p" color="foregroundMuted">
              If turned on, anyone using your app ID will require creating a session with{' '}
              <Link
                openInNewWindow
                underline
                to="https://docs.cloud.coinbase.com/onramp/docs/api-initializing/"
              >
                Onramp Session Token API
              </Link>
              .
            </TextBody>
          </VStack>
        </VStack>

        <Box spacingEnd={5}>
          <Switch
            onChange={handleValueChange}
            checked={secureInitialization}
            readOnly={!productDetails?.cbpay}
          />
        </Box>
      </HStack>
    </VStack>
  );
}
