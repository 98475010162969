import { useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Divider, VStack } from '@cbhq/cds-web/layout';
import { TabNavigation } from '@cbhq/cds-web/tabs';

import { useGetResourceMetrics } from ':cloud/queries/ResourceQueries/useGetResourceMetrics';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { useGetUserPermissions } from ':cloud/queries/UserQueries/useGetUserPermissions';
import { ICluster, IWorkflow } from ':cloud/types/ts_types';
import {
  isClusterAvailableToDelete,
  isClusterRunning,
  isDeployedByPipelines,
} from ':cloud/utils/clusters';
import { getNodeTerminology } from ':cloud/utils/protocols';
import { AppRoute, componentMappingTab } from ':cloud/utils/routes';
import { ClusterTop } from ':cloud/widgets/clusters/ClusterTop';
import { MetricsRemovalBanner } from ':cloud/widgets/clusters/MetricsRemovalBanner';
import { TopMetrics } from ':cloud/widgets/clusters/TopMetrics';
import * as MetricTabs from ':cloud/widgets/clusterTabs';
import { Layout } from ':cloud/widgets/layout';
import { Terminology } from ':cloud/widgets/sharedcomponents';
import { ReinforcedSecurity } from ':cloud/widgets/sidebar/ReinforcedSecurity';

interface ClusterGenerateDetailsProps {
  cluster: ICluster;
  selectedWorkflow: IWorkflow;
}

export function ClusterGenerateDetails({ cluster, selectedWorkflow }: ClusterGenerateDetailsProps) {
  const { activeOrg } = useGetUser();
  const { permissions } = useGetUserPermissions(activeOrg?.organizationId);
  const { configuration, artifacts } = cluster;
  const { metrics } = useGetResourceMetrics(cluster.type, cluster.name, isClusterRunning(cluster), {
    exactVersion: isDeployedByPipelines(cluster),
  });

  const DEFAULT_TABS = useMemo(() => {
    const tabs = [{ id: 'metrics', label: 'Metrics' }];
    if (permissions.manageResources && isClusterAvailableToDelete(cluster)) {
      tabs.push({ id: 'manage', label: 'Manage' });
    }
    return tabs;
  }, [permissions.manageResources, cluster]);

  const [tabKey, setTabKey] = useState('generateMetrics');
  const TabView = MetricTabs[componentMappingTab(tabKey, 'ClusterGenerateDetails')];

  // TODO: (artivilla) how is cluster.error triggered?
  if (cluster?.error) {
    return <Redirect to={AppRoute.Home} />;
  }

  return (
    <VStack spacingTop={4}>
      <MetricsRemovalBanner />
      <ClusterTop cluster={cluster} region={configuration?.region} />
      <TopMetrics
        cluster={cluster}
        widgetSchema={selectedWorkflow.widgetSchemas.info}
        metricsData={metrics?.data}
      />
      <Layout>
        <Layout.MainContainer fullWidth>
          <TabNavigation value={tabKey} tabs={DEFAULT_TABS} onChange={setTabKey} />
          <Divider />
        </Layout.MainContainer>
        <Layout.MainContainer>
          <TabView
            artifacts={artifacts}
            cluster={cluster}
            configuration={configuration}
            metrics={metrics}
            metricsData={metrics?.data}
            network={selectedWorkflow}
            widgetSchema={selectedWorkflow.widgetSchemas.metrics}
          />
        </Layout.MainContainer>

        <Layout.SidebarContainer>
          <Layout.ContentWrapper>
            <Terminology data={getNodeTerminology(selectedWorkflow.name)} />
          </Layout.ContentWrapper>
          <Layout.ContentWrapper>
            <ReinforcedSecurity />
          </Layout.ContentWrapper>
        </Layout.SidebarContainer>
      </Layout>
    </VStack>
  );
}
