import { useCallback } from 'react';
import { noop } from '@cbhq/cds-utils';
import { BoxProps } from '@cbhq/cds-web/layout';
import { Modal, ModalBody, ModalHeader } from '@cbhq/cds-web/overlays';
import { ConfirmTwoFactor } from '@cbhq/two-factor-web';
import {
  TwoFactorConstraints,
  TwoFactorConstraintsMode,
  TwoFactorError,
  TwoFactorErrorHandler,
  TwoFactorType,
} from '@cbhq/two-factor-web/types';

import config from ':cloud/config';
import { logError } from ':cloud/init/bugsnag/logging';

/**
 * The ConfirmTwoFactor component requires action and projectId props.
 *
 * The projectId prop is defined in our config file.
 *
 * The action prop value should clearly identify what action the user is taking that needs 2FA verification.
 *
 * The standard action format:  platform-CamelCaseAppName-CamelCaseFeature
 *
 * e.g. web-Retail-OnboardingPhoneRegister
 *
 * See more here: https://docs.cbhq.net/frontend/platform/unified-two-factor/verify/getting-started/web
 */
type TwoFactorAction = 'web-CloudBridge-ChangeExchangePlan';

const UNIFIED_TWO_FACTOR_CONSTRAINTS: TwoFactorConstraints = {
  mode: TwoFactorConstraintsMode.Allow,
  types: [
    TwoFactorType.SMS,
    TwoFactorType.TOTP,
    TwoFactorType.U2F,
    TwoFactorType.Passkey,
    TwoFactorType.RecoveryCode,
    TwoFactorType.IDV,
    TwoFactorType.No2FA,
  ],
};

const UNIFIED_TWO_FACTOR_BOX_PROPS: BoxProps = {
  spacingHorizontal: 4,
  spacingVertical: 4,
  alignItems: 'center',
  maxWidth: 'none',
};

type TwoFactorModalProps = {
  visible: boolean;
  onRequestClose: () => void;
  onSuccess: (proofToken: string) => void;
  onFailure: (error: TwoFactorError) => void;
  action: TwoFactorAction;
  title?: string;
};

export function TwoFactorModal({
  visible,
  onRequestClose,
  onSuccess,
  onFailure,
  action,
  title,
}: TwoFactorModalProps) {
  const handleSuccess = useCallback(
    (token: string) => {
      onSuccess(token);
      onRequestClose();
    },
    [onRequestClose, onSuccess],
  );

  const handleError = useCallback(
    (error: TwoFactorError, next?: TwoFactorErrorHandler) => {
      onFailure(error);
      logError(
        error.message,
        {
          context: 'two_factor_modal',
        },
        { name: `${action} two factor error`, error: error.message },
      );
      void next?.(error);
    },
    [onFailure, action],
  );

  return (
    <Modal onRequestClose={onRequestClose} visible={visible}>
      {title && <ModalHeader title={title} />}
      <ModalBody>
        <ConfirmTwoFactor
          onToast={noop}
          projectId={config.confirmTwoFactorProjectId}
          action={action}
          onCancel={onRequestClose}
          onSuccess={handleSuccess}
          onError={handleError}
          constraints={UNIFIED_TWO_FACTOR_CONSTRAINTS}
          rootBoxProps={UNIFIED_TWO_FACTOR_BOX_PROPS}
        />
      </ModalBody>
    </Modal>
  );
}
