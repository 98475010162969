import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import _extend from 'lodash/extend';
import _keyBy from 'lodash/keyBy';
import { FallbackColor } from '@cbhq/cds-common';

import { ORG_NAME_MAX_LENGTH } from ':cloud/constants';
import { logError } from ':cloud/init/bugsnag/logging';
import { generateOrganizationQuery } from ':cloud/queries/OrganizationQueries/useGetAllOrganizations';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { COMSErrorResponse } from ':cloud/state/Api';
import { OrganizationDetails } from ':cloud/types/service_proto';
import {
  OrganizationActiveUser,
  OrganizationInvitedUser,
  OrganizationUser,
} from ':cloud/types/ts_types';
import { truncateMiddle } from ':cloud/utils/common';
import { NULL_COINBASE_ID } from ':cloud/widgets/access/constants';

const COLOR_SCHEMES: FallbackColor[] = ['green', 'teal', 'pink', 'blue', 'purple'];

export type FormattedOrganizationResponse = {
  organizationUsers: OrganizationUser[];
  name?: string;
  truncatedName: string;
  activeUsers: OrganizationActiveUser[];
  activeUserMap: Record<string, OrganizationActiveUser>;
  invitedUsers: OrganizationInvitedUser[];
};

export function generateOrganizationResponse(
  data: OrganizationDetails,
): FormattedOrganizationResponse {
  /* extend appends status props to our existing (active) and pending invites (invited) to simplify filtering members in our organization dashboards */
  const activeUsers: OrganizationActiveUser[] =
    data?.users
      ?.sort((a, b) => (a.name || a.email).localeCompare(b.name || b.email))
      ?.filter((user) => user.id !== NULL_COINBASE_ID)
      ?.map(
        (value, index) =>
          _extend(
            {
              status: 'active',
              fullNameOrEmail:
                value.name && value.familyName ? `${value.name} ${value.familyName}` : value.email,
              avatarColor: COLOR_SCHEMES[(index + 1) % COLOR_SCHEMES.length],
            },
            value,
          ) as OrganizationActiveUser,
      ) || [];

  const invitedUsers: OrganizationInvitedUser[] =
    data?.invites
      ?.map((value) => _extend({ status: 'invited' }, value) as OrganizationInvitedUser)
      ?.sort((a, b) => a.email.localeCompare(b.email)) || [];
  const combinedUsers: OrganizationUser[] = [...activeUsers, ...invitedUsers];
  const truncatedName = truncateMiddle(data?.name || '', ORG_NAME_MAX_LENGTH, 0, '...');

  return {
    organizationUsers: combinedUsers,
    activeUsers,
    activeUserMap: { ..._keyBy(activeUsers, 'id'), ..._keyBy(activeUsers, 'coinbaseUserId') },
    invitedUsers,
    name: data?.name,
    truncatedName,
  };
}

export function useGetOrganization(orgId = ''): FormattedOrganizationResponse & {
  isLoading: boolean;
  orgHasMultipleUsers: boolean;
} {
  const { user } = useGetUser();
  const {
    isLoading,
    data,
    error: queryError,
  } = useQuery(
    generateOrganizationQuery({
      userId: user.userId,
    }),
  );
  const currentOrgResponse = data?.find((item) => item.organizationId === orgId);

  if (!currentOrgResponse) {
    const error = new Error('Current organization is undefined');
    error.cause = queryError;
    logError(
      error,
      { context: 'use_get_organization' },
      {
        userId: user.userId,
        userOrgIds: data?.map((org) => org.organizationId),
        currentOrgId: orgId,
        queryError: (queryError as AxiosError<COMSErrorResponse>).response?.data?.message,
      },
    );
    throw error;
  }

  const organizationResponse = generateOrganizationResponse(currentOrgResponse.organizationDetails);

  const orgHasMultipleUsers = organizationResponse.activeUsers.length > 1;

  return {
    ...organizationResponse,
    orgHasMultipleUsers,
    isLoading,
  };
}
