import { useMemo } from 'react';
import { CellSpacing } from '@cbhq/cds-common';
import { Cell, overflowClassName } from '@cbhq/cds-web/cells/Cell';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { palette } from '@cbhq/cds-web/tokens';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

import config from ':cloud/config';
import { useAppState } from ':cloud/contexts/AppStateContext';
import { useFindOrCreateToken } from ':cloud/hooks/useFindOrCreateToken';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';

import { baseConfig } from '../product/configurations/baseConfig';
import { ClipboardIcon } from '../sharedcomponents';

type RPCEndpointSectionProps = {
  selectedNetwork: string;
  title: string;
};

const spacing: CellSpacing = { spacing: 0, spacingHorizontal: 2 };

export function RPCEndpointSection({ selectedNetwork, title }: RPCEndpointSectionProps) {
  const { activeOrg } = useGetUser();
  const { selectedProject } = useAppState();
  const { token } = useFindOrCreateToken(
    activeOrg?.organizationId,
    selectedProject?.id,
    selectedProject?.type,
    selectedProject?.isTestnet,
  );

  const rpcEndpointUrl = useMemo(() => {
    return `${config.developerAPIURL}/rpc/v1/${
      selectedNetwork === baseConfig?.networkOptions?.[1].label
        ? baseConfig?.networkOptions?.[1].value
        : baseConfig?.networkOptions?.[0].value
    }/${token?.id}`;
  }, [selectedNetwork, token?.id]);

  return (
    <VStack bordered borderRadius="rounded" spacing={4} gap={3}>
      <HStack justifyContent="space-between">
        <HStack alignItems="center" gap={2}>
          <Box
            dangerouslySetBackground={palette.foreground}
            height={24}
            width={24}
            borderRadius="roundedFull"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Icon name="chainLink" size="s" color="primaryForeground" />
          </Box>
          <VStack>
            <TextHeadline as="h1">{title}</TextHeadline>
          </VStack>
        </HStack>
      </HStack>
      <HStack
        width="100%"
        gap={6}
        alignItems="center"
        background="backgroundAlternate"
        borderRadius="rounded"
      >
        <Cell
          priority="end"
          innerSpacing={spacing}
          outerSpacing={spacing}
          detail={
            <Box justifyContent="flex-end">
              <ClipboardIcon text={rpcEndpointUrl} iconSize="s" />
            </Box>
          }
        >
          <Box borderRadius="roundedSmall" display="flex" spacing={2} flexGrow={1}>
            <TextBody as="p" color="foregroundMuted" overflow="wrap" className={overflowClassName}>
              {rpcEndpointUrl}
            </TextBody>
          </Box>
        </Cell>
      </HStack>
    </VStack>
  );
}
