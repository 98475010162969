import { NavigationIcon, NavigationIconProps } from '@cbhq/cds-web/icons';
import { HStack } from '@cbhq/cds-web/layout';
import { Pressable } from '@cbhq/cds-web/system';
import { TextHeadline } from '@cbhq/cds-web/typography';

type DrawerIconProps = {
  title?: string;
  icon: NavigationIconProps['name'];
  active: boolean;
  onPress?: () => void;
};

/*
 * A SidebarItem does not provide a way to disable the gray background spanning both the icon and page title.
 * An IconButton doesn't have the `active` styling or the icon we need
 * This component adds a lightgray circular background to the NavigationIcon so it looks like an IconButton
 */
export function DrawerItem({ title, icon, active, onPress }: DrawerIconProps) {
  return (
    <Pressable onPress={onPress} background="transparent" as="button">
      <HStack key={title} spacingVertical={2}>
        <HStack gap={2} alignItems="center" justifyContent="flex-start">
          <HStack
            borderRadius="roundedFull"
            height={40}
            width={40}
            alignItems="center"
            justifyContent="center"
          >
            <NavigationIcon name={icon} key={`sidebar-item--${title}`} active={active} />
          </HStack>
          {title && <TextHeadline as="span">{title}</TextHeadline>}
        </HStack>
      </HStack>
    </Pressable>
  );
}
