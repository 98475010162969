import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { CreateWebhookResponse } from ':cloud/types/service_proto';
import { CoinbaseWebhook } from ':cloud/types/ts_types';

const errorMsg = 'An error occurred creating your Webhook. Please retry';

type NotificationHeaders = {
  key: string;
  value: string;
};

type EventFilter = {
  contractAddress?: string;
  fromAddress?: string;
  toAddress?: string;
};

async function createWebhook(
  orgId: string,
  projectId: string,
  network: string,
  eventType: string,
  notificationHeaders: NotificationHeaders[],
  notificationUri: string,
  eventFilters: EventFilter[],
): Promise<CreateWebhookResponse | undefined> {
  const path = `webhook/v1/organizations/${orgId}/projects/${projectId}/webhooks`;
  const body = {
    parent: `organizations/${orgId}/projects/${projectId}`,
    webhook: {
      network,
      eventType,
      eventFilters: eventFilters.map((filter) => ({
        contractAddress: filter.contractAddress,
        fromAddress: filter.fromAddress,
        toAddress: filter.toAddress,
      })),
      notificationUri,
      notificationHeaders,
    },
  };

  const response = await APIClientBase.post<CreateWebhookResponse>(path, body);

  return response.data;
}

type CreateWebhookMutate = {
  orgId: string;
  projectId: string;
  network: string;
  eventType: string;
  notificationHeaders: NotificationHeaders[];
  notificationUri: string;
  eventFilters: EventFilter[];
};

export function useCreateWebhook(
  orgId = '',
  onSuccess?: (webhook: CoinbaseWebhook) => void,
  onCloseModal?: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      projectId,
      network,
      eventType,
      notificationUri,
      notificationHeaders,
      eventFilters,
    }: CreateWebhookMutate) =>
      createWebhook(
        orgId,
        projectId,
        network,
        eventType,
        notificationHeaders,
        notificationUri,
        eventFilters,
      ),
    onSuccess: async (newlyCreatedWebhook) => {
      if (!newlyCreatedWebhook) return;

      queryClient.setQueryData([QueryKey.webhooks, newlyCreatedWebhook.uuid], newlyCreatedWebhook);
      await queryClient.invalidateQueries([QueryKey.webhooks]);
      onSuccess?.(newlyCreatedWebhook);
      onCloseModal?.();
    },
    meta: {
      errorMsg,
    },
  });
}
