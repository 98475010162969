import { css } from '@linaria/core';
import { fromUnixTime, sub } from 'date-fns';
import _last from 'lodash/last';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { VStack } from '@cbhq/cds-web/layout';
import { deviceBreakpoints } from '@cbhq/cds-web/layout/breakpoints';
import { palette, spacing } from '@cbhq/cds-web/tokens';

import { formatDate } from ':cloud/utils/date';
import { TextLabelGray } from ':cloud/widgets/sharedcomponents';

import {
  CustomTooltip,
  CustomXAxisTick,
  getXAxisTickLabels,
  IChartPointType,
  tooltipProps,
  xAxisProps,
  yAxisProps,
} from './ChartUtils';

const lineChartMargin = { top: 20, right: 30, left: 0, bottom: 0 };
const mapPayloadKeys = { uptime: { name: 'avg uptime', unit: '%' } };

const nodeUptimeStyles = css`
  margin-left: -${spacing[3]};
  margin: auto;
`;

// Handling MQs this way because CDS doesn't use margins and I want to maintain
// parity with the chart that already exists.
// Don't do this otherwise! Use responsiveConfig or useSimpleBreakpoints instead.
const chartLabelStyles = css`
  margin-left: 10%;
  @media (min-width: ${deviceBreakpoints.phone}px}) {
    margin-left: 0;
  }
`;

interface NodeUptimeChartProps {
  data: IChartPointType[];
}

interface StatusDotProps {
  cx: number;
  cy: number;
  payload: IChartPointType;
}

const yAxisDomain = [0, 100];
// createUptimeChart returned an uptime chart with minimum totalCount of graphs points
// timestamp are 12 hr intervals
function createUptimeChart(data: IChartPointType[], totalCount = 5): IChartPointType[] {
  const currentDate = new Date();
  if (data.length > 0 && data.length < totalCount) {
    const { unixTimestamp: lastTimestamp } = _last(data) as any;
    const newChart: IChartPointType[] = [...new Array(totalCount - data.length).fill(null)];
    const placeholderChart: IChartPointType[] = newChart.map((_, idx) => ({
      idx,
      uptime: '100',
      placeholder: true,
      unixTimestamp: sub(fromUnixTime(lastTimestamp), { hours: (totalCount - idx) * 12 }), // debug timestamp
      timestamp: formatDate(
        sub(fromUnixTime(lastTimestamp), { hours: (totalCount - idx) * 12 }),
        'MMM d h:mma',
      ),
    }));
    return placeholderChart.concat(data);
  }
  if (data.length >= totalCount) {
    return data;
  }
  const newChart: IChartPointType[] = [...new Array(totalCount).fill(null)];
  const placeholderChart = newChart.map((_, idx) => ({
    idx,
    uptime: '100',
    placeholder: true,
    unixTimestamp: sub(currentDate, { hours: (totalCount - idx) * 12 }), // debug timestamp
    timestamp: formatDate(sub(currentDate, { hours: (totalCount - idx) * 12 }), 'MMM d h:mma'),
  }));
  return placeholderChart;
}

/** used in MetricsTab */
function NodeUptimeChart({ data = [] }: NodeUptimeChartProps) {
  const uptimePoints = createUptimeChart(data);
  const XAxisTicks = getXAxisTickLabels(uptimePoints);

  function renderStatusDot({ cx, cy, payload }: StatusDotProps) {
    return (
      <svg
        key={String(payload.timestamp)}
        x={cx - 4}
        y={cy - 4}
        width={8}
        height={8}
        viewBox="0 0 8 8"
      >
        <circle cx="4" cy="4" r="2" fill={payload.placeholder ? palette.line : palette.primary} />
      </svg>
    );
  }

  function renderActiveDot({ cx, cy, payload }: StatusDotProps) {
    const colorFill = payload.placeholder ? palette.line : palette.primary;
    return (
      <svg x={cx - 10} y={cy - 10} width="23" height="23" viewBox="0 0 23 23">
        <circle cx="11.5" cy="11.5" r="11.5" fill={colorFill} fillOpacity="0.4" />
        <circle cx="11.5" cy="11.5" r="4.5 " fill={colorFill} />
      </svg>
    );
  }

  const coloredStroke = data.length >= 5;

  const renderCustomTick = (props) => <CustomXAxisTick {...props} ticks={XAxisTicks} />;

  return (
    <VStack height={135} width="100%">
      <TextLabelGray as="div" align="center" className={chartLabelStyles}>
        48 hr uptime
      </TextLabelGray>
      <ResponsiveContainer className={nodeUptimeStyles}>
        <LineChart margin={lineChartMargin} data={uptimePoints}>
          <XAxis {...xAxisProps} ticks={XAxisTicks} tick={renderCustomTick} />
          <YAxis {...yAxisProps} tickCount={2} dataKey="uptime" domain={yAxisDomain} unit="%" />
          <Tooltip
            {...tooltipProps}
            content={<CustomTooltip active mapPayloadKeys={mapPayloadKeys} />}
          />
          <Line
            dataKey="uptime"
            dot={(props) => renderStatusDot(props)}
            activeDot={(props) => renderActiveDot(props as StatusDotProps)}
            stroke={coloredStroke ? palette.primary : palette.line}
            strokeWidth={1}
            type="monotone"
            strokeDasharray={coloredStroke ? undefined : '5 5'}
          />
        </LineChart>
      </ResponsiveContainer>
    </VStack>
  );
}

export default NodeUptimeChart;
