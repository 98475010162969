import _get from 'lodash/get';
import _has from 'lodash/has';
import _map from 'lodash/map';
import { VStack } from '@cbhq/cds-web/layout';

import { ICluster, IConfiguration, IMetricData, IWidgetSchemaRoot } from ':cloud/types/ts_types';
import GenerateMetrics from ':cloud/widgets/clusterTabs/GenerateMetrics';
import { Layout } from ':cloud/widgets/layout';
import { DisplayUnit } from ':cloud/widgets/sharedcomponents';

type GroupMetricsProps = {
  root: ICluster | IConfiguration;
  category: string;
};

function GroupMetrics({ root, category }: GroupMetricsProps): JSX.Element | null {
  if (!_has(root, category)) {
    return null;
  }

  const replaceLabels = (label: string): string => {
    const mappingLabels: Record<string, string> = {
      'artifacts.endpoint': 'Public Address',
      'artifacts.eth': 'Eth Address',
      mode: 'Node Type',
    };
    return mappingLabels[label] || label.replace(/_/g, ' ');
  };
  const excludeClipboard = ['mode'];

  const value = _get(root, category);
  /* Single Case Metric Values */
  if (typeof value === 'string') {
    return (
      <DisplayUnit
        isCapitalized={false}
        key={category}
        label={replaceLabels(category)}
        ignoreAutoTruncate
        value={value}
        hasClipboard={!excludeClipboard.includes(category)}
      />
    );
  }

  /* Collection Categories */
  if (value && Object.keys(value as Record<number, string>).length > 0) {
    return (
      <>
        {_map(value, (key: number, val: string) => (
          <DisplayUnit
            isCapitalized={false}
            key={key}
            label={replaceLabels(val)}
            ignoreAutoTruncate
            value={val}
            hasClipboard={!excludeClipboard.includes(category)}
          />
        ))}
      </>
    );
  }

  return null;
}

interface TopMetricsProps {
  cluster: ICluster;
  widgetSchema?: IWidgetSchemaRoot;
  metricsData?: IMetricData;
}

/** most protocols will show nothing in TopMetrics */
const ARTIFACT_KEYS = [
  'artifacts.detailsCard',
  'artifacts.eth',
  'artifacts.endpoint',
  /** DEBUG: most protocols expose an artifacts.addressess you can disable to test */
  // 'artifacts.addresses',
];
// const CONFIG_KEYS = ['mode'];
/* TopMetrics dynamically renders the widgetSchema.metrics.info property that is set by protocol workflows */
export function TopMetrics({ cluster, widgetSchema, metricsData }: TopMetricsProps) {
  if (widgetSchema && metricsData) {
    return (
      // @ts-expect-error exports issue
      <GenerateMetrics
        artifacts={cluster?.artifacts}
        configuration={cluster?.configuration}
        metricsData={metricsData}
        widgetSchema={widgetSchema}
      />
    );
  }

  /** only show articfacts if they match the following keys */
  const showArtifact = ARTIFACT_KEYS.map((key) => _has(cluster, key)).some((i) => i);

  if (!showArtifact) {
    return null;
  }

  return (
    <Layout.MainContainer spacingVertical={0}>
      <VStack>
        {_map(ARTIFACT_KEYS, (category) => (
          <GroupMetrics key={category} category={category} root={cluster} />
        ))}
        {/* {cluster?.configuration
          ? _map(CONFIG_KEYS, (category) => (
              <GroupMetrics key={category} category={category} root={cluster?.configuration} />
            ))
          : null} */}
      </VStack>
    </Layout.MainContainer>
  );
}
