import { memo, useMemo } from 'react';
import { Button, IconButton } from '@cbhq/cds-web/buttons';
import { SelectOption } from '@cbhq/cds-web/controls';
import { Dropdown } from '@cbhq/cds-web/dropdown';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TableBody } from '@cbhq/cds-web/tables';
import { TextBody, TextTitle3 } from '@cbhq/cds-web/typography';

import { PaymentMethod } from ':cloud/types/service_proto';
import { PaymentMethodDisplayer } from ':cloud/widgets/billing/PaymentMethodDisplayer';
import { FULL_PAGE_MAX_WIDTH } from ':cloud/widgets/product/constants';
import { CloudErrorBoundary, EmptyTable } from ':cloud/widgets/sharedcomponents';
import { ErrorView } from ':cloud/widgets/sharedcomponents/ErrorView';

type BillingPaymentSectionProps = {
  onReplacePaymentMethod: () => void;
  onDeletePaymentMethod: () => void;
  paymentMethod?: PaymentMethod;
  isLoadingPaymentMethod?: boolean;
};

const MAX_TABLE_WIDTH = 360;

function BillingPaymentSection({
  paymentMethod,
  onReplacePaymentMethod,
  onDeletePaymentMethod,
  isLoadingPaymentMethod = false,
}: BillingPaymentSectionProps) {
  const options = useMemo(() => {
    const dropdownOptions: { text: string; action: () => void }[] = [];
    if (paymentMethod) {
      dropdownOptions.push({ text: 'Replace payment method', action: onReplacePaymentMethod });
      dropdownOptions.push({ text: 'Delete payment method', action: onDeletePaymentMethod });
    }
    return dropdownOptions;
  }, [paymentMethod, onReplacePaymentMethod, onDeletePaymentMethod]);

  const dropdownContent = useMemo(
    () =>
      options.map(({ text, action }) => (
        <SelectOption value={text} title={text} onPress={action} key={text} />
      )),
    [options],
  );

  return (
    <CloudErrorBoundary
      name="BillingPaymentSectionContent"
      fallback={
        <TableBody>
          <EmptyTable>
            <ErrorView />
          </EmptyTable>
        </TableBody>
      }
    >
      <VStack borderedBottom>
        <VStack maxWidth={FULL_PAGE_MAX_WIDTH} spacing={7}>
          <TextTitle3 as="h3">Payment method</TextTitle3>
          <TextBody
            as="p"
            spacingBottom={paymentMethod ? 4 : 0}
            spacingTop={1}
            color="foregroundMuted"
          >
            View and manage your preferred payment methods.
          </TextBody>
          {paymentMethod && (
            <HStack maxWidth={MAX_TABLE_WIDTH} alignItems="center" gap={2}>
              <PaymentMethodDisplayer
                paymentMethod={paymentMethod}
                transparent
                expanded
                spacingHorizontal={0}
              />
              <Dropdown content={dropdownContent}>
                <IconButton transparent name="more" />
              </Dropdown>
            </HStack>
          )}
          {!paymentMethod && !isLoadingPaymentMethod && (
            <Box spacingTop={5}>
              <Button onPress={onReplacePaymentMethod}>Add payment method</Button>
            </Box>
          )}
        </VStack>
      </VStack>
    </CloudErrorBoundary>
  );
}

export default memo(BillingPaymentSection);
