import { useCallback, useMemo, useState } from 'react';
import { Button } from '@cbhq/cds-web/buttons';
import { Checkbox, InputIcon, TextInput } from '@cbhq/cds-web/controls';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Box, Grid, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextCaption, TextHeadline, TextLabel2 } from '@cbhq/cds-web/typography';

import { EventName, logClick } from ':cloud/init/clientAnalytics/logging';
import {
  AttestationRequirement,
  GasPolicy,
  WhitelistedAddressAndMethod,
} from ':cloud/types/ts_types';
import {
  formatTxnAmount,
  getContractMethodsFromString,
  hasUpdatedWhitelist,
  isValidContractAddress,
  isValidGasAmount,
  isValidMethodInput,
  isValidTxnAmount,
} from ':cloud/utils/paymaster';

type GasPolicyFormProps = {
  onCancel?: () => void;
  policy: GasPolicy;
  onPublishGasPolicy: (policy: GasPolicy) => void;
};

const maxTxnErrorMessage = 'Per user maximum cannot exceed global maximum.';

export function GasPolicyForm({ onCancel, policy, onPublishGasPolicy }: GasPolicyFormProps) {
  const [isGlobalMaxEnabled, setIsGlobalMaxEnabled] = useState(policy.globalMaxEnabled || false);
  const [maxGlobalGas, setMaxGlobalGas] = useState(JSON.stringify(policy.maxGlobalGas) || '0');
  const [maxGlobalTxn, setMaxGlobalTxn] = useState(policy.maxGlobalTxn || '0');
  const [isPerUserMaxEnabled, setIsPerUserMaxEnabled] = useState(policy.perAddrMaxEnabled || false);
  const [maxGasPerAddr, setMaxGasPerAddr] = useState(JSON.stringify(policy.maxGasPerAddr) || '0');
  const [maxTxnPerAddr, setMaxTxnPerAddr] = useState(policy.maxTxnPerAddr || '0');

  const [maxTxnError, setMaxTxnError] = useState('');
  const [maxGasError, setMaxGasError] = useState('');

  const [smartContractAddress, setSmartContractAddress] = useState('');
  const [smartContractMethod, setSmartContractMethod] = useState('');
  const [addressFormatError, setAddressFormatError] = useState('');
  const [methodFormatError, setMethodFormatError] = useState('');

  const initialWhitelistedAddressesAndMethods = useMemo(() => {
    return policy?.contractAllowlist || [];
  }, [policy?.contractAllowlist]);

  const [whitelistedAddressAndMethods, setWhitelistedAddressAndMethods] = useState<
    WhitelistedAddressAndMethod[]
  >(initialWhitelistedAddressesAndMethods);

  // const initialAttestationRequirements = useMemo(() => {
  //   return policy?.attestationRequirements?.map((requirement) => requirement.schemaId);
  // }, [policy?.attestationRequirements]);

  // const [isCoinbaseVerifiedUserChecked, { toggle: toggleCoinbaseVerifiedUser }] = useToggler(
  //   initialAttestationRequirements?.includes('cb_verified'),
  // );
  // TODO: comment back in when cb1 attestation is live
  // const [isCoinbaseCB1Checked, { toggle: toggleCoinbaseCB1User }] = useToggler(
  //   initialAttestationRequirements?.includes('cb_1'),
  // );

  const numChangesUnsaved = useMemo(() => {
    let count = 0;
    if (hasUpdatedWhitelist(initialWhitelistedAddressesAndMethods, whitelistedAddressAndMethods)) {
      count += 1;
    }
    if (isGlobalMaxEnabled !== policy.globalMaxEnabled) {
      count += 1;
    }
    if (maxGlobalGas !== JSON.stringify(policy.maxGlobalGas)) {
      count += 1;
    }
    if (maxGlobalTxn !== policy.maxGlobalTxn) {
      count += 1;
    }
    if (isPerUserMaxEnabled !== policy.perAddrMaxEnabled) {
      count += 1;
    }
    if (maxGasPerAddr !== JSON.stringify(policy.maxGasPerAddr)) {
      count += 1;
    }
    if (maxTxnPerAddr !== policy.maxTxnPerAddr) {
      count += 1;
    }
    return count;
  }, [
    initialWhitelistedAddressesAndMethods,
    isGlobalMaxEnabled,
    isPerUserMaxEnabled,
    maxGasPerAddr,
    maxGlobalGas,
    maxGlobalTxn,
    maxTxnPerAddr,
    policy.globalMaxEnabled,
    policy.maxGasPerAddr,
    policy.maxGlobalGas,
    policy.maxGlobalTxn,
    policy.maxTxnPerAddr,
    policy.perAddrMaxEnabled,
    whitelistedAddressAndMethods,
  ]);

  const { isPhone } = useBreakpoints();
  const responsiveTemplateColumns = useMemo(() => {
    return isPhone ? '1fr' : '1fr 1fr';
  }, [isPhone]);

  const handleToggleGlobalMax = useCallback(() => {
    if (parseFloat(maxGasPerAddr) > parseFloat(maxGlobalGas) && !isGlobalMaxEnabled) {
      setMaxGasError(maxTxnErrorMessage);
    }
    if (parseInt(maxTxnPerAddr) > parseInt(maxGlobalTxn) && !isGlobalMaxEnabled) {
      setMaxTxnError(maxTxnErrorMessage);
    }
    if (isGlobalMaxEnabled) {
      setMaxGasError('');
      setMaxTxnError('');
    }
    setIsGlobalMaxEnabled((prev) => !prev);
  }, [isGlobalMaxEnabled, maxGasPerAddr, maxGlobalGas, maxGlobalTxn, maxTxnPerAddr]);

  const handleTogglePerUserMax = useCallback(() => {
    if (parseFloat(maxGasPerAddr) > parseFloat(maxGlobalGas) && !isPerUserMaxEnabled) {
      setMaxGasError(maxTxnErrorMessage);
    }
    if (parseInt(maxTxnPerAddr) > parseInt(maxGlobalTxn) && !isPerUserMaxEnabled) {
      setMaxTxnError(maxTxnErrorMessage);
    }
    if (isPerUserMaxEnabled) {
      setMaxGasError('');
      setMaxTxnError('');
    }
    setIsPerUserMaxEnabled((prev) => !prev);
  }, [isPerUserMaxEnabled, maxGasPerAddr, maxGlobalGas, maxGlobalTxn, maxTxnPerAddr]);

  const handleMaxGlobalGasChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isValidGasAmount(event.target.value)) {
        setMaxGlobalGas(event.target.value);
        setMaxGasError('');
      }
      if (parseFloat(event.target.value) < parseFloat(maxGasPerAddr) && isPerUserMaxEnabled) {
        setMaxGasError(maxTxnErrorMessage);
      }
    },
    [isPerUserMaxEnabled, maxGasPerAddr],
  );

  const handleMaxGlobalTxnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isValidTxnAmount(event.target.value)) {
        setMaxGlobalTxn(event.target.value);
        setMaxTxnError('');
      }
      if (parseInt(event.target.value) < parseInt(maxTxnPerAddr) && isPerUserMaxEnabled) {
        setMaxTxnError(maxTxnErrorMessage);
      }
    },
    [isPerUserMaxEnabled, maxTxnPerAddr],
  );

  const handleMaxGasPerAddressChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isValidGasAmount(event.target.value)) {
        setMaxGasPerAddr(event.target.value);
        setMaxGasError('');
      }
      if (parseFloat(event.target.value) > parseFloat(maxGlobalGas) && isGlobalMaxEnabled) {
        setMaxGasError(maxTxnErrorMessage);
      }
    },
    [isGlobalMaxEnabled, maxGlobalGas],
  );

  const handleMaxTxnPerAddressChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isValidTxnAmount(event.target.value)) {
        setMaxTxnPerAddr(event.target.value);
        setMaxTxnError('');
      }
      if (parseInt(event.target.value) > parseInt(maxGlobalTxn) && isGlobalMaxEnabled) {
        setMaxTxnError(maxTxnErrorMessage);
      }
    },
    [isGlobalMaxEnabled, maxGlobalTxn],
  );

  const handleSmartContractAddressChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAddressFormatError('');
      setSmartContractAddress(event.target.value);
    },
    [],
  );

  const handleSmartContractMethodChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setMethodFormatError('');
      setSmartContractMethod(event.target.value);
    },
    [],
  );

  const handleSmartContractMethodBlur = useCallback(() => {
    if (!isValidMethodInput(smartContractMethod)) {
      setMethodFormatError(
        'Must be comma-separated list of function signatures or function selectors',
      );
    }
  }, [smartContractMethod]);

  const handleSmartContractAddressBlur = useCallback(() => {
    if (!isValidContractAddress(smartContractAddress)) {
      setAddressFormatError('Invalid address');
    }
  }, [smartContractAddress]);

  const handleAddContractPress = useCallback(() => {
    // If the address or method is invalid, don't add it to the list
    if (!isValidContractAddress(smartContractAddress) || !isValidMethodInput(smartContractMethod)) {
      return;
    }
    logClick(EventName.paymaster_add_contract_allowlist_click);
    const whitelistItem: WhitelistedAddressAndMethod = {
      address: smartContractAddress,
      methods: getContractMethodsFromString(smartContractMethod),
    };
    setWhitelistedAddressAndMethods(whitelistedAddressAndMethods.concat(whitelistItem));
    setSmartContractAddress('');
    setSmartContractMethod('');
  }, [smartContractAddress, smartContractMethod, whitelistedAddressAndMethods]);

  const handleRemoveContractPress = useCallback(
    (key: string) => {
      const filteredContracts = whitelistedAddressAndMethods.filter(
        (item) => `${item.address}-${item.methods}` !== key,
      );
      setWhitelistedAddressAndMethods(filteredContracts);
    },
    [whitelistedAddressAndMethods],
  );

  const handlePublishPolicyPress = useCallback(() => {
    const attestationRequirements: AttestationRequirement[] = [];
    // if (isCoinbaseVerifiedUserChecked) {
    //   attestationRequirements.push({ schemaId: 'cb_verified' });
    // }
    // TODO: comment back in when cb1 attestation is live
    // if (isCoinbaseCB1Checked) {
    //   attestationRequirements.push({ schemaId: 'cb_1' });
    // }

    const newGasPolicy = {
      ...policy,
      globalMaxEnabled: isGlobalMaxEnabled,
      maxGlobalGas: parseFloat(maxGlobalGas),
      maxGlobalTxn: formatTxnAmount(maxGlobalTxn),
      perAddrMaxEnabled: isPerUserMaxEnabled,
      maxGasPerAddr: parseFloat(maxGasPerAddr),
      maxTxnPerAddr: formatTxnAmount(maxTxnPerAddr),
      contractAllowlist: whitelistedAddressAndMethods,
      attestationRequirements,
    };
    onPublishGasPolicy(newGasPolicy);
    setSmartContractAddress('');
    setSmartContractMethod('');
    setAddressFormatError('');
    setMethodFormatError('');
  }, [
    // isCoinbaseVerifiedUserChecked,
    policy,
    isGlobalMaxEnabled,
    maxGlobalGas,
    maxGlobalTxn,
    isPerUserMaxEnabled,
    maxGasPerAddr,
    maxTxnPerAddr,
    whitelistedAddressAndMethods,
    onPublishGasPolicy,
  ]);

  const whitelistedContracts = useMemo(() => {
    return whitelistedAddressAndMethods.map((whitelistItem) => {
      return (
        <HStack alignItems="flex-end" gap={2} spacingTop={2}>
          <Box flexGrow={1}>
            <Grid gap={1} templateColumns={responsiveTemplateColumns} width="100%">
              <TextInput
                type="text"
                label="Contract Address"
                value={whitelistItem.address}
                disabled
              />
              <TextInput type="text" label="Function(s)" value={whitelistItem.methods} disabled />
            </Grid>
          </Box>
          <Button
            variant="secondary"
            onPress={() =>
              handleRemoveContractPress(`${whitelistItem.address}-${whitelistItem.methods}`)
            }
          >
            Remove
          </Button>
        </HStack>
      );
    });
  }, [handleRemoveContractPress, responsiveTemplateColumns, whitelistedAddressAndMethods]);

  return (
    <VStack spacingTop={4} gap={6}>
      <VStack>
        {(maxTxnError || maxGasError) && (
          <TextBody as="p" spacingBottom={5} color="negative">
            {maxTxnError || maxGasError}
          </TextBody>
        )}

        <HStack justifyContent="space-between" alignItems="center">
          <VStack>
            <TextHeadline as="p">Global maximums</TextHeadline>
            <TextBody as="p" color="foregroundMuted">
              Limit the amount of ETH you are willing to sponsor globally
            </TextBody>
          </VStack>
          <Checkbox checked={isGlobalMaxEnabled} onChange={handleToggleGlobalMax} />
        </HStack>
        {isGlobalMaxEnabled && (
          <Grid spacingTop={3} gap={1} templateColumns={responsiveTemplateColumns}>
            <TextInput
              type="text"
              start={<InputIcon name="ethereum" />}
              label="Maximum ETH"
              placeholder="0.00"
              helperText="Max amount of ETH this policy will sponsor globally"
              value={maxGlobalGas}
              onChange={handleMaxGlobalGasChange}
            />
            <TextInput
              type="text"
              label="Maximum Number of User Operations"
              placeholder="0"
              helperText="Max number of user operations this policy will sponsor globally"
              value={maxGlobalTxn}
              onChange={handleMaxGlobalTxnChange}
            />
          </Grid>
        )}
      </VStack>

      <VStack>
        <HStack justifyContent="space-between" alignItems="center">
          <VStack>
            <TextHeadline as="p">Per user maximums</TextHeadline>
            <TextBody as="p" color="foregroundMuted">
              Limit the amount of ETH you are willing to sponsor per user
            </TextBody>
          </VStack>
          <Checkbox checked={isPerUserMaxEnabled} onChange={handleTogglePerUserMax} />
        </HStack>
        {isPerUserMaxEnabled && (
          <Grid spacingTop={3} gap={1} templateColumns={responsiveTemplateColumns}>
            <TextInput
              type="text"
              start={<InputIcon name="ethereum" />}
              label="Maximum ETH"
              placeholder="0.00"
              helperText="Max amount of ETH this policy will sponsor per user"
              value={maxGasPerAddr}
              onChange={handleMaxGasPerAddressChange}
            />
            <TextInput
              type="text"
              label="Maximum Number of User Operations"
              placeholder="0"
              helperText="Max number of user operations this policy will sponsor per user"
              value={maxTxnPerAddr}
              onChange={handleMaxTxnPerAddressChange}
            />
          </Grid>
        )}
      </VStack>

      <>
        <VStack>
          <TextCaption as="p" spacingBottom={4} spacingTop={2}>
            Contract and function allowlisting
          </TextCaption>
          <HStack justifyContent="space-between" alignItems="center">
            <VStack>
              <TextHeadline as="p">Allowlist smart contracts</TextHeadline>
              <TextBody as="p" color="foregroundMuted">
                Restrict gas sponsorship to a list of smart contracts. Optionally add specific
                functions of the contract to allowlist
              </TextBody>
            </VStack>
          </HStack>
          <HStack alignItems="center" spacingTop={3} gap={2}>
            <Grid templateColumns={responsiveTemplateColumns} gap={2} width="100%">
              <VStack>
                <TextInput
                  type="text"
                  label="Contract Address"
                  placeholder="0x123..."
                  variant={addressFormatError ? 'negative' : 'foregroundMuted'}
                  value={smartContractAddress}
                  onChange={handleSmartContractAddressChange}
                  onBlur={handleSmartContractAddressBlur}
                />
                <TextLabel2 as="p" color="foregroundMuted" spacingTop={0.5}>
                  {addressFormatError || 'Address of your contract'}
                </TextLabel2>
              </VStack>
              <VStack>
                <TextInput
                  type="text"
                  label="Function(s)"
                  placeholder="Ex: mint(),mintTo(address,uint256),0x449a52f8"
                  variant={methodFormatError ? 'negative' : 'foregroundMuted'}
                  value={smartContractMethod}
                  onChange={handleSmartContractMethodChange}
                  onBlur={handleSmartContractMethodBlur}
                />
                <TextLabel2 as="p" color="foregroundMuted" spacingTop={0.5}>
                  {methodFormatError ||
                    'Allowlisted functions, comma separated (supports function signatures and function selectors)'}
                </TextLabel2>
              </VStack>
            </Grid>
            <Box spacingTop={1}>
              <Button
                variant="primary"
                onPress={handleAddContractPress}
                disabled={
                  !isValidContractAddress(smartContractAddress) ||
                  !smartContractAddress ||
                  !isValidMethodInput(smartContractMethod)
                }
              >
                Add
              </Button>
            </Box>
          </HStack>
          {whitelistedContracts}
        </VStack>
        {/* <VStack>
            <TextCaption as="p" spacingBottom={4} spacingTop={2}>
              Attestation allowlisting
            </TextCaption>
            <VStack>
              <TextHeadline as="p">Allowlist attestations</TextHeadline>
              <TextBody as="p" color="foregroundMuted">
                Only sponsor wallets with certain attestations.
              </TextBody>
            </VStack>
            <VStack gap={2} spacingTop={4}>
              <Checkbox
                value="cb_verified"
                onChange={toggleCoinbaseVerifiedUser}
                checked={isCoinbaseVerifiedUserChecked}
              >
                <HStack alignItems="center" gap={0.5}>
                  <TextBody as="p">Coinbase Verified User </TextBody>
                  <Tooltip
                    content={
                      <TextBody as="span" color="foreground">
                        Learn more{' '}
                        <Pressable
                          background="transparent"
                          onPress={() => openUrlInNewWindow(VERIFICATION_LINK)}
                        >
                          <TextBody as="span" color="primary">
                            here
                          </TextBody>
                        </Pressable>
                      </TextBody>
                    }
                  >
                    <Icon name="info" size="s" color="foreground" />
                  </Tooltip>
                </HStack>
              </Checkbox>
              <TextBody as="p" color="foregroundMuted">
                More to come...
              </TextBody>
            </VStack>
          </VStack> */}
      </>
      <HStack gap={2} justifyContent="flex-end" alignItems="center">
        {!!numChangesUnsaved && (
          <TextBody as="p" color="foregroundMuted">{`${numChangesUnsaved} unsaved change${
            numChangesUnsaved > 1 ? 's' : ''
          }`}</TextBody>
        )}
        <Button variant="secondary" onPress={onCancel}>
          Close
        </Button>
        <Button
          variant="primary"
          onPress={handlePublishPolicyPress}
          disabled={!!maxTxnError || !!maxGasError || !numChangesUnsaved}
        >
          Save
        </Button>
      </HStack>
    </VStack>
  );
}
