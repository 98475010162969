/* Helper functions for Archival and Indexer Nodes */
import { ICluster } from ':cloud/types/ts_types';

export function isNodeArchival(cluster: ICluster): boolean {
  return (
    cluster?.configuration?.nodeType === 'archival' ||
    cluster?.configuration?.node_type === 'archival' ||
    cluster?.configuration?.syncMode === 'archival' ||
    cluster?.configuration?.pruningStrategy === 'archival' ||
    cluster?.configuration?.mode === 'archival' ||
    cluster?.configuration?.historyMode === 'archival'
  );
}

export function isNodeIndexer(cluster: ICluster): boolean {
  return (
    cluster?.configuration?.nodeType === 'qt-indexer' ||
    cluster?.configuration?.node_type === 'qt-indexer' ||
    cluster?.configuration?.syncMode === 'qt-indexer' ||
    cluster?.configuration?.pruningStrategy === 'qt-indexer' ||
    cluster?.configuration?.mode === 'qt-indexer' ||
    cluster?.configuration?.historyMode === 'qt-indexer'
  );
}
