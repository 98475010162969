import {
  endOfDay,
  roundToNearestHours,
  roundToNearestMinutes,
  startOfDay,
  subDays,
  subHours,
} from 'date-fns';
import _groupBy from 'lodash/groupBy';
import _merge from 'lodash/merge';
import _sortBy from 'lodash/sortBy';
import { ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { logError } from ':cloud/init/bugsnag/logging';
import { wholeOrFormatDecimals } from ':cloud/widgets/earnings/utils';
import { RANGE } from ':cloud/widgets/monitoring/monitoringConfig';
import {
  ChartData,
  DashboardRange,
  MetricDataPoint,
  MetricTag,
  TransformedMetricDataPoint,
} from ':cloud/widgets/monitoring/types';

/**
 * Returns an ISO formatted date string for the start of the day `n` days ago.
 */
function daysAgo(n = 0) {
  return startOfDay(subDays(new Date(), n)).toISOString();
}

/**
 * Returns an ISO formatted date string for `n` hours ago. Rounds down to the earliest hour by default.
 */
function hoursAgo(n = 0, roundingMethod: 'floor' | 'ceil' = 'floor') {
  const date = subHours(new Date(), n);
  const roundedDate = roundToNearestHours(date, { roundingMethod });
  return roundedDate.toISOString();
}

export function getStartTime(range: DashboardRange) {
  if (range === RANGE.day) {
    return hoursAgo(24);
  }
  if (range === 2) {
    return hoursAgo(48);
  }
  return daysAgo(range);
}

export function getEndTime(range: DashboardRange) {
  if (range === RANGE.day) {
    // Include the entirety of the current day, even if it technically includes the future
    return hoursAgo(0, 'ceil');
  }
  return endOfDay(subDays(new Date(), 0)).toISOString();
}

/**
 * Formats a timestamp to show either a date or a time, based on the range (1d, 7d, 30d).
 */
export function formatTickTimestamp(timestamp: string | Date, range: DashboardRange) {
  const date = new Date(timestamp);

  const defaultLocale = 'en-US';

  const locale = navigator.language || defaultLocale;

  let options = {};
  if (range === RANGE.day) {
    options = { timeStyle: 'short', hour12: false };
  } else if (range === RANGE.week) {
    options = { month: 'short', day: 'numeric' };
  } else {
    options = { month: 'short', day: 'numeric' };
  }

  try {
    return new Intl.DateTimeFormat(locale, options).format(date);
  } catch (error) {
    // Retry with the default locale
    logError(error, { context: 'format_tick_timestamp' }, { date: date.toString(), locale });
    return new Intl.DateTimeFormat(defaultLocale, options).format(date);
  }
}

export function getTagsFromChartData(data: ChartData[] | undefined): MetricTag[] {
  if (!data?.length) return [];
  return data.map((result) => result.tags).flat();
}

/**
 * Merges data by the groupBy tag value. Returns the merged data and the group names.
 */
export function mergeByGroup({ data, groupBy }: { data: ChartData[]; groupBy: string }): {
  chartData: TransformedMetricDataPoint[];
  groupSegmentNames: string[];
} {
  const groupSegmentNames: string[] = [];
  // Merge the arrays and change the dataPoint.value keys to match their respective tag values
  const flatMapped = data.flatMap((seriesData, i) => {
    const tagValue = seriesData.tags.find((tag) => tag.name === groupBy)?.value || `value-${i + 1}`;
    groupSegmentNames.push(tagValue);
    return seriesData.dataPoints.map(({ time, value }) => ({
      time,
      [tagValue]: value,
    }));
  });
  const grouped = _groupBy(flatMapped, 'time');
  const mergedChartData = Object.values(grouped).map(
    (values) => _merge({}, ...values) as TransformedMetricDataPoint,
  );

  return { chartData: mergedChartData, groupSegmentNames };
}

/** Aggregates time-series data values by provided interval (in minutes). NOT adjusted for local time zone. */
export function cumulativeDataPointsByInterval(
  dataPoints: MetricDataPoint[],
  minutes: number,
): MetricDataPoint[] {
  const intervalMs = minutes * 60 * 1000;
  /** We have to compare the current datapoint's time to the previous one, so the incoming data has to be sorted by date/time.
   *  This should be the case by default unless something is messed up... but yknow. Better safe than sorry. */
  const sortedDataPoints: MetricDataPoint[] = _sortBy(dataPoints, ['time']);

  const groupedByInterval = sortedDataPoints.reduce<MetricDataPoint[]>((acc, dataPoint) => {
    const previousSegment = acc[acc.length - 1];
    const currTime = roundToNearestMinutes(new Date(dataPoint.time)).getTime();
    const lastTime = roundToNearestMinutes(new Date(previousSegment?.time)).getTime();

    if (previousSegment && currTime - lastTime < intervalMs) {
      previousSegment.value += dataPoint.value;
    } else {
      // If the time diff between the last datapoint and the current datapoint is >= the interval, create a new segment
      acc.push({
        time: roundToNearestMinutes(new Date(currTime), {
          nearestTo: 15,
          roundingMethod: 'floor',
        }).toISOString(),
        value: dataPoint.value,
      });
    }

    return acc;
  }, []);

  return groupedByInterval;
}

export function getScope(metricName: string) {
  switch (metricName) {
    case 'cloud.rpc-proxy.request':
    case 'cloud.rpc-proxy.paymaster.sponsored-eth':
    case 'cloud.rpc-proxy.paymaster.sponsored-usd':
    case 'cloud.rpc-proxy.paymaster.sponsored-op':
    case 'cloud.rpc-proxy.paymaster.active-wallet.monthly':
    case 'cloud.rpc-proxy.paymaster.active-wallet.weekly':
    case 'cloud.rpc-proxy.paymaster.active-wallet.daily':
      return 'cloud.platform.rpc-proxy';
    case 'AddressCreated':
      return 'waas.consumer';
    case 'cloud.api-keys.request':
      return 'cloud.platform.api-keys';
    case 'transaction.count':
    case 'transaction.volume':
      return 'onramp_service';
    default:
      return 'cloud.platform';
  }
}

/** HOW TO HANDLE THE SERVICE TO PRODUCT MAPPING
- we receive an array of data arrays, each corresponding to a service
- service is listed under the tags

- datapoints are merged -> get list of data for each timestamp with values
per service

- for each datapoint, apply a mapping from product to service
- define a function that takes in all related service names, sums their values then updates the datapoint
with the product_name: summed_values

type productDataPoint = {
  time: string;
  'Trade Advanced':
  'Staking':
  'Platform SDK':
  'Onramp':
}
*/

const ProductToServiceMapping = {
  'Advanced-Trade': ['public_websocket_api', 'retail_rest_api_proxy'],
  Staking: ['staking', 'rewards-reporting'],
  'Platform-SDK': ['cdp_service'],
  Onramp: ['onramp-cloud'],
};

export function mapServiceToProduct(data: TransformedMetricDataPoint[]): {
  chartData: TransformedMetricDataPoint[];
  groupSegmentNames: string[];
} {
  /*
    init a json object with the productDataPoint type, with time and 0 for the product keys

    go through each product_name and iterate through its service names
    if the SERVICE_NAME exists on the datapoint, add it to the correct product key:
    - productDataPoint[product_name] = (productDataPoint[product_name] || 0) + value
    */

  const productData: TransformedMetricDataPoint[] = data.map((dataPoint) => {
    const newDataPoint = {
      time: dataPoint.time,
    } as TransformedMetricDataPoint;
    Object.entries(ProductToServiceMapping).forEach(([product, productServices]) => {
      productServices.forEach((service) => {
        if (dataPoint[service]) {
          newDataPoint[product] = (+newDataPoint[product] || 0) + +dataPoint[service];
        }
      });
    });
    return newDataPoint;
  });

  const groupSegmentNames = Object.keys(ProductToServiceMapping);
  return { chartData: productData, groupSegmentNames };
}

export function formatTooltipValueByUnit(value: ValueType | undefined, unit: string) {
  let newValue = value;
  if (typeof value === 'number') {
    newValue = wholeOrFormatDecimals(value, 4);
  }

  if (unit === 'Volume') {
    newValue = `${'$'}${newValue}`;
  }

  return newValue;
}
