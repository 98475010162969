/* Types listed here are matched with server payloads */
/* Application types should be relatively located to the filepath  */
import { type UiSchema } from '@rjsf/core';
import { ColorSurgeBackground, FallbackColor } from '@cbhq/cds-common';
import { NavIconName } from '@cbhq/cds-icons';
import { SpotIconName } from '@cbhq/cds-illustrations';

export type ClusterType = 'participation' | 'qt'; // fixed by workflow type

type ClusterRouteType = '/participate' | '/node'; // maintain route states with AppRoute

export type ClusterParamOptions = {
  protocolKey: string;
  clusterName: string;
  tabName?: string;
};

/*
 * Note: see Billing Service TDD for more info on model/property definitions
 * https://docs.google.com/document/d/1G2eZLQnygyQ7ZQAuzBOTrRDZg2eiQBG96o92-igz_XQ/edit?resourcekey=0-7gQpi4HKt1WqDWkCYL968w#
 */

/*
 * An Account is created for every organization and stores information on the
 * organization’s billing standing, such as whether an account is in a delinquent state.
 */
export interface Account {
  id: string;
  stripeCustomerId: string;
  billingEmail: string;
  billingStatus?: string;
  balance: number;
}

/*
 * A MeterTier is used when the pricing model should be a graduated
 * meter where discounts are applied at different usage levels.
 */
interface MeterTier {
  startingUnit: string;
  pricePerUnit: string;
}

/*
 * A BillingMeter tracks a price per unit of measurement.
 * For example, a ProductVariant that meters by API requests and wallets will have two BillingMeter set up.
 */
interface BillingMeter {
  id: string;
  name: string;
  productVariantId: string;
  unit: string;
  type: string;
  pricePerUnit: string;
  tiers: MeterTier[];
}

/*
 * A Product Variant describes a billing model associated with a Product. Depending on how it is configured,
 * it can represent a flat monthly rate, single tier metering, or multi-tier (volume-based discount) metering
 */
export interface ProductVariant {
  active: boolean;
  variantName: string;
  id: string;
  name: string;
  price: string;
  meters: BillingMeter[];
  productId: string;
}
/*
 * A Product represents a service, such as WaaS or Participate. Prices are not attached to Products
 * as they are primarily used for categorizing different billing models and plans.
 */
export interface Product {
  id: string;
  name: string;
  serviceId: string;
  variants: ProductVariant[];
  productName: string;
}

/** todo: update ProductType to match backend product types  */
export type ProductType =
  | 'staking_api'
  | 'rat'
  | 'participate'
  | 'base'
  | 'waas_consumer'
  | 'cbpay'
  | 'exchange';

// Specifically for COMS backend
// eslint-disable-next-line @cbhq/ts-singular-type-name
export type ProductTypeCOMS =
  | 'PRODUCT_TYPE_WAAS'
  | 'PRODUCT_TYPE_STAKING_API'
  | 'PRODUCT_TYPE_RAT'
  | 'PRODUCT_TYPE_PARTICIPATE'
  | 'PRODUCT_TYPE_BASE'
  | 'PRODUCT_TYPE_CB_PAY'
  | 'PRODUCT_TYPE_WAAS_CONSUMER'
  | 'PRODUCT_TYPE_EXCHANGE';

// The mapping of product type to the COMS product type PROTO.
export const ProductTypeMap: Record<ProductType, ProductTypeCOMS> = {
  // eslint-disable-next-line camelcase
  waas_consumer: 'PRODUCT_TYPE_WAAS_CONSUMER',
  // eslint-disable-next-line camelcase
  staking_api: 'PRODUCT_TYPE_STAKING_API',
  rat: 'PRODUCT_TYPE_RAT',
  participate: 'PRODUCT_TYPE_PARTICIPATE',
  base: 'PRODUCT_TYPE_BASE',
  cbpay: 'PRODUCT_TYPE_CB_PAY',
  exchange: 'PRODUCT_TYPE_EXCHANGE',
};

/**
 * A Project is a collection of products. In the interim, we only support a single product per project.
 * This type is only used for COMS write responses; the COMS read response shape has not been migrated yet.
 * For COMS read responses, use Project.
 */
export type ProjectCOMS = {
  id: string;
  organizationId: string;
  name: string;
  products: ProductType[];
  updatedAt: string;
  createdAt: string;
  createdByUserId: string;
  active: boolean;
};

/**
 * Product details represent the configuration data needed for a given product.
 */
export type ProductDetails = {
  cbpay?: CBPayApp;
  waasConsumer?: WaasConsumerApp;
};

/**
 * CBPayApp represents the details for a CB Pay app integration.
 */
type CBPayApp = {
  appId: string;
  cloudProjectId: string;
  projectId: string;
  destinationName: string;
  isSecureInitRequired: boolean;
  domainAllowlist: string[];
  isActive: boolean;
  displayName: string;
  secureInitRequired: boolean;
};

type Media = {
  externalUri: string;
  mediaUri?: string;
};

/**
 * WaasConsumerApp represents the details for a Waas Consumer app integration.
 */
export type WaasConsumerApp = {
  projectId: string;
  domainOrigin: string;
  etag?: string;
  appName?: string;
  logo?: Media;
  favicon?: Media;
  signInWithGoogle?: boolean;
};

/**
 * A Project is a collection of products. In the interim, we only support a single product per project.
 * This type is only used for COMS read responses; its response shape has not been migrated yet.
 * For COMS write requests, use ProjectCOMS.
 */
export type Project = {
  id: string;
  organizationId: string;
  name: string;
  type: ProductType;
  updatedAt: string;
  createdAt: string;
  createdByUserId: string;
  isTestnet: boolean;
  active: boolean;
};

// An Account Item represents an organization’s usage of a Product Variant.
export interface AccountItem {
  id: string; // aka account item id
  accountId: string;
  name: string; // e.g. "accounts/ACCOUNT_ID/items/ACCOUNT_ITEM_ID"
  productVariantId: string;
  projectId: string;
  createTime: string;
  cancelTime?: string | null;
  productVariant: ProductVariant | null;
}

/*
 * AccountCredit is a credit applied in USD issued by Cloud against a customers next billing cycle.
 */
export interface AccountCredit {
  id: string;
  name: string;
  /* Same as the organization id. */
  accountId: string;
  amountIssued: string;
  amountRemaining: string;
  /* The title of this credit displayed on customer's invoice. */
  title: string;
  creditType: string;
  issuer: string;
  issueTime: string;
  /* Should default to 90 days after creation for "signup" credits only. */
  expireTime: string | null;
  lastUpdateTime: string;
}

export type UserDerivedProperties = {
  isInternalUser: boolean;
  isOwnerOrAdmin: boolean;
  shouldShowDevModeToggle: boolean;
};

export type User = {
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  avatarUrl: string;
  // This is to account for the fact that the legacy response is the one currently
  // being used throughout the app and I didnt' want to change the entire app in this PR
  organizations: (Omit<Organization, 'role'> & OrganizationRoleCOMS)[];
  currentOrganization: Omit<CurrentOrganization, 'role'> & OrganizationRoleCOMS;
  displayName: string;
  exchangeEnabled: boolean;
  /**
  This field is omitted if the user does not have elevated permissions
  To obtain access sign up on https://confluence.coinbase-corp.com/display/CLOUD/Requesting+Elevated+Coinbase+Cloud+Permissions
  */
  developerModeEnabled: boolean;
};

/** Returned in the legacyResponse field of the /self endpoint */
export type LegacyUser = {
  currentOrganization: CurrentOrganization;
  organizations: Organization[];
  email: string;
  familyName?: string;
  name: string;
  userId: string;
  userUuid: string;
  avatarUrl: string;
  /**
  This field is omitted if the user does not have elevated permissions
  To obtain access sign up on https://confluence.coinbase-corp.com/display/CLOUD/Requesting+Elevated+Coinbase+Cloud+Permissions
  */
  developerModeEnabled: boolean;
};

export type AnyDuringJSToTSMigration = any;

export interface IAdapter {
  clustersFilter: (value: ICluster, index?: number, array?: ICluster[]) => boolean;
  clusterRoute: ClusterRouteType;
  clusterType: ClusterType;
  networksFilter: (value: IWorkflow, index?: number, array?: ICluster[]) => boolean;
  networkDetails: (value: IWorkflow) => IWorkflow;
  hasTokens: () => boolean;
}

interface IClusterTopology {
  scale: number;
}

export interface IConfiguration extends ProtocolNetworkType {
  clusterType: ClusterType;
  eth2Environment: unknown;
  name: string;
  ownerId: string;
  policy: ITokenPolicy;
  region: string;
  replicaCount: number;
  sk: string;
  tokens: IResourceToken[];
  topology: Record<string, IClusterTopology>;
  type: ProtocolKey;
  username?: string;
  /** dynamic keys per protocol */
  historyMode?: string;
  mode?: string;
  node_type?: string;
  nodeType?: string;
  pruningStrategy?: string;
  syncMode?: string;
}

export interface IResourceToken {
  auth?: IAuth;
  created: Date;
  id: string;
  name: string;
  username?: string;
  ownerId: string;
  pk: string;
  policy: ITokenPolicy;
  sk: string;
}

type IRateLimitDuration = 'second' | 'minute' | 'hour' | 'day';
interface IRateLimit {
  duration: IRateLimitDuration;
  limit: number;
}

interface ITokenPolicy {
  allowlist?: string[];
  basicAuth: boolean;
  rateLimits: IRateLimit[];
}

export interface IAPIToken {
  name: string;
  services: IAPIService[];
  guid: string;
  revoked: boolean;
  createdDate: string;
  token?: string;
}

export type IAPITokenPermType = 'read' | 'write' | 'delete';

export const IAPITokenPermTypeLabelMap: {
  [key in IAPITokenPermType]: string;
} = {
  read: 'Read',
  write: 'Create/Edit',
  delete: 'Delete/Exit',
};

export type IAPIService = {
  name: string;
  description?: string;
  permissions: IAPITokenPermType[];
};

type IAPIRateLimit = {
  policy: string;
  second: number;
  hour: number;
};

type IAPITokenConfig = {
  token_limit: number;
  rate_limits: IAPIRateLimit;
  kong_configuration_namespace?: string;
  controller_ingress_class?: string;
};

export type IAPIConfigResult = {
  services: IAPIService[];
  global: IAPITokenConfig;
};

interface IAuth {
  basic: IBasic;
}

interface IBasic {
  enabled: boolean;
  sk: string;
}

export type ClusterStateType =
  | 'BEING_CREATED'
  | 'CREATE_FAILED'
  | 'CREATE_COMPLETE'
  | 'BEING_UPDATED'
  | 'UPDATE_FAILED'
  | 'UPDATE_COMPLETE'
  | 'DELETE_FAILED'
  | 'BEING_DELETED'
  | 'DELETE_COMPLETE';

export interface ICluster {
  artifacts: IClusterArtifacts;
  artifactsHash?: string;
  clusterType: 'qt' | 'participation' | 'shared_qt';
  configuration: IConfiguration;
  created: Date;
  error?: string;
  id: string;
  name: string;
  nodes?: { id: string }[];
  ownerId: string;
  state: ClusterStateType;
  type: ProtocolKey;
  updated: Date;
  version: string;
}

export interface IClusterArtifacts {
  addresses?: Record<string, string>;
  endpoint?: string;
  eth?: string;
  explorer?: string;
  misc?: any;
  mode?: string;
  network: string;
  nodes?: string[];
  rpcPort?: string;
  sentryCount?: string;
  validatorFee?: string;
  version: string;
  wsPort?: string;
  specifications?: ClusterSpecsType;
  [key: string]: any;
}

type ClusterSpecsType = {
  node?: SpecsType;
  indexer?: SpecsType;
};
interface SpecsType {
  cpu: string;
  memory: string;
  name: string;
  scale: string;
  storage: string;
}

/* supported protocols as of 4/4/2023 */
export type ProtocolKey =
  | 'algorand'
  | 'aptos'
  | 'avalance'
  | 'alexar'
  | 'cardano'
  | 'celo'
  | 'cosmos'
  | 'eth2'
  | 'evmos'
  | 'ethereum'
  | 'flow'
  | 'jerky' // internal test chain
  | 'livepeer'
  | 'mina'
  | 'near'
  | 'ontology'
  | 'osmosis'
  | 'polkadot'
  | 'solana'
  | 'sui'
  | 'threshold'
  | string;

export type IWorkflowMap = Record<ProtocolKey, IWorkflow>;

export interface IWidgetSchemaRoot {
  options: object;
  sections: ISectionSchema[];
}
export interface ISectionSchema {
  name: string;
  options: ISectionOptions;
  widgets: IWidgetSchema[];
}

type FilterType = 'nodes' | 'metrics';

interface ISectionOptions {
  hideIfEmpty?: boolean;
  clientKeySource?: string;
  noMargin?: boolean;
  headerType?: 'withTag' | 'withFilters';
  isDynamic?: boolean;
  subTitle?: string;
  regionTitle?: string;
  tag?: string;
  isWrapper?: boolean;
  layout?: 'MainContainer' | 'ContentWrapper' | 'SidebarContainer' | 'TopInfo';
  filters?: FilterType[];
}

export type IWidgetType =
  | 'area_chart'
  | 'block_unit'
  | 'chart_unit'
  | 'display_unit'
  | 'eth_dot'
  | 'eth_validator_count'
  | 'line_chart_revamp'
  | 'line_chart'
  | 'node_empty'
  | 'node_header'
  | 'node_table'
  | 'node_unit'
  | 'peer_count'
  | 'percentage_bar'
  | 'progressbar_chart'
  | 'radial_chart'
  | 'request_chart'
  | 'request_revamp_chart'
  | 'response_chart'
  | 'response_revamp_chart'
  | 'stake_bar'
  | 'uptime_chart'
  | 'validator_count';

interface IWidgetTooltipOptions {
  label: string;
  unit?: string;
  type?: 'percent' | 'fraction';
}

type IWidgetSourceOptions = Record<
  string,
  {
    axes?: {
      showTicks: boolean;
      showLabel: boolean;
    };
    color: string;
    display?: { label: string; unit?: string };
    legend: { label: string };
    title?: string;
    tooltip: { label: string };
    type: IWidgetType;
  }
>;

type MuiGridSize = 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

/* Includes options for all widgets defined in schemaWidget/renderer */
interface IWidgetOptions {
  title?: string;
  endValue?: number;
  alignLeft?: boolean;
  color: string;
  hasClipboard?: boolean;
  icon?: string;
  isCapitalized?: boolean;
  isLabelBottom?: boolean;
  isTruncated?: boolean;
  joiner?: string;
  labelPluralization?: string;
  legend: { label: string };
  lgGridSpacing?: MuiGridSize;
  mdGridSpacing?: MuiGridSize;
  xsGridSpacing?: MuiGridSize;
  showPlaceholder?: boolean;
  sources: { metrics?: IWidgetSourceOptions };
  unit?: string;
  isWrapper?: boolean;
  type: string;
  dataKey?: string;
  transformKey?: string;
  yAxis?: {
    max?: number;
    unit?: string;
  };
  tooltip?: IWidgetTooltipOptions;
}

export interface IConditionalOption {
  source: SchemaSourceType;
  value: string | number;
}

export type SchemaSourceType = {
  type: string;
  source: 'artifacts' | 'configuration' | 'metrics';
  key: MetricKeyType;
};
export interface IWidgetSchema {
  options: IWidgetOptions;
  conditional: IConditionalOption;
  sources: SchemaSourceType[];
  widget: IWidgetType;
}

export interface Eth2Pod {
  kubernetes_cluster_id: string;
  region: string;
}

interface ICustomFormSchema {
  properties: Record<string, any>;
  required?: string[];
  type: 'object';
}

export interface IWorkflow {
  clusterType?: ClusterType;
  description: string;
  display: string;
  documentation?: string;
  hasTopology?: boolean;
  formSchema: ICustomFormSchema;
  icon: string;
  metaSchema: {
    networkChain?: string;
    plans?: string;
  };
  metricKeys: Record<string, string>;
  name: ProtocolKey;
  tickerSymbol: string;
  requireBasicAuth?: boolean;
  summary: string;
  uiSchema: UiSchema;
  version: string;
  visibility: NetworkVisibilityType;
  widgetSchemas: {
    info?: IWidgetSchemaRoot;
    metrics?: IWidgetSchemaRoot;
  };
}

enum NetworkVisibilityType {
  Active = 'active',
  Development = 'development',
  Hidden = 'hidden', // visible to bison users only
}

export interface INotifierInfo {
  type: 'info' | 'error' | 'success' | 'general';
  variant: ColorSurgeBackground;
  message: string;
}

export interface IMetricsRoot {
  data: IMetricData;
  metrics: IMetrics[] | [];
  keys: Record<string, any>;
}

export interface ProtocolMetricKeys {
  blockHeightKey: string;
  earningsKey: string;
  totalStakedKey: string;
  uptimeKey: string;
}

export interface Region {
  color: string;
  display: string;
  groupName: string;
  icon: string;
  locale: string;
  provider: 'gcp' | 'aws' | 'azure';
}

export type MetricRegionKey =
  | 'us-east-2.aws.001'
  | 'us-west-2.aws.001'
  | 'eu-west-1.aws.001'
  | 'ap-southeast-1.aws.001'
  | 'ap-northeast-1.aws.001'
  | 'asia-east2.gcp.001'
  | 'europe-west3.gcp.001'
  | 'us-east4.gcp.001'
  | 'us-east1.gcp.001';

export interface IMetrics {
  name: MetricKeyType;
  type: string;
  label: string;
  isRange: boolean;
}

export type MetricKeyType =
  | 'beacon_head_slot'
  | 'beacon_memory_usage_percent'
  | 'blocks_per_second_168h_168m'
  | 'blocks_per_second_24h_24m'
  | 'blocks_per_second_720h_720m'
  | 'blocks_per_second'
  | 'connected_peers'
  | 'cpu_usage_percent'
  | 'eth_block_number_proxy'
  | 'fullnode_cpu_usage_percent'
  | 'fullnode_cpu_usage'
  | 'fullnode_memory_usage'
  | 'http_2XX_216h_3h'
  | 'http_2XX_24h_20m'
  | 'http_2XX_6h_5m'
  | 'http_2XX_72h_1h'
  | 'http_4XX_216h_3h'
  | 'http_4XX_24h_20m'
  | 'http_4XX_6h_5m'
  | 'http_4XX_72h_1h'
  | 'http_5XX_216h_3h'
  | 'http_5XX_24h_20m'
  | 'http_5XX_6h_5m'
  | 'http_5XX_72h_1h'
  | 'http_latency_216h_216h'
  | 'http_latency_216h_3h'
  | 'http_latency_24h_20m'
  | 'http_latency_24h_24h'
  | 'http_latency_6h_5m'
  | 'http_latency_6h_6h'
  | 'http_latency_72h_1h'
  | 'http_latency_72h_72h'
  | 'http_requests_total'
  | 'http_requests'
  | 'http_responses'
  | 'http_XXX_216h_3h'
  | 'http_XXX_24h_20m'
  | 'http_XXX_6h_5m'
  | 'http_XXX_72h_1h'
  | 'memory_usage_percent'
  | 'memory_usage_percent'
  | 'memory_usage_proxy'
  | 'memory_usage_validator'
  | 'memory_usage'
  | 'memory_usage'
  | 'mempool_size_168h_168m'
  | 'mempool_size_24h_24m'
  | 'mempool_size_720h_720m'
  | 'mempool_size'
  | 'net_peers_proxy'
  | 'net_peers_validator'
  | 'network_receive_bytes_over_time'
  | 'network_receive_bytes'
  | 'network_transmit_bytes_over_time'
  | 'network_transmit_bytes'
  | 'p2p_peer_count'
  | 'peers_fullnode'
  | 'peers_validator'
  | 'peers'
  | 'proposals_168h_168m'
  | 'proposals_24h_24m'
  | 'proposals_720h_720m'
  | 'proposals'
  | 'tezos_chain_block_level'
  | 'transactions_per_second_168h_168m'
  | 'transactions_per_second_24h_24m'
  | 'transactions_per_second_720h_720m'
  | 'transactions_per_second'
  | 'uptime_48h_12h'
  | 'uptime_fullnode'
  | 'uptime_validator'
  | 'uptime'
  | 'validator_count_active'
  | 'validator_cpu_usage_percent'
  | 'validator_cpu_usage'
  | 'validator_memory_usage'
  | 'version_fullnode'
  | 'version_validator'
  | 'votes_168h_168m'
  | 'votes_24h_24m'
  | 'votes_720h_720m'
  | 'votes'
  | string;

export type IMetricData = Record<MetricKeyType, IMetricDataType[] | null>;

export type MetricValue = [number, string];
type MetricValues = MetricValue[];
type MetricMeta = Record<string, string>;

export interface IMetricDataType {
  metric: MetricMeta;
  values?: MetricValues;
  value?: MetricValue;
}

export interface IOrganization {
  billingId?: string;
  id: string;
  name: string;
  tagTypeId?: string;
  users?: OrganizationActiveUser[];
  invites: OrganizationInvitedUser[];
  waas_enabled: boolean;
}

export type ActiveMembers = {
  created: Date;
  email: string;
  familyName?: string;
  id: string;
  modified: Date;
  name: string;
  role: OrganizationRole;
  avatarUrl: string;
  coinbaseUserId?: string;
};

export type InvitedMembers = {
  email: string;
  modified: Date;
  role: OrganizationRole;
  avatarUrl: string;
};

export interface OrganizationActiveUser extends ActiveMembers {
  status: 'active';
  fullNameOrEmail: string;
  avatarColor: FallbackColor;
}

export interface OrganizationInvitedUser extends InvitedMembers {
  status: 'invited';
}
export type OrganizationUser = OrganizationActiveUser | OrganizationInvitedUser;

export interface IClusterLimits {
  clusterCount: number;
  clusterLimit: number;
  resourceType: ProtocolKey;
  userId: string;
}

export type ICustomerLimits = Record<ProtocolKey, IClusterLimits>;

type PrincipalType = 'USER' | 'SERVICE_ACCOUNT' | 'PRINCIPAL_TYPE_UNSPECIFIED';

export type TradePortfolio = {
  name: string;
  uuid: string;
  type: string;
};

export interface ITradePortfolio {
  portfolio: TradePortfolio;
  currency: string;
}

export type CoinbaseAPIKey = {
  projectId: string;
  nickname: string;
  allowedIps?: string[];
  scopes: string[];
  enabled?: boolean;
};

export type EditCoinbaseAPIKey = Partial<CoinbaseAPIKey> & {
  projectId?: string;
  nickname?: string;
  scopes?: string[];
};

export type CoinbaseWebhook = {
  uuid?: {
    hex: string;
  };
  notificationUri: string;
  notificationHeaders: {
    key: string;
    value: string;
  }[];
  network: string;
  eventType: string;
  eventFilters: [
    {
      contractAddress: string;
      fromAddress: string;
      toAddress: string;
    },
  ];
};

export type EditCoinbaseWebhook = Partial<CoinbaseWebhook> & {
  notificationUri: string;
};

export interface IPlatformAPIKey {
  createTime: string;
  name: string;
  /** update: optional to preserve backwards  compatibility */
  nickname?: string;
  principal: string;
  principalType: PrincipalType;
  privateKey: string;
  /** update: optional to preserve backwards  compatibility */
  projectId?: string;
  publicKey: string;
  scopes?: string[];
  allowedIps?: string[];
  keyType?: string;
  enabled?: boolean;
}

export interface IPlatformToken {
  createTime: string;
  name: string;
  scopes?: string[];
  organizationId: string;
  projectId: string;
  id: string;
  policies?: GasPolicy[];
  isTestnet?: boolean;
}

/**
 * owner: Account creator, single owner per org
 *
 * admin: Rights assigned by owner or other admins to allow priviledged access
 *
 * user: View only rights
 */
export type OrganizationRole = 'owner' | 'admin' | 'user';

// Specifically for COMS backend
// eslint-disable-next-line @cbhq/ts-singular-type-name
export type OrganizationRoleCOMS = 'ROLE_OWNER' | 'ROLE_ADMIN' | 'ROLE_USER';

export enum OrganizationRoleCOMSEnum {
  owner = 'ROLE_OWNER',
  admin = 'ROLE_ADMIN',
  user = 'ROLE_USER',
}

export interface Organization {
  role: OrganizationRole;
  organizationId: string;
  // TODO: add organization name once we migrate to Org service
  name?: string;
}

export interface CurrentOrganization {
  organizationId: string;
  role: OrganizationRole;
}

type ProtocolNetworkKey =
  | 'chain'
  | 'mode'
  | 'network'
  | 'networkId'
  | 'region'
  | 'genesis'
  | 'nodeType';

type ProtocolNetworkType = {
  [key in ProtocolNetworkKey]?: string;
};

export type IFormDataType = ProtocolNetworkType & {
  name: string;
  protocolKey: ProtocolKey;
  region: string;
  addresses?: string[];
};

export type CodeBlockLanguage = 'json' | 'bash' | 'javascript' | 'python' | 'ruby';

export type BillingInterval = 'day' | 'week' | 'month' | 'year';

export type GenericProviderProps = {
  children: JSX.Element | JSX.Element[];
};

export type BillingHistoryItem = {
  id: string;
  amount: string;
  billingPeriodStartTime: string;
  billingPeriodEndTime: string;
  status: 'PENDING' | 'PAID' | 'FAILED' | 'INCOMPLETE';
  // PENDING: time between invoice generation and processing, the download functionality isn't available during this time
  // note that all non-production invoices will remain in a PENDING state
  // PAID: invoice was processed & paid successfully
  // FAILED: payment not received
  // INCOMPLETE: charge was too small & is being deferred to the next invoice
  stripeInvoiceId: string;
};

export type ProductItem = {
  navigationIcon: NavIconName;
  spotIconName: SpotIconName;
  iconName?: NavIconName;
  title: string;
  productType?: ProductType;
  altDescription?: string;
  description: string;
  shortDesc?: string;
  linkToRoute: string;
  hasButton?: boolean;
  productTag?: 'Preview' | 'Enterprise';
  isOffPlatform?: boolean;
  categories: string[];
};

export type SelectedPortfolioType = {
  id: string;
  name: string;
};

export enum BaseNetworkEnum {
  Mainnet = 'mainnet',
  Testnet = 'testnet',
}

export type WhitelistedAddressAndMethod = {
  address: string;
  methods?: string[];
  name?: string;
};

export type AttestationRequirement = {
  schemaId: string;
};

export type GasPolicy = {
  name: string;
  id: string;
  policyName?: string;
  organizationId: string;
  projectId: string;
  tokenId: string;
  createTime: string;
  perAddrMaxEnabled: boolean;
  maxGasPerAddr: number;
  maxGasPerAddrUsd?: number;
  maxTxnPerAddr: string;
  globalMaxEnabled: boolean;
  maxGlobalGas: number;
  maxGlobalGasUsd?: number;
  maxGlobalTxn: string;
  initialCredits: number;
  intervalType?: string;
  policyType: string;
  enabled: boolean;
  contractAllowlist?: WhitelistedAddressAndMethod[];
  attestationRequirements?: AttestationRequirement[];
  paymasterSponsorName?: string;
  maxGasPerUserOperationEnabled: boolean;
  maxGasPerUserOperationUsd?: number;
};

export type UpdateGasPolicy = {
  name?: string;
  id?: string;
  policyName?: string;
  organizationId: string;
  projectId: string;
  tokenId: string;
  createTime?: string;
  perAddrMaxEnabled: boolean;
  maxGasPerAddr: number;
  maxTxnPerAddr: string;
  globalMaxEnabled: boolean;
  maxGlobalGas: number;
  maxGlobalTxn: string;
  initialCredits?: number;
  policyType?: string;
  enabled: boolean;
};

export type BaseCodeResponse = {
  code: string;
  responseStatus?: number;
};

export type FaucetResponse = {
  txHash: string;
};
