import { cloneElement, useCallback, useMemo } from 'react';

import { FlyoutContent } from './FlyoutContent';
import { FlyoutProps } from './FlyoutProps';
import { Popover } from './Popover';
import { useFlyoutState } from './useFlyoutState';

export function Flyout({
  children,
  content,
  placement = 'right',
  gap = 1,
  disablePortal,
  testID,
  zIndex,
  flyoutId: flyoutIdDefault,
}: FlyoutProps) {
  const { isOpen, handleOnMouseEnter, handleOnMouseLeave, handleOnFocus, handleOnBlur, flyoutId } =
    useFlyoutState(flyoutIdDefault);

  const handleOnPress = useCallback(
    (originalOnPress: (event: PointerEvent) => void) => (event: PointerEvent) => {
      originalOnPress?.(event);

      // If the content is a string (not a sub-menu), close the flyout when the trigger is clicked
      if (typeof content === 'string') {
        handleOnBlur();
      }
    },
    [handleOnBlur, content],
  );

  const clonedChild = useMemo(() => {
    return cloneElement(children, {
      'aria-describedby': flyoutId,
      onPress: handleOnPress(children.props.onPress),
    });
  }, [children, flyoutId, handleOnPress]);

  const contentPosition = useMemo(
    () => ({
      placement,
    }),
    [placement],
  );

  return (
    <Popover
      contentPosition={contentPosition}
      disablePortal={disablePortal}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      onMouseDown={handleOnMouseEnter}
      visible={isOpen}
      testID="sidebar-flyout-popover"
      content={
        <FlyoutContent
          flyoutId={flyoutId}
          content={content}
          gap={gap}
          testID={testID}
          zIndex={zIndex}
          placement={placement}
          onClick={handleOnMouseLeave}
        />
      }
    >
      {clonedChild}
    </Popover>
  );
}
