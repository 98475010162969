import { Dispatch, memo, SetStateAction, useCallback, useState } from 'react';
import { useAlert } from '@cbhq/cds-common/overlays/useAlert';
import { noop } from '@cbhq/cds-utils';
import { Button } from '@cbhq/cds-web/buttons';
import { ListCell } from '@cbhq/cds-web/cells';
import { Select, SelectOption } from '@cbhq/cds-web/controls';
import { Pictogram } from '@cbhq/cds-web/illustrations';
import { Box, Divider, VStack } from '@cbhq/cds-web/layout';
import { Avatar } from '@cbhq/cds-web/media';
import { Alert, Modal, ModalBody, ModalFooter, ModalHeader } from '@cbhq/cds-web/overlays';
import { TextBody, TextLabel2, TextTitle2 } from '@cbhq/cds-web/typography';

import { useSwitchMemberRole } from ':cloud/queries/OrganizationQueries/useSwitchMemberRole';
import { FormattedUser } from ':cloud/queries/UserQueries/useGetUser';
import { OrganizationActiveUser } from ':cloud/types/ts_types';

type ChangeOwnerModalProps = {
  visible: boolean;
  currentOwner: FormattedUser;
  user: OrganizationActiveUser;
  orgId: string;
  activeUsers: OrganizationActiveUser[];
  onRequestClose?: () => void;
};

export const ChangeOwnerModal = memo(function ChangeOwnerModal({
  visible,
  currentOwner,
  user,
  orgId,
  activeUsers,
  onRequestClose,
}: ChangeOwnerModalProps) {
  const [newOwner, setNewOwner] = useState(user.fullNameOrEmail);
  const coinbaseUserId = activeUsers.find((u) => u?.id === user.id)?.coinbaseUserId || '';

  const changeOwnerMutation = useSwitchMemberRole(orgId, user.email, coinbaseUserId);
  const confirmOwnership = useAlert();

  const handleChangeOwner = useCallback(() => {
    changeOwnerMutation.mutate('owner', {
      onSuccess: onRequestClose,
    });
  }, [changeOwnerMutation, onRequestClose]);

  const handleReconfirmChangeOwnership = useCallback(() => {
    onRequestClose?.();
    const handleCloseAlert = confirmOwnership.close;
    confirmOwnership.open(
      <Alert
        visible
        title={`Are you sure you want to change the owner to ${newOwner} ?`}
        body=""
        onRequestClose={handleCloseAlert}
        preferredActionLabel="Yes, I'm sure"
        onPreferredActionPress={handleChangeOwner}
        dismissActionLabel="Cancel"
        onDismissActionPress={handleCloseAlert}
      />,
    );
  }, [confirmOwnership, handleChangeOwner, newOwner, onRequestClose]);

  return (
    <Modal hideDividers visible={visible} onRequestClose={onRequestClose || noop}>
      <ModalHeader />
      <ModalBody>
        <VStack gap={3}>
          <ChangeOwnerHeader />
          <Divider />
          <CurrentOwnerSelection currentOwner={currentOwner} />
          <NewOwnerSelection
            currentOwner={currentOwner}
            newOwner={newOwner}
            setNewOwner={setNewOwner}
            activeUsers={activeUsers}
          />
        </VStack>
      </ModalBody>
      <ModalFooter
        primaryAction={
          <Button
            disabled={currentOwner.email === newOwner}
            onPress={handleReconfirmChangeOwnership}
          >
            Confirm
          </Button>
        }
        secondaryAction={
          <Button variant="secondary" onPress={onRequestClose}>
            Cancel
          </Button>
        }
      />
    </Modal>
  );
});

function ChangeOwnerHeader() {
  return (
    <VStack alignItems="center" gap={1}>
      <Box spacingTop={5} spacingBottom={2}>
        <Pictogram name="warning" scaleMultiplier={1.3} />
      </Box>
      <TextTitle2 as="h4">Change organization owner</TextTitle2>
      <TextBody color="foregroundMuted" as="p" align="center" spacingBottom={2}>
        Select a new owner for this organization. They&apos;ll be notified of their new role by
        email and the current owner will be moved to the User role.
      </TextBody>
    </VStack>
  );
}

function CurrentOwnerSelection({ currentOwner }) {
  return (
    <VStack gap={1}>
      <TextLabel2 color="foregroundMuted" as="p" align="start">
        current organization owner
      </TextLabel2>
      <ListCell
        selected
        title={`${currentOwner.name} ${currentOwner.familyName}` || currentOwner?.email}
        description={currentOwner.email}
        media={<Avatar alt={currentOwner.name || currentOwner.email} size="l" />}
      />
    </VStack>
  );
}

interface NewOwnerSelectionProps {
  currentOwner: FormattedUser;
  activeUsers: OrganizationActiveUser[];
  newOwner: string;
  setNewOwner: Dispatch<SetStateAction<string>>;
}

function NewOwnerSelection({
  currentOwner,
  newOwner,
  setNewOwner,
  activeUsers,
}: NewOwnerSelectionProps) {
  return (
    <VStack gap={1}>
      <TextLabel2 color="foregroundMuted" as="p" align="start">
        new organization owner
      </TextLabel2>
      <Select value={newOwner} onChange={setNewOwner}>
        {activeUsers?.map(
          (u: OrganizationActiveUser) =>
            u.email !== currentOwner.email && (
              <SelectOption
                key={u.fullNameOrEmail}
                value={u.fullNameOrEmail}
                title={u.fullNameOrEmail}
              />
            ),
        )}
      </Select>
    </VStack>
  );
}
