import { FunctionComponent } from 'react';
import { Elements } from '@stripe/react-stripe-js';

import { getStripe } from ':cloud/utils/getStripe';

function WithElements<T extends JSX.IntrinsicAttributes>(
  Component: React.ComponentType<T>,
): FunctionComponent<T> {
  const factory: React.ComponentType<T> = (props: T): JSX.Element => (
    <Elements stripe={getStripe()}>
      <Component {...props} />
    </Elements>
  );

  factory.displayName = `${Component.displayName}WithElements`;
  return factory;
}

export default WithElements;
