import { useQuery } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { GetAccountResponse } from ':cloud/types/service_proto';
import { Account } from ':cloud/types/ts_types';

async function fetchAccount(orgId?: string): Promise<GetAccountResponse> {
  const { data } = await APIClientBase.get<GetAccountResponse>(`/billing/v1/accounts/${orgId}`);
  return data;
}

export function useGetAccount(orgId?: string): {
  account: Account | undefined;
  isLoading: boolean;
} {
  const { isLoading, data } = useQuery({
    queryKey: [QueryKey.accounts, orgId],
    queryFn: async () => fetchAccount(orgId),
    staleTime: Infinity,
    enabled: !!orgId,
  });
  return {
    account: data,
    isLoading,
  };
}
