import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useToggler } from '@cbhq/cds-common/hooks/useToggler';
import { NavigationIcon } from '@cbhq/cds-web/icons';
import { Box, Divider, VStack } from '@cbhq/cds-web/layout';
import { Pressable } from '@cbhq/cds-web/system';

import { mobileHamburgerItems, NavItem } from ':cloud/config/navigation';
import { useNavigationContext } from ':cloud/contexts/NavigationContext';
import { useGetNavMenuItems } from ':cloud/hooks/useGetNavMenuItems';
import { EventName, logClick } from ':cloud/init/clientAnalytics/logging';
import { openUrlInNewWindow } from ':cloud/utils/openUrlInNewWindow';
import { AppRoute } from ':cloud/utils/routes';
import { LEFT_NAV_WIDTH } from ':cloud/widgets/layout/constants';
import { OrgSwitcher } from ':cloud/widgets/navigation/OrgSwitcher';
import { ProfileHeader, ProfileItems } from ':cloud/widgets/navigation/ProfileDropdown';
import { DrawerItem } from ':cloud/widgets/sharedcomponents/DrawerItem';
import { MobileDrawer } from ':cloud/widgets/sharedcomponents/MobileDrawer';

export function HamburgerNav() {
  const history = useHistory();
  const { activePage } = useNavigationContext();
  const [drawerOpen, { toggleOn: openDrawer, toggleOff: closeDrawer }] = useToggler();
  const [isOrgSwitcherOpen, { toggle: toggleOrgSwitcher }] = useToggler(false);

  const { displayedPrimaryNavItems } = useGetNavMenuItems();

  const handlePress = useCallback(
    (page: NavItem) => {
      const activeRoute = displayedPrimaryNavItems.find((item) => item.title === page.title);
      logClick(EventName.left_nav, { target: activeRoute?.title });

      if (page?.to?.startsWith('http')) {
        openUrlInNewWindow(page?.to);
      } else {
        history.push(page?.to || AppRoute.Home);
      }

      closeDrawer();
    },
    [closeDrawer, history, displayedPrimaryNavItems],
  );

  return (
    <>
      <Pressable background="transparent" onPress={openDrawer}>
        <Box spacingStart={0.5} alignItems="center">
          <NavigationIcon accessibilityLabel="App Switcher Menu" name="menu" />
        </Box>
      </Pressable>

      <MobileDrawer open={drawerOpen} closeDrawer={closeDrawer}>
        {isOrgSwitcherOpen ? (
          <OrgSwitcher handleOrgSwitcherClose={toggleOrgSwitcher} />
        ) : (
          <>
            {/* profile avatar, user name and email */}
            <ProfileHeader />
            <Divider spacingTop={2} />

            {/* mobile navigation above the fold */}
            <VStack width={LEFT_NAV_WIDTH}>
              {mobileHamburgerItems.map((props) => (
                <DrawerItem
                  key={props.title}
                  title={props.title}
                  icon={props.icon}
                  active={props.id === activePage.id}
                  onPress={() => handlePress(props)}
                />
              ))}
            </VStack>

            <Divider spacingTop={1} />
            <ProfileItems />
          </>
        )}
      </MobileDrawer>
    </>
  );
}
