import { TextBody, TextTitle3 } from '@cbhq/cds-web/typography';

type ReinforcedSecurityProps = {
  customText?: string;
};

export function ReinforcedSecurity({ customText }: ReinforcedSecurityProps) {
  const text =
    customText ||
    'We provide 24/7 monitoring and support to keep your infrastructure safe with best-in-class security architecture for key management using CloudHSM and YubiHSM hardware.';
  return (
    <div>
      <TextTitle3 spacingBottom={1} as="h3">
        Support
      </TextTitle3>
      <TextBody as="p">{text}</TextBody>
    </div>
  );
}
