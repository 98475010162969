import { useMemo, useState } from 'react';
import { Button, ButtonGroup } from '@cbhq/cds-web/buttons';
import { SelectOption } from '@cbhq/cds-web/controls';
import { Dropdown } from '@cbhq/cds-web/dropdown';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextDisplay3, TextTitle3, TextTitle4 } from '@cbhq/cds-web/typography';

import { ReactComponent as BaseDemoImg } from ':cloud/assets/Products/BaseDemo.svg';
import { ReactComponent as BaseGithubImg } from ':cloud/assets/Products/BaseGithub.svg';
import { ReactComponent as BaseQuickstartImg } from ':cloud/assets/Products/BaseQuickstart.svg';
import { PaymasterProvider } from ':cloud/contexts/PaymasterProvider';
import { useIsBasePlaygroundEnabled } from ':cloud/hooks/featureFlags/useIsBasePlaygroundEnabled';
import { EventName, useLogViewOnMount } from ':cloud/init/clientAnalytics/logging';
import {
  BASE_DEMO_APP_LINK,
  BASE_DOCS,
  CDP_BASE_DISCORD,
  PAYMASTER_BUNDLER_DOCS_LINK,
  PAYMASTER_GITHUB_LINK,
} from ':cloud/utils/links';
import { onExternalLinkPress } from ':cloud/utils/onExternalLinkPress';
import { ExampleSection, ExampleSectionItem } from ':cloud/widgets/base/ExampleSection';
import { PaymasterSection } from ':cloud/widgets/base/PaymasterSection';
import Playground from ':cloud/widgets/base/PlaygroundV2';
import { RPCEndpointSection } from ':cloud/widgets/base/RPCEndpointSection';
import { Layout } from ':cloud/widgets/layout';
import { ConfigurationItem } from ':cloud/widgets/product/ConfigurationItem';
import { baseConfig } from ':cloud/widgets/product/configurations/baseConfig';
import { FULL_PAGE_MAX_WIDTH, MAIN_CONTAINER_MIN_WIDTH } from ':cloud/widgets/product/constants';
import { KeyFeatureItem, KeyFeaturesSection } from ':cloud/widgets/product/KeyFeaturesSection';

const keyFeatures: KeyFeatureItem[] = [
  {
    iconName: 'decentralizedExchange',
    title: 'Reliable',
    description: 'Ensure continuous operation with high uptime.',
  },
  {
    iconName: 'higherLimits',
    title: 'High rate limit',
    description: 'Access up to 500M BU / month for free.',
  },
];

// eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
const imageStyle = { maxWidth: '100%', height: 'auto', width: '100%' };

const exampleItems: ExampleSectionItem[] = [
  {
    title: 'Quickstart',
    description: 'Make your first sponsored transaction with Paymaster ',
    cta: 'Paymaster docs',
    onPress: onExternalLinkPress(PAYMASTER_BUNDLER_DOCS_LINK),
    image: <BaseQuickstartImg style={imageStyle} />,
  },
  {
    title: 'Demo',
    description: 'Learn how Paymaster & Bundler works with a fully functional demo app',
    cta: 'View demo',
    onPress: onExternalLinkPress(BASE_DEMO_APP_LINK),
    image: <BaseDemoImg style={imageStyle} />,
  },
  {
    title: 'More examples',
    description: 'Integrate with 3rd party SDKs',
    cta: 'Github',
    onPress: onExternalLinkPress(PAYMASTER_GITHUB_LINK),
    image: <BaseGithubImg style={imageStyle} />,
  },
];

// this prevents odd text breakpoints (one word in a sentence on a line by itself)
const HEADER_TEXT_MAX_WIDTH = 930;

export function BaseLanding() {
  const isBasePlaygroundEnabled = useIsBasePlaygroundEnabled();

  const [selectedNetwork, setSelectedNetwork] = useState(
    baseConfig?.networkOptions?.[0].label || '',
  );

  const content = useMemo(
    () => (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {baseConfig?.networkOptions?.map((option) => (
          <SelectOption key={option.value} value={option.label} title={option.label} />
        ))}
      </>
    ),
    [],
  );

  useLogViewOnMount(EventName.node_page_view);

  return (
    <PaymasterProvider>
      <Layout enableMaxWidth={false}>
        <Layout.MainContainer
          minWidth={MAIN_CONTAINER_MIN_WIDTH}
          spacingHorizontal={0}
          spacingTop={0}
          fullWidth
        >
          <Box display="block" width="100%" minWidth="100%">
            <VStack>
              <HStack borderedBottom>
                <VStack justifyContent="center" spacing={9} maxWidth={HEADER_TEXT_MAX_WIDTH}>
                  {isBasePlaygroundEnabled ? (
                    <TextDisplay3 as="h1" spacingBottom={3}>
                      <TextDisplay3 as="span" color="primary">
                        Free
                      </TextDisplay3>{' '}
                      mainnet RPC endpoint
                    </TextDisplay3>
                  ) : (
                    <TextDisplay3 as="h1">
                      Free mainnet RPC endpoint, paymaster & bundler
                    </TextDisplay3>
                  )}
                  <TextTitle4 as="h2" color="foregroundMuted" spacingTop={1} spacingBottom={7}>
                    Read and write to the blockchain with high uptime. API access is{' '}
                    <TextTitle4 as="span" color="positive">
                      free
                    </TextTitle4>
                    , and{' '}
                    <TextTitle4 as="span" color="positive">
                      rate limited at 50 RPS
                    </TextTitle4>
                    . To request an increase in rate limits, reach out in Discord.
                  </TextTitle4>

                  <ButtonGroup>
                    <Button
                      variant="primary"
                      endIcon="diagonalUpArrow"
                      onPress={onExternalLinkPress(BASE_DOCS)}
                      accessibilityLabel="View docs + opens in new window"
                    >
                      View docs
                    </Button>
                    <Button
                      variant="secondary"
                      endIcon="diagonalUpArrow"
                      onPress={onExternalLinkPress(CDP_BASE_DISCORD)}
                      accessibilityLabel="Discord channel + opens in new window"
                    >
                      Discord channel
                    </Button>
                  </ButtonGroup>
                </VStack>
              </HStack>
              <VStack>
                <VStack maxWidth={FULL_PAGE_MAX_WIDTH} flexGrow={1} width="100%">
                  <VStack spacing={10} gap={5}>
                    <HStack gap={2} alignItems="center" justifyContent="space-between">
                      <TextTitle3 as="h1">Configuration</TextTitle3>
                      <Dropdown
                        value={selectedNetwork}
                        onChange={setSelectedNetwork}
                        content={content}
                        enableMobileModal
                      >
                        <Button compact variant="secondary" endIcon="caretDown">
                          {selectedNetwork}
                        </Button>
                      </Dropdown>
                    </HStack>
                    <RPCEndpointSection selectedNetwork={selectedNetwork} title="RPC Endpoint" />
                    {isBasePlaygroundEnabled ? (
                      <>
                        <TextTitle3 as="h1" spacingTop={6}>
                          Playground
                        </TextTitle3>
                        <ConfigurationItem
                          title="Make a request"
                          description="Try the request below or copy it into your terminal to run your first request."
                          iconName="arrowsHorizontal"
                          expandedContent={
                            <Playground
                              isTestnet={selectedNetwork !== baseConfig?.networkOptions?.[0].label}
                            />
                          }
                        />
                      </>
                    ) : (
                      <PaymasterSection />
                    )}
                  </VStack>
                </VStack>
              </VStack>
              {!isBasePlaygroundEnabled && <ExampleSection exampleItems={exampleItems} />}
              <KeyFeaturesSection features={keyFeatures} borderedTop={isBasePlaygroundEnabled} />
            </VStack>
          </Box>
        </Layout.MainContainer>
      </Layout>
    </PaymasterProvider>
  );
}
