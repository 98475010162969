import { Icon } from '@cbhq/cds-web/icons';
import { VStack } from '@cbhq/cds-web/layout';
import { TextBody } from '@cbhq/cds-web/typography';

import { WithChildren } from ':cloud/types/helper';

interface EmptyChartProps {
  message: string;
}

export function EmptyChart({ message }: EmptyChartProps & Partial<WithChildren>) {
  return (
    <VStack alignItems="center" justifyContent="center" minHeight={560}>
      <VStack height={20} alignItems="center">
        <Icon name="clockOutline" size="l" color="foreground" aria-hidden />
        <TextBody as="div" align="center" spacingTop={5}>
          {message}
        </TextBody>
      </VStack>
    </VStack>
  );
}
