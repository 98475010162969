import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { CancelAccountItemResponse } from ':cloud/types/service_proto';

async function cancelAccountItem(
  itemId: string,
  orgId: string,
): Promise<CancelAccountItemResponse> {
  const path = `/billing/v1/accounts/${orgId}/items/${itemId}:cancel`;
  const { data } = await APIClientBase.put<CancelAccountItemResponse>(path);
  return data;
}

// Only updates the canceled_at timestamp on an existing account item
export function useCancelAccountItem(orgId = '') {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (itemId: string) => cancelAccountItem(itemId, orgId),
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKey.accounts, QueryKey.accountItems, orgId]);
    },
  });
}
