import { isAxiosError } from 'axios';
import { ErrorSeverity, MiscMetadata } from '@cbhq/error-vitals-web';

import config from ':cloud/config';
import { getErrorVitalsClient } from ':cloud/init/bugsnag/init';

type RequiredErrorParams = {
  context: string;
  severity?: ErrorSeverity;
  isHandled?: boolean;
};

/**
 *
 * @param e unknown
 * @param metadata
 * context - location of the error, e.g. use_get_resources, signup_card, settings_screen. Must be snake_case and should be the same for components in the same general location.
 *
 * isHandled - isHandled means that the user is notified of an error and given a graceful way to retry
 * or go back OR the user never notices the error because it is mitigated by other logic, not that
 * it's just wrapped in a try/catch.
 *
 * severity - https://frontend.cbhq.net/errors/metadata#severity.
 * tldr:
 *
 * handled?
 * * no notification shown? warning
 * notification shown but user can try again? error
 *
 * unhandled?
 *
 * app still runs but chunks of it are missing or don't work? critical
 *
 * app cannot be used until it restarts? crash
 *
 */
export function logError(
  e: unknown,
  { context, isHandled = true, severity = 'error' }: RequiredErrorParams,
  errorMetadata?: MiscMetadata,
) {
  const eIsError = e instanceof Error;
  const error = eIsError ? e : new Error(String(e));
  if (!eIsError) {
    error.cause = e;
  }

  if (isChunkLoadingError(error)) {
    return;
  }

  const metadata = errorMetadata || {};

  if (isAxiosError(e)) {
    metadata.requestUrl = e.config?.url;
    metadata.message = e.response?.data?.message;
  }

  const client = getErrorVitalsClient();

  client.addBreadcrumb(error.message, metadata || {});

  client.logError(error, {
    context: !context || context === 'NOT_SET' ? config.appAnalyticsName : context,
    severity,
    isHandled,
    ...metadata,
  });
}

/*
 * Chunk errors originating from our own domain are connectivity issues
 * that are expected to happen from time to time and are beyond our control.
 * If we misconfigured our hosting servers, all chunks would fail and the app
 * wouldn't load, which would be reflected in synthetic tests
 */

function isChunkLoadingError(error: Error) {
  return (
    /Loading chunk .+ failed/.test(error.message) ||
    /Loading CSS chunk .+ failed/.test(error.message)
  );
}
