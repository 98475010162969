import { useMemo, useState } from 'react';
import { Button } from '@cbhq/cds-web/buttons';
import { Select, SelectOption } from '@cbhq/cds-web/controls';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Box, Grid, HStack, Spacer, VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextTitle3 } from '@cbhq/cds-web/typography';

import {
  generateAttestiationDataRequest,
  generateAttestiationDataResponse,
  generateUIDRequest,
  generateUIDResponse,
} from ':cloud/pages/Verifications/verificationsPlaygroundCode';
import { VerificationsPlaygroundSection } from ':cloud/pages/Verifications/VerificationsPlaygroundSection';
import { FULL_PAGE_MAX_WIDTH } from ':cloud/widgets/product/constants';
import { ClipboardIcon } from ':cloud/widgets/sharedcomponents';

const schemaTypeOptions = ['Verified account', 'Verified country', 'Verified Coinbase One'];
const schemaIDs = {
  'Verified account': '0xf8b05c79f090979bf4a80270aba232dff11a10d9ca55c4f88de95317970f0de9',
  'Verified country': '0x1801901fabd0e6189356b4fb52bb0ab855276d84f7ec140839fbd1f6801ca065',
  'Verified Coinbase One': '0x254bd1b63e0591fefa66818ca054c78627306f253f86be6023725a67ee6bf9f4',
};

export function VerificationsPlayground() {
  const { isDesktop } = useBreakpoints();
  const templateColumns = useMemo(() => {
    return isDesktop ? '1fr 2fr' : '1fr';
  }, [isDesktop]);

  const [schemaTypeValue, setSchemaTypeValue] = useState('Verified account');

  return (
    <VStack spacing={7} maxWidth={FULL_PAGE_MAX_WIDTH}>
      <VStack spacingHorizontal={7} gap={3}>
        <HStack justifyContent="space-between" alignItems="center" gap={3}>
          <VStack>
            <TextTitle3 as="h3">Explore the Coinbase Indexer and EAS SDK</TextTitle3>
            <TextBody as="p" color="foregroundMuted">
              Experiment and learn in our interactive sandbox
            </TextBody>
          </VStack>
          <Button variant="primary" endIcon="diagonalUpArrow" compact>
            View docs
          </Button>
        </HStack>

        <VStack gap={1} bordered borderRadius="rounded" spacingVertical={5} spacingHorizontal={7}>
          <Grid templateColumns={templateColumns} gap={3}>
            <Box flexGrow={1}>
              <Select label="Schema types" value={schemaTypeValue} onChange={setSchemaTypeValue}>
                {schemaTypeOptions.map((option) => (
                  <SelectOption key={option} value={option} title={option} />
                ))}
              </Select>
            </Box>

            <VStack gap={1} overflow="auto" flexGrow={2}>
              {isDesktop && <Spacer as="div" vertical={4} />}
              <HStack
                alignItems="center"
                borderRadius="rounded"
                background="backgroundAlternate"
                justifyContent="space-between"
                flexGrow={1}
                spacing={2}
              >
                <TextBody as="p" color="foregroundMuted" overflow="truncate">
                  {schemaIDs[schemaTypeValue]}
                </TextBody>
                <ClipboardIcon text={schemaIDs[schemaTypeValue]} iconSize="s" />
              </HStack>
            </VStack>
          </Grid>
        </VStack>

        <VerificationsPlaygroundSection
          heading="Input Schema and wallet ID to generate UID"
          subheading="UID is a unique ID that is created for every attestation onchain"
          request={generateUIDRequest}
          response={generateUIDResponse}
        />
        <VerificationsPlaygroundSection
          heading="Input UID to generate Attestation Data"
          subheading="Access attestation metadata by inputing the unique UID that you generated with the help of the schema"
          request={generateAttestiationDataRequest}
          response={generateAttestiationDataResponse}
        />
      </VStack>
    </VStack>
  );
}
