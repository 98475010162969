import { VStack } from '@cbhq/cds-web/layout/VStack';

import { TOTAL_TOP_NAV_HEIGHT } from ':cloud/widgets/navigation/constants';

import { LoadingSpinner } from './LoadingSpinner';

export function FullAppLoadingSpinner() {
  return (
    <VStack
      width="100%"
      minHeight={window.innerHeight - TOTAL_TOP_NAV_HEIGHT}
      justifyContent="center"
      alignItems="center"
      background
    >
      <LoadingSpinner />
    </VStack>
  );
}
