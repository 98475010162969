import { SpacingScale } from '@cbhq/cds-common';
import { Banner } from '@cbhq/cds-web/banner/Banner';
import { Box } from '@cbhq/cds-web/layout';
import { Pressable } from '@cbhq/cds-web/system';
import { TextHeadline } from '@cbhq/cds-web/typography';

type ContractAllowlistBannerProps = {
  spacingTop?: SpacingScale;
  isWarning?: boolean;
};
export function ContractAllowlistBanner({
  spacingTop = 4,
  isWarning = false,
}: ContractAllowlistBannerProps) {
  const scrollToAllowlist = () => {
    const element = document.querySelector('[data-testid="paymaster-allowlist-section"]');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <Box display="block" spacingTop={spacingTop}>
      <Banner
        title={isWarning ? 'Increase the global limit' : 'Add a contract to the allowlist'}
        variant={isWarning ? 'error' : 'promotional'}
        startIcon={isWarning ? 'warning' : 'info'}
        primaryAction={
          !isWarning && (
            <Pressable background="transparent" onPress={scrollToAllowlist}>
              <TextHeadline color="primary" as="p">
                Add
              </TextHeadline>
            </Pressable>
          )
        }
      >
        {isWarning
          ? '75% of the limit has been utilized. Increase the limit to avoid your service being interrupted.'
          : 'Before adjusting your gas policy, we recommend allowlisting at least 1 contract. This helps prevent unintended sponsorship, such as user operations outside of your app.'}
      </Banner>
    </Box>
  );
}
