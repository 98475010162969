import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import {
  ClientRecordNonSensitive,
  ClientRecordSensitive,
  CreateOAuthClientRequest,
  CreateOAuthClientResponse,
} from ':cloud/types/service_proto';

interface UseCreateOAuthClientOptions {
  onSuccess?: (client: ClientRecordNonSensitive, sensitiveInfo: ClientRecordSensitive) => void;
  onError?: (error: unknown, variables: CreateOAuthClientRequest) => void;
}

async function createOAuthClient(
  orgId: string,
  clientRequest: CreateOAuthClientRequest,
): Promise<CreateOAuthClientResponse | undefined> {
  const path = `/apikeys/v1/organizations/${orgId}/oauthClients`;
  const { data } = await APIClientBase.post<CreateOAuthClientResponse>(path, clientRequest);
  return data;
}

export function useCreateOAuthClient(orgId = '', options: UseCreateOAuthClientOptions = {}) {
  const { onSuccess, onError } = options;
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (clientRequest: CreateOAuthClientRequest) =>
      createOAuthClient(orgId, clientRequest),
    onSuccess: async (newClient) => {
      if (!newClient) return;
      await queryClient.invalidateQueries([QueryKey.oauth, orgId]);
      onSuccess?.(newClient.clientInfo, newClient.clientRecordSensitive);
    },
    onError: (error, variables) => {
      onError?.(error, variables);
    },
  });
}
