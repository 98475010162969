import { useEffect } from 'react';

import { APIClientRM } from ':cloud/state/Api';

export function SSORedirect(): null {
  useEffect(() => {
    async function getZendeskSSORedirect(returnTo = ''): Promise<string> {
      const path = `/v1/help/sso?return_to=${returnTo}`;
      const { data } = await APIClientRM.get<{ redirect_url: string }>(path);

      return data.redirect_url;
    }

    async function fetchSSORedirect(): Promise<void> {
      // Get return_to paramater
      const urlParams = new URLSearchParams(window.location.search);
      const returnTo = urlParams.get('return_to') || '';

      const redirectURL = await getZendeskSSORedirect(returnTo);

      window.location.href = redirectURL;
    }
    void fetchSSORedirect();
  }, []);

  return null;
}
