import axios from 'axios';

export function getErrorMessage(
  e: unknown,
  errorMessage: string,
  passThroughServerErr = false,
): string {
  if (passThroughServerErr || !axios.isAxiosError(e)) {
    return errorMessage;
  }

  return (e?.response?.data as { message?: string })?.message || errorMessage;
}

export class CustomNetworkError extends Error {
  statusCode: number;

  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
  }
}
