import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _omitBy from 'lodash/omitBy';
import _startCase from 'lodash/startCase';
import { Grid, VStack } from '@cbhq/cds-web/layout';
import { Link, TextTitle1 } from '@cbhq/cds-web/typography';

import { ICluster, IConfiguration } from ':cloud/types/ts_types';
import { flatten, shouldExcludeKey } from ':cloud/utils/common';
import { threeColumnGrid, twoColumnGrid } from ':cloud/utils/responsiveConfigs';
import { Layout } from ':cloud/widgets/layout';
import { DisplayUnit, TextLabelGray } from ':cloud/widgets/sharedcomponents';
import { CloudErrorBoundary } from ':cloud/widgets/sharedcomponents/CloudErrorBoundary';

interface ClusterSettingsProps {
  configuration: IConfiguration;
  nodes?: ICluster['nodes'];
  cluster: ICluster;
}

export function ClusterSettings({ configuration, cluster }: ClusterSettingsProps) {
  const configVals = flatten(_omitBy(configuration, (_, k) => shouldExcludeKey(k)));
  let artifactVals: { label: string; value?: string }[] = [];
  if (cluster.artifacts) {
    artifactVals = [
      {
        label: 'Public Address',
        value: cluster.artifacts.endpoint || cluster.artifacts.eth,
      },
      {
        label: 'RPC Port',
        value: cluster.artifacts.rpcPort,
      },
      {
        label: 'WS Port',
        value: cluster.artifacts.wsPort,
      },
    ].filter((value) => !value);
  }

  if (_isEmpty(configVals) && _isEmpty(artifactVals)) {
    return null;
  }

  return (
    <Layout.ContentWrapper>
      <TextTitle1 as="h2" spacingBottom={5}>
        Cluster settings
      </TextTitle1>
      <CloudErrorBoundary name="ClusterArtifacts">
        <Grid gap={3} responsiveConfig={twoColumnGrid}>
          {_map(configVals, (value, label) => {
            return <DisplayUnit isCard label={_startCase(label)} value={value} key={label} />;
          })}
          {_map(artifactVals, ({ label, value }) => {
            return <DisplayUnit isCard label={label} value={value} key={label} />;
          })}
        </Grid>
        {cluster.artifacts?.explorer && (
          <Grid gap={3} responsiveConfig={threeColumnGrid} spacingTop={3}>
            <VStack spacing={5} justifyContent="space-between">
              <Link to={cluster.artifacts.explorer} openInNewWindow rel="noopener noreferrer">
                Details
              </Link>
              <TextLabelGray spacingBottom={0.5}>{`${cluster.type} Explorer`}</TextLabelGray>
            </VStack>
          </Grid>
        )}
      </CloudErrorBoundary>
    </Layout.ContentWrapper>
  );
}
