import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import {
  DeletePlatformAPIKeyRequest,
  DeletePlatformAPIKeyResponse,
} from ':cloud/types/service_proto';

const errorMsg = 'An error occurred deleting your API key. Please retry';
const successMsg = 'API Key successfully deleted';

async function deleteKey(orgId: string, tokenId: string): Promise<DeletePlatformAPIKeyResponse> {
  const path = `/apikeys/v1/organizations/${orgId}/apiKeys/${tokenId}`;
  const body: DeletePlatformAPIKeyRequest = {
    name: `organizations/${orgId}/apiKeys/${tokenId}`,
  };
  const data = await APIClientBase.delete<DeletePlatformAPIKeyResponse>(
    path,
    body as AxiosRequestConfig,
  );
  return data;
}

export function useDeleteApiKey(orgId = '', onSuccess?: () => void) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (tokenId: string) => deleteKey(orgId, tokenId),
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKey.apiKeys]);
      onSuccess?.();
    },
    meta: {
      errorMsg,
      successMsg,
    },
  });
}
