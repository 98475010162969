import { useHistory } from 'react-router-dom';
import { SpotIcon } from '@cbhq/cds-web/illustrations';
import { Grid, VStack } from '@cbhq/cds-web/layout';
import { Pressable } from '@cbhq/cds-web/system';
import { TextLabel2, TextTitle3 } from '@cbhq/cds-web/typography';

import { useCardProducts } from ':cloud/hooks/useCardProducts';
import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';

const cardMaxWidth = 350;
const cardMinWidth = 160;

function MultiProductCard({ onPress, title, description, header }) {
  return (
    <VStack
      background="backgroundAlternate"
      borderRadius="roundedXLarge"
      maxWidth={cardMaxWidth}
      minWidth={cardMinWidth}
      height={156}
      spacing={2}
    >
      <Pressable background="transparent" onPress={onPress} noScaleOnPress height="100%">
        <VStack justifyContent="space-between" height="100%">
          {header}
          <VStack>
            <TextTitle3 as="h1">{title}</TextTitle3>
            <TextLabel2 as="p" color="foregroundMuted">
              {description}
            </TextLabel2>
          </VStack>
        </VStack>
      </Pressable>
    </VStack>
  );
}

export function MultiProductCards() {
  const history = useHistory();
  const filteredProducts = useCardProducts();
  const { isDesktop } = useSimpleBreakpoints();
  return (
    <Grid
      templateColumns={`repeat(${isDesktop ? 4 : 2}, minmax(${cardMinWidth}px, ${cardMaxWidth}px)`}
      gap={2}
      justifyContent="flex-start"
    >
      {filteredProducts.map((card) => (
        <MultiProductCard
          key={card.title}
          title={card.title}
          description={card.description}
          header={<SpotIcon name={card.spotIconName} />}
          onPress={() => history.push(card.linkToRoute)}
        />
      ))}
    </Grid>
  );
}
