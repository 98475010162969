import { getUserContext } from '@cbhq/client-analytics';
import { Client, ErrorSeverity, init } from '@cbhq/error-vitals-web';

import config from ':cloud/config';

let errorVitalsClient: Client;

export function initBugsnag() {
  if (!errorVitalsClient) {
    errorVitalsClient = init({
      appName: config.appAnalyticsName,
      apiKey: config.bugsnagKey,
      environment: config.environmentName,
      appVersion: config.appVersion,
      useGlobalHandlers: true,
      getUserContext,
    });
  }
}

export function getErrorVitalsClient() {
  if (!errorVitalsClient) {
    initBugsnag();
  }

  return errorVitalsClient;
}

export const errorVitalsClientContext = { client: getErrorVitalsClient() };

export const defaultErrorMetadata = {
  context: config.appAnalyticsName,
  severity: 'error' as ErrorSeverity,
  isHandled: true,
};
