import { useState } from 'react';
import { css, cx } from '@linaria/core';
import { IconButton } from '@cbhq/cds-web/buttons';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextTitle3 } from '@cbhq/cds-web/typography';

import { wordBreakWord } from ':cloud/brand/utilityClasses';
import { IAPIToken, IAPITokenPermTypeLabelMap } from ':cloud/types/ts_types';
import { formatDate } from ':cloud/utils/date';
import { TextLabelGray } from ':cloud/widgets/sharedcomponents';
import { RevokeTokenDialog } from ':cloud/widgets/tokens/api/RevokeTokenDialog';

const tokenNameStyles = css`
  line-height: 29px;
  letter-spacing: 0.44px;
`;

interface TokenCardProps {
  token: IAPIToken;
  renderServiceLabel: (service: string) => string;
}

/**
 * @deprecated Only used in LegacyAPITokenManagement.
 */
export function TokenCard({ token, renderServiceLabel }: TokenCardProps) {
  const [showRevokeDialog, setShowRevokeDialog] = useState<boolean>(false);

  return (
    <VStack
      position="relative"
      testID={`token-card-${token.name}`}
      spacing={5}
      bordered
      borderRadius="rounded"
      minHeight={220}
      justifyContent="space-between"
    >
      <div>
        <TextTitle3
          as="h2"
          spacingBottom={3}
          testID="token-card-token-name"
          className={cx(tokenNameStyles, wordBreakWord)}
        >
          {token.name}
        </TextTitle3>
        <TextBody as="p" color="foregroundMuted">
          {token.services
            .map((sp) => {
              return `${renderServiceLabel(sp.name)} (${sp.permissions
                .map((p) => IAPITokenPermTypeLabelMap[p])
                .join(', ')})`;
            })
            .join(', ')}
        </TextBody>
      </div>
      <HStack justifyContent="space-between" alignItems="center">
        <TextLabelGray>
          Generated {formatDate(new Date(token.createdDate), 'MMM d, yyyy')}
        </TextLabelGray>
        <IconButton
          name="trashCan"
          onClick={(): void => setShowRevokeDialog(true)}
          data-testid={`token-card-${token.guid}-revoke-button`}
        />
      </HStack>
      <RevokeTokenDialog
        visible={showRevokeDialog}
        closeFn={(): void => {
          setShowRevokeDialog(false);
        }}
        token={token}
      />
    </VStack>
  );
}
