/* eslint-disable camelcase */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { VStack } from '@cbhq/cds-web/layout';

import { useAppState } from ':cloud/contexts/AppStateContext';
import { usePaymasterContext } from ':cloud/contexts/PaymasterProvider';
import { useFindOrCreateToken } from ':cloud/hooks/useFindOrCreateToken';
import { EventName, logClick } from ':cloud/init/clientAnalytics/logging';
import { useBaseRPC } from ':cloud/queries/Base/useBaseRPC';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { BaseCodeResponse, CodeBlockLanguage } from ':cloud/types/ts_types';

import { getRPCEndpointURL } from '../helpers';
import { CloudErrorBoundary } from '../sharedcomponents/CloudErrorBoundary';
import { CodeBlock } from '../sharedcomponents/CodeBlock';
import { ErrorView } from '../sharedcomponents/ErrorView';

const codeResponseCustomStyle = {
  padding: '25px 25px',
  background: 'var(--secondary)',
  color: 'rgb(var(--gray60))',
  maxHeight: '500px',
  overflow: 'auto',
};

type PlaygroundProps = {
  isTestnet: boolean;
  requestID?: string;
  method?: string;
};

const TEST_ADDRESS = '0x5ff137d4b0fdcd49dca30c7cf57e578a026d2789';
const MAINNET_ASSET_ID = '318b5de8-a2ff-5ab8-b040-fbe18cd32472';
const TESTNET_ASSET_ID = 'dad6ec1f-4c0a-5536-8237-0c2e4f9bd10f';

const WALLET_HISTORY_PARAMS = [
  {
    address: TEST_ADDRESS,
    pageToken: '',
    pageSize: 1,
  },
];

const EVENT_NAME_MAP = {
  eth_supportedEntryPoints: EventName.bundler_playground_run_click,
  pm_getPaymasterStubData: EventName.paymaster_playground_run_click,
  eth_blockNumber: EventName.base_playground_run_click,
  cdp_listBalances: EventName.cdp_listBalances_run_click,
  cdp_listBalanceDetails: EventName.cdp_listBalanceDetails_run_click,
  cdp_listBalanceHistories: EventName.cdp_listBalanceHistories_run_click,
  cdp_listAddressTransactions: EventName.cdp_listAddressTransactions_run_click,
};

function Playground({ isTestnet, method, requestID = 'BaseRPC' }: PlaygroundProps) {
  const [codeResponse, setCodeResponse] = useState<BaseCodeResponse>();

  const { selectedProject } = useAppState();
  const { activeOrg } = useGetUser();
  const { token } = useFindOrCreateToken(
    activeOrg?.organizationId,
    selectedProject?.id,
    selectedProject?.type,
    selectedProject?.isTestnet,
  );

  const { setCompletedStep } = usePaymasterContext();

  useEffect(() => {
    // reset code response when requestID changes
    if (codeResponse) {
      setCodeResponse(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- prevent rerender
  }, [requestID, isTestnet, method]);

  const rpcEndpointUrl = getRPCEndpointURL(token?.id, isTestnet);

  const paymasterRequestParams = useMemo(() => {
    const chainId = isTestnet ? '0x14a34' : '0x2105';
    return [
      {
        sender: '0x0000000000000000000000000000000000000000',
        nonce: '0x0',
        initCode: '0x',
        callData: '0x',
        callGasLimit: '0x0',
        verificationGasLimit: '0x0',
        preVerificationGas: '0x0',
        maxFeePerGas: '0x0',
        maxPriorityFeePerGas: '0x0',
        paymasterAndData: '0x',
        signature: '0x',
      },
      TEST_ADDRESS,
      chainId,
      {},
    ];
  }, [isTestnet]);
  const walletHistoryWithAssetParams = useMemo(() => {
    const assetId = isTestnet ? TESTNET_ASSET_ID : MAINNET_ASSET_ID;
    return [
      {
        address: TEST_ADDRESS,
        assetId,
        pageToken: '',
        pageSize: 1,
      },
    ];
  }, [isTestnet]);

  const requestMethod = useMemo(() => {
    if (method) {
      return method;
    }
    if (requestID === 'Bundler') {
      return 'eth_supportedEntryPoints';
    }
    if (requestID === 'Paymaster') {
      return 'pm_getPaymasterStubData';
    }
    return 'eth_blockNumber';
  }, [method, requestID]);

  const baseRPCMutation = useBaseRPC({
    requestMethod,
    onRequestComplete: setCodeResponse,
  });

  const requestParams = useMemo(() => {
    if (requestMethod === 'pm_getPaymasterStubData') {
      return paymasterRequestParams;
    }
    if (requestMethod === 'cdp_listBalances' || requestMethod === 'cdp_listAddressTransactions') {
      return WALLET_HISTORY_PARAMS;
    }
    if (
      requestMethod === 'cdp_listBalanceDetails' ||
      requestMethod === 'cdp_listBalanceHistories'
    ) {
      return walletHistoryWithAssetParams;
    }
    return [];
  }, [paymasterRequestParams, requestMethod, walletHistoryWithAssetParams]);

  const rpcCodeCommand = useMemo(() => {
    if (!rpcEndpointUrl) return 'Loading...';
    return `curl -s ${rpcEndpointUrl}\n -H "Content-Type: application/json"\n -d '{"jsonrpc": "2.0", "id": 1, "method": "${requestMethod}", "params": ${JSON.stringify(
      requestParams,
    )}}'`;
  }, [requestMethod, requestParams, rpcEndpointUrl]);

  const rpcCode = useMemo(() => {
    return [
      {
        language: 'bash' as CodeBlockLanguage,
        code: rpcCodeCommand,
      },
    ];
  }, [rpcCodeCommand]);

  const rpcCodeResponse = useMemo(
    () => [
      {
        language: 'bash' as CodeBlockLanguage,
        code: codeResponse?.code || '',
      },
    ],
    [codeResponse],
  );

  const handleClickRun = useCallback(() => {
    const event = EVENT_NAME_MAP[requestMethod];
    if (event) {
      logClick(event);
    }

    setCompletedStep('playground');

    baseRPCMutation.mutate({ tokenId: token?.id, isTestnet, requestParams });
  }, [baseRPCMutation, isTestnet, requestMethod, requestParams, setCompletedStep, token?.id]);

  return (
    <CloudErrorBoundary name="Playground" fallback={<ErrorView />}>
      <VStack gap={5}>
        <VStack gap={5} spacingHorizontal={5}>
          <CodeBlock
            codeArr={rpcCode}
            showLanguageSelect={false}
            showCopy
            showRun
            onRun={handleClickRun}
            enableLineWrap
            title="Request"
          />
          <CodeBlock
            title="Response"
            codeArr={rpcCodeResponse}
            responseStatus={codeResponse?.responseStatus}
            showLanguageSelect={false}
            customStyle={codeResponseCustomStyle}
            headerBackground="var(--secondary)"
            onRun={handleClickRun}
            displayDarkTheme={false}
            showLineNumbers={false}
            dangerouslySetColor="foreground"
            showCopy={false}
            enableLineWrap
            placeholder={
              codeResponse?.code ? '' : 'Click "Run" to start a request and see the response here!'
            }
            minHeight={227}
          />
        </VStack>
      </VStack>
    </CloudErrorBoundary>
  );
}

export default Playground;
