import { ReactNode, useMemo, useState } from 'react';

import { ClientRecordNonSensitive } from ':cloud/types/service_proto';

import { OAuthInfoContext, SensitiveInfo } from './OAuthInfoContext';

interface OAuthInfoProviderProps {
  children: ReactNode;
}

export default function OAuthInfoProvider({ children }: OAuthInfoProviderProps) {
  const [sensitiveInfo, setSensitiveInfo] = useState<SensitiveInfo | null>(null);
  const [selectedClient, setSelectedClient] = useState<ClientRecordNonSensitive | null>(null);

  const value = useMemo(() => {
    return {
      sensitiveInfo,
      setSensitiveInfo,
      selectedClient,
      setSelectedClient,
    };
  }, [sensitiveInfo, selectedClient]);

  return <OAuthInfoContext.Provider value={value}>{children}</OAuthInfoContext.Provider>;
}
