import { useMemo } from 'react';
import { VStack } from '@cbhq/cds-web/layout';
import { TextTitle3 } from '@cbhq/cds-web/typography';

import { usePaymasterContext } from ':cloud/contexts/PaymasterProvider';
import { useIsBillingUsageEnabled } from ':cloud/hooks/featureFlags/useIsBillingUsageEnabled';
import { EventName, logClick, useLogViewOnMount } from ':cloud/init/clientAnalytics/logging';
import { PaymentMethod } from ':cloud/types/service_proto';
import { GasPolicy } from ':cloud/types/ts_types';
import { PAYMASTER_GUIDE_DOCS_URL } from ':cloud/utils/links';
import { openUrlInNewWindow } from ':cloud/utils/openUrlInNewWindow';
import { AppRoute } from ':cloud/utils/routes';

import { FULL_PAGE_MAX_WIDTH } from '../product/constants';

import { PaymasterGettingStartedItem } from './PaymasterGettingStartedItem';

type PaymasterGettingStartedProps = {
  gasPolicy?: GasPolicy;
  paymentMethod?: PaymentMethod;
};

export function PaymasterGettingStarted({
  gasPolicy,
  paymentMethod,
}: PaymasterGettingStartedProps) {
  const { hasCompletedStep } = usePaymasterContext();
  const isBillingUsageEnabled = useIsBillingUsageEnabled();

  const billingUrl = useMemo(() => {
    if (isBillingUsageEnabled) {
      return `${AppRoute.Billing.Details}?view=payment`;
    }
    return `${AppRoute.Billing.Home}?view=payment`;
  }, [isBillingUsageEnabled]);

  useLogViewOnMount(EventName.paymaster_getting_started_guide_view);

  return (
    <VStack borderedTop maxWidth="100%" alignItems="flex-start">
      <VStack gap={3} spacing={9} maxWidth={FULL_PAGE_MAX_WIDTH} width="100%">
        <TextTitle3 as="h1">Get started</TextTitle3>
        <VStack gap={3}>
          <PaymasterGettingStartedItem
            stepNumber="1"
            title="Try it out on Playground"
            description="Use the Playground to make a request and see the response."
            cta="Try"
            onPress={() => {
              openUrlInNewWindow(`${AppRoute.Products.BundlerAndPaymaster}?tab=playground`);
              logClick(EventName.paymaster_playground_step_click);
            }}
            completed={hasCompletedStep('playground')}
          />
          <PaymasterGettingStartedItem
            stepNumber="2"
            title="Allowlist contract(s)"
            description="Restrict gas sponsorship to a list of smart contracts. Optionally add specific functions of the contract to allowlist."
            cta="Add"
            onPress={() => {
              openUrlInNewWindow(
                `${AppRoute.Products.BundlerAndPaymaster}?tab=configuration&section=allowlist`,
              );
              logClick(EventName.paymaster_contract_step_click);
            }}
            completed={!!gasPolicy?.contractAllowlist?.length || hasCompletedStep('allowlist')}
          />
          <PaymasterGettingStartedItem
            stepNumber="3"
            title="Set gas limits"
            description="Set the maximum amount you are willing to sponsor globally, per user, and/or per User Operation."
            cta="Set"
            onPress={() => {
              openUrlInNewWindow(
                `${AppRoute.Products.BundlerAndPaymaster}?tab=configuration&section=gasLimit`,
              );
              logClick(EventName.paymaster_gas_step_click);
            }}
            completed={hasCompletedStep('gas')}
          />
          <PaymasterGettingStartedItem
            stepNumber="4"
            title="Customize sponsor label"
            description="Set what users see in their transaction screen."
            cta="Set"
            onPress={() => {
              openUrlInNewWindow(
                `${AppRoute.Products.BundlerAndPaymaster}?tab=configuration&section=sponsor`,
              );
              logClick(EventName.paymaster_sponsor_step_click);
            }}
            completed={!!gasPolicy?.paymasterSponsorName || hasCompletedStep('sponsor')}
          />
          <PaymasterGettingStartedItem
            stepNumber="5"
            title="Add a payment method"
            description="Get $500 in credits by adding a payment method. This ensures uninterrupted service beyond your credit balance."
            cta="Add"
            onPress={() => {
              openUrlInNewWindow(billingUrl);
              logClick(EventName.paymaster_payment_step_click);
            }}
            completed={!!paymentMethod || hasCompletedStep('payment')}
          />
          <PaymasterGettingStartedItem
            stepNumber="6"
            title="Send your first transaction"
            description="Submit your first smart wallet transaction on Base Sepolia with gas sponsorship."
            cta="Docs"
            onPress={() => {
              openUrlInNewWindow(PAYMASTER_GUIDE_DOCS_URL);
              logClick(EventName.paymaster_transaction_step_click);
            }}
            completed={hasCompletedStep('transaction')}
          />
        </VStack>
      </VStack>
    </VStack>
  );
}
