import { useMemo } from 'react';

import { useAppState } from ':cloud/contexts/AppStateContext';
import { useDashboardContext } from ':cloud/contexts/DashboardProvider';
import { useGetChartData } from ':cloud/queries/MonitoringQueries/useGetChartData';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import {
  ChartBodyProps,
  ChartData,
  MetricDataPoint,
  TransformedMetricDataPoint,
} from ':cloud/widgets/monitoring/types';
import {
  cumulativeDataPointsByInterval,
  getEndTime,
  getScope,
  getStartTime,
  mapServiceToProduct,
  mergeByGroup,
} from ':cloud/widgets/monitoring/utils';
import { EmptyChart } from ':cloud/widgets/sharedcomponents/EmptyChart';

import { LoadingSpinner } from '../sharedcomponents';

import { MonitoringAreaChart } from './monitoringCharts/MonitoringAreaChart';
import { MonitoringBarChart } from './monitoringCharts/MonitoringBarChart';
import { MonitoringLineChart } from './monitoringCharts/MonitoringLineChart';
import { RANGE_INTERVAL_MAP } from './monitoringConfig';

export function ChartBody({
  chartType,
  metricName,
  aggregationMethod,
  groupBy,
  filterBy,
  unit,
  range,
  showOverlay = true,
}: ChartBodyProps) {
  const { range: contextRange } = useDashboardContext();
  const { activeOrg } = useGetUser();
  const { selectedProject } = useAppState();

  const startTime = useMemo(() => getStartTime(range || contextRange), [range, contextRange]);
  const endTime = useMemo(() => getEndTime(range || contextRange), [range, contextRange]);

  const { data, isLoading } = useGetChartData(
    {
      metricName,
      aggregationMethod,
      groupBy,
      startTime,
      endTime,
      filterBy: filterBy || [],
      scope: getScope(metricName),
    },
    activeOrg?.organizationId || '',
    selectedProject?.id || '',
  );

  let groupSegmentNames = useMemo(() => [unit], [unit]);

  // Leaving data transforms in this parent component vs the charts themselves because
  // users will eventually be able to switch chart types on the fly.
  const cumulativeData = useMemo((): ChartData[] => {
    const intervalMinutes = RANGE_INTERVAL_MAP[chartType][range || contextRange];
    return (
      data?.map((chartData) => {
        return {
          ...chartData,
          dataPoints: cumulativeDataPointsByInterval(chartData.dataPoints, intervalMinutes),
        };
      }) || []
    );
  }, [data, chartType, range, contextRange]);

  if (isLoading) return <LoadingSpinner />;

  if (!isLoading && !data) {
    throw new Error('Invalid response');
  }

  // No data available in the BE yet
  if (!isLoading && data?.length === 0) {
    return (
      <EmptyChart chartType={chartType} showOverlay={showOverlay} range={range || contextRange} />
    );
  }

  // If there is no groupBy param, key the values by unit
  let chartData = cumulativeData[0].dataPoints.map((obj: MetricDataPoint) => {
    const { value, ...rest } = obj;
    return { ...rest, [unit]: value };
  }) as TransformedMetricDataPoint[];

  if (groupBy.length > 0) {
    // We're assuming a 1-dimensional grouping for now, but this can be generalized
    const groupByTag = groupBy[0];

    // Transform multiple grouped data array responses into a single array that
    // recharts can use consume for stacked charts
    ({ chartData, groupSegmentNames } = mergeByGroup({
      data: cumulativeData,
      groupBy: groupByTag,
    }));

    if (groupBy[0] === 'target_service') {
      ({ chartData, groupSegmentNames } = mapServiceToProduct(chartData));
    }
  }

  const chartMapper = {
    line: <MonitoringLineChart data={chartData} segments={groupSegmentNames} unit={unit} />,
    bar: (
      <MonitoringBarChart
        range={range}
        data={chartData}
        segments={groupSegmentNames}
        unit={unit}
        metricName={metricName}
      />
    ),
    area: <MonitoringAreaChart data={chartData} segments={groupSegmentNames} unit={unit} />,
  };

  return chartMapper[chartType];
}
