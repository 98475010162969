import { useCallback, useMemo } from 'react';
import { Button, ButtonGroup, ButtonProps } from '@cbhq/cds-web/buttons';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { BoxProps, Grid, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextDisplay2, TextTitle4 } from '@cbhq/cds-web/typography';

import { FULL_PAGE_MAX_WIDTH } from ':cloud/widgets/product/constants';

type LandingPageHeroProps = {
  heading: string | React.ReactNode;
  subheading: string;
  primaryCTA: ButtonProps;
  secondaryCTA: ButtonProps;
  rightRailContent: React.ReactNode;
} & BoxProps;
export function LandingPageHero({
  heading,
  subheading,
  primaryCTA,
  secondaryCTA,
  rightRailContent,
  ...boxProps
}: LandingPageHeroProps) {
  const { isDesktop } = useBreakpoints();
  const headerResponsiveTemplateColumns = useMemo(() => {
    return isDesktop ? '3fr 1fr' : '1fr';
  }, [isDesktop]);

  const handlePrimaryCTAPress = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      primaryCTA?.onPress?.(e);
    },
    [primaryCTA],
  );

  const handleSecondaryCTAPress = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      secondaryCTA?.onPress?.(e);
    },
    [secondaryCTA],
  );

  return (
    <HStack borderedBottom {...boxProps}>
      <Grid
        maxWidth={FULL_PAGE_MAX_WIDTH}
        gap={9}
        spacing={9}
        spacingVertical={0}
        templateColumns={headerResponsiveTemplateColumns}
      >
        <VStack justifyContent="center">
          <TextDisplay2 as="h1">{heading}</TextDisplay2>
          <TextTitle4 as="h2" color="foregroundMuted" spacingTop={5} spacingBottom={7}>
            {subheading}
          </TextTitle4>

          <ButtonGroup>
            <Button
              variant="primary"
              endIcon={primaryCTA.endIcon ?? 'diagonalUpArrow'}
              onPress={handlePrimaryCTAPress}
              accessibilityLabel={primaryCTA.accessibilityLabel}
            >
              {primaryCTA.children}
            </Button>
            <Button
              variant="secondary"
              endIcon={secondaryCTA.endIcon ?? 'diagonalUpArrow'}
              onPress={handleSecondaryCTAPress}
              accessibilityLabel={secondaryCTA.accessibilityLabel}
            >
              {secondaryCTA.children}
            </Button>
          </ButtonGroup>
        </VStack>
        {rightRailContent}
      </Grid>
    </HStack>
  );
}
