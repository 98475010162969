import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBaseRPC } from ':cloud/state/Api';
import { BaseCodeResponse } from ':cloud/types/ts_types';

type BaseRPCResponse = {
  jsonrpc: string;
  id: number;
  result: string;
};

type BaseRPCRequest = {
  tokenId: string;
  requestMethod: string;
  isTestnet?: boolean;
  requestParams?: any[];
};

async function baseRPC({
  tokenId,
  requestMethod,
  isTestnet = false,
  requestParams = [],
}: BaseRPCRequest): Promise<BaseRPCResponse> {
  const path = `/rpc/v1/${isTestnet ? 'base-sepolia' : 'base'}/${tokenId}`;

  const requestBody = {
    jsonrpc: '2.0',
    id: 1,
    method: requestMethod,
    params: requestParams,
  };
  const { data } = await APIClientBaseRPC.post<BaseRPCResponse>(path, requestBody, {
    withCredentials: false,
  });

  return data;
}

type BaseRPCMutate = {
  tokenId: string;
  isTestnet: boolean;
  requestParams: any[];
};

type BaseRPCProps = {
  requestMethod: string;
  onRequestComplete: (response: BaseCodeResponse) => void;
};

export function useBaseRPC({ requestMethod, onRequestComplete }: BaseRPCProps) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ tokenId, isTestnet, requestParams }: BaseRPCMutate) => {
      return baseRPC({ tokenId, requestMethod, isTestnet, requestParams });
    },
    onSuccess: async (data) => {
      queryClient.setQueryData([QueryKey.baseRPC, requestMethod], data);
      onRequestComplete?.({ code: JSON.stringify(data), responseStatus: 200 });
    },
    onError: (error: AxiosError) => {
      onRequestComplete?.({
        code: JSON.stringify(error?.response?.data),
        responseStatus: error?.response?.status,
      });
    },
  });
}
