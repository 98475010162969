import { CSSProperties, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { zIndex } from '@cbhq/cds-common/tokens/zIndex';
import { Divider, VStack } from '@cbhq/cds-web/layout';
import { Sidebar } from '@cbhq/cds-web/navigation/Sidebar';
import { SidebarItem } from '@cbhq/cds-web/navigation/SidebarItem';
import { Pressable } from '@cbhq/cds-web/system';
import { TextCaption } from '@cbhq/cds-web/typography';

import CloudLogoWordmark from ':cloud/assets/Brand/CDPLogoWordmark';
import { NavItem, NavItemId, platformAPIParentNavItems } from ':cloud/config/navigation';
import { useAppState } from ':cloud/contexts/AppStateContext';
import { useNavigationContext } from ':cloud/contexts/NavigationContext';
import { useGetNavMenuItems } from ':cloud/hooks/useGetNavMenuItems';
import { EventName, logClick } from ':cloud/init/clientAnalytics/logging';
import { openUrlInNewWindow } from ':cloud/utils/openUrlInNewWindow';
import { AppRoute } from ':cloud/utils/routes';
import { hideOnMobileConfig, LEFT_NAV_WIDTH } from ':cloud/widgets/layout/constants';
import { ProjectSelector } from ':cloud/widgets/projects/ProjectSelector';
import { Flyout } from ':cloud/widgets/sharedcomponents/Flyout/Flyout';

import { SidebarItemWithFlyoutMenu } from './SidebarItemWithFlyoutMenu';
import { getChildNavItems } from './utils';

const hideOverflowXStyle: CSSProperties = { overflowX: 'hidden' };

export function LeftNav() {
  const { selectedProject } = useAppState();
  const history = useHistory();
  const { activePage } = useNavigationContext();
  const { displayedPrimaryNavItems, displayedPlatformAPINavItems, displayedProductAPINavItems } =
    useGetNavMenuItems();

  const primaryItems = displayedPrimaryNavItems;
  const dashboardRoute = displayedPrimaryNavItems.find((item) => item.id === 'project-dashboard');

  const getIsItemActive = useCallback(
    (id) => {
      if (id === activePage.parentId) return true;
      if (id === activePage.id) return true;
      if (id === 'dashboard' && activePage.id === ('projects' as NavItemId)) return true;
      return false;
    },
    [activePage],
  );

  const handlePress = useCallback(
    (navRoot: NavItem) => {
      const activeRoute = [
        ...displayedPrimaryNavItems,
        ...displayedPlatformAPINavItems,
        ...displayedProductAPINavItems,
      ].find((item) => item.id === navRoot.id);
      logClick(EventName.left_nav, { target: activeRoute?.title });
      if (!activeRoute) {
        return;
      }
      if (activeRoute.to?.startsWith('http')) {
        openUrlInNewWindow(activeRoute.to);
      } else if (navRoot.id === 'project-dashboard' && selectedProject) {
        history.push(`${AppRoute.Projects.Home}/${selectedProject.id}`);
      } else {
        history.push(activeRoute.to || AppRoute.Home);
      }
    },
    [
      displayedPrimaryNavItems,
      history,
      displayedPlatformAPINavItems,
      displayedProductAPINavItems,
      selectedProject,
    ],
  );

  // Child items can be dynamic based on user's permissions
  const childNavItemsByParentId = useMemo(
    () =>
      platformAPIParentNavItems.reduce(
        (acc, parentItem) => {
          acc[parentItem.id] = getChildNavItems(parentItem, displayedPlatformAPINavItems, history);
          return acc;
        },
        {} as Record<string, (JSX.Element | null)[]>,
      ),
    [displayedPlatformAPINavItems, history],
  );

  const platformAPIsSection = useMemo(() => {
    return (
      <>
        <VStack spacingHorizontal={2} spacingTop={2}>
          <TextCaption color="foregroundMuted" as="p">
            CDP APIs
          </TextCaption>
        </VStack>
        <VStack width="100%" spacingVertical={2}>
          {platformAPIParentNavItems.map((parentItem) => (
            <SidebarItemWithFlyoutMenu
              parentActive={getIsItemActive(parentItem.id)}
              key={`sidebar-item--${parentItem.title}`}
              parentNavItem={parentItem}
              flyoutContent={childNavItemsByParentId[parentItem.id]}
            />
          ))}
        </VStack>
      </>
    );
  }, [childNavItemsByParentId, getIsItemActive]);

  const productAPIsSection = useMemo(() => {
    return (
      <>
        <VStack spacingHorizontal={2} spacingTop={2}>
          <TextCaption color="foregroundMuted" as="p">
            Product APIs
          </TextCaption>
        </VStack>
        <VStack width="100%" spacingVertical={2}>
          {displayedProductAPINavItems.map((item) => {
            return (
              <Flyout
                key={`sidebar-item--${item.title}`}
                data-testid={`sidebar-item--${item.id}`}
                content={item.detail}
                gap={1}
              >
                <SidebarItem
                  {...item}
                  active={getIsItemActive(item.id)}
                  onPress={() => handlePress(item)}
                  as="button"
                />
              </Flyout>
            );
          })}
        </VStack>
      </>
    );
  }, [getIsItemActive, handlePress, displayedProductAPINavItems]);

  return (
    <VStack
      minWidth={LEFT_NAV_WIDTH}
      alignItems="center"
      justifyContent="center"
      zIndex={zIndex.navigation}
      responsiveConfig={hideOnMobileConfig}
      height="100vh"
      overflow="auto"
    >
      <Sidebar
        //  @ts-expect-error -- CDS types are incorrect
        position="relative"
        width="100%"
        logo={
          <Pressable
            as="a"
            onPress={() => handlePress(dashboardRoute as NavItem)}
            background="transparent"
          >
            <CloudLogoWordmark />
          </Pressable>
        }
        style={hideOverflowXStyle}
        testID="side-navigation"
      >
        <VStack spacingStart={2} gap={1}>
          <ProjectSelector />
          <Divider offsetHorizontal={6} />
        </VStack>
        <VStack width="100%" spacingTop={1} spacingBottom={2}>
          {primaryItems.map((item) => {
            return (
              <VStack key={`sidebar-item--${item.title}`} testID={`sidebar-item--${item.id}`}>
                <SidebarItem
                  {...item}
                  active={getIsItemActive(item.id)}
                  onPress={() => handlePress(item)}
                  as="button"
                />
              </VStack>
            );
          })}
        </VStack>
        <Divider offsetHorizontal={6} />
        {platformAPIsSection}
        <Divider offsetHorizontal={6} />
        {productAPIsSection}
      </Sidebar>
    </VStack>
  );
}
