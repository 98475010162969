import { VStack } from '@cbhq/cds-web/layout';

import { EarningsRewardsChart } from ':cloud/widgets/earnings/EarningsRewardsChart';
import { EarningsChart, EarningsRange } from ':cloud/widgets/earnings/types';
import { ChartErrorFallback } from ':cloud/widgets/sharedcomponents/ChartErrorFallback';
import { CloudErrorBoundary } from ':cloud/widgets/sharedcomponents/CloudErrorBoundary';

export function Chart({ title, chartType, range }: EarningsChart & { range: EarningsRange }) {
  const chartMapper = {
    area: <EarningsRewardsChart chartType={chartType} title={title} range={range} />,
  };

  const chartComponent = chartMapper[chartType] || (
    <div>No chart available for the selected type</div>
  );

  return (
    <VStack bordered borderRadius="rounded" spacing={4} height={500}>
      <CloudErrorBoundary name="EarningsChart" fallback={<ChartErrorFallback />}>
        {chartComponent}
      </CloudErrorBoundary>
    </VStack>
  );
}
