import { useCallback, useMemo, useState } from 'react';
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { useDashboardContext } from ':cloud/contexts/DashboardProvider';
import { truncateMiddle } from ':cloud/utils/common';
import { CHART_COLORS } from ':cloud/widgets/monitoring/monitoringConfig';
import {
  ChartType,
  DashboardRange,
  TransformedMetricDataPoint,
} from ':cloud/widgets/monitoring/types';
import { formatTickTimestamp } from ':cloud/widgets/monitoring/utils';

import {
  defaultChartProps,
  defaultLegendWrapperProps,
  defaultYAxisProps,
  defaultYAxisTickFormatter,
  paymasterGasYAxisTickFormatter,
} from './chartDefaults';
import { CustomLegend } from './MonitoringChartLegend';
import { MonitoringChartTooltip } from './MonitoringChartTooltip';

const roundedBarRadius = [4, 4, 0, 0] as [number, number, number, number];
const barStyles = { outline: '1px solid var(--background)' };

function legendFormatter(value: string) {
  return truncateMiddle(value, 11, 13, '...');
}

type MonitoringBarChartProps = {
  data: TransformedMetricDataPoint[];
  segments: string[];
  unit?: string;
  metricName?: string;
  range?: DashboardRange;
};

export function MonitoringBarChart({
  data,
  segments,
  unit,
  metricName,
  range,
}: MonitoringBarChartProps) {
  const { range: contextRange } = useDashboardContext();
  const [focusBar, setFocusBar] = useState<number | undefined | null>();
  const [mouseLeave, setMouseLeave] = useState(true);

  const isPaymasterGasChart = metricName === 'cloud.rpc-proxy.paymaster.sponsored-usd';
  const xAxisTickFormatter = useCallback(
    (tickItem: string) => {
      return formatTickTimestamp(tickItem, range || contextRange);
    },
    [contextRange, range],
  );
  const yAxisTickFormatter = useCallback(
    (tickItem: string) => {
      if (isPaymasterGasChart) {
        return `$${paymasterGasYAxisTickFormatter(tickItem)}`;
      }
      return defaultYAxisTickFormatter(tickItem);
    },
    [isPaymasterGasChart],
  );

  const chartType = 'bar' as ChartType;

  /* can be in small decimal form so we need to adjust padding
  so values are not cut off */
  const chartProps = useMemo(() => {
    return defaultChartProps;
  }, []);

  return (
    <ResponsiveContainer minWidth="90%" minHeight="90%" key={range}>
      <BarChart
        data={data}
        {...chartProps}
        // Track the mouse position to determine if any bars are hovered (impacts opacity in cell)
        onMouseMove={(state) => {
          if (state.isTooltipActive) {
            setFocusBar(state.activeTooltipIndex);
            setMouseLeave(false);
          } else {
            setFocusBar(null);
            setMouseLeave(true);
          }
        }}
      >
        <YAxis tickFormatter={yAxisTickFormatter} {...defaultYAxisProps} />
        <RechartsTooltip
          content={<MonitoringChartTooltip chartType={chartType} />}
          cursor={false}
        />
        {segments.length > 1 && (
          <Legend
            formatter={legendFormatter}
            content={<CustomLegend />}
            {...defaultLegendWrapperProps}
          />
        )}
        {segments?.map((segment: string, segmentIndex: number) => {
          // This cycles over the chart colors if we have more segments than colors
          const color = CHART_COLORS[segmentIndex % CHART_COLORS.length];

          // Check if any of the following segments have a value greater than 0
          const isLastSegment = segmentIndex === segments.length - 1;
          const remainingSegments = segments.slice(segmentIndex + 1);

          return (
            <Bar
              key={segment}
              dataKey={segment}
              stackId="1"
              fill={color}
              style={barStyles}
              unit={unit}
            >
              {data.map((entry, index) => {
                const remainingSegmentValues = remainingSegments.map((s) => entry[s]);
                const isLastSegmentWithValue =
                  isLastSegment || remainingSegmentValues.every((v) => v === 0);

                // Round the corners of the last rendered segment
                const cellProps = isLastSegmentWithValue ? { radius: roundedBarRadius } : {};

                return (
                  // @ts-expect-error -- Recharts has incorrectly typed cell radius as a single number
                  // Lower opacity if one bar is hovered over, and its not the current bar
                  <Cell
                    key={`${segment}-${entry.time}`}
                    {...cellProps}
                    opacity={focusBar === index || mouseLeave ? 1 : 0.38}
                  />
                );
              })}
            </Bar>
          );
        })}
        <XAxis
          dataKey="time"
          tickFormatter={xAxisTickFormatter}
          tickLine={false}
          interval="equidistantPreserveStart"
          minTickGap={20}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
