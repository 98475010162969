import { memo, useCallback, useMemo, useState } from 'react';
import _capitalize from 'lodash/capitalize';
import { noop } from '@cbhq/cds-utils';
import { Button } from '@cbhq/cds-web/buttons';
import { Select, SelectOption, TextInput } from '@cbhq/cds-web/controls';
import { VStack } from '@cbhq/cds-web/layout';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@cbhq/cds-web/overlays';
import { useToast } from '@cbhq/cds-web/overlays/useToast';

import { useInviteMember } from ':cloud/queries/OrganizationQueries/useInviteMember';
import { OrganizationRole } from ':cloud/types/ts_types';
import { validEmailRegex } from ':cloud/utils/validate';

type Props = {
  visible: boolean;
  existingUsers?: string[];
  orgId?: string;
  onRequestClose?: () => void;
};

enum EmailValidity {
  Valid,
  Empty,
  DuplicateUser,
  Invalid,
  TooLong,
}

const ErrorStates = [EmailValidity.DuplicateUser, EmailValidity.Invalid, EmailValidity.TooLong];

export const InviteMemberModal = memo(function InviteMemberModal({
  visible,
  existingUsers,
  orgId,
  onRequestClose,
}: Props) {
  const [email, setEmail] = useState<string>('');
  const [emailState, setEmailState] = useState<EmailValidity>(EmailValidity.Empty);
  const [isEmailSubmitFailure, isSetEmailSubmitFailure] = useState(false);
  const [newRole, setNewRole] = useState<OrganizationRole>('user');
  const inviteMemberMutation = useInviteMember(orgId || '', email);
  const toast = useToast();

  const helperText = useMemo(() => {
    if (!isEmailSubmitFailure) {
      return undefined;
    }
    if (emailState === EmailValidity.DuplicateUser) {
      return 'User exists already';
    }
    if (emailState === EmailValidity.Invalid) {
      return 'The email you entered is invalid. Please retry';
    }
    if (emailState === EmailValidity.TooLong) {
      return 'The email you entered is too long. Please retry';
    }
    return undefined;
  }, [emailState, isEmailSubmitFailure]);

  const handleEmailChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const trimmedValue = event.target.value?.trim();
      if (trimmedValue === '') {
        setEmailState(EmailValidity.Empty);
      } else if (!validEmailRegex.test(trimmedValue)) {
        setEmailState(EmailValidity.Invalid);
      } else if (existingUsers?.includes(trimmedValue)) {
        setEmailState(EmailValidity.DuplicateUser);
      } else if (trimmedValue.length > 64) {
        setEmailState(EmailValidity.TooLong);
      } else {
        setEmailState(EmailValidity.Valid);
      }
      setEmail(trimmedValue);
    },
    [setEmail, existingUsers],
  );

  const handleInviteMember = useCallback(() => {
    if (emailState !== EmailValidity.Valid) {
      isSetEmailSubmitFailure(true);
      return;
    }

    inviteMemberMutation.mutate(newRole, {
      onSuccess: () => {
        onRequestClose?.();
      },
      onError: () => {
        onRequestClose?.();
        toast.show('Unable to invite user. Please retry', { variant: 'negative' });
      },
    });
  }, [inviteMemberMutation, newRole, onRequestClose, toast, emailState]);

  const handleSelectChange = useCallback((value) => setNewRole(value), []);

  return (
    <Modal visible={visible} onRequestClose={onRequestClose || noop}>
      <ModalHeader title="Invite Member" />
      <ModalBody>
        <VStack gap={4}>
          <TextInput
            label="Email"
            placeholder="user@email.com"
            onChange={handleEmailChange}
            helperText={helperText}
            variant={
              isEmailSubmitFailure && ErrorStates.includes(emailState) ? 'negative' : undefined
            }
            aria-invalid={isEmailSubmitFailure && ErrorStates.includes(emailState)}
          />
          <Select
            label="role"
            placeholder="Select role"
            value={newRole}
            valueLabel={_capitalize(newRole)}
            onChange={handleSelectChange}
          >
            <SelectOption value="user" title="User" />
            <SelectOption value="admin" title="Admin" />
          </Select>
        </VStack>
      </ModalBody>
      <ModalFooter
        primaryAction={
          <Button
            disabled={inviteMemberMutation.isLoading}
            block
            type="submit"
            onPress={handleInviteMember}
          >
            Add member
          </Button>
        }
      />
    </Modal>
  );
});
