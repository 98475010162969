import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Banner } from '@cbhq/cds-web/banner/Banner';
import { Button, ButtonGroup } from '@cbhq/cds-web/buttons';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Box, Grid, HStack, VStack } from '@cbhq/cds-web/layout';
import { Pressable } from '@cbhq/cds-web/system';
import {
  TextBody,
  TextDisplay2,
  TextHeadline,
  TextTitle3,
  TextTitle4,
} from '@cbhq/cds-web/typography';

import { ReactComponent as WalletsHeaderImg } from ':cloud/assets/Products/WaaSCompressed.svg';
import { useAppState } from ':cloud/contexts/AppStateContext';
import { EventName, logClick, useLogViewOnMount } from ':cloud/init/clientAnalytics/logging';
import { CodeBlockLanguage } from ':cloud/types/ts_types';
import { SMART_WALLET_DOCS_LINK, WALLETS_DISCORD_LINK } from ':cloud/utils/links';
import { onExternalLinkPress } from ':cloud/utils/onExternalLinkPress';
import { AppRoute } from ':cloud/utils/routes';
import { Layout } from ':cloud/widgets/layout';
import { FULL_PAGE_MAX_WIDTH, MAIN_CONTAINER_MIN_WIDTH } from ':cloud/widgets/product/constants';
import { KeyFeatureItem, KeyFeaturesSection } from ':cloud/widgets/product/KeyFeaturesSection';
import { QuickstartItem } from ':cloud/widgets/product/QuickstartItem';
import { CodeBlock } from ':cloud/widgets/sharedcomponents';

const keyFeatures: KeyFeatureItem[] = [
  {
    iconName: 'gasFees',
    title: 'Simplified gasless experiences',
    description:
      'Use a paymaster to create sponsorship policies for your app. Get started today with free gas credits on Base through the Coinbase Developer Platform (CDP).',
  },
  {
    iconName: 'coinShare',
    title: 'Access Coinbase balances',
    description:
      'Allows smart wallet users to use their Coinbase balance without complex transfers to a self custody wallet.',
  },
  {
    iconName: 'walletPassword',
    title: 'Easy onboarding with passkey',
    description:
      'Onboard users in seconds with no seed phrases, passwords, or third party app installs.',
  },
];

const dangerousColor = '#0093CB';
const backgroundColor = '#1E2025';
const customCodeStyle = {
  padding: '25px 10px 25px',
  background: backgroundColor,
  overflow: 'clip',
};

function WalletQuickstartSection() {
  const bashCode = useMemo(() => {
    const bashCodeCommand = `npm install @coinbase/wallet-sdk\n\n# Want to try out the new smart wallet on testnet? make sure you’re using ^4.0.0\n# npm install @coinbase/wallet-sdk@4.0.0-beta.0`;
    return [
      {
        language: 'bash' as CodeBlockLanguage,
        code: bashCodeCommand,
      },
    ];
  }, []);

  const javascriptCode = useMemo(() => {
    const javascriptCodeCommand = `# Initialize Coinbase Wallet SDK\nconst sdk = new CoinbaseWalletSDK({appName: "An Awesome App", appChainIds: [84532]});\n\n# Make web3 provider\nconst provider = sdk.makeWeb3Provider();\n\n# Initialize wallet connection\nconst addresses = provider.request("eth_requestAccounts");`;
    return [
      {
        language: 'javascript' as CodeBlockLanguage,
        code: javascriptCodeCommand,
      },
    ];
  }, []);

  return (
    <VStack bordered borderRadius="rounded" spacing={4} gap={6}>
      <QuickstartItem
        stepNumber={1}
        title="Install Wallet SDK"
        codeBlock={
          <CodeBlock
            codeArr={bashCode}
            showLanguageSelect={false}
            showCopy
            customStyle={customCodeStyle}
            headerBackground={backgroundColor}
            enableLineWrap
            title="shell"
          />
        }
      />
      <QuickstartItem
        stepNumber={2}
        title="Setup Wallet"
        codeBlock={
          <CodeBlock
            codeArr={javascriptCode}
            showLanguageSelect={false}
            showCopy
            customStyle={customCodeStyle}
            headerBackground={backgroundColor}
            enableLineWrap
            title="index.js"
          />
        }
      />
    </VStack>
  );
}

export function WalletsLanding() {
  const history = useHistory();
  const title = 'Seamlessly bring your users onchain';
  const description =
    'Smart Wallet makes it easy for your users to explore your app, whether they’re crypto native or not.';

  const { isDesktop } = useBreakpoints();
  const responsiveTemplateHeaderColumns = useMemo(() => {
    return isDesktop ? '2fr 1fr' : '1fr';
  }, [isDesktop]);

  const { selectedProject } = useAppState();

  const handleEmbeddedWalletsPress = useCallback(() => {
    logClick(EventName.embedded_wallets_learn_more_click);
    history.push(AppRoute.Products.WaasConsumer);
  }, [history]);

  useLogViewOnMount(EventName.wallets_page_view);

  return (
    <Layout enableMaxWidth={false}>
      <Layout.MainContainer minWidth={MAIN_CONTAINER_MIN_WIDTH} spacingHorizontal={0} fullWidth>
        <Box display="block" width="100%">
          <VStack>
            <Box spacingHorizontal={8} maxWidth={FULL_PAGE_MAX_WIDTH} display="block">
              <Banner
                variant="promotional"
                startIcon="wallet"
                title="Need more customization?"
                showDismiss
              >
                Try out the{' '}
                <Pressable
                  background="transparent"
                  noScaleOnPress
                  onPress={handleEmbeddedWalletsPress}
                >
                  <TextBody as="span" color="primary">
                    Embedded EOA Wallet.
                  </TextBody>
                </Pressable>
              </Banner>
            </Box>
            <HStack spacingVertical={4} borderedBottom>
              <HStack maxWidth={FULL_PAGE_MAX_WIDTH}>
                <Grid
                  maxWidth={FULL_PAGE_MAX_WIDTH}
                  gap={9}
                  spacing={9}
                  spacingVertical={0}
                  templateColumns={responsiveTemplateHeaderColumns}
                >
                  <VStack justifyContent="center">
                    <TextHeadline as="p" dangerouslySetColor={dangerousColor} spacingBottom={2}>
                      Smart Wallet
                    </TextHeadline>
                    <TextDisplay2 as="h1">{title}</TextDisplay2>
                    <TextTitle4 as="h2" color="foregroundMuted" spacingTop={5} spacingBottom={7}>
                      {description}
                    </TextTitle4>

                    <ButtonGroup>
                      <Button
                        variant="primary"
                        endIcon="diagonalUpArrow"
                        onPress={onExternalLinkPress(SMART_WALLET_DOCS_LINK)}
                        accessibilityLabel="View docs + opens in new window"
                      >
                        View docs
                      </Button>
                      <Button
                        variant="secondary"
                        endIcon="diagonalUpArrow"
                        onPress={onExternalLinkPress(WALLETS_DISCORD_LINK)}
                        accessibilityLabel="View docs + opens in new window"
                      >
                        Discord channel
                      </Button>
                    </ButtonGroup>
                  </VStack>
                  <WalletsHeaderImg />
                </Grid>
              </HStack>
            </HStack>
            {selectedProject && (
              <VStack borderedBottom>
                <VStack maxWidth={FULL_PAGE_MAX_WIDTH} spacing={10} gap={5}>
                  <HStack alignItems="center" gap={2}>
                    <TextTitle3 as="h1">Quickstart</TextTitle3>
                  </HStack>
                  <WalletQuickstartSection />
                </VStack>
              </VStack>
            )}
            <KeyFeaturesSection features={keyFeatures} />
          </VStack>
        </Box>
      </Layout.MainContainer>
    </Layout>
  );
}
