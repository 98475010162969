import { createContext, useCallback, useContext, useMemo } from 'react';

import useLocalStorage from ':cloud/hooks/useLocalStorage';

import { useAppState } from './AppStateContext';

type PaymasterContextType = {
  setCompletedStep: (step: string) => void;
  hasCompletedStep: (step: string) => boolean;
};

const PaymasterContext = createContext<PaymasterContextType | undefined>(undefined);

export function usePaymasterContext() {
  const context = useContext(PaymasterContext);
  if (context === undefined) {
    throw new Error('usePaymasterContext must be used within a PaymasterProvider');
  }
  return context;
}

export function PaymasterProvider({ children }) {
  const { selectedProject } = useAppState();
  const [completedSteps, setCompletedSteps] = useLocalStorage(
    `paymasterCompletedSteps-${selectedProject?.id}`,
    [''],
  );

  const hasCompletedStep = useCallback(
    (step: string) => {
      return completedSteps?.includes(step);
    },
    [completedSteps],
  );

  const setCompletedStep = useCallback(
    (step: string) => {
      if (!completedSteps?.includes(step)) {
        setCompletedSteps(completedSteps?.concat(step));
      }
    },
    [completedSteps, setCompletedSteps],
  );

  const providerValue = useMemo(
    () => ({ hasCompletedStep, setCompletedStep }),
    [hasCompletedStep, setCompletedStep],
  );

  return <PaymasterContext.Provider value={providerValue}>{children}</PaymasterContext.Provider>;
}
