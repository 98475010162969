import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Button } from '@cbhq/cds-web/buttons';
import { Icon } from '@cbhq/cds-web/icons';
import { SpotRectangle } from '@cbhq/cds-web/illustrations';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { Pressable } from '@cbhq/cds-web/system';
import { TextBody, TextTitle4 } from '@cbhq/cds-web/typography';

import useLocalStorage from ':cloud/hooks/useLocalStorage';
import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import useViewportDimensions from ':cloud/hooks/useViewportDimensions';
import { COINBASE_CAREERS } from ':cloud/utils/links';
import { onExternalLinkPress } from ':cloud/utils/onExternalLinkPress';
import { AppRoute } from ':cloud/utils/routes';

export function HiringBanner() {
  const { isPhone, isDesktop } = useSimpleBreakpoints();
  const location = useLocation();
  const hasIncrementedRef = useRef(false);

  const [showBanner, setShowBanner] = useState(false);
  const [visitCount, setVisitCount] = useLocalStorage('hiring-banner-visit-count', 0);
  const [dismissalCount, setDismissalCount] = useLocalStorage('hiring-banner-dismissal-count', 0);
  const [hasClosedDiscordBanner] = useLocalStorage('has-closed-discord-banner', false);

  useEffect(() => {
    // Don't start counting until user has closed the Discord banner
    if (!hasIncrementedRef.current && hasClosedDiscordBanner) {
      setVisitCount((prevCount) => prevCount + 1);
      hasIncrementedRef.current = true;
    }
  }, [setVisitCount, dismissalCount, hasClosedDiscordBanner]);

  useEffect(() => {
    // Don't show the banner if it's been dismissed twice, and don't show until
    // the user has visited the page at least 3x since banner was last dismissed
    if (dismissalCount < 2 && visitCount >= 3) {
      setShowBanner(true);
    }
  }, [setVisitCount, visitCount, showBanner, dismissalCount]);

  const handleClosePress = useCallback(() => {
    setShowBanner(false);
    setDismissalCount((prevCount) => prevCount + 1);

    // Reset the visit count so we can count how many visits before showing the banner again
    setVisitCount(0);
  }, [setDismissalCount, setVisitCount]);

  const { width } = useViewportDimensions();
  const joinButtonText = useMemo(() => {
    if (!width || width <= 1440) {
      return 'Careers';
    }

    return 'Explore careers at Coinbase';
  }, [width]);

  if (isPhone) {
    return null;
  }

  // Conditional rendering based on path
  const allowedPaths: string[] = [
    AppRoute.Access.CurrentTab,
    AppRoute.Projects.Overview,
    AppRoute.Billing.CurrentTab,
    AppRoute.Settings.Project,
  ];
  const currentPath = location.pathname;

  const isPathAllowed = allowedPaths.some((pathPattern) => {
    const match = matchPath(currentPath, { path: pathPattern, exact: true });
    return match !== null;
  });

  if (!isPathAllowed || !showBanner) {
    return null;
  }

  return (
    <Box
      spacing={4}
      position="relative"
      spacingHorizontal={isDesktop ? 10 : 7}
      width="100%"
      background="primaryWash"
    >
      <Box position="absolute" spacing={2} top={0} right={0}>
        <Pressable background="transparent" onPress={handleClosePress} noScaleOnPress>
          <Icon name="close" size="s" color="foreground" />
        </Pressable>
      </Box>
      <VStack alignItems="flex-end" width="100%">
        <HStack width="100%" justifyContent="space-between" alignItems="center" gap={2}>
          <HStack gap={isDesktop ? 10 : 5} alignItems="center">
            <SpotRectangle name="cryptoAndMore" scaleMultiplier={0.4} />
            <VStack>
              <TextTitle4 as="h4">We&apos;re hiring!</TextTitle4>
              <TextBody as="p">
                Do you believe in the potential of crypto? Apply to join the team at Coinbase!
              </TextBody>
            </VStack>
          </HStack>
          <Button
            variant="primary"
            endIcon="diagonalUpArrow"
            onPress={onExternalLinkPress(COINBASE_CAREERS)}
            compact={!isDesktop}
          >
            {joinButtonText}
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
}
