import { useMemo, useState } from 'react';
import _sumBy from 'lodash/sumBy';
import { Button } from '@cbhq/cds-web/buttons';
import { SelectOption } from '@cbhq/cds-web/controls';
import { Dropdown } from '@cbhq/cds-web/dropdown';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, Grid, HStack, VStack } from '@cbhq/cds-web/layout';
import { Tooltip } from '@cbhq/cds-web/overlays';
import { TextBody, TextDisplay3, TextLabel1, TextTitle3 } from '@cbhq/cds-web/typography';

import { useAppState } from ':cloud/contexts/AppStateContext';
import { DashboardProvider } from ':cloud/contexts/DashboardProvider';
import { useIsPaymasterAnalyticsPercentEnabled } from ':cloud/hooks/featureFlags/useIsPaymasterAnalyticsPercentEnabled';
import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { useGetChartData } from ':cloud/queries/MonitoringQueries/useGetChartData';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { getMonthRange, getShortDate, getWeekRange } from ':cloud/utils/date';
import { currencyFormatter } from ':cloud/utils/formatters/currency';

import { Chart } from '../monitoring/Chart';
import { productCharts } from '../monitoring/monitoringConfig';
import { DashboardRange } from '../monitoring/types';
import { getEndTime, getScope, getStartTime } from '../monitoring/utils';
import { CloudErrorBoundary } from '../sharedcomponents';

import { BaseNetworkDropdown } from './BaseNetworkDropdown';

function getPercentageChange(prevSum?: number, currentSum?: number) {
  if (!prevSum || !currentSum || prevSum - currentSum === 0) {
    return '';
  }
  const percentChange = ((currentSum - prevSum) / prevSum) * 100;
  const roundedPercentChange = percentChange.toFixed(2);
  return `(${percentChange > 0 ? '+' : ''}${roundedPercentChange}%)`;
}

function formatNumberUS(number: number) {
  const [integerPart, fractionalPart] = number.toString().split('.');
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return fractionalPart ? `${formattedIntegerPart}.${fractionalPart}` : formattedIntegerPart;
}

type AnalyticsItemProps = {
  label: string;
  value: string;
  percentageChange?: string;
};

function AnalyticsItem({ label, value, percentageChange }: AnalyticsItemProps) {
  const isPercentEnabled = useIsPaymasterAnalyticsPercentEnabled();
  const isWalletItem = label === 'Unique wallets sponsored';
  return (
    <VStack spacing={5} bordered borderRadius="rounded" minWidth={266} flexGrow={1}>
      <HStack alignItems="center" gap={0.5} spacingBottom={1}>
        <TextLabel1 as="p" color="foregroundMuted">
          {label}
        </TextLabel1>
        {isWalletItem && (
          <Tooltip content="Data is available from July 24, 2024 onwards.">
            <Icon name="info" size="s" color="foregroundMuted" />
          </Tooltip>
        )}
      </HStack>
      <HStack alignItems="flex-end">
        <TextDisplay3 as="h1">{value}</TextDisplay3>
        {!!percentageChange && isPercentEnabled && (
          <TextBody
            spacingStart={1}
            as="p"
            color={percentageChange.includes('+') ? 'positive' : 'negative'}
          >
            {percentageChange}
          </TextBody>
        )}
      </HStack>
    </VStack>
  );
}

type PaymasterAnalyticsProps = {
  selectedNetwork: string;
  setSelectedNetwork: (network: string) => void;
};

const rangeOptions = ['Day', 'Week', 'Month'];
const rangeMap = {
  Day: 1,
  Week: 7,
  Month: 30,
};

export function PaymasterAnalytics({
  selectedNetwork,
  setSelectedNetwork,
}: PaymasterAnalyticsProps) {
  const { activeOrg } = useGetUser();
  const { selectedProject } = useAppState();
  const [range, setRange] = useState(rangeOptions[2]);

  const { isDesktop } = useSimpleBreakpoints();
  const responsiveTemplateColumns = useMemo(() => {
    return isDesktop ? '1fr 1fr 1fr' : '1fr';
  }, [isDesktop]);

  const filterByNetwork = useMemo(() => {
    if (selectedNetwork === 'Base Mainnet') {
      return [{ name: 'network', value: 'base' }];
    }
    return [{ name: 'network', value: 'base-sepolia' }];
  }, [selectedNetwork]);

  const sponsoredGasChart = productCharts.base.charts[2];
  const { data: sponsoredGasData } = useGetChartData(
    {
      metricName: sponsoredGasChart.metricName,
      aggregationMethod: sponsoredGasChart.aggregationMethod,
      groupBy: sponsoredGasChart.groupBy,
      startTime: getStartTime(rangeMap[range]),
      endTime: getEndTime(rangeMap[range]),
      filterBy: filterByNetwork,
      scope: getScope(sponsoredGasChart.metricName),
    },
    activeOrg?.organizationId || '',
    selectedProject?.id || '',
  );
  const { data: prevSponsoredGasData } = useGetChartData(
    {
      metricName: sponsoredGasChart.metricName,
      aggregationMethod: sponsoredGasChart.aggregationMethod,
      groupBy: sponsoredGasChart.groupBy,
      startTime: getStartTime((rangeMap[range] * 2) as DashboardRange),
      endTime: getStartTime(rangeMap[range]),
      filterBy: filterByNetwork,
      scope: getScope(sponsoredGasChart.metricName),
    },
    activeOrg?.organizationId || '',
    selectedProject?.id || '',
  );
  const { sponsoredGas, percentageChange: sponsoredGasPercentageChange } = useMemo(() => {
    if (!sponsoredGasData) {
      return { sponsoredGas: '--' };
    }
    const dataSet = sponsoredGasData?.[0];
    const relevantDataPoints = dataSet?.dataPoints?.filter((dataPoint) => dataPoint?.time);
    const totalCurrentGas = _sumBy(relevantDataPoints, (dataPoint) => +dataPoint.value);
    const currentGas = currencyFormatter(totalCurrentGas);

    const prevDataSet = prevSponsoredGasData?.[0];
    const prevRelevantDataPoints = prevDataSet?.dataPoints?.filter((dataPoint) => dataPoint?.time);
    const totalPreviousGas = _sumBy(prevRelevantDataPoints, (dataPoint) => +dataPoint.value);
    return {
      sponsoredGas: currentGas,
      percentageChange: getPercentageChange(totalPreviousGas, totalCurrentGas),
    };
  }, [prevSponsoredGasData, sponsoredGasData]);

  const sponsoredUserOpChart = productCharts.base.charts[3];
  const { data: sponsoredUserOpData } = useGetChartData(
    {
      metricName: sponsoredUserOpChart.metricName,
      aggregationMethod: sponsoredUserOpChart.aggregationMethod,
      groupBy: sponsoredUserOpChart.groupBy,
      startTime: getStartTime(rangeMap[range]),
      endTime: getEndTime(rangeMap[range]),
      filterBy: filterByNetwork,
      scope: getScope(sponsoredUserOpChart.metricName),
    },
    activeOrg?.organizationId || '',
    selectedProject?.id || '',
  );
  const { data: prevSponsoredUserOpData } = useGetChartData(
    {
      metricName: sponsoredUserOpChart.metricName,
      aggregationMethod: sponsoredUserOpChart.aggregationMethod,
      groupBy: sponsoredUserOpChart.groupBy,
      startTime: getStartTime((rangeMap[range] * 2) as DashboardRange),
      endTime: getStartTime(rangeMap[range]),
      filterBy: filterByNetwork,
      scope: getScope(sponsoredUserOpChart.metricName),
    },
    activeOrg?.organizationId || '',
    selectedProject?.id || '',
  );
  const { sponsoredUserOps, percentageChange: userOpsPercentageChange } = useMemo(() => {
    if (!sponsoredUserOpData) {
      return { sponsoredUserOps: '--' };
    }
    const dataSet = sponsoredUserOpData?.[0];
    const relevantDataPoints = dataSet?.dataPoints?.filter((dataPoint) => dataPoint?.time);
    const totalUserOps = _sumBy(relevantDataPoints, (dataPoint) => +dataPoint.value);
    const currentUserOps = formatNumberUS(totalUserOps);

    const prevDataSet = prevSponsoredUserOpData?.[0];
    const prevRelevantDataPoints = prevDataSet?.dataPoints?.filter((dataPoint) => dataPoint?.time);
    const totalPreviousUserOps = _sumBy(prevRelevantDataPoints, (dataPoint) => +dataPoint.value);
    return {
      sponsoredUserOps: currentUserOps,
      percentageChange: getPercentageChange(totalPreviousUserOps, totalUserOps),
    };
  }, [prevSponsoredUserOpData, sponsoredUserOpData]);

  const sponsoredSmartWalletsChart = useMemo(() => {
    return (
      productCharts.base.charts.find((chart) => chart.title.includes(range)) ||
      productCharts.base.charts[4]
    );
  }, [range]);

  const { data: sponsoredSmartWalletsData } = useGetChartData(
    {
      metricName: sponsoredSmartWalletsChart.metricName,
      aggregationMethod: sponsoredSmartWalletsChart.aggregationMethod,
      groupBy: sponsoredSmartWalletsChart.groupBy,
      startTime: getStartTime(rangeMap[range]),
      endTime: getEndTime(rangeMap[range]),
      filterBy: filterByNetwork,
      scope: getScope(sponsoredSmartWalletsChart.metricName),
    },
    activeOrg?.organizationId || '',
    selectedProject?.id || '',
  );
  const { data: prevSponsoredSmartWalletsData } = useGetChartData(
    {
      metricName: sponsoredSmartWalletsChart.metricName,
      aggregationMethod: sponsoredSmartWalletsChart.aggregationMethod,
      groupBy: sponsoredSmartWalletsChart.groupBy,
      startTime: getStartTime((rangeMap[range] * 2) as DashboardRange),
      endTime: getStartTime(rangeMap[range]),
      filterBy: filterByNetwork,
      scope: getScope(sponsoredSmartWalletsChart.metricName),
    },
    activeOrg?.organizationId || '',
    selectedProject?.id || '',
  );
  const { sponsoredSmartWallets, percentageChange: smartWalletsPercentageChange } = useMemo(() => {
    if (!sponsoredSmartWalletsData) {
      return { sponsoredSmartWallets: '--' };
    }
    const dataSet = sponsoredSmartWalletsData?.[0];
    const relevantDataPoints = dataSet?.dataPoints?.filter((dataPoint) => dataPoint?.time);
    const totalSmartWallets = _sumBy(relevantDataPoints, (dataPoint) => +dataPoint.value);
    const currentSmartWallets = formatNumberUS(totalSmartWallets);

    const prevDataSet = prevSponsoredSmartWalletsData?.[0];
    const prevRelevantDataPoints = prevDataSet?.dataPoints?.filter((dataPoint) => dataPoint?.time);
    const totalPreviousSmartWallets = _sumBy(
      prevRelevantDataPoints,
      (dataPoint) => +dataPoint.value,
    );

    return {
      sponsoredSmartWallets: currentSmartWallets,
      percentageChange: getPercentageChange(totalPreviousSmartWallets, totalSmartWallets),
    };
  }, [prevSponsoredSmartWalletsData, sponsoredSmartWalletsData]);

  const rangeDropdownContent = useMemo(() => {
    return rangeOptions.map((option) => {
      return <SelectOption key={option} value={option} title={option} />;
    });
  }, []);

  const chartSubtitle = useMemo(() => {
    if (rangeMap[range] === 1) {
      return getShortDate(new Date());
    }
    if (rangeMap[range] === 7) {
      return getWeekRange(new Date());
    }
    return getMonthRange(new Date());
  }, [range]);

  return (
    <CloudErrorBoundary name="PaymasterAnalyticsTabContent">
      <DashboardProvider>
        <VStack gap={3}>
          <HStack gap={2} spacingBottom={2} alignItems="center" justifyContent="space-between">
            <TextTitle3 as="h1">Analytics</TextTitle3>
            <HStack gap={2}>
              <Box zIndex={5}>
                <Dropdown
                  value={range}
                  onChange={setRange}
                  content={rangeDropdownContent}
                  enableMobileModal
                >
                  <Button compact variant="secondary" endIcon="caretDown">
                    {range}
                  </Button>
                </Dropdown>
              </Box>
              <BaseNetworkDropdown
                setSelectedNetwork={setSelectedNetwork}
                selectedNetwork={selectedNetwork}
              />
            </HStack>
          </HStack>

          <Grid gap={2} templateColumns={responsiveTemplateColumns}>
            <AnalyticsItem
              label="Gas sponsored"
              value={sponsoredGas}
              percentageChange={sponsoredGasPercentageChange}
            />
            <AnalyticsItem
              label="User Operations sponsored"
              value={sponsoredUserOps}
              percentageChange={userOpsPercentageChange}
            />
            <AnalyticsItem
              label="Unique wallets sponsored"
              value={sponsoredSmartWallets}
              percentageChange={smartWalletsPercentageChange}
            />
          </Grid>

          <Box display="block">
            <Chart
              {...sponsoredGasChart}
              filterBy={filterByNetwork}
              title="Gas sponsored"
              bordered={false}
              range={rangeMap[range]}
              subtitle={chartSubtitle}
            />
          </Box>

          <Box display="block">
            <Chart
              {...sponsoredUserOpChart}
              filterBy={filterByNetwork}
              title="User Operations sponsored"
              bordered={false}
              range={rangeMap[range]}
              subtitle={chartSubtitle}
            />
          </Box>

          <Box display="block">
            <Chart
              {...sponsoredSmartWalletsChart}
              filterBy={filterByNetwork}
              title="Unique wallets sponsored"
              bordered={false}
              range={rangeMap[range]}
              subtitle={chartSubtitle}
            />
          </Box>
        </VStack>
      </DashboardProvider>
    </CloudErrorBoundary>
  );
}
