import { useMemo } from 'react';
import { VStack } from '@cbhq/cds-web/layout';

import { cdsAliasToCssVar, getCDSVariantFromWorkflow } from ':cloud/brand/colors';
import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { ChartWidgetType } from ':cloud/types/helper';
import { deriveValueFromMetrics } from ':cloud/widgets/charts/ChartUtils';
import { RadialChart } from ':cloud/widgets/charts/RadialChart';
import { TextLabelGray } from ':cloud/widgets/sharedcomponents';

export function RadialChartWidget({
  sources,
  options,
  metricsData,
  configuration,
  isDynamic,
  pod,
}: ChartWidgetType) {
  const { isPhone } = useSimpleBreakpoints();

  const radialColorVariant = useMemo(() => {
    return getCDSVariantFromWorkflow(options.color);
  }, [options.color]);

  return (
    <VStack spacing={1} justifyContent="center" minHeight={isPhone ? 180 : undefined}>
      <TextLabelGray align="center">{options.label}</TextLabelGray>
      <RadialChart
        value={
          sources[0]
            ? Number(deriveValueFromMetrics(sources[0], configuration, isDynamic, metricsData, pod))
            : undefined
        }
        radialPercent={
          sources[1]
            ? Number(deriveValueFromMetrics(sources[1], configuration, isDynamic, metricsData, pod))
            : undefined
        }
        color={cdsAliasToCssVar(radialColorVariant)}
        unit={options.unit}
      />
    </VStack>
  );
}
