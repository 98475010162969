import { useMemo } from 'react';
import { css, cx } from '@linaria/core';
import { styled } from '@linaria/react';
import { Card } from '@cbhq/cds-web/cards';
import { VStack } from '@cbhq/cds-web/layout';
import { palette, spacing } from '@cbhq/cds-web/tokens';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

import { ReactComponent as CheckIcon } from ':cloud/assets/Actions/check.svg';
import config from ':cloud/config';
import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';

const StyledImageWrapper = styled.div`
  margin: auto;
  width: fit-content;
  padding: ${spacing[5]} 0;
`;

const StyledImage = styled.img`
  height: auto;
  width: inherit;
`;

const checkIconStyles = css`
  position: absolute;
  width: 20;
  top: ${spacing[1]};
  right: ${spacing[1]};
  stroke: inherit;
  visibility: hidden;
`;

const checkIconSelectedStyles = css`
  stroke: ${palette.primary};
  visibility: inherit;
`;

interface BoxSelectWidgetProps {
  description: string;
  image?: string;
  onClick: () => void;
  selected: boolean;
  title: string;
  fieldType: string;
  widgetType: string;
  hasError: boolean;
}

export function BoxSelectWidget({
  description,
  image,
  onClick,
  selected,
  title,
  fieldType,
  hasError,
}: BoxSelectWidgetProps) {
  const { isPhone } = useSimpleBreakpoints();
  const defaultImageStyle = useMemo(() => {
    return { width: isPhone ? 55 : 64 };
  }, [isPhone]);
  return (
    <Card
      height="100%"
      onPress={onClick}
      onKeyPress={onClick}
      bordered
      borderRadius="rounded"
      spacing={5}
      borderColor={selected ? 'primary' : hasError ? 'negative' : 'lineHeavy'}
    >
      <CheckIcon className={cx(checkIconStyles, selected && checkIconSelectedStyles)} />
      <VStack justifyContent="space-between" height="inherit" alignContent="center" width="100%">
        <TextHeadline as="p" align="center" spacingBottom={image ? 0 : 1}>
          {title}
        </TextHeadline>
        <VStack flexGrow={1} flexShrink={1} flexBasis={0}>
          {image && (
            <StyledImageWrapper style={defaultImageStyle}>
              <StyledImage src={`${config.assets}/${image}`} alt={title} />
            </StyledImageWrapper>
          )}
          {fieldType !== 'NodeCountField' && (
            <TextBody align="center" as="p">
              {description}
            </TextBody>
          )}
        </VStack>
      </VStack>
    </Card>
  );
}
