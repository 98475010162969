import { type ThemeProps, withTheme } from '@rjsf/core';

import * as fields from './fields';
import * as Templates from './templates';
import * as widgets from './widgets';

const theme = {
  widgets,
  fields,
  ...Templates,
} as unknown as ThemeProps;

export default withTheme(theme);
