import { memo, useCallback } from 'react';
import { noop } from '@cbhq/cds-utils';
import { Button } from '@cbhq/cds-web/buttons';
import { VStack } from '@cbhq/cds-web/layout';
import { Avatar } from '@cbhq/cds-web/media';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@cbhq/cds-web/overlays';
import { TextBody, TextTitle2 } from '@cbhq/cds-web/typography';

import { useGetOrganization } from ':cloud/queries/OrganizationQueries/useGetOrganization';
import { useRemoveInvitedMember } from ':cloud/queries/OrganizationQueries/useRemoveInvitedMember';
import { useRemoveMember } from ':cloud/queries/OrganizationQueries/useRemoveMember';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { OrganizationActiveUser } from ':cloud/types/ts_types';

type Props = {
  visible: boolean;
  user: OrganizationActiveUser;
  orgId: string;
  onRequestClose?: () => void;
};

export const RemoveMemberModal = memo(function RemoveMemberModal({
  visible,
  user,
  orgId,
  onRequestClose,
}: Props) {
  const { activeOrg } = useGetUser();
  const { activeUsers } = useGetOrganization(activeOrg?.organizationId);
  const coinbaseUserId = activeUsers.find((u) => u?.id === user.id)?.coinbaseUserId || '';

  const removeMemberMutation = useRemoveMember(orgId, user.email);
  const removeMemberInvitedMutation = useRemoveInvitedMember(orgId, user.email);

  const handleRemoveMember = useCallback(() => {
    if (user.status === 'active') {
      return removeMemberMutation.mutate(coinbaseUserId, {
        onSuccess: onRequestClose,
      });
    }
    if (user.status === 'invited') {
      return removeMemberInvitedMutation.mutate(undefined, {
        onSuccess: onRequestClose,
      });
    }
    return null;
  }, [
    user.status,
    removeMemberMutation,
    coinbaseUserId,
    onRequestClose,
    removeMemberInvitedMutation,
  ]);

  return (
    <Modal
      hideCloseButton
      shouldCloseOnEscPress={false}
      visible={visible}
      onRequestClose={onRequestClose || noop}
    >
      <ModalHeader title="Remove Member" />
      <ModalBody>
        <VStack gap={3} alignItems="center" spacing={1}>
          <Avatar alt={user?.name || ''} size="xxxl" />
          <VStack alignItems="center" gap={0.5}>
            <TextTitle2 as="h4">{user?.name}</TextTitle2>
            <TextBody color="foregroundMuted" as="p">
              {user.email}
            </TextBody>
          </VStack>
          <TextBody align="center" as="p">
            Are you sure you want to remove this team member from the organization? Any API tokens
            associated with them will be disabled.
          </TextBody>
        </VStack>
      </ModalBody>
      <ModalFooter
        primaryAction={<Button onPress={handleRemoveMember}>Confirm</Button>}
        secondaryAction={
          <Button variant="secondary" onPress={onRequestClose}>
            Cancel
          </Button>
        }
      />
    </Modal>
  );
});
