import { Dispatch, SetStateAction, useState } from 'react';
import { Checkbox } from '@cbhq/cds-web/controls';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { Link, TextBody } from '@cbhq/cds-web/typography';

import { CLOUD_TOS, COINBASE_PRIVACY } from ':cloud/utils/links';

type TermsCheckboxProps = {
  hasCheckboxError: boolean;
  setHasCheckboxError: Dispatch<SetStateAction<boolean>>;
  hasAgreedToTerms: boolean;
  setHasAgreedToTerms: Dispatch<SetStateAction<boolean>>;
};

export function TermsCheckbox({
  hasCheckboxError,
  hasAgreedToTerms,
  setHasCheckboxError,
  setHasAgreedToTerms,
}: TermsCheckboxProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setHasCheckboxError(false);
    setHasAgreedToTerms(event.target.checked);
  };

  return (
    <VStack>
      <HStack as="label" gap={0.5} alignItems="center">
        <Checkbox checked={hasAgreedToTerms} onChange={handleChange} color="primary" />
        <TextBody as="p">
          I have read and agree to Coinbase Developer PLatform’s{` `}
          <Link variant="body" to={CLOUD_TOS} openInNewWindow color="primary">
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link variant="body" to={COINBASE_PRIVACY} openInNewWindow color="primary">
            Privacy Policy
          </Link>
          .
        </TextBody>
      </HStack>
      {hasCheckboxError && (
        <TextBody as="p" color="negative">
          Please accept the terms and conditions
        </TextBody>
      )}
    </VStack>
  );
}

export function useTermsCheckboxState(): {
  hasAgreedToTerms: boolean;
  setHasAgreedToTerms: Dispatch<SetStateAction<boolean>>;
  hasCheckboxError: boolean;
  setHasCheckboxError: Dispatch<SetStateAction<boolean>>;
  resetCheckboxState: () => void;
} {
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
  const [hasCheckboxError, setHasCheckboxError] = useState(false);

  function resetCheckboxState(): void {
    setHasAgreedToTerms(false);
    setHasCheckboxError(false);
  }

  return {
    hasAgreedToTerms,
    setHasAgreedToTerms,
    hasCheckboxError,
    setHasCheckboxError,
    resetCheckboxState,
  };
}
