import { css } from '@linaria/core';

export const wordBreakWord = css`
  word-break: break-word;
`;

export const wordBreakAll = css`
  word-break: break-all;
`;

export const cursorPointer = css`
  cursor: pointer;
`;
