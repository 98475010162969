import { CodeBlockLanguage } from ':cloud/types/ts_types';
import { QuickstartItemProps } from ':cloud/widgets/product/QuickstartItem';

type CodeBlockContentProps = {
  language: CodeBlockLanguage;
  code: string;
};
export type ExchangeQuickstartStepProps = Omit<QuickstartItemProps, 'codeBlock'> & {
  codeBlockContent: CodeBlockContentProps[];
};

export const restSteps: ExchangeQuickstartStepProps[] = [
  {
    stepNumber: 1,
    title: 'Setup configurations in terminal',
    codeBlockContent: [
      {
        language: 'bash' as CodeBlockLanguage,
        code: `
# clone sample scripts
git clone https://github.com/coinbase-samples/exchange-scripts-py

# change directories and install dependencies within the REST folder
cd exchange-scripts-py/rest
pip install -r requirements.txt

# setup API key
export API_KEY=API_KEY_HERE
export PASSPHRASE=PASSPHRASE_HERE
export SECRET_KEY=SECRET_KEY_HERE

# setup profile ID
export PROFILE_ID=PROFILE_IDHERE
`.trim(),
      },
    ],
  },
  {
    stepNumber: 2,
    title: 'Run script to get profiles in terminal',
    codeBlockContent: [
      {
        language: 'bash' as CodeBlockLanguage,
        code: `python exchange_get_profiles.py`,
      },
    ],
  },
];

export const fixOrderEntrySteps: ExchangeQuickstartStepProps[] = [
  {
    stepNumber: 1,
    title: 'Setup configurations in terminal',
    codeBlockContent: [
      {
        language: 'bash' as CodeBlockLanguage,
        code: `
# clone sample scripts
git clone https://github.com/coinbase-samples/exchange-scripts-py

# change directories and install dependencies within the FIX folder
cd exchange-scripts-py/fix
pip install -r requirements.txt

# setup API key
export API_KEY=API_KEY_HERE
export PASSPHRASE=PASSPHRASE_HERE
export SECRET_KEY=SECRET_KEY_HERE
export PORTFOLIO_ID=PORTFOLIO_ID_HERE
export SVC_ACCOUNTID=SVC_ACCOUNT_ID_HERE

# setup FIX
export FIX_VERSION=FIXT.1.1
export DEFAULT_APPL_VER_ID=9
export TARGET_COMP_ID=Coinbase
`.trim(),
      },
    ],
  },
  {
    stepNumber: 2,
    title: 'Customize order details within build_create_order.py',
    codeBlockContent: [],
  },
  {
    stepNumber: 3,
    title: 'Run script to make an order in terminal',
    codeBlockContent: [
      {
        language: 'bash' as CodeBlockLanguage,
        code: `python client_create_order.py`,
      },
    ],
  },
];

export const fixMarketDataSteps: ExchangeQuickstartStepProps[] = [
  {
    stepNumber: 1,
    title: 'Setup configurations in terminal',
    codeBlockContent: [
      {
        language: 'bash' as CodeBlockLanguage,
        code: `
# clone sample scripts
git clone https://github.com/coinbase-samples/exchange-scripts-py

# change directories and install dependencies within the FIX market data folder
cd exchange-scripts-py/fix_md
pip install -r requirements.txt

# setup API key
export API_KEY=API_KEY_HERE
export PASSPHRASE=PASSPHRASE_HERE
export SECRET_KEY=SECRET_KEY_HERE
export PORTFOLIO_ID=PORTFOLIO_ID_HERE
export SVC_ACCOUNTID=SVC_ACCOUNT_ID_HERE

# setup FIX
export FIX_VERSION=FIXT.1.1
export DEFAULT_APPL_VER_ID=9
export TARGET_COMP_ID=Coinbase
`.trim(),
      },
    ],
  },
  {
    stepNumber: 2,
    title: 'Run script to connect to market data',
    codeBlockContent: [
      {
        language: 'bash' as CodeBlockLanguage,
        code: `python client_market_data.py`,
      },
    ],
  },
];

export const websocketMarketDataSteps: ExchangeQuickstartStepProps[] = [
  {
    stepNumber: 1,
    title: 'Setup configurations in terminal',
    codeBlockContent: [
      {
        language: 'bash' as CodeBlockLanguage,
        code: `
# clone sample scripts
git clone https://github.com/coinbase-samples/exchange-scripts-py

# change directories and install dependencies within the Websockets folder
cd exchange-scripts-py/websocket
pip install -r requirements.txt

# setup API key
export API_KEY=API_KEY_HERE
export PASSPHRASE=PASSPHRASE_HERE
export SECRET_KEY=SECRET_KEY_HERE
`.trim(),
      },
    ],
  },
  {
    stepNumber: 2,
    title: 'Run script to connect to L2 data feed in terminal',
    codeBlockContent: [
      {
        language: 'bash' as CodeBlockLanguage,
        code: `python auth_ws_channel.py`,
      },
    ],
  },
];
