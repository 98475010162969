import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBaseRPC } from ':cloud/state/Api';
import { PaymasterBalanceResponse } from ':cloud/types/service_proto';

async function fetchRemainingPaymasterBalance(
  tokenId?: string,
  isTestnet?: boolean,
): Promise<number | undefined> {
  const path = '/rpc/remainingPaymasterBalance';

  const { data } = await APIClientBaseRPC.post<PaymasterBalanceResponse>(
    path,
    { tokenId, network: isTestnet ? 'base-sepolia' : 'base' },
    { withCredentials: false },
  );

  return data?.remainingCredits;
}

type GetRemainingPaymasterBalanceProps = {
  tokenId: string;
  isTestnet: boolean;
  onSuccess?: (response: number | undefined) => void;
};

export function useGetRemainingPaymasterBalance({
  tokenId,
  isTestnet,
  onSuccess,
}: GetRemainingPaymasterBalanceProps) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      return fetchRemainingPaymasterBalance(tokenId, isTestnet);
    },
    onSuccess: async (data) => {
      onSuccess?.(data);
      queryClient.setQueryData([QueryKey.paymasterBalance, tokenId], data);
    },
  });
}
