import { useEffect } from 'react';
import { logMetric as clientAnalyticsLogMetric, MetricType } from '@cbhq/client-analytics';

type MetricName =
  | 'general_api_key_create'
  | 'trading_api_key_create'
  | 'accept_tos'
  | 'cdp_api_key_create';
type LogMetricName = `attempt_${MetricName}` | `successful_${MetricName}` | `view_${MetricName}`;

export function logAttemptMetric(
  metricName: MetricName,
  metricType?: MetricType,
  value?: number,
  tags?: Record<string, string | boolean>,
) {
  logMetric(`attempt_${metricName}`, metricType, value, tags);
}

export function logSuccessMetric(
  metricName: MetricName,
  metricType?: MetricType,
  value?: number,
  tags?: Record<string, string | boolean>,
) {
  logMetric(`successful_${metricName}`, metricType, value, tags);
}

export function useLogViewMetricOnMount(
  metricName: MetricName,
  metricType?: MetricType,
  value?: number,
  tags?: Record<string, string | boolean>,
) {
  useEffect(() => {
    logMetric(`view_${metricName}`, metricType, value, tags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

// send to Datadog via client-analytics
// note that tags are not an array. see https://docs.cbhq.net/frontend/analytics/log-metrics#best-practices
function logMetric(metricName: LogMetricName, metricType = MetricType.count, value = 1, tags = {}) {
  clientAnalyticsLogMetric({
    metricName: `cloud_developer_portal.${metricName}`,
    metricType,
    value,
    tags,
  });
}
