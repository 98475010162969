import { useQueries } from '@tanstack/react-query';
import { Axios } from 'axios';

import { useIsCOMSWriteEnabled } from ':cloud/hooks/featureFlags/useIsCOMSWriteEnabled';
import { QueryKey } from ':cloud/queries/types';
import { APIClientBase, APIClientRM } from ':cloud/state/Api';

interface Permissions {
  /** edit organization name */
  manageOrgName: boolean;
  /** invite users and revoke permissions for team members */
  manageUsers: boolean;
  /** create, delete or update number of running resources */
  manageResources: boolean;
  /** update team billing information  */
  manageBilling: boolean;
}

async function postOrgPermissions(orgId: string, path: string, Client: Axios): Promise<boolean> {
  const { data } = await Client.post<boolean>(path, {
    resource: `organization:${orgId}:name`,
    action: 'write',
  });
  return data;
}
async function postManageUsersPermissions(
  orgId: string,
  path: string,
  Client: Axios,
): Promise<boolean> {
  const { data } = await Client.post<boolean>(path, {
    resource: `organization:${orgId}:user:*`,
    action: 'write',
  });
  return data;
}
async function postManageResourcesPermissions(
  orgId: string,
  path: string,
  Client: Axios,
): Promise<boolean> {
  const { data } = await Client.post<boolean>(path, {
    resource: `organization:${orgId}:cluster:*`,
    action: 'write',
  });
  return data;
}
async function postBillingPermissions(
  orgId: string,
  path: string,
  Client: Axios,
): Promise<boolean> {
  const { data } = await Client.post<boolean>(path, {
    resource: `organization:${orgId}`,
    action: 'manageBilling',
  });
  return data;
}

const PATH = '/v1/can';
function generateUserPermissionsQueriesLEGACY(orgId: string) {
  return [
    {
      queryKey: [QueryKey.permissions, 'manage-org-name', orgId],
      queryFn: async () => postOrgPermissions(orgId, PATH, APIClientRM),
      enabled: !!orgId,
      staleTime: 60 * 1000,
    },
    {
      queryKey: [QueryKey.permissions, 'manage-users', orgId],
      queryFn: async () => postManageUsersPermissions(orgId, PATH, APIClientRM),
      enabled: !!orgId,
      staleTime: 60 * 1000,
    },
    {
      queryKey: [QueryKey.permissions, 'manage-clusters', orgId],
      queryFn: async () => postManageResourcesPermissions(orgId, PATH, APIClientRM),
      enabled: !!orgId,
      staleTime: 60 * 1000,
    },
    {
      queryKey: [QueryKey.permissions, 'manage-billing', orgId],
      queryFn: async () => postBillingPermissions(orgId, PATH, APIClientRM),
      enabled: !!orgId,
      staleTime: 60 * 1000,
    },
  ];
}

const COMS_PATH = '/organization-management/v1/can';
function generateUserPermissionsQueries(orgId: string) {
  return [
    {
      queryKey: [QueryKey.permissions, 'manage-org-name', orgId],
      queryFn: async () => postOrgPermissions(orgId, COMS_PATH, APIClientBase),
      enabled: !!orgId,
      staleTime: 60 * 1000,
      select: (data: { allowed: boolean }) => data?.allowed,
    },
    {
      queryKey: [QueryKey.permissions, 'manage-users', orgId],
      queryFn: async () => postManageUsersPermissions(orgId, COMS_PATH, APIClientBase),
      enabled: !!orgId,
      staleTime: 60 * 1000,
      select: (data: { allowed: boolean }) => data?.allowed,
    },
    {
      queryKey: [QueryKey.permissions, 'manage-clusters', orgId],
      queryFn: async () => postManageResourcesPermissions(orgId, COMS_PATH, APIClientBase),
      enabled: !!orgId,
      staleTime: 60 * 1000,
      select: (data: { allowed: boolean }) => data?.allowed,
    },
    {
      queryKey: [QueryKey.permissions, 'manage-billing', orgId],
      queryFn: async () => postBillingPermissions(orgId, COMS_PATH, APIClientBase),
      enabled: !!orgId,
      staleTime: 60 * 1000,
      select: (data: { allowed: boolean }) => data?.allowed,
    },
  ];
}

function generatePermissionsResponse(queries?: { data?: boolean }[]) {
  return {
    manageOrgName: queries?.[0]?.data || false,
    manageUsers: queries?.[1]?.data || false,
    manageResources: queries?.[2]?.data || false,
    manageBilling: queries?.[3]?.data || false,
  };
}

export function useGetUserPermissions(orgId = ''): {
  isLoading: boolean;
  isError: boolean;
  permissions: Permissions;
} {
  const shouldUseCOMSEndpoint = useIsCOMSWriteEnabled();

  const queries = useQueries({
    queries: shouldUseCOMSEndpoint
      ? generateUserPermissionsQueries(orgId)
      : generateUserPermissionsQueriesLEGACY(orgId),
  });

  const isError = queries.some((query) => query.isError);

  const permissions = generatePermissionsResponse(queries || []);

  return {
    isLoading: queries.some((query) => query.isLoading),
    isError,
    permissions,
  };
}
