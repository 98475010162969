import { ReactNode, useCallback, useMemo, useState } from 'react';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Alert } from '@cbhq/cds-web/overlays';
import { PortalProvider } from '@cbhq/cds-web/overlays/PortalProvider';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { getUserContext } from '@cbhq/client-analytics';
import { getIsLocalDev } from '@cbhq/session-manager-auth';

import config from ':cloud/config';
import { logError } from ':cloud/init/bugsnag/logging';
import { CLOUD_HELP } from ':cloud/utils/links';
import { CloudErrorBoundary } from ':cloud/widgets/sharedcomponents';

/*
 * These are the endpoints for which 401s definitely indicate an expired
 * session and for which we show the session expiry modal.
 * This doesn't include endpoints that may return 401s for other reasons,
 * such as /v1/session, waas endpoints, etc.
 */
const ENDPOINTS_INDICATING_SESSION_EXPIRY = [
  '/v1/can',
  '/v1/self',
  '/v1/organizations',
  '/v1/resources',
  '/v1/customers/invites',
  '/cloud-metrics/v1/query',
];

type QueryClientProviderWrapperProps = { children: ReactNode | ReactNode[] };

export function QueryClientProviderWrapper({ children }: QueryClientProviderWrapperProps) {
  const toast = useToast();
  const userContext = getUserContext();

  const isLocalDev = getIsLocalDev();
  const openSupport = useCallback(() => window.open(CLOUD_HELP, '_blank'), []);

  const [isSessionExpiryAlertVisible, setIsSessionExpiryAlertVisible] = useState(false);

  const handleSignInPress = useCallback(() => {
    window.location.href = config.loginURL;
  }, []);

  const handleCloseAlert = useCallback(() => {
    setIsSessionExpiryAlertVisible(false);
  }, []);

  /** queryClient should maintain same instance on re-renders */
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: false,
          },
        },
        mutationCache: new MutationCache({
          onError: (error, variables, ctx, { meta }): void => {
            if (meta?.errorMsg && meta?.triggerSupport) {
              toast.show(meta.errorMsg as string, {
                variant: 'negative',
                action: { label: 'Contact Support', onPress: openSupport },
              });
            } else if (meta?.errorMsg) {
              toast.show(meta?.errorMsg as string, { variant: 'negative' });
            }
          },
          onSuccess: (data, variables, ctx, { meta }): void => {
            /* success message with delay timer */
            if (meta?.successMsg && meta?.delayTimer) {
              setTimeout(() => {
                toast.show(meta.successMsg as string, { variant: 'positive' });
              }, meta.delayTimer as number);
            } else if (meta?.successMsg) {
              toast.show(meta.successMsg as string, { variant: 'positive' });
            }
          },
        }),
        queryCache: new QueryCache({
          onError: (error, { meta }): void => {
            if (config.isMocksEnabled()) {
              return;
            }

            if (error instanceof AxiosError) {
              const isRelevantEndpoint = ENDPOINTS_INDICATING_SESSION_EXPIRY.some((endpointBase) =>
                String(error.config?.url).includes(endpointBase),
              );
              /**
               * We're making the assumption that all 401s imply an invalid/expired session
               * and directing users to a modal enabling them to sign in again
               */
              if (error.response?.status === 401 && isRelevantEndpoint && !isLocalDev) {
                const sessionExpiryError = new Error('Session expired');
                sessionExpiryError.cause = error;
                logError(
                  sessionExpiryError,
                  { context: 'query_client_provider_wrapper', severity: 'debug' },
                  userContext,
                );
                setIsSessionExpiryAlertVisible(true);
              } else {
                const responseData = error.response?.data;
                logError(
                  error,
                  { context: 'query_client_provider_wrapper' },
                  { ...userContext, responseData },
                );
              }
            }

            // notify if useQuery showToast notifier is specified
            if (meta?.showToast && error instanceof Error) {
              // QRT-358: @artivilla pass error message from error.message vs meta.message
              // setGlobalNotifier((error as Error).name);
              toast.show(meta.errorMsg as string, { variant: 'negative' });
            }
          },
        }),
      }),
    [toast, openSupport, isLocalDev, userContext],
  );

  return (
    <CloudErrorBoundary name="query_client_provider_wrapper">
      <QueryClientProvider client={queryClient}>
        <PortalProvider>
          {children}
          <Alert
            title="Your session has expired"
            body="Your session has expired or is invalid. Please sign in again."
            pictogram="warning"
            visible={isSessionExpiryAlertVisible}
            onRequestClose={handleCloseAlert}
            preferredActionLabel="Sign in"
            onPreferredActionPress={handleSignInPress}
          />
        </PortalProvider>
      </QueryClientProvider>
    </CloudErrorBoundary>
  );
}
