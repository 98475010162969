import { navigationBarMinHeight } from '@cbhq/cds-common/tokens/navigation';
import { PopoverContentPositionConfig } from '@cbhq/cds-web/overlays/popover/PopoverProps';

import config from ':cloud/config';

export const NOTIFIICATIONS_DROPDOWN_WIDTH = 381;
export const DROPDOWN_HEIGHT = 600;
export const SCROLLBAR_WIDTH = 10;
export const DROPDOWN_CONTENT_POSITION = {
  placement: 'bottom-start',
  gap: 1,
} as PopoverContentPositionConfig;

const ENV_BANNER_HEIGHT = 34;
export const TOTAL_TOP_NAV_HEIGHT =
  navigationBarMinHeight + (config.isProduction() ? 0 : ENV_BANNER_HEIGHT);
