import { TabNavigationProps } from '@cbhq/cds-common';
import { zIndex } from '@cbhq/cds-common/tokens/zIndex';
import { Box } from '@cbhq/cds-web/layout';
import { TabNavigation } from '@cbhq/cds-web/tabs';

/**
 * If you want `NavigationBar`-style tabs but don't need to change the URL when the tab changes, use this component.
 */
export function UnroutedTopNavTabs({ value, tabs, onChange }: TabNavigationProps) {
  return (
    <Box
      spacingStart={1}
      borderedBottom
      width="100%"
      position="relative"
      top="-2px"
      background="background"
      zIndex={zIndex.navigation}
    >
      <TabNavigation value={value} tabs={tabs} onChange={onChange} spacingHorizontal={5} />
    </Box>
  );
}
