import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@cbhq/cds-web/overlays/useToast';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { GasPolicyResponse } from ':cloud/types/service_proto';
import { GasPolicy, UpdateGasPolicy } from ':cloud/types/ts_types';

const updateGasPolicyErrorMsg = 'An error occurred updating gas policy.';
const updateGasPolicySuccessMsg = 'Gas policy updated.';

async function updateGasPolicy(
  policy: UpdateGasPolicy,
  organizationId?: string,
  projectId?: string,
): Promise<GasPolicy> {
  if (!organizationId || !projectId) {
    throw new Error('Missing required information. Cannot update gas policy.');
  }
  const path = `/apikeys/v1/organizations/${organizationId}/projects/${projectId}/policies/${policy.id}`;

  const { data } = await APIClientBase.put<GasPolicyResponse>(path, policy);

  return data?.policy;
}

type UpdateGasPolicyProps = {
  organizationId: string;
  projectId: string;
  onSuccess?: () => void;
  successMsg?: string;
  previousPolicy?: GasPolicy;
};

type UpdateAccountAbstractionStatusProps = {
  organizationId?: string;
  projectId: string;
  successMsg?: string;
};

type UpdateGasPolicyMutationProps = {
  policy: UpdateGasPolicy;
};

/* We need this additional function so that we can display
the appropriate success message when a user enables/disables
account abstraction */
export function useUpdateAccountAbstractionStatus({
  organizationId,
  projectId,
  successMsg,
}: UpdateAccountAbstractionStatusProps) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ policy }: UpdateGasPolicyMutationProps) =>
      updateGasPolicy(policy, organizationId, projectId),
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKey.gasPolicy]);
      await queryClient.invalidateQueries([QueryKey.tokens, organizationId, projectId]);
    },
    meta: {
      successMsg,
    },
  });
}

export function useUpdateGasPolicy({
  organizationId,
  projectId,
  onSuccess,
  successMsg,
  previousPolicy,
}: UpdateGasPolicyProps) {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ policy }: UpdateGasPolicyMutationProps) =>
      updateGasPolicy(policy, organizationId, projectId),
    onSuccess: async (gasPolicy) => {
      await queryClient.invalidateQueries([QueryKey.gasPolicy]);
      await queryClient.invalidateQueries([QueryKey.tokens, organizationId, projectId]);
      onSuccess?.();
      if (successMsg) {
        toast.show(successMsg, { variant: 'positive' });
        return;
      }
      if (gasPolicy?.perAddrMaxEnabled !== previousPolicy?.perAddrMaxEnabled) {
        toast.show(`Per user limit ${gasPolicy?.perAddrMaxEnabled ? 'enabled' : 'disabled'}`, {
          variant: 'positive',
        });
        return;
      }
      if (
        gasPolicy?.maxGasPerUserOperationEnabled !== previousPolicy?.maxGasPerUserOperationEnabled
      ) {
        toast.show(
          `Per User Operation limit ${
            gasPolicy?.maxGasPerUserOperationEnabled ? 'enabled' : 'disabled'
          }`,
          { variant: 'positive' },
        );
        return;
      }
      if (gasPolicy?.maxGasPerUserOperationUsd !== previousPolicy?.maxGasPerUserOperationUsd) {
        toast.show('Per User Operation limit updated', { variant: 'positive' });
        return;
      }
      if (
        gasPolicy?.maxGasPerAddrUsd !== previousPolicy?.maxGasPerAddrUsd ||
        gasPolicy?.maxTxnPerAddr !== previousPolicy?.maxTxnPerAddr ||
        gasPolicy?.intervalType !== previousPolicy?.intervalType
      ) {
        toast.show('Per user limit updated', { variant: 'positive' });
        return;
      }
      if (
        gasPolicy?.maxGlobalGasUsd !== previousPolicy?.maxGlobalGasUsd ||
        gasPolicy?.maxGlobalTxn !== previousPolicy?.maxGlobalTxn
      ) {
        toast.show('Global limit updated', { variant: 'positive' });
        return;
      }
      toast.show(updateGasPolicySuccessMsg, { variant: 'positive' });
    },
    meta: {
      errorMsg: updateGasPolicyErrorMsg,
    },
  });
}
