import { useCallback } from 'react';
import { Button, ButtonGroup, NavigationIconButton } from '@cbhq/cds-web/buttons';
import { DotCount } from '@cbhq/cds-web/dots/DotCount';
import { Dropdown } from '@cbhq/cds-web/dropdown';
import { NavigationIcon } from '@cbhq/cds-web/icons';
import { HeroSquare } from '@cbhq/cds-web/illustrations';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { Avatar } from '@cbhq/cds-web/media';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { ThemeProvider } from '@cbhq/cds-web/system';
import { TextBody, TextHeadline, TextLabel1, TextTitle3 } from '@cbhq/cds-web/typography';

import { useNavigationContext } from ':cloud/contexts/NavigationContext';
import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { useUnseenInvites } from ':cloud/hooks/useUnseenInvites';
import { errorMsg, useAcceptInvite } from ':cloud/queries/NotificationQueries/useAcceptInvite';
import { useRejectInvite } from ':cloud/queries/NotificationQueries/useRejectInvite';
import { useGetOrganizationInvites } from ':cloud/queries/OrganizationQueries/useGetOrganizationInvites';
import { getFormattedTime } from ':cloud/utils/date';
import {
  DROPDOWN_CONTENT_POSITION,
  DROPDOWN_HEIGHT,
  NOTIFIICATIONS_DROPDOWN_WIDTH,
  SCROLLBAR_WIDTH,
} from ':cloud/widgets/navigation/constants';

interface EmptyNotificationStateProps {
  onClose?: () => void;
}

function EmptyNotificationState({ onClose }: EmptyNotificationStateProps) {
  return (
    <VStack gap={10} spacingHorizontal={5} spacingTop={9} spacingBottom={7}>
      <VStack gap={3} alignItems="center">
        <HeroSquare name="notificationsAlt" scaleMultiplier={0.5} />
        <TextHeadline as="h1">No notifications</TextHeadline>
        <TextBody as="p" color="foregroundMuted">
          You have no notifications available at this time
        </TextBody>
      </VStack>
      <Button variant="secondary" onPress={onClose}>
        Got it
      </Button>
    </VStack>
  );
}

interface NotificationsDropdownContentProps {
  invitesLeft: number;
  onClose?: () => void;
}

function NotificationsDropdownContent({ invitesLeft, onClose }: NotificationsDropdownContentProps) {
  const { data, isLoading: areInvitesLoading } = useGetOrganizationInvites();
  const acceptInvite = useAcceptInvite();
  const rejectInvite = useRejectInvite();
  const toast = useToast();

  const handleAcceptInvite = useCallback(
    (sendersOrganizationId) => {
      const invite = data.find((inv) => inv.organization.id === sendersOrganizationId);
      if (!invite) {
        toast.show(errorMsg, {
          variant: 'negative',
        });
        return;
      }
      /* close after last invite is acknowledged */
      acceptInvite.mutate(sendersOrganizationId, {
        onSuccess: () => {
          if (invite) {
            toast.show(`You have successfully joined ${invite.organization.name}`, {
              variant: 'positive',
            });
            return;
          }
          if (invitesLeft <= 1) {
            onClose?.();
          }
        },
      });
    },
    [acceptInvite, data, invitesLeft, onClose, toast],
  );

  const handleRejectInvite = useCallback(
    (sendersOrganizationId) => {
      const invite = data.find((inv) => inv.organization.id === sendersOrganizationId);
      if (!invite) {
        toast.show(errorMsg, {
          variant: 'negative',
        });
        return;
      }
      rejectInvite.mutate(sendersOrganizationId, {
        onSuccess: () => {
          if (invite) {
            toast.show(`You have declined the invite from ${invite.organization.name}`, {
              variant: 'negative',
            });
          }
          if (invitesLeft <= 1) {
            onClose?.();
          }
        },
      });
    },
    [data, invitesLeft, onClose, rejectInvite, toast],
  );

  const isEmptyState = Array.isArray(data) && !data?.length;

  const notificationsContents = !isEmptyState ? (
    data?.map((invite) => {
      return (
        <VStack key={invite.id}>
          <NotificationCell
            name={invite.organization.name}
            category="Organization"
            meta={getFormattedTime(invite.createdAt)}
            primaryAction={() => handleAcceptInvite(invite.organization.id)}
            secondaryAction={() => handleRejectInvite(invite.organization.id)}
            isLoading={acceptInvite.isLoading || rejectInvite.isLoading}
          >
            <VStack gap={1}>
              {/* content block */}
              <div>
                <TextBody as="span">
                  You&apos;ve been invited to{' '}
                  <TextHeadline as="span">{invite.organization.name}</TextHeadline> by:{' '}
                </TextBody>
                <TextBody as="p" color="primary">
                  {invite.invitedByUser.email}
                </TextBody>
              </div>
            </VStack>
          </NotificationCell>
        </VStack>
      );
    })
  ) : (
    <EmptyNotificationState onClose={onClose} />
  );

  return (
    <VStack spacingTop={3}>
      <TextTitle3 as="h1" overflow="wrap" spacingVertical={3} spacingHorizontal={3}>
        Notifications
      </TextTitle3>
      {areInvitesLoading ? <EmptyNotificationState /> : notificationsContents}
    </VStack>
  );
}

interface NotificationCellProps {
  name: string;
  category: string;
  children: React.ReactNode;
  meta: string;
  primaryAction: () => void;
  secondaryAction: () => void;
  isLoading: boolean;
}

function NotificationCell({
  name,
  category,
  children,
  meta,
  primaryAction,
  secondaryAction,
  isLoading,
}: NotificationCellProps) {
  return (
    <VStack spacing={2} spacingStart={3} spacingEnd={4}>
      <HStack gap={2} justifyContent="space-between" alignItems="center">
        <Avatar size="xxl" alt={name} name={name} />
        <VStack gap={0.5} width="100%" flexGrow={1}>
          {/* meta fields */}
          <HStack justifyContent="space-between" alignContent="center">
            <TextLabel1 as="p" color="foregroundMuted">
              {category}
            </TextLabel1>
            <TextLabel1 as="p" color="foregroundMuted">
              {meta}
            </TextLabel1>
          </HStack>
          {children}
        </VStack>
      </HStack>

      {/* notification actions */}
      <VStack spacingTop={1} spacingHorizontal={10}>
        <ButtonGroup block accessibilityLabel="Group">
          <Button compact disabled={isLoading} variant="secondary" onPress={secondaryAction}>
            Decline
          </Button>
          <Button compact disabled={isLoading} onPress={primaryAction}>
            Accept
          </Button>
        </ButtonGroup>
      </VStack>
    </VStack>
  );
}
type NotificationsDropdownProps = { buttonVariant?: boolean };

export function NotificationsDropdown({ buttonVariant }: NotificationsDropdownProps) {
  const { isPhone } = useSimpleBreakpoints();
  const { data: pendingInvites } = useGetOrganizationInvites();
  const { notificationRef } = useNavigationContext();
  const { unseenInvites } = useUnseenInvites(pendingInvites);
  const hasInvites = pendingInvites?.length > 0;

  const closeDropdown = useCallback(() => {
    notificationRef?.current?.closeMenu();
  }, [notificationRef]);

  return (
    <Dropdown
      ref={notificationRef}
      content={
        <ThemeProvider scale="xSmall">
          <NotificationsDropdownContent
            invitesLeft={pendingInvites.length}
            onClose={closeDropdown}
          />
        </ThemeProvider>
      }
      contentPosition={DROPDOWN_CONTENT_POSITION}
      maxWidth={NOTIFIICATIONS_DROPDOWN_WIDTH - SCROLLBAR_WIDTH}
      maxHeight={DROPDOWN_HEIGHT}
      enableMobileModal
    >
      {unseenInvites.length > 0 ? (
        <DotCount count={pendingInvites.length} pin="top-end">
          {isPhone && !buttonVariant ? (
            <NavigationIcon accessibilityLabel="Notifier Menu" name="bell" size="m" />
          ) : (
            <NavigationIconButton accessibilityLabel="Notifier Menu" name="bell" />
          )}
        </DotCount>
      ) : (
        <div>
          {isPhone && !buttonVariant ? (
            <NavigationIcon
              active={hasInvites}
              accessibilityLabel="Notifier Menu"
              name="bell"
              size="m"
            />
          ) : (
            <NavigationIconButton
              active={hasInvites}
              accessibilityLabel="Notifier Menu"
              name="bell"
            />
          )}
        </div>
      )}
    </Dropdown>
  );
}
