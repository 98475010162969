import { ReactComponent as AmexIcon } from ':cloud/assets/Billing/amex-square.svg';
import { ReactComponent as DinersIcon } from ':cloud/assets/Billing/diners-square.svg';
import { ReactComponent as DiscoverIcon } from ':cloud/assets/Billing/discover-square.svg';
import { ReactComponent as JcbIcon } from ':cloud/assets/Billing/jcb-square.svg';
import { ReactComponent as MastercardIcon } from ':cloud/assets/Billing/mastercard-square.svg';
import { ReactComponent as UnionPayIcon } from ':cloud/assets/Billing/unionpay-square.svg';
import { ReactComponent as VisaIcon } from ':cloud/assets/Billing/visa-square.svg';
import { PaymentCard } from ':cloud/types/service_proto';
import { getFormattedDateExtended } from ':cloud/utils/date';

export function isCardDateValid(card?: PaymentCard | null): boolean {
  if (!card?.expirationMonth || !card?.expirationYear) {
    return false;
  }

  const { expirationMonth, expirationYear } = card;
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  if (currentYear === expirationYear) {
    return currentMonth <= expirationMonth;
  }

  return currentYear <= expirationYear;
}

const BRANDED_CARD_SIZE = 32;
const CARD_ICON_PROPS: React.SVGProps<SVGSVGElement> = {
  width: BRANDED_CARD_SIZE,
  height: BRANDED_CARD_SIZE,
  style: { borderRadius: '4px' },
};

export function getCardMedia(cardType: string | undefined, fallback: JSX.Element) {
  switch (cardType) {
    case 'amex':
      return <AmexIcon {...CARD_ICON_PROPS} />;
    case 'diners':
      return <DinersIcon {...CARD_ICON_PROPS} />;
    case 'discover':
      return <DiscoverIcon {...CARD_ICON_PROPS} />;
    case 'jcb':
      return <JcbIcon {...CARD_ICON_PROPS} />;
    case 'mastercard':
      return <MastercardIcon {...CARD_ICON_PROPS} />;
    case 'unionpay':
      return <UnionPayIcon {...CARD_ICON_PROPS} />;
    case 'visa':
      return <VisaIcon {...CARD_ICON_PROPS} />;
    default:
      return fallback;
  }
}

export function getNextBillDate() {
  const today = new Date();
  return getFormattedDateExtended(new Date(today.getFullYear(), today.getMonth() + 1, 1));
}

export function getCurrentBillingCycle() {
  const today = new Date();
  const currentMonth = today.toLocaleString('default', { month: 'long' });
  const currentYear = today.getFullYear();
  const firstDayOfMonth = new Date(currentYear, today.getMonth(), 1);
  const lastDayOfMonth = new Date(currentYear, today.getMonth() + 1, 0);

  const formattedFirstDay = firstDayOfMonth.toLocaleDateString('default', { day: '2-digit' });
  const formattedLastDay = lastDayOfMonth.toLocaleDateString('default', { day: '2-digit' });

  return `${currentMonth} ${formattedFirstDay} - ${currentMonth} ${formattedLastDay}`;
}

export function getCurrentYearAndMonth() {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because getMonth() returns zero-based index
  return `${year}-${month}`;
}
