import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientRM } from ':cloud/state/Api';
import { CreateResourceResponse } from ':cloud/types/service_proto';
import { ICluster, IFormDataType } from ':cloud/types/ts_types';

const errorMsg = 'An error occurred creating your cluster. Please retry';
const successMsg = 'Cluster added! Initializing now...';

export const CLUSTER_CREATION_TIME = 7 * 1000;
async function createResource(
  formData: Partial<IFormDataType>,
  workflowVersion?: string,
): Promise<CreateResourceResponse> {
  if (!formData?.name || !formData?.protocolKey) {
    throw new Error('Missing form data');
  }
  const clusterOptions = { ...formData };
  /** remove protocolKey prior to creating cluster as its already in path */
  const createClusterReq = { ...clusterOptions, protocolKey: undefined };
  let path = `/v1/resources/${encodeURIComponent(formData.protocolKey)}/${encodeURIComponent(
    formData.name,
  )}`;

  if (workflowVersion) {
    path = path.concat(`/${workflowVersion}`);
  }

  const { data } = await APIClientRM.post<CreateResourceResponse>(path, createClusterReq);
  return data;
}

export function useCreateResource(formData?: Partial<IFormDataType>, workflowVersion?: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      if (!formData) {
        throw new Error('Cluster form data is missing. Cannot create a cluster.');
      }
      return createResource(formData, workflowVersion);
    },
    onMutate: async () => {
      const previousResources = queryClient.getQueryData<ICluster[]>([QueryKey.resources]);
      if (previousResources) {
        const optimisticallyUpdatedResource = {
          artifacts: {},
          clusterType: 'participation',
          configuration: {},
          created: new Date(),
          id: 'optimistic-id',
          name: formData?.name,
          ownerId: '',
          state: 'BEING_CREATED',
          type: formData?.protocolKey,
          updated: new Date(),
          version: '',
        };

        /** update optimistically */
        queryClient.setQueryData(
          [QueryKey.resources],
          [...previousResources, optimisticallyUpdatedResource],
        );
      }
      /* previous state returned to onError and onSuccess cb */
      return { previousResources };
    },
    onError: (err, newResource, context) => {
      if (context?.previousResources) {
        queryClient.setQueryData([QueryKey.resources], context.previousResources);
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKey.resources]);
    },
    /** delay success message for 7 seconds until the Transition screen is visible */
    meta: {
      triggerSupport: true,
      errorMsg,
      successMsg,
      delayTimer: CLUSTER_CREATION_TIME,
    },
  });
}
