import { VStack } from '@cbhq/cds-web/layout';

import { ChartWidgetType } from ':cloud/types/helper';
import { deriveValueFromMetrics } from ':cloud/widgets/charts/ChartUtils';
import ValidatorCount from ':cloud/widgets/charts/ValidatorCount';
import { TextLabelGray } from ':cloud/widgets/sharedcomponents';

export function ValidatorCountWidget({
  sources,
  options,
  metricsData,
  configuration,
  isDynamic,
  pod,
}: ChartWidgetType) {
  const validatorVal = deriveValueFromMetrics(
    sources[0],
    configuration,
    isDynamic,
    metricsData,
    pod,
  );
  return (
    <VStack justifyContent="space-between" width={88} height={135}>
      <TextLabelGray align="center">{options.label}</TextLabelGray>
      <ValidatorCount active={validatorVal ? Number(validatorVal) : undefined} />
    </VStack>
  );
}
