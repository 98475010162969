import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useToggler } from '@cbhq/cds-common/hooks/useToggler';
import { Button, ButtonGroup } from '@cbhq/cds-web/buttons';
import { Collapsible } from '@cbhq/cds-web/collapsible/Collapsible';
import { TextInput } from '@cbhq/cds-web/controls';
import { NavigationIcon } from '@cbhq/cds-web/icons';
import { Box, Grid, HStack, VStack } from '@cbhq/cds-web/layout';
import { ThemeProvider } from '@cbhq/cds-web/system';
import { palette } from '@cbhq/cds-web/tokens';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

import { ReactComponent as AuthPreviewImg } from ':cloud/assets/Products/WaaSAuthPreview.svg';
import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { useCreateProductDetailsWithMessage } from ':cloud/queries/Projects/useCreateProductDetails';
import { useGetProductDetails } from ':cloud/queries/Projects/useGetProductDetails';
import { useUpdateProductDetailsWithMessage } from ':cloud/queries/Projects/useUpdateProductDetails';
import { ProductTypeMap, Project, WaasConsumerApp } from ':cloud/types/ts_types';
import { ERROR_SUCCESS_MESSAGES } from ':cloud/widgets/waasConsumer/common';

type ProductDetailsSectionProps = {
  project: Project;
};

function isValidURI(uri: string) {
  try {
    // eslint-disable-next-line
    new URL(uri);
    return true;
  } catch {
    return false;
  }
}

type TFormState = {
  dappName?: string;
  logoUri?: string;
  faviconUri?: string;
};

const CUSTOM_PALETTE_NAV_ICON = {
  foreground: 'gray0' as const,
} as const;

export function CoinbaseAuthSection({ project }: ProductDetailsSectionProps) {
  const productType = ProductTypeMap.waas_consumer;
  const { productDetails } = useGetProductDetails(project, productType);
  const [formState, setFormState] = useState<TFormState>({});
  const [isFormEditable, { toggle }] = useToggler();

  const { isDesktop } = useSimpleBreakpoints();
  const responsiveTemplateColumns = useMemo(() => {
    return isDesktop ? '2fr 1fr' : '1fr';
  }, [isDesktop]);

  useEffect(() => {
    if (productDetails) {
      setFormState({
        dappName: productDetails.waasConsumer?.appName,
        logoUri: productDetails.waasConsumer?.logo?.externalUri,
        faviconUri: productDetails.waasConsumer?.favicon?.externalUri,
      });
    }
  }, [productDetails]);

  const createProductDetails = useCreateProductDetailsWithMessage(ERROR_SUCCESS_MESSAGES);
  const updateProductDetails = useUpdateProductDetailsWithMessage(ERROR_SUCCESS_MESSAGES);

  const updateEuaProductDetails = useCallback(
    (updatedDetails: TFormState, onSettled) => {
      if (!updatedDetails.logoUri || !updatedDetails.faviconUri || !updatedDetails.dappName) {
        return;
      }

      const newProductDetails = {
        waasConsumer: {
          ...productDetails?.waasConsumer,
          logo: {
            externalUri: updatedDetails.logoUri,
          },
          favicon: {
            externalUri: updatedDetails.faviconUri,
          },
          appName: updatedDetails.dappName,
        } as WaasConsumerApp,
      };

      const mutation = productDetails ? updateProductDetails : createProductDetails;
      mutation.mutate({ project, productType, productDetails: newProductDetails }, { onSettled });
    },
    [createProductDetails, updateProductDetails, productType, project, productDetails],
  );

  const isDappNameValid = useMemo(() => {
    return formState.dappName && formState.dappName.length <= 32;
  }, [formState.dappName]);

  const isLogoURIValid = useMemo(() => {
    return formState.logoUri && isValidURI(formState.logoUri);
  }, [formState.logoUri]);

  const isFaviconURIValid = useMemo(() => {
    return formState.faviconUri && isValidURI(formState.faviconUri);
  }, [formState.faviconUri]);

  const formValid = isDappNameValid && isLogoURIValid && isFaviconURIValid;

  const trySubmitFormData = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      updateEuaProductDetails(formState, () => {
        toggle();
      });
    },
    [updateEuaProductDetails, toggle, formState],
  );

  return (
    <VStack bordered borderRadius="rounded" spacing={4}>
      <HStack alignItems="center" gap={2}>
        <Box
          dangerouslySetBackground={palette.foreground}
          height={24}
          width={24}
          borderRadius="roundedFull"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <ThemeProvider palette={CUSTOM_PALETTE_NAV_ICON}>
            <NavigationIcon name="key" size="s" />
          </ThemeProvider>
        </Box>
        <VStack gap={2} flexGrow={1}>
          <VStack>
            <TextHeadline as="h1">Coinbase Managed Authentication (optional)</TextHeadline>
            <TextBody as="p" color="foregroundMuted">
              Use Coinbase’s 3rd-Party Login UI to authenticate your users.
            </TextBody>
          </VStack>
        </VStack>
        {!isFormEditable && (
          <Button
            variant="secondary"
            disabled={!productDetails?.waasConsumer?.domainOrigin}
            onPress={toggle}
          >
            Edit
          </Button>
        )}
      </HStack>
      <Collapsible collapsed={!isFormEditable}>
        <Grid templateColumns={responsiveTemplateColumns} gap={10}>
          <form style={{ width: '100%' }} onSubmit={trySubmitFormData}>
            <VStack gap={3} spacingStart={8} spacingTop={1}>
              <TextInput
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    dappName: e.target.value,
                  })
                }
                placeholder="App Name"
                name="name"
                label="App Name"
                value={formState.dappName}
                variant={!isDappNameValid && formState.dappName ? 'negative' : undefined}
                helperText={
                  !isDappNameValid && formState.dappName
                    ? 'Error: Enter an App Name less than 32 characters'
                    : '32 characters maximum in length'
                }
              />
              <TextInput
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    logoUri: e.target.value,
                  })
                }
                placeholder="App Logo URL"
                name="logo.primary"
                label="App Logo URL"
                value={formState.logoUri}
                variant={!isLogoURIValid && formState.logoUri ? 'negative' : undefined}
                helperText={
                  !isLogoURIValid && formState.logoUri
                    ? 'Error: Enter a valid URL.'
                    : '100x100 pixel max in PNG format'
                }
              />
              <TextInput
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    faviconUri: e.target.value,
                  })
                }
                placeholder="Favicon URL"
                name="logo.favicon"
                label="Favicon URL"
                value={formState.faviconUri}
                variant={!isFaviconURIValid && formState.faviconUri ? 'negative' : undefined}
                helperText={
                  !isFaviconURIValid && formState.faviconUri
                    ? 'Error: Enter a valid URL.'
                    : '16x16 pixel max in ICO format'
                }
              />
              <ButtonGroup>
                <Button type="submit" disabled={!formValid}>
                  Save
                </Button>
                <Button variant="secondary" onPress={toggle}>
                  Cancel
                </Button>
              </ButtonGroup>
            </VStack>
          </form>
          <VStack>
            <TextHeadline spacingStart={3} as="p">
              Preview
            </TextHeadline>
            <AuthPreviewImg />
          </VStack>
        </Grid>
      </Collapsible>
    </VStack>
  );
}
