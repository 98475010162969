import { useEffect, useMemo, useRef, useState } from 'react';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { DropdownRefProps } from '@cbhq/cds-web/dropdown';

import { NavItem, NavItemChild } from ':cloud/config/navigation';
import { NavigationContext, TabTypeId } from ':cloud/contexts/NavigationContext';
import { useGetNavMenuItems } from ':cloud/hooks/useGetNavMenuItems';
import { GenericProviderProps } from ':cloud/types/ts_types';
import { AppRoute } from ':cloud/utils/routes';

const DYNAMIC_ROUTES = [
  AppRoute.Projects.DetailsTab,
  AppRoute.Products.Details,
  AppRoute.Settings.CurrentTab,
  AppRoute.Access.CurrentTab,
  AppRoute.Participate.Details,
  AppRoute.Billing.CurrentTab,
];

const ACCESS_ROUTES: string[] = [
  AppRoute.Access.OAuth,
  AppRoute.Access.OAuthEdit,
  AppRoute.Access.OAuthCreate,
];

export default function NavigationProvider({ children }: GenericProviderProps) {
  const notificationRef = useRef<DropdownRefProps>(null);

  const location = useLocation();

  const { displayedPrimaryNavItems, displayedPlatformAPINavItems, displayedProductAPINavItems } =
    useGetNavMenuItems();

  useEffect(() => {
    document.querySelector('.main-container')?.scrollTo(0, 0);
  }, [location.pathname]);

  // our navs don't have access to full router state b/c of their placement in the DOM
  // this is used to recreate the route matching that happens in <Route>s and pass to nav components
  const match = matchPath<{ currentTab?: string; projectId?: string; productName?: string }>(
    location.pathname,
    {
      path: DYNAMIC_ROUTES,
      exact: false,
      strict: false,
    },
  );

  const initialPage: NavItem | NavItemChild = useMemo(() => {
    const allNavItems = [
      ...displayedPrimaryNavItems,
      ...displayedPlatformAPINavItems,
      ...displayedProductAPINavItems,
    ];
    const pageDerivedFromPath = allNavItems.find((item) => {
      if (item.to === AppRoute.Projects.Home && match?.path.includes(item.to)) return true;
      if (item.to === AppRoute.Participate.Home && location.pathname.includes(item.to)) return true;
      // Wallets has a subpage for Embedded Wallets
      if (item.to === AppRoute.Products.Wallets && location.pathname.includes(item.to)) return true;
      if (item.to === AppRoute.Billing.Home && location.pathname.includes(item.to)) return true;
      // Access has a subpages for OAuth view, create, and edit
      if (item.to === AppRoute.Access.API && ACCESS_ROUTES.includes(location.pathname)) return true;
      return item.to === match?.path || item.to === location.pathname;
    });

    return pageDerivedFromPath || allNavItems[0];
  }, [
    displayedPrimaryNavItems,
    location.pathname,
    match?.path,
    displayedPlatformAPINavItems,
    displayedProductAPINavItems,
  ]);

  const [activePage, setActivePage] = useState(initialPage);
  const [activeTab, setActiveTab] = useState<TabTypeId | undefined>(
    match?.params?.currentTab as TabTypeId,
  );
  const [projectId, setProjectId] = useState<string | undefined>(match?.params?.projectId);

  useEffect(() => {
    setActivePage(initialPage);
  }, [initialPage]);

  useEffect(() => {
    setActiveTab(match?.params?.currentTab as TabTypeId);
    setProjectId(match?.params?.projectId);
  }, [match?.params]);

  const value = useMemo(
    () => ({
      activePage,
      activeTab,
      projectId,
      setActivePage,
      notificationRef,
    }),
    [activePage, activeTab, projectId],
  );

  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
}
