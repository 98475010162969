import { styled } from '@linaria/react';
import { TextHeadline } from '@cbhq/cds-web/typography';

import { ReactComponent as EmptyCount } from ':cloud/assets/Objects/validator-count-empty.svg';
import { ReactComponent as FilledCount } from ':cloud/assets/Objects/validator-count-filled.svg';

const StyledWrapper = styled.div<{ width?: number }>`
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  width: ${(props) => (props.width ? props.width : '80px')};
  height: 72px;
`;

type ValidatorCountProps = { active?: number; width?: number };

function ValidatorCount({ active = 0, width }: ValidatorCountProps) {
  return (
    <>
      <StyledWrapper width={width}>{active === 0 ? <EmptyCount /> : <FilledCount />}</StyledWrapper>
      <TextHeadline as="p" align="center">
        {active}
      </TextHeadline>
    </>
  );
}

export default ValidatorCount;
