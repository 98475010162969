import { useEffect, useState } from 'react';
import { css } from '@linaria/core';
import _sortBy from 'lodash/sortBy';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { spacing } from '@cbhq/cds-web/tokens';
import { Link, TextBody, TextTitle3 } from '@cbhq/cds-web/typography';

import useArticles from ':cloud/hooks/useArticles';
import { CLOUD_HELP, COINBASE_HELP } from ':cloud/utils/links';
import { ExternalLink } from ':cloud/widgets/sharedcomponents';

const panelLinkStyles = css`
  width: 100%;
  text-decoration: none !important;
  padding: ${spacing[3]} 0;
  &:hover span {
    cursor: pointer;
  }
`;

export interface HelpArticle {
  position?: number;
  title: string;
  url: string;
  id: number;
  directLink?: boolean;
}

interface HelpArticlePanelProps {
  article: HelpArticle;
}

function HelpArticlePanel({ article }: HelpArticlePanelProps) {
  return (
    <Box borderedBottom>
      <ExternalLink
        to={article.url}
        className={panelLinkStyles}
        testID={`external-link-${article.url}`}
      >
        <HStack alignItems="center" justifyContent="space-between">
          <TextBody color="foreground" as="span">
            {article.title}
          </TextBody>
          <Icon name="caretRight" size="m" color="foreground" />
        </HStack>
      </ExternalLink>
    </Box>
  );
}

type StaticHelpPanelProps = {
  articles: HelpArticle[] | undefined;
  title: string;
  alphabeticalOrder?: boolean;
};

export function StaticHelpPanel({
  articles,
  title,
  alphabeticalOrder = false,
}: StaticHelpPanelProps) {
  const [helpTerms, setHelpTerms] = useState<HelpArticle[]>([]);

  useEffect(() => {
    const sortedTerms: HelpArticle[] = _sortBy(articles, [
      alphabeticalOrder ? 'title' : 'position',
    ]);
    setHelpTerms(sortedTerms);
  }, [articles, alphabeticalOrder]);

  return (
    <VStack>
      <TextTitle3 as="h3">{title}</TextTitle3>
      <VStack as="ul">
        {helpTerms.map((d) => (
          <HelpArticlePanel article={d} key={d.title} />
        ))}
      </VStack>
      <TextBody as="div" color="foregroundMuted" spacingVertical={3}>
        <span>See more articles from our </span>
        <Link to={COINBASE_HELP}>Help center</Link>
        {` or `}
        <Link to={CLOUD_HELP}>contact support</Link>.
      </TextBody>
    </VStack>
  );
}

interface HelpPanelProps {
  title?: string;
  labels: string[];
  alphabeticalOrder?: boolean;
}

/* only used in Participate */
export function HelpPanel({
  title = 'Help Center Articles',
  labels,
  alphabeticalOrder = false,
}: HelpPanelProps) {
  const { articles, loadArticlesByLabels } = useArticles();

  useEffect(() => {
    loadArticlesByLabels(labels);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [labels.join()]);

  return (
    <StaticHelpPanel articles={articles} title={title} alphabeticalOrder={alphabeticalOrder} />
  );
}
