import { Table, TableBody, TableCellFallback, TableRow } from '@cbhq/cds-web/tables';

import OAuthTableHeader from ':cloud/widgets/access/oauth/OAuthTableHeader';

export default function OAuthTableLoadingState() {
  return (
    <Table tableLayout="auto">
      <OAuthTableHeader />
      <TableBody>
        {[...Array(3).keys()].map((e) => (
          <TableRow key={e}>
            <TableCellFallback title />
            <TableCellFallback title />
            <TableCellFallback title />
            <TableCellFallback title />
            <TableCellFallback title />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
