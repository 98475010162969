import { useEffect, useState } from 'react';
import {
  Route,
  Switch as RouterSwitch,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import { useNewCluster } from ':cloud/contexts/NewClusterContext';
import { useIsDeveloperMode } from ':cloud/hooks/useIsDeveloperMode';
import { useGetWorkflowByKey } from ':cloud/queries/WorkflowQueries/useGetWorkflows';
import { useGetWorkflowVersion } from ':cloud/queries/WorkflowQueries/useGetWorkflowVersion';
import { IWorkflow } from ':cloud/types/ts_types';
import { AppRoute } from ':cloud/utils/routes';
import CreateClusterBilling from ':cloud/widgets/billing/CreateClusterBilling';
import { Layout } from ':cloud/widgets/layout';
import { LoadingSpinner } from ':cloud/widgets/sharedcomponents';

import { CreateClusterSideRail } from './CreateClusterSideRail';
import CustomWizardForm from './CustomWizardForm';
import { Transition } from './Transition';

export function CreateCluster() {
  const { isDeveloperMode } = useIsDeveloperMode();
  const { protocolKey } = useParams<{ protocolKey: string }>();
  const match = useRouteMatch();
  const location = useLocation();

  const newCluster = useNewCluster();

  const { workflow, isLoading: isWorkflowLoading } = useGetWorkflowByKey(protocolKey);
  const [selectedWorkflow, setSelectedWorkflow] = useState<IWorkflow | undefined>(workflow);

  const paramVersion = new URLSearchParams(location.search).get('version');

  /* dev mode: fetch exact version only if params are specified */
  const { workflow: paramWorkflow } = useGetWorkflowVersion({
    protocol: protocolKey,
    version: paramVersion as string,
    exactVersion: true,
  });

  // TODO: refactor new cluster context
  useEffect(() => {
    if (isDeveloperMode && paramVersion) {
      setSelectedWorkflow(paramWorkflow);
      /* dev mode: append workflow PR version to deploy */
      newCluster.setWorkflowVersion(paramWorkflow?.version);
    } else {
      newCluster.setWorkflowVersion(undefined);
      setSelectedWorkflow(workflow);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isDeveloperMode, paramVersion, paramWorkflow, workflow]);

  if (!selectedWorkflow || isWorkflowLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Layout>
      {newCluster.isDeploying ? (
        <Layout.MainContainer fullWidth>
          <Transition />
        </Layout.MainContainer>
      ) : (
        <>
          <Layout.MainContainer>
            {match.isExact && selectedWorkflow && (
              <CustomWizardForm selectedWorkflow={selectedWorkflow} />
            )}
            <RouterSwitch>
              <Route exact path={AppRoute.Participate.Review}>
                <CreateClusterBilling cluster={newCluster} />
              </Route>
            </RouterSwitch>
          </Layout.MainContainer>
          <Layout.SidebarContainer>
            <CreateClusterSideRail protocolKey={protocolKey} />
          </Layout.SidebarContainer>
        </>
      )}
    </Layout>
  );
}
