import { useCallback } from 'react';
import { useToggler } from '@cbhq/cds-common/hooks/useToggler';
import { Banner } from '@cbhq/cds-web/banner/Banner';
import { VStack } from '@cbhq/cds-web/layout';
import { Link } from '@cbhq/cds-web/typography';

import { useNavigationContext } from ':cloud/contexts/NavigationContext';
import { useUnseenInvites } from ':cloud/hooks/useUnseenInvites';
import { EventName, useLogViewOnMount } from ':cloud/init/clientAnalytics/logging';
import { useGetOrganizationInvites } from ':cloud/queries/OrganizationQueries/useGetOrganizationInvites';
import { OrganizationInvite } from ':cloud/types/service_proto';
import { AccessTable } from ':cloud/widgets/access/teams/AccessTable';
import { Layout } from ':cloud/widgets/layout';
import { MAIN_CONTAINER_MIN_WIDTH } from ':cloud/widgets/product/constants';
import { CloudErrorBoundary } from ':cloud/widgets/sharedcomponents';

export function Teams() {
  /** persist acknowledged notifierIds and only show Banner and DotCount if new invites have been received */
  const [pendingInviteBannerVisible, { toggleOff }] = useToggler(true);
  const { notificationRef } = useNavigationContext();
  const { data: pendingInvites } = useGetOrganizationInvites();
  const { unseenInvites, addNotifierIds } = useUnseenInvites(pendingInvites);

  useLogViewOnMount(EventName.teams_page_view);

  const closeNotifier = useCallback(() => {
    const newIds = pendingInvites.map((invite: OrganizationInvite) => invite.inviteToken);
    addNotifierIds(newIds);
    toggleOff();
  }, [pendingInvites, addNotifierIds, toggleOff]);

  const openNotifierMenu = useCallback(() => {
    notificationRef?.current?.openMenu();
    closeNotifier();
  }, [closeNotifier, notificationRef]);

  return (
    <Layout>
      <Layout.MainContainer fullWidth minWidth={MAIN_CONTAINER_MIN_WIDTH}>
        <CloudErrorBoundary name="EditableOrganization" isComponentLevel>
          <VStack gap={10} spacingTop={2}>
            {unseenInvites.length > 0 && pendingInviteBannerVisible && (
              <Banner
                title="Pending invites"
                variant="promotional"
                startIcon="info"
                borderRadius="rounded"
                showDismiss
                primaryAction={
                  <Link color="primary" onPress={openNotifierMenu}>
                    Open
                  </Link>
                }
                secondaryAction={
                  <Link color="primary" onPress={closeNotifier}>
                    Ignore
                  </Link>
                }
              >
                You have organization invites that are currently pending. Open to view them.
              </Banner>
            )}
            <AccessTable />
          </VStack>
        </CloudErrorBoundary>
      </Layout.MainContainer>
    </Layout>
  );
}
