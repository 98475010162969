import { useQuery } from '@tanstack/react-query';
import { format, parse } from 'date-fns';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { GetStakingReportsResponse } from ':cloud/widgets/earnings/types';

type StakingEarningsReportTableRow = { label: string; csvPath?: string; pdfPath?: string };

const PATH = '/billing/v1/accounts';
function getDocumentDownloadUrl(orgId: string, filename?: string) {
  if (!filename) {
    return undefined;
  }

  return `${PATH}/${orgId}/reports/${filename}`;
}

async function fetchReports(orgId: string): Promise<GetStakingReportsResponse> {
  const url = `${PATH}/${orgId}/reports`;
  const { data } = await APIClientBase.get<GetStakingReportsResponse>(url);
  return data;
}

export function useGetStakingReports(orgId: string): {
  isLoading: boolean;
  sortedReports: StakingEarningsReportTableRow[];
} {
  const { isLoading, data } = useQuery({
    queryKey: [QueryKey.earnings, 'stakingReports', orgId],
    queryFn: async () => fetchReports(orgId),
    enabled: !!orgId,
    staleTime: 3 * 60 * 60 * 1000, // 3 hours based on ETH refresh rates
    refetchOnWindowFocus: true,
  });

  const reportObj: Record<string, StakingEarningsReportTableRow> = {};
  const periods: string[] = [];

  data?.reports
    .filter((report) => report.period)
    .forEach((report) => {
      const periodKey = report.period;
      const isCSV = report.type === 'staking_rewards_csv';
      const isPDF = report.type === 'staking_rewards_pdf';

      if (!reportObj[periodKey]) {
        const date = parse(periodKey, 'MM-yyyy', new Date());
        reportObj[periodKey] = { label: format(date, 'MMM yyyy') };
        periods.push(periodKey);
      }

      if (isCSV) {
        reportObj[periodKey].csvPath = getDocumentDownloadUrl(orgId, report.filename);
      }

      if (isPDF) {
        reportObj[periodKey].pdfPath = getDocumentDownloadUrl(orgId, report?.filename);
      }
    });

  const sortedReports = periods
    .sort((a, b) => {
      const dateA = parse(a, 'MM-yyyy', new Date());
      const dateB = parse(b, 'MM-yyyy', new Date());
      return dateB.getTime() - dateA.getTime();
    })
    .map((period) => reportObj[period]);

  return {
    isLoading,
    sortedReports,
  };
}
