import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import {
  CreateOrUpdateProductDetailsRequest,
  CreateOrUpdateProductDetailsResponse,
} from ':cloud/types/service_proto';
import { ProductDetails, ProductTypeCOMS } from ':cloud/types/ts_types';

// Creates product details for a given product scoped to a project in COMS.
async function createProductDetails(
  organizationId: string,
  projectId: string,
  productType: ProductTypeCOMS,
  productDetails: ProductDetails,
): Promise<CreateOrUpdateProductDetailsResponse> {
  const path = `/organization-management/v1/organizations/${organizationId}/projects/${projectId}/products`;

  const requestBody = {
    productType,
    productDetails,
  };

  const { data } = await APIClientBase.post<CreateOrUpdateProductDetailsResponse>(
    path,
    requestBody,
  );
  return data;
}

type MessagesType = {
  errorMsg: string;
  successMsg: string;
};

export function useCreateProductDetailsWithMessage(messages?: MessagesType) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      project,
      productType,
      productDetails,
    }: CreateOrUpdateProductDetailsRequest) => {
      return createProductDetails(project.organizationId, project.id, productType, productDetails);
    },
    onSuccess: async (_, { project, productType }) => {
      await queryClient.invalidateQueries([
        QueryKey.productDetails,
        project.organizationId,
        project.id,
        productType,
      ]);
      await queryClient.invalidateQueries([
        QueryKey.accounts,
        QueryKey.accountItems,
        project.organizationId,
      ]);
    },
    meta: {
      ...(messages ?? {}),
    },
  });
}
