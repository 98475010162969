import { useQuery } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { GetPaymentMethodResponse } from ':cloud/types/service_proto';

async function fetchPaymentMethod(orgId?: string): Promise<GetPaymentMethodResponse> {
  const { data } = await APIClientBase.get<GetPaymentMethodResponse>(
    `/billing/v1/accounts/${orgId}/paymentMethod`,
  );
  return data;
}

export function useGetPaymentMethod(orgId?: string): {
  isLoading: boolean;
  paymentMethod: GetPaymentMethodResponse | undefined;
} {
  const { isLoading, data } = useQuery({
    queryKey: [QueryKey.billing, 'paymentMethod', orgId],
    queryFn: async () => fetchPaymentMethod(orgId),
    staleTime: Infinity,
    enabled: !!orgId,
  });

  const paymentMethod = data?.type ? data : undefined;

  return {
    paymentMethod,
    isLoading,
  };
}
