import { ResponsiveProps } from '@cbhq/cds-web';
import { VStack, VStackProps } from '@cbhq/cds-web/layout';

export type ContentWrapperProps = VStackProps<'div'> & {
  className?: string;
};

const contentWrapperResponsiveConfig: ResponsiveProps = {
  phone: {
    spacingTop: 5,
  },
  tablet: {
    spacingTop: 7,
  },
  desktop: {
    spacingTop: 7,
  },
};

export function ContentWrapper({ children, className = '', ...props }: ContentWrapperProps) {
  return (
    <VStack
      as="div"
      responsiveConfig={contentWrapperResponsiveConfig}
      className={`${className}`}
      {...props}
    >
      {children}
    </VStack>
  );
}
