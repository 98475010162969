import { useCallback, useEffect, useState } from 'react';
import { noop } from '@cbhq/cds-utils';
import { Button } from '@cbhq/cds-web/buttons';
import { Checkbox, CheckboxGroup, Select, SelectOption, TextInput } from '@cbhq/cds-web/controls';
import { useCheckboxGroupState } from '@cbhq/cds-web/hooks/useCheckboxGroupState';
import { Icon } from '@cbhq/cds-web/icons';
import { Divider, HStack, VStack } from '@cbhq/cds-web/layout';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@cbhq/cds-web/overlays';
import { Pressable } from '@cbhq/cds-web/system';
import { TextHeadline } from '@cbhq/cds-web/typography';

import { useAppState } from ':cloud/contexts/AppStateContext';
import { useGetApiKeys } from ':cloud/queries/ApiKeyQueries/useGetApiKeys';
import { useGetTradePortfolios } from ':cloud/queries/ApiKeyQueries/useGetTradePortfolios';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { CoinbaseAPIKey, SelectedPortfolioType } from ':cloud/types/ts_types';
import { generateStringValidationError } from ':cloud/utils/generateStringValidationError';
import { apiKeyNicknameSchema } from ':cloud/utils/validate';

import { TRADE_CHECKBOX_OPTIONS } from '../constants';
import { formatAPIKeyScopes } from '../helpers';

type APIKeyCreateModalProps = {
  onRequestClose?: () => void;
  onSubmit: (apiKey: CoinbaseAPIKey, requireTwoFA: boolean) => void;
  enableAdvancedSettings?: boolean;
};

const DEFAULT_PERMISSIONS = ['view'];

export function CoinbaseAPIKeyCreateModal({
  onRequestClose,
  onSubmit,
  enableAdvancedSettings = false,
}: APIKeyCreateModalProps) {
  const { activeOrg } = useGetUser();
  const { selectedProject } = useAppState();
  const { keys } = useGetApiKeys(activeOrg?.organizationId);
  const { portfolios } = useGetTradePortfolios();

  const [apiKeyName, setApiKeyName] = useState('');
  const [nicknameError, setNicknameError] = useState('');
  const [areAdvancedSettingsEnabled, setAreAdvancedSettingsEnabled] =
    useState(enableAdvancedSettings);
  const [allowedAddresses, setAllowedAddresses] = useState('');
  const [selectedPortfolio, setSelectedPortfolio] = useState<SelectedPortfolioType>();

  const [selectedPermissions, { toggle }] = useCheckboxGroupState(
    Object.keys(TRADE_CHECKBOX_OPTIONS),
    DEFAULT_PERMISSIONS,
  );

  useEffect(() => {
    if (portfolios.length && !selectedPortfolio) {
      setSelectedPortfolio({ id: portfolios[0].uuid, name: portfolios[0].name });
    }
  }, [portfolios, selectedPortfolio]);

  const handleOnChangeName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setApiKeyName(event.target.value.replace(/ /g, ''));
    setNicknameError('');
  }, []);

  const handleOnBlur = useCallback(() => {
    const isDuplicate = keys.some((apiKey) => apiKey?.nickname === apiKeyName);
    if (isDuplicate) {
      setNicknameError('Duplicate name found.');
    } else {
      const nameError = generateStringValidationError(apiKeyName, apiKeyNicknameSchema);
      if (nameError) {
        setNicknameError(nameError);
      }
    }
  }, [apiKeyName, keys]);

  const handleAdvancedSettingsChange = useCallback(() => {
    setAreAdvancedSettingsEnabled((prev) => !prev);
  }, []);

  const handleOnChangeAddresses = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setAllowedAddresses(event.target.value.trim());
  }, []);

  const handlePortfolioChange = useCallback(
    (portfolioId) => {
      const foundPortfolio = portfolios.find((portfolio) => portfolio.uuid === portfolioId);
      if (foundPortfolio) {
        setSelectedPortfolio({ id: foundPortfolio.uuid, name: foundPortfolio.name });
      }
    },

    [portfolios],
  );

  const handleSubmit = useCallback(() => {
    if (!selectedProject?.id) {
      throw new Error('No project selected');
    }
    const scopes = formatAPIKeyScopes(selectedPermissions, selectedPortfolio?.id);
    const permissions = Array.from(selectedPermissions);
    const requireTwoFA = permissions?.includes('trade') || permissions?.includes('transfer');
    const allowedAddressesArr = allowedAddresses?.split(',').map((ip) => ip.trim());
    const coinbaseAPIKey = {
      nickname: apiKeyName,
      allowedIps: allowedAddresses?.length ? allowedAddressesArr : [],
      scopes,
      projectId: selectedProject?.id,
    };
    onSubmit(coinbaseAPIKey, requireTwoFA);
  }, [
    allowedAddresses,
    apiKeyName,
    onSubmit,
    selectedPermissions,
    selectedPortfolio?.id,
    selectedProject?.id,
  ]);

  return (
    <Modal onRequestClose={onRequestClose || noop} visible width={480}>
      <ModalHeader title="Create API key" />
      <ModalBody>
        <VStack gap={2}>
          <TextInput
            label="API key nickname"
            required
            placeholder="Your API key name"
            value={apiKeyName}
            onChange={handleOnChangeName}
            onBlur={handleOnBlur}
            helperText={nicknameError || ''}
            variant={nicknameError ? 'negative' : undefined}
          />
          <Pressable
            onPress={handleAdvancedSettingsChange}
            background="transparent"
            noScaleOnPress
            borderRadius="rounded"
            transparentWhilePressed
          >
            <HStack spacingVertical={4} justifyContent="space-between">
              <TextHeadline as="h3">API restrictions</TextHeadline>
              <Icon
                name={areAdvancedSettingsEnabled ? 'caretUp' : 'caretDown'}
                size="s"
                color="foreground"
              />
            </HStack>
          </Pressable>
          {areAdvancedSettingsEnabled && (
            <VStack>
              <Divider />
              <VStack spacingVertical={3}>
                <TextHeadline as="h3" spacingBottom={3}>
                  Global restrictions
                </TextHeadline>
                <TextInput
                  label="IP whitelist"
                  placeholder="10.10.10.0 / 24, 192.168.1.10"
                  value={allowedAddresses}
                  onChange={handleOnChangeAddresses}
                />
              </VStack>
              <Divider />
              <VStack spacingVertical={3}>
                <TextHeadline as="h3" spacingBottom={2}>
                  API-specific restrictions
                </TextHeadline>
                <VStack spacingStart={1} gap={1}>
                  <TextHeadline as="h3">Advanced Trade</TextHeadline>
                  <Select
                    value={selectedPortfolio?.name}
                    label="Portfolio"
                    placeholder="Select Portfolio"
                    onChange={handlePortfolioChange}
                  >
                    {portfolios.map((option) => (
                      <SelectOption value={option.uuid} key={option.uuid} title={option.name} />
                    ))}
                  </Select>
                  <CheckboxGroup
                    aria-labelledby="api-key-permissions"
                    name="checkbox-group"
                    selectedValues={selectedPermissions}
                    onChange={toggle}
                  >
                    {Object.entries(TRADE_CHECKBOX_OPTIONS).map(([value, label]) => (
                      <Checkbox
                        key={value}
                        value={value}
                        disabled={value === DEFAULT_PERMISSIONS[0]}
                      >
                        {label}
                      </Checkbox>
                    ))}
                  </CheckboxGroup>
                </VStack>
              </VStack>
            </VStack>
          )}
        </VStack>
      </ModalBody>
      <ModalFooter
        secondaryAction={
          <Button variant="secondary" onPress={onRequestClose}>
            Cancel
          </Button>
        }
        primaryAction={
          <Button
            type="submit"
            onPress={handleSubmit}
            testID="submit"
            disabled={!!nicknameError || !apiKeyName.length}
          >
            Create & download
          </Button>
        }
      />
    </Modal>
  );
}
