import { useMemo } from 'react';
import { Button, ButtonGroup } from '@cbhq/cds-web/buttons';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Box, Grid, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextDisplay2, TextTitle4 } from '@cbhq/cds-web/typography';

import { ReactComponent as OnchainKitImg } from ':cloud/assets/Products/OnchainKit.svg';
import { useAppState } from ':cloud/contexts/AppStateContext';
import { EventName, logClick, useLogViewOnMount } from ':cloud/init/clientAnalytics/logging';
import { CodeBlockLanguage } from ':cloud/types/ts_types';
import { ONCHAIN_KIT_GITHUB_LINK, ONCHAIN_KIT_LINK } from ':cloud/utils/links';
import { openUrlInNewWindow } from ':cloud/utils/openUrlInNewWindow';
import { APIKeyTokenSection } from ':cloud/widgets/base/APIKeyToken';
import { Layout } from ':cloud/widgets/layout';
import { FULL_PAGE_MAX_WIDTH, MAIN_CONTAINER_MIN_WIDTH } from ':cloud/widgets/product/constants';
import { KeyFeatureItem, KeyFeaturesSection } from ':cloud/widgets/product/KeyFeaturesSection';
import { QuickstartItem } from ':cloud/widgets/product/QuickstartItem';
import { CodeBlock } from ':cloud/widgets/sharedcomponents';

const keyFeatures: KeyFeatureItem[] = [
  {
    iconName: 'walletLogoNavigation',
    iconType: 'pictogram',
    title: 'Coinbase Smart Wallet',
    description:
      'Integrate a "Connect Wallet" button with Coinbase Smart Wallet with a plug and play component.',
  },
  {
    iconName: 'swapEth',
    iconType: 'spotSquare',
    title: 'Swap',
    description:
      'Enable token swaps in your app with a few lines of code. Leverage over $1 billion of decentralized liquidity on Base.',
  },
  {
    iconName: 'identityCard',
    iconType: 'pictogram',
    title: 'Identity',
    description:
      'Components that automatically query and display ENS usernames and EAS attestations for your users.',
  },
];

const backgroundColor = '#1E2025';
const customCodeStyle = {
  padding: '25px 10px 25px',
  background: backgroundColor,
  overflow: 'clip',
};

function TemplateQuickstartSection() {
  const bashCode = useMemo(() => {
    const bashCodeCommand = `npm install @coinbase/onchainkit`;
    return [
      {
        language: 'bash' as CodeBlockLanguage,
        code: bashCodeCommand,
      },
    ];
  }, []);

  const bashCode2 = useMemo(() => {
    const jsCodeCommand = `'use client';

import { base } from 'viem/chains';
import { OnchainKitProvider } from '@coinbase/onchainkit';
  
const App = () => {
  return (
    <OnchainKitProvider apiKey="YOUR_API_KEY" chain={base}>
      <YourKit />
    </OnchainKitProvider>
  );
};
    `;
    return [
      {
        language: 'javascript' as CodeBlockLanguage,
        code: jsCodeCommand,
      },
    ];
  }, []);

  const bashCode3 = useMemo(() => {
    const jsCodeCommand = `// Simply place this at the top of your application's entry point to have the components working out of the box.
import '@coinbase/onchainkit/styles.css';`;
    return [
      {
        language: 'javascript' as CodeBlockLanguage,
        code: jsCodeCommand,
      },
    ];
  }, []);

  const bashCode4 = useMemo(() => {
    const jsCodeCommand = `import { Avatar } from '@coinbase/onchainkit/identity';
import { TokenRow } from '@coinbase/onchainkit/token';
import { ConnectWallet } from '@coinbase/onchainkit/wallet'; 

const address = "0x838aD0EAE54F99F1926dA7C3b6bFbF617389B4D9";
const token = {
  "address": "0x50c5725949a6f0c72e6c4a641f24049a917db0cb",
  "chainId": 8453,
  "decimals": 18,
  "image": "https://makerdao.com/images/logo.svg",
  "name": "Dai",
  "symbol": "DAI"
};

// Connect Wallet
// See https://onchainkit.xyz/wallet/wallet for docs!
<ConnectWallet />

// Identity 
// See https://onchainkit.xyz/identity/address for docs!
<Avatar address={address} />;

// Token 
// See https://onchainkit.xyz/token/token-row for docs!
<TokenRow token={token} />;`;
    return [
      {
        language: 'javascript' as CodeBlockLanguage,
        code: jsCodeCommand,
      },
    ];
  }, []);

  return (
    <VStack bordered borderRadius="rounded" spacing={4} gap={6}>
      <QuickstartItem
        stepNumber={1}
        title="Install OnchainKit"
        codeBlock={
          <CodeBlock
            codeArr={bashCode}
            showLanguageSelect={false}
            showCopy
            customStyle={customCodeStyle}
            headerBackground={backgroundColor}
            enableLineWrap
            title="shell"
          />
        }
      />
      <QuickstartItem
        stepNumber={2}
        title="Configure with your API key"
        codeBlock={
          <CodeBlock
            codeArr={bashCode2}
            showLanguageSelect={false}
            showCopy
            customStyle={customCodeStyle}
            headerBackground={backgroundColor}
            enableLineWrap
            title="index.js"
          />
        }
      />
      <QuickstartItem
        stepNumber={3}
        title="Import Styling"
        codeBlock={
          <CodeBlock
            codeArr={bashCode3}
            showLanguageSelect={false}
            showCopy
            customStyle={customCodeStyle}
            headerBackground={backgroundColor}
            enableLineWrap
            title="index.js"
          />
        }
      />
      <QuickstartItem
        stepNumber={4}
        title="Start using OnchainKit!"
        codeBlock={
          <CodeBlock
            codeArr={bashCode4}
            showLanguageSelect={false}
            showCopy
            customStyle={customCodeStyle}
            headerBackground={backgroundColor}
            enableLineWrap
            title="index.js"
          />
        }
      />
    </VStack>
  );
}

export function TemplateLanding() {
  const title = 'Build beautiful onchain apps with React components and TypeScript utilities.';
  const description =
    'Saves weeks spent on infrastructure setup and go onchain in minutes with off-the-shelf components.';

  const { isDesktop } = useBreakpoints();
  const responsiveTemplateHeaderColumns = useMemo(() => {
    return isDesktop ? '1fr 1fr' : '1fr';
  }, [isDesktop]);

  const { selectedProject } = useAppState();

  useLogViewOnMount(EventName.boat_page_view);

  return (
    <Layout enableMaxWidth={false}>
      <Layout.MainContainer minWidth={MAIN_CONTAINER_MIN_WIDTH} spacingHorizontal={0} fullWidth>
        <Box display="block" width="100%">
          <VStack>
            <HStack borderedBottom>
              <HStack maxWidth={FULL_PAGE_MAX_WIDTH}>
                <Grid
                  maxWidth={FULL_PAGE_MAX_WIDTH}
                  gap={9}
                  spacing={9}
                  spacingVertical={0}
                  templateColumns={responsiveTemplateHeaderColumns}
                >
                  <VStack justifyContent="center">
                    <TextDisplay2 as="h1">{title}</TextDisplay2>
                    <TextTitle4 as="h2" color="foregroundMuted" spacingTop={5} spacingBottom={7}>
                      {description}
                    </TextTitle4>

                    <ButtonGroup>
                      <Button
                        variant="primary"
                        endIcon="diagonalUpArrow"
                        onPress={() => {
                          logClick(EventName.boat_page_external_click);
                          openUrlInNewWindow(ONCHAIN_KIT_LINK);
                        }}
                        accessibilityLabel="View docs + opens in new window"
                      >
                        Start building
                      </Button>
                      <Button
                        variant="secondary"
                        endIcon="diagonalUpArrow"
                        onPress={() => {
                          logClick(EventName.boat_page_external_click);
                          openUrlInNewWindow(ONCHAIN_KIT_GITHUB_LINK);
                        }}
                        accessibilityLabel="View docs + opens in new window"
                      >
                        Github
                      </Button>
                    </ButtonGroup>
                  </VStack>
                  <HStack spacingBottom={3} alignItems="center" alignContent="center" width="100%">
                    <OnchainKitImg />
                  </HStack>
                </Grid>
              </HStack>
            </HStack>
            {selectedProject && (
              <VStack borderedBottom>
                <VStack maxWidth={FULL_PAGE_MAX_WIDTH} spacing={10} gap={5}>
                  <APIKeyTokenSection selectedNetwork="base" title="API Key" />
                  <TemplateQuickstartSection />
                </VStack>
              </VStack>
            )}
            <KeyFeaturesSection features={keyFeatures} />
          </VStack>
        </Box>
      </Layout.MainContainer>
    </Layout>
  );
}
