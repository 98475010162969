import { ChartWidgetType } from ':cloud/types/helper';
import { getBlockUnitIcon } from ':cloud/utils/assets';
import { BlockUnit } from ':cloud/widgets/charts/BlockUnit';
import { deriveValueFromMetrics, deriveValueFromSource } from ':cloud/widgets/charts/ChartUtils';

// TODO(arti.villa):  replace workflows for NodeUnitWidget
export function BlockUnitWidget({
  sources,
  options,
  metricsData,
  configuration,
  artifacts,
  pod,
}: ChartWidgetType) {
  let blockValue;
  if (sources[0].source === 'metrics') {
    blockValue = deriveValueFromMetrics(sources[0], configuration, false, metricsData, pod);
  } else {
    blockValue = deriveValueFromSource(sources[0], configuration, artifacts);
  }
  return (
    <BlockUnit
      label={
        options.labelPluralization && Number(blockValue) > 1
          ? options.labelPluralization
          : options.label
      }
      value={blockValue}
      IconComponent={getBlockUnitIcon(options.icon, 1)}
      unit={options.unit}
      alignLeft={options.alignLeft}
      fullWidth
    />
  );
}
