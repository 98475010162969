import { object, ObjectSchema, string } from 'yup';

export const projectNameError =
  'Must start with a letter and end with a letter or number. Can contain letters, numbers, spaces, hyphens, and underscores.';

export const projectNameSchema = string()
  .required('This field is required')
  .max(40, 'Must be less than 40 characters')
  .matches(/^[a-zA-Z][ a-zA-Z0-9_-]*[a-zA-Z0-9]$/, {
    excludeEmptyString: true,
    message: projectNameError,
  });

export const apiKeyNicknameSchema = string()
  .required('This field is required')
  .min(2, 'Must be a minimum of 2 characters')
  .max(50, 'Must be less than 50 characters')
  .matches(/^[A-Za-z][A-Za-z0-9\-_]*$/, {
    excludeEmptyString: true,
    message:
      'Must begin with a letter and can only contain letters, numbers, hyphens, and underscores',
  });

// matches notification service email validation
export const validEmailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i;

export function generateProjectNameSchema(
  fields: Record<string, any>,
  existingNames?: string[],
): ObjectSchema<any> {
  const objShape = Object.keys(fields).reduce((acc, field) => {
    acc[field] = projectNameSchema.test(
      'is-unique',
      'Project name already exists',
      (value) => !existingNames?.includes(value),
    );
    return acc;
  }, {});

  return object().shape(objShape);
}

// Returns whether the specified value is a valid URL.
function isValidUrl(value: string): boolean {
  try {
    void new URL(value);

    return true;
  } catch {
    return false;
  }
}

// returns whether the value is a domain origin according to the spec:
// https://developer.mozilla.org/en-US/docs/Web/API/URL/origin
function isValidOrigin(value: string): boolean {
  try {
    const url = new URL(value);

    return url.origin === value;
  } catch {
    return false;
  }
}

// Validates that the input is a valid URL origin as per the spec:
// https://developer.mozilla.org/en-US/docs/Web/API/URL/origin
//
// Note: The yup `url` helper does not support localhost URLs which
// we need to be able to be input.
const domainOriginSchema = string()
  .required('This field is required')
  .test('is-valid-url', 'Must be a valid URL', isValidUrl)
  .test(
    'is-valid-origin',
    `Must be a valid URL origin, including the scheme ('https' vs. 'http'), host, and port if non-standard. (e.g: "https://myapp.com", "https://localhost:3000)`,
    isValidOrigin,
  );

// Validates the schema for a form whose value is a URL origin.
export const domainOriginFormSchema = object().shape({ value: domainOriginSchema });
