import { useCallback, useMemo, useState } from 'react';
import { Button } from '@cbhq/cds-web/buttons';
import { TextInput } from '@cbhq/cds-web/controls';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextLabel1 } from '@cbhq/cds-web/typography';

import { useAppState } from ':cloud/contexts/AppStateContext';
import { usePaymasterContext } from ':cloud/contexts/PaymasterProvider';
import { EventName, logClick } from ':cloud/init/clientAnalytics/logging';
import { useUpdateGasPolicy } from ':cloud/queries/Base/useUpdateGasPolicy';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { GasPolicy } from ':cloud/types/ts_types';
import { isValidLabel } from ':cloud/utils/paymaster';

type PaymasterCustomizationSectionProps = {
  gasPolicy: GasPolicy;
};

export function PaymasterCustomizationSection({ gasPolicy }: PaymasterCustomizationSectionProps) {
  const [sponsorName, setSponsorName] = useState(gasPolicy?.paymasterSponsorName || '');
  const { activeOrg } = useGetUser();
  const { selectedProject } = useAppState();

  const { setCompletedStep } = usePaymasterContext();

  const handleSuccess = useCallback(() => {
    setCompletedStep('sponsor');
  }, [setCompletedStep]);

  const updateGasPolicy = useUpdateGasPolicy({
    organizationId: activeOrg?.organizationId || '',
    projectId: selectedProject?.id || '',
    successMsg: 'Updated sponsor label',
    onSuccess: handleSuccess,
  });

  const numChangesUnsaved = useMemo(() => {
    if (!gasPolicy?.paymasterSponsorName && !sponsorName) {
      return 0;
    }
    if (gasPolicy?.paymasterSponsorName !== sponsorName) {
      return 1;
    }
    return 0;
  }, [gasPolicy, sponsorName]);

  const handleSponsorNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (isValidLabel(event.target.value)) {
      setSponsorName(event.target.value);
    }
  }, []);

  const handleSavePress = useCallback(() => {
    if (!gasPolicy) {
      return;
    }

    const newGasPolicy = {
      ...gasPolicy,
      paymasterSponsorName: sponsorName,
    };

    logClick(EventName.paymaster_sponsor_name_update);
    updateGasPolicy.mutate({ policy: newGasPolicy });
  }, [gasPolicy, sponsorName, updateGasPolicy]);

  const exceedsCharLimit = !!(sponsorName?.length && sponsorName?.length > 25);

  return (
    <VStack spacingTop={3} gap={3}>
      <VStack>
        <TextInput
          type="text"
          placeholder="Coinbase"
          value={sponsorName}
          onChange={handleSponsorNameChange}
          variant={exceedsCharLimit ? 'negative' : 'foregroundMuted'}
          start={
            <Box spacingStart={2}>
              <TextLabel1 as="p">Sponsored by</TextLabel1>
            </Box>
          }
        />
        <TextBody as="p" color={exceedsCharLimit ? 'negative' : 'foregroundMuted'} spacingTop={0.5}>
          {`${sponsorName.length}/25 characters`}
        </TextBody>
      </VStack>
      <HStack gap={2} justifyContent="flex-end" alignItems="center" spacingTop={4}>
        <TextBody as="p" color="foregroundMuted">{`${numChangesUnsaved} unsaved change${
          numChangesUnsaved === 1 ? '' : 's'
        }`}</TextBody>
        <Button
          variant="primary"
          onPress={handleSavePress}
          disabled={!numChangesUnsaved || exceedsCharLimit}
        >
          Save
        </Button>
      </HStack>
    </VStack>
  );
}
