import { Fragment } from 'react';
import { VStack } from '@cbhq/cds-web/layout';

import { ICluster, ProtocolKey } from ':cloud/types/ts_types';
import { ClusterList } from ':cloud/widgets/clusters/ClusterList';
import { SearchGroup } from ':cloud/widgets/clusters/ClusterSearch';
import { ProtocolSection } from ':cloud/widgets/clusters/ProtocolSection';
import { Layout } from ':cloud/widgets/layout';
import { CloudErrorBoundary } from ':cloud/widgets/sharedcomponents/CloudErrorBoundary';

import { DropdownWithSearchOption } from '../sharedcomponents/DropdownWithSearch';

interface ClustersSectionProps {
  activeClusters: ICluster[];
  protocolMappedClusters: Record<ProtocolKey, ICluster[]>;
  query?: DropdownWithSearchOption;
}

/* only used in Participate */
export function ClustersSection({
  activeClusters,
  protocolMappedClusters,
  query,
}: ClustersSectionProps) {
  const hasQuery = query?.value !== '';
  const searchMode = (query?.group as SearchGroup) ?? 'Clusters';

  if (searchMode === 'Clusters' && query?.title) {
    const queriedClusters: ICluster[] = activeClusters.filter((c) =>
      c.name.includes(query?.title as string),
    );
    return <ClusterList list={queriedClusters} hasQuery={hasQuery} />;
  }
  if (searchMode === 'Protocols' && query?.group) {
    const queriedProtocol = query?.value;
    return (
      <VStack gap={2}>
        <CloudErrorBoundary name={`ProtocolSection-${queriedProtocol}`}>
          <ProtocolSection
            clusterList={protocolMappedClusters[queriedProtocol]}
            protocolKey={queriedProtocol}
            hasQuery={hasQuery}
          />
        </CloudErrorBoundary>
      </VStack>
    );
  }
  return (
    <VStack gap={5}>
      {Object.keys(protocolMappedClusters)
        .sort()
        .map((protocol: ProtocolKey) => (
          <Fragment key={protocol}>
            <Layout.ContentWrapper>
              <CloudErrorBoundary name={`ProtocolSection-${protocol}`}>
                <ProtocolSection
                  key={protocol}
                  clusterList={protocolMappedClusters[protocol]}
                  protocolKey={protocol}
                  hasQuery={hasQuery}
                />
              </CloudErrorBoundary>
            </Layout.ContentWrapper>
          </Fragment>
        ))}
    </VStack>
  );
}
