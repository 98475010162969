import { MouseEvent, useCallback } from 'react';
import { Icon, IconSize } from '@cbhq/cds-web/icons';
import { HStack } from '@cbhq/cds-web/layout';
import { Tooltip } from '@cbhq/cds-web/overlays/Tooltip/Tooltip';
import { Pressable } from '@cbhq/cds-web/system';
import { TextHeadline } from '@cbhq/cds-web/typography';

import { useAppState } from ':cloud/contexts/AppStateContext';
import { useCopyToClipboard } from ':cloud/hooks/useCopyToClipboard';

interface ClipboardIconProps {
  text: string;
  color?: string;
  stopPropagation?: boolean;
  removeLinebreaks?: boolean;
  withLabel?: boolean;
  withIcon?: boolean;
  iconSize?: IconSize;
}

export function ClipboardIcon({
  text,
  color = 'foreground',
  iconSize = 'm',
  stopPropagation = false,
  removeLinebreaks = false,
  withLabel = false,
  withIcon = true,
}: ClipboardIconProps) {
  const { isDarkMode } = useAppState();
  // format to remove linebreaks so a user can copy & paste in a single line (useful for terminal commands)
  const formattedText = removeLinebreaks ? text.replace(/\n|\r/g, '').trim() : text;
  const [value, copy] = useCopyToClipboard();
  const handleCopy = useCallback(
    async (event: MouseEvent) => {
      if (stopPropagation) {
        event.stopPropagation();
      }
      await copy(formattedText);
    },
    [copy, stopPropagation, formattedText],
  );

  return (
    <Tooltip content={value ? 'Copied' : 'Copy'}>
      <Pressable onPress={handleCopy} background="transparent">
        <HStack alignItems="center" gap={withLabel ? 1 : 0}>
          {withLabel && (
            <TextHeadline as="p" color="primary">
              Copy
            </TextHeadline>
          )}
          {withIcon && (
            <Icon
              key={`copy-icon-${isDarkMode}`}
              name="copy"
              size={iconSize}
              dangerouslySetColor={color === 'foreground' && isDarkMode ? 'background' : color}
            />
          )}
        </HStack>
      </Pressable>
    </Tooltip>
  );
}
