import { ReactElement, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import _find from 'lodash/find';
import _isNull from 'lodash/isNull';

import { useGetResources } from ':cloud/queries/ResourceQueries/useGetResources';
import { useGetWorkflowVersion } from ':cloud/queries/WorkflowQueries/useGetWorkflowVersion';
import { ClusterParamOptions, ICluster } from ':cloud/types/ts_types';
import { isDeployedByPipelines } from ':cloud/utils/clusters';
import { ClusterGenerateDetails } from ':cloud/widgets/clusters/ClusterGenerateDetails';
import { ClusterVisualDetails } from ':cloud/widgets/clusters/ClusterVisualDetails';
import { LoadingSpinner } from ':cloud/widgets/sharedcomponents';

export function ClusterDetailsRoute(): ReactElement {
  const { resources, isLoading: isLoadingResources } = useGetResources(
    (r: ICluster) => r.clusterType === 'participation',
  );
  const { protocolKey, clusterName } = useParams<ClusterParamOptions>();
  const resource = useMemo(
    () => _find(resources, { type: protocolKey, name: clusterName }) as ICluster,
    [clusterName, protocolKey, resources],
  );
  const { workflow: selectedWorkflow, isLoading: isLoadingWorkflow } = useGetWorkflowVersion({
    protocol: protocolKey,
    version: resource?.version,
    exactVersion: isDeployedByPipelines(resource),
  });

  if (!selectedWorkflow || isLoadingResources || isLoadingWorkflow) {
    return <LoadingSpinner />;
  }

  /** used by workflows that define widgetSchemas */
  if (!_isNull(selectedWorkflow?.widgetSchemas)) {
    return <ClusterGenerateDetails cluster={resource} selectedWorkflow={selectedWorkflow} />;
  }
  /** use as default */
  return <ClusterVisualDetails cluster={resource} selectedWorkflow={selectedWorkflow} />;
}
