import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { Tooltip } from '@cbhq/cds-web/overlays/Tooltip/Tooltip';
import { TextHeadline } from '@cbhq/cds-web/typography';

import { EM_DASH } from ':cloud/brand/constants';
import { cursorPointer, wordBreakAll } from ':cloud/brand/utilityClasses';
import { truncateMiddle } from ':cloud/utils/common';
import { ClipboardIcon, TextLabelGray } from ':cloud/widgets/sharedcomponents';

interface DisplayUnitProps {
  isCard?: boolean;
  label: string;
  value?: string;
  unit?: string;
  isCapitalized?: boolean;
  isLabelInverted?: boolean;
  hasClipboard?: boolean;
  isTruncated?: boolean;
  ignoreAutoTruncate?: boolean;
  isSmaller?: boolean;
  noWrap?: boolean;
}

function DisplayUnit({
  isCard = false,
  label,
  value = '—',
  unit,
  isLabelInverted = false,
  hasClipboard = false,
  isCapitalized = false,
  isTruncated = false,
  isSmaller = false,
  noWrap = false,
}: DisplayUnitProps) {
  return (
    <VStack
      alignItems="flex-start"
      justifyContent="space-between"
      spacing={isCard ? 5 : 0}
      testID="charts-display-unit"
    >
      {isLabelInverted && <TextLabelGray spacingBottom={1}>{label}</TextLabelGray>}
      {isTruncated && (
        <Box alignItems="center">
          <Tooltip content={value}>
            <TextHeadline
              as="span"
              transform={isCapitalized ? 'uppercase' : undefined}
              spacingEnd={0.5}
              className={cursorPointer}
            >
              {truncateMiddle(value, 6, 6, '...')}
            </TextHeadline>
          </Tooltip>
          <ClipboardIcon text={value} />
        </Box>
      )}
      {!isTruncated && hasClipboard && (
        <HStack as="div" gap={1} alignItems="center" className={wordBreakAll}>
          <TextHeadline
            as="p"
            display="inline"
            transform={isCapitalized ? 'uppercase' : undefined}
            className={wordBreakAll}
          >
            {value}
          </TextHeadline>
          <ClipboardIcon text={value} />
        </HStack>
      )}
      {!isTruncated && !hasClipboard && (
        <HStack
          alignItems="flex-end"
          justifyContent="flex-start"
          flexWrap={noWrap ? 'nowrap' : 'wrap'}
        >
          <TextHeadline
            as="p"
            transform={isCapitalized ? 'uppercase' : undefined}
            className={wordBreakAll}
          >
            {value}
          </TextHeadline>
          {value && value !== EM_DASH && unit && (
            <TextHeadline as="span" transform="uppercase">
              {unit}
            </TextHeadline>
          )}
        </HStack>
      )}
      {!isLabelInverted && (
        <TextLabelGray spacingTop={isSmaller ? 0 : 1} noWrap={noWrap}>
          {label}
        </TextLabelGray>
      )}
    </VStack>
  );
}

export default DisplayUnit;
