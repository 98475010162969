import { useCallback, useMemo } from 'react';
import { Button } from '@cbhq/cds-web/buttons';
import { TextInput } from '@cbhq/cds-web/controls';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { TextBody } from '@cbhq/cds-web/typography';

import { useAppState } from ':cloud/contexts/AppStateContext';
import { usePaymasterContext } from ':cloud/contexts/PaymasterProvider';
import { EventName, logClick } from ':cloud/init/clientAnalytics/logging';
import { useUpdateGasPolicy } from ':cloud/queries/Base/useUpdateGasPolicy';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { GasPolicy } from ':cloud/types/ts_types';

type PaymasterPerUserOpLimitSectionProps = {
  gasPolicy?: GasPolicy;
  maxGasPerUserOpUsd?: string;
  maxGasError?: string;
  onMaxGasPerUserOpChange?: (max: React.ChangeEvent<HTMLInputElement>) => void;
};

export function PaymasterPerUserOpLimitSection({
  gasPolicy,
  maxGasPerUserOpUsd,
  maxGasError,
  onMaxGasPerUserOpChange,
}: PaymasterPerUserOpLimitSectionProps) {
  const { activeOrg } = useGetUser();
  const { selectedProject } = useAppState();

  const { setCompletedStep } = usePaymasterContext();

  const handleSuccess = useCallback(() => {
    setCompletedStep('gas');
  }, [setCompletedStep]);

  const updateGasPolicy = useUpdateGasPolicy({
    organizationId: activeOrg?.organizationId || '',
    projectId: selectedProject?.id || '',
    previousPolicy: gasPolicy,
    onSuccess: handleSuccess,
  });

  const numChangesUnsaved = useMemo(() => {
    let count = 0;
    if (gasPolicy?.maxGasPerUserOperationUsd !== parseFloat(maxGasPerUserOpUsd || '0')) {
      count += 1;
    }
    return count;
  }, [gasPolicy?.maxGasPerUserOperationUsd, maxGasPerUserOpUsd]);

  const handleSavePress = useCallback(() => {
    if (!gasPolicy) {
      return;
    }

    const newGasPolicy = {
      ...gasPolicy,
      maxGasPerUserOperationUsd: maxGasPerUserOpUsd ? parseFloat(maxGasPerUserOpUsd) : 0,
    };
    logClick(EventName.paymaster_update_user_op_limit);
    updateGasPolicy.mutate({ policy: newGasPolicy });
  }, [gasPolicy, maxGasPerUserOpUsd, updateGasPolicy]);

  const isSaveDisabled =
    !numChangesUnsaved || !!maxGasError || !maxGasPerUserOpUsd || maxGasPerUserOpUsd === '.';

  return (
    <VStack gap={3} spacingTop={3}>
      <TextInput
        type="text"
        label="Maximum USD"
        placeholder="0.00"
        helperText={maxGasError || 'Max amount of USD this policy will sponsor per User Operation'}
        value={maxGasPerUserOpUsd}
        onChange={onMaxGasPerUserOpChange}
        suffix="USD"
        variant={maxGasError ? 'negative' : 'foregroundMuted'}
      />
      <HStack gap={2} justifyContent="flex-end" alignItems="center" spacingTop={7}>
        <TextBody as="p" color="foregroundMuted">{`${numChangesUnsaved} unsaved change${
          numChangesUnsaved === 1 ? '' : 's'
        }`}</TextBody>
        <Button variant="primary" onPress={handleSavePress} disabled={isSaveDisabled}>
          Save
        </Button>
      </HStack>
    </VStack>
  );
}
