import { useCallback, useMemo } from 'react';
import _capitalize from 'lodash/capitalize';
import { noop } from '@cbhq/cds-utils';
import { Button } from '@cbhq/cds-web/buttons';
import { ListCell } from '@cbhq/cds-web/cells';
import { Switch } from '@cbhq/cds-web/controls';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from '@cbhq/cds-web/overlays';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { useGetTradePortfolios } from ':cloud/queries/ApiKeyQueries/useGetTradePortfolios';
import { useGetOrganization } from ':cloud/queries/OrganizationQueries/useGetOrganization';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { IPlatformAPIKey } from ':cloud/types/ts_types';
import { getFormattedTimeZoneLocal } from ':cloud/utils/date';
import { ClipboardIcon } from ':cloud/widgets/sharedcomponents';

import { getKeyId, getPermissionsFromScopes, getPortfolioIdFromScopes } from '../helpers';

type APIKeyDetailProps = Partial<ModalProps> & {
  apiKey: IPlatformAPIKey;
  onStatusChangePress: (keyId: string, status: boolean, requireTwoFA?: boolean) => void;
  onDeletePress: (keyId: string) => void;
  onEditPress: (keyId: string) => void;
};

export function APIKeyDetailModal({
  onRequestClose,
  apiKey,
  onStatusChangePress,
  onDeletePress,
  onEditPress,
}: APIKeyDetailProps) {
  const { activeOrg } = useGetUser();

  const { keyId, formattedKeyId } = getKeyId(apiKey?.name || '');
  const createdDateTime = getFormattedTimeZoneLocal(
    apiKey ? new Date(apiKey.createTime) : new Date(),
  );

  const { portfolios } = useGetTradePortfolios();
  const portfolioId = getPortfolioIdFromScopes(apiKey?.scopes || []);
  const portfolioName = portfolios.find((p) => p?.uuid === portfolioId)?.name || '';
  const permissions = getPermissionsFromScopes(apiKey?.scopes || []);
  const { isPhone } = useSimpleBreakpoints();

  const { activeUserMap } = useGetOrganization(activeOrg?.organizationId);
  const user = activeUserMap[apiKey.principal];
  const userHasName = user?.name && user?.familyName;

  const handleStatusChange = useCallback(
    (tokenId, status) => {
      const requireTwoFA =
        status && (permissions.includes('trade') || permissions.includes('transfer'));
      return () => onStatusChangePress?.(tokenId, status, requireTwoFA);
    },
    [onStatusChangePress, permissions],
  );

  const handleDeletePress = useCallback(() => {
    onDeletePress(keyId);
  }, [keyId, onDeletePress]);

  const handleEditPress = useCallback(() => {
    onEditPress(keyId);
  }, [keyId, onEditPress]);

  const formattedPermissions = useMemo(() => permissions.map(_capitalize), [permissions]);

  return (
    <Modal onRequestClose={onRequestClose || noop} visible width={isPhone ? 414 : 500}>
      <ModalHeader title="API key detail" />
      <ModalBody>
        {!apiKey && (
          <TextBody as="p" color="negative">
            An error occurred displaying your API key
          </TextBody>
        )}
        <ListCell description="Name" detail={apiKey?.nickname} compact />
        <ListCell
          compact
          description="Key ID"
          detail={
            <HStack gap={1} justifyContent="flex-end">
              <VStack background="primaryWash" spacingHorizontal={1}>
                <TextBody as="p" mono>
                  {isPhone ? formattedKeyId : keyId}
                </TextBody>
              </VStack>
              <ClipboardIcon text={keyId} iconSize="s" />
            </HStack>
          }
        />
        <ListCell compact description="Generated" detail={createdDateTime} />
        {portfolioName && <ListCell compact description="Portfolio" detail={portfolioName} />}

        {user && (
          <ListCell
            compact
            description="Created by"
            detail={
              <VStack justifyContent="center" alignItems="flex-end">
                <TextHeadline as="p">
                  {userHasName ? `${user.name} ${user.familyName}` : user.email}
                </TextHeadline>
                {!!userHasName && (
                  <TextBody as="p" color="foregroundMuted">
                    {user.email}
                  </TextBody>
                )}
              </VStack>
            }
          />
        )}
        {formattedPermissions?.length > 0 && (
          <ListCell compact description="Permissions" detail={formattedPermissions?.join(', ')} />
        )}

        <ListCell
          compact
          description="IP Whitelist"
          detail={apiKey.allowedIps?.length ? apiKey.allowedIps?.join(', ') : '--'}
        />

        <ListCell
          description="Active"
          compact
          detail={
            <HStack justifyContent="flex-end">
              <Switch
                onChange={handleStatusChange(keyId, !apiKey?.enabled)}
                checked={apiKey?.enabled}
              >
                {apiKey?.enabled ? 'Enabled' : 'Disabled'}
              </Switch>
            </HStack>
          }
        />
      </ModalBody>
      <ModalFooter
        primaryAction={
          <Button block onPress={handleDeletePress} variant="negative">
            Delete API key
          </Button>
        }
        secondaryAction={
          <Button block onPress={handleEditPress} variant="secondary">
            Edit API key
          </Button>
        }
      />
    </Modal>
  );
}
