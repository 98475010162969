import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useCookie } from '@coinbase/cookie-manager';
import { Box } from '@cbhq/cds-web/layout';
import { identify } from '@cbhq/client-analytics';
import { setUserIdentifier } from '@cbhq/user-research-web';

import config from ':cloud/config';
import { logError } from ':cloud/init/bugsnag/logging';
import { getDeviceId } from ':cloud/init/clientAnalytics/init';
import { CloudTOSFlow } from ':cloud/pages/CloudTOSFlow';
import { ErrorPage } from ':cloud/pages/ErrorPage';
import { AppRoute } from ':cloud/utils/routes';
import { LoadingSpinner } from ':cloud/widgets/sharedcomponents/LoadingSpinner';

function updateAnalyticsInstances(response?: SessionResponse) {
  const userId = response?.session?.userUuid || getDeviceId();

  // client analytics (also updates bugsnag)
  identify({ userId });
  // sprig
  setUserIdentifier(userId);
}

type SessionResponseSession = {
  userId: string;
  userUuid: string;
  email: string;
  firstName: string;
  lastName: string;
  avatarUrl: string;
  features: string[];
};

type SessionResponseRequirement = {
  requirement:
    | 'REQUIREMENT_TYPE_PHONE'
    | 'REQUIREMENT_TYPE_EMAIL'
    | 'REQUIREMENT_TYPE_CLOUD_TERMS_OF_SERVICE'
    | 'REQUIREMENT_TYPE_TERMS_OF_SERVICE';
  status: 'REQUIREMENT_STATUS_PENDING';
};

type SessionResponse = {
  session: SessionResponseSession;
  nextRequirement: SessionResponseRequirement | null;
} & Response;

type SessionContextType = {
  isLoggedIn: boolean;
  logUserOut: () => void;
  setLocationCookie: () => void;
  sessionResponse?: SessionResponseSession;
};

export const SessionContext = createContext<SessionContextType>({
  isLoggedIn: false,
  logUserOut: () => {
    // Do Nothing
  },
  setLocationCookie: () => {
    // Do Nothing
  },
  sessionResponse: undefined,
});

async function fetchSession(): Promise<Response> {
  return fetch(`${config.apiBaseURL}/organization-management-session/v1/session`, {
    method: 'GET',
    credentials: 'include',
  });
}

type SessionProviderProps = {
  children: React.ReactNode;
  fallback: React.ReactNode;
};

export function SessionProvider({ children, fallback }: SessionProviderProps) {
  const [errorStatus, setErrorStatus] = useState<500 | undefined>();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sessionResponse, setSessionResponse] = useState<SessionResponseSession>();
  const [cookie, setCookie] = useCookie('login-state');
  const [requiresTOS, setRequiresTOS] = useState(false);

  const handleEmailRequirement = useCallback((): void => {
    window.location.href = `${config.coinbaseURL}/setup/confirm`;
  }, []);

  const handlePhoneRequirement = useCallback((): void => {
    const policyParams = new URLSearchParams([
      ['policy', 'SIGNIN_CLOUD'],
      ['redirect_url', config.deployedHost],
    ]);
    window.location.href = `${config.accountsURL}/onboarding?${policyParams.toString()}`;
  }, []);

  const handleTOSRequirement = useCallback((): void => {
    setIsLoading(false);
    setIsLoggedIn(false);
    setRequiresTOS(true);
  }, []);

  const handleOtherRequirement = useCallback((response): void => {
    logError(
      new Error('Unexpected response from session endpoint'),
      { context: 'session_provider' },
      { response },
    );

    // treat as logged out and redirect out of the app
    setIsLoggedIn(false);
    setIsLoading(false);
  }, []);

  const attemptLogin = useCallback(async () => {
    const res: Response = await fetchSession();

    if (res.ok) {
      const response = (await res.json()) as SessionResponse;
      updateAnalyticsInstances(response);

      // COMS session endpoint returns requirement issues in 200 response instead of a 401
      if (response.nextRequirement !== null) {
        switch (response.nextRequirement?.requirement) {
          case 'REQUIREMENT_TYPE_EMAIL':
            handleEmailRequirement();
            break;
          case 'REQUIREMENT_TYPE_PHONE':
            handlePhoneRequirement();
            break;
          case 'REQUIREMENT_TYPE_CLOUD_TERMS_OF_SERVICE':
            handleTOSRequirement();
            break;
          default:
            handleOtherRequirement(response);
            break;
        }
      }

      if (response.session !== null) {
        // log them into cloud
        setIsLoggedIn(true);
        setErrorStatus(undefined);
        setIsLoading(false);
        setSessionResponse(response.session);
        setRequiresTOS(false);

        if (cookie) {
          const newPath = cookie;
          setCookie(undefined);
          /* using href instead of pathname allows us to preserve query params */
          window.location.href = newPath;
        }
      }
      return;
    }

    if (res.status === 500) {
      setErrorStatus(res.status);
      return;
    }

    if (res.status === 401) {
      /*
      window.location.pathname does not include query params so
      you need to combine with window.location.search
      */
      setCookie(`${window.location.pathname}${window.location.search}`);
      if (res.headers.get('content-type')?.includes('text/plain')) {
        const response = await res.text();
        const trimmedResponse = response.trim();

        // completely invalid login
        if (trimmedResponse === 'Unauthorized') {
          updateAnalyticsInstances();
          setIsLoggedIn(false);
          setIsLoading(false);
          return;
        }
      }

      const response = await res.json();
      // eslint-disable-next-line no-console
      console.log('Unexpected 401 response:', response);
      handleOtherRequirement(response);
      return;
    }

    // any add'l status codes > 299 end up here
    // eslint-disable-next-line no-console
    console.log('Unexpected response status:', res.status, res.statusText);
    logError(
      new Error('Unexpected response from session endpoint'),
      { context: 'session_provider' },
      { status: res.status, statusText: res.statusText },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- setCookie causes infinite re-renders b/c it updates a related context
  }, [cookie]);

  useEffect(() => {
    try {
      void attemptLogin();
    } catch (error) {
      throw new Error((error as Error)?.message ?? 'Error from SessionProvider');
    }
  }, [attemptLogin, requiresTOS]);

  const handleTOSSuccess = useCallback(() => {
    setRequiresTOS(false);
    setIsLoggedIn(true);
  }, []);

  const handleTOSError = useCallback(() => {
    setIsLoggedIn(false);
    setErrorStatus(500);
  }, []);

  const value = useMemo((): SessionContextType => {
    const logUserOut = (): void => {
      if (window.location.pathname !== AppRoute.Home) {
        setCookie(window.location.pathname);
      }
      // eslint-disable-next-line
      console.log('Redirecting to logoutURL, ', config.logoutURL);
      window.location.href = config.logoutURL;
    };

    const setLocationCookie = () => {
      if (window.location.pathname !== AppRoute.Home) {
        setCookie(window.location.pathname);
      }
    };
    return {
      isLoggedIn,
      logUserOut,
      sessionResponse,
      setLocationCookie,
    };
  }, [isLoggedIn, sessionResponse, setCookie]);

  const content = useMemo(() => {
    if (isLoading) {
      return (
        <Box height="100vh" alignItems="center" background>
          <LoadingSpinner />
        </Box>
      );
    }

    if (errorStatus) {
      return <ErrorPage statusCode={errorStatus} buttonText="Reload" />;
    }

    if (requiresTOS) {
      return <CloudTOSFlow onSuccess={handleTOSSuccess} onError={handleTOSError} />;
    }

    if (isLoggedIn) {
      return children;
    }

    return fallback;
  }, [
    isLoading,
    errorStatus,
    requiresTOS,
    isLoggedIn,
    fallback,
    handleTOSSuccess,
    handleTOSError,
    children,
  ]);

  return <SessionContext.Provider value={value}>{content}</SessionContext.Provider>;
}
