import { ResponsiveGridProps } from '@cbhq/cds-common';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

import { useAppState } from ':cloud/contexts/AppStateContext';
import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { TeamAvatars } from ':cloud/widgets/projects/TeamSection';
import { ClipboardIcon } from ':cloud/widgets/sharedcomponents';

const headerResponsiveConfig: ResponsiveGridProps = {
  phone: {
    spacingEnd: 0,
    spacingBottom: 6,
  },
  tablet: {
    spacingEnd: 6,
    spacingBottom: 0,
  },
  desktop: {
    spacingEnd: 6,
    spacingBottom: 0,
  },
};

export function ProjectDashboardHeader() {
  const { selectedProject } = useAppState();
  const { isPhone } = useSimpleBreakpoints();
  return (
    <Box flexDirection={isPhone ? 'column' : 'row'}>
      <VStack responsiveConfig={headerResponsiveConfig} gap={1}>
        <TextHeadline as="p">Team</TextHeadline>
        <HStack>
          <TeamAvatars />
        </HStack>
      </VStack>
      <VStack gap={2}>
        <TextHeadline as="p">Project ID</TextHeadline>
        <HStack gap={2} alignItems="center" spacingTop={0.5}>
          <TextBody as="p" color="foregroundMuted">
            {selectedProject?.id}
          </TextBody>
          <ClipboardIcon text={selectedProject?.id || ''} />
        </HStack>
      </VStack>
    </Box>
  );
}
