import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { CreateOrUpdateProjectResponse, DeleteProjectRequest } from ':cloud/types/service_proto';

async function deleteProject({
  projectId,
  organizationId,
}: DeleteProjectRequest): Promise<CreateOrUpdateProjectResponse> {
  const path = `/organization-management/v1/organizations/${organizationId}/projects/${projectId}`;

  const { data } = await APIClientBase.delete<CreateOrUpdateProjectResponse>(path);
  return data;
}

export function useDeleteProject(showToast = true) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ projectId, organizationId }: DeleteProjectRequest) =>
      deleteProject({ projectId, organizationId }),
    onSuccess: async (_, { organizationId }) => {
      await queryClient.invalidateQueries([QueryKey.projects, organizationId]);
      await queryClient.invalidateQueries([
        QueryKey.accounts,
        QueryKey.accountItems,
        organizationId,
      ]);
    },
    meta: showToast
      ? {
          successMsg: 'Project successfully deleted',
        }
      : {},
  });
}
