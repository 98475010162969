import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientCOMSLegacy } from ':cloud/state/Api';
import { RemoveMemberResponse } from ':cloud/types/service_proto';

async function removeMember(orgId: string, userId: string): Promise<RemoveMemberResponse> {
  const path = `/organization-management/v1/organizations/${orgId}/users`;
  const { data } = await APIClientCOMSLegacy.delete<RemoveMemberResponse>(path, {
    data: { orgId, userId },
  });
  return data;
}

export function useRemoveMember(orgId: string, memberEmail: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (userId: string) => removeMember(orgId, userId),
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKey.organizations]);
    },
    meta: {
      errorMsg: `Remove ${memberEmail} failed`,
      successMsg: `Member ${memberEmail} removed from the organization`,
    },
  });
}
