import config from ':cloud/config';
import useHasExperiment from ':cloud/hooks/useHasExperiment';

export function useIsOAuthEnabled() {
  /* return true if environment is dev or if user
  is in the experiment */
  const isDev = config.isDevelopment();
  //   return false;
  return useHasExperiment('may_2024_oauth_tab', 'treatment') || isDev;
}
