import { useMemo } from 'react';
import _sumBy from 'lodash/sumBy';

import config from ':cloud/config';
import { useAppState } from ':cloud/contexts/AppStateContext';
import useHasExperiment from ':cloud/hooks/useHasExperiment';
import { useGetChartData } from ':cloud/queries/MonitoringQueries/useGetChartData';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { getCurrentYearAndMonth } from ':cloud/utils/billing';
import { currencyFormatter } from ':cloud/utils/formatters/currency';
import { productCharts } from ':cloud/widgets/monitoring/monitoringConfig';
import { getEndTime, getScope, getStartTime } from ':cloud/widgets/monitoring/utils';

const CHART_RANGE = 30;

export function useIsBillingUsageEnabled() {
  const { activeOrg } = useGetUser();
  const { selectedProject } = useAppState();
  const billingUnitsChart = productCharts.base.charts[2];
  const { aggregationMethod, metricName, groupBy } = billingUnitsChart;

  const { data } = useGetChartData(
    {
      metricName,
      aggregationMethod,
      groupBy,
      startTime: getStartTime(CHART_RANGE),
      endTime: getEndTime(CHART_RANGE),
      filterBy: [{ name: 'network', value: 'base' }],
      scope: getScope(metricName),
    },
    activeOrg?.organizationId || '',
    selectedProject?.id || '',
  );

  const currentBillingCycleSponsoredGas = useMemo(() => {
    if (!data) {
      return '--';
    }
    const dataSet = data?.[0];
    const currentBillingCycleData = dataSet?.dataPoints?.filter(
      (dataPoint) => !!dataPoint?.time?.startsWith(getCurrentYearAndMonth()),
    );
    const totalGas = _sumBy(currentBillingCycleData, (dataPoint) => +dataPoint.value);
    return currencyFormatter(totalGas);
  }, [data]);

  const isProd = config.isProduction();

  return (
    (useHasExperiment('may_2024_billing_usage_tab', 'treatment') &&
      currentBillingCycleSponsoredGas !== '$0') ||
    !isProd
  );
}
