import { useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import { Box } from '@cbhq/cds-web/layout';
import { palette, spacing } from '@cbhq/cds-web/tokens';

interface PercentageBarProps {
  data: {
    color?: string;
    count: BigNumber;
    total?: boolean;
  }[];
}

type BarProps = { width: number; color?: string };

function Bar({ width, color = palette.line }: BarProps) {
  const [appliedWidth, setAppliedWidth] = useState<number>(0);

  useEffect(() => {
    if (appliedWidth !== width) {
      setAppliedWidth(width);
    }
  }, [width, appliedWidth, setAppliedWidth]);

  const barStyles = useMemo(
    () => ({
      transition: 'width 550ms ease-out',
      width: `${appliedWidth * 100}%`,
      backgroundColor: color,
    }),
    [appliedWidth, color],
  );

  return (
    <Box
      display="block"
      testID="percentage-bar-bar"
      height={spacing[1]}
      width={0}
      style={barStyles}
    />
  );
}

function PercentageBar({ data }: PercentageBarProps) {
  const totalObj = data.find((d) => d.total);
  let total: BigNumber;
  let finalMap: PercentageBarProps['data'];
  let wrapperBackground = palette.line as string | undefined;

  if (totalObj) {
    total = totalObj.count;

    if (totalObj.color) {
      wrapperBackground = totalObj.color;
    }

    finalMap = data.filter((d) => !d.total);
  } else {
    total = data.reduce((acc, curr) => acc.plus(curr.count), new BigNumber(0));
    finalMap = data;
  }

  return (
    <Box
      width="100%"
      borderRadius="roundedSmall"
      dangerouslySetBackground={wrapperBackground}
      overflow="hidden"
      testID="percentage-bar"
    >
      {finalMap.map((set) => (
        <Bar
          width={set.count.dividedBy(total).toNumber()}
          color={set.color}
          key={`${set.color}-${set.count}`}
        />
      ))}
    </Box>
  );
}

export default PercentageBar;
