import _orderBy from 'lodash/orderBy';

import { OrganizationUser } from ':cloud/types/ts_types';
import { getFormattedId } from ':cloud/utils/common';

export function formatAPIKeyScopes(selectedPermissions, portfolioId) {
  const genericScopes = Array.from(selectedPermissions).map((permission) => `rat#${permission}`);
  const scopes = Array.from(selectedPermissions).map(
    (permission) => `rat/portfolio:${portfolioId}#${permission}`,
  );
  return scopes.concat(genericScopes);
}

export function getKeyId(keyName: string) {
  const splitName = keyName.split('apiKeys/');
  const keyId = splitName[splitName.length - 1];
  return {
    keyId,
    formattedKeyId: getFormattedId(splitName.length ? keyId : ''),
  };
}

export function getPortfolioIdFromScopes(scopes: string[]) {
  /* ex: "rat/portfolio:{portfolioId}#view" */
  const scopeWithId = scopes?.filter((scope) => scope?.includes('portfolio'))[0] || '';
  const partialId = scopeWithId.split(':')?.[1] || '';
  return partialId.split('#')[0] || '';
}

/* when you create a trade api key and select permissions (i.e 'view')
the key will be created with a scopes property equal to an array containing
two values per selected permission: 
      [
        "rat/portfolio:{portfolioId}#view",
        "rat#view"
      ] 
this function will return an array of permissions (i.e ['view']) from the scopes
array by filtering out the generic permissions (i.e 'rat#view') and then splitting
the remaining scopes by the '#' character and returning the second value in the array 
(i.e 'view') */
export function getPermissionsFromScopes(scopes: string[]) {
  return scopes
    ?.filter((scope) => scope.includes('portfolio'))
    ?.map((scope) => {
      return scope?.split('#')[1];
    });
}

/**
 * Sorts organization users by prioritizing the owner, then the current user (self),
 * followed by active and invited users.
 */
export function sortOrganizationUsers(
  users: OrganizationUser[],
  selfEmail: string,
): OrganizationUser[] {
  // Find the owner and self user
  const owner = users.find((user) => user.role === 'owner');
  const selfUser = users.find((user) => user.email === selfEmail);
  const isSelfOwner = owner && selfUser && owner.email === selfUser.email;

  // Filter out the owner and self from the rest of the users
  const filteredUsers = users.filter((user) => user.email !== selfEmail && user.role !== 'owner');

  // Sort the filtered users by status
  const sortedFilteredUsers = _orderBy(filteredUsers, ['status'], ['asc']);

  // Construct the final sorted array
  const sortedUsers: OrganizationUser[] = [];
  if (owner && !isSelfOwner) sortedUsers.push(owner);
  if (selfUser) sortedUsers.push(selfUser);
  sortedUsers.push(...sortedFilteredUsers);

  return sortedUsers;
}
