import { useEffect } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { TextTitle3 } from '@cbhq/cds-web/typography';
import { useIsTreatment } from '@cbhq/experiments';

import { useAppState } from ':cloud/contexts/AppStateContext';
import { DashboardProvider } from ':cloud/contexts/DashboardProvider';
import { useGetProject } from ':cloud/hooks/useGetProject';
import { Experiments } from ':cloud/init/cifer/experiments';
import { useGetProjects } from ':cloud/queries/Projects/useGetProjects';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { AppRoute } from ':cloud/utils/routes';
import { Layout } from ':cloud/widgets/layout';
import { Chart } from ':cloud/widgets/monitoring/Chart';
import { DashboardRangeDropdown } from ':cloud/widgets/monitoring/DashboardRangeDropdown';
import { DisplayOnrampMetrics } from ':cloud/widgets/monitoring/DisplayOnrampMetrics';
import { defaultChart, productCharts } from ':cloud/widgets/monitoring/monitoringConfig';
import { MultiProductCards } from ':cloud/widgets/product/MultiProductCards';
import { ProjectDashboardHeader } from ':cloud/widgets/projects/ProjectDashboardHeader';
import { LoadingSpinner } from ':cloud/widgets/sharedcomponents';

function ProjectDashboardContent() {
  // TODO: aggregate all charts request charts & display stacked
  // const baseRequestsChart = productCharts.default.charts[0];

  const isOnrampMetricsEnabled = useIsTreatment(Experiments.jun_2024_cdp_onramp_metrics);

  const baseChartShown = isOnrampMetricsEnabled
    ? defaultChart.default_api_requests.charts[0]
    : productCharts.base.charts[0]; // determine default api requests chart

  const dashboardCharts = productCharts.cbpay.charts; // two onramp charts displayed

  return (
    <VStack testID="dashboard-content" spacingBottom={3}>
      <VStack spacingHorizontal={7} spacingVertical={6}>
        <ProjectDashboardHeader />
      </VStack>
      <VStack spacingHorizontal={7} spacingVertical={6} borderedTop>
        <TextTitle3 as="h3" spacingBottom={3}>
          Explore the platform
        </TextTitle3>
        <MultiProductCards />
      </VStack>
      {isOnrampMetricsEnabled ? (
        <VStack spacingHorizontal={7} spacingTop={6} borderedTop>
          <HStack spacingBottom={5} justifyContent="space-between">
            <TextTitle3 as="h3">Metrics</TextTitle3>
            <DashboardRangeDropdown />
          </HStack>
          <HStack spacingBottom={5}>
            <Chart {...baseChartShown} key={baseChartShown.title} />
          </HStack>
          <DisplayOnrampMetrics dashboardCharts={dashboardCharts} />
        </VStack>
      ) : (
        <VStack spacingHorizontal={7} spacingTop={6} borderedTop>
          <HStack spacingBottom={5} justifyContent="space-between">
            <TextTitle3 as="h3">Metrics</TextTitle3>
          </HStack>
          <HStack>
            <Chart {...baseChartShown} title="Total API requests" range={30} />
          </HStack>
        </VStack>
      )}
    </VStack>
  );
}

export function ProjectDashboard() {
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();
  const { activeOrg } = useGetUser();
  const { selectedProject, setSelectedProject } = useAppState();
  const { projects, isLoading: isProjectsLoading } = useGetProjects(activeOrg?.organizationId);
  const foundProject = useGetProject(projectId);

  useEffect(() => {
    if (!projects?.length) return;
    // if projectId in route isnt the same as the selected project
    if (foundProject && !isProjectsLoading && selectedProject?.id !== projectId) {
      setSelectedProject(foundProject);
    }
    // if selected project can't be found (suggests incorrect projectId param in route)
    const fallbackProject = projects[0];
    if (!foundProject) {
      history.push(`${AppRoute.Projects.Home}/${fallbackProject.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- selectedProject AND setSelectedProject can cause infinite re-renders
  }, [projectId, projects, history, foundProject, isProjectsLoading]);

  /** if no projects exist has to be checked first, order matters */
  if (projects.length === 0) {
    return <Redirect to={AppRoute.Projects.Home} />;
  }

  if (!selectedProject) {
    return <LoadingSpinner />;
  }

  return (
    <Layout>
      <Layout.MainContainer spacingHorizontal={0} fullWidth>
        <DashboardProvider>
          <ProjectDashboardContent />
        </DashboardProvider>
      </Layout.MainContainer>
    </Layout>
  );
}
