import { useMemo } from 'react';
import { FieldProps, utils } from '@rjsf/core';
import { VStack } from '@cbhq/cds-web/layout';

/* only used in Participate */
export function FullWidthInputField({
  schema,
  registry = utils.getDefaultRegistry(),
  uiSchema,
  idSchema,
  formData,
  required,
  disabled,
  readonly,
  autofocus,
  onChange,
  onBlur,
  onFocus,
  rawErrors,
}: FieldProps) {
  // prioritize title over ui:title (in workflow) for backwards compatibility
  const { title: schemaTitle, format } = schema;
  const { widgets, formContext } = registry;
  const enumOptions = utils.isSelect(schema) && utils.optionsList(schema);
  let defaultWidget = enumOptions ? 'select' : 'text';

  if (format && utils.hasWidget(schema, format, widgets)) {
    defaultWidget = format;
  }
  // @ts-expect-error MUI incorrect types
  // prettier-ignore
  const { title, description, isPercentage, placeholder = '', widget = defaultWidget, label, ...options } = utils.getUiOptions(uiSchema);
  const Widget = utils.getWidget(schema, widget, widgets);

  const widgetOptions = useMemo(() => ({ ...options, enumOptions }), [enumOptions, options]);

  return (
    <VStack gap={2}>
      {/*
         // @ts-expect-error MUI incorrect types */}
      <Widget
        options={widgetOptions}
        schema={schema}
        id={idSchema?.$id}
        label={label === undefined ? schemaTitle || title : label}
        value={formData}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        required={required}
        disabled={disabled}
        readonly={readonly}
        formContext={formContext}
        autofocus={autofocus}
        registry={registry}
        placeholder={placeholder}
        rawErrors={rawErrors}
        isPercentage={isPercentage}
        spellCheck="false"
      />
    </VStack>
  );
}
