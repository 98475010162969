import * as yup from 'yup';

const urlSchema = yup
  .string()
  .required()
  .test(
    'is-valid-url',
    'URL must start with "https://" or "chrome-extension://" and must be a valid URL',
    (value) => {
      if (!value) return false; // Handle undefined or null values

      // Check for the correct protocols
      if (!(value.startsWith('https://') || value.startsWith('chrome-extension://'))) {
        return false;
      }

      // Special case for chrome-extension URLs
      if (value.startsWith('chrome-extension://')) {
        // Extract the part after "chrome-extension://"
        return true;
      }
      // Try constructing a new URL to validate normal URLs
      try {
        const url = new URL(value);
        const [domain, ext] = url.hostname.split('.');
        if (domain.length < 2 || ext.length < 2) return false;

        // Further checks can be added here, like validating domain name more rigorously
        return true;
      } catch (error) {
        return false; // If URL constructor fails, the URL is not valid
      }
    },
  );

export function validateDomain(url) {
  try {
    urlSchema.validateSync(url);
    return true;
  } catch (error) {
    return false;
  }
}
