import { useCallback, useMemo, useState } from 'react';
import { ListCell } from '@cbhq/cds-web/cells';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { Pressable } from '@cbhq/cds-web/system';
import { TextCaption } from '@cbhq/cds-web/typography';

import { ORG_NAME_MAX_LENGTH } from ':cloud/constants';
import { useGetActiveOrg } from ':cloud/hooks/useGetActiveOrg';
import {
  GetOrganizationOverview,
  useGetAllOrganizations,
} from ':cloud/queries/OrganizationQueries/useGetAllOrganizations';
import { useSwitchOrganization } from ':cloud/queries/OrganizationQueries/useSwitchOrganization';

interface OrgSwitcherProps {
  handleOrgSwitcherClose?: () => void;
  hideBackButton?: boolean;
}

type OrgSwitcherItemProps = {
  organization: GetOrganizationOverview;
  setSelectedOrgId: (orgId: string) => void;
  selectedOrgId?: string;
};

function OrgSwitcherItem({ organization, setSelectedOrgId, selectedOrgId }: OrgSwitcherItemProps) {
  const switchOrg = useSwitchOrganization();
  const handleOrgPress = useCallback(() => {
    // optimistically update value in switcher to avoid weird laggy UX
    setSelectedOrgId(organization.organizationId);
    switchOrg.mutate(organization.organizationId);
  }, [organization.organizationId, setSelectedOrgId, switchOrg]);

  const displayName = useMemo(() => {
    if (organization.organizationDetails.name.length > ORG_NAME_MAX_LENGTH) {
      return `${organization.organizationDetails.name.slice(0, ORG_NAME_MAX_LENGTH - 3)}...`;
    }
    return organization.organizationDetails.name;
  }, [organization.organizationDetails.name]);

  return (
    <ListCell
      selected={organization.organizationId === selectedOrgId}
      as="li"
      compact
      title={displayName}
      onPress={handleOrgPress}
    />
  );
}

export function OrgSwitcher({ handleOrgSwitcherClose, hideBackButton }: OrgSwitcherProps) {
  const activeOrg = useGetActiveOrg();
  const { organizations } = useGetAllOrganizations();
  const [selectedOrgId, setSelectedOrgId] = useState(activeOrg?.organizationId);

  const backButton = useMemo(() => {
    if (hideBackButton) {
      return null;
    }
    return (
      <Box spacingStart={1}>
        <Pressable background="transparent" onPress={handleOrgSwitcherClose}>
          <Icon name="backArrow" size="xs" color="foreground" />
        </Pressable>
      </Box>
    );
  }, [handleOrgSwitcherClose, hideBackButton]);

  if (!activeOrg?.organizationId) {
    throw new Error('Selected organization does not exist');
  }

  return (
    <VStack spacingTop={1} spacingBottom={2}>
      <HStack spacingStart={1} spacingVertical={2} borderedBottom>
        {backButton}
        <TextCaption spacingStart={1} as="p" color="foreground">
          Organization
        </TextCaption>
      </HStack>
      <VStack as="ul" spacing={0}>
        {organizations.map((organization) => {
          return (
            <OrgSwitcherItem
              selectedOrgId={selectedOrgId}
              setSelectedOrgId={setSelectedOrgId}
              organization={organization}
              key={organization.organizationId}
            />
          );
        })}
      </VStack>
    </VStack>
  );
}
