import { useEffect } from 'react';

import { useCreateToken } from ':cloud/queries/ApiKeyQueries/useCreateToken';
import { useGetTokens } from ':cloud/queries/ApiKeyQueries/useGetTokens';

export function useFindOrCreateToken(organizationId, projectId, projectType, isTestnet) {
  const { tokens: fetchedTokens, isLoading } = useGetTokens({
    organizationId,
    projectId,
    enabled: Boolean(organizationId),
  });

  const createTokenMutation = useCreateToken({
    organizationId,
    projectId,
    isTestnet,
  });

  useEffect(() => {
    if (!isLoading && !fetchedTokens?.length && createTokenMutation.isIdle) {
      createTokenMutation.mutate();
    }
  }, [createTokenMutation, fetchedTokens, isLoading]);

  return { token: fetchedTokens[0], isLoading };
}
