import { useQuery } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { toSnakeCase } from ':cloud/utils/common';
import { EarningsRange, GetStakingRewardResponse } from ':cloud/widgets/earnings/types';

type GetChartPostBody = {
  filter: EarningsRange;
  page_size?: number;
  page_token?: string;
};

async function fetchChartData(
  body: GetChartPostBody,
  orgId: string,
): Promise<GetStakingRewardResponse> {
  const path = `/billing/v1/accounts/${orgId}/earnings:aggregateStakingRewards?filter=date_range=${toSnakeCase(
    body.filter,
  )}`;
  const { data } = await APIClientBase.get<GetStakingRewardResponse>(path);
  return data;
}
export function useGetStakingRewards(
  body: GetChartPostBody,
  orgId: string,
): {
  isLoading: boolean;
  data: GetStakingRewardResponse;
} {
  const { isLoading, data } = useQuery({
    queryKey: [QueryKey.earnings, 'stakingRewards', body, orgId],
    queryFn: async () => fetchChartData(body, orgId),
    enabled: !!orgId,
    staleTime: 3 * 60 * 60 * 1000, // 3 hours based on ETH refresh rates
    refetchOnWindowFocus: true,
  });

  const defaultEmptyResponse: GetStakingRewardResponse = {
    protocolRewards: [],
    pageSize: 0,
    pageToken: '',
  };

  return {
    isLoading,
    data: data ?? defaultEmptyResponse,
  };
}
