import { IconButton } from '@cbhq/cds-web/buttons';
import { SelectOption } from '@cbhq/cds-web/controls';
import { Dropdown } from '@cbhq/cds-web/dropdown';
import { Icon } from '@cbhq/cds-web/icons';
import { TableCell } from '@cbhq/cds-web/tables';
import { TextHeadline } from '@cbhq/cds-web/typography';

import { OrganizationRole, OrganizationUser } from ':cloud/types/ts_types';

interface ActionCellProps {
  user: OrganizationUser;
  signedInUser: boolean;
  signedInUserRole: OrganizationRole;
  handleMemberAction: (event: string, user: OrganizationUser) => void;
}

export function ActionCell({
  user,
  signedInUser,
  signedInUserRole,
  handleMemberAction,
}: ActionCellProps) {
  const isSignedInMemberOwner = signedInUserRole === 'owner';
  const isSignedInMemberAdmin = signedInUserRole === 'admin';
  return (
    <TableCell alignItems="flex-end">
      {/* row is myself */}
      {/* can't perform any actions */}
      {Boolean(signedInUser) && isSignedInMemberOwner && <DashIcon />}

      {/* row is an owner and I'm not an owner */}
      {/* can't perform any actions */}
      {user.role === 'owner' && !isSignedInMemberOwner && <DashIcon />}

      {/* row is a user role, I'm an admin or an owner */}
      {user.role === 'user' &&
        (isSignedInMemberAdmin || isSignedInMemberOwner) &&
        !signedInUser && (
          <ActionOption handleMemberAction={handleMemberAction} user={user}>
            <>
              {user.status === 'invited' && (
                <>
                  <SelectOption value="resend-invitation" title="Resend invitation" />
                  <SelectOption
                    value="cancel-invitation"
                    title={
                      <TextHeadline color="negative" as="span">
                        Cancel invitation
                      </TextHeadline>
                    }
                  />
                </>
              )}
              {user.status === 'active' && (
                <>
                  <SelectOption value="edit-member" title="Edit" />
                  <SelectOption
                    value="remove-member"
                    title={
                      <TextHeadline color="negative" as="span">
                        Remove
                      </TextHeadline>
                    }
                  />
                  {/* row is an active user, I'm an owner and can change owner */}
                  {isSignedInMemberOwner && (
                    <SelectOption value="change-owner" title="Change owner" />
                  )}
                </>
              )}
            </>
          </ActionOption>
        )}

      {/* row is an admin member, I'm an admin */}
      {user.role === 'admin' &&
        isSignedInMemberAdmin &&
        !signedInUser &&
        user.status === 'active' && <DashIcon />}
      {user.role === 'admin' &&
        isSignedInMemberAdmin &&
        !signedInUser &&
        user.status === 'invited' && (
          <ActionOption handleMemberAction={handleMemberAction} user={user}>
            <SelectOption value="resend-invitation" title="Resend invitation" />
            <SelectOption
              value="cancel-invitation"
              title={
                <TextHeadline color="negative" as="span">
                  Cancel invitation
                </TextHeadline>
              }
            />
          </ActionOption>
        )}

      {/* row is an admin member, I'm an owner */}
      {user.role === 'admin' && isSignedInMemberOwner && !signedInUser && (
        <ActionOption handleMemberAction={handleMemberAction} user={user}>
          <>
            {user.status === 'invited' && (
              <>
                <SelectOption value="resend-invitation" title="Resend invitation" />
                <SelectOption
                  value="cancel-invitation"
                  title={
                    <TextHeadline color="negative" as="span">
                      Cancel invitation
                    </TextHeadline>
                  }
                />
              </>
            )}
            {user.status === 'active' && (
              <>
                <SelectOption value="edit-member" title="Edit" />
                <SelectOption
                  value="remove-member"
                  title={
                    <TextHeadline color="negative" as="span">
                      Remove
                    </TextHeadline>
                  }
                />
                {/* row is an active user, I'm an owner and can change owner */}
                {isSignedInMemberOwner && (
                  <SelectOption value="change-owner" title="Change owner" />
                )}
              </>
            )}
          </>
        </ActionOption>
      )}
    </TableCell>
  );
}

type ActionOptionProps = {
  handleMemberAction: (event: string, user: OrganizationUser) => void;
  user: OrganizationUser;
  children: JSX.Element | JSX.Element[];
};

function ActionOption({ user, children, handleMemberAction }: ActionOptionProps) {
  return (
    <Dropdown width={50} onChange={(event) => handleMemberAction(event, user)} content={children}>
      <IconButton
        name="more"
        accessibilityLabel="Settings action"
        variant="secondary"
        transparent
      />
    </Dropdown>
  );
}

export function DashIcon() {
  return <Icon name="horizontalLine" size="xs" color="foregroundMuted" spacingEnd={2} />;
}
