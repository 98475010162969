import { ForwardedRef, forwardRef, memo, useCallback, useMemo } from 'react';
import { zIndex as cdsZIndex } from '@cbhq/cds-common/tokens/zIndex';
import { Box } from '@cbhq/cds-web/layout';
import { spacing } from '@cbhq/cds-web/tokens';
import { TextLabel2 } from '@cbhq/cds-web/typography';

import { PopperFlyoutProps } from './FlyoutProps';

export const FlyoutContent = memo(
  forwardRef(
    (
      { content, gap, testID, zIndex, flyoutId, placement, onClick }: PopperFlyoutProps,
      ref: ForwardedRef<HTMLDivElement>,
    ) => {
      const spacingPlacement = useMemo(() => {
        switch (placement) {
          case 'top-start':
          case 'top':
          case 'top-end':
            return 'bottom';
          case 'right-start':
          case 'right':
          case 'right-end':
            return 'left';
          case 'bottom-start':
          case 'bottom':
          case 'bottom-end':
            return 'top';
          case 'left-start':
          case 'left':
          case 'left-end':
            return 'right';
          default:
            return 'bottom';
        }
      }, [placement]);

      // Add extra padding for a more forgiving hover area
      const padding = useMemo(() => {
        return `calc(${spacing[gap]} + ${spacing[1]})`;
      }, [gap]);

      // Add negative margin to offset the extra padding
      const margin = useMemo(() => {
        return `calc(${spacing[1]} * -1)`;
      }, []);

      const outerStyle = useMemo(
        () => ({
          [`padding-${spacingPlacement}`]: padding,
          [`margin-${spacingPlacement}`]: margin,
          zIndex: zIndex ?? cdsZIndex.overlays.tooltip,
        }),
        [zIndex, margin, padding, spacingPlacement],
      );

      const handleOnClick = useCallback(() => {
        onClick?.();
      }, [onClick]);

      return (
        <Box style={outerStyle} onClick={handleOnClick}>
          <Box
            bordered
            elevation={2}
            ref={ref}
            spacingHorizontal={1}
            spacingVertical={0}
            background="background"
            borderRadius="roundedLarge"
            maxWidth={404}
            testID={testID}
          >
            {typeof content === 'string' ? (
              <Box spacing={2}>
                <TextLabel2 as="p" overflow="break" id={flyoutId} accessibilityLabel={content}>
                  {content}
                </TextLabel2>
              </Box>
            ) : (
              <Box id={flyoutId} width="100%">
                {content}
              </Box>
            )}
          </Box>
        </Box>
      );
    },
  ),
);
