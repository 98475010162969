import { ProtocolKey } from ':cloud/types/ts_types';

import { useGetWorkflowByKey } from './useGetWorkflows';

export function useGetProtocolDisplayName(key: ProtocolKey): {
  isLoading: boolean;
  displayName: string;
} {
  const { isLoading, workflow } = useGetWorkflowByKey(key);
  return {
    isLoading,
    displayName: workflow?.display || '',
  };
}
