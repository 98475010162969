/* eslint-disable import/no-unused-modules */
/* coinbase link */
export const COINBASE_HELP = 'https://help.coinbase.com';
const COINBASE_HOME = 'https://www.coinbase.com';
export const COINBASE_PRIVACY = `${COINBASE_HOME}/legal/privacy`;
export const CLOUD_TOS = `${COINBASE_HOME}/legal/cloud/terms-of-service`;
export const BUSINESS_SIGNUP_LINK = 'https://accounts.coinbase.com/businesssignup';
export const COINBASE_CAREERS = 'https://www.coinbase.com/careers/positions/developer-platform';

/* cloud link */
export const CLOUD_DOCS = 'https://docs.cdp.coinbase.com';
const CLOUD_DOCS_BETA = 'https://cloud-docs-beta.coinbase.com';

export const CLOUD_FORUM = 'https://discord.gg/cdp';
export const CLOUD_HELP = `${COINBASE_HELP}/en/contact-us?product=coinbase-cloud&topic=Coinbase+Cloud+Services`;
export const CLOUD_SALES = `${COINBASE_HOME}/cloud/cloud-interest`;
// export const CLOUD_PROJECTS = `${CLOUD_DOCS}/waas/docs/cloud-projects`;
// export const CLOUD_MANAGEMENT = `${CLOUD_DOCS}/waas/docs/cloud-keys`;
// export const CLOUD_HELP_FAQ = `${COINBASE_HELP}/en/cloud`;

export const CLOUD_DISCORD = 'https://discord.gg/cdp';
/* cloud products link */
export const CLOUD_SALES_WAAS = `${COINBASE_HOME}/cloud/cloud-interest?product=Wallet-as-a-Service`;
export const WAAS_DOCS = `${CLOUD_DOCS}/waas`;
export const EMBEDDED_WALLETS_DOCS_LINK = `${CLOUD_DOCS}/embedded-wallets/docs/welcome`;
export const ONRAMP_DOCS_LINK = `${CLOUD_DOCS}/onramp/docs/welcome`;
export const WAAS_SDK = 'https://github.com/coinbase/waas-sdk-react-native';
export const BASE_DOCS = `${CLOUD_DOCS}/node/docs/welcome`;
export const PAYMASTER_DOCS = 'https://docs.cdp.coinbase.com/node/docs/paymaster-bundler-qs';
export const BASE_DISCORD = 'https://discord.com/invite/buildonbase';
export const WAAS_WELCOME_LINK = 'https://docs.cloud.coinbase.com/waas/docs/welcome';
export const CDP_BASE_DISCORD = 'https://discord.gg/eJKmu4CR';
export const WALLETS_DISCORD_LINK =
  'https://discord.com/channels/1220414409550336183/1225853955452440647';
export const EMBEDDED_WALLETS_DISCORD_LINK =
  'https://discord.com/channels/1220414409550336183/1220463644198830223';
export const SMART_WALLET_DOCS_LINK = 'https://www.smartwallet.dev/why';
export const BOAT_LINK = 'https://buildonchainapps.xyz';
export const BOAT_GITHUB_LINK = 'https://github.com/coinbase/build-onchain-apps';
export const ONCHAIN_KIT_LINK = 'https://onchainkit.xyz/';
export const ONCHAIN_KIT_GITHUB_LINK = 'https://github.com/coinbase/onchainkit';
export const PAYMASTER_DISCORD_LINK =
  'https://discord.com/channels/1220414409550336183/1233164126251909190';
export const ONRAMP_DISCORD_LINK =
  'https://discord.com/channels/1220414409550336183/1222644539261718609';
export const STAKING_DISCORD_LINK =
  'https://discord.com/channels/1220414409550336183/1220465786750242836';

export const COINBASE_PLATFORM_APIS_DOCS_LINK = `${CLOUD_DOCS}/mpc-wallet/docs/quickstart`;
export const COINBASE_PLATFORM_APIS_DISCORD_LINK =
  'https://discord.com/channels/1220414409550336183/1232677295546957919';

export const PRIME_DOCS_LINK = `${CLOUD_DOCS}/prime/docs/introduction`;
export const EXCHANGE_DOCS_LINK = `${CLOUD_DOCS}/exchange/docs/welcome`;
export const COMMERCE_DOCS_LINK = `${CLOUD_DOCS}/commerce/docs/welcome`;
export const COINBASE_WALLET_SDK_DOCS_LINK = `${CLOUD_DOCS}/wallet-sdk/docs/welcome`;
export const ROSETTA_DOCS_LINK = `${CLOUD_DOCS}/rosetta/docs/welcome`;
export const COINBASE_APP_DOCS_LINK = `${CLOUD_DOCS}/coinbase-app/docs/welcome`;
export const ADVANCED_TRADING_DOCS_LINK = `${CLOUD_DOCS}/advanced-trade/docs/welcome`;
export const ADVANCED_TRADING_MARKETING_LINK = 'https://advanced.coinbase.com';
export const ADVANCED_TRADE_UPGRADE_INFO_LINK =
  'https://help.coinbase.com/en/coinbase/trading-and-funding/cb-advanced/fee-upgrade-incentive';
export const ADVANCED_TRADE_UPGRADE_LINK = 'https://www.coinbase.com/advanced-fee-upgrade';
export const TRADE_DOCS_LINK = `${CLOUD_DOCS}/cdp-apis/docs/trades`;

export const BASE_DEMO_APP_LINK = 'https://buildonchainapps.xyz/paymaster-bundler';
export const PAYMASTER_BUNDLER_DOCS_LINK =
  'https://docs.cdp.coinbase.com/node/docs/paymaster-bundler-api';
export const PAYMASTER_GITHUB_LINK = 'https://github.com/coinbase/paymaster-bundler-examples';

export const STAKING_CUSTOMER_STORY_LINK =
  'https://www.coinbase.com/cloud/discover/customer-stories/cb-wallet';

export const GO_DOCS_LINK = 'https://github.com/coinbase/staking-client-library-go';
export const TYPESCRIPT_DOCS_LINK = 'https://github.com/coinbase/staking-client-library-ts';

export const STAKING_DOCS_LINK = `${CLOUD_DOCS}/staking/docs/welcome`;
export const STAKING_CASE_STUDY = `${COINBASE_HOME}/cloud/discover/customer-stories/cb-wallet`;
export const STAKING_DOCS_CREATE_API_KEY = `${CLOUD_DOCS_BETA}/f8d0c4483a86d9b6b843418ecb24d8d7c593608b/staking/docs/get-started/#2-generate-an-api-key`;
export const STAKING_CLIENT_LIBRARY_GITHUB =
  'https://github.com/coinbase/staking-client-library-ts';

export const VERIFICATIONS_LINK = 'https://www.coinbase.com/onchain-verify';
export const VERIFICATIONS_DOCS_LINK = `${CLOUD_DOCS}/verifications/docs/welcome`;
export const VERIFICATIONS_QUICKSTART_LINK = `${CLOUD_DOCS}/verifications/docs/quickstart`;
export const VERIFICATIONS_GITHUB_LINK = 'https://github.com/coinbase/verifications';
export const EAS_SCAN_LINK = 'https://base.easscan.org/';
export const EAS_SCAN_DOCS_LINK = 'https://docs.attest.org/docs/developer-tools/eas-sdk';

export const INDEXERS_DOCS_LINK = `${CLOUD_DOCS}/onchain-data/docs/onchain-data-api`;
export const INDEXERS_DISCORD_LINK = 'https://discord.gg/dWtVApNB';
export const MARKET_DATA_TERMS_OF_USE = 'https://www.coinbase.com/legal/market_data';

export const PAYMASTER_GUIDE_DOCS_URL = `${CLOUD_DOCS}/node/docs/paymaster-bundler-qs/`;
