import { memo, useCallback, useState } from 'react';
import _capitalize from 'lodash/capitalize';
import { noop } from '@cbhq/cds-utils';
import { Button } from '@cbhq/cds-web/buttons';
import { Select, SelectOption } from '@cbhq/cds-web/controls';
import { VStack } from '@cbhq/cds-web/layout';
import { Avatar } from '@cbhq/cds-web/media';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@cbhq/cds-web/overlays';
import { TextBody, TextTitle2 } from '@cbhq/cds-web/typography';

import { useGetOrganization } from ':cloud/queries/OrganizationQueries/useGetOrganization';
import { useSwitchMemberRole } from ':cloud/queries/OrganizationQueries/useSwitchMemberRole';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { OrganizationActiveUser, OrganizationRole } from ':cloud/types/ts_types';

type Props = {
  visible: boolean;
  user: OrganizationActiveUser;
  orgId: string;
  onRequestClose?: () => void;
};

export const EditMemberModal = memo(function EditMemberModal({
  visible,
  user,
  orgId,
  onRequestClose,
}: Props) {
  const { activeOrg } = useGetUser();
  const [newRole, setNewRole] = useState<OrganizationRole>(user.role || 'user');
  const { activeUsers } = useGetOrganization(activeOrg?.organizationId);
  const coinbaseUserId = activeUsers.find((u) => u?.id === user.id)?.coinbaseUserId || '';
  const editMemberMutation = useSwitchMemberRole(orgId, user.email, coinbaseUserId);

  const handleEditMember = useCallback(() => {
    editMemberMutation.mutate(newRole, {
      onSuccess: onRequestClose,
    });
  }, [editMemberMutation, newRole, onRequestClose]);

  const handleSelectChange = useCallback((value) => setNewRole(value), []);

  return (
    <Modal visible={visible} onRequestClose={onRequestClose || noop}>
      <ModalHeader title="Edit Member" />
      <ModalBody>
        <VStack gap={3} alignItems="center" spacing={1}>
          <Avatar alt={user.name || user.email} size="xxxl" />
          <VStack alignItems="center" gap={0.5}>
            <TextTitle2 as="h4">{user.name}</TextTitle2>
            <TextBody color="foregroundMuted" as="p">
              {user.email}
            </TextBody>
          </VStack>
          <Select
            label="Role"
            valueLabel={_capitalize(newRole)}
            value={newRole}
            onChange={handleSelectChange}
          >
            <SelectOption value="user" title="User" />
            <SelectOption value="admin" title="Admin" />
          </Select>
        </VStack>
      </ModalBody>
      <ModalFooter
        primaryAction={
          <Button disabled={user.role === newRole} onPress={handleEditMember}>
            Confirm changes
          </Button>
        }
        secondaryAction={
          <Button variant="secondary" onPress={onRequestClose}>
            Cancel
          </Button>
        }
      />
    </Modal>
  );
});
