import { useMemo } from 'react';
import _sumBy from 'lodash/sumBy';

import { logError } from ':cloud/init/bugsnag/logging';
import { useGetAccountCredits } from ':cloud/queries/BillingQueries/useGetAccountCredits';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';

const LOW_BALANCE_THRESHOLD = 0.8; // over 80% used

export function useAccountCreditsBalances() {
  const { activeOrg } = useGetUser();
  const orgId = activeOrg?.organizationId;
  const { accountCredits } = useGetAccountCredits(orgId);

  // Setup default values for fault tolerance
  const defaultValues = {
    accountCreditsIssued: 0,
    accountCreditsRemaining: 0,
    isAccountCreditsBalanceLow: false,
    accountCreditTypes: [],
    additionalCredits: {} as Record<string, number>,
  };

  try {
    const accountCreditsIssued = useMemo(() => {
      return _sumBy(accountCredits, (credit) => +credit.amountIssued);
    }, [accountCredits]);

    const accountCreditsRemaining = _sumBy(
      accountCredits.filter((credit) => !credit.creditType.startsWith('additional_')),
      (credit) => +credit.amountRemaining,
    );

    const accountCreditsUsed = 1 - accountCreditsRemaining / accountCreditsIssued;
    const isAccountCreditsBalanceLow = accountCreditsUsed >= LOW_BALANCE_THRESHOLD;

    const accountCreditTypes = [...new Set(accountCredits.map((credit) => credit.creditType))];

    const additionalCreditsList = accountCredits.filter((credit) =>
      credit.creditType.startsWith('additional_'),
    );
    const additionalCredits = additionalCreditsList.reduce((acc, credit) => {
      acc[credit.creditType] = (acc[credit.creditType] || 0) + +credit.amountRemaining;
      return acc;
    }, {}) as Record<string, number>;

    return {
      accountCreditsIssued,
      accountCreditsRemaining,
      isAccountCreditsBalanceLow,
      accountCreditTypes,
      additionalCredits,
    };
  } catch (error) {
    logError(error, {
      context: 'useAccountCreditsBalances',
    });
    // Return default values in case of an error
    return defaultValues;
  }
}
