import { Box, VStack } from '@cbhq/cds-web/layout';
import { Spinner } from '@cbhq/cds-web/loaders/Spinner';
import { TextHeadline } from '@cbhq/cds-web/typography';

interface LoadingSpinnerProps {
  message?: string;
}

export function LoadingSpinner({ message }: LoadingSpinnerProps) {
  return (
    <VStack display="flex" alignItems="center" gap={7} width="100%" spacing={10}>
      {message && (
        <Box maxWidth={500}>
          <TextHeadline as="p">{message}</TextHeadline>
        </Box>
      )}
      <Spinner color="primary" size={5} />
    </VStack>
  );
}
