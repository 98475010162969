import { useMemo } from 'react';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextHeadline } from '@cbhq/cds-web/typography';

import { PaymentSepaDebit } from ':cloud/types/service_proto';

type PaymentMethodDisplaySepaDebitProps = {
  displayedMethod: PaymentSepaDebit;
  showIcon?: boolean;
  transparent?: boolean;
  expanded?: boolean;
};

export function PaymentMethodDisplaySepaDebit({
  displayedMethod: sepaDebit,
  showIcon = true,
  expanded = false,
}: PaymentMethodDisplaySepaDebitProps) {
  const title = useMemo(() => `SEPA Direct Debit ***${sepaDebit.lastFour}`, [sepaDebit.lastFour]);
  return (
    <HStack alignItems="center">
      {showIcon && (
        <Box spacingEnd={3}>
          <Icon size="m" name="bank" />
        </Box>
      )}
      {expanded ? (
        <HStack gap={3}>
          <TextHeadline as="h4">{title}</TextHeadline>
        </HStack>
      ) : (
        <VStack alignItems="flex-start">
          <TextHeadline as="h4">{title}</TextHeadline>
        </VStack>
      )}
    </HStack>
  );
}
