import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import _mapKeys from 'lodash/mapKeys';

import config from ':cloud/config';
import { QueryKey } from ':cloud/queries/types';
import { SessionContext } from ':cloud/utils/SessionProvider';

type GetExperimentsParams = {
  subjectId: string;
  isEmployee: boolean;
};

type ExperimentsResponse = {
  groups: {
    test: string;
    group: string;
    isTracked?: boolean;
  }[];
};

type Experiment = {
  name: string;
  group: string;
  isTracked?: boolean;
};

const EXPERIMENTS_WEB_CLIENT_TYPE = 3;
const SUBJECT_TYPE_UUID = 6;

function getExperimentsUrl(params: GetExperimentsParams) {
  const { subjectId, isEmployee } = params;

  const query = {
    /* eslint-disable camelcase */
    subject_id: subjectId,
    subject_type: SUBJECT_TYPE_UUID,
    is_employee: isEmployee,
    client: {
      type: EXPERIMENTS_WEB_CLIENT_TYPE,
    },
    /* eslint-enable camelcase */
  };
  const encodedQuery = btoa(JSON.stringify(query));
  const searchParams = new URLSearchParams({ q: encodedQuery });
  return `${config.apiBaseURL}/api/v3/coinbase.experiment.ExperimentService/ListSplitTestGroups?${searchParams}`;
}

/**
 * using `fetch` because axios strips out the `content-type` header on GET requests.
 * For some reason, our API needs it to be there to process the request.
 * If this ever fails with a mysterious error that "-proto: cannot parse invalid wire-format data",
 * that means that likely means that a `fetch` upgrade now removes the `content-type` header
 */
async function fetchExperiments(params: GetExperimentsParams): Promise<ExperimentsResponse> {
  const url = getExperimentsUrl(params);

  try {
    const response = await fetch(url, { headers: { 'content-type': 'application/json' } });
    const data = (await response.json()) as ExperimentsResponse;
    return data;
  } catch (error) {
    throw new Error((error as Error)?.message ?? 'Error from useGetExperiments');
  }
}

export function useGetExperiments(): {
  isLoading: boolean;
  experiments: Experiment[];
} {
  const { sessionResponse } = useContext(SessionContext);

  const { isLoading, data, error } = useQuery({
    queryKey: [QueryKey.experiments, sessionResponse?.userUuid, sessionResponse?.email],
    queryFn: async () =>
      fetchExperiments({
        subjectId: sessionResponse?.userUuid || '',
        isEmployee: sessionResponse?.email
          ? sessionResponse.email.endsWith('@bisontrails.co') ||
            sessionResponse.email.endsWith('@coinbase.com')
          : false,
      }),
    staleTime: Infinity,
    enabled: !!sessionResponse?.email,
  });

  if (error) {
    throw new Error((error as Error)?.message);
  }

  return {
    // we don't have a sessionResponse when running mocks so we don't block loading in that case
    isLoading: isLoading && !config.isMocksEnabled(),
    experiments:
      // rename `test` to `name` in the response objects
      // [{ test: 'sample_test, ... }] => [{name: 'sample_text', ... }]
      (data?.groups?.map((obj) =>
        _mapKeys(obj, (_, key) => (key === 'test' ? 'name' : key)),
      ) as Experiment[]) || [],
  };
}
