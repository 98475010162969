import _map from 'lodash/map';
import _startCase from 'lodash/startCase';
import { Grid } from '@cbhq/cds-web/layout';
import { TextTitle1 } from '@cbhq/cds-web/typography';

import { threeColumnGrid } from ':cloud/utils/responsiveConfigs';
import { Layout } from ':cloud/widgets/layout';
import { DisplayUnit } from ':cloud/widgets/sharedcomponents';

interface ClusterAddressesProps {
  addresses?: Record<string, string>;
}

export function ClusterAddresses({ addresses }: ClusterAddressesProps) {
  if (!addresses) {
    return null;
  }

  return (
    <Layout.ContentWrapper>
      <TextTitle1 spacingBottom={5} as="h2">
        Addresses
      </TextTitle1>
      <Grid gap={3} responsiveConfig={threeColumnGrid}>
        {_map(addresses, (val: string, key: string) => {
          return (
            <DisplayUnit
              key={key}
              overrideTruncate
              isCard
              label={_startCase(key.toString())}
              value={val}
            />
          );
        })}
      </Grid>
    </Layout.ContentWrapper>
  );
}
