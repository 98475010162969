import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { APIClientRM } from ':cloud/state/Api';

async function updateDevMode(value: boolean): Promise<boolean> {
  const path = `/v1/self/developer-mode`;
  const { data } = await APIClientRM.put<boolean>(path, { developerModeEnabled: value });
  return data;
}

export function useIsDeveloperMode(): {
  isDeveloperMode: boolean;
  shouldShowDevModeToggle: boolean;
  mutateDevMode: UseMutationResult<boolean, unknown, boolean, unknown>;
} {
  const { user } = useGetUser();

  const queryClient = useQueryClient();

  const mutateDevMode = useMutation({
    mutationFn: async (value: boolean) => updateDevMode(value),
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKey.self]);
    },
    meta: {
      errorMsg: `Failed to update developer mode`,
    },
  });

  return {
    isDeveloperMode: user.developerModeEnabled,
    shouldShowDevModeToggle: user.shouldShowDevModeToggle,
    mutateDevMode,
  };
}
