import { Button } from '@cbhq/cds-web/buttons';
import { SpotRectangle } from '@cbhq/cds-web/illustrations';
import { VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextTitle1 } from '@cbhq/cds-web/typography';

import { EventName, useLogViewOnMount } from ':cloud/init/clientAnalytics/logging';
import { AppRoute } from ':cloud/utils/routes';

type APIKeyTableEmptyStateProps = {
  hasProjects: boolean;
  isViewOnly: boolean;
  onCreatePress: (projectFilter?: string) => void;
  isTradeTable?: boolean;
};

export function APIKeyTableEmptyState({
  hasProjects,
  isViewOnly,
  onCreatePress,
  isTradeTable = false,
}: APIKeyTableEmptyStateProps) {
  useLogViewOnMount(EventName.api_key_empty_state_view);

  return (
    <VStack
      testID="api-key-table-empty-state"
      justifyContent="center"
      alignItems="center"
      gap={1}
      width="100%"
      spacingVertical={10}
    >
      {hasProjects || isTradeTable ? (
        <VStack width={358} gap={5}>
          <VStack gap={3} alignItems="center">
            <SpotRectangle name="apiKey" scaleMultiplier={1} />
            <TextTitle1 as="p" align="center">
              Get started with an API key
            </TextTitle1>
            <TextBody as="p" color="foregroundMuted" align="center">
              API keys are used to identify and authenticate your applications. You can use your API
              keys for integrating with third party developers.
            </TextBody>
          </VStack>
          {!isViewOnly && <Button onPress={() => onCreatePress()}>Create API key</Button>}
        </VStack>
      ) : (
        <VStack width={358} gap={5}>
          <VStack gap={3} alignItems="center">
            <SpotRectangle name="apiKey" scaleMultiplier={1} />
            <TextTitle1 as="p" align="center">
              Create a project to generate your API key
            </TextTitle1>
            <TextBody as="p" color="foregroundMuted" align="center">
              Starting a project is required for generating an API key.
            </TextBody>
          </VStack>
          {!isViewOnly && <Button to={AppRoute.Projects.Home}>Create project</Button>}
        </VStack>
      )}
    </VStack>
  );
}
