import { styled } from '@linaria/react';
import { PortalProvider as CDSPortalProvider } from '@cbhq/cds-web/overlays/PortalProvider';
import { ThemeProvider as CDSThemeProvider } from '@cbhq/cds-web/system/ThemeProvider';

import { useAppState } from ':cloud/contexts/AppStateContext';
import { WithChildren } from ':cloud/types/helper';

const FontBaseline = styled.div`
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
  pre,
  code {
    font-family: var(--cds-font-mono);
  }
`;

export function CloudThemeProvider({ children }: WithChildren) {
  const { isDarkMode } = useAppState();
  return (
    <CDSThemeProvider scale="xSmall" spectrum={isDarkMode ? 'dark' : 'light'}>
      <CDSPortalProvider>
        <FontBaseline>{children}</FontBaseline>
      </CDSPortalProvider>
    </CDSThemeProvider>
  );
}
