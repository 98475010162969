import { ArrayFieldTemplateProps } from '@rjsf/core';
import { Button } from '@cbhq/cds-web/buttons';
import { HStack, VStack } from '@cbhq/cds-web/layout';

type ToolbarProp = {
  canAdd: boolean;
  hasRemove: boolean;
  onAddClick: (event?: any) => void;
  onDropIndexClick: (index: number) => (event?: any) => void;
  idx: number;
};

function Toolbar({
  hasRemove,
  canAdd,
  onDropIndexClick,
  onAddClick,
  idx,
}: ToolbarProp): JSX.Element | null {
  return (
    <>
      {canAdd ? (
        <Button block={false} onPress={onAddClick} transparent>
          Add
        </Button>
      ) : null}
      {hasRemove ? (
        <Button block={false} onPress={onDropIndexClick(idx)} transparent>
          Remove
        </Button>
      ) : null}
    </>
  );
}
/** currently only used by Algorand */
/** existing bug: user can progress to next step without filling in steps for Algorand only, unless an address is selected */
export function ArrayFieldTemplate({ canAdd, onAddClick, items }: ArrayFieldTemplateProps) {
  return (
    <VStack width="100%" maxWidth={600}>
      {items.map(({ children, key, hasRemove, hasToolbar, onDropIndexClick, index }) => (
        <HStack key={key} alignItems="center">
          {children}
          {hasToolbar ? (
            <Toolbar
              onAddClick={onAddClick}
              canAdd={canAdd}
              onDropIndexClick={onDropIndexClick}
              hasRemove={hasRemove}
              idx={index}
            />
          ) : null}
        </HStack>
      ))}
    </VStack>
  );
}
