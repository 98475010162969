import { useCallback, useMemo } from 'react';
import { Switch } from '@cbhq/cds-web/controls';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { TextHeadline } from '@cbhq/cds-web/typography';

import { useAppState } from ':cloud/contexts/AppStateContext';
import { EventName, logClick } from ':cloud/init/clientAnalytics/logging';
import { useUpdateGasPolicy } from ':cloud/queries/Base/useUpdateGasPolicy';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { GasPolicy } from ':cloud/types/ts_types';

type PaymasterRequirementsSectionProps = {
  gasPolicy: GasPolicy;
};

export function PaymasterRequirementsSection({ gasPolicy }: PaymasterRequirementsSectionProps) {
  const { activeOrg } = useGetUser();
  const { selectedProject } = useAppState();
  const updateGasPolicy = useUpdateGasPolicy({
    organizationId: activeOrg?.organizationId || '',
    projectId: selectedProject?.id || '',
    successMsg: 'Requirements updated',
  });

  const initialAttestationRequirements = useMemo(() => {
    return gasPolicy?.attestationRequirements?.map((requirement) => requirement.schemaId);
  }, [gasPolicy?.attestationRequirements]);

  const isCoinbaseVerifiedUserChecked = initialAttestationRequirements?.includes('cb_verified');
  const isCoinbase1Checked = initialAttestationRequirements?.includes('cb_1');

  const handleRequirementToggle = useCallback(
    (requirement: string) => {
      return () => {
        if (!gasPolicy) {
          return;
        }
        let attestationRequirements = gasPolicy?.attestationRequirements || [];

        const isChecked =
          requirement === 'cb_1' ? isCoinbase1Checked : isCoinbaseVerifiedUserChecked;

        if (!isChecked) {
          attestationRequirements = attestationRequirements.concat({ schemaId: requirement });
        } else {
          attestationRequirements = attestationRequirements.filter(
            (item) => item.schemaId !== requirement,
          );
        }
        const newGasPolicy = {
          ...gasPolicy,
          attestationRequirements,
        };
        logClick(EventName.paymaster_update_requirements);
        updateGasPolicy.mutate({ policy: newGasPolicy });
      };
    },
    [gasPolicy, isCoinbase1Checked, isCoinbaseVerifiedUserChecked, updateGasPolicy],
  );

  return (
    <VStack gap={3} spacingTop={3}>
      <HStack gap={3} justifyContent="space-between" alignItems="center">
        <TextHeadline as="p">Coinbase Verified User</TextHeadline>
        <Switch
          onChange={handleRequirementToggle('cb_verified')}
          checked={isCoinbaseVerifiedUserChecked}
        />
      </HStack>
      <HStack gap={3} justifyContent="space-between" alignItems="center">
        <TextHeadline as="p">Coinbase One Subscriber</TextHeadline>
        <Switch onChange={handleRequirementToggle('cb_1')} checked={isCoinbase1Checked} />
      </HStack>
    </VStack>
  );
}
