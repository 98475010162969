import { PlatformName } from '@cbhq/client-analytics';
import { defaultClientKillSwitchConfig } from '@cbhq/client-kill-switch';
import { MiscMetadata } from '@cbhq/error-vitals-web';
import { enableResearchEventsOnNavigationChange, init } from '@cbhq/user-research-web';

import config from ':cloud/config';
import { logError } from ':cloud/init/bugsnag/logging';
import { history } from ':cloud/utils/history';

export function initSprig() {
  try {
    init({
      platform: PlatformName.web,
      environmentId: config.sprigId,
      killSwitchConfig: defaultClientKillSwitchConfig.default,
      onError: (error, metadata) =>
        logError(error, { context: 'sprig_init' }, metadata as MiscMetadata),
    });
    enableResearchEventsOnNavigationChange(history);
  } catch (error) {
    logError(error, { context: 'sprig_init' });
  }
}
