import { useQuery } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientRM } from ':cloud/state/Api';
import { IWorkflow, ProtocolKey } from ':cloud/types/ts_types';

async function fetchWorkflowVersion(wf: WorkflowVersionProps): Promise<IWorkflow> {
  const path = wf.exactVersion
    ? `/v1/protocols/${encodeURIComponent(wf.protocol)}?version=${wf.version}&exactVersion=true`
    : `/v1/protocols/${encodeURIComponent(wf.protocol)}?version=${wf.version}`;
  const { data } = await APIClientRM.get<IWorkflow>(path);
  return data;
}

interface WorkflowVersionProps {
  protocol: ProtocolKey;
  version: string;
  exactVersion: boolean;
}

export function useGetWorkflowVersion(wf: WorkflowVersionProps): {
  isLoading: boolean;
  workflow?: IWorkflow;
} {
  const { isLoading, data } = useQuery({
    queryKey: [QueryKey.workflows, wf.protocol, wf.version, wf.exactVersion],
    queryFn: async () =>
      fetchWorkflowVersion({
        protocol: wf.protocol,
        version: wf.version,
        exactVersion: wf.exactVersion,
      }),
    meta: { retry: 3 },
    staleTime: Infinity,
    enabled: !!wf.protocol && !!wf.version,
  });

  return { isLoading, workflow: data };
}
