import { useCallback, useState } from 'react';
import { useToggler } from '@cbhq/cds-common/hooks/useToggler';
import { VStack } from '@cbhq/cds-web/layout';
import { useToast } from '@cbhq/cds-web/overlays/useToast';

import { useAppState } from ':cloud/contexts/AppStateContext';
import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { EventName, logClick, useLogViewOnMount } from ':cloud/init/clientAnalytics/logging';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { useCreateWebhook } from ':cloud/queries/WebhookQueries/useCreateWebhook';
import { useDeleteWebhook } from ':cloud/queries/WebhookQueries/useDeleteWebhook';
import { useUpdateWebhook } from ':cloud/queries/WebhookQueries/useUpdateWebhook';
import { CoinbaseWebhook, EditCoinbaseWebhook } from ':cloud/types/ts_types';
import { Layout } from ':cloud/widgets/layout';
import { MAIN_CONTAINER_MIN_WIDTH } from ':cloud/widgets/product/constants';
import { CloudErrorBoundary } from ':cloud/widgets/sharedcomponents';
import { CoinbaseWebhookCreateModal } from ':cloud/widgets/webhooks/CoinbaseWebhookCreateModal';
import { CoinbaseWebhookEditModal } from ':cloud/widgets/webhooks/CoinbaseWebhookEditModal';
import { CoinbaseWebhookTable } from ':cloud/widgets/webhooks/CoinbaseWebhookTable';

function WebhooksContent(): JSX.Element {
  useLogViewOnMount(EventName.webhook_page_view);

  const { isPhone } = useSimpleBreakpoints();
  const [createModalVisible, { toggleOn: toggleCreateModalOn, toggleOff: toggleCreateModalOff }] =
    useToggler(false);

  const [editModalVisible, { toggleOn: toggleEditModalOn, toggleOff: toggleEditModalOff }] =
    useToggler(false);

  const [selectedWebhook, setSelectedWebhook] = useState<CoinbaseWebhook | null>(null);
  const { activeOrg } = useGetUser();
  const { selectedProject } = useAppState();
  const toast = useToast();

  const createWebhookMutation = useCreateWebhook(
    activeOrg?.organizationId,
    undefined,
    toggleCreateModalOff,
  );

  const updateWebhookMutation = useUpdateWebhook(undefined, toggleEditModalOff);

  const deleteWebhookMutation = useDeleteWebhook((deletedWebhookId) => {
    toast.show(`Webhook ${deletedWebhookId} deleted`, {
      variant: 'positive',
    });
  });

  const [, setCoinbaseWebhook] = useState<CoinbaseWebhook>();
  const [, setEditedCoinbaseWebhook] = useState<EditCoinbaseWebhook>();

  const handleCreateWebhook = useCallback(
    (webhook: CoinbaseWebhook) => {
      setCoinbaseWebhook(webhook);
      createWebhookMutation.mutate({
        orgId: activeOrg?.organizationId || '',
        projectId: selectedProject?.id || '',
        network: webhook.network,
        eventType: webhook.eventType,
        notificationHeaders: webhook.notificationHeaders,
        notificationUri: webhook.notificationUri,
        eventFilters: [
          {
            contractAddress: webhook.eventFilters[0].contractAddress,
            fromAddress: webhook.eventFilters[0].fromAddress,
            toAddress: webhook.eventFilters[0].toAddress,
          },
        ],
      });
    },
    [activeOrg?.organizationId, createWebhookMutation, selectedProject?.id],
  );

  const handleEditWebhook = useCallback(
    (webhook: CoinbaseWebhook) => {
      setEditedCoinbaseWebhook(webhook);
      updateWebhookMutation.mutate({
        orgId: activeOrg?.organizationId || '',
        projectId: selectedProject?.id || '',
        webhookId: webhook.uuid?.hex || '',
        webhook,
      });
    },
    [activeOrg?.organizationId, updateWebhookMutation, selectedProject?.id],
  );

  const handleDeleteWebhook = useCallback(
    (webhook: CoinbaseWebhook) => {
      deleteWebhookMutation.mutate({
        orgId: activeOrg?.organizationId || '',
        projectId: selectedProject?.id || '',
        webhookId: webhook.uuid?.hex || '',
      });
    },
    [selectedProject, deleteWebhookMutation, activeOrg?.organizationId],
  );

  const handleCreateWebhookPress = useCallback(() => {
    logClick(EventName.create_webhook_click);

    toggleCreateModalOn();
  }, [toggleCreateModalOn]);

  const handleDeleteWebhookPress = useCallback((keyId) => {
    setSelectedWebhook(keyId);
  }, []);

  const handleEditWebhookPress = useCallback(
    (webhook) => {
      setSelectedWebhook(webhook);
      toggleEditModalOn();
    },
    [toggleEditModalOn],
  );

  return (
    <>
      {createModalVisible && (
        <CoinbaseWebhookCreateModal
          onSubmit={handleCreateWebhook}
          onRequestClose={toggleCreateModalOff}
        />
      )}

      {editModalVisible && selectedWebhook && (
        <CoinbaseWebhookEditModal
          onRequestClose={toggleEditModalOff}
          webhook={selectedWebhook}
          onSubmit={handleEditWebhook}
          onDelete={handleDeleteWebhook}
          organizationId={activeOrg?.organizationId || ''}
          projectId={selectedProject?.id || ''}
        />
      )}

      <VStack gap={8}>
        {!isPhone && (
          <CoinbaseWebhookTable
            onCreatePress={handleCreateWebhookPress}
            onDeletePress={handleDeleteWebhookPress}
            onEditPress={handleEditWebhookPress}
          />
        )}
      </VStack>
    </>
  );
}

export function WebhooksPage() {
  return (
    <Layout>
      <Layout.MainContainer fullWidth minWidth={MAIN_CONTAINER_MIN_WIDTH}>
        <CloudErrorBoundary name="WebhookTable" isComponentLevel>
          <WebhooksContent />
        </CloudErrorBoundary>
      </Layout.MainContainer>
    </Layout>
  );
}
