import { css } from '@linaria/core';
import _sortBy from 'lodash/sortBy';
import { ResponsiveGridProps } from '@cbhq/cds-web';
import { Accordion } from '@cbhq/cds-web/accordion/Accordion';
import { AccordionItem } from '@cbhq/cds-web/accordion/AccordionItem';
import { Box, Grid } from '@cbhq/cds-web/layout';
import { TextBody, TextTitle3 } from '@cbhq/cds-web/typography';

// This is specifically to maintain parity with the existing two-column terminology layout.
// Can't use interpolated device values in media queries for some reason.
const terminologyBorderStyle = css`
  @media (min-width: 768px) and (max-width: 1279px) {
    border-bottom: none;
  }
`;

const responsiveConfig: ResponsiveGridProps = {
  phone: {
    columns: 1,
    gap: 0,
  },
  tablet: {
    columns: 2,
    gap: 9,
  },
  desktop: {
    columns: 1,
  },
};

export interface TermDefinition {
  order?: number;
  title: string;
  definition: string;
}

interface TerminologyProps {
  data: TermDefinition[];
  heading?: string;
  alphabeticalOrder?: boolean;
}

export function Terminology({
  data,
  heading = 'Terminology',
  alphabeticalOrder = false,
}: TerminologyProps) {
  const secondHalfTerms = _sortBy(data, [alphabeticalOrder ? 'title' : 'order']);
  const firstHalfTerms = secondHalfTerms.splice(0, Math.floor(secondHalfTerms.length / 2));
  return (
    <>
      <Box spacingStart={3} spacingVertical={4} borderedBottom>
        <TextTitle3 as="h2" testID="heading">
          {heading}
        </TextTitle3>
      </Box>
      <Grid responsiveConfig={responsiveConfig} testID="terminology">
        <Box borderedBottom className={terminologyBorderStyle}>
          <Accordion>
            {firstHalfTerms.map(({ title, definition }) => (
              <AccordionItem itemKey={title} title={title} key={title}>
                {definition
                  .split('\r\n')
                  .filter((p) => p !== ' ')
                  .map((p) => (
                    <TextBody as="p" key={p} color="foregroundMuted">
                      {p}
                    </TextBody>
                  ))}
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
        <Box>
          <Accordion>
            {secondHalfTerms.map(({ title, definition }) => (
              <AccordionItem itemKey={title} title={title} key={title}>
                {definition
                  .split('\r\n')
                  .filter((p) => p !== ' ')
                  .map((p) => (
                    <TextBody as="p" key={p} color="foregroundMuted">
                      {p}
                    </TextBody>
                  ))}
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
      </Grid>
    </>
  );
}
