import { Redirect, Route, Switch } from 'react-router-dom';

import { ProjectDashboard } from ':cloud/pages/ProjectDashboard';
import { AppRoute } from ':cloud/utils/routes';

export function Projects() {
  return (
    <Switch>
      <Route exact path={AppRoute.Projects.Overview}>
        <ProjectDashboard />
      </Route>
      <Redirect to={AppRoute.Projects.Overview} />
    </Switch>
  );
}
