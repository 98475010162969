import { AccountItem, Product, Project } from ':cloud/types/ts_types';

type ProjectProduct = Pick<Product, 'serviceId' | 'productName'>;

export function getProjectProducts(
  accountItems: AccountItem[],
  products: Product[],
  project: Project,
): ProjectProduct[] {
  // find active account items for this project
  const projectActiveAccountItemIds = accountItems
    .filter((accountItem) => accountItem.projectId === project.id)
    .map((accountItem) => accountItem.productVariantId);

  // return early if project has no active account items
  if (!projectActiveAccountItemIds.length) return [];

  const projectProducts: ProjectProduct[] = [];

  // iterate over all product variants, and add active ones for this project to the array to be returned
  products.forEach(({ variants, productName, serviceId }) => {
    variants.forEach((variant) => {
      if (projectActiveAccountItemIds.includes(variant.id)) {
        projectProducts.push({ productName, serviceId });
      }
    });
  });

  return projectProducts;
}
