import { useQuery } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { GetPlatformTokenResponse } from ':cloud/types/service_proto';
import { IPlatformToken } from ':cloud/types/ts_types';

async function fetchTokens(organizationId?: string, projectId?: string): Promise<IPlatformToken[]> {
  const path = `/apikeys/v1/organizations/${organizationId}/projects/${projectId}/tokens`;
  const { data } = await APIClientBase.get<GetPlatformTokenResponse>(path);
  return data?.tokens;
}

export function useGetTokens({ organizationId = '', projectId = '', enabled = true }): {
  isLoading: boolean;
  tokens: IPlatformToken[];
} {
  const { isLoading, data } = useQuery({
    queryKey: [QueryKey.tokens, organizationId, projectId],
    queryFn: async () => fetchTokens(organizationId, projectId),
    enabled,
    staleTime: Infinity,
  });

  return {
    isLoading,
    tokens: data || [],
  };
}
