import { useMemo } from 'react';

import useLocalStorage from ':cloud/hooks/useLocalStorage';
import { OrganizationInvite } from ':cloud/types/service_proto';

export function useUnseenInvites(pendingInvites: OrganizationInvite[]) {
  const [notifierIds, setNotifierIds] = useLocalStorage('notifierIds', ['']);

  const unseenInvites = useMemo((): OrganizationInvite[] => {
    return pendingInvites.filter((invite) => !notifierIds.includes(invite.inviteToken));
  }, [pendingInvites, notifierIds]);

  const addNotifierIds = (newIds) => {
    const combinedIds = Array.from(new Set([...notifierIds, ...newIds]));
    setNotifierIds(combinedIds);
  };

  return {
    unseenInvites,
    addNotifierIds,
  };
}
