import { useQuery } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { APIClientCOMSLegacy } from ':cloud/state/Api';
import { GetOrganizationsResponse, OrganizationDetails } from ':cloud/types/service_proto';

// queries COMS endpoint, returns old response shape
async function fetchOrganizations(): Promise<GetOrganizationsResponse> {
  const path = `/organization-management/v1/customers/organizations`;
  const { data } = await APIClientCOMSLegacy.get<GetOrganizationsResponse>(path);
  return data;
}

export interface GetOrganizationOverview {
  userId: string;
  organizationId: string;
  role: string;
  created: string;
  organizationDetails: Omit<OrganizationDetails, 'users' | 'invites'>;
  usersCount: number;
}

type GenerateOrganizationQueryProps = {
  userId: string;
};
export function generateOrganizationQuery({ userId }: GenerateOrganizationQueryProps) {
  return {
    queryKey: [QueryKey.organizations, userId],
    queryFn: async () => fetchOrganizations(),
    staleTime: Infinity,
    enabled: !!userId,
  };
}

// TODO: Migrate to new COMS response data shape
export function useGetAllOrganizations(): {
  organizations: GetOrganizationOverview[];
  isLoading: boolean;
} {
  const { user } = useGetUser();
  const { isLoading, data } = useQuery(
    generateOrganizationQuery({
      userId: user.userId,
    }),
  );
  const organizations =
    data
      ?.map((item) => {
        const { users, invites, ...organizationDetails } = item.organizationDetails;
        return {
          ...item,
          organizationDetails,
          usersCount: users.length,
        };
      })
      .sort((a, b) => a.organizationDetails.name.localeCompare(b.organizationDetails.name)) ?? [];

  return {
    organizations,
    isLoading,
  };
}
