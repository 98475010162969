import { useMutation, useQueryClient } from '@tanstack/react-query';

import { APIClientBase } from ':cloud/state/Api';

import { QueryKey } from '../types';

type GetContractFunctionsResponse = {
  functions: string[];
};

async function getContractFunctions(address?: string): Promise<string[]> {
  const { data } = await APIClientBase.post<GetContractFunctionsResponse>('/rpc/api/functions', {
    address,
  });
  return data?.functions || [];
}

type GetContractFunctionsProps = {
  onSuccess?: (functions: string[]) => void;
};

export function useGetContractFunctions({ onSuccess }: GetContractFunctionsProps) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (address: string) => getContractFunctions(address),
    onSuccess: async (data) => {
      onSuccess?.(data);
      queryClient.setQueryData([QueryKey.contractFunctions], data);
    },
  });
}
