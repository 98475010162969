import { useCallback, useMemo } from 'react';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { Table, TableBody, TableCell, TableHeader, TableRow } from '@cbhq/cds-web/tables';
import { Link, TextBody, TextTitle3 } from '@cbhq/cds-web/typography';

import { useGetActiveOrg } from ':cloud/hooks/useGetActiveOrg';
import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { useGetStakingReports } from ':cloud/queries/EarningsQueries/useGetStakingReports';
import { downloadCSV, downloadPDF } from ':cloud/utils/files';
import { EmptyEarningsView } from ':cloud/widgets/earnings/EmptyEarningsView';
import { CloudErrorBoundary, EmptyTable } from ':cloud/widgets/sharedcomponents';
import { ErrorView } from ':cloud/widgets/sharedcomponents/ErrorView';

function EarningsReportTable() {
  const { isPhone } = useSimpleBreakpoints();
  const toast = useToast();
  const activeOrg = useGetActiveOrg();
  const { sortedReports, isLoading } = useGetStakingReports(activeOrg?.organizationId || '');

  const handleToast = useCallback(
    (success: boolean, fileType: string) => {
      const formattedFileType = fileType.toUpperCase();
      if (success) {
        toast.show(`${formattedFileType} is downloading`, { variant: 'positive' });
      } else {
        toast.show(`Failed to download ${formattedFileType}. Please try again.`, {
          variant: 'negative',
        });
      }
    },
    [toast],
  );

  const handleDownload = useCallback(
    async (filetype: 'pdf' | 'csv', filePath?: string) => {
      if (!filePath) {
        return;
      }
      const success = await (filetype === 'pdf' ? downloadPDF : downloadCSV)(filePath);
      handleToast(success, filetype);
    },
    [handleToast],
  );

  const earningReportItems = useMemo(() => {
    return sortedReports.map((report) => {
      const { label, csvPath, pdfPath } = report;

      return (
        <TableRow key={label}>
          <TableCell height={60} title={label} />
          <TableCell alignItems="flex-end">
            <HStack gap={5}>
              <Box visibility={csvPath ? 'visible' : 'hidden'}>
                <Link variant="headline" onPress={async () => handleDownload('csv', csvPath)}>
                  CSV
                </Link>
              </Box>
              <Box visibility={pdfPath ? 'visible' : 'hidden'}>
                <Link variant="headline" onPress={async () => handleDownload('pdf', pdfPath)}>
                  PDF
                </Link>
              </Box>
            </HStack>
          </TableCell>
        </TableRow>
      );
    });
  }, [sortedReports, handleDownload]);

  if (isLoading) {
    return null;
  }

  if (sortedReports.length === 0) {
    return <EmptyEarningsView />;
  }

  return (
    <Table tableLayout={isPhone ? 'fixed' : 'auto'} compact={isPhone} maxHeight="280px">
      <TableHeader sticky>
        <TableRow>
          <TableCell title="Report" />
          <TableCell title="Download" alignItems="flex-end" />
        </TableRow>
      </TableHeader>
      <CloudErrorBoundary
        name="EarningsReportsTable"
        fallback={
          <TableBody>
            <EmptyTable>
              <ErrorView />
            </EmptyTable>
          </TableBody>
        }
      >
        <TableBody>{earningReportItems}</TableBody>
      </CloudErrorBoundary>
    </Table>
  );
}

export function EarningsReport() {
  return (
    <VStack gap={5} spacingTop={3}>
      <div>
        <TextTitle3 as="h1" spacingBottom={2}>
          Staking reports
        </TextTitle3>
        <TextBody as="p">
          The CSV details rewards by address and epoch, while the PDF offers a monthly reward
          summary.
        </TextBody>
      </div>
      <EarningsReportTable />
    </VStack>
  );
}
