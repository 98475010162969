import { useCallback, useState } from 'react';
import { useToggler } from '@cbhq/cds-common/hooks/useToggler';
import { useAlert } from '@cbhq/cds-common/overlays/useAlert';
import { Banner } from '@cbhq/cds-web/banner/Banner';
import { Alert } from '@cbhq/cds-web/overlays';

import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { useGetOrganization } from ':cloud/queries/OrganizationQueries/useGetOrganization';
import { useInviteMember } from ':cloud/queries/OrganizationQueries/useInviteMember';
import { useRemoveInvitedMember } from ':cloud/queries/OrganizationQueries/useRemoveInvitedMember';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { OrganizationActiveUser, OrganizationUser } from ':cloud/types/ts_types';

import { AccessTableWrapper } from './AccessTableWrapper';
import { ChangeOwnerModal } from './ChangeOwnerModal';
import { EditMemberModal } from './EditMemberModal';
import { InviteMemberModal } from './InviteMemberModal';
import { MobileAccessTableWrapper } from './MobileAccessTableWrapper';
import { RemoveMemberModal } from './RemoveMemberModal';

function ExchangeUsersBanner() {
  return (
    <Banner
      title="Your Exchange organization has too many users"
      variant="warning"
      startIcon="warning"
    >
      CDP organizations cannot have additional participants in order to be eligible for a paid
      Market Data subscription. Please remove all members or contact support.
    </Banner>
  );
}

export function AccessTable() {
  const { isPhone } = useSimpleBreakpoints();

  const { user: self, activeOrg } = useGetUser();
  const [selectedUser, setSelectedUser] = useState<OrganizationUser>();
  const { organizationUsers, activeUsers, orgHasMultipleUsers } = useGetOrganization(
    activeOrg?.organizationId,
  );
  const existingUsers = organizationUsers?.map((user) => user.email);

  const [inviteModalVisible, { toggleOn: toggleInviteModalOn, toggleOff: toggleInviteModalOff }] =
    useToggler(false);
  const [editMemberModalVisible, { toggleOn: toggleEditModalOn, toggleOff: toggleEditModalOff }] =
    useToggler(false);
  const [
    removeMemberModalVisible,
    { toggleOn: toggleRemoveModalOn, toggleOff: toggleRemoveModalOff },
  ] = useToggler(false);
  const [
    changeOwnerModalVisible,
    { toggleOn: toggleChangeOwnerModalOn, toggleOff: toggleChangeOwnerModalOff },
  ] = useToggler(false);

  const cancelInvitation = useAlert();
  const resendInvitation = useAlert();

  const reinviteMemberMutation = useInviteMember(
    activeOrg?.organizationId,
    selectedUser?.email,
    true,
  );
  const removeInvitedMemberMutation = useRemoveInvitedMember(
    activeOrg?.organizationId,
    selectedUser?.email,
  );

  /** Reinvite uses Invite member API  */
  const handleReinvitation = useCallback(
    (user) => {
      setSelectedUser(user);
      /** keep modal open when users invitation fails due to network error */
      reinviteMemberMutation.mutate(user?.role, {
        onSuccess: () => {
          resendInvitation.close();
          setSelectedUser(undefined);
        },
      });
    },
    [reinviteMemberMutation, resendInvitation],
  );

  const handleCancelInvitation = useCallback(
    (user) => {
      if (!user?.role) return;
      setSelectedUser(user);
      removeInvitedMemberMutation.mutate(user?.role, {
        onSuccess: () => {
          cancelInvitation.close();
          setSelectedUser(undefined);
        },
      });
    },
    [cancelInvitation, removeInvitedMemberMutation],
  );

  const handleMemberAction = useCallback(
    (event: string, user: OrganizationUser): void => {
      const handleResendInvitationClose = resendInvitation.close;
      const handleCancelInvitationClose = cancelInvitation.close;
      switch (event) {
        case 'edit-member':
          setSelectedUser(user);
          toggleEditModalOn();
          break;
        case 'remove-member':
          setSelectedUser(user);
          toggleRemoveModalOn();
          break;
        case 'change-owner':
          setSelectedUser(user);
          toggleChangeOwnerModalOn();
          break;
        case 'resend-invitation':
          setSelectedUser(user);
          resendInvitation.open(
            <Alert
              visible
              title="Are you sure you want to resend this invitation?"
              body=""
              onRequestClose={handleResendInvitationClose}
              preferredActionLabel="Yes, resend"
              onPreferredActionPress={() => handleReinvitation(user)}
              dismissActionLabel="Nevermind"
              onDismissActionPress={handleResendInvitationClose}
            />,
          );
          break;
        case 'cancel-invitation':
          setSelectedUser(user);
          cancelInvitation.open(
            <Alert
              visible
              title="Are you sure you want to cancel this invitation?"
              onRequestClose={handleCancelInvitationClose}
              body=""
              preferredActionLabel="Yes, cancel"
              preferredActionVariant="negative"
              onPreferredActionPress={() => handleCancelInvitation(user)}
              dismissActionLabel="Nevermind"
              onDismissActionPress={handleCancelInvitationClose}
            />,
          );
          break;
        default:
          throw new Error(`Unexpected Action: ${event}`);
      }
    },
    [
      resendInvitation,
      cancelInvitation,
      toggleEditModalOn,
      toggleRemoveModalOn,
      toggleChangeOwnerModalOn,
      handleReinvitation,
      handleCancelInvitation,
    ],
  );

  return (
    <>
      {inviteModalVisible && (
        <InviteMemberModal
          visible
          existingUsers={existingUsers}
          orgId={activeOrg?.organizationId || ''}
          onRequestClose={toggleInviteModalOff}
        />
      )}
      {editMemberModalVisible && !!selectedUser && (
        <EditMemberModal
          visible
          user={selectedUser as OrganizationActiveUser}
          orgId={activeOrg?.organizationId || ''}
          onRequestClose={toggleEditModalOff}
        />
      )}
      {removeMemberModalVisible && !!selectedUser && (
        <RemoveMemberModal
          visible
          user={selectedUser as OrganizationActiveUser}
          orgId={activeOrg?.organizationId || ''}
          onRequestClose={toggleRemoveModalOff}
        />
      )}
      {changeOwnerModalVisible && !!selectedUser && (
        <ChangeOwnerModal
          visible
          currentOwner={self}
          user={selectedUser as OrganizationActiveUser}
          orgId={activeOrg?.organizationId || ''}
          activeUsers={activeUsers}
          onRequestClose={toggleChangeOwnerModalOff}
        />
      )}
      {self?.exchangeEnabled && orgHasMultipleUsers && <ExchangeUsersBanner />}
      {isPhone ? (
        <MobileAccessTableWrapper
          toggleInviteModalOn={toggleInviteModalOn}
          handleMemberAction={handleMemberAction}
        />
      ) : (
        <AccessTableWrapper
          toggleInviteModalOn={toggleInviteModalOn}
          handleMemberAction={handleMemberAction}
        />
      )}
    </>
  );
}
