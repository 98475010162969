import { useCallback, useMemo } from 'react';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Button } from '@cbhq/cds-web/buttons';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { Pressable } from '@cbhq/cds-web/system';
import { TextBody, TextTitle4 } from '@cbhq/cds-web/typography';

import { ReactComponent as DiscordLogo } from ':cloud/assets/Products/DiscordLogo.svg';
import { useAppState } from ':cloud/contexts/AppStateContext';
import useLocalStorage from ':cloud/hooks/useLocalStorage';
import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import useViewportDimensions from ':cloud/hooks/useViewportDimensions';
import { CLOUD_DISCORD } from ':cloud/utils/links';
import { onExternalLinkPress } from ':cloud/utils/onExternalLinkPress';
import { AppRoute } from ':cloud/utils/routes';

const dangerousBackgroundColor = '#080F33';

export function DiscordBanner() {
  const { isDarkMode } = useAppState();
  const { isPhone } = useSimpleBreakpoints();
  const location = useLocation();

  const [hasClosedDiscordBanner, setHasClosedDiscordBanner] = useLocalStorage(
    'has-closed-discord-banner',
    false,
  );
  const handleClosePress = useCallback(() => {
    setHasClosedDiscordBanner(true);
  }, [setHasClosedDiscordBanner]);

  const { width } = useViewportDimensions();
  const joinButtonText = useMemo(() => {
    if (!width || width <= 1260) {
      return 'Join';
    }

    return 'Join us on Discord';
  }, [width]);

  const textColor = useMemo(() => (isDarkMode ? 'foreground' : 'secondary'), [isDarkMode]);

  if (isPhone) {
    return null;
  }

  // Conditional rendering based on path
  const allowedPaths: string[] = [
    AppRoute.Settings.Project,
    // AppRoute.Team.Home,
    AppRoute.Access.API,
    AppRoute.Projects.Overview,
  ];
  const currentPath = location.pathname;

  const isPathAllowed = allowedPaths.some((pathPattern) => {
    const match = matchPath(currentPath, { path: pathPattern, exact: true });
    return match !== null;
  });

  if (hasClosedDiscordBanner || !isPathAllowed) {
    return null;
  }

  return (
    <Box
      dangerouslySetBackground={dangerousBackgroundColor}
      spacing={4}
      position="relative"
      spacingHorizontal={10}
      width="100%"
    >
      <Box position="absolute" spacing={2} top={0} right={0}>
        <Pressable background="transparent" onPress={handleClosePress} noScaleOnPress>
          <Icon name="close" size="s" color={textColor} />
        </Pressable>
      </Box>
      <VStack alignItems="flex-end" width="100%">
        <HStack width="100%" justifyContent="space-between" spacingEnd={6} alignItems="center">
          <HStack gap={10} alignItems="center">
            <DiscordLogo height={44} />
            <VStack>
              <TextTitle4 as="h4" color={textColor}>
                Join our Discord community
              </TextTitle4>
              <TextBody as="p" color={textColor}>
                Get help with your project, meet other developers, and get access to the web3
                community.
              </TextBody>
            </VStack>
          </HStack>
          <Button
            variant="primary"
            endIcon="diagonalUpArrow"
            onPress={onExternalLinkPress(CLOUD_DISCORD)}
          >
            {joinButtonText}
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
}
