import { Grid, GridProps } from '@cbhq/cds-web/layout';

import { FULL_PAGE_MAX_WIDTH } from ':cloud/widgets/product/constants';

import { ContentWrapper, ContentWrapperProps } from './ContentWrapper';
import { MainContainer, MainContainerProps } from './MainContainer';
import { SidebarContainer, SidebarContainerProps } from './SidebarContainer';

type LayoutProps = GridProps & {
  className?: string;
  enableMaxWidth?: boolean;
};

// <Layout /> is a wrapper to sub-layouts <Layout.MainContainer /> and <Layout.SidebarContainer />
export function Layout({ className = '', children, enableMaxWidth = true }: LayoutProps) {
  return (
    <Grid
      columns={12}
      maxWidth={enableMaxWidth ? FULL_PAGE_MAX_WIDTH : undefined}
      className={className}
      width="100%"
    >
      {children}
    </Grid>
  );
}

Layout.SidebarContainer = SidebarContainer as (props: SidebarContainerProps) => JSX.Element;
Layout.MainContainer = MainContainer as (props: MainContainerProps) => JSX.Element;
Layout.ContentWrapper = ContentWrapper as (props: ContentWrapperProps) => JSX.Element;
