import { HStack, VStack } from '@cbhq/cds-web/layout';
import { TextTitle1 } from '@cbhq/cds-web/typography';

import { useGetProtocolDisplayName } from ':cloud/queries/WorkflowQueries/useGetProtocolDisplayName';
import { ICluster, ProtocolKey } from ':cloud/types/ts_types';
import { ClusterList } from ':cloud/widgets/clusters/ClusterList';
import { ProtocolIcon } from ':cloud/widgets/sharedcomponents';

interface ProtocolSectionProps {
  clusterList: ICluster[];
  protocolKey: ProtocolKey;
  hasQuery: boolean;
}

export function ProtocolSection({ protocolKey, clusterList, hasQuery }: ProtocolSectionProps) {
  const { displayName } = useGetProtocolDisplayName(protocolKey);

  return (
    <>
      <VStack gap={5} spacingBottom={5}>
        <HStack gap={3}>
          <ProtocolIcon protocolKey={protocolKey} size={32} />
          <TextTitle1 as="h2">{displayName}</TextTitle1>
        </HStack>
      </VStack>
      {clusterList.length > 0 && <ClusterList list={clusterList} hasQuery={hasQuery} />}
    </>
  );
}
