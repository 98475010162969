import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Grid, VStack } from '@cbhq/cds-web/layout';
import { Link, TextBody } from '@cbhq/cds-web/typography';

import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { ICluster } from ':cloud/types/ts_types';
import { getAdapter, statuses } from ':cloud/utils/clusters';
import { threeColumnGrid } from ':cloud/utils/responsiveConfigs';
import { ClusterCard } from ':cloud/widgets/clusters/ClusterCard';
import { CollapseLink } from ':cloud/widgets/sharedcomponents';

function sortClusters(c1: ICluster, c2: ICluster): number {
  const includesFailing = (cluster: ICluster): number =>
    cluster.state.includes(
      statuses.CREATE_FAILED ||
        statuses.DELETE_FAILED ||
        statuses.UPDATE_FAILED ||
        statuses.BEING_DELETED,
    )
      ? -1
      : 1;
  return (
    includesFailing(c1) - includesFailing(c2) ||
    new Date(c1.created).getTime() - new Date(c2.created).getTime()
  );
}

interface ClusterListProps {
  list: ICluster[];
  hasQuery: boolean;
}

export function ClusterList({ list, hasQuery }: ClusterListProps) {
  const adapter = getAdapter();
  const { isTablet } = useSimpleBreakpoints();
  const DEFAULT_COLLAPSED_CARDS = isTablet ? 4 : 3;
  const [visibleSize, setVisibleSize] = useState(DEFAULT_COLLAPSED_CARDS);
  const isCollapsed = visibleSize === DEFAULT_COLLAPSED_CARDS;

  useEffect(() => {
    setVisibleSize(hasQuery ? list.length : DEFAULT_COLLAPSED_CARDS);
  }, [hasQuery, list.length, isTablet, DEFAULT_COLLAPSED_CARDS]);

  return (
    <>
      <Grid gap={4} spacingBottom={4} responsiveConfig={threeColumnGrid}>
        {list
          .sort(sortClusters)
          .slice(0, visibleSize)
          .map((cluster) => (
            <VStack key={cluster.id}>
              {cluster.state === statuses.BEING_DELETED ? (
                <ClusterCard key={cluster.id} cluster={cluster} />
              ) : (
                <Link
                  renderContainer={(props): JSX.Element => (
                    <RouterLink
                      {...props}
                      to={`${adapter.clusterRoute}/${cluster.type}/${cluster.name}`}
                    />
                  )}
                >
                  <ClusterCard key={cluster.id} cluster={cluster} />
                </Link>
              )}
            </VStack>
          ))}
      </Grid>
      {list.length > DEFAULT_COLLAPSED_CARDS && (
        <Box display="block">
          <CollapseLink
            isCollapsed={isCollapsed}
            onPress={(): void =>
              isCollapsed ? setVisibleSize(list.length) : setVisibleSize(DEFAULT_COLLAPSED_CARDS)
            }
          >
            <TextBody as="span">
              {isCollapsed ? `Show all clusters (${list.length})` : 'Collapse list'}
            </TextBody>
          </CollapseLink>
        </Box>
      )}
    </>
  );
}
