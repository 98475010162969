import { EarningsChart } from ':cloud/widgets/earnings/types';

export const defaultEarningsCharts = {
  title: 'Rewards Summary',
  range: 'currentMonth',
};

export const RANGE: Record<string, string> = {
  currentMonth: 'Current month',
  previousMonth: 'Previous month',
  '60Days': '60 days',
};
export const earningsCharts: { charts: EarningsChart[] } = {
  charts: [
    {
      title: 'Total rewards',
      key: 'TotalRewardsChart',
      chartType: 'area',
    },
  ],
};
