import { Box } from '@cbhq/cds-web/layout';
import { TextTitle3 } from '@cbhq/cds-web/typography';

type TotalOverlayProps = { value: string };

export function TotalOverlay({ value }: TotalOverlayProps) {
  return (
    <Box position="relative" display="block" top="-80%" left="-49%">
      <TextTitle3 as="h3" align="center">
        {value}
      </TextTitle3>
    </Box>
  );
}
