import { useQuery } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { GetPlatformAPIKeyResponse } from ':cloud/types/service_proto';
import { IPlatformAPIKey } from ':cloud/types/ts_types';

async function fetchKeys(organizationId: string): Promise<IPlatformAPIKey[]> {
  const path = `/apikeys/v1/organizations/${organizationId}/apiKeys`;
  const { data } = await APIClientBase.get<GetPlatformAPIKeyResponse>(path);
  return data?.apiKeys;
}

export function useGetApiKeys(organizationId = ''): {
  isLoading: boolean;
  keys: IPlatformAPIKey[];
} {
  const { isLoading, data } = useQuery({
    queryKey: [QueryKey.apiKeys, organizationId],
    queryFn: async () => fetchKeys(organizationId),
    staleTime: Infinity,
    meta: { retry: 1 },
    enabled: !!organizationId,
  });

  return {
    isLoading,
    keys: data || [],
  };
}
