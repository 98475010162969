/* eslint @cbhq/ts-singular-type-name:0 */

enum Access {
  Home = '/access',
  API = '/access/api',
  OAuth = '/access/oauth',
  OAuthCreate = '/access/oauth-create', // TODO: Figure out why /access/oauth/create is not working.
  OAuthEdit = '/access/oauth-edit',
  LegacyAPI = '/access/legacy-api',
  Permissions = '/access/permissions',
  Teams = '/access/teams',
  CurrentTab = '/access/:currentTab',
}

enum Earnings {
  Home = '/earnings',
}

enum Billing {
  Home = '/billing',
  CurrentTab = '/billing/:currentTab',
  Details = '/billing/details',
  Usage = '/billing/usage',
}

enum Team {
  Home = '/team',
}

enum Participate {
  Home = '/participate',
  Create = '/participate/new/:protocolKey',
  Review = '/participate/new/:protocolKey/review',
  Details = '/participate/:protocolKey/details',
  DetailsByClusterName = '/participate/:protocolKey/:clusterName',
  DetailsByClusterNameAndTabName = '/participate/:protocolKey/:clusterName/:tabName',
}

enum Products {
  Home = '/products',
  Details = '/products/:productName',
  Base = '/products/node',
  Faucet = '/products/faucet',
  Boat = '/products/onchainkit',
  Waas = '/products/waas',
  Onramp = '/products/onramp',
  WaasConsumer = '/products/wallets/embedded-wallets', // Behind feature flag.
  Wallets = '/products/wallets',
  Indexers = '/products/onchain-data',
  Staking = '/products/staking',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Participate = '/products/participate',
  AdvancedTrade = '/products/advanced-trade',
  BundlerAndPaymaster = '/products/bundler-and-paymaster',
  PlatformWallets = '/products/platform-wallets',
  Exchange = '/products/exchange',
  Webhooks = '/products/webhooks',
  Verifications = '/products/verifications',
  App = '/products/coinbase-app',
}

enum Projects {
  Home = '/projects',
  Details = '/projects/:projectId',
  DetailsTab = '/projects/:projectId/:currentTab',
  Overview = '/projects/:projectId/overview',
}

enum Settings {
  Home = '/settings',
  CurrentTab = '/settings/:currentTab',
  Account = '/settings/account',
  Organization = '/settings/organization',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Billing = '/settings/billing',
  Project = '/project-settings',
}

enum Legal {
  BaseNodeTOS = '/paymaster-bundler-terms-of-service',
}

const Home = '/';

const CookiePreferences = '/cookie-preferences';

export const AppRoute = {
  Home,
  Participate,
  Team,
  Billing,
  Projects,
  Settings,
  Earnings,
  CookiePreferences,
  Access,
  Products,
  Legal,
};

export function componentMappingTab(tabKey, componentName): string {
  switch (tabKey) {
    case 'manage':
      return 'manage';
    case 'metrics':
    default:
      if (componentName === 'ClusterVisualDetails') {
        return 'metrics';
      }
      return 'generateMetrics';
  }
}

export function useQueryParam(key: string): string | null {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
}
