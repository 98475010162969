import { useCallback, useContext } from 'react';
import { exposeExperiment, SubjectType } from '@cbhq/client-analytics';
import { ExperimentProvider as CBExperimentProvider } from '@cbhq/experiments';

import { useGetExperiments } from ':cloud/queries/ExperimentsQueries/useGetExperiments';
import { GenericProviderProps } from ':cloud/types/ts_types';
import { SessionContext } from ':cloud/utils/SessionProvider';
import { FullAppLoadingSpinner } from ':cloud/widgets/sharedcomponents/FullAppLoadingSpinner';

export function ExperimentsProvider({ children }: GenericProviderProps) {
  const { experiments, isLoading } = useGetExperiments();
  const { sessionResponse } = useContext(SessionContext);

  const trackExposure = useCallback(
    (testName: string, group: string) => {
      if (!sessionResponse?.email) {
        return;
      }

      exposeExperiment({
        subjectId: sessionResponse.email,
        subjectType: SubjectType.device,
        group,
        testName,
      });
    },
    [sessionResponse?.email],
  );

  return (
    <CBExperimentProvider experiments={experiments} trackExposure={trackExposure}>
      {isLoading ? <FullAppLoadingSpinner /> : children}
    </CBExperimentProvider>
  );
}
