import { useMemo } from 'react';
import { Banner } from '@cbhq/cds-web/banner/Banner';
import { Button, ButtonGroup } from '@cbhq/cds-web/buttons';
import { ContainedAssetCard } from '@cbhq/cds-web/cards/ContainedAssetCard';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Box, Grid, HStack, VStack } from '@cbhq/cds-web/layout';
import {
  TextBody,
  TextDisplay3,
  TextHeadline,
  TextLabel2,
  TextTitle3,
  TextTitle4,
} from '@cbhq/cds-web/typography';

import { ReactComponent as EthStaking } from ':cloud/assets/Products/EthStaking.svg';
import { ReactComponent as EthStakingDarkMode } from ':cloud/assets/Products/EthStakingDarkMode.svg';
import { ReactComponent as EthereumImg } from ':cloud/assets/Protocols/Logos/ethereum.svg';
import { ReactComponent as MoreNetworksImg } from ':cloud/assets/Staking/MoreNetworks.svg';
import { ReactComponent as SolanaImg } from ':cloud/assets/Staking/Solana.svg';
import { useAppState } from ':cloud/contexts/AppStateContext';
import { STAKING_CASE_STUDY, STAKING_DISCORD_LINK, STAKING_DOCS_LINK } from ':cloud/utils/links';
import { onExternalLinkPress } from ':cloud/utils/onExternalLinkPress';
import { Layout } from ':cloud/widgets/layout';
import { FULL_PAGE_MAX_WIDTH, MAIN_CONTAINER_MIN_WIDTH } from ':cloud/widgets/product/constants';
import { KeyFeatureItem, KeyFeaturesSection } from ':cloud/widgets/product/KeyFeaturesSection';

const keyFeatures: KeyFeatureItem[] = [
  {
    iconName: 'layerNetworks',
    title: 'Multi-network simplicity',
    description:
      'Simplify staking operations across multiple networks using a single integration for orchestration, rewards, and more.',
    iconType: 'pictogram',
  },
  {
    iconName: 'transferSend',
    title: 'Rich rewards data',
    description:
      'Access to historical and pending rewards data across multiple networks and addresses.',
    iconType: 'pictogram',
  },
  {
    iconName: 'portfolioPerformance',
    title: 'Insightful reporting',
    description:
      'Easily understand and visualize reporting and insights with our companion dashboard.',
    iconType: 'heroSquare',
  },
  {
    iconName: 'cryptoAssets',
    title: 'Testnet availability',
    description:
      'Testnet support for all available protocols makes tackling new integrations a straightforward process.',
    iconType: 'spotSquare',
  },
];

function ProtocolAvailabilitySection() {
  return (
    <VStack>
      <TextBody as="p" color="foregroundMuted">
        We enable developers to stake, unstake and view rewards data across all our networks through
        a single API endpoint. To learn more about our upcoming networks, reach out on Discord.
      </TextBody>
      <HStack gap={3} spacingTop={4} spacingBottom={7} flexWrap="wrap" width="100%">
        <Box
          spacing={2}
          width={327}
          height={156}
          borderRadius="roundedLarge"
          background="backgroundAlternate"
        >
          <VStack justifyContent="space-between">
            <EthereumImg height={32} width={32} />
            <VStack>
              <TextHeadline as="p">Ethereum</TextHeadline>
              <TextLabel2 color="foregroundMuted" as="p">
                Shared and Dedicated Validators
              </TextLabel2>
            </VStack>
          </VStack>
        </Box>
        <ContainedAssetCard
          title="Solana"
          subtitle="Free"
          header={<SolanaImg height={32} width={32} />}
        />
        <ContainedAssetCard
          title="More networks"
          description="Coming soon"
          header={<div />}
          size="l"
        >
          <MoreNetworksImg />
        </ContainedAssetCard>
      </HStack>
    </VStack>
  );
}

export function StakingAPILanding() {
  const { isDarkMode } = useAppState();

  const { isDesktop } = useBreakpoints();
  const responsiveTemplateColumns = useMemo(() => {
    return isDesktop ? '4fr 3fr' : '1fr';
  }, [isDesktop]);

  return (
    <Layout enableMaxWidth={false}>
      <Layout.MainContainer minWidth={MAIN_CONTAINER_MIN_WIDTH} spacingHorizontal={0} fullWidth>
        <VStack>
          <Box spacingHorizontal={8} maxWidth={FULL_PAGE_MAX_WIDTH} display="block">
            <Banner
              variant="promotional"
              startIcon="info"
              title="Try Dedicated ETH Staking for free"
            >
              We&apos;re offering 0% commission fees for Dedicated ETH Staking (32 ETH minimum) from
              August 8th 2024 until September 6th, 2024. We will charge a monthly 8% reward
              commission fee after September 6th.
            </Banner>
          </Box>
          <Grid
            maxWidth={FULL_PAGE_MAX_WIDTH}
            // templateColumns={responsiveTemplateColumns} // not used in header without pricing card
            gap={9}
            spacing={10}
            borderedBottom
          >
            <VStack justifyContent="center">
              <TextDisplay3 as="h1">Enable your users to earn rewards</TextDisplay3>
              <TextTitle4 as="h2" color="foregroundMuted" spacingTop={1} spacingBottom={7}>
                An end-to-end solution for developers to enable staking rewards for their users
                across multiple networks.
              </TextTitle4>

              <ButtonGroup>
                <Button
                  variant="primary"
                  endIcon="diagonalUpArrow"
                  onPress={onExternalLinkPress(STAKING_DOCS_LINK)}
                  accessibilityLabel="View docs + opens in new window"
                >
                  View docs
                </Button>
                <Button
                  variant="secondary"
                  endIcon="diagonalUpArrow"
                  onPress={onExternalLinkPress(STAKING_DISCORD_LINK)}
                  accessibilityLabel="Discord channel + opens in new window"
                >
                  Discord channel
                </Button>
              </ButtonGroup>
            </VStack>
            {/* To be added back in when pricing is confirmed */}
            {/* <StakingPricingCard /> */}
          </Grid>
          <VStack borderedBottom>
            <VStack maxWidth={FULL_PAGE_MAX_WIDTH} spacing={10} gap={5} flexGrow={1} width="100%">
              <HStack gap={2}>
                <TextTitle3 as="h1">Network Availability</TextTitle3>
              </HStack>
              <ProtocolAvailabilitySection />
            </VStack>
          </VStack>
          <KeyFeaturesSection features={keyFeatures} />
          <VStack borderedTop spacing={10}>
            <VStack maxWidth={FULL_PAGE_MAX_WIDTH} flexGrow={1} width="80%">
              <Grid gap={4} width="100%" templateColumns={responsiveTemplateColumns}>
                {isDarkMode ? <EthStakingDarkMode /> : <EthStaking />}
                <VStack gap={1} alignItems="flex-start">
                  <TextTitle3 as="h1">See how Coinbase Wallet is using the Staking API</TextTitle3>
                  <TextBody as="p" color="foregroundMuted" spacingBottom={2}>
                    Explore how Coinbase Wallet integrated with the Staking API and makes staking
                    accessible to users holding less than 32 ETH in their wallet.
                  </TextBody>
                  <Button
                    variant="secondary"
                    endIcon="diagonalUpArrow"
                    onPress={onExternalLinkPress(STAKING_CASE_STUDY)}
                    accessibilityLabel="Read more + opens in new window"
                  >
                    Learn more
                  </Button>
                </VStack>
              </Grid>
            </VStack>
          </VStack>
        </VStack>
      </Layout.MainContainer>
    </Layout>
  );
}
