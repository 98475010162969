import { useCallback, useState } from 'react';
import { noop } from '@cbhq/cds-utils';
import { Banner } from '@cbhq/cds-web/banner/Banner';
import { Button } from '@cbhq/cds-web/buttons';
import { TextInput } from '@cbhq/cds-web/controls';
import { VStack } from '@cbhq/cds-web/layout';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from '@cbhq/cds-web/overlays';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { TextBody, TextLabel1 } from '@cbhq/cds-web/typography';

import { useDeleteApiKey } from ':cloud/queries/ApiKeyQueries/useDeleteApiKey';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { NULL_COINBASE_ID } from ':cloud/widgets/access/constants';
import { TextWithCopyIcon } from ':cloud/widgets/sharedcomponents/TextWithCopyIcon';

type APIKeyDeleteModalProps = Partial<ModalProps> & {
  onSuccess?: () => void;
  keyId?: string;
};

export function APIKeyDeleteModal(props: APIKeyDeleteModalProps): JSX.Element {
  const { onRequestClose, onSuccess, keyId } = props;
  const toast = useToast();
  const { activeOrg } = useGetUser();
  const deleteKeyMutation = useDeleteApiKey(activeOrg?.organizationId, onSuccess);
  const [selectedKey, setSelectedKey] = useState(NULL_COINBASE_ID);

  const handleSubmit = useCallback(async () => {
    if (selectedKey) {
      deleteKeyMutation.mutate(selectedKey);
      onRequestClose?.();
    } else if (!selectedKey || selectedKey === NULL_COINBASE_ID) {
      toast.show('Oops, something went wrong. No key has been selected.', {
        variant: 'negative',
      });
    }
  }, [deleteKeyMutation, onRequestClose, selectedKey, toast]);

  const handleInputChange = useCallback((event) => {
    setSelectedKey(event.target.value);
  }, []);

  if (!keyId) {
    throw new Error('Key ID not available');
  }

  return (
    <Modal onRequestClose={onRequestClose || noop} visible {...props}>
      <ModalHeader title="Delete API key" />
      <ModalBody>
        <VStack gap={5}>
          <TextBody as="p">Copy and paste the ID associated with the key.</TextBody>
          <VStack gap={1}>
            <TextLabel1 as="p">API key id</TextLabel1>
            <TextWithCopyIcon text={keyId} />
          </VStack>
          <VStack>
            <TextInput
              label="API key id"
              placeholder={NULL_COINBASE_ID}
              onChange={handleInputChange}
            />
          </VStack>
          <Banner title="Warning" variant="warning" startIcon="warning">
            This key will be permanently and irreversibly invalidated, and will stop working
            immediately after it is revoked.
          </Banner>
        </VStack>
      </ModalBody>
      <ModalFooter
        primaryAction={
          <Button
            type="submit"
            variant="negative"
            onPress={handleSubmit}
            testID="submit"
            disabled={keyId !== selectedKey}
          >
            Delete key
          </Button>
        }
        secondaryAction={
          <Button variant="secondary" onPress={onRequestClose}>
            Cancel
          </Button>
        }
      />
    </Modal>
  );
}
