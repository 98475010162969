import { useMemo } from 'react';
import _includes from 'lodash/includes';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { spacing } from '@cbhq/cds-web/tokens';
import { TextHeadline } from '@cbhq/cds-web/typography';

import config from ':cloud/config';

import { ICloudProvider } from '../types';

import { SelectWidget } from './SelectWidget';

interface RegionSelectWidgetProps {
  provider: ICloudProvider;
  exclude?: string[];
  selected: boolean;
  required: boolean;
  disabled: boolean;
  error: boolean;
  value?: any;
}

export function RegionSelectWidget({
  provider,
  exclude = [],
  selected,
  required,
  disabled,
  value,
  error,
  ...inputProps
}: RegionSelectWidgetProps) {
  const selectOptions = useMemo(() => {
    const enumOptions = provider.regions.filter((region) => !_includes(exclude, region.value));
    return { enumOptions };
  }, [exclude, provider.regions]);

  return (
    <VStack
      justifyContent="space-between"
      position="relative"
      bordered
      borderRadius="rounded"
      spacing={5}
      width="100%"
      height="100%"
      borderColor={selected ? 'primary' : 'lineHeavy'}
    >
      {selected && (
        <Box display="inline-block" position="absolute" top={spacing[2]} right={spacing[2]}>
          <Icon name="checkmark" size="m" />
        </Box>
      )}
      <Box width={40} height={40} display="flex" alignItems="flex-end">
        <img width={40} src={`${config.assets}/${provider.icon}`} alt={provider.name} />
      </Box>
      <TextHeadline as="p" spacingVertical={5}>
        {provider.name}
      </TextHeadline>
      <SelectWidget
        selected={selected}
        value={value}
        error={error}
        required={false}
        disabled={false}
        options={selectOptions}
        label="Region"
        placeholder="Select one"
        fullWidth
        variant={error ? 'negative' : 'foregroundMuted'}
        {...inputProps}
      />
    </VStack>
  );
}
