import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ButtonGroup } from '@cbhq/cds-web/buttons';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Box, Grid, HStack, VStack } from '@cbhq/cds-web/layout';
import { SectionHeader } from '@cbhq/cds-web/section-header/SectionHeader';
import { TabNavigation } from '@cbhq/cds-web/tabs/TabNavigation';
import { Link, TextBody, TextDisplay2, TextTitle4 } from '@cbhq/cds-web/typography';

import { ReactComponent as PlatformWalletsHeroImg } from ':cloud/assets/Products/PlatformWallets.svg';
import { useAppState } from ':cloud/contexts/AppStateContext';
import { EventName, useLogViewOnMount } from ':cloud/init/clientAnalytics/logging';
import { CodeBlockLanguage } from ':cloud/types/ts_types';
import {
  COINBASE_PLATFORM_APIS_DISCORD_LINK,
  COINBASE_PLATFORM_APIS_DOCS_LINK,
} from ':cloud/utils/links';
import { openUrlInNewWindow } from ':cloud/utils/openUrlInNewWindow';
import { AppRoute } from ':cloud/utils/routes';
import { Layout } from ':cloud/widgets/layout';
import { FULL_PAGE_MAX_WIDTH, MAIN_CONTAINER_MIN_WIDTH } from ':cloud/widgets/product/constants';
import { KeyFeatureItem, KeyFeaturesSection } from ':cloud/widgets/product/KeyFeaturesSection';
import { QuickstartItem } from ':cloud/widgets/product/QuickstartItem';
import { CodeBlock } from ':cloud/widgets/sharedcomponents';

const keyFeatures: KeyFeatureItem[] = [
  {
    iconName: 'wallet',
    title: 'Use developer controlled wallets',
    description:
      'Create and manage wallets for your users while customizing the experience to fit your app.',
  },
  {
    iconName: 'coinFocus',
    title: 'Focus on your app, not the blockchain',
    description:
      'Our SDK handles all the complexities of crypto so you can focus on building your app.',
  },
  {
    iconName: 'barChart',
    title: 'Send and receive crypto',
    description: 'Get started with simple APIs to send and receive crypto within seconds.',
  },
];

const bashCommands = {
  ruby: `gem install coinbase-sdk`,
  typescript: `npm install @coinbase/coinbase-sdk`,
};

const quickStartCodes = {
  ruby: `require 'coinbase'
      
# Paste in your API key name and private key generated from https://portal.cdp.coinbase.com/access/api below:
Coinbase.configure do |config|
  config.api_key_name = 'organizations/your-org-id/apiKeys/your-api-key-id'
  # Make sure to use double quotes for the private key.
  config.api_key_private_key = "-----BEGIN EC PRIVATE KEY-----\\nyour-api-key-private-key\\n-----END"
end

# Obtain the default user for the API key.
u = Coinbase.default_user

# Create your first wallet.
w1 = u.create_wallet

# Fund your wallet using a faucet.
w1.faucet`,
  typescript: `import { Coinbase } from "@coinbase/coinbase-sdk";
    
// Paste in your API key name and private key generated from https://portal.cdp.coinbase.com/access/api below:
const apiKeyName = "organizations/your-org-id/apiKeys/your-api-key-id";

const apiKeyPrivateKey = "-----BEGIN EC PRIVATE KEY-----\\nyour-api-key-private-key\\n-----END";

const coinbase = new Coinbase(apiKeyName, apiKeyPrivateKey);

// Obtain the default user for the API key.
const user = await coinbase.getDefaultUser();

// Create your first wallet.
const wallet = await user.createWallet();

// Fund your wallet using a faucet.
await wallet.faucet();`,
};

const codeBackgroundColor = 'var(--foreground)';
const codeStyle = {
  padding: '25px 10px 25px',
  background: codeBackgroundColor,
  overflow: 'clip',
};

export function PlatformWallets() {
  const [selectedTab, setTab] = useState('typescript');
  const history = useHistory();
  const title = "Leverage Coinbase's MPC technology to create secure wallets";
  const description = `
    A server-side SDK that provides simple APIs to create and use crypto wallets.
    Currently available in Ruby and Node.js.
  `;

  const bashCode = useMemo(() => {
    return [
      {
        language: 'bash' as CodeBlockLanguage,
        code: bashCommands[selectedTab],
      },
    ];
  }, [selectedTab]);

  const quickStartCode = useMemo(() => {
    return [
      {
        language: selectedTab as CodeBlockLanguage,
        code: quickStartCodes[selectedTab],
      },
    ];
  }, [selectedTab]);

  const handleAPIKeyPress = useCallback(() => {
    history.push(AppRoute.Access.API);
  }, [history]);

  const quickstartDescription = (
    <TextBody as="p" color="foregroundMuted" numberOfLines={2}>
      Ensure that you have already provisioned a{' '}
      <Link onPress={handleAPIKeyPress}>CDP API key</Link>.
    </TextBody>
  );

  const { isDesktop } = useBreakpoints();
  const responsiveTemplateHeaderColumns = useMemo(() => {
    return isDesktop ? '1fr 1fr' : '1fr';
  }, [isDesktop]);

  const { selectedProject } = useAppState();
  useLogViewOnMount(EventName.platform_api_page_view);

  const tabs = useMemo(
    () => [
      { id: 'typescript', label: 'TypeScript' },
      { id: 'ruby', label: 'Ruby' },
    ],
    [],
  );

  return (
    <Layout enableMaxWidth={false}>
      <Layout.MainContainer minWidth={MAIN_CONTAINER_MIN_WIDTH} spacingHorizontal={0} fullWidth>
        <Box display="block" width="100%">
          <VStack>
            <HStack borderedBottom>
              <HStack maxWidth={FULL_PAGE_MAX_WIDTH}>
                <Grid
                  maxWidth={FULL_PAGE_MAX_WIDTH}
                  gap={9}
                  spacing={9}
                  spacingVertical={0}
                  templateColumns={responsiveTemplateHeaderColumns}
                >
                  <VStack justifyContent="center">
                    <TextDisplay2 as="h1">{title}</TextDisplay2>
                    <TextTitle4 as="h2" color="foregroundMuted" spacingTop={5} spacingBottom={7}>
                      {description}
                    </TextTitle4>

                    <ButtonGroup>
                      <Button
                        variant="primary"
                        endIcon="diagonalUpArrow"
                        onPress={() => {
                          openUrlInNewWindow(COINBASE_PLATFORM_APIS_DOCS_LINK);
                        }}
                        accessibilityLabel="Open CDP API docs in new window"
                      >
                        Get started
                      </Button>
                      <Button
                        variant="secondary"
                        endIcon="diagonalUpArrow"
                        onPress={() => {
                          openUrlInNewWindow(COINBASE_PLATFORM_APIS_DISCORD_LINK);
                        }}
                        accessibilityLabel="Open CDP API Discord channel"
                      >
                        Discord channel
                      </Button>
                    </ButtonGroup>
                  </VStack>
                  <PlatformWalletsHeroImg />
                </Grid>
              </HStack>
            </HStack>
            {selectedProject && (
              <VStack maxWidth={FULL_PAGE_MAX_WIDTH} spacing={7} gap={3}>
                <SectionHeader title="Quickstart" description={quickstartDescription} />
                <VStack spacingStart={4}>
                  <TabNavigation value={selectedTab} tabs={tabs} onChange={setTab} />
                </VStack>
                <VStack spacing={4} gap={6}>
                  <QuickstartItem
                    stepNumber={1}
                    title="Install the SDK"
                    codeBlock={
                      <CodeBlock
                        codeArr={bashCode}
                        showLanguageSelect={false}
                        showCopy
                        customStyle={codeStyle}
                        headerBackground={codeBackgroundColor}
                        enableLineWrap
                        title="shell"
                      />
                    }
                  />
                  <QuickstartItem
                    stepNumber={2}
                    title="Configure the SDK to create and fund a wallet"
                    codeBlock={
                      <CodeBlock
                        codeArr={quickStartCode}
                        showLanguageSelect={false}
                        showCopy
                        customStyle={codeStyle}
                        headerBackground={codeBackgroundColor}
                        enableLineWrap
                        title="Ruby"
                      />
                    }
                  />
                </VStack>
              </VStack>
            )}
            <KeyFeaturesSection features={keyFeatures} />
          </VStack>
        </Box>
      </Layout.MainContainer>
    </Layout>
  );
}
