import { useQuery } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientRM } from ':cloud/state/Api';
import { IMetricsRoot, ProtocolKey } from ':cloud/types/ts_types';

type MetricsRequest = {
  short?: boolean;
  range?: '6h' | '24h' | '168h' | '336h';
  count?: number;
  exactVersion?: boolean;
};

async function fetchResourceMetrics(
  protocol: ProtocolKey,
  clustername: string,
  params?: MetricsRequest,
): Promise<IMetricsRoot> {
  // TODO: replace with resourceId
  const range = params?.range ? `&range=${params.range}` : '';
  const count = params?.count ? `&count=${params.count}` : '';
  const exactVersion = params?.exactVersion ? `&exactVersion=${params.exactVersion}` : '';
  const path = `/v1/resources/${encodeURIComponent(protocol)}/${encodeURIComponent(
    clustername,
  )}/metrics?short=${params?.short ?? false}${range}${count}${exactVersion}`;
  const { data } = await APIClientRM.get<IMetricsRoot>(path);
  return data;
}

const defaultMetrics = { metrics: [], data: {}, keys: {} };
export function useGetResourceMetrics(
  protocol: string,
  clustername: string,
  isRunning: boolean,
  params?: MetricsRequest,
): {
  isLoading: boolean;
  metrics: IMetricsRoot;
} {
  const { isLoading, data } = useQuery<IMetricsRoot>({
    queryKey: [QueryKey.resources, 'metrics', protocol, clustername, params],
    queryFn: async () => fetchResourceMetrics(protocol, clustername, params),
    retry: 1,
    /* metrics are not fetched if cluster is not running (ClusterStateType) */
    enabled: !!protocol && !!clustername && isRunning,
  });

  return {
    isLoading,
    metrics: data || defaultMetrics,
  };
}
