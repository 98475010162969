import { Framework, Region, TrackerType, TrackingCategory } from '@coinbase/cookie-manager';

export const config = {
  categories: [
    {
      id: TrackingCategory.NECESSARY,
      required: true,
      expiry: 365,
      trackers: [
        {
          id: '__stripe_sid',
          description: 'Fraud prevention',
          type: TrackerType.COOKIE,
        },
        {
          id: '__stripe_mid',
          description: 'Fraud prevention',
          type: TrackerType.COOKIE,
        },
        {
          id: 'unified-session-manager-cookie',
          description: 'Used for authentication',
          type: TrackerType.COOKIE,
        },
        {
          id: 'session-manager-cookie',
          description: 'Used for authentication',
          type: TrackerType.COOKIE,
        },
        {
          id: 'login-session',
          description: 'Used for authentication',
          type: TrackerType.COOKIE,
        },
        {
          id:
            process.env.ENV_NAME && process.env.ENV_NAME !== 'production'
              ? `cb-gssc_${process.env.ENV_NAME}`
              : 'cb-gssc',
          description: 'Used for authentication',
          type: TrackerType.COOKIE,
        },
        {
          id: 'login-state',
          description: 'Used for authentication',
          type: TrackerType.COOKIE,
        },
        {
          id: '__cf_bm',
          description: 'Bot management',
          type: TrackerType.COOKIE,
        },
        {
          id: 'cb_dm',
          description: 'Device security protection',
          type: TrackerType.COOKIE,
        },
      ],
    },
    {
      id: TrackingCategory.FUNCTIONAL,
      expiry: 365,
      trackers: [
        {
          id: 'cm_default_preferences',
          description: 'Cookie preferences',
          type: TrackerType.COOKIE,
        },
      ],
    },
    {
      id: TrackingCategory.PERFORMANCE,
      expiry: 365,
      trackers: [
        {
          id: 'coinbase_device_id',
          description: 'Device identification and testing',
          type: TrackerType.COOKIE,
        },
        {
          id: 'ajs_anonymous_id',
          description: 'Visitor tracking',
          type: TrackerType.COOKIE,
        },
      ],
    },
  ],
  geolocationRules: [
    {
      region: Region.DEFAULT,
      framework: Framework.OPT_OUT,
    },
    {
      region: Region.EU,
      framework: Framework.OPT_IN,
    },
  ],
};
