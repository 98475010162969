import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientRM } from ':cloud/state/Api';
import { DeleteResourceRequest } from ':cloud/types/service_proto';

const errorMsg = 'An error occurred deleting the cluster. Please retry';
const successMsg = 'Cluster successfully deleted';

async function deleteResource(name: string, type: string) {
  // TODO: replace with resourceId
  const path = `/v1/resources/${encodeURIComponent(type)}/${encodeURIComponent(name)}`;
  await APIClientRM.delete(path);
}

export function useDeleteResource() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ name, type }: DeleteResourceRequest) => deleteResource(name, type),
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKey.resources]);
    },
    meta: {
      errorMsg,
      successMsg,
    },
  });
}
