import { useCallback, useState } from 'react';
import { VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

import { CloudErrorBoundary, CodeBlock } from ':cloud/widgets/sharedcomponents';
import type { CodeBlockCode } from ':cloud/widgets/sharedcomponents/CodeBlock/CodeBlock';
import { ErrorView } from ':cloud/widgets/sharedcomponents/ErrorView';

const codeResponseCustomStyle = {
  padding: '25px 25px',
  background: 'var(--secondary)',
  color: 'rgb(var(--gray60))',
  maxHeight: '500px',
  overflow: 'auto',
};

type VerificationsPlaygroundSectionProps = {
  heading: string;
  subheading: string;
  request: CodeBlockCode[];
  response: CodeBlockCode[];
};

export function VerificationsPlaygroundSection({
  heading,
  subheading,
  request,
  response,
}: VerificationsPlaygroundSectionProps) {
  const [showResponse, setShowResponse] = useState(false);

  const handleOnRun = useCallback(() => {
    setShowResponse(true);
  }, []);

  return (
    <VStack gap={3} bordered borderRadius="rounded" spacingVertical={5} spacingHorizontal={7}>
      <VStack>
        <TextHeadline as="p">{heading}</TextHeadline>
        <TextBody as="p" color="foregroundMuted">
          {subheading}
        </TextBody>
      </VStack>
      <CloudErrorBoundary name="Verifications Playground" fallback={<ErrorView />}>
        <VStack gap={1}>
          <CodeBlock
            title="Request"
            codeArr={request}
            showLanguageSelect={false}
            showCopy
            showRun
            onRun={handleOnRun}
            enableLineWrap
          />
          <CodeBlock
            title="Response"
            codeArr={response}
            showLanguageSelect={false}
            showCopy={false}
            enableLineWrap
            customStyle={codeResponseCustomStyle}
            headerBackground="var(--secondary)"
            displayDarkTheme={false}
            showLineNumbers={false}
            dangerouslySetColor="foreground"
            placeholder={
              showResponse ? '' : 'Click "Run" to start a request and see the response here!'
            }
            minHeight={227}
          />
        </VStack>
      </CloudErrorBoundary>
    </VStack>
  );
}
