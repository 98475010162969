import { Surface, Symbols } from 'recharts';
import {
  ContentType,
  Formatter,
  HorizontalAlignmentType,
  IconType,
  Payload,
  VerticalAlignmentType,
} from 'recharts/types/component/DefaultLegendContent';
import { DataKey, LayoutType, SymbolType } from 'recharts/types/util/types';
import { Box, HStack } from '@cbhq/cds-web/layout';
import { Tooltip } from '@cbhq/cds-web/overlays/Tooltip/Tooltip';
import { TextBody } from '@cbhq/cds-web/typography';

const SIZE = 32;
const viewBox = { x: 0, y: 0, width: SIZE, height: SIZE };
const svgStyle = { display: 'inline-block', verticalAlign: 'middle', marginRight: 4 };

type LegendIconProps = { data: Payload };
function LegendIcon({ data }: LegendIconProps) {
  if (!data?.payload) {
    return null;
  }

  const halfSize = SIZE / 2;
  const sixthSize = SIZE / 6;
  const thirdSize = SIZE / 3;
  const color = data.inactive ? '#e6e6e6' : data.color;

  if (data.type === 'plainline') {
    return (
      <line
        strokeWidth={4}
        fill="none"
        stroke={color}
        strokeDasharray={data?.payload?.strokeDasharray}
        x1={0}
        y1={halfSize}
        x2={SIZE}
        y2={halfSize}
        className="recharts-legend-icon"
      />
    );
  }
  if (data.type === 'line') {
    return (
      <path
        strokeWidth={4}
        fill="none"
        stroke={color}
        d={`M0,${halfSize}h${thirdSize}
          A${sixthSize},${sixthSize},0,1,1,${2 * thirdSize},${halfSize}
          H${SIZE}M${2 * thirdSize},${halfSize}
          A${sixthSize},${sixthSize},0,1,1,${thirdSize},${halfSize}`}
        className="recharts-legend-icon"
      />
    );
  }
  if (data.type === 'rect') {
    return (
      <path
        stroke="none"
        fill={color}
        d={`M0,${SIZE / 8}h${SIZE}v${(SIZE * 3) / 4}h${-SIZE}z`}
        className="recharts-legend-icon"
      />
    );
  }

  if (data.type === 'circle') {
    return <circle cx={halfSize} cy={halfSize} r={halfSize} stroke="none" fill={color} />;
  }

  return (
    <Symbols
      fill={color}
      cx={halfSize}
      cy={halfSize}
      size={SIZE}
      sizeType="diameter"
      type={data.type as SymbolType}
    />
  );
}

interface CustomLegendProps {
  content?: ContentType;
  iconSize?: number;
  type?: IconType;
  layout?: LayoutType;
  align?: HorizontalAlignmentType;
  verticalAlign?: VerticalAlignmentType;
  payload?: Payload[];
  inactiveColor?: string;
  formatter?: Formatter;
  onMouseEnter?: (
    data: Payload & { dataKey?: DataKey<any> },
    index: number,
    event: MouseEvent,
  ) => void;
  onMouseLeave?: (
    data: Payload & { dataKey?: DataKey<any> },
    index: number,
    event: MouseEvent,
  ) => void;
  onClick?: (data: Payload & { dataKey?: DataKey<any> }, index: number, event: MouseEvent) => void;
}

export function CustomLegend({ iconSize = 12, payload, formatter }: CustomLegendProps) {
  if (!payload?.length) {
    return null;
  }

  return (
    <HStack alignItems="center" flexWrap="wrap" spacingTop={2} gap={3}>
      {payload.map((entry: Payload, i: number) => {
        const finalFormatter = entry.formatter || formatter;

        if (entry.type === 'none') {
          return null;
        }
        return (
          <Box key={entry.value} spacingBottom={1}>
            <Tooltip content={entry.value}>
              <HStack alignItems="center">
                <>
                  <Surface width={iconSize} height={iconSize} viewBox={viewBox} style={svgStyle}>
                    <LegendIcon data={entry} />
                  </Surface>
                  <TextBody as="span" color="foregroundMuted">
                    {finalFormatter ? finalFormatter(entry.value, entry, i) : entry.value}
                  </TextBody>
                </>
              </HStack>
            </Tooltip>
          </Box>
        );
      })}
    </HStack>
  );
}
