import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CellSpacing } from '@cbhq/cds-common';
import { Button } from '@cbhq/cds-web/buttons';
import { Cell, overflowClassName } from '@cbhq/cds-web/cells/Cell';
import { NativeTextArea, TextInput } from '@cbhq/cds-web/controls';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { Pressable } from '@cbhq/cds-web/system';
import { Link, TextBody, TextLabel1, TextTitle3 } from '@cbhq/cds-web/typography';

import { useOAuthInfo } from ':cloud/contexts/OAuthInfoContext';
import { useLogoUpload } from ':cloud/hooks/useLogoUpload';
import { logError } from ':cloud/init/bugsnag/logging';
import {
  isOverLengthLimit,
  isValidURI,
  lengthErrorMessages,
  lengthLimits,
  validateRedirectURIs,
} from ':cloud/pages/OAuthCreateClient';
import { useUpdateOAuthClient } from ':cloud/queries/OAuthClientQueries/useUpdateOAuthClient';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { ClientRecordNonSensitive } from ':cloud/types/service_proto';
import { AppRoute } from ':cloud/utils/routes';
import { EditClientFormData, EditErrorsState } from ':cloud/widgets/access/oauth/types';
import { Layout } from ':cloud/widgets/layout';
import { TextLabelGray } from ':cloud/widgets/sharedcomponents';
import { ClipboardIcon } from ':cloud/widgets/sharedcomponents/ClipboardIcon';

const spacing: CellSpacing = { spacing: 0, spacingHorizontal: 2 };
const inputStyles = { display: 'none' };

export default function OAuthEditClient() {
  const history = useHistory();
  const toast = useToast();
  const { selectedClient } = useOAuthInfo();
  const { activeOrg } = useGetUser();
  const orgId = activeOrg?.organizationId || '';

  const {
    mutate: editOAuthClient,
    isLoading,
    isError,
  } = useUpdateOAuthClient(orgId, {
    onSuccess: () => {
      toast.show('Client successfully updated.', {
        variant: 'positive',
      });
      history.push(AppRoute.Access.OAuth);
    },
    onError: (editError, variables) => {
      logError(
        editError,
        {
          context: 'edit_client',
        },
        { variables: JSON.stringify(variables) },
      );
      toast.show('An error occurred updating the OAuth client. Please retry.', {
        variant: 'negative',
      });
    },
  });

  const [formData, setFormData] = useState<EditClientFormData>({
    developerWebsite: selectedClient?.clientUri || '',
    description: selectedClient?.description || '',
    redirectUris: selectedClient?.redirectUris || '',
    notificationsUrl: selectedClient?.notificationsUrl || '',
    icon: selectedClient?.icon || '',
  });

  const [errors, setErrors] = useState<EditErrorsState>({
    redirectUris: '',
    developerWebsite: '',
    notificationsUrl: '',
    description: '',
  });

  const validateFields = useCallback(() => {
    const newErrors = {
      redirectUris: formData.redirectUris ? '' : 'Redirect URIs are required.',
      notificationsUrl:
        !formData.notificationsUrl || isValidURI(formData.notificationsUrl)
          ? ''
          : 'Invalid Notification URL format.',
      developerWebsite:
        !formData.developerWebsite || isValidURI(formData.developerWebsite)
          ? ''
          : 'Invalid Developer Website URL format.',
      description: '',
    } as EditErrorsState;

    if (!validateRedirectURIs(formData.redirectUris) && formData.redirectUris) {
      newErrors.redirectUris = 'Invalid redirect URI format.';
    }

    // Check and set length limit errors
    const fieldsToCheck = [
      { key: 'developerWebsite', value: formData.developerWebsite },
      { key: 'notificationsUrl', value: formData.notificationsUrl },
      { key: 'redirectUris', value: formData.redirectUris },
      { key: 'description', value: formData.description },
    ];

    fieldsToCheck.forEach(({ key, value }) => {
      if (isOverLengthLimit(value, lengthLimits[key])) {
        newErrors[key] = lengthErrorMessages[key];
      }
    });

    setErrors(newErrors);

    return Object.values(newErrors).every((e) => e.length === 0);
  }, [formData]);

  const handleLogoRemove = useCallback(() => {
    setFormData((prevData) => ({ ...prevData, icon: '' }) as EditClientFormData);
  }, []);

  const handleLogoUpload = useCallback((file) => {
    setFormData((prevData) => ({ ...prevData, icon: file.bytes }) as EditClientFormData);
  }, []);

  const {
    uploadedFile,
    handleFileChange,
    handleUploadClick,
    handleFileRemove,
    handleDragOver,
    handleDrop,
    fileInputRef,
    sizeError,
  } = useLogoUpload({
    onFileRemove: handleLogoRemove,
    onFileUpload: handleLogoUpload,
  });

  const logoDrop = useMemo(
    () => (
      <Pressable
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onPress={handleUploadClick}
        as="button"
        background="transparent"
      >
        <Box
          borderRadius="rounded"
          justifyContent="center"
          bordered
          background="transparent"
          spacing={2}
        >
          <VStack justifyContent="center" alignItems="center" gap={2}>
            <Icon color="foreground" name="upload" size="m" />
            <TextLabel1 as="p">Upload or drag and drop an image</TextLabel1>
            <Button>Choose File</Button>
            <input
              key={uploadedFile?.name}
              ref={fileInputRef}
              type="file"
              accept="image/png, image/jpeg"
              style={inputStyles}
              onChange={handleFileChange}
            />
          </VStack>
        </Box>
      </Pressable>
    ),
    [
      handleDrop,
      handleDragOver,
      handleUploadClick,
      uploadedFile?.name,
      fileInputRef,
      handleFileChange,
    ],
  );

  useEffect(() => {
    if (selectedClient) {
      setFormData(selectedClient);
    } else {
      // Redirect to the OAuth page if no client is selected
      toast.show('Select a client to edit', { variant: 'negative' });
      history.push(AppRoute.Access.OAuth);
    }
  }, [history, selectedClient, toast]);

  const getLogoErrorMessage = useCallback(() => {
    if (sizeError === 'fileSize') {
      return 'File size exceeds 500KB limit. Please choose a smaller file.';
    }
    if (sizeError === 'dimensions') {
      return 'Image dimensions exceed 500x500 pixels. Please choose a smaller image.';
    }
    return '';
  }, [sizeError]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({ ...prevData, [name]: value }) as EditClientFormData);
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    },
    [],
  );

  const imageExists = uploadedFile || formData?.icon;
  const imgSrc = uploadedFile?.url || `data:image/jpeg;base64,${formData?.icon}`;

  const handleBackPress = useCallback(() => {
    history.push(AppRoute.Access.OAuth);
  }, [history]);

  const handleEditPress = useCallback(() => {
    if (validateFields()) {
      editOAuthClient({
        clientId: selectedClient?.clientId || '',
        clientRequest: {
          clientRecord: {
            name: `organizations/${orgId}/oauthClients/${selectedClient?.clientId}`,
            clientRecordNonSensitive: formData as ClientRecordNonSensitive,
          },
        },
      });
    } else {
      toast.show('Please correct the errors before submitting.', {
        variant: 'negative',
      });
    }
  }, [editOAuthClient, formData, orgId, selectedClient?.clientId, toast, validateFields]);

  return (
    <Layout>
      <Layout.MainContainer fullWidth>
        <VStack spacingBottom={4} gap={4}>
          <TextTitle3 as="h2">Edit OAuth Client: {selectedClient?.name}</TextTitle3>
          <TextLabel1 as="p">Client ID</TextLabel1>
          <HStack
            width="100%"
            gap={6}
            alignItems="center"
            background="backgroundAlternate"
            borderRadius="rounded"
          >
            <Cell
              priority="end"
              innerSpacing={spacing}
              outerSpacing={spacing}
              detail={
                <Box justifyContent="flex-end">
                  <ClipboardIcon text="rpcEndpointUrl" iconSize="s" />
                </Box>
              }
            >
              <Box borderRadius="roundedSmall" display="flex" spacing={2} flexGrow={1}>
                <TextBody
                  as="p"
                  color="foregroundMuted"
                  overflow="wrap"
                  className={overflowClassName}
                >
                  {selectedClient?.clientId}
                </TextBody>
              </Box>
            </Cell>
          </HStack>
          <TextInput
            inputNode={
              <NativeTextArea
                rows={3}
                value={formData.description}
                onChange={handleInputChange}
                placeholder="Simplify crypto transfers between friends."
                name="description"
              />
            }
            name="description"
            label="Description"
            variant={errors.description ? 'negative' : undefined}
            helperText={errors.description || 'A brief description of your application. Optional.'}
          />
          <TextLabel1 as="label">Logo</TextLabel1>
          {imageExists ? (
            <HStack gap={3} alignItems="center">
              <Box height="126px" borderRadius="rounded" overflow="hidden">
                <img src={imgSrc} alt="logo" height="126px" style={{ objectFit: 'cover' }} />
              </Box>
              <VStack gap={1}>
                <TextBody as="p">{uploadedFile?.name || 'Uploaded logo'}</TextBody>
                <Button
                  onClick={() => {
                    handleLogoRemove();
                    handleFileRemove();
                  }}
                  variant="secondary"
                >
                  Remove
                </Button>
              </VStack>
            </HStack>
          ) : (
            <>
              {logoDrop}
              {sizeError && (
                <TextBody as="p" color="negative">
                  {getLogoErrorMessage()}
                </TextBody>
              )}
            </>
          )}
          <TextInput
            inputNode={
              <NativeTextArea
                rows={3}
                value={formData.redirectUris}
                onChange={handleInputChange}
                placeholder="https://example.com/callback"
                name="redirectUris"
              />
            }
            name="redirectUris"
            label="Redirect URIs"
            helperText={
              errors.redirectUris ||
              'Enter one URI per line. When using OAuth, only these redirect URIs will be permitted.'
            }
            variant={errors.redirectUris ? 'negative' : undefined}
          />
          <TextInput
            label="Notification URL (coming soon)"
            name="notificationsUrl"
            disabled
            value={formData.notificationsUrl}
            onChange={handleInputChange}
            placeholder="https://www.example.com/notifications"
            helperText={
              errors.notificationsUrl || (
                <TextLabelGray as="sub">
                  Webhook for notifications. For more information
                  <Link
                    openInNewWindow
                    href="https://docs.cdp.coinbase.com/sign-in-with-coinbase/docs/notifications"
                  >
                    {' '}
                    see our documentation.{' '}
                  </Link>
                  Optional.
                </TextLabelGray>
              )
            }
            variant={errors.notificationsUrl ? 'negative' : undefined}
          />
          <TextInput
            label="Developer Website"
            name="developerWebsite"
            value={formData.developerWebsite}
            onChange={handleInputChange}
            placeholder="https://john-doe-dev.com"
            helperText={errors.developerWebsite || "Developer's website. Optional."}
            variant={errors.developerWebsite ? 'negative' : undefined}
          />
          <HStack justifyContent="space-between">
            <Button variant="secondary" onClick={handleBackPress}>
              Cancel
            </Button>
            <Button loading={isLoading} variant="primary" onPress={handleEditPress}>
              Save changes
            </Button>
          </HStack>
          <HStack justifyContent="flex-end">
            {isError && (
              <TextBody as="p" color="negative">
                An error occured. Please try again later.
              </TextBody>
            )}
          </HStack>
        </VStack>
      </Layout.MainContainer>
    </Layout>
  );
}
