import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormValidation } from '@rjsf/core';
import _omit from 'lodash/omit';
import { Button } from '@cbhq/cds-web/buttons';
import { VStack } from '@cbhq/cds-web/layout';
import { TextLabel2, TextTitle1 } from '@cbhq/cds-web/typography';

import { useNewCluster } from ':cloud/contexts/NewClusterContext';
import { useIsDeveloperMode } from ':cloud/hooks/useIsDeveloperMode';
import { useGetResources } from ':cloud/queries/ResourceQueries/useGetResources';
import { ICluster, IFormDataType, IWorkflow } from ':cloud/types/ts_types';
import { getAdapter } from ':cloud/utils/clusters';
import { customValidation, transformErrors } from ':cloud/utils/forms';
import { scrollToTop } from ':cloud/utils/scrollToTop';
import { FormBtnRef } from ':cloud/widgets/forms/form-btn-ref';
import { TextLabelGray } from ':cloud/widgets/sharedcomponents';
import Form from ':cloud/widgets/sharedcomponents/JSONSchemaForm/FormSchema';

interface CustomWizardFormProps {
  selectedWorkflow: IWorkflow;
  enableClusterSummaryRef?: boolean;
}

function CustomWizardForm({ selectedWorkflow, enableClusterSummaryRef }: CustomWizardFormProps) {
  const adapter = getAdapter();
  const { clusterType } = adapter;
  const { formData, setFormData, workflowVersion } = useNewCluster();
  const { isDeveloperMode } = useIsDeveloperMode();
  const history = useHistory();
  const { protocolKey } = useParams<{ protocolKey: string }>();
  const { resources } = useGetResources((r: ICluster) => r.clusterType === 'participation');
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const formContext = useMemo(
    () => ({
      hasSubmitted,
      formData,
      clusterType,
      order: selectedWorkflow?.uiSchema['ui:order'],
    }),
    [clusterType, formData, hasSubmitted, selectedWorkflow?.uiSchema],
  );

  /** disallow customers from creating with duplicate names  */
  // TODO: do we want to filter this by protocolKey?
  const userDefinedClusterNames = useMemo(() => {
    return (
      resources
        // .filter((resource) => resource.type === protocolKey)
        .map((resource) => resource.name)
    );
  }, [resources]);

  useLayoutEffect(() => {
    scrollToTop('auto');
  }, []);

  const handleSubmit = useCallback(() => {
    const path = `/review${workflowVersion ? `?version=${workflowVersion}` : ''}`;

    history.push(history.location.pathname.concat(path));
  }, [history, workflowVersion]);

  const handleErrors = useCallback((errors: string[]) => {
    /** form errors in console in dev */
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      errors.map((err: string) => console.error(err));
    }
    setHasSubmitted(true);
  }, []);

  return (
    <>
      <VStack spacingTop={5} spacingBottom={8}>
        {!enableClusterSummaryRef && (
          <TextLabelGray as="span" spacingEnd={1}>
            {selectedWorkflow?.display}
          </TextLabelGray>
        )}
        {isDeveloperMode && (
          <TextLabel2 as="span" spacingVertical={1} dangerouslySetColor="rgb(var(--purple60))">
            Workflow Version: {selectedWorkflow?.version}
          </TextLabel2>
        )}
        <TextTitle1 as="h2">Configure your project</TextTitle1>
      </VStack>
      <Form
        formContext={formContext}
        formData={formData}
        liveOmit
        liveValidate={hasSubmitted}
        noHtml5Validate
        onChange={(data: any): void => setFormData({ ...data.formData, protocolKey })}
        onError={handleErrors}
        onSubmit={handleSubmit}
        schema={_omit(selectedWorkflow.formSchema, ['title', 'description'])}
        showErrorList={false}
        transformErrors={(errors) => transformErrors(errors, selectedWorkflow)}
        uiSchema={selectedWorkflow.uiSchema}
        validate={(form: IFormDataType, errors: FormValidation): FormValidation => {
          return customValidation(form, selectedWorkflow, userDefinedClusterNames, errors);
        }}
      >
        {enableClusterSummaryRef ? (
          <FormBtnRef />
        ) : (
          <VStack spacingVertical={10}>
            <Button disabled={false} type="submit" block>
              Save &amp; continue
            </Button>
          </VStack>
        )}
      </Form>
    </>
  );
}

export default CustomWizardForm;
