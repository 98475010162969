import { useEffect, useState } from 'react';
import { CookiePreferencesModal } from '@coinbase/cookie-banner';

import { AppRoute } from './routes';

export function CookiePreferences() {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = window.location;

  useEffect(() => {
    if (pathname === AppRoute.CookiePreferences) {
      setIsOpen(true);
    }
  }, [pathname]);

  const onClose = (): void => {
    setIsOpen(false);
    window.location.href = AppRoute.Home;
  };

  return <> {isOpen && <CookiePreferencesModal isOpen={isOpen} onClose={onClose} />} </>;
}
