import { VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextTitle3 } from '@cbhq/cds-web/typography';

import { useDashboardContext } from ':cloud/contexts/DashboardProvider';
import { ChartBody } from ':cloud/widgets/monitoring/ChartBody';
import { DashboardChart } from ':cloud/widgets/monitoring/types';
import { ChartErrorFallback } from ':cloud/widgets/sharedcomponents/ChartErrorFallback';

import { CloudErrorBoundary } from '../sharedcomponents';

export function Chart({
  title,
  chartType,
  metricName,
  aggregationMethod,
  groupBy,
  filterBy,
  unit,
  bordered = true,
  showOverlay,
  range,
  subtitle,
}: DashboardChart) {
  const { errorBoundaryKey } = useDashboardContext();

  return (
    <VStack
      bordered={bordered}
      borderRadius="rounded"
      spacing={5}
      spacingBottom={subtitle ? 10 : 5}
      height={368}
      minWidth="50%"
      flexGrow={1}
      borderColor="line"
      alignContent="center"
    >
      <TextTitle3 align="start" as="h3" spacingBottom={subtitle ? 1 : 4}>
        {title}
      </TextTitle3>
      {subtitle && (
        <TextBody as="p" color="foregroundMuted" spacingBottom={4}>
          {subtitle}
        </TextBody>
      )}
      {/* Hidden for MVP; will be adding this back in later */}
      {/* <Select value={chartType} onChange={setChartType} width={185}>
        {CHART_TYPES.map((t) => (
          <SelectOption key={t} value={t} title={t} />
        ))}
      </Select> */}
      <CloudErrorBoundary name="ChartBody" fallback={<ChartErrorFallback />} key={errorBoundaryKey}>
        <ChartBody
          chartType={chartType}
          metricName={metricName}
          aggregationMethod={aggregationMethod}
          groupBy={groupBy}
          filterBy={filterBy}
          unit={unit}
          showOverlay={showOverlay}
          range={range}
        />
      </CloudErrorBoundary>
    </VStack>
  );
}
