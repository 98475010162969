import { useMemo } from 'react';

import { useGetAllOrganizations } from ':cloud/queries/OrganizationQueries/useGetAllOrganizations';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';

export function useGetActiveOrg() {
  const { activeOrg } = useGetUser();
  const { organizations } = useGetAllOrganizations();

  return useMemo(
    () => organizations?.find((org) => org.organizationId === activeOrg?.organizationId),
    [organizations, activeOrg],
  );
}
