import { useQuery } from '@tanstack/react-query';
import _keyBy from 'lodash/keyBy';

import { QueryKey } from ':cloud/queries/types';
import { APIClientRM } from ':cloud/state/Api';
import { GetResourceCustomerLimitsResponse } from ':cloud/types/service_proto';
import { ICustomerLimits } from ':cloud/types/ts_types';

async function fetchResourceLimits(orgId: string): Promise<GetResourceCustomerLimitsResponse> {
  const path = `/v1/customers/${orgId}/limits`;
  const { data } = await APIClientRM.get<GetResourceCustomerLimitsResponse>(path);
  return data;
}

export function useGetResourceLimits(orgId = '') {
  const { isLoading, data } = useQuery({
    queryKey: [QueryKey.resources, 'limits', orgId],
    queryFn: async () => fetchResourceLimits(orgId),
    meta: { retry: 3 },
    enabled: !!orgId,
  });

  return {
    isLoading,
    resourceLimits: _keyBy(data, 'resourceType') as ICustomerLimits,
  };
}
