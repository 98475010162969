/**
 *
 * @param top pixels from top to scroll to
 * @param left pixels from left to scroll to
 * @param behavior smooth | auto
 * @NB if using 'auto', put in useLayoutEffect to avoid noticeable page jumping
 */
export function scrollToTop(behavior: ScrollBehavior = 'smooth', top = 0, left = 0): void {
  window.scrollTo({ top, left, behavior });
}
