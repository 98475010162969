import { AccessTable } from ':cloud/widgets/access/teams/AccessTable';
import { Layout } from ':cloud/widgets/layout';

export function TeamPage() {
  return (
    <Layout>
      <Layout.MainContainer fullWidth>
        <AccessTable />
      </Layout.MainContainer>
    </Layout>
  );
}
