export const QueryKey = {
  accountCredits: 'accountCredits',
  accountItems: 'accountItems',
  accounts: 'accounts',
  apiKeys: 'apiKeys',
  apiKeyUsage: 'apiKeyUsage',
  baseRPC: 'baseRPC',
  billing: 'billing',
  contractFunctions: 'contractFunctions',
  earnings: 'earnings',
  experiments: 'experiments',
  faucet: 'faucet',
  gasPolicy: 'gasPolicy',
  internal: 'internal', // used by internal admin pages
  killSwitches: 'killSwitches',
  monitoring: 'monitoring',
  oauth: 'oauth',
  organizations: 'organizations',
  paymasterBalance: 'paymasterBalance',
  paymasterInfo: 'paymasterInfo',
  permissions: 'permissions',
  portfolios: 'portfolios',
  products: 'products',
  productDetails: 'productDetails',
  projects: 'projects',
  resources: 'resources',
  self: 'self',
  tokens: 'tokens',
  waasProjects: 'waasProjects',
  wallets: 'wallets',
  webhooks: 'webhooks',
  workflows: 'workflows',
};
