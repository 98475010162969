import { useNewCluster } from ':cloud/contexts/NewClusterContext';
import { AnyDuringJSToTSMigration } from ':cloud/types/ts_types';

export function FormBtnRef(props: AnyDuringJSToTSMigration) {
  const { setFormBtnRef } = useNewCluster();

  return (
    <button
      type="submit"
      ref={(r) => setFormBtnRef(r as AnyDuringJSToTSMigration)}
      style={{ display: 'none' }}
      {...props}
    />
  );
}
