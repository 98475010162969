import { useQuery } from '@tanstack/react-query';

import { APIClientBase } from ':cloud/state/Api';
import { GetChartDataResponse } from ':cloud/widgets/monitoring/types';
import { getTagsFromChartData } from ':cloud/widgets/monitoring/utils';

import { QueryKey } from '../types';

type GetChartDataBody = {
  metricName: string;
  scope: string;
  aggregationMethod: string;
  groupBy: string[];
  startTime: string;
  endTime: string;
  filterBy: { name: string; value: string }[];
};

async function fetchChartData(body: GetChartDataBody): Promise<GetChartDataResponse> {
  const path = '/cloud-metrics/v1/query';
  const { data } = await APIClientBase.post<GetChartDataResponse>(path, body);
  return data;
}

function generateChartDataQuery({
  body,
  enabled,
  queryKey,
}: {
  body: GetChartDataBody;
  enabled: boolean;
  queryKey: any[];
}) {
  return {
    // eslint-disable-next-line @tanstack/query/exhaustive-deps -- body details are included in generated query key
    queryKey,
    queryFn: async () => fetchChartData(body),
    enabled,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: true,
  };
}

export function useGetChartData(body: GetChartDataBody, organizationId: string, projectId: string) {
  const { filterBy } = body;

  // All queries must be filtered by organizationId and projectId at a minimum
  const mergedFilters = [
    { name: 'cloud.organization.id', value: organizationId },
    { name: 'cloud.project.id', value: projectId },
    ...filterBy,
  ];
  const requestBody = { ...body, filterBy: mergedFilters };
  const enabled = !!organizationId && !!projectId;

  const queryKey = [
    QueryKey.monitoring,
    'charts',
    organizationId,
    projectId,
    body.metricName,
    body.startTime,
    body.endTime,
    ...Object.values(filterBy),
    ...body.groupBy,
    body.aggregationMethod,
  ];

  const { isLoading, data } = useQuery(
    generateChartDataQuery({ body: requestBody, enabled, queryKey }),
  );

  return {
    isLoading,
    data: data?.results,
    tags: getTagsFromChartData(data?.results || []),
  };
}
