import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { Tooltip } from '@cbhq/cds-web/overlays/Tooltip/Tooltip';
import { TextBody } from '@cbhq/cds-web/typography';

import { EM_DASH } from ':cloud/brand/constants';
import { cursorPointer, wordBreakAll } from ':cloud/brand/utilityClasses';
import { truncateMiddle } from ':cloud/utils/common';

import { ClipboardIcon } from './ClipboardIcon';
import { TextLabelGray } from './TextLabelGray';

interface DisplayUnitProps {
  isCard?: boolean;
  label: string;
  value?: string;
  unit?: string;
  isCapitalized?: boolean;
  invertLabel?: boolean;
  hasClipboard?: boolean;
  overrideTruncate?: boolean;
  ignoreAutoTruncate?: boolean;
}

export function DisplayUnit({
  isCard = false,
  label,
  value = '—',
  unit,
  invertLabel = false,
  hasClipboard = false,
  isCapitalized,
  overrideTruncate = false,
  ignoreAutoTruncate = false,
}: DisplayUnitProps) {
  const autoTruncate = ignoreAutoTruncate
    ? false
    : label.match(/address|^validator$|^rewards key|^stake key|signer|public\skey/i);

  return (
    <VStack
      testID="shared-display-unit"
      alignItems="flex-start"
      justifyContent="space-between"
      spacing={isCard ? 5 : 0}
      borderRadius={isCard ? 'roundedSmall' : undefined}
      borderColor={isCard ? 'line' : undefined}
    >
      {!invertLabel && <TextLabelGray spacingBottom={1}>{label}</TextLabelGray>}
      {(autoTruncate || overrideTruncate) && (
        <Box alignItems="center">
          <Tooltip content={value}>
            <TextBody
              as="span"
              transform={isCapitalized ? 'uppercase' : undefined}
              spacingEnd={0.5}
              className={cursorPointer}
            >
              {truncateMiddle(value, 6, 6, '...')}
            </TextBody>
          </Tooltip>
          <ClipboardIcon text={value} />
        </Box>
      )}
      {!autoTruncate && !overrideTruncate && hasClipboard && (
        <HStack as="div" gap={1} alignItems="center" className={wordBreakAll}>
          <TextBody
            as="p"
            display="inline"
            transform={isCapitalized ? 'uppercase' : undefined}
            className={wordBreakAll}
          >
            {value}
          </TextBody>
          <ClipboardIcon text={value} />
        </HStack>
      )}
      {!autoTruncate && !overrideTruncate && !hasClipboard && (
        <HStack alignItems="flex-end" justifyContent="flex-start">
          <TextBody
            as="p"
            transform={isCapitalized ? 'uppercase' : undefined}
            className={wordBreakAll}
          >
            {value}
          </TextBody>
          {value !== EM_DASH && unit && (
            <TextBody as="span" transform="uppercase">
              {unit}
            </TextBody>
          )}
        </HStack>
      )}
      {invertLabel && <TextLabelGray spacingTop={1}>{label}</TextLabelGray>}
    </VStack>
  );
}
