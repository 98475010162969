import type { FC, SVGProps } from 'react';

import { ReactComponent as NodeFour } from ':cloud/assets/Nodes/small-four.svg';
import { ReactComponent as NodeOne } from ':cloud/assets/Nodes/small-one.svg';
import { ReactComponent as NodeThree } from ':cloud/assets/Nodes/small-three.svg';
import { ReactComponent as NodeTwo } from ':cloud/assets/Nodes/small-two.svg';
import { ReactComponent as BlockIcon } from ':cloud/assets/Objects/block.svg';
import { ReactComponent as Eth2Icon } from ':cloud/assets/Objects/eth2.svg';
import { ReactComponent as SyncStatusIcon } from ':cloud/assets/Objects/sync-status.svg';
import { ReactComponent as ArrowUpIcon } from ':cloud/assets/Objects/uptime.svg';
import { ReactComponent as ValidatorIcon } from ':cloud/assets/Objects/validators.svg';
import AWSLogoPath from ':cloud/assets/Providers/aws/aws.svg';
import AzureLogoPath from ':cloud/assets/Providers/azure/azure.svg';
import GCPLogoPath from ':cloud/assets/Providers/gcp/gcp.svg';
import { ProtocolKey } from ':cloud/types/ts_types';

export function getIconAlt(protocolKey: ProtocolKey): string {
  return protocolKey.toLowerCase().replace(/\s/g, '').replace(/qt$/i, 'QT');
}

export function formatProtocolKey(protocolKey: ProtocolKey): string {
  return protocolKey.toLowerCase().replace(/\s/g, '').replace(/qt$/i, '');
}

export function getProviderIconSrc(regionPath: string): string {
  if (regionPath.includes('aws')) {
    return AWSLogoPath;
  }
  if (regionPath.includes('gcp')) {
    return GCPLogoPath;
  }
  if (regionPath.includes('azure')) {
    return AzureLogoPath;
  }
  return regionPath;
}

function getNodeIcon(count = 1): FC<SVGProps<SVGSVGElement>> {
  const wordCount: Record<number, FC<SVGProps<SVGSVGElement>>> = {
    1: NodeOne,
    2: NodeTwo,
    3: NodeThree,
    4: NodeFour,
  };
  return wordCount[count];
}

export function getBlockUnitIcon(
  icon: string,
  nodeCount?: number,
): FC<SVGProps<SVGSVGElement>> | undefined {
  switch (icon) {
    case 'arrow':
    case 'arrowUp':
      return ArrowUpIcon;
    case 'validator':
      return ValidatorIcon;
    case 'eth2':
      return Eth2Icon;
    case 'version':
    case 'block':
      return BlockIcon;
    case 'node_count':
    case 'node':
      return getNodeIcon(nodeCount);
    case 'sync':
    case 'sync-status':
      return SyncStatusIcon;
    default:
      return undefined;
  }
}
