import { useMemo } from 'react';
import { Button } from '@cbhq/cds-web/buttons';
import { Grid, VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextHeadline, TextTitle3 } from '@cbhq/cds-web/typography';

import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';

import { FULL_PAGE_MAX_WIDTH } from '../product/constants';

export type ExampleSectionItem = {
  title: string;
  description: string;
  cta?: string;
  onPress?: () => void;
  image: JSX.Element;
};

function ExampleSectionItemComponent({
  title,
  description,
  cta,
  onPress,
  image,
}: ExampleSectionItem) {
  return (
    <VStack
      alignItems="flex-start"
      borderRadius="rounded"
      justifyContent="space-between"
      width="100%"
    >
      <VStack width="100%">
        {image}
        <TextHeadline as="h3" spacingTop={3} spacingBottom={1}>
          {title}
        </TextHeadline>
        <TextBody as="p" color="foregroundMuted" spacingBottom={1}>
          {description}
        </TextBody>
      </VStack>
      {cta && onPress && (
        <Button
          compact
          variant="primary"
          flush="start"
          transparent
          endIcon="diagonalUpArrow"
          accessibilityLabel={`${cta} + opens in new window`}
          onPress={onPress}
        >
          {cta}
        </Button>
      )}
    </VStack>
  );
}

type ExampleSectionProps = {
  exampleItems: ExampleSectionItem[];
};

export function ExampleSection({ exampleItems }: ExampleSectionProps) {
  const { isDesktop } = useSimpleBreakpoints();
  const responsiveTemplateColumns = useMemo(() => {
    const desktopColumns = Array.from({ length: exampleItems.length }, () => '1fr').join(' ');
    return isDesktop ? desktopColumns : '1fr';
  }, [exampleItems.length, isDesktop]);

  return (
    <VStack borderedTop borderedBottom maxWidth="100%" alignItems="flex-start">
      <VStack gap={9} spacing={9} maxWidth={FULL_PAGE_MAX_WIDTH}>
        <TextTitle3 as="h1">Tutorials</TextTitle3>
        <Grid templateColumns={responsiveTemplateColumns} gap={3}>
          {exampleItems.map(({ image, title, description, cta, onPress }) => {
            return (
              <ExampleSectionItemComponent
                key={title}
                image={image}
                title={title}
                description={description}
                cta={cta}
                onPress={onPress}
              />
            );
          })}
        </Grid>
      </VStack>
    </VStack>
  );
}
