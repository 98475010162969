import { useMemo } from 'react';
import { Icon } from '@cbhq/cds-web/icons';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { Tooltip as CDSTooltip } from '@cbhq/cds-web/overlays/Tooltip/Tooltip';
import { TextDisplay3, TextHeadline, TextTitle2 } from '@cbhq/cds-web/typography';

import { useGetStakingRewards } from ':cloud/queries/EarningsQueries/useGetStakingRewards';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { ProtocolRewardsChart } from ':cloud/widgets/earnings/ProtocolRewardsChart';
import { ChartType, EarningsRange } from ':cloud/widgets/earnings/types';
import { sumEarnings } from ':cloud/widgets/earnings/utils';
import { LoadingSpinner } from ':cloud/widgets/sharedcomponents';
import { EmptyChart } from ':cloud/widgets/sharedcomponents/EmptyChart';

interface ProtocolHeaderProps {
  title: string;
  value?: string;
  helperText?: string;
  unit?: string;
}

function ProtocolHeader({ title, helperText, value, unit }: ProtocolHeaderProps) {
  return (
    <VStack gap={0.5} spacingBottom={5}>
      {helperText ? (
        <CDSTooltip content={helperText} placement="right">
          <HStack gap={0.5} alignItems="center">
            <TextHeadline as="p" color="foregroundMuted">
              {title}
            </TextHeadline>
            <Icon name="info" size="s" color="foregroundMuted" />
          </HStack>
        </CDSTooltip>
      ) : (
        <TextTitle2 as="h2">{title}</TextTitle2>
      )}
      {value && <TextDisplay3 as="h2">{`${value} ${unit}`}</TextDisplay3>}
    </VStack>
  );
}

const DEFAULT_PROTOCOL = 'ethereum';
interface EarningsRewardsChartProps {
  title: string;
  chartType: ChartType;
  range: EarningsRange;
}
// TODO: improve empty graph charts
export function EarningsRewardsChart({ title, chartType, range }: EarningsRewardsChartProps) {
  const { activeOrg } = useGetUser();
  const { isLoading, data } = useGetStakingRewards(
    { filter: range },
    activeOrg?.organizationId || '',
  );

  const filteredProtocol = useMemo(() => {
    const protocolReward = data.protocolRewards?.find((pr) => pr.protocol === DEFAULT_PROTOCOL);
    return { ticker: protocolReward?.ticker || '', rewards: protocolReward?.rewards || {} };
  }, [data.protocolRewards]);

  const sumRewards = useMemo(() => {
    return sumEarnings(filteredProtocol.rewards);
  }, [filteredProtocol.rewards]);

  if (isLoading) return <LoadingSpinner />;

  if (!isLoading && !data) {
    throw new Error('Invalid response');
  }

  if (!isLoading && data.protocolRewards.length === 0) {
    return <EmptyChart chartType={chartType} />;
  }

  return (
    <>
      <ProtocolHeader
        title={title}
        unit={filteredProtocol.ticker}
        helperText="Displayed in native token"
        value={sumRewards.totalEarnedNativeUnits}
      />
      <ProtocolRewardsChart
        protocol={DEFAULT_PROTOCOL}
        rewards={filteredProtocol.rewards}
        ticker={filteredProtocol.ticker}
        selectedPeriod="previousMonth"
      />
    </>
  );
}
