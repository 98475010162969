import { TwoFactorTargetEnv } from '@cbhq/two-factor-web/types';

import config from ':cloud/config';

export function useIdentityTarget(): TwoFactorTargetEnv {
  const releaseStage = config.environmentName;

  if (config.isMocksEnabled()) {
    return TwoFactorTargetEnv.Mock;
  }

  if (window.location.hostname.includes('localhost')) {
    return TwoFactorTargetEnv.Local;
  }

  return releaseStage as unknown as TwoFactorTargetEnv;
}
