import { useCallback, useMemo } from 'react';
import { addMinutes } from 'date-fns';
import { Payload, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { capitalize } from '@cbhq/cds-utils';
import { Icon } from '@cbhq/cds-web/icons';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { TextBody } from '@cbhq/cds-web/typography';

import { useDashboardContext } from ':cloud/contexts/DashboardProvider';
import { truncateMiddle } from ':cloud/utils/common';
import { RANGE, RANGE_INTERVAL_MAP } from ':cloud/widgets/monitoring/monitoringConfig';
import { formatTooltipValueByUnit } from ':cloud/widgets/monitoring/utils';

import { ChartType, DashboardRange } from '../types';

/** The max # of tooltip items to show before we start hiding zero values.
 * The tooltip will continue displaying items beyond this limit if their value is > 0.
 */
const MAX_TOOLTIP_ITEMS = 5;

type MonitoringChartTooltipTimestampProps = {
  label: string;
  chartType: ChartType;
};
function MonitoringChartTooltipTimestamp({
  label,
  chartType,
}: MonitoringChartTooltipTimestampProps) {
  // use this to retrieve the range if useDashboardContext is not working
  const { range } = useDashboardContext(); // retrieves the range from the global context

  const endOfInterval = useMemo(() => {
    const date = new Date(label);
    return new Date(addMinutes(date, RANGE_INTERVAL_MAP[chartType][range]));
  }, [label, range, chartType]);

  const formatTooltipTimestamp = useCallback(
    (date: string) => {
      const timeFormatOptions: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short',
      };
      const dateFormatOptions: Intl.DateTimeFormatOptions = {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
      };
      const yearFormatOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric',
      };
      const options =
        range === RANGE.day
          ? { ...timeFormatOptions, ...dateFormatOptions }
          : { ...dateFormatOptions, ...yearFormatOptions };
      return Intl.DateTimeFormat('en-US', options).format(new Date(date));
    },
    [range],
  );

  const spliceTooltipTime = (date: string) => {
    return formatTooltipTimestamp(date).split(',').slice(2).join(',');
  };

  const spliceTooltipDay = (date: string) => {
    return formatTooltipTimestamp(date).split(',').slice(0, 2).join(',');
  };

  // Omits showing the Weekday / Date twice when the date is the same on a day range view
  const showTooltipIntervalSecondDayStr =
    range === RANGE.day &&
    spliceTooltipDay(endOfInterval.toISOString()) === spliceTooltipDay(label);

  const tooltipIntervalSecondDayStr = spliceTooltipTime(endOfInterval.toISOString());

  return (
    <TextBody as="p" mono color="foregroundMuted">
      {formatTooltipTimestamp(label)}
      {showTooltipIntervalSecondDayStr && (
        <>
          {' to'}
          <br />
          {tooltipIntervalSecondDayStr}
        </>
      )}
    </TextBody>
  );
}

type MonitoringChartTooltipProps = {
  active?: boolean;
  payload?: TooltipPayload[];
  label?: string;
  chartType: ChartType;
  range?: DashboardRange;
};

type TooltipPayload = Payload<ValueType, string>;

const textAndDataColor = 'rgb(var(--gray100))'; // color for tooltip unit strings

export function MonitoringChartTooltip({
  active,
  payload,
  label,
  chartType,
}: MonitoringChartTooltipProps) {
  const isPayloadAllZeroes = useMemo(() => payload?.every((item) => item.value === 0), [payload]);

  if (active && payload && payload.length > 0) {
    const unit = payload[0].unit || '';
    const shouldTruncatePayload = payload.length > MAX_TOOLTIP_ITEMS;
    return (
      <VStack
        background="background"
        spacing={3}
        gap={1}
        borderRadius="rounded"
        borderColor="line"
        elevation={2}
      >
        {label && <MonitoringChartTooltipTimestamp label={label} chartType={chartType} />}
        {/* Replace all tooltip data charts for 'No requests' if all returned values are zero value. */}
        {isPayloadAllZeroes && shouldTruncatePayload && (
          <TextBody as="p">No {unit || 'data'}</TextBody>
        )}
        {/* Hide 0 values when payload length exceeds MAX_TOOLTIP_ITEMS. If values are > 0, they will be shown regardless of limit. */}

        {payload.map(({ name, value, color }) => {
          if (shouldTruncatePayload && value === 0) {
            return null;
          }
          return (
            <HStack gap={1} justifyContent="space-between" key={name}>
              <HStack alignItems="center" gap={0.5}>
                <Icon name="dot" size="m" dangerouslySetColor={color} />
                <TextBody as="p" dangerouslySetColor={textAndDataColor}>
                  {capitalize(name || '').slice(0, 1) +
                    truncateMiddle(name || '', 11, 13, '...').slice(1)}
                </TextBody>
              </HStack>
              <TextBody as="p" dangerouslySetColor={textAndDataColor}>
                {value ? formatTooltipValueByUnit(value, unit.toString()) : '0'}
              </TextBody>
            </HStack>
          );
        })}
      </VStack>
    );
  }
  return null;
}
