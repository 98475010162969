import { UiIconName } from '@cbhq/cds-icons';
import { Button } from '@cbhq/cds-web/buttons';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { palette } from '@cbhq/cds-web/tokens';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

type ConfigurationItemProps = {
  title: string;
  description?: string;
  iconName?: UiIconName;
  cta?: string;
  ctaIcon?: UiIconName;
  onPress?: () => void;
  expandedContent?: JSX.Element;
};

export function ConfigurationItem({
  title,
  description,
  iconName,
  cta,
  ctaIcon,
  onPress,
  expandedContent,
}: ConfigurationItemProps) {
  return (
    <VStack bordered borderRadius="rounded" spacing={4} gap={1}>
      <HStack
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        spacingBottom={expandedContent ? 2 : 0}
      >
        <HStack alignItems="center" gap={2}>
          {iconName && (
            <Box
              dangerouslySetBackground={palette.foreground}
              height={24}
              width={24}
              minWidth={24}
              borderRadius="roundedFull"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon name={iconName} size="s" color="primaryForeground" />
            </Box>
          )}
          <VStack>
            <TextHeadline as="h1">{title}</TextHeadline>
            <TextBody as="p" color="foregroundMuted">
              {description}
            </TextBody>
          </VStack>
        </HStack>
        {cta && onPress && (
          <Button transparent flush="start" onPress={onPress} endIcon={ctaIcon}>
            {cta}
          </Button>
        )}
      </HStack>
      {expandedContent}
    </VStack>
  );
}
