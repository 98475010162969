import { useMemo } from 'react';
import _capitalize from 'lodash/capitalize';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

import { PaymentCard } from ':cloud/types/service_proto';
import { getCardMedia, isCardDateValid as getIsCardDateValid } from ':cloud/utils/billing';
import { getFormattedExpirationDate } from ':cloud/utils/formatters/time';

type PaymentMethodDisplayCardProps = {
  displayedMethod: PaymentCard;
  showIcon?: boolean;
  onClickHandler?: React.MouseEventHandler<HTMLInputElement>;
  onCancel?: React.MouseEventHandler<HTMLInputElement>;
  transparent?: boolean;
  expanded?: boolean;
};

const invalidCardStyle = { paddingTop: '2px' };

export function PaymentMethodDisplayCard({
  displayedMethod: card,
  showIcon = true,
  expanded = false,
}: PaymentMethodDisplayCardProps) {
  const cardType = useMemo(() => card.network, [card]);
  const fallbackCardMedia = useMemo(() => <Icon name="card" size="m" />, []);
  const cardMedia = useMemo(
    () => getCardMedia(cardType, fallbackCardMedia),
    [cardType, fallbackCardMedia],
  );
  const title = useMemo(
    () => `${_capitalize(cardType)} ***${card.lastFour}`,
    [cardType, card.lastFour],
  );
  const detail = useMemo(
    () => `Exp. ${getFormattedExpirationDate(card.expirationMonth, card.expirationYear)}`,
    [card.expirationMonth, card.expirationYear],
  );
  const isCardDateValid = useMemo(() => getIsCardDateValid(card), [card]);
  const hasInvalidCardOnFile = useMemo(
    () => !isCardDateValid && cardType,
    [isCardDateValid, cardType],
  );

  return (
    <HStack alignItems="center">
      {showIcon && <Box spacingEnd={3}>{cardMedia}</Box>}
      {expanded ? (
        <HStack gap={3}>
          <TextHeadline as="h4">{title}</TextHeadline>
          <HStack alignItems="center">
            {hasInvalidCardOnFile && (
              // CDS Icon sets lineHeight to 1 instead of normal.
              // Need to add this padding for proper vertical alignment.
              <Box style={invalidCardStyle}>
                <Icon color="negative" size="s" name="error" spacingEnd={0.5} />
              </Box>
            )}
            <TextBody as="p" color={hasInvalidCardOnFile ? 'negative' : 'foregroundMuted'}>
              {detail}
            </TextBody>
          </HStack>
        </HStack>
      ) : (
        <VStack alignItems="flex-start">
          <TextHeadline as="h4">{title}</TextHeadline>
          <HStack alignItems="center">
            {hasInvalidCardOnFile && (
              // CDS Icon sets lineHeight to 1 instead of normal.
              // Need to add this padding for proper vertical alignment.
              <Box style={invalidCardStyle}>
                <Icon color="negative" size="s" name="error" spacingEnd={0.5} />
              </Box>
            )}
            <TextBody as="p" color={hasInvalidCardOnFile ? 'negative' : 'foregroundMuted'}>
              {detail}
            </TextBody>
          </HStack>
        </VStack>
      )}
    </HStack>
  );
}
