import { useMemo } from 'react';
import { Banner } from '@cbhq/cds-web/banner/Banner';
import { Button, ButtonGroup } from '@cbhq/cds-web/buttons';
import { Grid, HStack, VStack } from '@cbhq/cds-web/layout';
import { Pressable } from '@cbhq/cds-web/system';
import { Link, TextBody, TextDisplay3, TextTitle4 } from '@cbhq/cds-web/typography';

import { ReactComponent as RATHeaderImg } from ':cloud/assets/Products/RATHeader.svg';
import { useIsRATCreditBannerEnabled } from ':cloud/hooks/featureFlags/useIsRATCreditBannerEnabled';
import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import {
  ADVANCED_TRADE_UPGRADE_INFO_LINK,
  ADVANCED_TRADE_UPGRADE_LINK,
  ADVANCED_TRADING_DOCS_LINK,
  ADVANCED_TRADING_MARKETING_LINK,
} from ':cloud/utils/links';
import { onExternalLinkPress } from ':cloud/utils/onExternalLinkPress';
import { Layout } from ':cloud/widgets/layout';
import { FULL_PAGE_MAX_WIDTH, MAIN_CONTAINER_MIN_WIDTH } from ':cloud/widgets/product/constants';
import { KeyFeatureItem, KeyFeaturesSection } from ':cloud/widgets/product/KeyFeaturesSection';

const keyFeatures: KeyFeatureItem[] = [
  {
    iconName: 'usdtToUSDC',
    iconType: 'heroSquare',
    title: 'Deep liquidity',
    description:
      'Maximize your trades in 550+ markets, now with 237 new USDC pairs - all at competitive, volume-based fees.',
  },
  {
    iconName: 'futures',
    iconType: 'pictogram',
    title: 'Futures trading available in US',
    description:
      'Advanced Trade API supports trading for Coinbase Financial Markets (CFM) futures products.',
  },
  {
    iconName: 'recommendInvest',
    iconType: 'heroSquare',
    title: 'Trade perpetual futures',
    description: 'Perpetual futures trading is available for users in eligible regions.',
  },
  {
    iconName: 'timingCheck',
    iconType: 'pictogram',
    title: 'Real-time market data',
    description:
      'Utilize our Coinbase Advanced WebSocket server for the most up-to-date market data.',
  },
];

const IMAGE_STYLE = { height: 'auto', width: '510px' };
const MOBILE_IMAGE_STYLE = { height: 'auto', width: '100%', maxWidth: '510px' };

export function AdvancedTradeLanding() {
  const isRATCreditBannerEnabled = useIsRATCreditBannerEnabled();
  const { isDesktop } = useSimpleBreakpoints();

  const headerResponsiveTemplateColumns = useMemo(() => {
    return isDesktop ? '3fr 1fr' : '1fr';
  }, [isDesktop]);

  return (
    <Layout enableMaxWidth={false}>
      <Layout.MainContainer minWidth={MAIN_CONTAINER_MIN_WIDTH} spacingHorizontal={0} fullWidth>
        <VStack>
          {isRATCreditBannerEnabled && (
            <VStack>
              <VStack
                flexGrow={1}
                width="100%"
                maxWidth={FULL_PAGE_MAX_WIDTH}
                spacing={9}
                spacingBottom={0}
              >
                <Banner
                  title="Fast-track to as low as 0.0% maker fees on spot pairs"
                  variant="promotional"
                  startIcon="info"
                  primaryAction={
                    <Link to={ADVANCED_TRADE_UPGRADE_LINK} openInNewWindow>
                      Apply now
                    </Link>
                  }
                >
                  VIP at another exchange? Submit proof of your trading volume and get upgraded to
                  lower fees than you pay today on Coinbase Advanced.{' '}
                  <Pressable
                    background="transparent"
                    onPress={onExternalLinkPress(ADVANCED_TRADE_UPGRADE_INFO_LINK)}
                  >
                    <TextBody as="span" color="primary">
                      See offer details
                    </TextBody>
                  </Pressable>
                </Banner>
              </VStack>
            </VStack>
          )}
          <HStack borderedBottom>
            <Grid
              maxWidth={FULL_PAGE_MAX_WIDTH}
              spacing={9}
              gap={4}
              templateColumns={headerResponsiveTemplateColumns}
            >
              {!isDesktop && (
                <HStack>
                  <RATHeaderImg style={MOBILE_IMAGE_STYLE} />
                </HStack>
              )}
              <VStack justifyContent="center">
                <TextDisplay3 as="h1">An API for all your trading needs</TextDisplay3>
                <TextTitle4 as="h2" color="foregroundMuted" spacingTop={1} spacingBottom={7}>
                  Maximize your trading with Coinbase Advanced API. Automate your trades in minutes
                  on the most trusted crypto trading platform.{' '}
                  <Pressable
                    background="transparent"
                    onPress={onExternalLinkPress(ADVANCED_TRADING_MARKETING_LINK)}
                  >
                    <TextTitle4 as="span" color="primary">
                      Learn about Coinbase Advanced
                    </TextTitle4>
                  </Pressable>
                </TextTitle4>

                <ButtonGroup>
                  <Button
                    variant="primary"
                    endIcon="diagonalUpArrow"
                    onPress={onExternalLinkPress(ADVANCED_TRADING_DOCS_LINK)}
                    accessibilityLabel="View docs + opens in new window"
                  >
                    View docs
                  </Button>
                </ButtonGroup>
              </VStack>
              {isDesktop && (
                <HStack justifyContent="flex-end" height={262}>
                  <RATHeaderImg style={IMAGE_STYLE} />
                </HStack>
              )}
            </Grid>
          </HStack>
          <VStack>
            {/* TODO: will likely bring this back later */}
            {/* <VStack maxWidth={FULL_PAGE_MAX_WIDTH} spacing={10} gap={5} flexGrow={1} width="100%">
                <VStack>
                  <TextTitle3 as="h1">Configuration</TextTitle3>
                </VStack>
                <ConfigurationItem
                  title="Create API key"
                  description="An API key is required to authenticate your account and make requests. Configure your advanced settings and permissions."
                  iconName="api"
                  cta={projectKeys?.length ? 'Manage' : 'Setup'}
                  onPress={handleSetupAPIKeyPress}
                />
                <ConfigurationItem
                  title="Make your first API request"
                  description="Learn how to make your first API request through our Advanced Trade API tech docs."
                  iconName="arrowsHorizontal"
                  cta="View docs"
                  onPress={handleSendRequestPress}
                />
              </VStack> */}
          </VStack>

          <KeyFeaturesSection features={keyFeatures} />
        </VStack>
      </Layout.MainContainer>
    </Layout>
  );
}
