import { useQuery } from '@tanstack/react-query';

import { APIClientBase } from ':cloud/state/Api';
import { GetWebhooksResponse } from ':cloud/types/service_proto';

async function getWebhooks(orgId: string, projectId: string): Promise<GetWebhooksResponse> {
  const response = await APIClientBase.get<GetWebhooksResponse>(
    `/webhook/v1/organizations/${orgId}/projects/${projectId}/webhooks`,
  );
  return response.data;
}

export function useGetWebhooks(orgId: string, projectId: string) {
  return useQuery<GetWebhooksResponse, Error>({
    queryKey: ['webhooks', orgId, projectId],
    queryFn: async () => getWebhooks(orgId, projectId),
  });
}
