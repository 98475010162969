import { AjvError, FormValidation } from '@rjsf/core';

import { logError } from ':cloud/init/bugsnag/logging';
import { IFormDataType, IWorkflow } from ':cloud/types/ts_types';

interface Base64ValidationResult {
  isValid: boolean;
  errMsg?: string;
}
function getBase64Validation(
  formData: IFormDataType,
  prop: { decodedLength: number },
  propKeyName: keyof IFormDataType,
): Base64ValidationResult {
  const formValue = formData[propKeyName] as string | undefined;
  if (!formValue) {
    return { isValid: true };
  }
  try {
    // String length must be Math.floor((decodedLen + 2) / 3) * 4
    // Base64 encoding uses 4 characters to encode 3 bytes (24 bits)
    // '=' characters are used to pad if necessary
    const requiredLen = Math.floor((prop.decodedLength + 2) / 3) * 4;
    const bytes = atob(formValue);
    if (
      bytes &&
      (!prop.decodedLength ||
        (prop.decodedLength === bytes.length && formValue.length === requiredLen))
    ) {
      return { isValid: true };
    }
  } catch (e) {
    logError(e, { context: 'custom_wizard_form' });
  }
  if (typeof prop.decodedLength === 'number' && prop.decodedLength > 0) {
    return {
      isValid: false,
      errMsg: `Value must be a valid base64-encoded string representing a ${prop.decodedLength} byte value.`,
    };
  }
  return {
    isValid: false,
    errMsg: `Value must be a valid base64-encoded string.`,
  };
}

function namesMatch(name: string, userDefinedClusterNames: string[]): boolean {
  return userDefinedClusterNames.some((clusterName) => clusterName === name);
}

export function customValidation(
  form: IFormDataType,
  selectedWorkflow: IWorkflow,
  userDefinedClusterNames: string[],
  errors: FormValidation,
): FormValidation {
  Object.entries(selectedWorkflow.formSchema.properties).forEach(([propKeyName, prop]) => {
    if (prop.type === 'string' && prop?.encoding === 'base64') {
      const { isValid, errMsg } = getBase64Validation(
        form,
        prop,
        propKeyName as keyof IFormDataType,
      );

      if (!isValid) {
        errors[propKeyName].addError(errMsg ?? 'An error occured validating the data');
      }
    }

    if (
      prop.type === 'string' &&
      propKeyName === 'name' &&
      form?.name &&
      namesMatch(form.name, userDefinedClusterNames)
    ) {
      errors[propKeyName].addError(
        `You already have a ${selectedWorkflow.display} cluster with this name. Choose another name.`,
      );
    }
  });

  return errors;
}

function getPatternError(property: string): string {
  let message = '';
  switch (property) {
    case '.name':
      message =
        'Cluster name must start with a letter and only contain letters, numbers and dashes. No spaces allowed.';
      break;
    case '.poolCost':
      message = 'Pool Cost must be maximum of 7 decimals';
      break;
    case '.poolPledge':
      message = 'Pool Pledge must be maximum of 7 decimals';
      break;
    default:
      break;
  }
  return message;
}

export function transformErrors(errors: AjvError[], selectedWorkflow: IWorkflow): AjvError[] {
  const {
    formSchema: { properties: schemaProperties },
  } = selectedWorkflow;

  return errors.map(
    (err: { name: string; property: string; message: string; params: any; stack: string }) => {
      // for some reason react-jsonschema-form@^3.0.0 returns a name of the key with dot
      const propertyName = err.property.replace('.', '');
      const schemaProperty = schemaProperties?.[propertyName];
      let message;

      switch (err.name) {
        case 'pattern':
          message =
            schemaProperty?.message ||
            getPatternError(err.property) ||
            'Data is not in the correct format';
          break;
        case 'required':
          message = schemaProperty?.message || 'This field is required.';
          break;
        default:
          message = err.message;
      }
      return {
        ...err,
        message,
      };
    },
  );
}
