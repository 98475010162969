import { css } from '@linaria/core';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { TextLabel2 } from '@cbhq/cds-web/typography';

import Charmander from ':cloud/assets/Favicons/dev.png';
import Pokemon from ':cloud/assets/Favicons/local.png';
import Charmeleon from ':cloud/assets/Favicons/staging.png';
import config from ':cloud/config';

const styles = {
  wrapper: css`
    border-bottom: 2px solid rgb(var(--yellow30));
  `,
};

type Env = 'staging' | 'dev' | 'local';
type BannerConfigType = Record<
  Env,
  {
    title: string;
    iconSrc: string;
  }
>;

function EnvBannerContent() {
  const isMocksEnabled = config.isMocksEnabled();

  const bannerEnvConfig: BannerConfigType = {
    local: {
      title: 'Local',
      iconSrc: Pokemon,
    },
    dev: {
      title: isMocksEnabled ? 'Dev + mocks' : 'Dev',
      iconSrc: Charmander,
    },
    staging: {
      title: 'Staging',
      iconSrc: Charmeleon,
    },
  };
  const env = process.env.REACT_APP_API || 'local';
  const { title, iconSrc } = bannerEnvConfig[env];
  return (
    <VStack testID="banner" background="background" className={styles.wrapper}>
      <HStack spacingVertical={1} spacingHorizontal={8}>
        <img src={iconSrc} alt={`favicon-${env}`} />
        <TextLabel2 spacingHorizontal={2} as="span">
          {(title as string).toUpperCase()}
        </TextLabel2>
      </HStack>
    </VStack>
  );
}

export function EnvBanner() {
  if (config.isProduction()) {
    return null;
  }
  return <EnvBannerContent />;
}
