import { useEffect } from 'react';
import { zIndex } from '@cbhq/cds-common/tokens/zIndex';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { deviceBreakpoints } from '@cbhq/cds-web/layout/breakpoints';
import { Pressable } from '@cbhq/cds-web/system';

import { useScrollBlock } from ':cloud/hooks/useScrollBlock';

const drawerStyle = { transition: '0.15s' };

type DrawerProps = {
  children: React.ReactNode;
  open: boolean;
  closeDrawer: () => void;
};

export function MobileDrawer({ children, open, closeDrawer }: DrawerProps) {
  // prevent background from scrolling when open
  const { blockScroll, allowScroll } = useScrollBlock();

  useEffect(() => {
    if (open) {
      blockScroll();
    } else {
      allowScroll();
    }
  }, [allowScroll, blockScroll, open]);

  return (
    <VStack
      position="fixed"
      width="100%"
      top={0}
      bottom={0}
      // use tablet to ensure it's fully offscreen
      left={open ? 0 : -deviceBreakpoints.tablet}
      background
      style={drawerStyle}
      zIndex={zIndex.overlays.portal}
      spacing={3}
      overflow="scroll"
      testID="mobile-drawer"
    >
      {/* close button */}
      <Pressable onPress={closeDrawer} background="transparent" as="button">
        <Box position="absolute" top={-16} right={-16} spacing={2}>
          <Icon name="close" size="s" color="foreground" testID="close-icon" />
        </Box>
      </Pressable>
      {children}
    </VStack>
  );
}
