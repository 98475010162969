import { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

import config from ':cloud/config';

let stripePromise: Promise<Stripe | null>;
/* stripe singleton to load only once */
export async function getStripe(): Promise<Stripe | null> {
  // ensuring promise is only created once, taken from Stripe docs
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  if (!stripePromise) {
    /*
     * if this is not here, Stripe makes POST requests on each page load to
     * send user data back to its servers
     * https://stackoverflow.com/questions/45718026/stripe-js-making-duplicate-requests-new-requests-on-state-change
     */
    loadStripe.setLoadParameters({ advancedFraudSignals: false });
    stripePromise = loadStripe(config.stripeKey);
  }
  return stripePromise;
}
