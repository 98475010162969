import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { CreateAccountItemRequest, CreateAccountItemResponse } from ':cloud/types/service_proto';

async function createAccountItem(
  { productVariantId, projectId }: CreateAccountItemRequest,
  orgId: string,
): Promise<CreateAccountItemResponse> {
  const path = `/billing/v1/accounts/${orgId}/items`;
  const { data } = await APIClientBase.post<CreateAccountItemResponse>(path, {
    productVariantId,
    projectId,
  });
  return data;
}

export function useCreateAccountItem(orgId = '') {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ productVariantId, projectId }: CreateAccountItemRequest) =>
      createAccountItem({ productVariantId, projectId }, orgId),
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKey.accounts, QueryKey.accountItems, orgId]);
    },
  });
}
