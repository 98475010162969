import { memo, ReactNode, useCallback, useContext, useMemo } from 'react';
import { useLocation } from 'react-router';
import { ResponsiveProps } from '@cbhq/cds-web';
import { Button, IconButton } from '@cbhq/cds-web/buttons';
import { NavigationIcon } from '@cbhq/cds-web/icons';
import { Box, HStack } from '@cbhq/cds-web/layout';
import { NavigationTitle } from '@cbhq/cds-web/navigation/NavigationTitle';
import { ThemeProvider } from '@cbhq/cds-web/system/ThemeProvider';
import { Tag } from '@cbhq/cds-web/tag/Tag';
import { NavigationBar as IDNavigationBar } from '@cbhq/identity-navigation';

import config from ':cloud/config';
import { useAppState } from ':cloud/contexts/AppStateContext';
import { useIdentityTarget } from ':cloud/hooks/useIdentityTarget';
import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { CLOUD_DOCS, CLOUD_FORUM, COINBASE_CAREERS } from ':cloud/utils/links';
import { onExternalLinkPress } from ':cloud/utils/onExternalLinkPress';
import { AppRoute } from ':cloud/utils/routes';
import { SessionContext } from ':cloud/utils/SessionProvider';
import { HamburgerNav } from ':cloud/widgets/navigation/HamburgerNav';
import { NavTitle } from ':cloud/widgets/navigation/NavTitle';
import { NotificationsDropdown } from ':cloud/widgets/navigation/NotificationsDropdown';

const earlyAccessPages = [AppRoute.Products.Staking] as string[];

type NavigationBarProps = {
  showBackButton: boolean;
  handleBackPress: () => void;
  bottomContent: ReactNode;
  title?: string;
  titleTag?: string;
};

const responsiveConfigEndContent: ResponsiveProps = {
  phone: { spacingEnd: 0 },
  tablet: { spacingBottom: 0 },
  desktop: { spacingBottom: 0 },
};

const responsiveConfigNavBar: ResponsiveProps = {
  phone: { spacingStart: 0.5 },
  tablet: { spacingBottom: 0 },
  desktop: { spacingBottom: 0 },
};

export const NavigationBar = memo(
  ({ showBackButton, handleBackPress, bottomContent, title }: NavigationBarProps) => {
    const { isPhone } = useSimpleBreakpoints();

    const { isDarkMode, toggleDarkMode } = useAppState();

    const location = useLocation();
    const isPageEarlyAccess = earlyAccessPages.includes(location.pathname);

    const { setLocationCookie } = useContext(SessionContext);

    const startContent = useMemo(
      () =>
        showBackButton && (
          <Box spacingHorizontal={2}>
            <IconButton name="backArrow" onPress={handleBackPress} />
          </Box>
        ),
      [handleBackPress, showBackButton],
    );

    const endContent = useMemo(
      () => (
        <HStack alignItems="center" responsiveConfig={responsiveConfigEndContent} gap={1}>
          <HStack>
            <Button
              onPress={onExternalLinkPress(COINBASE_CAREERS)}
              transparent
              variant="secondary"
              compact
              accessibilityLabel="Explore careers + opens in new window"
            >
              <HStack display="flex" gap={1} alignItems="center">
                <NavigationIcon name="coinbase" size="s" />
                We&apos;re hiring
              </HStack>
            </Button>
            <Button
              onPress={onExternalLinkPress(CLOUD_DOCS)}
              transparent
              variant="secondary"
              startIcon="smartContract"
              compact
              accessibilityLabel="View docs + opens in new window"
            >
              Docs
            </Button>
            <Button
              onPress={onExternalLinkPress(CLOUD_FORUM)}
              transparent
              variant="secondary"
              compact
              startIcon="discordLogo"
              accessibilityLabel="Discord channel + opens in new window"
            >
              Get help
            </Button>
          </HStack>
          <NotificationsDropdown buttonVariant />
        </HStack>
      ),
      [],
    );

    const handleBeforeNavigate = useCallback(
      (url: URL) => {
        if (url.href === config.logoutURL) {
          setLocationCookie();
        }
      },
      [setLocationCookie],
    );

    const navigationTitle = useMemo(() => {
      if (isPhone) {
        return (
          <HStack width="100%" justifyContent="center">
            {/* @ts-expect-error -- error says this component requires >1 child 🤷  */}
            <NavigationTitle align="start" overflow="break" numberOfLines={2}>
              {title}
            </NavigationTitle>
          </HStack>
        );
      }

      return (
        <HStack gap={2} alignItems="center">
          <NavTitle value={title || ''} />
          {isPageEarlyAccess && <Tag>Early access</Tag>}
        </HStack>
      );
    }, [isPageEarlyAccess, isPhone, title]);

    const content = useMemo(() => {
      /* increase hit box on mobile */
      return (
        <HStack
          alignItems="center"
          justifyContent="flex-start"
          responsiveConfig={responsiveConfigNavBar}
          width="100%"
          gap={isPhone ? 1 : 5}
        >
          {isPhone && <HamburgerNav />}
          {navigationTitle}
        </HStack>
      );
    }, [isPhone, navigationTitle]);

    const target = useIdentityTarget();
    const navBarConfig = useMemo(
      () => ({
        loginUrl: config.loginURL ?? 'https://cloud-api.coinbase.com/login',
      }),
      [],
    );

    return (
      <ThemeProvider scale="large">
        <IDNavigationBar
          start={startContent}
          end={endContent}
          bottom={bottomContent}
          action="web-CloudConsole-NavigationBar"
          spacingHorizontal={isPhone ? 2 : 4}
          projectId={config.appSwitcherProjectId}
          target={target}
          onThemeToggle={toggleDarkMode}
          activeThemeLabel={isDarkMode ? 'Dark' : 'Light'}
          themeToggleValue={isDarkMode ? 'dark' : 'light'}
          onBeforeNavigate={handleBeforeNavigate}
          config={navBarConfig}
          isUserSwitcher
        >
          {content}
        </IDNavigationBar>
      </ThemeProvider>
    );
  },
);
