import { Box, VStack } from '@cbhq/cds-web/layout';
import {
  Link,
  TextBody,
  TextHeadline,
  TextTitle1,
  TextTitle2,
  TextTitle3,
} from '@cbhq/cds-web/typography';

export function BaseNodeTermsOfService() {
  return (
    <Box display="block" spacing={9} maxWidth="100%">
      <VStack alignItems="center" gap={5} spacingBottom={7}>
        <VStack alignItems="center" gap={3}>
          <TextTitle1 as="h1">COINBASE, INC.</TextTitle1>
          <TextTitle2 as="h2">
            Coinbase Developer Platform Paymaster/ Bundler Product Agreement
          </TextTitle2>
        </VStack>
        <VStack width={760} maxWidth="100%">
          <TextBody as="p">
            This{' '}
            <TextHeadline as="span">
              Coinbase Developer Platform Paymaster/ Bundler Product Agreement
              (&ldquo;Paymaster/BundlerProduct&rdquo;)
            </TextHeadline>
            (the <TextHeadline as="span">&ldquo;Agreement&rdquo;</TextHeadline>) is entered into by
            and between Coinbase, Inc. (
            <TextHeadline as="span">&ldquo;Coinbase&rdquo;</TextHeadline>,{' '}
            <TextHeadline as="span">&ldquo;us&rdquo;</TextHeadline>, or{' '}
            <TextHeadline as="span">&ldquo;we&rdquo;</TextHeadline>) and you (
            <TextHeadline as="span">&ldquo;you&rdquo;</TextHeadline>). This Agreement is effective
            as of the date you click the &ldquo;Submit&rdquo; button to accept the terms herein (the{' '}
            <TextHeadline as="span">&ldquo;Effective Date&rdquo;</TextHeadline>). You agree to the
            following:
          </TextBody>

          <TextTitle3 as="h3" spacingTop={5} spacingBottom={2}>
            1. Product Overview
          </TextTitle3>
          <TextBody as="p" spacingBottom={2}>
            Coinbase is launching Bundler and Paymaster services to developers in the Base
            ecosystem. The Bundler submits transactions (known as &apos;user operations&apos;) from
            smart accounts for transaction inclusion on the Base network. The Paymaster allows
            developers to sponsor the gas of their end user&apos;s user operations. Gas credits will
            be extended to Coinbase Developer Platform customers, intended to cover some initial
            amount of sponsorship cost.
          </TextBody>
          <TextBody as="p">
            This Agreement outlines the terms under which Coinbase will deliver the Minimum Viable
            Product (MVP) of the Bundler & Paymaster to you. The MVP is defined as the earliest
            version of the Bundler & Paymaster that includes just enough features to be usable by
            early customers who can then provide feedback for future product development. In
            addition to the commitments set forth herein, this MVP launch is intended to validate
            the Bundler & Paymaster concept, gather user feedback, and identify priorities for
            further development.
          </TextBody>

          <TextTitle3 as="h3" spacingTop={5} spacingBottom={2}>
            2. License Grant
          </TextTitle3>
          <TextBody as="p">
            Coinbase grants you a gratis, limited license in accordance with the instructions
            provided by Coinbase in writing, to use the Coinbase Developer Platform
            Paymaster/Bundler Product as part of the Coinbase Developer Platform Tools, subject to
            the Coinbase Developer Platform Terms of Service (located at{' '}
            <Link>www.coinbase.com/legal/cloud/terms-of-service</Link>) ({' '}
            <TextHeadline as="span">Coinbase Developer Platform TOS”</TextHeadline>), which are
            agreed to by you and incorporated herein by reference.
          </TextBody>

          <TextTitle3 as="h3" spacingTop={5} spacingBottom={2}>
            3. Definitions
          </TextTitle3>
          <VStack gap={1} spacingStart={4}>
            <TextBody as="p">
              <TextHeadline as="span">3.1. Base </TextHeadline>Base is a layer-2 blockchain
              developed by Coinbase Technologies, Inc., and built on Ethereum.{' '}
            </TextBody>
            <TextBody as="p">
              <TextHeadline as="span">3.2. Bundlers </TextHeadline>Bundlers are a type of Ethereum
              node that supports UserOperations, instead, (or in addition to) typical Ethereum
              transactions.
            </TextBody>
            <TextBody as="p">
              <TextHeadline as="span">3.3. Dapp </TextHeadline>Decentralized application or programs
              that operate on a blockchain or peer-to-peer network instead of a single computer.
            </TextBody>
            <TextBody as="p">
              <TextHeadline as="span">3.4. Documentation </TextHeadline>Documentation includes but
              is not limited to manuals, specifications, and any materials describing the
              functionality, features, operating characteristics, and use of the Services as
              provided by Coinbase.
            </TextBody>
            <TextBody as="p">
              <TextHeadline as="span">3.5. Gas Credits </TextHeadline>Gas credits refer to ETH
              provided to you via the Paymaster, allowing you to sponsor gas fees on behalf of
              another user or entity.
            </TextBody>
            <TextBody as="p">
              <TextHeadline as="span">3.6. Paymaster </TextHeadline>A paymaster is a mechanism
              responsible for handling the payment of transaction fees. A paymaster might act as a
              facilitator for transactions, ensuring that the necessary fees are paid and the
              transaction is correctly processed on the blockchain. In this context, the team would
              like to use the paymaster to pay for the gas fees (transaction fees) on behalf of
              users for a select amount of DApps.
            </TextBody>
            <TextBody as="p">
              <TextHeadline as="span">3.7. User Operations </TextHeadline>A UserOperation is an
              onchain transaction submitted to the Bundler by your Dapp.
            </TextBody>
          </VStack>

          <TextTitle3 as="h3" spacingTop={5} spacingBottom={2}>
            4. Restrictions
          </TextTitle3>
          <VStack gap={1} spacingStart={4}>
            <TextBody as="p">
              <TextHeadline as="span">4.1.</TextHeadline>You may only use and access the Coinbase
              Developer Platform Paymaster/Bundler Product as permitted by this Agreement and
              Documentation, subject to the Prohibited Use Policy located at{' '}
              <Link>https://www.coinbase.com/legal/prohibited_use</Link>.
            </TextBody>
          </VStack>

          <TextTitle3 as="h3" spacingTop={5} spacingBottom={2}>
            5. Digital Asset Control and Ownership
          </TextTitle3>
          <TextBody as="p">
            You acknowledge and agree Digital Asset control, title, and ownership shall at all times
            remain with you or your end users, and shall not transfer to Coinbase, as outlined and
            further noted in Section 6 of the Coinbase Developer Platform TOS. For the avoidance of
            doubt, use of Coinbase Developer Platform Paymaster/Bundler Product does not involve or
            create any type of custodial relationship between you or your end users and Coinbase.
          </TextBody>

          <TextTitle3 as="h3" spacingTop={5} spacingBottom={2}>
            6. Additional License Terms and Representation and Warranties
          </TextTitle3>
          <TextBody as="p" spacingBottom={2}>
            By using the Coinbase Developer Platform Paymaster/Bundler Product, you agree to the
            following terms in addition to the license terms and representations and warranties as
            set forth in the Coinbase Developer Platform TOS:
          </TextBody>

          <VStack gap={1} spacingStart={4}>
            <TextBody as="p">
              <TextHeadline as="span">6.1.</TextHeadline> You shall only use and access the Coinbase
              Developer Platform Paymaster/Bundler Product as permitted by this Agreement, the
              Documentation and for your own internal business purposes.
            </TextBody>
            <TextBody as="p">
              <TextHeadline as="span">6.2.</TextHeadline> You agree that Coinbase is not responsible
              for the loss of any funds, or loss in value of any funds or assets related to the use
              of the Coinbase Developer Platform Paymaster/Bundler Product.
            </TextBody>
            <TextBody as="p">
              <TextHeadline as="span">6.3.</TextHeadline> You shall not license the Coinbase
              Developer Platform Paymaster/Bundler Product or any materials derived from the
              Coinbase Developer Platform Paymaster/Bundler Product to any third party.
            </TextBody>
            <TextBody as="p">
              <TextHeadline as="span">6.4.</TextHeadline> You acknowledge and understand that the
              Coinbase Developer Platform Paymaster/Bundler Product is an MVP, and may be incomplete
              or could contain errors, bugs, or inaccuracies. As such, you acknowledge and
              understand that any assets used in connection with the Coinbase Developer Platform
              Paymaster/Bundler Product may be permanently lost.
            </TextBody>
            <TextBody as="p">
              <TextHeadline as="span">6.5.</TextHeadline> Coinbase shall not be responsible for any
              costs, expenses, or other liabilities you may incur as a result of using the Coinbase
              Developer Platform Paymaster/Bundler Product, including but not limited to any damage,
              loss, or corruption of any software, information, or data.
            </TextBody>
          </VStack>

          <TextTitle3 as="h3" spacingTop={5} spacingBottom={2}>
            7. You understand, agree, acknowledge, represent and warrant that:
          </TextTitle3>
          <VStack gap={1} spacingStart={4}>
            <TextBody as="p">
              <TextHeadline as="span">7.1.</TextHeadline> Your end-users are your customers, not of
              Coinbase who does not maintain accounts or relationships with them;
            </TextBody>
            <TextBody as="p">
              <TextHeadline as="span">7.2.</TextHeadline> You are solely responsible for
              implementing policies, procedures and controls, for your end users and their use of
              the Coinbase Developer Platform Paymaster/Bundler product, that are reasonably
              designed to comply with applicable anti-money laundering, counter-terrorist financing
              or governmental sanctions, including, but not limited to those implemented by the U.S.
              Department of the Treasury’s Office of Foreign Assets Control (“
              <TextHeadline as="span">OFAC</TextHeadline>”) (collectively, “
              <TextHeadline as="span">AML and Sanctions Laws</TextHeadline>”) and, in furtherance of
              Section 4 of the Coinbase Developer Platform TOS, will not rely on Coinbase to fulfill
              any such obligations;
            </TextBody>
            <TextBody as="p">
              <TextHeadline as="span">7.3.</TextHeadline> You have implemented similar policies,
              procedures and controls to ensure any sub-contractors, vendors, suppliers or any other
              third party acting on the your behalf that use the Coinbase Developer Platform
              Paymaster/Bundler Product will do so in a manner that complies with applicable AML and
              Sanctions Law;
            </TextBody>
            <TextBody as="p">
              <TextHeadline as="span">7.4.</TextHeadline> You will perform all legally necessary due
              diligence on its end users prior to, and will perform ongoing due diligence of the end
              users as required by applicable AML and Sanctions laws; and
            </TextBody>
            <TextBody as="p">
              <TextHeadline as="span">7.5.</TextHeadline> You will not instruct or otherwise cause
              Coinbase to engage in any transactions that will cause Coinbase to violate applicable
              laws and regulations, including applicable AML and Sanctions Laws, and will indemnify
              Coinbase for any penalties incurred by Coinbase for doing so.
            </TextBody>
          </VStack>

          <TextHeadline as="p" spacingVertical={7}>
            BY CLICKING THE “ACTIVATE” BUTTON, YOU AFFIRM READING AND UNDERSTANDING THIS AGREEMENT
            AND AGREE TO BE BOUND BY THE TERMS AND CONDITIONS CONTAINED HEREIN. FURTHER, YOU ARE AN
            AUTHORISED SIGNER AND REPRESENT THAT YOU ARE DULY AUTHORISED TO BE BOUND BY THIS
            AGREEMENT AND TO EXECUTE THE AGREEMENT.
          </TextHeadline>
        </VStack>
      </VStack>
    </Box>
  );
}
