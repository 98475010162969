import { useCallback, useEffect, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import { useToggler } from '@cbhq/cds-common/hooks/useToggler';
import { Button, ButtonGroup } from '@cbhq/cds-web/buttons';
import { Collapsible } from '@cbhq/cds-web/collapsible/Collapsible';
import { TextInput } from '@cbhq/cds-web/controls';
import { Icon, IconProps } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { palette } from '@cbhq/cds-web/tokens';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

type Inputs = {
  value: string;
};

type EditableConfigValueProps = {
  name: string;
  isLoading: boolean;
  submitActionLabel: string;
  iconName: IconProps['name'];
  resetDefaultValueOnEdit?: boolean;
  openFormIfNoValueSet?: boolean;
  disabled?: boolean;
  validationSchema?: ObjectSchema<any>;
  value?: string;
  placeholder?: string;
  helperText?: string;
  onSubmit: (value: string, onSettled?: () => void) => void;
};

export function EditableConfigValue({
  disabled = false,
  openFormIfNoValueSet = false,
  resetDefaultValueOnEdit = false,
  submitActionLabel = 'Save',
  validationSchema,
  name,
  isLoading,
  iconName,
  value,
  placeholder,
  helperText,
  onSubmit,
}: EditableConfigValueProps) {
  const [isFormEditable, { toggle, toggleOn }] = useToggler();
  const wasLoading = useRef<boolean>(isLoading);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { isSubmitting, errors },
    reset,
  } = useForm<Inputs>({
    defaultValues: resetDefaultValueOnEdit ? { value: '' } : { value },
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
  });

  // The form is initially rendered even if the data isn't loaded.
  // When we transition from loading to loaded, we reset the form's initial values.
  useEffect(() => {
    if (wasLoading && !isLoading) {
      reset({ value });

      // If configured to open the form if no value set, the form is not disabled,
      // and no value is set, then we toggle the form open as a call to action.
      if (openFormIfNoValueSet && !disabled && !value) {
        toggleOn();
      }
    }
  }, [reset, disabled, toggleOn, wasLoading, isLoading, value, openFormIfNoValueSet]);

  const onFormSubmit: SubmitHandler<Inputs> = useCallback(() => {
    onSubmit(getValues('value'), toggle);
  }, [getValues, onSubmit, toggle]);

  const submitDisabled = isSubmitting || Boolean(Object.keys(errors).length);

  return (
    <VStack bordered borderRadius="rounded" spacing={4}>
      <HStack justifyContent="space-between">
        <HStack alignItems="center" gap={2}>
          <Box
            dangerouslySetBackground={palette.foreground}
            height={24}
            width={24}
            borderRadius="roundedFull"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Icon name={iconName} size="s" color="primaryForeground" />
          </Box>
          <VStack>
            <TextHeadline as="h1">{name}</TextHeadline>
            {!isFormEditable && (
              <TextBody as="p" color="foregroundMuted">
                {value}
              </TextBody>
            )}
          </VStack>
        </HStack>
        {!isFormEditable && (
          <Button variant="secondary" onPress={toggle} disabled={disabled}>
            Edit
          </Button>
        )}
      </HStack>
      <Collapsible collapsed={!isFormEditable}>
        <form style={{ width: '100%' }} onSubmit={handleSubmit(onFormSubmit)}>
          <HStack width="100%" gap={6} spacingStart={8} spacingTop={1}>
            <VStack width="100%">
              <TextInput
                {...register('value')}
                spellCheck={false}
                defaultValue={getValues('value')}
                placeholder={placeholder}
                helperText={helperText}
              />
              {errors.value && (
                <TextBody color="negative" as="p" spacingTop={1}>
                  {errors.value.message}
                </TextBody>
              )}
            </VStack>
            <Box>
              <ButtonGroup accessibilityLabel="Org actions">
                <Button variant="secondary" type="submit" disabled={submitDisabled}>
                  {submitActionLabel}
                </Button>
              </ButtonGroup>
            </Box>
          </HStack>
        </form>
      </Collapsible>
    </VStack>
  );
}
