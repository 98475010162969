import { useEffect, useState } from 'react';
import _compact from 'lodash/compact';
import _groupBy from 'lodash/groupBy';
import { ResponsiveProps } from '@cbhq/cds-common';
import { Box, Divider, VStack } from '@cbhq/cds-web/layout';
import { TextTitle1, TextTitle2 } from '@cbhq/cds-web/typography';

import { useNewCluster } from ':cloud/contexts/NewClusterContext';
import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { useGetResources } from ':cloud/queries/ResourceQueries/useGetResources';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { useGetUserPermissions } from ':cloud/queries/UserQueries/useGetUserPermissions';
import { ICluster, ProtocolKey } from ':cloud/types/ts_types';
import { ClusterSearch } from ':cloud/widgets/clusters/ClusterSearch';
import { ClustersSection } from ':cloud/widgets/clusters/ClustersSection';
import { HelpPanel } from ':cloud/widgets/help/HelpPanel';
import { Layout } from ':cloud/widgets/layout';
import { ProtocolList } from ':cloud/widgets/participate/ProtocolList';
import { LoadingSpinner } from ':cloud/widgets/sharedcomponents';
import { CloudErrorBoundary } from ':cloud/widgets/sharedcomponents/CloudErrorBoundary';
import { DropdownWithSearchOption } from ':cloud/widgets/sharedcomponents/DropdownWithSearch';

function getHeaderCopy(activeClusters: number, name: string): string {
  if (activeClusters === 0) {
    return name ? `Hi ${name}! Let's create a cluster` : `Hi! Let's create a cluster`;
  }
  return `My clusters`;
}

const headingResponsiveConfig: ResponsiveProps = {
  phone: {
    flexDirection: 'column',
  },
  tablet: {
    flexDirection: 'column',
  },
  desktop: {
    flexDirection: 'row',
    alignItems: 'center',
  },
};

export function ParticipateDashboard() {
  const { reset } = useNewCluster();
  const { isPhone, isDesktop } = useSimpleBreakpoints();
  const { resources, isLoading: isLoadingResources } = useGetResources(
    (r: ICluster) => r.clusterType === 'participation',
  );

  const { activeOrg } = useGetUser();
  const { permissions } = useGetUserPermissions(activeOrg?.organizationId);
  const { user } = useGetUser();

  const [searchQuery, setSearchQuery] = useState<DropdownWithSearchOption>();
  const [headerCopy, setHeaderCopy] = useState(getHeaderCopy(0, user?.name));

  const protocolMappedClusters: Record<ProtocolKey, ICluster[]> = _groupBy(resources, 'type');
  const showClusterArticles = resources.length > 0 ? 'yes' : 'no';

  useEffect(() => {
    /* clear persisted formData state */
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoadingResources) {
      setHeaderCopy(getHeaderCopy(resources.length, user.name));
    }
  }, [user.name, isLoadingResources, resources.length]);

  const handleSelectOption = (search: DropdownWithSearchOption) => {
    setSearchQuery(search);
  };

  if (isLoadingResources) {
    return <LoadingSpinner />;
  }

  return (
    <Layout>
      <Layout.MainContainer fullWidth>
        <Layout.ContentWrapper>
          {!!resources.length && (
            <Box
              responsiveConfig={headingResponsiveConfig}
              justifyContent="space-between"
              flexGrow={1}
            >
              {!isPhone ? (
                <TextTitle2 as="h1" spacingBottom={!isDesktop ? 1 : 0}>
                  {headerCopy}
                </TextTitle2>
              ) : null}
              <VStack minWidth={isPhone ? '100%' : 600}>
                <ClusterSearch
                  clusters={resources}
                  selectedOption={searchQuery}
                  onSelectOption={handleSelectOption}
                />
              </VStack>
            </Box>
          )}
          <Divider spacingTop={5} />
        </Layout.ContentWrapper>
        {/* Running Clusters */}
        {resources.length > 0 && (
          <Layout.ContentWrapper>
            <ClustersSection
              activeClusters={resources}
              protocolMappedClusters={protocolMappedClusters}
              query={searchQuery}
            />
          </Layout.ContentWrapper>
        )}
        {/* create new participate cluster */}
        {permissions.manageResources && (
          <Layout.ContentWrapper>
            <TextTitle1 as="h2" spacingBottom={7}>
              Create a new cluster
            </TextTitle1>
            <CloudErrorBoundary name="ProtocolList">
              <ProtocolList clusterType="participation" />
            </CloudErrorBoundary>
          </Layout.ContentWrapper>
        )}
        <Layout.ContentWrapper>
          <HelpPanel labels={_compact(['participation', showClusterArticles])} />
        </Layout.ContentWrapper>
      </Layout.MainContainer>
    </Layout>
  );
}
