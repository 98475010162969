import { useCallback, useEffect, useRef, useState } from 'react';
import { CookieBanner } from '@coinbase/cookie-banner';
import {
  Provider as CookieManagerProvider,
  Region,
  TrackingCategory,
  TrackingPreference,
} from '@coinbase/cookie-manager';

import config from ':cloud/config';
import { config as cookieManagerConfig } from ':cloud/config/cookieManager';
import { logError } from ':cloud/init/bugsnag/logging';
import { GenericProviderProps } from ':cloud/types/ts_types';
import { CookiePreferences } from ':cloud/utils/CookiePreferences';
import { isGdprCountry } from ':cloud/utils/privacyHelpers/europe';
import { getRegionFromLocaleCode } from ':cloud/utils/privacyHelpers/getRegionFromLocaleCode';

function handleError(error: Error): void {
  if (!config.isProduction()) {
    // eslint-disable-next-line no-console -- Not in prod
    console.error(error);
  }
  logError(error, { context: 'cookie_provider' });
}

function handleLog(str: string): void {
  if (!config.isProduction()) {
    // eslint-disable-next-line no-console -- Not in prod
    console.log(str);
  }
}

export function CookieProvider({ children }: GenericProviderProps) {
  const locale = navigator.language;
  const region = getRegionFromLocaleCode(locale);

  const [isMounted, setIsMounted] = useState(false);
  const trackingPreference = useRef<TrackingPreference | undefined>();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const setTrackingPreference = useCallback((newPreference: TrackingPreference) => {
    const priorConsent = trackingPreference.current?.consent;
    trackingPreference.current = newPreference;

    if (!priorConsent) {
      // The first time the modal appears, this function is called with nothing present in
      // trackingPreference.current. To avoid an infinite refresh loop, we return early on
      // the first call.
      return;
    }

    const newConsent = newPreference.consent;

    // Best only to reload if the preferences have actually changed.
    const diff = [
      ...priorConsent.filter((elem: TrackingCategory) => !newConsent.includes(elem)),
      ...newConsent.filter((elem: any) => !priorConsent.includes(elem)),
    ];

    // reload if the preferences have changed
    if (diff.length > 0) {
      window.location.reload();
    }
  }, []);

  if (!isMounted) return null;

  return (
    <CookieManagerProvider
      onError={(error) => handleError(error)}
      onPreferenceChange={setTrackingPreference}
      locale="en"
      region={isGdprCountry(region) ? Region.EU : Region.DEFAULT}
      projectName="cdp"
      config={cookieManagerConfig}
      log={(str) => handleLog(str)}
    >
      <>
        {children}
        <CookiePreferences />
        <CookieBanner
          companyName="Coinbase Developer Platform"
          link="https://www.coinbase.com/legal/cookie"
        />
      </>
    </CookieManagerProvider>
  );
}
