import { IconType } from 'recharts/types/component/DefaultLegendContent';

export function defaultYAxisTickFormatter(tickItem: string) {
  const tickItemNumber = Number(tickItem);
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(tickItemNumber);
}

export function paymasterGasYAxisTickFormatter(tickItem: string) {
  const tickItemNumber = Number(tickItem);
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 5,
  }).format(tickItemNumber);
}

// appx 4 characters (we use compact formatting, e.g. 2.5K, 1.2M)
const LEFT_SPACING_ADJUSTMENT = 24;

export const defaultChartProps = { margin: { left: -LEFT_SPACING_ADJUSTMENT } };

export const defaultLegendWrapperProps = {
  wrapperStyle: { paddingLeft: LEFT_SPACING_ADJUSTMENT * 2.5 },
  iconType: 'circle' as IconType,
  iconSize: 12,
};

export const defaultYAxisProps = { tickLine: false, axisLine: false };
