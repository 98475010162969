import { useMemo, useState } from 'react';
import { styled } from '@linaria/react';
import _filter from 'lodash/filter';
import _flatten from 'lodash/flatten';
import _groupBy from 'lodash/groupBy';
import _keys from 'lodash/keys';
import _map from 'lodash/map';
import _maxBy from 'lodash/maxBy';
import _merge from 'lodash/merge';
import _some from 'lodash/some';
import _startCase from 'lodash/startCase';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { AxisDomain } from 'recharts/types/util/types';
import { ResponsiveProps } from '@cbhq/cds-common';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TabNavigation } from '@cbhq/cds-web/tabs';
import { palette } from '@cbhq/cds-web/tokens';
import { TextTitle1 } from '@cbhq/cds-web/typography';

import { cdsAliasToCssVar } from ':cloud/brand/colors';
import { BlockUnit } from ':cloud/widgets/charts/BlockUnit';
import {
  CustomLegend,
  CustomTooltip,
  CustomXAxisTick,
  getChartDisplayValue,
  getGraphMetricsByKey,
  getXAxisTickLabels,
  tooltipProps,
  xAxisProps,
  yAxisProps,
} from ':cloud/widgets/charts/ChartUtils';
import { EmptyChart } from ':cloud/widgets/charts/EmptyChart';
import { LinedChartProps } from ':cloud/widgets/clusters/schemaWidget/types';
import { BorderedBox } from ':cloud/widgets/sharedcomponents';

const titleResponsiveConfig: ResponsiveProps = {
  phone: {
    spacingVertical: 0,
    spacingHorizontal: 3,
  },
  tablet: {
    spacing: 3,
  },
  desktop: {
    spacing: 3,
  },
};

const StyledResponsiveChart = styled.div`
  width: 90%;
  height: 295px;
  margin: auto;
`;

type ITimeSlices = Record<string, ITimeSlice>;

interface ITimeSlice {
  value: string;
  displayText: string;
  timeInterval: number;
}

const LINED_CHART_SLICES: ITimeSlices = {
  '1D': { value: '24h_24m', timeInterval: 24, displayText: 'day' },
  '1W': { value: '168h_168m', timeInterval: 168, displayText: 'week' },
};

export function LinedChartWidget({
  title,
  chartConfig,
  tooltipConfig,
  metricsData,
}: LinedChartProps) {
  const cursor = useMemo(
    () => ({
      fill: palette.secondary,
    }),
    [],
  );
  const [timeSlice, setTimeSlice] = useState('1D');

  const formatLinedChart = (x: string): string => parseFloat(x).toFixed(2);
  const slicedData = Object.entries(chartConfig).map(([k]) => {
    return getGraphMetricsByKey(
      metricsData,
      k,
      `${k}_${LINED_CHART_SLICES[timeSlice].value}`,
      formatLinedChart,
    );
  });

  const groupByTimestamp = _groupBy(_flatten([...slicedData]), 'timestamp');

  // @ts-expect-error TODO: correct after 12/22 repo migration
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- headed for deprecation
  const mergedData = _map(groupByTimestamp, (val) => _merge(...val));
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- headed for deprecation
  const pruneData = (arr: any[]) => _filter(arr, (v) => _keys(v).length !== 0);
  const chartDataIsEmpty = pruneData(mergedData).length === 0;
  const XAxisTicks = getXAxisTickLabels(mergedData);

  const legendFormatted = Object.entries(chartConfig).map(([, v]) => v) as any;

  const renderCustomTick = (props) => <CustomXAxisTick {...props} ticks={XAxisTicks} />;

  const domain = useMemo(() => {
    // TODO(arti.villa):  BUG: calculate max for YAxis in single lined charts
    const getMaxYAxisValue = (data: any) => {
      const [key] = Object.keys(chartConfig);
      if (data && typeof key === 'string' && _some(data, key)) {
        const obj = _maxBy(data, (o: any) => Number(o[key]));
        if (parseInt(obj[key], 10) !== 0) {
          return parseInt(obj[key], 10);
        }
        return 1;
      }
      return 'auto';
    };
    return [0, getMaxYAxisValue(mergedData)] as AxisDomain;
  }, [chartConfig, mergedData]);

  const handleOnChange = (tabId: string) => {
    setTimeSlice(tabId);
  };

  const tabs = Object.entries(LINED_CHART_SLICES).map(([key, slice]) => ({
    id: key,
    label: `1 ${_startCase(slice.displayText)}`,
  }));

  return (
    <VStack width="100%">
      <TextTitle1 as="p" responsiveConfig={titleResponsiveConfig}>
        {title}
      </TextTitle1>
      <BorderedBox minHeight={620}>
        <Box width="100%" justifyContent="center">
          <TabNavigation value={timeSlice} onChange={handleOnChange} tabs={tabs} />
        </Box>
        {chartDataIsEmpty && (
          <EmptyChart
            message={`There has been no activity within the past ${LINED_CHART_SLICES[timeSlice].displayText}.`}
          />
        )}
        {!chartDataIsEmpty && (
          <>
            <HStack justifyContent="space-evenly" spacingTop={5} spacingBottom={3}>
              {Object.keys(chartConfig)
                .filter((k: string) => chartConfig[k].displayLabel)
                .map((k) => (
                  <BlockUnit
                    key={k}
                    label={chartConfig[k].displayLabel}
                    value={getChartDisplayValue(mergedData, k)}
                  />
                ))}
            </HStack>
            <StyledResponsiveChart>
              <ResponsiveContainer debounce={0.5}>
                <LineChart data={mergedData}>
                  <XAxis {...xAxisProps} ticks={XAxisTicks} tick={renderCustomTick} />
                  <YAxis {...yAxisProps} yAxisId="line" domain={domain} />
                  <Tooltip
                    {...tooltipProps}
                    cursor={cursor}
                    content={<CustomTooltip active mapPayloadKeys={tooltipConfig} />}
                  />
                  {Object.values(chartConfig).map((chartKey) => (
                    // @ts-expect-error TODO: correct after 12/22 repo migration
                    <Line
                      key={chartKey.dataKey}
                      dataKey={chartKey.dataKey}
                      dot={false}
                      name={chartKey.dataKey}
                      // @ts-expect-error Not sure why this is erroring, to be honest.
                      stroke={cdsAliasToCssVar(chartKey.color)}
                      strokeWidth={2}
                      type="monotone"
                      yAxisId="line"
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
              <CustomLegend payload={legendFormatted} />
            </StyledResponsiveChart>
          </>
        )}
      </BorderedBox>
    </VStack>
  );
}
