import { ReactElement, useEffect, useState } from 'react';
import { VStack } from '@cbhq/cds-web/layout';

import { ProtocolKey } from ':cloud/types/ts_types';
import { formatProtocolKey, getIconAlt } from ':cloud/utils/assets';

interface TopologyIconProps {
  protocolKey: ProtocolKey;
  className?: string;
}

export function TopologyIcon({ protocolKey, className }: TopologyIconProps): ReactElement {
  const [iconSrc, setIconSrc] = useState({ default: '' });

  useEffect(() => {
    const formattedProtocolKey = formatProtocolKey(protocolKey);
    import(`:cloud/assets/Protocols/Topology/${formattedProtocolKey}.svg`)
      .then((module) => {
        setIconSrc(module);
      })
      .catch(() => {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.error(`Topology icon for ${formattedProtocolKey} not found`);
        }
      });
  }, [protocolKey]);

  return (
    <VStack alignItems="center" justifyContent="center">
      <img
        src={iconSrc.default}
        alt={`${getIconAlt(protocolKey)} topology`}
        className={className}
      />
    </VStack>
  );
}
