import { useCallback, useMemo } from 'react';
import { Button } from '@cbhq/cds-web/buttons';
import { TextInput } from '@cbhq/cds-web/controls';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextDisplay3, TextLabel1 } from '@cbhq/cds-web/typography';
import { ProgressBar } from '@cbhq/cds-web/visualizations/ProgressBar';

import { useAppState } from ':cloud/contexts/AppStateContext';
import { usePaymasterContext } from ':cloud/contexts/PaymasterProvider';
import { EventName, logClick } from ':cloud/init/clientAnalytics/logging';
import { useGetPaymasterInfo } from ':cloud/queries/Base/useGetPaymasterInfo';
import { useUpdateGasPolicy } from ':cloud/queries/Base/useUpdateGasPolicy';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { GasPolicy } from ':cloud/types/ts_types';
import { currencyFormatter } from ':cloud/utils/formatters/currency';

import { ContractAllowlistBanner } from './ContractAllowlistBanner';

type PaymasterGlobalLimitSectionProps = {
  maxGlobalGasUsd: string;
  onMaxGlobalGasChange: (gas: React.ChangeEvent<HTMLInputElement>) => void;
  maxGasError?: string;
  gasPolicy: GasPolicy;
  isTestnet: boolean;
  setMaxGasPerAddrUsd: (gas: string) => void;
  setMaxGasPerUserOpUsd: (gas: string) => void;
};

export function PaymasterGlobalLimitSection({
  maxGlobalGasUsd,
  onMaxGlobalGasChange,
  maxGasError,
  gasPolicy,
  isTestnet,
  setMaxGasPerAddrUsd,
  setMaxGasPerUserOpUsd,
}: PaymasterGlobalLimitSectionProps) {
  const { activeOrg } = useGetUser();
  const { selectedProject } = useAppState();
  const { paymasterInfo } = useGetPaymasterInfo({
    organizationId: activeOrg?.organizationId,
    projectId: selectedProject?.id,
    isTestnet,
  });

  const { setCompletedStep } = usePaymasterContext();

  const maxGasPerAddrUsd = useMemo(() => {
    if (!gasPolicy?.maxGasPerAddrUsd) {
      return 0;
    }
    if (
      !gasPolicy?.perAddrMaxEnabled &&
      gasPolicy?.maxGasPerAddrUsd > parseFloat(maxGlobalGasUsd)
    ) {
      return parseFloat(maxGlobalGasUsd);
    }
    return gasPolicy?.maxGasPerAddrUsd;
  }, [gasPolicy?.maxGasPerAddrUsd, gasPolicy?.perAddrMaxEnabled, maxGlobalGasUsd]);

  const maxGasPerUserOperationUsd = useMemo(() => {
    if (!gasPolicy?.maxGasPerUserOperationUsd) {
      return 0;
    }
    if (
      !gasPolicy?.maxGasPerUserOperationEnabled &&
      gasPolicy?.maxGasPerUserOperationUsd > parseFloat(maxGlobalGasUsd)
    ) {
      return parseFloat(maxGlobalGasUsd);
    }
    return gasPolicy?.maxGasPerUserOperationUsd;
  }, [
    gasPolicy?.maxGasPerUserOperationEnabled,
    gasPolicy?.maxGasPerUserOperationUsd,
    maxGlobalGasUsd,
  ]);

  const handleSuccess = useCallback(() => {
    if (!gasPolicy?.maxGasPerUserOperationEnabled) {
      setMaxGasPerUserOpUsd(JSON.stringify(maxGasPerUserOperationUsd));
    }

    if (!gasPolicy?.perAddrMaxEnabled) {
      setMaxGasPerAddrUsd(JSON.stringify(maxGasPerAddrUsd));
    }

    setCompletedStep('gas');
  }, [
    gasPolicy?.maxGasPerUserOperationEnabled,
    gasPolicy?.perAddrMaxEnabled,
    maxGasPerAddrUsd,
    maxGasPerUserOperationUsd,
    setCompletedStep,
    setMaxGasPerAddrUsd,
    setMaxGasPerUserOpUsd,
  ]);

  const updateGasPolicy = useUpdateGasPolicy({
    organizationId: activeOrg?.organizationId || '',
    projectId: selectedProject?.id || '',
    onSuccess: handleSuccess,
    previousPolicy: gasPolicy,
  });

  const numChangesUnsaved = useMemo(() => {
    if (gasPolicy?.maxGlobalGasUsd !== parseFloat(maxGlobalGasUsd)) {
      return 1;
    }
    return 0;
  }, [gasPolicy?.maxGlobalGasUsd, maxGlobalGasUsd]);

  const sponsoredGas = paymasterInfo?.usdSponsored;
  const formattedSponsoredGas = sponsoredGas !== undefined ? currencyFormatter(sponsoredGas) : '-';
  const formattedMaxGlobalGas =
    gasPolicy?.maxGlobalGasUsd !== undefined ? currencyFormatter(gasPolicy?.maxGlobalGasUsd) : '-';
  const progressBarProgress = useMemo(() => {
    if (sponsoredGas === undefined || gasPolicy?.maxGlobalGasUsd === undefined) {
      return 0;
    }
    return sponsoredGas / gasPolicy.maxGlobalGasUsd;
  }, [gasPolicy.maxGlobalGasUsd, sponsoredGas]);

  const isDisabled = !gasPolicy?.contractAllowlist?.length;
  const isWarning = progressBarProgress >= 0.75;

  const handleSavePress = useCallback(() => {
    if (!gasPolicy) {
      return;
    }

    const newGasPolicy = {
      ...gasPolicy,
      maxGlobalGasUsd: parseFloat(maxGlobalGasUsd),
      maxGasPerAddrUsd,
      maxGasPerUserOperationUsd,
    };

    logClick(EventName.paymaster_update_global_gas_limit);

    updateGasPolicy.mutate({ policy: newGasPolicy });
  }, [gasPolicy, maxGasPerAddrUsd, maxGasPerUserOperationUsd, maxGlobalGasUsd, updateGasPolicy]);

  const isSaveDisabled =
    !numChangesUnsaved || !!maxGasError || !maxGlobalGasUsd || maxGlobalGasUsd === '.';

  return (
    <VStack spacingTop={3} gap={3}>
      <Box spacing={4} bordered borderRadius="rounded" maxWidth={464}>
        <VStack gap={2} width="100%">
          <TextLabel1 as="p">Sponsored gas globally</TextLabel1>
          <HStack alignItems="flex-end">
            <TextDisplay3 as="h3">{formattedSponsoredGas}</TextDisplay3>
            <TextBody as="p" color="foregroundMuted">{`/ ${formattedMaxGlobalGas}`}</TextBody>
          </HStack>
          <ProgressBar progress={progressBarProgress} />
        </VStack>
      </Box>
      {(isDisabled || isWarning) && (
        <ContractAllowlistBanner spacingTop={1} isWarning={isWarning} />
      )}

      <TextInput
        type="text"
        label="Maximum USD"
        placeholder="0.00"
        helperText={maxGasError || 'Max amount of USD this policy will sponsor globally'}
        value={maxGlobalGasUsd}
        onChange={onMaxGlobalGasChange}
        suffix="USD"
        variant={maxGasError ? 'negative' : 'foregroundMuted'}
      />
      <HStack gap={2} justifyContent="flex-end" alignItems="center" spacingTop={7}>
        <TextBody as="p" color="foregroundMuted">{`${numChangesUnsaved} unsaved change${
          numChangesUnsaved === 1 ? '' : 's'
        }`}</TextBody>
        <Button variant="primary" onPress={handleSavePress} disabled={isSaveDisabled}>
          Save
        </Button>
      </HStack>
    </VStack>
  );
}
