import { useMemo } from 'react';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextHeadline } from '@cbhq/cds-web/typography';

import { PaymentUsBankAccount } from ':cloud/types/service_proto';

type PaymentMethodDisplayUsBankAccountProps = {
  displayedMethod: PaymentUsBankAccount;
  showIcon?: boolean;
  transparent?: boolean;
  expanded?: boolean;
};

export function PaymentMethodDisplayUsBankAccount({
  displayedMethod: usBankAccount,
  showIcon = true,
  expanded = false,
}: PaymentMethodDisplayUsBankAccountProps) {
  const title = useMemo(
    () => `ACH ${usBankAccount.bank} ***${usBankAccount.lastFour}`,
    [usBankAccount.lastFour, usBankAccount.bank],
  );
  return (
    <HStack alignItems="center">
      {showIcon && (
        <Box spacingEnd={3}>
          <Icon size="m" name="bank" />
        </Box>
      )}
      {expanded ? (
        <HStack gap={3}>
          <TextHeadline as="h4">{title}</TextHeadline>
        </HStack>
      ) : (
        <VStack alignItems="flex-start">
          <TextHeadline as="h4">{title}</TextHeadline>
        </VStack>
      )}
    </HStack>
  );
}
