import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { type IllustrationHeroSquareNames } from '@cbhq/cds-common';
import { Button } from '@cbhq/cds-web/buttons';
import { HeroSquare } from '@cbhq/cds-web/illustrations/HeroSquare';
import { VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextTitle1 } from '@cbhq/cds-web/typography';

import { CustomNetworkError } from ':cloud/utils/errors';
import { CLOUD_HELP } from ':cloud/utils/links';

const DEFAULT_ERROR_TITLE = "We're having connection issues";
const DEFAULT_ERROR_MESSAGE = "We're looking into it right now. Please try again later.";

export type ErrorPageProps = {
  statusCode?: 400 | 404 | 500 | 503;
  errorMsg?: string;
  errorSubheading?: string;
  buttonText?: string;
  error?: unknown;
  hasAction?: boolean;
  resetErrorBoundary?: () => void;
};

export function ErrorPage({
  statusCode = 500,
  errorMsg = DEFAULT_ERROR_MESSAGE,
  buttonText = 'Back to Coinbase Developer Platform',
  error,
  hasAction = true,
  resetErrorBoundary, // injected here - https://github.cbhq.net/frontend/web/blob/master/packages/error-vitals-core/src/ErrorBoundary.tsx#L130
}: ErrorPageProps) {
  const message = (error as Error)?.message || errorMsg;
  const errorCode = (error as CustomNetworkError)?.statusCode || statusCode;
  const history = useHistory();
  const statusImg =
    errorCode === 503
      ? 'iceCreamMeltingSystemError'
      : ('errorWeb'.concat(errorCode.toString()) as IllustrationHeroSquareNames);
  const StatusViews: Record<number, { title: string; subtext: string }> = {
    404: {
      title: 'Page not found',
      subtext: "Sorry, we couldn't find what you were looking for.",
    },
    500: {
      title: DEFAULT_ERROR_TITLE,
      subtext: DEFAULT_ERROR_MESSAGE,
    },
    503: {
      title: 'Temporarily down for maintenance',
      subtext: `The Coinbase Developer Platform team is performing scheduled maintenance for the next hour or two.
      All products, including Participate and WaaS, will continue operating normally during this maintenance period.`,
    },
    400: {
      title: "Something's not right",
      subtext: message,
    },
  };

  const handleButtonPress = useCallback(() => {
    resetErrorBoundary?.();
    if (history) {
      history.replace('/', null);
    } else {
      window.location.replace('/');
    }
  }, [history, resetErrorBoundary]);

  return (
    <VStack height="100vh" justifyContent="center" alignItems="center" background>
      <VStack width={330} gap={5} alignItems="center">
        {StatusViews[errorCode] ? (
          <HeroSquare name={statusImg} />
        ) : (
          <HeroSquare name="errorWeb500" />
        )}
        <div>
          <TextTitle1 align="center" as="h3" spacingBottom={3}>
            {StatusViews[errorCode] ? StatusViews[errorCode].title : DEFAULT_ERROR_TITLE}
          </TextTitle1>
          <TextBody align="center" as="p" color="foregroundMuted">
            {StatusViews[errorCode] ? StatusViews[errorCode].subtext : DEFAULT_ERROR_MESSAGE}
          </TextBody>
        </div>
        {hasAction &&
          (errorCode === 503 ? (
            <Button to={CLOUD_HELP} variant="primary" target="_blank" block>
              Developer Platform support
            </Button>
          ) : (
            <Button onPress={handleButtonPress} variant="secondary" block>
              {buttonText}
            </Button>
          ))}
      </VStack>
    </VStack>
  );
}
