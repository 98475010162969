import _keyBy from 'lodash/keyBy';

import { getCDSVariantFromWorkflow } from ':cloud/brand/colors';
import { ChartWidgetType } from ':cloud/types/helper';
import { IWidgetSchema, SchemaSourceType } from ':cloud/types/ts_types';
import { getTooltipConfig } from ':cloud/widgets/charts/ChartUtils';
import { ChartConfig } from ':cloud/widgets/clusters/schemaWidget/types';

import { LinedChartWidget } from './LinedChartWidget';

export function LinedChartWrapper({ sources, options, metricsData }: ChartWidgetType) {
  const metricOptions = options.sources.metrics;

  const chartConfigVals = (sources as IWidgetSchema['sources']).map((source: SchemaSourceType) => ({
    dataKey: source.key,
    value: metricOptions[source.key].legend?.label,
    color: getCDSVariantFromWorkflow(metricOptions[source.key].color),
    displayLabel: metricOptions[source.key].display?.label,
    displayCompute: metricOptions[source.key].display?.compute,
  }));

  // @ts-expect-error likely headed for deprecation
  const chartConfig = _keyBy(chartConfigVals, 'dataKey') as Record<string, ChartConfig>[];

  return (
    <LinedChartWidget
      title={options.title}
      chartConfig={chartConfig}
      tooltipConfig={getTooltipConfig(options, sources)}
      metricsData={metricsData}
    />
  );
}
