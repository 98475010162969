import { useMutation } from '@tanstack/react-query';

import { APIClientBase } from ':cloud/state/Api';
import { handleDownload } from ':cloud/utils/files';

async function getPaymasterLogsDownload(organizationId?: string, projectId?: string) {
  const { data } = await APIClientBase.post<string>('/rpc/api/userops', {
    organizationId,
    projectId,
  });
  const csvData = atob(btoa(data));
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  const downloadURL = URL.createObjectURL(blob);
  handleDownload(downloadURL);
}

export function useGetPaymasterLogsDownload(orgId = '', projId = '') {
  return useMutation({
    mutationFn: async () => getPaymasterLogsDownload(orgId, projId),
    meta: {
      errorMsg: `There was an error generating a download link`,
    },
  });
}
