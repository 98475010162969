import { css } from '@linaria/core';
import _isNumber from 'lodash/isNumber';
import _isString from 'lodash/isString';
import type { SpacingScale } from '@cbhq/cds-web';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextHeadline } from '@cbhq/cds-web/typography';

import { EM_DASH } from ':cloud/brand/constants';
import { TextLabelGray } from ':cloud/widgets/sharedcomponents';

const iconStyle = css`
  width: 24px;
  height: 24px;
`;

interface BlockUnitProps {
  label: string;
  value?: string | number;
  unit?: string;
  border?: boolean;
  spacingHorizontal?: SpacingScale;
  spacingVertical?: SpacingScale;
  IconComponent?: React.ComponentType<{ className?: string }>;
  children?: React.ReactNode;
  alignLeft?: boolean;
  fullWidth?: boolean;
}

export function BlockUnit({
  label,
  value,
  unit,
  IconComponent,
  children,
  spacingHorizontal = 5,
  spacingVertical = 5,
  border = true,
  fullWidth,
}: BlockUnitProps) {
  const displayUnit: boolean = _isNumber(value) || _isString(value);
  return (
    <Box width={fullWidth ? '100%' : undefined} testID="block-unit">
      <HStack
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        spacingHorizontal={spacingHorizontal}
        spacingVertical={spacingVertical}
        bordered={border}
        borderRadius="roundedSmall"
      >
        <VStack>
          <TextLabelGray>{label}</TextLabelGray>
          <div>
            <TextHeadline as="span" spacingBottom={1}>
              {value || EM_DASH}
            </TextHeadline>
            {displayUnit && <TextHeadline as="span">{unit}</TextHeadline>}
          </div>
        </VStack>
        {IconComponent && <IconComponent className={iconStyle} />}
        {children}
      </HStack>
    </Box>
  );
}
