import BigNumber from 'bignumber.js';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _keyBy from 'lodash/keyBy';
import _omit from 'lodash/omit';

import { EM_DASH } from ':cloud/brand/constants';
import {
  IMetricData,
  IMetrics,
  IMetricsRoot,
  MetricKeyType,
  ProtocolKey,
} from ':cloud/types/ts_types';

enum NETWORK {
  LPT = 'livepeer',
  NU = 'nucypher',
}

const prefix = ['', 'k', 'm', 'g', 't'];

function readableBytes(num: string | number): {
  readable: number;
  modifier: number;
  prefix: string;
} {
  let modifier = 0;
  let readable = Number.isInteger(num) ? (num as number) : Number.parseInt(num as string, 10);
  while (readable > 1000) {
    readable /= 1000;
    modifier += 1;
  }

  return { readable, modifier, prefix: prefix[modifier] };
}

export function bytesToGigaBit(n: number): number {
  return new BigNumber(n).dividedBy(10 ** 9).toNumber();
}

function parseEnum(val: number, meta: IMetrics, network?: ProtocolKey): string | number {
  // TODO: Ideally this map would exist elsewhere for easy updates
  if (network === NETWORK.LPT || network === NETWORK.NU) {
    if (meta.name === 'active_status') {
      return val === 0 ? 'Inactive' : 'Active';
    }
    if (meta.name === 'bonded_status') {
      return val === 0 ? 'Unbonded' : 'Bonded';
    }
    if (meta.name === 'registered_status') {
      return val === 0 ? 'False' : 'True';
    }
    if (meta.name === 'ursula_restaking') {
      return val === 0 ? 'False' : 'True';
    }
    if (meta.name === 'ursula_windown') {
      return val === 0 ? 'False' : 'True';
    }
  }

  return val;
}

type ParseValueReturn = {
  metric: string | number | boolean;
  value: string;
  unit?: string;
  label: string;
};

export function parseValue(
  metric: any | undefined,
  meta: any,
  network?: ProtocolKey,
): ParseValueReturn {
  const label = _get(meta, 'label', EM_DASH);
  if (!metric?.[0]?.value?.[1]) {
    return { metric, value: EM_DASH, unit: '', label };
  }

  const raw: string = metric[0].value[1];
  let value;
  let unit;
  switch (meta.type) {
    case 'bytes': {
      const { readable, modifier } = readableBytes(raw);
      value = readable.toFixed(0).toLocaleString();
      unit = `${prefix[modifier]}b`;
      break;
    }
    case 'bps': {
      const { readable, modifier } = readableBytes(raw);
      value = readable.toFixed(0).toLocaleString();
      unit = `${prefix[modifier]}bps`;
      break;
    }
    case 'string':
      value = raw.toLocaleString();
      break;
    case 'number':
      value = Number.parseFloat(raw).toLocaleString();
      break;
    case 'integer':
      value = parseEnum(Number.parseInt(raw, 10), meta as IMetrics, network).toLocaleString();
      break;
    case 'percent':
      value = (Number.parseFloat(raw) * 100).toPrecision(3);
      unit = '%';
      break;
    default:
      value = raw.toLocaleString();
      unit = meta.type;
      break;
  }

  return { metric, value, unit, label };
}

export function parseMetricValue(
  metricKey: string,
  metrics: IMetricsRoot,
  network?: ProtocolKey,
): ParseValueReturn {
  const meta = _keyBy(metrics?.metrics, 'name');
  let metric;

  if (_has(metrics, `data.${metricKey}`)) {
    metric = metrics.data[metricKey];
  }

  return parseValue(metric, meta[metricKey], network);
}

export function parseNewMetricValue(
  metric: any,
  sourceType: string,
): string | number | BigNumber | undefined {
  if (!metric?.[0]?.value?.[1]) {
    return undefined;
  }
  const raw: string = metric[0].value[1];
  let value: string | number | BigNumber | undefined;
  switch (sourceType) {
    case 'bytes': {
      const { readable } = readableBytes(raw);
      value = readable.toFixed(0).toLocaleString();
      break;
    }
    case 'bps': {
      const { readable } = readableBytes(raw);
      value = readable.toFixed(0).toLocaleString();
      break;
    }
    case 'string':
      value = raw.toLocaleString();
      break;
    case 'float':
    case 'number':
      value = Number.parseFloat(raw).toLocaleString();
      break;
    case 'integer':
    case 'percent':
      value = (Number.parseFloat(raw) * 100).toPrecision(3);
      break;
    case 'big_int':
      value = new BigNumber(raw);
      break;
    default:
      value = raw.toLocaleString();
      break;
  }
  return value;
}

export function getDyanmicMetricsData(
  metricsData: IMetricData,
  protocolKey: ProtocolKey,
  keys?: Record<MetricKeyType, string>,
): IMetricData | undefined {
  if (!keys) {
    return undefined;
  }
  let excludeNetworkKeys: string[] = ['memory_usage', 'memory_usage_percent', 'cpu_usage_percent'];
  if (protocolKey === 'celo') {
    excludeNetworkKeys = [
      'eth_block_number_proxy',
      'eth_block_number_proxy',
      'memory_usage_proxy',
      'memory_usage_validator',
      'net_peers_proxy',
      'net_peers_validator',
      'network_transmit_bytes',
      'network_receive_bytes',
      'memory_usage',
      'memory_usage_percent',
      'cpu_usage_percent',
    ];
  }
  return _omit(metricsData, [
    keys.uptimeKey,
    keys.blockHeightKey,
    keys.peersKey,
    ...excludeNetworkKeys,
  ]);
}
