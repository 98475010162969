import Cookies from 'js-cookie';
import {
  generateUUID,
  identify,
  init,
  initTrackPageview,
  PlatformName,
} from '@cbhq/client-analytics';
import { getClientLocale } from '@cbhq/intl';

import config from ':cloud/config';
import { logError } from ':cloud/init/bugsnag/logging';
import { history } from ':cloud/utils/history';

import { pageKeyRegex } from './pageKey';

const COOKIE_DEVICE_ID = 'coinbase_device_id';
const TWENTY_YEARS_AS_MS = 630_720_000_000;
const isProd = config.isProduction();

export function getDeviceId() {
  let id = Cookies.get(COOKIE_DEVICE_ID);
  if (!id) {
    id = generateUUID();
    Cookies.set(COOKIE_DEVICE_ID, id, {
      expires: new Date(Date.now() + TWENTY_YEARS_AS_MS),
    });
  }
  return id;
}

export function initClientAnalytics() {
  try {
    identify({
      deviceId: getDeviceId(),
      countryCode: getClientLocale(),
    });

    init({
      amplitudeApiKey: config.amplitudeKey,
      isProd,
      platform: PlatformName.web,
      projectName: config.appAnalyticsName,
      showDebugLogging: false,
      trackUserId: true,
      version: config.appVersion,
    });

    initTrackPageview({ browserHistory: history, pageKeyRegex });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error initializing analytics');
    logError(error, {
      context: 'initClientAnalytics',
    });
  }
}
