import { Banner } from '@cbhq/cds-web/banner/Banner';
import { VStack } from '@cbhq/cds-web/layout';

import useIsFeatureEnabled from ':cloud/hooks/useIsFeatureEnabled';

export function MetricsRemovalBanner() {
  const areMetricsEnabled = useIsFeatureEnabled('aug_2023_participate_metrics');

  if (!areMetricsEnabled) {
    return null;
  }
  return (
    <VStack spacing={4}>
      <Banner
        title="Cluster metrics unavailable until further notice"
        variant="warning"
        startIcon="error"
      >
        Our metrics are undergoing feature updates to enhance your experience. During this time they
        will be unavailable.
      </Banner>
    </VStack>
  );
}
