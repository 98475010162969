import { useQuery } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { GetPaymentSetupSecretResponse } from ':cloud/types/service_proto';

async function fetchSetupIntentSecret(orgId?: string): Promise<GetPaymentSetupSecretResponse> {
  const { data } = await APIClientBase<GetPaymentSetupSecretResponse>({
    url: `/billing/v1/accounts/${orgId}:generatePaymentSecret`,
    method: 'post',
  });
  return data;
}

export function useGetPaymentSetupSecret(orgId?: string): {
  secret?: string;
  type?: string;
  error: unknown;
  isLoading: boolean;
} {
  const { isLoading, data, error } = useQuery({
    queryKey: [QueryKey.billing, 'payment-setup-secret', orgId],
    queryFn: async () => fetchSetupIntentSecret(orgId),
    enabled: !!orgId,
  });

  return {
    secret: data?.paymentSecret,
    type: data?.type,
    error,
    isLoading,
  };
}
