import { HStack } from '@cbhq/cds-web/layout';
import { NavigationTitle } from '@cbhq/cds-web/navigation/NavigationTitle';

type NavTitleProps = {
  value: string;
};

export function NavTitle({ value }: NavTitleProps) {
  return (
    <HStack width="100%" justifyContent="flex-start" alignItems="center" gap={1}>
      <NavigationTitle align="start" overflow="break" numberOfLines={2}>
        {value}
      </NavigationTitle>
    </HStack>
  );
}
