import { useCallback } from 'react';

import { useCreateProductDetailsWithMessage } from ':cloud/queries/Projects/useCreateProductDetails';
import { useGetProductDetails } from ':cloud/queries/Projects/useGetProductDetails';
import { useUpdateProductDetailsWithMessage } from ':cloud/queries/Projects/useUpdateProductDetails';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { ProductDetails, ProductTypeMap, Project } from ':cloud/types/ts_types';
import { domainOriginFormSchema } from ':cloud/utils/validate';
import { EditableConfigValue } from ':cloud/widgets/product/EditableConfigValue';
import { ERROR_SUCCESS_MESSAGES } from ':cloud/widgets/waasConsumer/common';

type ProductDetailsSectionProps = {
  project: Project;
};

const DOMAIN_URL = 'Domain URL';
const HELPER_TEXT = 'Add your domain where users will access Embedded Wallets.';

export function UpdateDomainComponent({ project }: ProductDetailsSectionProps) {
  const productType = ProductTypeMap.waas_consumer;

  const { isLoading, productDetails } = useGetProductDetails(project, productType);
  const {
    user: { isOwnerOrAdmin },
  } = useGetUser();

  const createProductDetails = useCreateProductDetailsWithMessage(ERROR_SUCCESS_MESSAGES);
  const updateProductDetails = useUpdateProductDetailsWithMessage(ERROR_SUCCESS_MESSAGES);

  const handleDomainOriginUpdate = useCallback(
    (domainOrigin, onSettled) => {
      const newProductDetails: ProductDetails = {
        waasConsumer: {
          ...(productDetails?.waasConsumer || {}),
          projectId: project.id,
          domainOrigin,
          etag: productDetails?.waasConsumer?.etag,
        },
      };

      let mutation = createProductDetails;

      if (productDetails) {
        mutation = updateProductDetails;
      }

      mutation.mutate({ project, productType, productDetails: newProductDetails }, { onSettled });
    },
    [createProductDetails, updateProductDetails, productType, project, productDetails],
  );

  return (
    <EditableConfigValue
      name="Set domain"
      isLoading={isLoading}
      value={productDetails?.waasConsumer?.domainOrigin}
      placeholder={DOMAIN_URL}
      helperText={HELPER_TEXT}
      iconName="chainLink"
      submitActionLabel="Update"
      onSubmit={handleDomainOriginUpdate}
      disabled={!isOwnerOrAdmin}
      validationSchema={domainOriginFormSchema}
      resetDefaultValueOnEdit
      openFormIfNoValueSet
    />
  );
}
