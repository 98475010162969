import { useEffect, useState } from 'react';
import _keyBy from 'lodash/keyBy';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';

import { useGetResourceMetrics } from ':cloud/queries/ResourceQueries/useGetResourceMetrics';
import { ICluster } from ':cloud/types/ts_types';
import { isClusterRunning } from ':cloud/utils/clusters';
import { parseMetricValue, parseValue } from ':cloud/utils/metrics';
import { extractMetricsKeys } from ':cloud/utils/protocols';
import DisplayUnit from ':cloud/widgets/charts/DisplayUnit';
import { ClusterStatusTitle } from ':cloud/widgets/clusters/ClusterStatusTitle';

interface ClusterCardProps {
  cluster: ICluster;
}

export function ClusterCard({ cluster }: ClusterCardProps) {
  const { metrics } = useGetResourceMetrics(cluster.type, cluster.name, isClusterRunning(cluster));

  const [uptime, setUptime] = useState<string>();
  const [blockHeight, setBlockHeight] = useState<string>();

  useEffect(() => {
    function loadLimitedMetricsForCard(): void {
      const { blockHeightKey, uptimeKey } = extractMetricsKeys(metrics);

      const meta = _keyBy(metrics?.metrics, 'name');
      if (meta[uptimeKey] && metrics?.data[uptimeKey]) {
        const { value } = parseValue(metrics.data[uptimeKey], meta[uptimeKey]);
        setUptime(value);
      }

      if (cluster.type === 'eth2' && metrics) {
        const { value: uptimeBeacon } = parseMetricValue('uptime_beacon', metrics);
        const { value: uptimeValidator } = parseMetricValue('uptime_validator', metrics);
        const overallUptime = Math.ceil(
          (parseInt(uptimeBeacon, 10) + parseInt(uptimeValidator, 10)) / 2,
        );
        setUptime(String(overallUptime));
      }

      if (meta[blockHeightKey]) {
        const { value } = parseValue(metrics?.data[blockHeightKey], meta[blockHeightKey]);
        setBlockHeight(value);
      }
    }
    if (metrics) {
      loadLimitedMetricsForCard();
    }
  }, [metrics, cluster]);

  return (
    <VStack bordered borderRadius="roundedSmall" gap={5} spacing={5}>
      <ClusterStatusTitle cluster={cluster} />
      <HStack width="100%" gap={3}>
        <Box flexGrow={1}>
          <DisplayUnit label="uptime" isSmaller value={uptime} unit="%" />
        </Box>
        <Box flexGrow={1}>
          <DisplayUnit label="block height" isSmaller value={blockHeight} noWrap />
        </Box>
        {cluster.type === 'algorand' && cluster.artifacts?.mode && (
          <Box flexGrow={1}>
            <DisplayUnit
              label="node type"
              isCapitalized
              isSmaller
              value={cluster.artifacts?.mode}
              noWrap
            />
          </Box>
        )}
        {cluster.artifacts?.validatorFee && (
          <Box flexGrow={1}>
            <DisplayUnit
              label="validator fee"
              isSmaller
              value={Number.parseFloat(cluster.artifacts.validatorFee).toFixed(2)}
              unit="%"
              noWrap
            />
          </Box>
        )}
      </HStack>
    </VStack>
  );
}
