import { Box } from '@cbhq/cds-web/layout';
import { TableCell, TableRow } from '@cbhq/cds-web/tables';
import { TextHeadline } from '@cbhq/cds-web/typography';

type APIKeyTableEmptySearchStateProps = { isViewOnly: boolean };

export function APIKeyTableEmptySearchState({ isViewOnly }: APIKeyTableEmptySearchStateProps) {
  return (
    <TableRow>
      <TableCell height={80} colSpan={isViewOnly ? 5 : 6} alignItems="center">
        <Box width={400} spacingVertical={10}>
          <TextHeadline as="p" color="foregroundMuted" align="center">
            APIs secure app interactions through access control. Clear filters to view all keys
          </TextHeadline>
        </Box>
      </TableCell>
    </TableRow>
  );
}
