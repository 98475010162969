import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { DeletePaymentMethodResponse } from ':cloud/types/service_proto';

type DeletePaymentMethodProps = {
  orgId: string;
  paymentMethodId: string;
};

async function deletePaymentMethod({
  orgId,
  paymentMethodId,
}: DeletePaymentMethodProps): Promise<DeletePaymentMethodResponse> {
  const path = `/billing/v1/accounts/${orgId}/detachPaymentMethod`;
  const { data } = await APIClientBase.post<DeletePaymentMethodResponse>(path, {
    externalId: paymentMethodId,
  });
  return data;
}

export function useDeletePaymentMethod(orgId = '') {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (paymentMethodId: string) => deletePaymentMethod({ paymentMethodId, orgId }),
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKey.billing, 'paymentMethod', orgId]);
    },
    meta: {
      successMsg: `Your payment method was successfully removed.`,
    },
  });
}
