import { createContext, Dispatch, SetStateAction, useContext } from 'react';

import { ClientRecordNonSensitive } from ':cloud/types/service_proto';

export interface SensitiveInfo {
  oauthClientID: string;
  oauthClientSecret: string;
}

interface OAuthInfoContextProps {
  sensitiveInfo: SensitiveInfo | null;
  setSensitiveInfo: Dispatch<SetStateAction<SensitiveInfo | null>>;
  selectedClient: ClientRecordNonSensitive | null;
  setSelectedClient: Dispatch<SetStateAction<ClientRecordNonSensitive | null>>;
}

export const OAuthInfoContext = createContext<OAuthInfoContextProps | undefined>(undefined);

export function useOAuthInfo(): OAuthInfoContextProps {
  const context = useContext(OAuthInfoContext);
  if (context === undefined) {
    throw new Error('useOAuthInfo must be used within an OAuthInfoProvider');
  }
  return context;
}
