import { useCallback, useState } from 'react';
import { noop } from '@cbhq/cds-utils';
import { Banner } from '@cbhq/cds-web/banner/Banner';
import { Button } from '@cbhq/cds-web/buttons';
import { TextInput } from '@cbhq/cds-web/controls';
import { VStack } from '@cbhq/cds-web/layout';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from '@cbhq/cds-web/overlays';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { TextBody, TextLabel1 } from '@cbhq/cds-web/typography';

import { useDeleteOAuthClient } from ':cloud/queries/OAuthClientQueries/useDeleteOAuthClient';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { ClientDeleteType } from ':cloud/types/service_proto';
import { TextWithCopyIcon } from ':cloud/widgets/sharedcomponents/TextWithCopyIcon';

type OAuthClientDeleteModalProps = Partial<ModalProps> & {
  onSuccess?: (clientId: string) => void;
  clientId?: string;
};

export function OAuthClientDeleteModal(props: OAuthClientDeleteModalProps): JSX.Element {
  const { onRequestClose, onSuccess, clientId } = props;
  const { activeOrg } = useGetUser();
  const toast = useToast();
  const [selectedClientId, setSelectedClientId] = useState('');

  const { mutate: deleteOAuthClient, isLoading } = useDeleteOAuthClient(
    activeOrg?.organizationId,
    (deletedClientId) => {
      toast.show('Client successfully deleted.', {
        variant: 'positive',
      });
      onSuccess?.(deletedClientId);
      onRequestClose?.();
    },
  );

  const handleSubmit = useCallback(() => {
    if (selectedClientId) {
      deleteOAuthClient({ clientId: selectedClientId, deleteType: ClientDeleteType.SOFT_DELETE });
    } else {
      toast.show('Oops, something went wrong. No client ID has been selected.', {
        variant: 'negative',
      });
    }
  }, [deleteOAuthClient, selectedClientId, toast]);

  const handleInputChange = useCallback((event) => {
    setSelectedClientId(event.target.value);
  }, []);

  if (!clientId) {
    throw new Error('Client ID not available');
  }

  return (
    <Modal onRequestClose={onRequestClose || noop} visible {...props}>
      <ModalHeader title="Delete client" />
      <ModalBody>
        <VStack gap={5}>
          <TextBody as="p">Copy and paste the client ID of the OAuth application.</TextBody>
          <VStack gap={1}>
            <TextLabel1 as="p">Client ID</TextLabel1>
            <TextWithCopyIcon text={clientId} />
          </VStack>
          <VStack>
            <TextInput
              label="Client ID"
              placeholder="Enter client ID"
              onChange={handleInputChange}
            />
          </VStack>
          <Banner title="Warning" variant="warning" startIcon="warning">
            This OAuth application will be permanently and irreversibly invalidated, and will stop
            working shortly after it is deleted.
          </Banner>
        </VStack>
      </ModalBody>
      <ModalFooter
        primaryAction={
          <Button
            type="submit"
            variant="negative"
            onPress={handleSubmit}
            disabled={clientId !== selectedClientId}
            loading={isLoading}
          >
            Delete client
          </Button>
        }
        secondaryAction={
          <Button variant="secondary" onPress={onRequestClose}>
            Cancel
          </Button>
        }
      />
    </Modal>
  );
}
