import {
  ChangeEventHandler,
  forwardRef,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DimensionValue, useToggler } from '@cbhq/cds-web';
import { SelectOption, SelectOptionProps, TextInput } from '@cbhq/cds-web/controls';
import { Dropdown } from '@cbhq/cds-web/dropdown/Dropdown';
import { DropdownRefProps } from '@cbhq/cds-web/dropdown/DropdownProps';
import { Icon } from '@cbhq/cds-web/icons/Icon';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextCaption } from '@cbhq/cds-web/typography';

import { useDebounce } from ':cloud/hooks/useDebounce';
import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';

export type DropdownWithSearchOption = {
  searchKey: string;
  group?: string;
} & SelectOptionProps;

type DropdownWithSearchProps = {
  testID?: string;
  options: DropdownWithSearchOption[];
  customOptionWidth?: DimensionValue;
  valueLabel?: string;
  placeholder?: string;
  disabled?: boolean;
  tabIndex?: number;
  startNode?: ReactNode;
  onChange: (selected: string) => void;
  onSearchChange?: (search: string) => void;
};

export const DropdownWithSearch = memo(
  forwardRef<DropdownRefProps, DropdownWithSearchProps>(
    (
      {
        valueLabel,
        options,
        placeholder,
        testID,
        disabled,
        tabIndex,
        customOptionWidth,
        startNode,
        onChange,
        onSearchChange,
      },
      ref,
    ) => {
      const [dropdownActive, { toggle }] = useToggler();
      const [searchValue, setSearchValue] = useState<string>('');
      const debouncedSearch = useDebounce(searchValue, 200);

      const [blockSearchFocus, setBlockSearchFocus] = useState(false);

      const searchInputRef = useRef<HTMLInputElement>(null);

      const focusSearchInput = useCallback(() => {
        if (!blockSearchFocus) {
          searchInputRef.current?.focus();
        }
      }, [blockSearchFocus]);

      const blurSearchInput = useCallback(() => {
        setBlockSearchFocus(true);
        searchInputRef.current?.blur();
      }, []);

      const handleDropdownBlur = useCallback(() => {
        focusSearchInput();
      }, [focusSearchInput]);

      const onSearchInputPress = useCallback(() => {
        setBlockSearchFocus(false);
      }, [setBlockSearchFocus]);

      const onDropdownMenuChange = useCallback(() => {
        focusSearchInput();
        toggle();
      }, [focusSearchInput, toggle]);

      useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
          if (
            !searchInputRef.current?.contains(event.target as Node) &&
            document.activeElement === searchInputRef.current
          ) {
            blurSearchInput();
          }
        }

        document.addEventListener('click', handleClickOutside);
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, [blurSearchInput]);

      const filteredOptions = useMemo(() => {
        // if we are storing the search text outside of the component,
        // we don't want to filter the options internally
        if (onSearchChange) {
          return options;
        }
        return options.filter(
          ({ searchKey }) => searchKey?.toLowerCase().includes(debouncedSearch?.toLowerCase()),
        );
      }, [options, debouncedSearch, onSearchChange]);

      const groupedOptions = useMemo(() => {
        // Group options by their "group" property
        const groups = filteredOptions.reduce(
          (acc, option) => {
            const key = option.group || 'Ungrouped';
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(option);
            return acc;
          },
          {} as Record<string, (typeof filteredOptions)[0][]>,
        );

        // Remove the "Ungrouped" group if it's empty
        if (groups.Ungrouped && groups.Ungrouped.length === 0) {
          delete groups.Ungrouped;
        }

        return groups;
      }, [filteredOptions]);

      const content = useMemo(
        () => (
          <VStack width={customOptionWidth}>
            {filteredOptions.length ? (
              Object.keys(groupedOptions).map((group) =>
                groupedOptions[group].length ? (
                  <VStack key={group}>
                    {Object.keys(groupedOptions).length > 1 && (
                      <Box spacing={2} spacingBottom={1} key={group}>
                        <TextCaption as="p">{group}</TextCaption>
                      </Box>
                    )}
                    {groupedOptions[group].map((optionProps) => (
                      <SelectOption
                        {...optionProps}
                        testID="dropdown-with-search-option"
                        key={`${group}-${optionProps.searchKey}`}
                      />
                    ))}
                  </VStack>
                ) : null,
              )
            ) : (
              <HStack spacingHorizontal={2} spacingVertical={2}>
                <TextCaption as="h2" color="foregroundMuted">
                  No results
                </TextCaption>
              </HStack>
            )}
          </VStack>
        ),
        [customOptionWidth, groupedOptions, filteredOptions.length],
      );

      const handleSearchChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => {
          const searchText = event.target.value;
          setSearchValue(searchText);
          onSearchChange?.(searchText);
          onChange('');
        },
        [onSearchChange, onChange],
      );

      const onChangeWrapper = useCallback(
        (selected: string) => {
          setSearchValue('');
          onSearchChange?.('');
          onChange(selected);
          blurSearchInput();
        },
        [onSearchChange, onChange, blurSearchInput],
      );

      /**
       * This is a hack to get around the fact that the dropdown doesn't
       * correctly register the first character typed after initial render due
       * to focus issues between the Dropdown and TextInput components.
       */
      const onKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (searchValue.length === 0) {
            if (e.key.length === 1) {
              setSearchValue(e.key);
              onSearchChange?.(e.key);
              onChange('');
            }
          }
        },
        [onChange, onSearchChange, searchValue],
      );

      const { isPhone } = useSimpleBreakpoints();

      return (
        <Dropdown
          testID={testID}
          ref={ref}
          width="100%"
          enableMobileModal
          minWidth={isPhone ? 385 : 600}
          value={valueLabel || searchValue}
          content={content}
          onChange={onChangeWrapper}
          onCloseMenu={onDropdownMenuChange}
          onOpenMenu={onDropdownMenuChange}
          onBlur={handleDropdownBlur}
          disableTypeFocus
          disablePortal
        >
          <TextInput
            testID="dropdown-with-search-input"
            width="100%"
            ref={searchInputRef}
            value={valueLabel || searchValue}
            placeholder={placeholder}
            onChange={handleSearchChange}
            onKeyDown={onKeyDown}
            onPress={onSearchInputPress}
            role="searchbox"
            disabled={disabled}
            tabIndex={tabIndex}
            start={startNode}
            end={
              <Box spacingHorizontal={2}>
                <Icon name={dropdownActive ? 'caretUp' : 'caretDown'} size="s" color="foreground" />
              </Box>
            }
          />
        </Dropdown>
      );
    },
  ),
);
