import { VStack } from '@cbhq/cds-web/layout';
import { Divider } from '@cbhq/cds-web/layout/Divider';
import { TextBody, TextLabel2, TextTitle3 } from '@cbhq/cds-web/typography';

import { ReactComponent as RelayNode } from ':cloud/assets/Protocols/Topology/relay.svg';
import { ReactComponent as SentryNodes } from ':cloud/assets/Protocols/Topology/sentry_nodes.svg';
import { useGetProtocolDisplayName } from ':cloud/queries/WorkflowQueries/useGetProtocolDisplayName';
import { ProtocolKey } from ':cloud/types/ts_types';
import { TopologyIcon } from ':cloud/widgets/sharedcomponents';

type SentryNodesProps = Record<ProtocolKey, { label: string }>;
const sentryNodes: SentryNodesProps = {
  cardano: { label: 'Relay nodes' },
  celo: { label: 'Proxy nodes' },
  cosmos: { label: 'Sentry nodes' },
  // eslint-disable-next-line camelcase
  crypto_dot_com: { label: 'Sentry nodes' },
  eth2: { label: 'Validators' },
  evmos: { label: 'Sentry nodes' },
  oasis: { label: 'Sentry nodes' },
  polkadot: { label: 'Sentry nodes' },
  provenance: { label: 'Sentry nodes' },
  terra: { label: 'Sentry nodes' },
};

const relayNodes = ['algorand', 'flow'];

interface ClusterHexedProps {
  protocolKey: ProtocolKey;
}

export function ClusterHexed({ protocolKey }: ClusterHexedProps) {
  const { displayName } = useGetProtocolDisplayName(protocolKey);
  const hasRelayNode = relayNodes.includes(protocolKey);
  const hasSentryNodes = Object.keys(sentryNodes).includes(protocolKey);

  return (
    <VStack alignItems="flex-start" gap={5}>
      <>
        <TextTitle3 as="h3" spacingBottom={1}>
          {displayName}
        </TextTitle3>
        <TextBody as="span">Participate Cluster</TextBody>
      </>
      <TopologyIcon protocolKey={protocolKey} />
      {hasSentryNodes && (
        <>
          <TextLabel2 as="span" spacingBottom={1}>
            {sentryNodes[protocolKey].label}
          </TextLabel2>
          <SentryNodes />
        </>
      )}
      {hasRelayNode && <Divider direction="horizontal" color="line" />}
      {hasRelayNode && (
        <>
          <TextBody as="span" spacingBottom={5}>
            Relay Cluster
          </TextBody>
          <RelayNode />
        </>
      )}
    </VStack>
  );
}
