import { useCallback, useMemo, useState } from 'react';
import noop from 'lodash/noop';
import { Button } from '@cbhq/cds-web/buttons';
import { Checkbox, NativeTextArea, Select } from '@cbhq/cds-web/controls';
import { TextInput } from '@cbhq/cds-web/controls/TextInput';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@cbhq/cds-web/overlays';
import { TextHeadline, TextLabel1, TextLabel2 } from '@cbhq/cds-web/typography';

import { useGetContractFunctions } from ':cloud/queries/Base/useGetContractFunctions';
import { WhitelistedAddressAndMethod } from ':cloud/types/ts_types';
import {
  getContractMethodsFromString,
  isValidContractAddress,
  isValidLabel,
  isValidMethodInput,
} from ':cloud/utils/paymaster';

type PaymasterAllowlistModalProps = {
  onRequestClose: () => void;
  onAdd: (whitelist: WhitelistedAddressAndMethod[]) => void;
  whitelistedAddressAndMethods: WhitelistedAddressAndMethod[];
};

function PaymasterAllowlistModal({
  onRequestClose,
  onAdd,
  whitelistedAddressAndMethods,
}: PaymasterAllowlistModalProps) {
  // functions fetched for contract via api
  const [contractFunctions, setContractFunctions] = useState<string[]>([]);
  // functions added via function selector
  const [smartContractFunctions, setSmartContractFunctions] = useState<string[]>([]);
  // functions added manually
  const [customFunctions, setCustomFunctions] = useState('');
  const [smartContractAddress, setSmartContractAddress] = useState('');
  const [addressFormatError, setAddressFormatError] = useState('');
  const [methodFormatError, setMethodFormatError] = useState('');
  const [contractName, setContractName] = useState('');

  const getContractFunctions = useGetContractFunctions({
    onSuccess: (funcs) => {
      setContractFunctions(funcs);
      // select all contracts by default if they exist
      if (funcs?.length) {
        setSmartContractFunctions(funcs.concat('all'));
      }
    },
  });

  const handleSmartContractAddressChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAddressFormatError('');
      setSmartContractAddress(event.target.value);
      setContractFunctions([]);
      setSmartContractFunctions([]);
      if (isValidContractAddress(event.target.value) && event.target.value.length) {
        getContractFunctions.mutate(event.target.value);
      }
    },
    [getContractFunctions],
  );

  const handleSmartContractAddressBlur = useCallback(() => {
    if (!isValidContractAddress(smartContractAddress)) {
      setAddressFormatError('Invalid address');
    }
  }, [smartContractAddress]);

  const handleSelectFunction = useCallback(
    (option) => {
      return () => {
        // user deselects all functions option
        if (option === 'all' && smartContractFunctions.includes('all')) {
          setSmartContractFunctions([]);
          return;
        }
        // user selects all functions option
        if (option === 'all') {
          const updatedFunctions = contractFunctions.concat('all');
          setSmartContractFunctions(updatedFunctions);
          return;
        }
        // user deselects function
        if (smartContractFunctions.includes(option)) {
          // remove function and deselect all functions option
          const updatedFunctions = smartContractFunctions.filter(
            (func) => func !== option && func !== 'all',
          );
          setSmartContractFunctions(updatedFunctions);
          return;
        }
        // if selecting last unselected option, select all functions option
        if (smartContractFunctions.length === contractFunctions.length - 1) {
          const updatedFunctions = smartContractFunctions.concat(option).concat('all');
          setSmartContractFunctions(updatedFunctions);
          return;
        }
        setSmartContractFunctions(smartContractFunctions.concat(option));
      };
    },
    [contractFunctions, smartContractFunctions],
  );

  const handleCancelPress = useCallback(() => {
    onRequestClose();
  }, [onRequestClose]);

  const handleAddPress = useCallback(() => {
    // If the address or method is invalid, don't add it to the list
    if (!isValidContractAddress(smartContractAddress)) {
      return;
    }

    const customFuncArray = getContractMethodsFromString(customFunctions);

    let functions: string[] = [];

    if (!smartContractFunctions.includes('all')) {
      // combine function selector funcs and custom funcs and then remove whitespace
      functions = smartContractFunctions
        .concat(customFuncArray)
        .map((str) => str.replace(/\s+/g, ''));
    }

    const whitelistItem: WhitelistedAddressAndMethod = {
      address: smartContractAddress,
      methods: functions,
      name: contractName,
    };

    onAdd(whitelistedAddressAndMethods.concat(whitelistItem));
    setSmartContractAddress('');
    setSmartContractFunctions([]);
    setContractFunctions([]);
    setCustomFunctions('');
    setContractName('');
    onRequestClose();
  }, [
    contractName,
    customFunctions,
    onAdd,
    onRequestClose,
    smartContractAddress,
    smartContractFunctions,
    whitelistedAddressAndMethods,
  ]);

  const handleSmartContractFunctionChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setMethodFormatError('');
      setCustomFunctions(event.target.value);
    },
    [],
  );

  const handleContractNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (isValidLabel(event.target.value)) {
      setContractName(event.target.value);
    }
  }, []);

  const handleSmartContractFunctionInputBlur = useCallback(() => {
    if (!isValidMethodInput(customFunctions)) {
      setMethodFormatError(
        'Must be comma-separated list of function signatures or function selectors',
      );
    }
  }, [customFunctions]);

  const functionSelectOptions = useMemo(() => {
    return contractFunctions?.map((option) => (
      <HStack spacingHorizontal={2} spacingVertical={1} gap={2}>
        <Checkbox
          value={option}
          onChange={handleSelectFunction(option)}
          checked={smartContractFunctions.includes(option)}
        />
        <TextHeadline as="p">{option}</TextHeadline>
      </HStack>
    ));
  }, [contractFunctions, smartContractFunctions, handleSelectFunction]);

  const selectValueLabel = useMemo(() => {
    if (smartContractFunctions.includes('all')) {
      return 'All functions';
    }
    return `${smartContractFunctions.length} function${
      smartContractFunctions.length === 1 ? '' : 's'
    }`;
  }, [smartContractFunctions]);

  const exceedsCharLimit = contractName?.length > 25;

  return (
    <Modal hideDividers onRequestClose={onRequestClose || noop} visible width={600}>
      <ModalHeader title="Add a contract" />
      <ModalBody>
        <VStack gap={3} spacingBottom={5}>
          <VStack>
            <TextInput
              type="text"
              label="Name"
              placeholder="Contract name"
              variant={exceedsCharLimit ? 'negative' : 'foregroundMuted'}
              value={contractName}
              onChange={handleContractNameChange}
            />
            <TextLabel2
              as="p"
              color={exceedsCharLimit ? 'negative' : 'foregroundMuted'}
              spacingTop={0.5}
            >
              {`${contractName.length}/25 characters`}
            </TextLabel2>
          </VStack>
          <VStack>
            <TextInput
              type="text"
              label="Contract Address"
              placeholder="0x123..."
              variant={addressFormatError ? 'negative' : 'foregroundMuted'}
              value={smartContractAddress}
              onChange={handleSmartContractAddressChange}
              onBlur={handleSmartContractAddressBlur}
            />
            <TextLabel2 as="p" color="foregroundMuted" spacingTop={0.5}>
              {addressFormatError || 'Address of your contract'}
            </TextLabel2>
          </VStack>
          {!!contractFunctions?.length && (
            <VStack spacingTop={0.5}>
              <TextLabel1 as="p" spacingBottom={1}>
                Function(s)
              </TextLabel1>
              <Select valueLabel={selectValueLabel}>
                <HStack spacingHorizontal={2} spacingVertical={1} gap={2} borderedBottom>
                  <Checkbox
                    value="all"
                    onChange={handleSelectFunction('all')}
                    checked={smartContractFunctions.includes('all')}
                  />
                  <TextHeadline as="p">All functions</TextHeadline>
                </HStack>
                {functionSelectOptions}
              </Select>
            </VStack>
          )}

          <VStack>
            <TextInput
              onBlur={handleSmartContractFunctionInputBlur}
              inputNode={
                <NativeTextArea
                  value={customFunctions}
                  onChange={handleSmartContractFunctionChange}
                  rows={7}
                  cols={5}
                  placeholder="Ex: mint(),mintTo(address,uint256),0x449a52f8"
                />
              }
              label={contractFunctions?.length ? 'Other functions' : 'Functions'}
            />
            <TextLabel2
              as="p"
              color={methodFormatError ? 'negative' : 'foregroundMuted'}
              spacingTop={1}
            >
              {methodFormatError ||
                'Allowlisted functions, comma separated (supports function signatures and function selectors)'}
            </TextLabel2>
          </VStack>
        </VStack>
      </ModalBody>
      <ModalFooter
        primaryAction={
          <Button
            variant="primary"
            onPress={handleAddPress}
            disabled={
              !isValidContractAddress(smartContractAddress) ||
              !smartContractAddress ||
              !!methodFormatError ||
              exceedsCharLimit
            }
          >
            Add
          </Button>
        }
        secondaryAction={
          <Button variant="secondary" onPress={handleCancelPress}>
            Cancel
          </Button>
        }
      />
    </Modal>
  );
}

export default PaymasterAllowlistModal;
