import { useCallback } from 'react';
import noop from 'lodash/noop';
import { Button } from '@cbhq/cds-web/buttons';
import { Pictogram } from '@cbhq/cds-web/illustrations';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { Modal, ModalBody } from '@cbhq/cds-web/overlays';
import { TextBody, TextTitle3 } from '@cbhq/cds-web/typography';

type DisableAccountAbstractionWarningModalProps = {
  onRequestClose: () => void;
  onConfirm: () => void;
};

function DisableAccountAbstractionWarningModal({
  onRequestClose,
  onConfirm,
}: DisableAccountAbstractionWarningModalProps) {
  const handleDeny = useCallback(() => {
    onRequestClose();
  }, [onRequestClose]);

  const handleConfirm = useCallback(() => {
    onConfirm();
    onRequestClose();
  }, [onConfirm, onRequestClose]);

  return (
    <Modal hideDividers onRequestClose={onRequestClose || noop} visible width={318}>
      <ModalBody>
        <VStack justifyContent="center" alignItems="center" spacingBottom={1} spacingTop={2}>
          <Pictogram name="warning" dimension="48x48" scaleMultiplier={2} />
          <TextTitle3 as="h1" align="center" spacingTop={2}>
            Disabling Paymaster & Bundler will stop sponsored transactions
          </TextTitle3>
          <TextBody as="p" color="foregroundMuted" align="center">
            Your users will no longer receive sponsored transactions.
          </TextBody>
        </VStack>
        <HStack spacingVertical={2} width="100%" gap={2}>
          <Button variant="secondary" onPress={handleDeny} block>
            Deny
          </Button>
          <Button variant="primary" onPress={handleConfirm} block>
            Confirm
          </Button>
        </HStack>
      </ModalBody>
    </Modal>
  );
}

export default DisableAccountAbstractionWarningModal;
