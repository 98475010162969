import { useEffect, useState } from 'react';

import useOnce from './useOnce';

type DefaultDimensions = { width?: number; height?: number };

function getDimensions(defaultDimensions?: DefaultDimensions): DefaultDimensions {
  if (typeof window === 'undefined') {
    return {
      width: defaultDimensions?.width || 0,
      height: defaultDimensions?.height || 0,
    };
  }
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

function useViewportDimensions(defaultDimensions?: DefaultDimensions): DefaultDimensions {
  const initialState = useOnce(() => getDimensions(defaultDimensions));
  const [dimensions, setDimensions] = useState(initialState);

  useEffect(() => {
    const listener = (): void => setDimensions(getDimensions());
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, []);

  return dimensions;
}

export default useViewportDimensions;
