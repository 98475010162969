import { HeroSquare } from '@cbhq/cds-web/illustrations';
import { VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

export function EmptyEarningsView() {
  return (
    <VStack justifyContent="center" alignItems="center" gap={5} spacingVertical={10} width="100%">
      <HeroSquare name="walletFlyEmptyState" scaleMultiplier={0.3} />
      <VStack maxWidth={230} gap={0.5}>
        <TextHeadline align="center" as="p">
          No earnings at this time
        </TextHeadline>
        <TextBody align="center" as="p" color="foregroundMuted">
          Rewards will be visible once profits are generated
        </TextBody>
      </VStack>
    </VStack>
  );
}
