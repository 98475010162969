import { useEffect, useState } from 'react';
import _capitalize from 'lodash/capitalize';
import _join from 'lodash/join';
import _map from 'lodash/map';
import _split from 'lodash/split';
import { ResponsiveProps } from '@cbhq/cds-web';
import { Button } from '@cbhq/cds-web/buttons';
import { Box, Grid, VStack } from '@cbhq/cds-web/layout';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { Link, TextHeadline, TextTitle1, TextTitle2 } from '@cbhq/cds-web/typography';

import { useTokens } from ':cloud/contexts/LegacyTokensContext';
import useLoadedLegacyAPITokens from ':cloud/hooks/useLoadedLegacyAPITokens';
import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { CLOUD_HELP } from ':cloud/utils/links';
import { twoColumnGrid } from ':cloud/utils/responsiveConfigs';
import { Layout } from ':cloud/widgets/layout';
import { CloudErrorBoundary } from ':cloud/widgets/sharedcomponents';
import { ReinforcedSecurity } from ':cloud/widgets/sidebar/ReinforcedSecurity';
import { CopyTokenDialog } from ':cloud/widgets/tokens/api/CopyTokenDialog';
import { CreateTokenDialog } from ':cloud/widgets/tokens/api/CreateTokenDialog';
import { TokenCard } from ':cloud/widgets/tokens/api/TokenCard';

const MAX_TOKENS = 5;

const responsiveConfig: ResponsiveProps = {
  phone: { flexDirection: 'column', gap: 2 },
  tablet: { flexDirection: 'row', gap: 2 },
  desktop: { flexDirection: 'row', gap: 2 },
};

function renderTokenServiceName(service: string): string {
  switch (service) {
    // TODO: Service name overrides. This should come external
    case 'eth2':
      return 'Eth2 Validator';
    default:
      return _join(_map(_split(service, '-'), _capitalize), ' ');
  }
}

function LegacyAPITokenManagementContent() {
  const { isPhone } = useSimpleBreakpoints();
  const toast = useToast();

  const [createTokenDialogOpen, setCreateTokenDialogOpen] = useState<boolean>(false);
  const [newToken, setNewToken] = useState<string>();

  const {
    APIServices,
    APITokens,
    isLoadingServices,
    isLoadingTokens,
    loadServices,
    setTokenNotifier,
    tokenNotifier,
  } = useTokens();

  useLoadedLegacyAPITokens();

  useEffect(() => {
    void (async function loadAPIServices(): Promise<void> {
      if (
        APIServices === undefined &&
        !isLoadingTokens &&
        !(tokenNotifier && tokenNotifier.type === 'error')
      ) {
        await loadServices();
      }
    })();
  }, [APIServices, isLoadingServices, isLoadingTokens, loadServices, tokenNotifier]);

  useEffect(() => {
    if (tokenNotifier) {
      toast.show(tokenNotifier.message, {
        variant: tokenNotifier.variant,
        onDidHide: (): void => {
          setTokenNotifier(undefined);
          toast.clearQueue();
        },
      });
    }
  }, [toast, tokenNotifier, setTokenNotifier]);

  const canMakeTokens: boolean = !APITokens || APITokens.length < MAX_TOKENS;

  return (
    <>
      <CreateTokenDialog
        visible={createTokenDialogOpen}
        setNewToken={setNewToken}
        setOpen={setCreateTokenDialogOpen}
        APITokens={APITokens}
        APIServices={APIServices}
        renderServiceLabel={renderTokenServiceName}
      />
      <CopyTokenDialog
        visible
        token={newToken}
        closeFn={(): void => {
          setNewToken(undefined);
        }}
      />

      <TextTitle2 as="h1" spacingBottom={10}>
        Configure Legacy ETh2 Validator API Tokens
      </TextTitle2>
      {APITokens && APITokens.length > 0 && (
        <VStack spacingBottom={9}>
          <TextTitle1 as="h2" spacingBottom={5}>
            {`API access tokens (${APITokens.length} of 5)`}
          </TextTitle1>
          <Grid gap={3} responsiveConfig={twoColumnGrid}>
            {APITokens.map((t) => {
              return (
                <TokenCard token={t} renderServiceLabel={renderTokenServiceName} key={t.name} />
              );
            })}
          </Grid>
        </VStack>
      )}
      <VStack
        gap={3}
        alignItems="center"
        borderColor="line"
        borderRadius="roundedSmall"
        spacing={4}
      >
        <Box
          responsiveConfig={responsiveConfig}
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <VStack minWidth="25%" flexGrow={1}>
            <TextHeadline as="p" align="center" color="foregroundMuted">
              API access
            </TextHeadline>
          </VStack>
          <Box display="block" spacingTop={isPhone ? 3 : 0}>
            <TextHeadline as="p" align={isPhone ? 'center' : 'start'}>
              {canMakeTokens ? (
                <>
                  Create and view eth2 validators on your platform, query indexes of the chain, and
                  build machine-to-machine integrations with our backend.
                </>
              ) : (
                <>
                  You&apos;ve reached your token limit.{' '}
                  <Link to={CLOUD_HELP} openInNewWindow underline={false}>
                    Contact us to increase your limit.
                  </Link>
                </>
              )}
            </TextHeadline>
          </Box>
        </Box>
        {canMakeTokens && (
          <Button
            onPress={(): void => setCreateTokenDialogOpen(true)}
            testID="api-token-mgmt-open-create-token"
            block
          >
            Create API access token
          </Button>
        )}
      </VStack>
    </>
  );
}

export function LegacyAPITokenManagement() {
  return (
    <Layout>
      <Layout.MainContainer>
        <CloudErrorBoundary name="LegacyAPITokenManagementContent">
          <LegacyAPITokenManagementContent />
        </CloudErrorBoundary>
      </Layout.MainContainer>
      <Layout.SidebarContainer>
        <Layout.ContentWrapper>
          <ReinforcedSecurity
            customText="For security reasons, API Access Tokens are not stored on our platform;
                                  you can only copy them once. They are long-lived and revokable at any time."
          />
        </Layout.ContentWrapper>
      </Layout.SidebarContainer>
    </Layout>
  );
}
