import { useCallback, useMemo } from 'react';
import { useToggler } from '@cbhq/cds-common';
import { usePrefixedId } from '@cbhq/cds-common/hooks/usePrefixedId';

export function useFlyoutState(id?: string) {
  const [isHovered, { toggleOn: handleOnMouseEnter, toggleOff: toggleOffIsHovered }] =
    useToggler(false);
  const [isFocused, { toggleOn: handleOnFocus, toggleOff: toggleOffIsFocused }] = useToggler(false);
  const flyoutId = usePrefixedId(id);

  const handleOnBlur = useCallback(() => {
    // TODO: Figure out why isFocused never stays set to true
    toggleOffIsFocused();
    toggleOffIsHovered();
  }, [toggleOffIsFocused, toggleOffIsHovered]);

  const handleOnMouseLeave = useCallback(() => {
    toggleOffIsHovered();
  }, [toggleOffIsHovered]);

  return useMemo(() => {
    return {
      isOpen: isHovered || isFocused,
      flyoutId,
      handleOnMouseEnter,
      handleOnMouseLeave,
      handleOnFocus,
      handleOnBlur,
      isFocused,
      isHovered,
    };
  }, [
    handleOnBlur,
    handleOnFocus,
    handleOnMouseEnter,
    handleOnMouseLeave,
    isFocused,
    isHovered,
    flyoutId,
  ]);
}
