import { useState } from 'react';

type CopiedValue = string | null;
type CopyFn = (textToCopy: string) => Promise<void>;

export function useCopyToClipboard(): [CopiedValue, CopyFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const copy: CopyFn = async (textToCopy) => {
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      try {
        await navigator.clipboard.writeText(textToCopy);
        setCopiedText(textToCopy);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('Navigator API unavailable', error);
        setCopiedText(null);
      }
    } else {
      // Fallback method
      const textArea = document.createElement('textarea');
      textArea.value = textToCopy;

      // Avoid scrolling to bottom
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        setCopiedText(textToCopy);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        textArea.remove();
      }
    }
  };

  return [copiedText, copy];
}
