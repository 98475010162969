import { useCallback, useEffect, useState } from 'react';
import { useToggler } from '@cbhq/cds-common/hooks/useToggler';
import { Button } from '@cbhq/cds-web/buttons';
import { InputIconButton, TextInput } from '@cbhq/cds-web/controls';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { Modal, ModalBody, ModalProps } from '@cbhq/cds-web/overlays';
import { TextBody, TextLabel2 } from '@cbhq/cds-web/typography';

import { useCopyToClipboard } from ':cloud/hooks/useCopyToClipboard';
import { CloudModalHeader } from ':cloud/widgets/sharedcomponents';

interface CopyTokenDialogProps
  extends Omit<ModalProps, 'children' | 'onRequestClose' | 'className'> {
  token?: string;
  closeFn: () => void;
}

/* only used in LegacyAPITokenManagement */
export function CopyTokenDialog({ token = '', closeFn, ...props }: CopyTokenDialogProps) {
  const [isTokenVisible, { toggle: showTokenToggle }] = useToggler();
  const [type, setType] = useState<string>('text');

  const [copied, setCopied] = useState<boolean>(false);
  const [, copy] = useCopyToClipboard();
  const handleCopy = useCallback(async () => {
    await copy(token);
    setCopied(true);
  }, [copy, token]);

  useEffect(() => {
    if (!token) {
      setCopied(false);
    }
  }, [token]);

  useEffect(() => {
    setType(isTokenVisible ? 'text' : 'password');
  }, [isTokenVisible]);

  return (
    <Modal
      {...props}
      visible={!!token}
      onRequestClose={(): void => closeFn()}
      hideDividers
      testID="copy-token-dialog"
    >
      <CloudModalHeader title="Copy API Access Token" />
      <ModalBody>
        <TextBody as="p" spacingBottom={7}>
          This token will be active until it is revoked.
        </TextBody>
        <VStack gap={3}>
          <TextInput
            label="API Access Token"
            variant={copied ? 'positive' : 'foregroundMuted'}
            end={
              <InputIconButton
                aria-label="toggle password visibility"
                onPress={showTokenToggle}
                name={isTokenVisible ? 'visibleActive' : 'visibleInactive'}
              />
            }
            testID="copy-token-dialog-token-input"
            type={type}
            value={token}
            readOnly
          />
          <TextLabel2 as="p" color="negative">
            Warning: For security reasons, this token is only available to copy once. If you miss
            this step, you will need to create a new token.
          </TextLabel2>
        </VStack>
      </ModalBody>
      <HStack
        spacingHorizontal={3}
        spacingVertical={2}
        justifyContent="flex-end"
        width="100%"
        gap={2}
      >
        <Button testID="copy-token-dialog-copy-button" block onPress={handleCopy}>
          {copied ? 'Copied' : 'Copy'}
        </Button>
      </HStack>
    </Modal>
  );
}
