import { useInteractableHeight } from '@cbhq/cds-common/hooks/useInteractableHeight';
import { useModalParent } from '@cbhq/cds-common/overlays/ModalParentContext';
import { ModalHeaderBaseProps } from '@cbhq/cds-common/types/ModalBaseProps';
import { IconButton } from '@cbhq/cds-web/buttons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextTitle1 } from '@cbhq/cds-web/typography';

type CloudModalHeaderProps = {
  /** Handles back button press */
  onBackButtonPress?: React.MouseEventHandler;
} & Omit<ModalHeaderBaseProps, 'onRequestClose' | 'onBackButtonPress'>;

export function CloudModalHeader({
  title,
  onBackButtonPress,
}: CloudModalHeaderProps): JSX.Element | null {
  const height = useInteractableHeight(true);
  const { onRequestClose, accessibilityLabelledBy, hideCloseButton, hideDividers } =
    useModalParent();

  if (!title && !onBackButtonPress && !onRequestClose) {
    return null;
  }

  // use empty placeholder which has the same size as IconButton to maintain horizontal position
  const emptyPlaceholder = <Box height={height} width={height} />;

  return (
    <VStack spacingHorizontal={3} spacingVertical={2} borderedBottom={!hideDividers}>
      <HStack alignItems="center" borderedBottom={!hideDividers}>
        <TextTitle1 as="span" id={accessibilityLabelledBy}>
          {title}
        </TextTitle1>
        <Box flexGrow={1} justifyContent="flex-end">
          {!hideCloseButton ? (
            <IconButton
              transparent
              name="close"
              onPress={onRequestClose}
              testID="modal-close-button"
            />
          ) : (
            emptyPlaceholder
          )}
        </Box>
      </HStack>
    </VStack>
  );
}
