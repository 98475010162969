import { ProductItem } from ':cloud/types/ts_types';
import { AppRoute } from ':cloud/utils/routes';

enum ProductFilterCategory {
  ALL = 'All',
  STAKING = 'Staking',
  PAYMENTS = 'Payments',
  WALLETS = 'Wallets',
  OFF_PLATFORM = 'Off platform',
}

export const INTEGRATED_PRODUCTS: ProductItem[] = [
  {
    navigationIcon: 'base',
    spotIconName: 'nodeProduct',
    title: 'Node',
    shortDesc: 'Read and write to Base with free, rate limited RPC endpoints',
    altDescription: 'Free RPC endpoint for Base',
    description: 'Read and write to Base with free, rate limited RPC endpoints',
    linkToRoute: AppRoute.Products.Base,
    hasButton: true,
    categories: [],
    productTag: 'Preview',
  },
  {
    navigationIcon: 'advancedTradeProduct',
    spotIconName: 'advancedTradeProduct',
    productType: 'rat',
    title: 'Advanced Trade',
    shortDesc: 'Automate your trades',
    description: 'Automate your trading & order management and obtain up-to-date market data',
    linkToRoute: AppRoute.Products.AdvancedTrade,
    categories: [],
  },
  {
    navigationIcon: 'waasProduct',
    spotIconName: 'walletAsAServiceProduct',
    iconName: 'waasProduct',
    title: 'Wallets',
    productType: 'waas_consumer',
    altDescription: '',
    description: 'Give your users access to wallets natively within your app',
    shortDesc: 'Onboard your customers to web3',
    linkToRoute: AppRoute.Products.Wallets,
    hasButton: true,
    categories: [ProductFilterCategory.WALLETS],
  },
  {
    navigationIcon: 'staking',
    spotIconName: 'pieChart',
    title: 'Paymaster',
    description: 'Sponsor gas on behalf of your users and send transactions from smart wallets',
    linkToRoute: AppRoute.Products.BundlerAndPaymaster,
    hasButton: true,
    categories: [],
  },
  {
    navigationIcon: 'staking',
    spotIconName: 'stakingProduct',
    title: 'Staking',
    productType: 'staking_api',
    altDescription: 'Free',
    description:
      'Simplify complex staking operations so that you can easily and quickly build staking products for your end users.',
    shortDesc: 'Maximize your customer rewards',
    linkToRoute: AppRoute.Products.Staking,
    hasButton: true,
    categories: [],
  },
  {
    navigationIcon: 'participateProduct',
    spotIconName: 'participateProduct',
    title: 'Participate',
    productType: 'participate',
    description:
      'Launch private, dedicated validators and staking nodes for your own funds or your customers.',
    shortDesc: 'Launch private validators and staking nodes',
    linkToRoute: AppRoute.Products.Participate,
    productTag: 'Enterprise',
    hasButton: true,
    categories: [ProductFilterCategory.STAKING],
  },
  {
    navigationIcon: 'exchangeProduct',
    spotIconName: 'exchangeProduct',
    title: 'Exchange',
    productType: 'exchange',
    description:
      'An Institutional API for direct access to our spot exchange for trading and market data',
    shortDesc: 'Access our spot for trading and market data',
    linkToRoute: AppRoute.Products.Exchange,
    hasButton: true,
    categories: [],
  },
];
