import { createContext, Dispatch, SetStateAction, useContext } from 'react';

import { IAPIService, IAPIToken, INotifierInfo, IResourceToken } from ':cloud/types/ts_types';

type TokensContextType = {
  APIServices?: IAPIService[];
  APITokens?: IAPIToken[];
  createAPIToken: (token: Pick<IAPIToken, 'name' | 'services'>) => Promise<IAPIToken | undefined>;
  createTokenForResource: (
    resourceId: string,
    token: IResourceToken,
  ) => Promise<IResourceToken | undefined>;
  isLoadingServices: boolean;
  isLoadingTokens: boolean;
  loadAllAPITokens: () => Promise<void>;
  loadServices: () => Promise<void>;
  loadTokensForResource: (resourceId: string) => Promise<IResourceToken[]>;
  revokeAPIToken: (token: IAPIToken) => Promise<boolean>;
  revokeTokenForResource: (resourceId: string, token: IResourceToken) => Promise<void>;
  setTokenNotifier: Dispatch<SetStateAction<INotifierInfo | undefined>>;
  tokenNotifier?: INotifierInfo;
  updateTokenForResource: (
    resourceId: string,
    token: IResourceToken,
  ) => Promise<{
    success: boolean;
  }>;
};

export const LegacyTokensContext = createContext<TokensContextType | undefined>(undefined);

/**
 * Used for legacy API token management
 */
export function useTokens(): TokensContextType {
  const context = useContext(LegacyTokensContext);
  if (context === undefined) {
    throw new Error('useLegacyTokens must be used within a LegacyTokensProvider');
  }
  return context;
}
