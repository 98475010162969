import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { CreateOrUpdateProjectResponse, UpdateProjectRequest } from ':cloud/types/service_proto';

async function updateProject({
  id,
  name,
  organizationId,
}: UpdateProjectRequest): Promise<CreateOrUpdateProjectResponse> {
  const path = `/organization-management/v1/organizations/${organizationId}/projects/${id}`;
  const requestBody = {
    project: {
      id,
      organizationId,
      name,
    },
    updateMask: 'name',
  };
  const { data } = await APIClientBase.put<CreateOrUpdateProjectResponse>(path, requestBody);
  return data;
}

// currently you can only update the project's name
export function useUpdateProject() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, name, organizationId }: UpdateProjectRequest) =>
      updateProject({ id, name, organizationId }),
    onSuccess: async (_, { organizationId }) => {
      await queryClient.invalidateQueries([QueryKey.projects, organizationId]);
    },
    meta: {
      errorMsg: 'Unable to update project. Please retry',
      successMsg: 'Project updated',
    },
  });
}
