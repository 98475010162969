import { CSSProperties, Dispatch, SetStateAction, useMemo } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { BorderRadius, PartialPaletteConfig } from '@cbhq/cds-web';
import { Button } from '@cbhq/cds-web/buttons';
import { Select, SelectOption } from '@cbhq/cds-web/controls';
import { Icon } from '@cbhq/cds-web/icons/Icon';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { Pressable, ThemeProvider } from '@cbhq/cds-web/system';
import { ThemeProvider as CDSThemeProvider } from '@cbhq/cds-web/system/ThemeProvider';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

import { cdsAliasToCssVar, colors } from ':cloud/brand/colors';
import { CodeBlockLanguage } from ':cloud/types/ts_types';

import { ClipboardIcon } from '../ClipboardIcon';

import { darkTheme, lightTheme } from './styles';

const lineNumberStyle: CSSProperties = { width: 32, color: cdsAliasToCssVar('foregroundMuted') };
const lineProps = { style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } };

const palette: PartialPaletteConfig = {
  foreground: 'gray0',
  foregroundMuted: 'gray5',
  background: 'gray90',
  primary: 'gray0',
};

const mapLanguageToLabel = {
  bash: 'cURL',
  javascript: 'Node',
  json: 'json',
  python: 'Python',
};

export type CodeBlockCode = { language: CodeBlockLanguage; code: string };

export interface CodeBlockProps {
  codeArr: CodeBlockCode[];
  title?: string;
  responseStatus?: number;
  showLineNumbers?: boolean;
  showLanguageSelect?: boolean;
  showCopy?: boolean;
  showDownload?: boolean;
  showRun?: boolean;
  onDownload?: () => void;
  onRun?: () => void;
  customStyle?: CSSProperties;
  headerBackground?: string;
  option?: number;
  setOption?: Dispatch<SetStateAction<number>>;
  displayDarkTheme?: boolean;
  dangerouslySetColor?: string;
  enableLineWrap?: boolean;
  placeholder?: string;
  minHeight?: number;
  bordered?: boolean;
  borderRadius?: BorderRadius;
  removeLinebreaks?: boolean;
}

const defaultCustomStyle = {
  padding: '25px 10px',
  background: 'rgb(var(--gray90))',
  overflow: 'clip',
};

type CodeBlockSelectPalletteProps = { children: React.ReactChild };

function CodeBlockSelectPallette({ children }: CodeBlockSelectPalletteProps) {
  return <ThemeProvider palette={palette}>{children}</ThemeProvider>;
}

export function CodeBlock({
  codeArr,
  title,
  responseStatus,
  showLineNumbers = true,
  showLanguageSelect = true,
  showCopy = true,
  showDownload = false,
  showRun = false,
  onDownload,
  onRun,
  option = 0,
  customStyle,
  setOption,
  headerBackground = colors.gray90,
  displayDarkTheme = true,
  dangerouslySetColor = 'background',
  enableLineWrap = false,
  placeholder,
  minHeight,
  borderRadius = 'rounded',
  bordered = true,
  removeLinebreaks = true,
}: CodeBlockProps) {
  const { code, language } = codeArr[option];
  const wideHeader = showLanguageSelect || showCopy;

  const selectDisabled = codeArr.length === 1;

  const mergedCustomStyles = useMemo(() => {
    return { ...defaultCustomStyle, ...customStyle };
  }, [customStyle]);

  return (
    <CDSThemeProvider scale="xSmall" spectrum="light">
      <VStack
        bordered={bordered}
        borderRadius={borderRadius}
        overflow="hidden"
        minHeight={minHeight}
        dangerouslySetBackground={headerBackground}
      >
        <HStack
          alignItems="center"
          spacingTop={3}
          spacingHorizontal={5}
          dangerouslySetBackground={headerBackground}
          justifyContent="space-between"
        >
          <HStack justifyContent="space-between" alignItems="center" width="100%">
            {title && (
              <TextHeadline
                dangerouslySetColor={dangerouslySetColor}
                as="p"
                spacingVertical={wideHeader ? 0 : 0.5}
              >
                {title}
              </TextHeadline>
            )}
            {responseStatus !== undefined && (
              <HStack gap={1}>
                <TextBody as="p" color="foregroundMuted">
                  Status:
                </TextBody>
                <TextHeadline as="p" color={responseStatus === 200 ? 'positive' : 'negative'}>
                  {responseStatus}
                </TextHeadline>
              </HStack>
            )}
          </HStack>
          <HStack alignItems="center" justifyContent="space-between" gap={4}>
            {showLanguageSelect && (
              <CodeBlockSelectPallette>
                <Select
                  disabled={selectDisabled}
                  compact
                  width={150}
                  value={mapLanguageToLabel[language]}
                  onChange={(v: string) => {
                    if (!selectDisabled && setOption) {
                      setOption(+v);
                    }
                  }}
                >
                  {codeArr.map(({ language: lang }, index) => (
                    <SelectOption
                      key={lang}
                      value={index.toString()}
                      title={mapLanguageToLabel[lang]}
                    />
                  ))}
                </Select>
              </CodeBlockSelectPallette>
            )}
            {showCopy && (
              <ClipboardIcon
                text={code}
                color={dangerouslySetColor}
                removeLinebreaks={language === 'bash' && removeLinebreaks}
              />
            )}
            {showDownload && (
              <Pressable as="button" onPress={onDownload} background="transparent">
                <HStack justifyContent="center" alignItems="center" gap={2}>
                  <TextHeadline as="span" dangerouslySetColor="background">
                    Download{' '}
                  </TextHeadline>
                  <Icon size="m" name="download" dangerouslySetColor="background" />{' '}
                </HStack>
              </Pressable>
            )}
          </HStack>
        </HStack>
        {placeholder ? (
          <Box background="secondary" spacingTop={5} justifyContent="center">
            <TextBody
              as="p"
              color="foregroundMuted"
              spacingHorizontal={5}
              spacingVertical={3}
              align="center"
              mono
            >
              {placeholder}
            </TextBody>
          </Box>
        ) : (
          <SyntaxHighlighter
            language={language}
            showLineNumbers={showLineNumbers}
            style={displayDarkTheme ? darkTheme : lightTheme}
            customStyle={mergedCustomStyles}
            lineNumberStyle={lineNumberStyle}
            wrapLines
            lineProps={enableLineWrap ? lineProps : undefined}
          >
            {code}
          </SyntaxHighlighter>
        )}

        {showRun && (
          <HStack spacing={2} dangerouslySetBackground={headerBackground} justifyContent="flex-end">
            <Button onPress={onRun}>Run</Button>
          </HStack>
        )}
      </VStack>
    </CDSThemeProvider>
  );
}
