import { useMemo, useState } from 'react';
import axios from 'axios';

import { LegacyTokensContext } from ':cloud/contexts/LegacyTokensContext';
import { logError } from ':cloud/init/bugsnag/logging';
import { APIClientRM } from ':cloud/state/Api';
import {
  GenericProviderProps,
  IAPIConfigResult,
  IAPIService,
  IAPIToken,
  INotifierInfo,
  IResourceToken,
} from ':cloud/types/ts_types';
import { getErrorMessage } from ':cloud/utils/errors';

/**
 * Used for legacy API token management
 */
export default function LegacyTokensProvider({ children }: GenericProviderProps) {
  const [APITokens, setAPITokens] = useState<IAPIToken[]>();
  const [isLoadingTokens, setIsLoadingTokens] = useState(false);
  const [tokenNotifier, setTokenNotifier] = useState<INotifierInfo>();
  const [APIServices, setAPIServices] = useState<IAPIService[]>();
  const [isLoadingServices, setIsLoadingServices] = useState(false);

  const loadAllAPITokens = async (): Promise<void> => {
    setIsLoadingTokens(true);
    try {
      const {
        data: { result },
      } = await APIClientRM.get<{ result: IAPIToken[] }>('/v1/token-api/tokens');
      setAPITokens(result);
    } catch (e) {
      setTokenNotifier({
        type: 'error',
        variant: 'negative',
        message: getErrorMessage(e, 'An error occurred when loading tokens. Please retry'),
      });
      logError(e, { context: 'tokens_provider' });
    }
    setIsLoadingTokens(false);
  };

  const createAPIToken = async (
    token: Pick<IAPIToken, 'name' | 'services'>,
  ): Promise<IAPIToken | undefined> => {
    try {
      const {
        data: { result },
      } = await APIClientRM.post<{ result: IAPIToken }>(
        '/v1/token-api/tokens',
        JSON.stringify(token),
      );
      return result;
    } catch (e) {
      setTokenNotifier({
        type: 'error',
        variant: 'negative',
        message: getErrorMessage(e, 'An error occurred when creating the token. Please retry'),
      });
      logError(e, { context: 'tokens_provider' });
      return undefined;
    }
  };

  const revokeAPIToken = async (token: IAPIToken): Promise<boolean> => {
    try {
      await APIClientRM.delete('/v1/token-api/tokens', {
        data: JSON.stringify({ name: token.name }),
      });
      setTokenNotifier({
        type: 'success',
        variant: 'positive',
        message: 'Your API Access Token has been revoked.',
      });

      return true;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 500) {
        setTokenNotifier({
          type: 'error',
          variant: 'negative',
          message: 'An error occurred when revoking the token. Please retry',
        });
      }
      logError(e, { context: 'tokens_provider' });
      return false;
    }
  };

  const createTokenForResource = async (
    resourceId: string,
    token: IResourceToken,
  ): Promise<IResourceToken | undefined> => {
    const path = `/v1/resources/${resourceId}/tokens`;
    try {
      const { data } = await APIClientRM.post<{ token: IResourceToken }>(path, token);
      if (data) {
        return data.token;
      }
      return undefined;
    } catch (e) {
      setTokenNotifier({
        type: 'error',
        variant: 'negative',
        message: getErrorMessage(
          e,
          `An error occurred when creating ${token.name} token. Please retry`,
          true,
        ),
      });
      logError(e, { context: 'tokens_provider' });
      return undefined;
    }
  };

  const revokeTokenForResource = async (
    resourceId: string,
    token: IResourceToken,
  ): Promise<void> => {
    const path = `/v1/resources/${resourceId}/tokens/${token.id}`;
    try {
      await APIClientRM.delete(path);
      setTokenNotifier({
        type: 'success',
        variant: 'positive',
        message: `${token.name} has been revoked.`,
      });
    } catch (e) {
      setTokenNotifier({
        type: 'error',
        variant: 'negative',
        message: getErrorMessage(e, 'An error occurred when revoking the token. Please retry'),
      });
      logError(e, { context: 'tokens_provider' });
    }
  };

  const loadTokensForResource = async (resourceId: string): Promise<IResourceToken[] | []> => {
    const path = `/v1/resources/${resourceId}/tokens`;
    setIsLoadingTokens(true);
    try {
      const { data } = await APIClientRM.get<{ tokens: IResourceToken[] }>(path);
      if (data) {
        return data.tokens;
      }
      return [];
    } catch (e) {
      setTokenNotifier({
        type: 'error',
        variant: 'negative',
        message: getErrorMessage(e, 'An error occurred when loading tokens. Please retry'),
      });
      logError(e, { context: 'tokens_provider' });
    } finally {
      setIsLoadingTokens(false);
    }

    return [];
  };

  const updateTokenForResource = async (
    resourceId: string,
    token: IResourceToken,
  ): Promise<{ success: true }> => {
    const path = `/v1/resources/${resourceId}/tokens/${token.id}`;
    try {
      await APIClientRM.put(path, token);
      setTokenNotifier({
        type: 'success',
        variant: 'positive',
        message: `${token.name} has been updated.`,
      });
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 500) {
        setTokenNotifier({
          type: 'error',
          variant: 'negative',
          message: getErrorMessage(e, 'An error occurred when updating your token. Please retry'),
        });
      }
      logError(e, { context: 'tokens_provider' });
    }

    return { success: true };
  };

  const loadServices = async (): Promise<void> => {
    setIsLoadingServices(true);
    try {
      const {
        data: { result },
      } = await APIClientRM.get<{ result: IAPIConfigResult }>('/v1/token-api/config');
      setAPIServices(result.services);
    } catch (e) {
      setTokenNotifier({
        type: 'error',
        variant: 'negative',
        message: getErrorMessage(
          e,
          'An error occurred when loading available services. Please retry',
        ),
      });
      logError(e, { context: 'tokens_provider' });
    } finally {
      setIsLoadingServices(false);
    }
  };

  const value = useMemo(
    () => ({
      APIServices,
      APITokens,
      createAPIToken,
      createTokenForResource,
      isLoadingServices,
      isLoadingTokens,
      loadAllAPITokens,
      loadServices,
      loadTokensForResource,
      revokeAPIToken,
      revokeTokenForResource,
      setTokenNotifier,
      tokenNotifier,
      updateTokenForResource,
    }),
    [APIServices, APITokens, isLoadingServices, isLoadingTokens, tokenNotifier],
  );

  return <LegacyTokensContext.Provider value={value}>{children}</LegacyTokensContext.Provider>;
}
