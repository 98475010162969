import { useHistory } from 'react-router-dom';
import { css } from '@linaria/core';
import type { CellSpacing } from '@cbhq/cds-web';
import { ListCell } from '@cbhq/cds-web/cells/ListCell';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { TextLabel2 } from '@cbhq/cds-web/typography';

import { useIsDeveloperMode } from ':cloud/hooks/useIsDeveloperMode';
import { useGetResourceLimits } from ':cloud/queries/ResourceQueries/useGetResourceLimits';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { useGetUserPermissions } from ':cloud/queries/UserQueries/useGetUserPermissions';
import { useGetWorkflowsBy } from ':cloud/queries/WorkflowQueries/useGetWorkflows';
import { ClusterType, ICustomerLimits, IWorkflow } from ':cloud/types/ts_types';
import { getAdapter } from ':cloud/utils/clusters';
import { CLOUD_SALES } from ':cloud/utils/links';
import { openUrlInNewWindow } from ':cloud/utils/openUrlInNewWindow';
import { LoadingSpinner, ProtocolIcon } from ':cloud/widgets/sharedcomponents';

const listOuterSpacing: CellSpacing = { spacingVertical: 0 };

const devModeStyles = css`
  outline: 2px solid rgb(var(--purple60));
  outline-offset: -1px;
  border-radius: 8px;
`;

function ProtocolListCell({
  cluster,
  customerLimits,
  canManageClusters = false,
}: ProtocolListCellProps) {
  const { isDeveloperMode } = useIsDeveloperMode();
  const history = useHistory();
  const toast = useToast();
  const adapter = getAdapter();
  const isHidden = cluster.visibility === 'hidden';
  const inDevelopment = cluster.visibility === 'development';
  const isActive = cluster.visibility === 'active';

  const acceptedVisibilityStatuses = isHidden || inDevelopment || isActive;
  if (!acceptedVisibilityStatuses) {
    return null;
  }

  /* hide protocols with "hidden" or "development" visibility status unless in developer mode */
  if (!isDeveloperMode && (isHidden || inDevelopment)) {
    return null;
  }

  let clusterCount = 0;
  let clusterLimit = 0;
  if (customerLimits?.[cluster.name]) {
    ({ clusterCount, clusterLimit } = customerLimits[cluster.name]);
  }
  const hasRemainingClusters = clusterLimit > 0 && clusterCount < clusterLimit;
  const highlight = isDeveloperMode && !isActive;

  const handleNodeCreation = () => {
    const customerView = !isDeveloperMode;
    // allow creation in dev mode
    if (isDeveloperMode) {
      // notify internal users to increase cluster limits
      if (!hasRemainingClusters) {
        return toast.show('Internal only: Increase cluster limits', { hideCloseButton: true });
      }
      return history.push(`${adapter.clusterRoute}/new/${cluster.name}`);
    }
    if (customerView && !canManageClusters) {
      openUrlInNewWindow(CLOUD_SALES);
    }
    if (customerView && isActive && hasRemainingClusters) {
      return history.push(`${adapter.clusterRoute}/new/${cluster.name}`);
    }
    openUrlInNewWindow(CLOUD_SALES);
    return null;
  };

  return (
    <VStack spacing={0.5} key={cluster.name} className={highlight ? devModeStyles : ''}>
      <ListCell
        outerSpacing={listOuterSpacing}
        title={cluster.display}
        description={cluster.tickerSymbol}
        detail={
          <HStack justifyContent="flex-end">
            {isHidden && <TextLabel2 as="p">internal only</TextLabel2>}
            {inDevelopment && <TextLabel2 as="p">in development</TextLabel2>}
          </HStack>
        }
        onPress={handleNodeCreation}
        media={<ProtocolIcon size={24} protocolKey={cluster.name} />}
      />
    </VStack>
  );
}

type ProtocolListProps = { clusterType: ClusterType };

/* only used in Participate */
export function ProtocolList({ clusterType }: ProtocolListProps) {
  const { workflows, isLoading: isWorkflowLoading } = useGetWorkflowsBy(
    (wf) => wf.clusterType === clusterType,
  );
  const { activeOrg } = useGetUser();
  const { resourceLimits, isLoading: isLimitsLoading } = useGetResourceLimits(
    activeOrg?.organizationId,
  );
  const { permissions } = useGetUserPermissions(activeOrg?.organizationId);

  if (isWorkflowLoading || isLimitsLoading) {
    return <LoadingSpinner />;
  }

  if (workflows.length === 0) {
    return null;
  }

  return (
    <VStack>
      {workflows.map((cluster) => (
        <ProtocolListCell
          key={cluster.name}
          cluster={cluster}
          customerLimits={resourceLimits}
          canManageClusters={permissions.manageResources}
        />
      ))}
    </VStack>
  );
}

type ProtocolListCellProps = {
  cluster: IWorkflow;
  customerLimits?: ICustomerLimits;
  canManageClusters?: boolean;
};
