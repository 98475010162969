import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useToast } from '@cbhq/cds-web/overlays/useToast';

import { EventName, logClick } from ':cloud/init/clientAnalytics/logging';
import { useCreateProject } from ':cloud/queries/Projects/useCreateProject';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { AppRoute } from ':cloud/utils/routes';

export function useCreateProjectAndPushRoute() {
  const { activeOrg } = useGetUser();
  const organizationId = activeOrg?.organizationId || '';
  const createProjectMutation = useCreateProject();
  const history = useHistory();
  const toast = useToast();

  return useCallback(() => {
    logClick(EventName.create_project);

    createProjectMutation.mutate(
      {
        // unique name is generated on the backend
        name: '',
        products: [],
        organizationId,
      },
      {
        onSuccess(data) {
          // @ts-expect-error -- @zack.wilder maintaining backwards compatibility until COMS FE cleanup
          const projectId = data?.id || data?.project?.id;
          history.push(`${AppRoute.Projects.Home}/${projectId}`);
        },
        onError(error) {
          if (error instanceof AxiosError) {
            if (error.response?.data.message === 'project would exceed limit') {
              toast.show('You have reached the maximum number of projects allowed.', {
                variant: 'negative',
              });
            } else {
              toast.show('Unable to create project. Please retry', {
                variant: 'negative',
              });
            }
          }
        },
      },
    );
  }, [createProjectMutation, history, organizationId, toast]);
}
