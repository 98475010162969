import { NativeTextArea, Select, SelectOption, Switch, TextInput } from '@cbhq/cds-web/controls';
import { Icon } from '@cbhq/cds-web/icons/Icon';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { Tooltip } from '@cbhq/cds-web/overlays';
import { TextBody, TextHeadline, TextLabel1 } from '@cbhq/cds-web/typography';

import { ServicesOffered } from ':cloud/types/service_proto';
import { ErrorsState, RequiredState } from ':cloud/widgets/access/oauth/types';

const servicesOffered: {
  label: string;
  value: keyof ServicesOffered;
  description: string;
}[] = [
  {
    label: 'Remittances',
    value: 'remittances',
    description: 'Cross border transfers without an associated sale of goods',
  },
  {
    label: 'Financial services',
    value: 'financialServices',
    description: 'Include holding funds on behalf of customers, offering credit or escrow',
  },
  {
    label: 'Gambling',
    value: 'gambling',
    description: 'Accepting payment in exchange for the chance of winning a prize',
  },
  {
    label: 'Currency exchange',
    value: 'currencyExchange',
    description: 'Include holding funds on behalf of customers, offering credit or escrow',
  },
];

interface OAuthRequiredStepsProps {
  requiredState: RequiredState;
  errors: ErrorsState;
  handleRequiredInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleSelectChange: (newValue: string) => void;
  handleSwitchChange: (service: keyof ServicesOffered) => void;
}

export function OAuthRequiredSteps({
  requiredState,
  errors,
  handleRequiredInputChange,
  handleSelectChange,
  handleSwitchChange,
}: OAuthRequiredStepsProps) {
  const servicesSwitches = servicesOffered.map((service) => (
    <HStack alignItems="center" gap={2} key={service.label}>
      <VStack width={500}>
        <TextHeadline as="label">{service.label}</TextHeadline>
        <TextBody as="label">{service.description}</TextBody>
      </VStack>
      <Switch
        checked={requiredState.servicesOffered[service.value]}
        onChange={() => handleSwitchChange(service.value)}
      />
    </HStack>
  ));

  return (
    <VStack gap={2}>
      <TextInput
        label="Application name"
        name="name"
        value={requiredState.name}
        onChange={handleRequiredInputChange}
        placeholder="My client name"
        variant={errors.name ? 'negative' : undefined}
        helperText={
          errors.name ||
          'Your application name will be displayed to users during OAuth client connection.'
        }
      />
      <Select
        label="Application type"
        value={requiredState.appType}
        onChange={handleSelectChange}
        placeholder="Select a category type"
        variant={errors.appType ? 'negative' : undefined}
        helperText={errors.appType || ' '}
      >
        {[
          'eCommerce',
          'Micropayments or Programmable Payments',
          'Bitcoin Wallet',
          'Gaming',
          'Financial Services',
          'Other',
        ].map((type) => (
          <SelectOption value={type} key={type} title={type} />
        ))}
      </Select>
      <HStack alignItems="center" gap={0.5}>
        <TextLabel1 as="label">
          Do you anticipate offering any of the following services?
        </TextLabel1>
        <Tooltip content="Certain 3rd party activities may require us to learn a bit more about your application or company, to protect you and our users.">
          <Icon name="info" size="s" color="currentColor" />
        </Tooltip>
      </HStack>
      {servicesSwitches}
      <TextInput
        inputNode={
          <NativeTextArea
            rows={3}
            value={requiredState.redirectUris}
            onChange={handleRequiredInputChange}
            placeholder="https://example.com/callback"
            name="redirectUris"
          />
        }
        variant={errors.redirectUris ? 'negative' : undefined}
        name="redirectUris"
        label="Redirect URIs"
        helperText={
          errors.redirectUris ||
          'Enter one or more URIs where users should go after signing in. Each URI should be on a new line. They will be sent here once signed in via OAuth. For more information, see our documentation.'
        }
      />
    </VStack>
  );
}
