import _isEmpty from 'lodash/isEmpty';
import _partition from 'lodash/partition';
import _some from 'lodash/some';
import { Grid, VStack } from '@cbhq/cds-web/layout';

import { twoColumnGrid } from ':cloud/utils/responsiveConfigs';

import { ICloudProvider } from '../types';
import { RegionSelectWidget } from '../widgets';
import { type OptionType } from '../widgets/SelectWidget';

interface RegionSelectFieldProps {
  formContext: {
    hasSubmitted: boolean;
    formData: Record<string, any>;
    clusterType: string;
    order: string[];
  };
  formData?: any;
  rawErrors?: string[];
  schema: {
    type: string;
    providers: ICloudProvider[];
    // TODO: fill uniqueTo
    uniqueTo?: string[];
  };
  required: boolean;
  disabled: boolean;
}

/* only used in Participate */
export function RegionSelectField({
  formContext,
  formData,
  rawErrors,
  schema,
  required,
  disabled,
  ...inputProps
}: RegionSelectFieldProps) {
  const { formData: data } = formContext;
  const exclude = schema.uniqueTo?.map((ut: any) => data[ut] as string).filter(Boolean);
  const [requiredError, validationErrors] = _partition(rawErrors, (e) => e === 'required');
  const hasError =
    (!_isEmpty(requiredError) && formContext.hasSubmitted) || !_isEmpty(validationErrors);

  return (
    <VStack spacingTop={5}>
      <Grid gap={3} responsiveConfig={twoColumnGrid}>
        {schema.providers.map((provider) => {
          const selected = _some(
            provider.regions,
            (region: OptionType): boolean => region.value === formData,
          );
          return (
            <RegionSelectWidget
              key={provider.key}
              provider={provider}
              exclude={exclude}
              selected={selected}
              value={selected ? formData : undefined}
              error={hasError}
              required={required}
              disabled={disabled}
              {...inputProps}
            />
          );
        })}
      </Grid>
    </VStack>
  );
}
