import { memo } from 'react';
import { VStack } from '@cbhq/cds-web/layout';
import { Link, TextLegal } from '@cbhq/cds-web/typography';

import { CLOUD_TOS } from ':cloud/utils/links';

export const DisclaimerText = memo((): JSX.Element => {
  return (
    <VStack spacingBottom={5}>
      <TextLegal as="p" color="foregroundMuted">
        By clicking below, you agree to{' '}
        <Link to={CLOUD_TOS} openInNewWindow color="primary">
          Terms
        </Link>{' '}
        and authorize a potential monthly recurring charge based on your usage of CDP products. All
        payments are securely processed by Stripe. View Stripe&apos;s{' '}
        <Link to="https://stripe.com/terms" openInNewWindow>
          Terms
        </Link>{' '}
        and{' '}
        <Link to="https://stripe.com/privacy" openInNewWindow>
          Privacy Policy
        </Link>
        .
      </TextLegal>
    </VStack>
  );
});
