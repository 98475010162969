import _round from 'lodash/round';
import { Grid, GridColumn } from '@cbhq/cds-web/layout';
import { palette } from '@cbhq/cds-web/tokens';

import { useGetMetricKeys } from ':cloud/queries/WorkflowQueries/useGetWorkflows';
import { IMetricsRoot, ProtocolKey } from ':cloud/types/ts_types';
import { bytesToGigaBit } from ':cloud/utils/metrics';
import { ConnectedPeers } from ':cloud/widgets/charts/ConnectedPeers';
import NodeUptimeChart from ':cloud/widgets/charts/NodeUptimeChart';
import { RadialChart } from ':cloud/widgets/charts/RadialChart';
import { TextLabelGray } from ':cloud/widgets/sharedcomponents';

import { getMetricsByQueryKey, IChartPointType, RadialColumn } from './ChartUtils';

interface NodeMetricChartProps {
  cpuUsagePercentKey?: string;
  memoryUsageKey?: string;
  memoryUsagePercentKey?: string;
  metrics: IMetricsRoot;
  peersKey?: string;
  protocolKey: ProtocolKey;
  uptimeChartKey?: string;
}

/** used in MetricsTab */
export function NodeMetricChart({
  cpuUsagePercentKey,
  memoryUsageKey,
  memoryUsagePercentKey,
  metrics,
  peersKey,
  protocolKey,
  uptimeChartKey,
}: NodeMetricChartProps) {
  const { metricKeys } = useGetMetricKeys(protocolKey);

  function formatPercent(x: string): number {
    return _round(parseFloat(x) * 100);
  }
  const cpuUtilization = getMetricsByQueryKey(
    metrics,
    cpuUsagePercentKey || (metricKeys?.cpuUsagePercentKey as string),
    formatPercent,
  );
  const connectedPeers = getMetricsByQueryKey(
    metrics,
    peersKey || (metricKeys?.peersKey as string),
    (x: string) => parseInt(x, 10),
  ) as number;
  const memoryUsage = getMetricsByQueryKey(
    metrics,
    memoryUsageKey || (metricKeys?.memoryUsageKey as string),
    (x: string) => bytesToGigaBit(parseInt(x, 10)),
  );
  const memoryUsagePercent = getMetricsByQueryKey(
    metrics,
    memoryUsagePercentKey || (metricKeys?.memoryUsagePercentKey as string),
    formatPercent,
  );
  const uptimeValues = getMetricsByQueryKey(
    metrics,
    uptimeChartKey || (metricKeys?.uptimeChartKey as string),
    undefined,
    (x: string) => (parseFloat(x) * 100).toFixed(2),
  ) as IChartPointType[];

  return (
    <Grid gap={3} columns={12}>
      <GridColumn gridColumn="1 / 7" justifyContent="space-around">
        <RadialColumn>
          <TextLabelGray align="center">CPU utilization</TextLabelGray>
          <RadialChart
            radialPercent={cpuUtilization && Number(cpuUtilization)}
            color={palette.primary}
            unit="%"
          />
        </RadialColumn>

        <RadialColumn>
          <TextLabelGray align="center">Memory usage</TextLabelGray>
          <RadialChart
            value={memoryUsage && Number(memoryUsage)}
            radialPercent={memoryUsage && Number(memoryUsagePercent)}
            color={palette.positive}
            unit="GB"
          />
        </RadialColumn>

        {Boolean(connectedPeers) && (
          <RadialColumn>
            <TextLabelGray align="center">Connected peers</TextLabelGray>
            <ConnectedPeers active={connectedPeers} />
          </RadialColumn>
        )}

        {/*  md={5} xs={12} */}
      </GridColumn>
      <GridColumn gridColumn="7 / -1">
        <NodeUptimeChart data={uptimeValues} />
      </GridColumn>
    </Grid>
  );
}
