import { useCallback, useState } from 'react';
import { ResponsiveGridProps } from '@cbhq/cds-web';
import { Button, IconButton } from '@cbhq/cds-web/buttons';
import { SelectOption } from '@cbhq/cds-web/controls';
import { Dropdown } from '@cbhq/cds-web/dropdown';
import { Grid, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextTitle3 } from '@cbhq/cds-web/typography';

import { DashboardProvider } from ':cloud/contexts/DashboardProvider';
import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { EventName, useLogViewOnMount } from ':cloud/init/clientAnalytics/logging';
import { Chart } from ':cloud/widgets/earnings/Chart';
import { defaultEarningsCharts, earningsCharts, RANGE } from ':cloud/widgets/earnings/config';
import { EarningsReport } from ':cloud/widgets/earnings/EarningsReport';
import { EarningsRange } from ':cloud/widgets/earnings/types';
import { Layout } from ':cloud/widgets/layout';
import { CloudErrorBoundary } from ':cloud/widgets/sharedcomponents';

const responsiveGridConfig: ResponsiveGridProps = {
  phone: {
    columns: 1,
  },
  tablet: {
    columns: 1,
  },
  desktop: {
    columns: 1,
  },
};
const content = Object.entries(RANGE).map(([key, title]) => (
  <SelectOption borderRadius="rounded" key={key} value={key} title={title} />
));

function EarningsContent() {
  const { isPhone } = useSimpleBreakpoints();
  const [range, setRange] = useState<EarningsRange>('currentMonth');

  const handleSwitchChartRange = useCallback(
    (value: EarningsRange) => {
      setRange(value);
    },
    [setRange],
  );

  return (
    <VStack gap={10}>
      <VStack gap={3}>
        <HStack justifyContent="space-between" alignItems="center">
          <TextTitle3 as="h1">{defaultEarningsCharts.title}</TextTitle3>

          <HStack alignItems="center">
            <Dropdown
              value={range}
              onChange={(value) => handleSwitchChartRange(value)}
              content={<VStack width={208}>{content}</VStack>}
              enableMobileModal
            >
              {isPhone ? (
                <IconButton name="more" />
              ) : (
                <Button compact endIcon="caretDown" variant="secondary">
                  {RANGE[range]}
                </Button>
              )}
            </Dropdown>
          </HStack>
        </HStack>

        <Grid responsiveConfig={responsiveGridConfig} gap={5}>
          {earningsCharts.charts.map((chart) => (
            <Chart {...chart} key={chart.key} range={range} />
          ))}
        </Grid>

        <Grid responsiveConfig={responsiveGridConfig}>
          <EarningsReport />
        </Grid>
      </VStack>
    </VStack>
  );
}

export function EarningsPage() {
  useLogViewOnMount(EventName.earnings_page_view);
  return (
    <Layout>
      <Layout.MainContainer fullWidth>
        <CloudErrorBoundary name="EarningsContent">
          <DashboardProvider>
            <EarningsContent />
          </DashboardProvider>
        </CloudErrorBoundary>
      </Layout.MainContainer>
    </Layout>
  );
}
