import { useMemo } from 'react';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { Spinner } from '@cbhq/cds-web/loaders/Spinner';

import { useGetWorkflows } from ':cloud/queries/WorkflowQueries/useGetWorkflows';
import { ICluster } from ':cloud/types/ts_types';
import { getProtocolDisplayName } from ':cloud/utils/protocols';
import { ProtocolIcon } from ':cloud/widgets/sharedcomponents';

import {
  DropdownWithSearch,
  DropdownWithSearchOption,
} from '../sharedcomponents/DropdownWithSearch';

interface ClusterSearchProps {
  clusters: ICluster[];
  selectedOption?: DropdownWithSearchOption;
  onSelectOption: (value: any) => void;
}

export type SearchGroup = 'Clusters' | 'Protocols';

export function ClusterSearch({ clusters, selectedOption, onSelectOption }: ClusterSearchProps) {
  const { workflows, isLoading: isWorkflowsLoading } = useGetWorkflows();

  const onlyProtocols: string[] = Array.from(
    new Set(clusters.map((cluster) => cluster.type)),
  ).sort();

  const protocolOptions: DropdownWithSearchOption[] = onlyProtocols.map((protocolKey) => ({
    group: 'Protocols',
    title: getProtocolDisplayName(workflows, protocolKey),
    searchKey: `${protocolKey}`,
    value: protocolKey,
    media: <ProtocolIcon protocolKey={protocolKey} />,
  }));

  const clusterOptions: DropdownWithSearchOption[] = clusters
    .map((option) => ({
      group: 'Clusters',
      title: option.name,
      description: getProtocolDisplayName(workflows, option.type),
      searchKey: `${option.type}-${option.name}`,
      value: `${option.type}-${option.name}`,
    }))
    .sort((a, b) => a.title.localeCompare(b.title));

  const protocolAndClusterOptions = useMemo(() => {
    return [...protocolOptions, ...clusterOptions];
  }, [clusterOptions, protocolOptions]);

  const selectedOptionLabel = useMemo(() => {
    const value = protocolAndClusterOptions.find((option: DropdownWithSearchOption): boolean => {
      return option?.searchKey?.toLowerCase() === selectedOption?.searchKey?.toLowerCase();
    });
    return selectedOption ? `${value?.title}` : '';
  }, [protocolAndClusterOptions, selectedOption]);

  const handleSelectOption = (value: any) => {
    const selected = protocolAndClusterOptions.find((option: DropdownWithSearchOption): boolean => {
      return option.searchKey === value;
    });
    onSelectOption(selected);
  };

  if (isWorkflowsLoading) {
    return <Spinner size={4} />;
  }

  return (
    <VStack width="100%">
      <DropdownWithSearch
        placeholder="Search for a cluster or protocol"
        options={protocolAndClusterOptions}
        valueLabel={selectedOptionLabel}
        onChange={handleSelectOption}
        customOptionWidth="100%"
        startNode={
          <Box spacingStart={2} spacingEnd={1}>
            <Icon name="search" size="m" color="foreground" />
          </Box>
        }
      />
    </VStack>
  );
}
