// appx 4 characters (we use compact formatting, e.g. 2.5K, 1.2M)
const LEFT_SPACING_ADJUSTMENT = 24;

export const defaultChartProps = { margin: { left: -LEFT_SPACING_ADJUSTMENT } };

export const defaultYAxisProps = { tickLine: false, axisLine: false };

export function defaultYAxisTickFormatter(tickItem: string) {
  const tickItemNumber = Number(tickItem);
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 2,
  }).format(tickItemNumber);
}
