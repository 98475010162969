import { VStack } from '@cbhq/cds-web/layout';
import { TableCell, TableRow } from '@cbhq/cds-web/tables';

export function EmptyTable({ children }) {
  return (
    <TableRow>
      <TableCell alignItems="center" justifyContent="center" colSpan={5}>
        <VStack spacing={10} justifyContent="center" alignItems="center" gap={1}>
          {children}
        </VStack>
      </TableCell>
    </TableRow>
  );
}
