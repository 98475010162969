import { CellSpacing } from '@cbhq/cds-web';
import { Cell, overflowClassName } from '@cbhq/cds-web/cells/Cell';
import { Box } from '@cbhq/cds-web/layout';
import { TextBody } from '@cbhq/cds-web/typography';

import { ClipboardIcon } from './ClipboardIcon';

type TextWithCopyIconProps = {
  text: string;
};

const outerSpacing: CellSpacing = { spacingVertical: 0 };
const innerSpacing: CellSpacing = { spacingVertical: 0.5 };

export function TextWithCopyIcon({ text }: TextWithCopyIconProps) {
  return (
    <Box
      borderRadius="roundedSmall"
      background="backgroundAlternate"
      display="flex"
      spacing={2}
      flexGrow={1}
    >
      <Cell
        outerSpacing={outerSpacing}
        innerSpacing={innerSpacing}
        compact
        priority="end"
        detail={
          <Box justifyContent="flex-end">
            <ClipboardIcon text={text} />
          </Box>
        }
      >
        <TextBody as="p" color="foregroundMuted" overflow="wrap" className={overflowClassName}>
          {text}
        </TextBody>
      </Cell>
    </Box>
  );
}
