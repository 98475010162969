import { useQuery } from '@tanstack/react-query';

import { APIClientBase } from ':cloud/state/Api';

import { QueryKey } from '../types';

type GetPaymasterInfoResponse = {
  chainId: number;
  ethSponsored: number;
  organizationId: string;
  projectId: string;
  sponsoredTransactionCount: number;
  usdSponsored: number;
};

type GetPaymasterInfoProps = {
  organizationId: string;
  projectId: string;
  isTestnet?: boolean;
};

async function getPaymasterInfo({
  organizationId,
  projectId,
  isTestnet,
}: GetPaymasterInfoProps): Promise<GetPaymasterInfoResponse> {
  const basePath = '/rpc/api/paymaster/projectInfo';
  const chainId = isTestnet ? 84532 : 8453;
  const { data } = await APIClientBase.get<GetPaymasterInfoResponse>(
    `${basePath}?organizationId=${organizationId}&projectId=${projectId}&chainId=${chainId}`,
  );
  return data;
}

type UseGetPaymasterInfoProps = {
  organizationId?: string;
  projectId?: string;
  isTestnet?: boolean;
};

export function useGetPaymasterInfo({
  organizationId = '',
  projectId = '',
  isTestnet = false,
}: UseGetPaymasterInfoProps) {
  const { isLoading, data } = useQuery({
    queryKey: [QueryKey.paymasterInfo, organizationId, projectId, isTestnet],
    queryFn: async () => getPaymasterInfo({ organizationId, projectId, isTestnet }),
    staleTime: Infinity,
    enabled: !!projectId && !!organizationId,
  });

  return {
    isLoading,
    paymasterInfo: data,
  };
}
