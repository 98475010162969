import { Select, SelectOption, SelectProps } from '@cbhq/cds-web/controls';

export interface OptionType {
  label: string;
  value: string;
}

type SelectWidgetProps = {
  error: boolean;
  fullWidth?: boolean;
  label?: string;
  options: { enumOptions: OptionType[]; enumDisabled?: string[] };
  placeholder?: string;
  value?: any;
  selected: boolean;
  required: boolean;
  disabled: boolean;
} & Omit<SelectProps, 'children'>;

export function SelectWidget({
  disabled,
  error = false,
  fullWidth = false,
  label = '',
  options,
  placeholder = 'Select one',
  value,
  ...inputProps
}: SelectWidgetProps) {
  const { enumOptions, enumDisabled } = options;
  const valueLabel = enumOptions.find((option: OptionType): boolean => option.value === value)
    ?.label;

  return (
    <Select
      disabled={disabled}
      label={label}
      placeholder={placeholder}
      testID={`selectWidget${label}`}
      value={value}
      valueLabel={valueLabel}
      variant={error ? 'negative' : 'foreground'}
      width={fullWidth ? '100%' : undefined}
      {...inputProps}
    >
      {enumOptions.map((option: OptionType) => (
        <SelectOption
          key={option.value}
          value={option.value}
          disabled={enumDisabled?.includes(option.value)}
          title={option.label}
        />
      ))}
    </Select>
  );
}
