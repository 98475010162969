import { useEffect, useMemo } from 'react';
import { noop } from '@cbhq/cds-utils';
import { Button } from '@cbhq/cds-web/buttons';
import { SpotSquare } from '@cbhq/cds-web/illustrations/SpotSquare';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { Modal, ModalBody, ModalHeader, ModalProps } from '@cbhq/cds-web/overlays';
import { TextBody, TextLabel1, TextTitle3 } from '@cbhq/cds-web/typography';

import { wordBreakAll } from ':cloud/brand/utilityClasses';
import { useEdwardsKey } from ':cloud/hooks/featureFlags/useEdwardsKey';
import { EventName, useLogViewOnMount } from ':cloud/init/clientAnalytics/logging';
import { useLogViewMetricOnMount } from ':cloud/init/clientAnalytics/metricsLogging';
import { IPlatformAPIKey } from ':cloud/types/ts_types';
import { generateJsonFile } from ':cloud/utils/files';
import { ClipboardIcon, LoadingSpinner } from ':cloud/widgets/sharedcomponents';

type APIKeySnippetModalProps = Partial<ModalProps> & {
  createdKey?: IPlatformAPIKey;
  isLoading?: boolean;
  isErrored?: boolean;
};

export function APIKeySuccessModal(props: APIKeySnippetModalProps): JSX.Element {
  const { onRequestClose, createdKey, isLoading, isErrored } = props;

  useLogViewOnMount(EventName.api_key_success_modal_view, { apiKeyType: createdKey?.keyType });
  useLogViewMetricOnMount('trading_api_key_create');

  useEffect(() => {
    if (createdKey && !isErrored) {
      const downloadObject = { name: createdKey.name, privateKey: createdKey.privateKey };
      generateJsonFile(downloadObject, '.cdp_api_key');
    }
  }, [createdKey, isErrored]);

  const apiKeyName = useMemo(() => createdKey?.name || '', [createdKey?.name]);

  // ensure "\n" shows up as text
  const apiPrivateKey = useMemo(() => {
    const stringValue = JSON.stringify(createdKey?.privateKey || '');
    const withoutQuotes = stringValue.replace(/^"|"$/g, ''); // Removes leading and trailing quotes
    return withoutQuotes;
  }, [createdKey?.privateKey]);

  const isEdwardsKeyEnabled = useEdwardsKey();

  return (
    <Modal
      hideDividers
      disableOverlayPress
      onRequestClose={onRequestClose || noop}
      visible
      shouldCloseOnEscPress={false}
      {...props}
      width={480}
    >
      <ModalHeader title="" />
      <ModalBody alignItems="center">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <VStack spacing={1}>
            <VStack gap={1} spacingBottom={4}>
              <HStack justifyContent="space-around" spacingBottom={3}>
                <SpotSquare name="securityShield" dimension="96x96" scaleMultiplier={0.66} />
              </HStack>

              <TextTitle3 as="p" align="center">
                Keep your key safe
              </TextTitle3>
              <Box width="90%" spacingBottom={6}>
                <TextBody as="p" color="foregroundMuted" align="center">
                  Your API key has been successfully created and downloaded. For security, it
                  can&apos;t be re-downloaded. If lost, delete and create a new one.
                </TextBody>
              </Box>

              <VStack gap={0.5} spacingBottom={2}>
                <TextLabel1 as="p">{isEdwardsKeyEnabled ? 'API key' : 'API key name'}</TextLabel1>{' '}
                <HStack
                  justifyContent="space-between"
                  gap={10}
                  spacing={2}
                  alignItems="center"
                  background="secondary"
                  borderRadius="roundedSmall"
                >
                  <VStack alignItems="center">
                    <TextBody as="p" mono color="foregroundMuted" className={wordBreakAll}>
                      {apiKeyName}
                    </TextBody>
                  </VStack>
                  <ClipboardIcon text={createdKey?.name || ''} />
                </HStack>
              </VStack>
              <VStack gap={0.5} spacingBottom={2}>
                <TextLabel1 as="p">Secret</TextLabel1>
                <HStack
                  justifyContent="space-between"
                  gap={10}
                  spacing={2}
                  alignItems="center"
                  background="secondary"
                  borderRadius="roundedSmall"
                >
                  <VStack alignItems="center">
                    <TextBody as="p" mono color="foregroundMuted" className={wordBreakAll}>
                      {apiPrivateKey}
                    </TextBody>
                  </VStack>
                  <ClipboardIcon text={apiPrivateKey} />
                </HStack>
              </VStack>
            </VStack>

            <HStack spacingBottom={2}>
              <Button
                variant="secondary"
                type="submit"
                onPress={onRequestClose}
                testID="submit"
                block
              >
                I&apos;ve saved my key
              </Button>
            </HStack>
          </VStack>
        )}
      </ModalBody>
    </Modal>
  );
}
