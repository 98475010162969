import { Helmet } from 'react-helmet';
import _capitalize from 'lodash/capitalize';

import config from ':cloud/config';

function envAppTitle(env: string): string {
  return env === 'production' ? 'Developer Platform' : `${_capitalize(env)} - Developer Platform`;
}

export function AppHelmet() {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />

      {/* manifest.json provides metadata used when your web app is added to the
      homescreen on Android. See https://developers.google.com/web/fundamentals/web-app-manifest/ */}
      <link rel="manifest" href="/static/manifest.json" />
      <link rel="icon" type="image/png" href="/static/favicon.ico" />

      <script type="text/javascript">{`
        var href = window.location.href;
        var origin = window.location.origin;

        if (
          origin === 'https://app.bisontrails.co' ||
          origin === 'https://console.cloud.coinbase.com'
        ) {
          var redirect = href.replace(origin, '${config.deployedHost}');
          window.location.replace(redirect);
        }
      `}</script>

      <title>Developer Platform</title>
      <title>{envAppTitle(config.environmentName)}</title>
      <meta
        name="description"
        content="Build your first app in minutes with Base, onramps, wallets, and more."
      />
    </Helmet>
  );
}
