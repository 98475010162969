import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { APIClientCOMSLegacy } from ':cloud/state/Api';

const errorMsg = 'Failed to decline invite. Please retry';

async function rejectInvite(organizationId: string, email: string): Promise<boolean> {
  const path = `organization-management/v1/organizations/${organizationId}/invite/reject`;
  const { data } = await APIClientCOMSLegacy.post<boolean>(path, { email });
  return data;
}

export function useRejectInvite() {
  const queryClient = useQueryClient();
  const { user } = useGetUser();
  return useMutation<boolean, Error, string>({
    mutationFn: async (organizationId) => rejectInvite(organizationId, user.email),
    meta: { errorMsg },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKey.self, 'invites']);
    },
  });
}
