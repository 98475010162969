import { useHistory } from 'react-router-dom';
import { Button } from '@cbhq/cds-web/buttons';
import { HeroSquare } from '@cbhq/cds-web/illustrations';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { TextHeadline } from '@cbhq/cds-web/typography';

import { AppRoute } from ':cloud/utils/routes';

type EmptyStateProps = {
  text?: string;
};

export function EmptyState({
  text = 'You are not subscribed to any products yet.',
}: EmptyStateProps) {
  const history = useHistory();
  const handlePress = (): void => {
    history.push(AppRoute.Home);
  };
  return (
    <Box justifyContent="center">
      <VStack spacing={10} justifyContent="center" alignItems="center" gap={3}>
        <HeroSquare name="squidEmptyState" />
        <TextHeadline as="p" spacingTop={2}>
          {text}
        </TextHeadline>
        <Button onPress={handlePress}>Start a project</Button>
      </VStack>
    </Box>
  );
}
