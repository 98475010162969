import { useCallback, useMemo } from 'react';
import { useToggler } from '@cbhq/cds-common/hooks/useToggler';
import { Button } from '@cbhq/cds-web/buttons/Button';
import { Collapsible } from '@cbhq/cds-web/collapsible/Collapsible';
import { NativeTextArea, TextInput } from '@cbhq/cds-web/controls';
import { useA11yControlledVisibility } from '@cbhq/cds-web/hooks/useA11yControlledVisibility';
import { Icon } from '@cbhq/cds-web/icons/Icon';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { Interactable } from '@cbhq/cds-web/system';
import { Pressable } from '@cbhq/cds-web/system/Pressable';
import { Link, TextBody, TextHeadline, TextLabel1 } from '@cbhq/cds-web/typography';

import { useLogoUpload } from ':cloud/hooks/useLogoUpload';
import { ErrorsState, OptionalState, UploadedFile } from ':cloud/widgets/access/oauth/types';
import { TextLabelGray } from ':cloud/widgets/sharedcomponents';

interface OAuthOptionalStepsProps {
  optionalState: OptionalState;
  errors: ErrorsState;
  handleOptionalInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleLogoUpload: (file: UploadedFile) => void;
  handleLogoRemove: () => void;
}

const inputStyles = { display: 'none' };

export default function OAuthOptionalSteps({
  optionalState,
  errors,
  handleOptionalInputChange,
  handleLogoUpload,
  handleLogoRemove,
}: OAuthOptionalStepsProps) {
  const [collapsed, { toggle }] = useToggler(true);

  const { triggerAccessibilityProps, controlledElementAccessibilityProps } =
    useA11yControlledVisibility(!collapsed, {
      accessibilityLabel: 'cds-collapsible',
      hasPopupType: 'true',
    });

  const {
    uploadedFile,
    handleFileChange,
    handleUploadClick,
    handleFileRemove,
    handleDragOver,
    handleDrop,
    fileInputRef,
    sizeError,
  } = useLogoUpload({
    onFileUpload: handleLogoUpload,
    onFileRemove: handleLogoRemove,
  });

  const getLogoErrorMessage = useCallback(() => {
    if (sizeError === 'fileSize') {
      return 'File size exceeds 500KB limit. Please choose a smaller file.';
    }
    if (sizeError === 'dimensions') {
      return 'Image dimensions exceed 500x500 pixels. Please choose a smaller image.';
    }
    return '';
  }, [sizeError]);

  const caretDir = useMemo(() => (collapsed ? 'caretDown' : 'caretUp'), [collapsed]);

  const logoDrop = useMemo(
    () => (
      <Pressable
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onPress={handleUploadClick}
        as="button"
        background="transparent"
      >
        <Box
          borderRadius="rounded"
          justifyContent="center"
          bordered
          background="transparent"
          spacing={2}
        >
          <VStack justifyContent="center" alignItems="center" gap={2}>
            <Icon color="foreground" name="upload" size="m" />
            <TextLabel1 as="p">Upload or drag and drop an image</TextLabel1>
            <Button>Choose File</Button>
            <input
              key={uploadedFile?.name}
              ref={fileInputRef}
              type="file"
              accept="image/png, image/jpeg"
              style={inputStyles}
              onChange={handleFileChange}
            />
          </VStack>
        </Box>
      </Pressable>
    ),
    [
      handleDrop,
      handleDragOver,
      handleUploadClick,
      uploadedFile?.name,
      fileInputRef,
      handleFileChange,
    ],
  );

  return (
    <>
      <Interactable
        as="button"
        background="transparent"
        onClick={toggle}
        {...triggerAccessibilityProps}
        width="100%"
      >
        <HStack spacingVertical={2} gap={2} alignItems="center">
          <TextHeadline as="sub">Advanced options</TextHeadline>
          <TextBody as="sub">(Optional)</TextBody>
          <Icon size="s" name={caretDir} />
        </HStack>
      </Interactable>
      <Collapsible collapsed={collapsed} {...controlledElementAccessibilityProps}>
        <VStack width="100%" gap={2}>
          {uploadedFile ? (
            <HStack gap={3} alignItems="center">
              <Box height="126px" borderRadius="rounded" overflow="hidden">
                <img
                  src={uploadedFile.url}
                  alt={uploadedFile.name}
                  height="126px"
                  style={{ objectFit: 'cover' }}
                />
              </Box>
              <VStack gap={1}>
                <TextBody as="p">{uploadedFile.name}</TextBody>
                <Button onClick={handleFileRemove} variant="secondary">
                  Remove
                </Button>
              </VStack>
            </HStack>
          ) : (
            <>
              {logoDrop}
              {sizeError && (
                <TextBody as="p" color="negative">
                  {getLogoErrorMessage()}
                </TextBody>
              )}
            </>
          )}
          <TextInput
            label="Developer Website"
            name="developerWebsite"
            value={optionalState.developerWebsite}
            onChange={handleOptionalInputChange}
            placeholder="https://my-cryptosite.com"
            helperText={errors.developerWebsite || "Developer's website. Optional."}
            variant={errors.developerWebsite ? 'negative' : undefined}
          />
          <TextInput
            label="Description"
            inputNode={
              <NativeTextArea
                name="description"
                value={optionalState.description}
                onChange={handleOptionalInputChange}
                rows={3}
                placeholder="Simplify crypto transfers between friends."
              />
            }
          />
          <TextInput
            label="Notification URL (coming soon)"
            name="notificationsUrl"
            disabled
            value={optionalState.notificationsUrl}
            onChange={handleOptionalInputChange}
            placeholder="https://www.example.com/notifications"
            helperText={
              errors.notificationsUrl || (
                <TextLabelGray as="sub">
                  Webhook for notifications. For more information,{' '}
                  <Link
                    openInNewWindow
                    href="https://docs.cdp.coinbase.com/sign-in-with-coinbase/docs/notifications"
                  >
                    see our documentation.
                  </Link>
                </TextLabelGray>
              )
            }
            variant={errors.notificationsUrl ? 'negative' : undefined}
          />
        </VStack>
      </Collapsible>
    </>
  );
}
