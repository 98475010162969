import { useQuery } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { GetProductsResponse } from ':cloud/types/service_proto';
import { Product } from ':cloud/types/ts_types';

async function fetchProducts(): Promise<GetProductsResponse> {
  const { data } = await APIClientBase.get<GetProductsResponse>(`/billing/v1/products`);
  return data;
}

export function useGetProducts(): { products: Product[]; isLoading: boolean } {
  const { isLoading, data } = useQuery({
    queryKey: [QueryKey.products],
    queryFn: fetchProducts,
    staleTime: Infinity,
  });

  return {
    products: data?.products ?? [],
    isLoading,
  };
}
