import { useQuery } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { GetBillingHistoryItemsResponse } from ':cloud/types/service_proto';
import { BillingHistoryItem } from ':cloud/types/ts_types';

async function fetchBillingHistoryItems(orgId): Promise<GetBillingHistoryItemsResponse> {
  const { data } = await APIClientBase.get<GetBillingHistoryItemsResponse>(
    `/billing/v1/accounts/${orgId}/invoices`,
  );
  return data;
}

function generateBillingHistoryItemsQuery({ orgId, enabled }) {
  return {
    queryKey: [QueryKey.billing, 'account-history-items', orgId],
    queryFn: async () => fetchBillingHistoryItems(orgId),
    enabled,
    staleTime: Infinity,
  };
}

export function useGetBillingHistoryItems(orgId?: string): {
  isLoading: boolean;
  billingHistoryItems: BillingHistoryItem[];
} {
  const { isLoading, data } = useQuery(
    generateBillingHistoryItemsQuery({ orgId, enabled: !!orgId }),
  );
  return {
    isLoading,
    billingHistoryItems: data?.accountInvoices || [],
  };
}
