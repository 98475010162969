type ConfigKeyType =
  | 'appAnalyticsName'
  | 'accountsURL'
  | 'amplitudeKey'
  | 'apiURL'
  | 'apiPortfolioURL'
  | 'apiBaseURL'
  | 'appVersion'
  | 'assets'
  | 'authUnifiedLoginURL'
  | 'bugsnagKey'
  | 'appSwitcherProjectId'
  | 'coinbaseURL'
  | 'confirmTwoFactorProjectId'
  | 'deployedHost'
  | 'developerAPIURL'
  | 'environmentName'
  | 'experimentsURL'
  | 'killSwitchURL'
  | 'loggedOutLandingPage'
  | 'loginURL'
  | 'logoutURL'
  | 'logs'
  | 'monorailSignupURL'
  | 'notifyURL'
  | 'prometheus'
  | 'securityURL'
  | 'stripeKey'
  | 'resourceManagerServer'
  | 'graphqlResolver'
  | 'sessionsURL'
  | 'sprigId';

enum ReleaseStage {
  development = 'development',
  staging = 'staging',
  production = 'production',
  local = 'local',
}

type Config = {
  [key in ConfigKeyType]: string;
} & {
  isDevelopment: () => boolean;
  isStaging: () => boolean;
  isProduction: () => boolean;
  isMocksEnabled: () => boolean;
  disableMockLogging: boolean;
  environmentName: ReleaseStage;
};

const config: Config = {
  appAnalyticsName: 'cloud_console',
  environmentName: (process.env.ENV_NAME as ReleaseStage) || ReleaseStage.development,
  accountsURL: process.env.ACCOUNTS_URL || '',
  amplitudeKey: process.env.AMPLITUDE_KEY || '',
  apiBaseURL: process.env.API_BASE_URL || '',
  apiPortfolioURL: process.env.API_PORTFOLIO_URL || '',
  appVersion: process.env.APP_VERSION || '',
  apiURL: process.env.API_URL || '',
  authUnifiedLoginURL: process.env.AUTH_UNIFIED_LOGIN_URL || '',
  deployedHost: process.env.DEPLOYED_HOST || '',
  developerAPIURL: process.env.DEVELOPER_API_URL || '',
  disableMockLogging: process.env.DISABLE_MOCK_LOGGING
    ? Boolean(JSON.parse(process.env.DISABLE_MOCK_LOGGING))
    : false,
  assets: process.env.ASSETS_URL || '',
  experimentsURL: process.env.EXPERIMENTS_URL || '',
  graphqlResolver: process.env.GRAPHQL_RESOLVER || '',
  killSwitchURL: process.env.KILL_SWITCH_URL || '',
  loginURL: process.env.LOGIN_URL || '',
  logoutURL: process.env.LOGOUT_URL || '',
  loggedOutLandingPage: process.env.LOGGED_OUT_LANDING_PAGE || '',
  logs: process.env.ADMIN_RESOURCE_LOGS || '',
  monorailSignupURL: process.env.MONORAIL_SIGNUP_URL || '',
  prometheus: process.env.PROMETHEUS_URL || '',
  resourceManagerServer: process.env.RESOURCE_MANAGER_URL || '',
  securityURL: process.env.SECURITY_SETTINGS_URL || '',
  stripeKey: process.env.STRIPE_KEY || '',
  sprigId: process.env.SPRIG_ID || '',

  // Bugsnag
  bugsnagKey: process.env.BUGSNAG_KEY || '',
  notifyURL: process.env.NOTIFY_URL || '',
  sessionsURL: process.env.SESSIONS_URL || '',

  // App Switcher
  appSwitcherProjectId: process.env.APP_SWITCHER_PROJECT_ID || '',

  coinbaseURL: process.env.APP_SWITCHER_COINBASE_URL || '',

  // 2FA
  confirmTwoFactorProjectId: '2L3Z9vDIdAn9AbJj0cAHxUI1ew4',

  isDevelopment() {
    return process.env.ENV_NAME === 'development';
  },
  isStaging() {
    return process.env.ENV_NAME === 'staging';
  },
  isProduction() {
    return process.env.ENV_NAME === 'production';
  },
  isMocksEnabled() {
    return process.env.REACT_APP_MOCK === 'true' || process.env.NODE_ENV === 'test';
  },
};

export default config;
