import { useMemo } from 'react';

import { INTEGRATED_PRODUCTS } from ':cloud/content/products';
import { useIsOnStakingAllowlist } from ':cloud/hooks/featureFlags/useIsOnStakingAllowlist';

import { useIsExchangeOnboardingEnabled } from './featureFlags/useIsExchangeOnboardingEnabled';

export function useCardProducts() {
  const isOnStakingApiAllowlist = useIsOnStakingAllowlist();
  const isExchangeOnboardingEnabled = useIsExchangeOnboardingEnabled();

  return useMemo(() => {
    return INTEGRATED_PRODUCTS.filter(
      ({ productType }) =>
        productType !== 'participate' &&
        !(productType === 'staking_api' && !isOnStakingApiAllowlist) &&
        !(productType === 'exchange' && !isExchangeOnboardingEnabled),
    );
  }, [isOnStakingApiAllowlist, isExchangeOnboardingEnabled]);
}
