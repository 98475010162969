import { useVariant, UseVariantOptions } from '@cbhq/experiments';

import config from ':cloud/config';
import { Experiments } from ':cloud/init/cifer/experiments';

export default function useHasExperiment<
  K extends keyof typeof Experiments,
  H extends keyof typeof Experiments,
>(
  name: K | null,
  variant: (typeof Experiments)[K]['groups'][number],
  options?: UseVariantOptions<(typeof Experiments)[H]>,
): boolean {
  // all experiments enabled when running mocks b/c we don't have a user email to query against
  return (
    useVariant(name ? Experiments[name] : null, options) === variant || config.isMocksEnabled()
  );
}
