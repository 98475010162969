import { useQuery } from '@tanstack/react-query';

import { logError } from ':cloud/init/bugsnag/logging';
import { QueryKey } from ':cloud/queries/types';
import { APIClientPortfolio } from ':cloud/state/Api';
import { GetTradePortfoliosResponse } from ':cloud/types/service_proto';
import { ITradePortfolio, TradePortfolio } from ':cloud/types/ts_types';

async function fetchPortfolios(): Promise<ITradePortfolio[]> {
  const path = '/GetPortfolios';
  const response = await APIClientPortfolio.post<GetTradePortfoliosResponse>(path, {
    resourceType: 'arraybuffer',
  });
  const { data } = response;

  return data?.portfolios;
}

export function useGetTradePortfolios(): {
  isLoading: boolean;
  portfolios: TradePortfolio[];
} {
  const { isLoading, data } = useQuery({
    queryKey: [QueryKey.portfolios],
    queryFn: async () => fetchPortfolios(),
    staleTime: Infinity,
    meta: { retry: 1 },
  });

  const portfolios = data?.map((portfolio) => portfolio.portfolio) || [];

  if (!isLoading && !portfolios.length) {
    logError(new Error('No portfolios returned from GetPortfolios query'), {
      context: 'useGetTradePortfolios',
      severity: 'warning',
    });
  }

  return {
    isLoading,
    portfolios,
  };
}
