import { createContext, Dispatch, SetStateAction, useContext } from 'react';

import { IFormDataType } from ':cloud/types/ts_types';

export type NewClusterContextType = {
  formBtnRef?: HTMLButtonElement;
  formData?: Partial<IFormDataType>;
  workflowVersion?: string;
  isDeploying: boolean;
  setIsDeploying: Dispatch<SetStateAction<boolean>>;
  reset: () => void;
  setFormBtnRef: Dispatch<SetStateAction<HTMLButtonElement | undefined>>;
  setFormData: Dispatch<SetStateAction<IFormDataType | undefined>>;
  setWorkflowVersion: Dispatch<SetStateAction<string | undefined>>;
};

export const NewClusterContext = createContext<NewClusterContextType | undefined>(undefined);

export function useNewCluster(): NewClusterContextType {
  const context = useContext(NewClusterContext);
  if (context === undefined) {
    throw new Error('useNewCluster must be used within an NewClusterProvider');
  }
  return context;
}
