import { Fragment, useCallback } from 'react';
import { FieldProps, utils } from '@rjsf/core';
import { Grid, VStack } from '@cbhq/cds-web/layout';
import { TextBody } from '@cbhq/cds-web/typography';

import { threeColumnGrid, twoColumnGrid } from ':cloud/utils/responsiveConfigs';

import { BoxSelectWidget } from '../widgets';

/* only used in Participate */
export function BoxSelectSchemaField({
  schema,
  onChange,
  formData,
  uiSchema,
  rawErrors = [],
}: FieldProps) {
  const handleSelectClick = useCallback(
    (item) => {
      if (item === formData) {
        onChange(undefined);
      } else {
        onChange(item);
      }
    },
    [formData, onChange],
  );

  if (!schema) {
    return null;
  }
  // @ts-expect-error TODO: cleanup MUI types
  const { widgetType = 'default', field, description, ...props } = utils.getUiOptions(uiSchema);
  const hasError = rawErrors.length > 0;

  return (
    <>
      {Boolean(description) && (
        <TextBody as="p" spacingTop={1}>
          {description}
        </TextBody>
      )}
      <VStack spacingTop={5}>
        {widgetType !== 'wide' && (
          <Grid gap={3} responsiveConfig={threeColumnGrid}>
            {schema?.enum?.map((item, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={idx}>
                <BoxSelectWidget
                  // @ts-expect-error TODO: cleanup MUI types
                  title={schema?.enumNames[idx] || ''}
                  // @ts-expect-error TODO: cleanup MUI types
                  description={props[item]?.description}
                  // @ts-expect-error TODO: cleanup MUI types
                  image={props[item]?.image}
                  selected={formData === item}
                  hasError={hasError}
                  widgetType={widgetType}
                  fieldType={field}
                  onClick={() => handleSelectClick(item)}
                />
              </Fragment>
            ))}
          </Grid>
        )}
        {widgetType === 'wide' && (
          <Grid gap={3} responsiveConfig={twoColumnGrid}>
            {schema?.enum?.map((item, idx) => (
              // items not expected to change
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={idx}>
                <BoxSelectWidget
                  // @ts-expect-error TODO: cleanup MUI types
                  title={schema?.enumNames[idx] || ''}
                  // @ts-expect-error TODO: cleanup MUI types
                  description={props[item]?.description}
                  // @ts-expect-error TODO: cleanup MUI types
                  image={props[item]?.image}
                  selected={formData === item}
                  hasError={hasError}
                  widgetType={widgetType}
                  fieldType={field}
                  onClick={() => handleSelectClick(item)}
                />
              </Fragment>
            ))}
          </Grid>
        )}
      </VStack>
    </>
  );
}
