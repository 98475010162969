import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TabNavigation } from '@cbhq/cds-web/tabs/TabNavigation';

import { useAppState } from ':cloud/contexts/AppStateContext';
import { TabType, useNavigationContext } from ':cloud/contexts/NavigationContext';
import { useIsBillingUsageEnabled } from ':cloud/hooks/featureFlags/useIsBillingUsageEnabled';
import { useIsEarningsEnabled } from ':cloud/hooks/featureFlags/useIsEarningsEnabled';
import { useIsLegacyAPITokenManagementEnabled } from ':cloud/hooks/featureFlags/useIsLegacyAPITokenManagementEnabled';
import { useIsOAuthEnabled } from ':cloud/hooks/featureFlags/useIsOAuthEnabled';
import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { useGetOrganization } from ':cloud/queries/OrganizationQueries/useGetOrganization';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { useGetUserPermissions } from ':cloud/queries/UserQueries/useGetUserPermissions';
import { AppRoute } from ':cloud/utils/routes';
import { NavigationBar } from ':cloud/widgets/navigation/NavigationBar';

/** order of tabs matters */
const headerTabs: Record<string, TabType[]> = {
  settings: [
    {
      id: 'organization',
      label: 'Organization',
    },
    {
      id: 'billing',
      label: 'Billing',
    },
  ],
  finance: [
    {
      id: 'billing',
      label: 'Billing',
    },
    {
      id: 'earnings',
      label: 'Earnings',
    },
  ],
  access: [
    {
      id: 'teams',
      label: 'Teams',
    },
    {
      id: 'api',
      label: 'API keys',
    },
    {
      id: 'legacy-api',
      label: 'Legacy API management',
    },
  ],
  admin: [
    {
      id: 'protocols',
      label: 'Protocols',
    },
    {
      id: 'clusters',
      label: 'Clusters',
    },
    {
      id: 'organizations',
      label: 'Organizations',
    },
  ],
  projectDetails: [
    {
      id: 'overview',
      label: 'Overview',
    },
    {
      id: 'monitor',
      label: 'Monitor',
    },
    {
      id: 'manage',
      label: 'Manage',
    },
  ],
  billing: [
    {
      id: 'details',
      label: 'Details',
    },
    {
      id: 'usage',
      label: 'Usage',
    },
  ],
};

const accessTabItems: {
  id: string;
  label: string;
}[] = [
  {
    id: AppRoute.Access.API,
    label: 'API keys',
  },
  {
    id: AppRoute.Access.OAuth,
    label: 'OAuth',
  },
];

export function TopNav() {
  const { selectedProject } = useAppState();
  const { activePage, activeTab, projectId } = useNavigationContext();
  const { user, activeOrg } = useGetUser();
  const { truncatedName: orgName } = useGetOrganization(activeOrg?.organizationId);
  const history = useHistory();
  const location = useLocation();
  const pathArray = location.pathname.split('/');
  const { isPhone } = useSimpleBreakpoints();
  const isLegacyAPIManagementEnabled = useIsLegacyAPITokenManagementEnabled();
  const { permissions } = useGetUserPermissions(activeOrg?.organizationId);
  const isOAuthEnabled = useIsOAuthEnabled();
  const isOAuthUpsertClientRoute =
    location.pathname.includes(AppRoute.Access.OAuthCreate) ||
    location.pathname.includes(AppRoute.Access.OAuthEdit);
  const isAccessPage =
    isOAuthEnabled &&
    !isOAuthUpsertClientRoute &&
    (location.pathname.includes(AppRoute.Access.API) ||
      location.pathname.includes(AppRoute.Access.OAuth));
  const isEarningsEnabled = useIsEarningsEnabled();
  const isBillingUsageEnabled = useIsBillingUsageEnabled();

  const currentProject = useMemo(
    () => (projectId ? selectedProject : undefined),
    [projectId, selectedProject],
  );

  const tabs = useMemo(() => {
    if (activePage.id === 'projects' && projectId) {
      return headerTabs.projectDetails;
    }
    if (activePage.id === 'billing' && !isBillingUsageEnabled) {
      return undefined;
    }
    return headerTabs[activePage.id];
  }, [activePage.id, isBillingUsageEnabled, projectId]);

  const handleBackPress = useCallback(() => {
    if (location.pathname.includes('participate')) {
      history.push(AppRoute.Participate.Home);
    } else {
      history.go(-1);
    }
  }, [history, location.pathname]);

  const showBackButton = useMemo(
    (): boolean =>
      !isPhone &&
      ((pathArray.length > 2 && location.pathname.includes(AppRoute.Participate.Home)) ||
        location.pathname.includes(AppRoute.Products.WaasConsumer) ||
        isOAuthUpsertClientRoute),
    [isOAuthUpsertClientRoute, isPhone, location.pathname, pathArray.length],
  );

  const filteredTabs = tabs?.filter((filteredTabId: TabType): boolean => {
    switch (filteredTabId.id) {
      /** admin page */
      case 'protocols':
      case 'clusters':
      case 'organizations':
        return true;

      /** access page */
      case 'api':
        return true;
      case 'legacy-api':
        return isLegacyAPIManagementEnabled || user.isInternalUser;

      /** settings page */
      case 'organization':
        return true;
      case 'billing':
        return permissions.manageBilling && isBillingUsageEnabled;

      /** earnings page */
      case 'earnings':
        return isEarningsEnabled;

      /** project details page */
      case 'overview':
        return true;

      /** manage tab */
      case 'manage':
        return true;
      case 'details':
        return permissions.manageBilling && isBillingUsageEnabled;
      case 'usage':
        return permissions.manageBilling && isBillingUsageEnabled;
      default:
        return false;
    }
  });

  const bottomContent = filteredTabs && (
    <TabNavigation
      spacingStart={isPhone ? 1 : 2}
      tabs={filteredTabs}
      value={activeTab as string}
      onChange={(tabId) => {
        if (activePage.id === 'projects' || activePage.id === 'project-dashboard') {
          history.replace(`/${pathArray[1]}/${projectId}/${tabId}`);
          return;
        }
        history.replace(`/${pathArray[1]}/${tabId}`);
      }}
    />
  );

  const accessTabs = (
    <TabNavigation
      width={200}
      spacingStart={isPhone ? 1 : 2}
      tabs={accessTabItems}
      value={location.pathname || accessTabItems[0].id}
      onChange={(accessTabId: string) => {
        history.push(accessTabId);
      }}
    />
  );

  const renderTitle = useMemo(() => {
    if (currentProject?.name) {
      return currentProject.name;
    }
    if (activePage.id === 'dashboard') {
      return orgName;
    }

    if (activePage.id === 'waas_consumer' && location?.pathname?.includes('embedded-wallets')) {
      return 'Embedded Wallets';
    }

    if (isOAuthUpsertClientRoute) {
      // conditionally render title based on route
      return location.pathname.includes('create') ? 'Create client' : 'Edit client';
    }

    if (activePage?.pageTitle) {
      return activePage?.pageTitle;
    }

    return activePage.title;
  }, [
    activePage.id,
    activePage?.pageTitle,
    activePage.title,
    currentProject?.name,
    isOAuthUpsertClientRoute,
    location.pathname,
    orgName,
  ]);

  const renderTitleTag = useMemo(() => {
    if (currentProject?.isTestnet) {
      return 'Base Node - Sepolia';
    }
    return 'Base Node - Mainnet';
  }, [currentProject?.isTestnet]);

  return (
    <NavigationBar
      bottomContent={isAccessPage ? accessTabs : bottomContent}
      handleBackPress={handleBackPress}
      showBackButton={showBackButton}
      title={renderTitle}
      titleTag={renderTitleTag}
    />
  );
}
