import { HStack, VStack } from '@cbhq/cds-web/layout';

import { useDashboardContext } from ':cloud/contexts/DashboardProvider';
import { DashboardChart } from ':cloud/widgets/monitoring/types';

import { Chart } from './Chart';

type DisplayOnrampMetricsProps = {
  dashboardCharts: DashboardChart[];
};

export function DisplayOnrampMetrics({ dashboardCharts }: DisplayOnrampMetricsProps) {
  const { range } = useDashboardContext();
  return (
    <VStack>
      <HStack spacingBottom={5} spacingEnd={5} justifyContent="space-evenly" gap={5}>
        <Chart {...dashboardCharts[0]} key={dashboardCharts[0].title} range={range} />
        <Chart {...dashboardCharts[1]} key={dashboardCharts[1].title} range={range} />
      </HStack>
    </VStack>
  );
}
