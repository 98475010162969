import { useState } from 'react';

import articleMap from ':cloud/content/help-article-map.json';
import { type HelpArticle } from ':cloud/widgets/help/HelpPanel';

function useArticles(): {
  articles: HelpArticle[];
  loadArticlesByLabels: (labels: string[]) => void;
} {
  const [articles, setArticles] = useState<HelpArticle[]>([]);

  const loadArticlesByLabels = (labels: string[] = []): void => {
    const displayedArticles = articleMap.filter((article) => {
      return labels.some((label) => article.label_names.includes(label));
    });
    setArticles(displayedArticles);
  };

  return {
    articles,
    loadArticlesByLabels,
  };
}

export default useArticles;
