import { useCallback, useMemo } from 'react';
import { Button } from '@cbhq/cds-web/buttons';
import { SelectOption } from '@cbhq/cds-web/controls';
import { Dropdown } from '@cbhq/cds-web/dropdown';
import { PortalProvider } from '@cbhq/cds-web/overlays/PortalProvider';

import { useDashboardContext } from ':cloud/contexts/DashboardProvider';
import { RANGE_OPTIONS } from ':cloud/widgets/monitoring/monitoringConfig';

export function DashboardRangeDropdown() {
  const { range, setRange, errorBoundaryKey, setErrorBoundaryKey } = useDashboardContext();

  // Allow re-fetching of errored charts
  const resetChartErrors = useCallback(() => {
    setErrorBoundaryKey(errorBoundaryKey + 1);
  }, [errorBoundaryKey, setErrorBoundaryKey]);

  const content = useMemo(
    () =>
      RANGE_OPTIONS.map(({ title }) => (
        <SelectOption key={title} value={title.toString()} title={title} />
      )),
    [],
  );

  const getRangeValue = useCallback(
    (rangeTitle: string) => RANGE_OPTIONS.find((option) => option.title === rangeTitle)?.value || 1,
    [],
  );

  const getRangeTitle = useCallback(
    (rangeValue: number) =>
      RANGE_OPTIONS.find((option) => option.value === rangeValue)?.title || '',
    [],
  );

  const setRangeCallback = useCallback(
    (nextRangeTitle: string) => {
      const nextRangeValue = getRangeValue(nextRangeTitle);
      setRange(nextRangeValue);
    },
    [setRange, getRangeValue],
  );

  const handleOnChange = useCallback(
    (nextRangeTitle: string) => {
      setRangeCallback(nextRangeTitle);
      resetChartErrors();
    },
    [resetChartErrors, setRangeCallback],
  );

  return (
    <PortalProvider>
      <Dropdown
        value={range.toString()}
        onChange={handleOnChange}
        content={content}
        enableMobileModal
        width={185}
      >
        <Button compact endIcon="caretDown" variant="secondary">
          {getRangeTitle(range)}
        </Button>
      </Dropdown>
    </PortalProvider>
  );
}
