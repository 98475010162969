import { createElement, ReactNode } from 'react';

import {
  Eth2Pod,
  IClusterArtifacts,
  IConfiguration,
  IMetricData,
  IWidgetSchema,
  IWidgetType,
} from ':cloud/types/ts_types';

import { BlockUnitWidget } from './BlockUnitWidget';
import { LinedChartWrapper } from './LinedChartWrapper';
import { PeerWidget } from './PeerWidget';
import { RadialChartWidget } from './RadialChartWidget';
import { StakeBarWidget } from './StakeBarWidget';
import { UptimeChartWidget } from './UptimeChartWidget';
import { ValidatorCountWidget } from './ValidatorCountWidget';

type SchemaWidgetType = {
  [key in IWidgetType]?: ReactNode;
};

/** Maps to widget graphs used by protocols here: https://github.com/bisontrails/workflow/blob/4547c989b7aff7c754e64fffad0673f88d9d6a11/workflows/include/widgets.jsonnet#L64 */
/** In order to identify if the component is used make a global search across bison repos: https://github.com/search?q=org%3Abisontrails+++widgets.Widgets.&type=code */
const SchemaWidgets: SchemaWidgetType = {
  /* eslint-disable camelcase */
  chart_unit: BlockUnitWidget,
  node_unit: BlockUnitWidget, // search widgets.Widgets.Node.Unit
  peer_count: PeerWidget, // search widgets.Widgets.Metrics.Peers
  radial_chart: RadialChartWidget, // search widgets.Widgets.Charts.Radial
  stake_bar: StakeBarWidget, // search widgets.Widgets.Eth.StakeBar
  uptime_chart: UptimeChartWidget, // search widgets.Widgets.Charts.Uptime
  line_chart: LinedChartWrapper, // search widgets.Widgets.Charts.Line
  validator_count: ValidatorCountWidget, // search widgets.Widgets.Metrics.ValidatorCount
  node_empty: () => {
    return null;
  },
  /* eslint-enable camelcase */
};

export function renderer(
  widget: IWidgetSchema,
  configuration: IConfiguration,
  isDynamic: boolean,
  artifacts?: IClusterArtifacts,
  metricsData?: IMetricData,
  pod?: Eth2Pod,
  selectedNodes?: Set<string>,
): ReactNode {
  if (widget?.widget && typeof SchemaWidgets[widget.widget] !== 'undefined') {
    // @ts-expect-error TODO: parse and derive sources here and pass through to widgets
    return createElement(SchemaWidgets[widget.widget], {
      sources: widget.sources,
      options: widget.options,
      conditional: widget.conditional,
      configuration,
      isDynamic,
      artifacts,
      metricsData,
      pod,
      selectedNodes,
    });
  }
  // eslint-disable-next-line no-console
  return console.error(
    `The widget ${widget?.widget} has not been defined. SchemaWidget file has missing component.`,
  );
}
