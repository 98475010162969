import { useQuery } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientRM } from ':cloud/state/Api';
import { IWorkflow, IWorkflowMap, MetricKeyType, ProtocolKey } from ':cloud/types/ts_types';

async function fetchLatestWorkflows(): Promise<IWorkflowMap> {
  const path = '/v1/networks';
  const { data } = await APIClientRM.get<IWorkflowMap>(path);
  return data;
}

export function useGetWorkflows(): { isLoading: boolean; workflows: IWorkflowMap } {
  const { isLoading, data } = useQuery({
    queryKey: [QueryKey.workflows],
    queryFn: fetchLatestWorkflows,
    meta: { retry: 3 },
    staleTime: Infinity,
    /** filter out all non essential protocol workflows */
    select: (workflows: IWorkflowMap) => {
      return Object.fromEntries(
        Object.entries(workflows).filter(
          ([key, workflow]) => workflow.clusterType !== 'qt' && !key.endsWith('_cdk'),
        ),
      );
    },
  });

  return { isLoading, workflows: data || {} };
}

export function useGetWorkflowByKey(key: ProtocolKey): {
  isLoading: boolean;
  workflow?: IWorkflow;
} {
  const { isLoading, workflows } = useGetWorkflows();
  const workflow = isLoading ? undefined : workflows[key];
  return {
    isLoading,
    workflow,
  };
}

export function useGetWorkflowsBy(predicateFn: (wf: IWorkflow) => boolean): {
  isLoading: boolean;
  workflows: IWorkflow[];
} {
  const { isLoading, workflows } = useGetWorkflows();

  return {
    isLoading,
    workflows: Object.values(workflows).filter(predicateFn),
  };
}

function getDefaultMetricKeys(workflow: IWorkflow): Record<MetricKeyType, string> {
  return {
    blockHeightKey: workflow?.metricKeys?.block_height || 'block_height',
    totalStakedKey: workflow?.metricKeys?.total_staked || 'earnings',
    uptimeKey: workflow?.metricKeys?.uptime || 'uptime',
    peersKey: workflow?.metricKeys?.connected_peers || 'connected_peers',
    memoryUsageKey: workflow?.metricKeys?.memory_usage || 'memory_usage',
    memoryUsagePercentKey: workflow?.metricKeys?.memory_usage_percent || 'memory_usage_percent',
    uptimeChartKey: workflow?.metricKeys?.uptime_48h_12h || 'uptime_48h_12h',
    cpuUsagePercentKey: workflow?.metricKeys?.cpu_usage_percent || 'cpu_usage_percent',
  };
}
export function useGetMetricKeys(key: ProtocolKey): {
  isLoading: boolean;
  metricKeys?: ReturnType<typeof getDefaultMetricKeys>;
} {
  const { isLoading, workflow } = useGetWorkflowByKey(key);
  const metricKeys = workflow && getDefaultMetricKeys(workflow);
  return {
    isLoading,
    metricKeys,
  };
}

export function useGetProtocolNetworkChain(key: ProtocolKey): {
  isLoading: boolean;
  networkChain: string;
} {
  const { isLoading, workflow } = useGetWorkflowByKey(key);
  const networkChain = workflow?.metaSchema?.networkChain ?? 'network';
  const customNetworkChainKey = workflow?.formSchema?.properties?.[networkChain];
  return {
    isLoading,
    networkChain: customNetworkChainKey?.title || 'network',
  };
}
