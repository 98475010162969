import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import _capitalize from 'lodash/capitalize';
import { Button } from '@cbhq/cds-web/buttons';
import { VStack } from '@cbhq/cds-web/layout';
import { Avatar } from '@cbhq/cds-web/media';
import { Tooltip } from '@cbhq/cds-web/overlays/Tooltip/Tooltip';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHeader,
  TableRow,
} from '@cbhq/cds-web/tables';
import { Tag } from '@cbhq/cds-web/tag/Tag';
import { Link, TextBody, TextHeadline, TextTitle3 } from '@cbhq/cds-web/typography';

import { useGetOrganization } from ':cloud/queries/OrganizationQueries/useGetOrganization';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { useGetUserPermissions } from ':cloud/queries/UserQueries/useGetUserPermissions';
import { OrganizationUser } from ':cloud/types/ts_types';
import { AppRoute } from ':cloud/utils/routes';
import { ActionCell } from ':cloud/widgets/access/components/ActionCell';
import { sortOrganizationUsers } from ':cloud/widgets/access/helpers';

import {
  AccessTableWrapperProps,
  inviteWarning,
  MAX_MEMBER_INVITE_LIMIT,
} from './AccessTableWrapper';

export function MobileAccessTableWrapper({
  toggleInviteModalOn,
  handleMemberAction,
}: AccessTableWrapperProps) {
  const history = useHistory();
  const { user: self, activeOrg } = useGetUser();
  const { permissions } = useGetUserPermissions(activeOrg?.organizationId);
  const { organizationUsers, invitedUsers } = useGetOrganization(activeOrg?.organizationId);
  const hasInvitedMaxUsers = invitedUsers?.length >= MAX_MEMBER_INVITE_LIMIT;

  const sortedOrganizationUsers = useMemo(() => {
    return sortOrganizationUsers(organizationUsers, self.email);
  }, [organizationUsers, self.email]);

  const accessTableBody = sortedOrganizationUsers?.map((user) => {
    const signedInUser = self.email === user?.email;
    return (
      <TableRow key={`row--${user?.email}`}>
        <TableCell
          start={
            <Avatar alt={user.email} size="xl" selected={signedInUser} dangerouslySetSize={34} />
          }
        >
          <TextHeadline as="p" overflow="break">
            {getMobileDisplayName(user)}
          </TextHeadline>
        </TableCell>
        <TableCell direction="horizontal" justifyContent="flex-start">
          <TextHeadline spacingVertical={2} as="p">
            {_capitalize(user.role)}
          </TextHeadline>
          {signedInUser ? <Tag colorScheme="blue">SELF</Tag> : null}
        </TableCell>
        {permissions.manageUsers && activeOrg?.role && (
          <ActionCell
            user={user}
            signedInUser={signedInUser}
            signedInUserRole={activeOrg.role}
            handleMemberAction={handleMemberAction}
          />
        )}
      </TableRow>
    );
  });

  return (
    <Table bordered variant="ruled" tableLayout="fixed" compact>
      <TableCaption>
        <VStack spacingTop={1} spacingHorizontal={0.5}>
          <TextTitle3 as="h3">Organization members</TextTitle3>
          <VStack gap={2} width="100%" justifyContent="space-between" spacingVertical={2}>
            <TextBody as="p" color="foregroundMuted">
              A list of people in your organization, including their roles. For details, see{' '}
              <Link onPress={() => history.push(AppRoute.Access.Permissions)} underline={false}>
                Roles and Permissions
              </Link>
            </TextBody>
            {permissions.manageUsers ? (
              <VStack justifyContent="center">
                {self.exchangeEnabled ? (
                  <Tooltip content={inviteWarning} placement="bottom">
                    <Button disabled onPress={toggleInviteModalOn}>
                      Invite member
                    </Button>
                  </Tooltip>
                ) : hasInvitedMaxUsers ? (
                  <Tooltip content={inviteWarning} placement="bottom">
                    <Button disabled={hasInvitedMaxUsers} onPress={toggleInviteModalOn}>
                      Invite member
                    </Button>
                  </Tooltip>
                ) : (
                  <Button disabled={hasInvitedMaxUsers} onPress={toggleInviteModalOn}>
                    Invite member
                  </Button>
                )}
              </VStack>
            ) : null}
          </VStack>
        </VStack>
      </TableCaption>
      <TableHeader>
        <TableRow>
          <TableCell title="Name" width="45%" />
          <TableCell title="Role" />
          {permissions.manageUsers && <TableCell title="Actions" alignItems="flex-end" />}
        </TableRow>
      </TableHeader>
      <TableBody>{accessTableBody}</TableBody>
    </Table>
  );
}

function getMobileDisplayName(user: OrganizationUser): string {
  if (user.status !== 'active') {
    return '';
  }
  if (user?.name && user?.familyName) {
    return `${user?.name} ${user?.familyName?.[0]}.`;
  }

  return user.email;
}
