import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@cbhq/cds-web/buttons';
import { CardBodyAction, CardBodyActionProps } from '@cbhq/cds-web/cards';
import { CardGroup } from '@cbhq/cds-web/cards/CardGroup';
import { FeatureEntryCard, FeatureEntryCardProps } from '@cbhq/cds-web/cards/FeatureEntryCard';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { OnPress } from '@cbhq/cds-web/system/Pressable';
import { TextBody, TextDisplay3, TextHeadline, TextTitle3 } from '@cbhq/cds-web/typography';

import { useAppState } from ':cloud/contexts/AppStateContext';
import { useIsDeleteProjectEnabled } from ':cloud/hooks/featureFlags/useIsDeleteProjectEnabled';
import { useCopyToClipboard } from ':cloud/hooks/useCopyToClipboard';
import { useDeleteProject } from ':cloud/queries/Projects/useDeleteProject';
import { useGetProjects } from ':cloud/queries/Projects/useGetProjects';
import { useUpdateProject } from ':cloud/queries/Projects/useUpdateProject';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { relativeTimeString } from ':cloud/utils/formatters/relativeTime';
import { CLOUD_DOCS, CLOUD_HELP } from ':cloud/utils/links';
import { openUrlInNewWindow } from ':cloud/utils/openUrlInNewWindow';
import { AppRoute } from ':cloud/utils/routes';
import { generateProjectNameSchema } from ':cloud/utils/validate';
import { Layout } from ':cloud/widgets/layout';
import { MAIN_CONTAINER_MIN_WIDTH } from ':cloud/widgets/product/constants';
import { ProjectDeleteModal } from ':cloud/widgets/projects/ProjectDeleteModal';
import { EditableValueWithButton } from ':cloud/widgets/sharedcomponents/EditableValueWithButton';

// this is necessary because of a typing issue with CardBodyAction in CDS
const CardBodyActionWrapper: React.FC<CardBodyActionProps & { onPress: OnPress }> = CardBodyAction;

function ProjectSettingsContent() {
  const { selectedProject, setSelectedProject } = useAppState();
  const {
    user: { isOwnerOrAdmin },
    activeOrg,
  } = useGetUser();
  const history = useHistory();
  const projectId = selectedProject?.id || '';
  const projectName = selectedProject?.name || '';
  const projectType = selectedProject?.type || '';
  const organizationId = activeOrg?.organizationId || '';
  const { projects } = useGetProjects(organizationId);
  const deleteMutation = useDeleteProject();
  const deleteProjectEnabled = useIsDeleteProjectEnabled();

  const updateProjectMutation = useUpdateProject();

  const [showProjectDeleteModal, setShowProjectDeleteModal] = useState(false);

  const createdAtDate = selectedProject?.createdAt
    ? new Date(selectedProject.createdAt)
    : new Date();
  const createdString = `Created ${relativeTimeString(createdAtDate)}`;

  const [, copy] = useCopyToClipboard();
  const handleCopy = useCallback(async () => {
    await copy(projectId);
  }, [copy, projectId]);

  const handleDelete = useCallback(() => {
    deleteMutation.mutate(
      {
        projectId,
        organizationId,
      },
      {
        onSuccess: () => {
          // most recent active project or previous project
          const fallbackProject =
            projects.find((project) => project.active) || projects[projects.length - 1];
          setShowProjectDeleteModal(false);
          setSelectedProject(fallbackProject);
          history.push(`${AppRoute.Projects.Home}/${fallbackProject.id}`);
        },
      },
    );
  }, [deleteMutation, projectId, organizationId, projects, setSelectedProject, history]);

  const handleShowDeleteModal = useCallback(() => {
    setShowProjectDeleteModal(true);
  }, []);

  const handleEdit = useCallback(
    (name, onSettled) => {
      updateProjectMutation.mutate(
        { id: projectId, name, organizationId },
        {
          onSettled,
        },
      );
    },
    [projectId, organizationId, updateProjectMutation],
  );

  const validationSchema = useMemo(() => {
    const existingProjectNames = projects.map((p) => p.name);
    return generateProjectNameSchema({ value: selectedProject?.name }, existingProjectNames);
  }, [projects, selectedProject?.name]);

  const deleteText = useMemo(() => {
    if (!isOwnerOrAdmin) {
      return 'Contact your admin to delete this project';
    }
    if (projectType === 'rat') {
      return 'Advanced trading projects cannot currently be deleted';
    }
    return 'Project and resources will be permanently deleted. This action cannot be undone.';
  }, [isOwnerOrAdmin, projectType]);

  return (
    <VStack gap={4}>
      <VStack gap={1} spacingVertical={4} spacingHorizontal={7}>
        <TextDisplay3 as="h1">{selectedProject?.name}</TextDisplay3>
        <TextBody as="p">{createdString}</TextBody>
      </VStack>
      <VStack gap={8} spacingHorizontal={7} spacingVertical={4} borderedTop>
        <TextTitle3 as="p">Project settings</TextTitle3>
        <VStack alignItems="center">
          <EditableValueWithButton
            name="Project name"
            value={selectedProject?.name}
            onSubmit={handleEdit}
            disabled={!isOwnerOrAdmin}
            validationSchema={validationSchema}
          />
        </VStack>
        <HStack justifyContent="space-between">
          <VStack>
            <TextHeadline as="p">Project ID</TextHeadline>
            <TextBody as="p" color="foregroundMuted">
              {selectedProject?.id}
            </TextBody>
          </VStack>
          <Button variant="secondary" onPress={handleCopy}>
            Copy
          </Button>
        </HStack>
        <HStack justifyContent="space-between">
          <VStack>
            <TextHeadline as="p">Delete</TextHeadline>
            <TextBody as="p" color="foregroundMuted">
              {deleteText}
            </TextBody>
          </VStack>
          <Button
            variant="negative"
            onPress={handleShowDeleteModal}
            // we initially need to prevent users from deleting RAT projects
            disabled={
              !deleteProjectEnabled || !isOwnerOrAdmin || !projectId || projectType === 'rat'
            }
          >
            Delete
          </Button>
        </HStack>
      </VStack>
      {showProjectDeleteModal && (
        <ProjectDeleteModal
          onSubmit={handleDelete}
          projectId={projectId}
          projectName={projectName}
          onRequestClose={() => setShowProjectDeleteModal(false)}
        />
      )}
    </VStack>
  );
}
const cardProps: FeatureEntryCardProps[] = [
  {
    title: 'Need help?',
    description: `Let us know if anything isn't working as you expect.`,
    spotSquare: 'optInPushNotificationsEmail',
    action: (
      <CardBodyActionWrapper onPress={() => openUrlInNewWindow(CLOUD_HELP)} endIcon="externalLink">
        Support
      </CardBodyActionWrapper>
    ),
  },
  {
    title: 'Developer Platform docs',
    description: `Use our API references and detailed guides curated for developers.`,
    spotSquare: 'documentSuccess',
    action: (
      <CardBodyActionWrapper onPress={() => openUrlInNewWindow(CLOUD_DOCS)} endIcon="externalLink">
        Docs
      </CardBodyActionWrapper>
    ),
  },
];

export function ProjectSettings() {
  return (
    <Layout>
      <Layout.MainContainer minWidth={MAIN_CONTAINER_MIN_WIDTH} spacingHorizontal={0}>
        <ProjectSettingsContent />
      </Layout.MainContainer>
      <Layout.SidebarContainer spacingHorizontal={0}>
        <VStack>
          <CardGroup>
            {cardProps.map((card) => (
              <VStack spacingHorizontal={2}>
                <FeatureEntryCard {...card} />
              </VStack>
            ))}
          </CardGroup>
        </VStack>
      </Layout.SidebarContainer>
    </Layout>
  );
}
