import { useMemo } from 'react';
import { ErrorBoundaryProps as CBErrorBoundaryProps } from '@cbhq/error-vitals-core';
import { ErrorBoundary as CBErrorBoundary } from '@cbhq/error-vitals-web';

import { defaultErrorMetadata } from ':cloud/init/bugsnag/init';
import { ErrorPage } from ':cloud/pages/ErrorPage';
import { ErrorView } from ':cloud/widgets/sharedcomponents/ErrorView';

type CloudErrorBoundaryProps = Omit<
  CBErrorBoundaryProps,
  'getErrorMetadata' | 'defaultMetadata'
> & {
  statusCode?: 400 | 404 | 500 | 503;
  errorMsg?: string;
  buttonText?: string;
  isComponentLevel?: boolean;
};

/**
 *
 * A light wrapper around that passes our defaultErrorMetadata to the error-vitals' ErrorBoundary
 *
 * The ErrorBoundary inherits all its parent's names to produce a path. For example, the following "button"
 * boundary will send `root/my_page/button` as the `path` metadata
 * <ErrorBoundary name="root">
 *   <ErrorBoundary name="my_page">
 *     <ErrorBoundary name="button">
 *
 */
export function CloudErrorBoundary({
  children,
  name,
  fallback,
  onCatch,
  onReset,
  statusCode,
  errorMsg,
  buttonText,
  isComponentLevel = false,
}: CloudErrorBoundaryProps) {
  const renderFallback = useMemo(() => {
    if (fallback) return fallback;
    if (isComponentLevel) return <ErrorView errorMsg={errorMsg} />;
    // `error` prop injected here - https://github.cbhq.net/frontend/web/blob/master/packages/error-vitals-core/src/ErrorBoundary.tsx#L128
    return <ErrorPage statusCode={statusCode} errorMsg={errorMsg} buttonText={buttonText} />;
  }, [buttonText, isComponentLevel, errorMsg, fallback, statusCode]);

  return (
    <CBErrorBoundary
      name={name}
      defaultMetadata={defaultErrorMetadata}
      onCatch={onCatch}
      onReset={onReset}
      fallback={renderFallback}
    >
      {children}
    </CBErrorBoundary>
  );
}
