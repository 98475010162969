import { useMemo } from 'react';
import { Button, ButtonGroup } from '@cbhq/cds-web/buttons';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Box, Grid, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextDisplay2, TextTitle3, TextTitle4 } from '@cbhq/cds-web/typography';

import { ReactComponent as CBPAYHero } from ':cloud/assets/Products/Onramp-hero.svg';
import { useAppState } from ':cloud/contexts/AppStateContext';
import { ONRAMP_DISCORD_LINK, ONRAMP_DOCS_LINK } from ':cloud/utils/links';
import { onExternalLinkPress } from ':cloud/utils/onExternalLinkPress';
import { Layout } from ':cloud/widgets/layout';
import { DomainAllowlistSection } from ':cloud/widgets/onramp/DomainAllowlistSection';
import { SecureInitializationSection } from ':cloud/widgets/onramp/SecureInitializationSection';
import { WalletNameSection } from ':cloud/widgets/onramp/WalletNameSection';
import {
  FULL_PAGE_MAX_WIDTH,
  MAIN_CONTAINER_MIN_WIDTH,
  RIGHT_RAIL_MIN_WIDTH,
} from ':cloud/widgets/product/constants';
import { KeyFeatureItem, KeyFeaturesSection } from ':cloud/widgets/product/KeyFeaturesSection';

const keyFeatures: KeyFeatureItem[] = [
  {
    iconName: 'addCard',
    title: 'More payment methods',
    description:
      "Accept payment methods already saved in your user's Coinbase account, including ACH, debit, and crypto balances. Users without a Coinbase account can buy with a debit card.",
  },
  {
    iconName: 'worldwide',
    title: 'Trusted global coverage',
    description:
      'Coinbase Pay supports 60+ fiat currencies with regulatory compliance and licensing, as well as 100+ cryptocurrencies with a rigorous asset listing process.',
  },
  {
    iconName: 'delegate',
    title: 'Built-in KYC and compliance',
    description:
      'No need to verify users and store their payment information. Coinbase can handle KYC, fraud claims and customer support cases on your behalf.',
  },
];

export function OnrampLanding() {
  const title = 'Enable in-app crypto purchases';
  const description =
    'Coinbase Onramp is a widget that enables users to purchase 100+ tokens with 60+ fiat currencies, natively within an application.';

  const { isDesktop, isPhone } = useBreakpoints();
  const responsiveTemplateColumns = useMemo(() => {
    return isDesktop ? '1fr 1fr' : '1fr';
  }, [isDesktop]);

  const { selectedProject } = useAppState();

  return (
    <Layout enableMaxWidth={false}>
      <Layout.MainContainer minWidth={MAIN_CONTAINER_MIN_WIDTH} spacingHorizontal={0} fullWidth>
        <Box display="block" width="100%">
          <VStack>
            <HStack borderedBottom>
              <HStack maxWidth={FULL_PAGE_MAX_WIDTH}>
                <Grid
                  maxWidth={FULL_PAGE_MAX_WIDTH}
                  gap={9}
                  spacing={9}
                  templateColumns={responsiveTemplateColumns}
                >
                  <VStack>
                    <TextDisplay2 as="h1">{title}</TextDisplay2>
                    <TextTitle4 as="h2" color="foregroundMuted" spacingTop={5} spacingBottom={7}>
                      {description}
                    </TextTitle4>
                    <ButtonGroup>
                      <Button
                        variant="primary"
                        endIcon="diagonalUpArrow"
                        onPress={onExternalLinkPress(ONRAMP_DOCS_LINK)}
                        accessibilityLabel="View docs + opens in new window"
                      >
                        View docs
                      </Button>
                      <Button
                        variant="secondary"
                        endIcon="diagonalUpArrow"
                        onPress={onExternalLinkPress(ONRAMP_DISCORD_LINK)}
                        accessibilityLabel="View docs + opens in new window"
                      >
                        Discord channel
                      </Button>
                    </ButtonGroup>
                  </VStack>
                  <VStack
                    borderRadius="roundedNone"
                    maxWidth={RIGHT_RAIL_MIN_WIDTH}
                    height="max-content"
                  >
                    <CBPAYHero width="100%" height="max-content" />
                  </VStack>
                </Grid>
              </HStack>
            </HStack>
            {selectedProject && (
              <VStack borderedBottom>
                <VStack maxWidth={FULL_PAGE_MAX_WIDTH} spacing={10} gap={5}>
                  <HStack alignItems="center" gap={2}>
                    <TextTitle3 as="h1">Configuration</TextTitle3>
                  </HStack>
                  <WalletNameSection project={selectedProject} />
                  <SecureInitializationSection project={selectedProject} />
                  <DomainAllowlistSection project={selectedProject} />
                </VStack>
              </VStack>
            )}
            <Box spacingBottom={isPhone ? 10 : 0}>
              <KeyFeaturesSection features={keyFeatures} />
            </Box>
          </VStack>
        </Box>
      </Layout.MainContainer>
    </Layout>
  );
}
