import { useMemo } from 'react';
import _sumBy from 'lodash/sumBy';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { Tooltip } from '@cbhq/cds-web/overlays';
import { TextBody, TextDisplay3, TextHeadline } from '@cbhq/cds-web/typography';

import { useAppState } from ':cloud/contexts/AppStateContext';
import { DashboardProvider } from ':cloud/contexts/DashboardProvider';
import { useAccountCreditsBalances } from ':cloud/hooks/useAccountCreditsBalances';
import { EventName, useLogViewOnMount } from ':cloud/init/clientAnalytics/logging';
import { useGetAccount } from ':cloud/queries/BillingQueries/useGetAccount';
import { useGetChartData } from ':cloud/queries/MonitoringQueries/useGetChartData';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { getCurrentBillingCycle, getCurrentYearAndMonth } from ':cloud/utils/billing';
import { currencyFormatter } from ':cloud/utils/formatters/currency';
import { Layout } from ':cloud/widgets/layout';
import { Chart } from ':cloud/widgets/monitoring/Chart';
import { productCharts } from ':cloud/widgets/monitoring/monitoringConfig';
import { getEndTime, getScope, getStartTime } from ':cloud/widgets/monitoring/utils';
import { FULL_PAGE_MAX_WIDTH } from ':cloud/widgets/product/constants';
import { CloudErrorBoundary } from ':cloud/widgets/sharedcomponents/CloudErrorBoundary';

const CHART_RANGE = 30;

function Usage() {
  const { activeOrg } = useGetUser();
  const { selectedProject } = useAppState();

  const account = useGetAccount(activeOrg?.organizationId)?.account;
  const balance = account?.balance ? currencyFormatter(account?.balance) : '-';
  const { accountCreditsRemaining } = useAccountCreditsBalances();
  const formattedAccountCredits = currencyFormatter(accountCreditsRemaining);

  const billingUnitsChart = productCharts.base.charts[2];
  const { aggregationMethod, metricName, groupBy } = billingUnitsChart;
  const { data } = useGetChartData(
    {
      metricName,
      aggregationMethod,
      groupBy,
      startTime: getStartTime(CHART_RANGE),
      endTime: getEndTime(CHART_RANGE),
      filterBy: [{ name: 'network', value: 'base' }],
      scope: getScope(metricName),
    },
    activeOrg?.organizationId || '',
    selectedProject?.id || '',
  );

  const currentBillingCycleSponsoredGas = useMemo(() => {
    if (!data) {
      return '--';
    }
    const dataSet = data?.[0];
    const currentBillingCycleData = dataSet?.dataPoints?.filter(
      (dataPoint) => !!dataPoint?.time?.startsWith(getCurrentYearAndMonth()),
    );
    const totalGas = _sumBy(currentBillingCycleData, (dataPoint) => +dataPoint.value);
    return currencyFormatter(totalGas);
  }, [data]);

  const currentBillingCycle = getCurrentBillingCycle();

  useLogViewOnMount(EventName.billing_usage_tab_viewed);

  return (
    <VStack spacing={7} spacingTop={2}>
      <TextBody as="p" spacingBottom={3}>
        Current billing cycle <TextHeadline as="span">{currentBillingCycle}</TextHeadline>
      </TextBody>
      <HStack spacingBottom={5} gap={5}>
        <VStack spacing={5} bordered borderRadius="rounded" minWidth={266}>
          <HStack alignItems="center" gap={0.5} spacingBottom={1}>
            <TextHeadline as="p" color="foregroundMuted">
              Estimated Balance
            </TextHeadline>
            <Tooltip content="Including 7% fee">
              <Icon name="info" size="s" color="foregroundMuted" />
            </Tooltip>
          </HStack>
          <TextDisplay3 as="h1">{balance}</TextDisplay3>
        </VStack>
        <VStack spacing={5} bordered borderRadius="rounded" minWidth={266}>
          <HStack alignItems="center" gap={0.5} spacingBottom={1}>
            <TextHeadline as="p" color="foregroundMuted">
              Paymaster Sponsored Gas
            </TextHeadline>
            {/* <Tooltip content="Mainnet sponsored gas + 7% fee">
                  <Icon name="info" size="s" color="foregroundMuted" />
                </Tooltip> */}
          </HStack>
          <TextDisplay3 as="h1">{currentBillingCycleSponsoredGas}</TextDisplay3>
        </VStack>
        <VStack spacing={5} bordered borderRadius="rounded" minWidth={266}>
          <HStack alignItems="center" gap={0.5} spacingBottom={1}>
            <TextHeadline as="p" color="foregroundMuted">
              Remaining Credits
            </TextHeadline>
            {/* <Tooltip content="Mainnet sponsored gas + 7% fee">
                  <Icon name="info" size="s" color="foregroundMuted" />
                </Tooltip> */}
          </HStack>
          <TextDisplay3 as="h1">{formattedAccountCredits}</TextDisplay3>
        </VStack>
      </HStack>
      <Box bordered borderRadius="rounded" display="block">
        <Chart
          {...billingUnitsChart}
          title="Paymaster Sponsored Gas"
          bordered={false}
          range={CHART_RANGE}
        />
      </Box>
    </VStack>
  );
}

export function UsagePage() {
  return (
    <Layout>
      <Layout.MainContainer spacingHorizontal={0} fullWidth maxWidth={FULL_PAGE_MAX_WIDTH}>
        <CloudErrorBoundary name="UsagePageContent">
          <DashboardProvider>
            <Usage />
          </DashboardProvider>
        </CloudErrorBoundary>
      </Layout.MainContainer>
    </Layout>
  );
}
