import { useCallback, useState } from 'react';
import { useToggler } from '@cbhq/cds-common/hooks/useToggler';

import { OAuthClientDeleteModal } from ':cloud/widgets/access/oauth/OAuthClientDeleteModal';
import { OAuthTable } from ':cloud/widgets/access/oauth/OAuthTable';
import { Layout } from ':cloud/widgets/layout';
import { MAIN_CONTAINER_MIN_WIDTH } from ':cloud/widgets/product/constants';
import { CloudErrorBoundary } from ':cloud/widgets/sharedcomponents';

export function OAuth() {
  const [deleteModalVisible, { toggleOn: toggleDeleteModalOn, toggleOff: toggleDeleteModalOff }] =
    useToggler(false);
  const [selectedClientId, setSelectedClientId] = useState('');

  const handleDeleteRequest = useCallback(
    (clientId: string) => {
      // console.log(`Requesting to delete client with ID: ${clientId}`);
      setSelectedClientId(clientId);
      toggleDeleteModalOn();
    },
    [toggleDeleteModalOn],
  );

  const handleDeleteClient = useCallback(() => {
    // console.log(`Deleting client with ID: ${clientId}`);
    setSelectedClientId('');
    toggleDeleteModalOff();
  }, [toggleDeleteModalOff]);

  return (
    <Layout>
      <Layout.MainContainer fullWidth minWidth={MAIN_CONTAINER_MIN_WIDTH}>
        <CloudErrorBoundary name="ApiKeyTable" isComponentLevel>
          {deleteModalVisible && (
            <OAuthClientDeleteModal
              clientId={selectedClientId}
              onRequestClose={toggleDeleteModalOff}
              onSuccess={handleDeleteClient}
            />
          )}
          <OAuthTable onDeletePress={handleDeleteRequest} />
        </CloudErrorBoundary>
      </Layout.MainContainer>
    </Layout>
  );
}
