import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@cbhq/cds-web/overlays/useToast';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { DeleteWebhookRequest, DeleteWebhookResponse } from ':cloud/types/service_proto';

async function deleteWebhook({
  orgId,
  projectId,
  webhookId,
}: DeleteWebhookRequest): Promise<DeleteWebhookResponse> {
  const path = `/webhook/v1/organizations/${orgId}/projects/${projectId}/webhooks/${webhookId}`;
  const body = {
    name: `organizations/${orgId}/projects/${projectId}/webhooks/${webhookId}`,
  };

  const response = await APIClientBase.delete<DeleteWebhookResponse>(path, { data: body });

  return response.data;
}

export function useDeleteWebhook(
  onSuccess?: (webhookId: string) => void,
  onError?: (error: Error) => void,
) {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation<DeleteWebhookResponse, Error, DeleteWebhookRequest>({
    mutationFn: deleteWebhook,
    onSuccess: async (_, variables) => {
      queryClient.removeQueries([QueryKey.webhooks, variables.webhookId]);
      await queryClient.invalidateQueries([QueryKey.webhooks]);
      onSuccess?.(variables.webhookId);
    },
    onError: (error) => {
      onError?.(error);
      toast.show('Failed to delete webhook', {
        variant: 'negative',
      });
    },
  });
}
