import { useQuery } from '@tanstack/react-query';
import { fetchKillSwitches } from '@cbhq/client-kill-switch';

import { serviceUrl } from ':cloud/init/killSwitch/init';
import { QueryKey } from ':cloud/queries/types';

/**
 * The reason for this hook is solely to delay the app from loading until the Kill Switch service loads (or errors).
 * To query for an actual kill switch value, we'd call useKillSwitch('some_feature') in our app, and the Kill Switch
 * Provider would poll the service every 30 seconds for updates.
 */
export function useIsKillSwitchLoaded(): { isLoading: boolean } {
  const { isLoading } = useQuery({
    queryKey: [QueryKey.killSwitches],
    queryFn: async () => fetchKillSwitches({ optionalKillSwitchUrl: serviceUrl }),
    staleTime: Infinity,
  });

  return {
    isLoading,
  };
}
