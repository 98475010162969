import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import {
  ClientRecordNonSensitive,
  UpdateOAuthClientRequest,
  UpdateOAuthClientResponse,
} from ':cloud/types/service_proto';

interface UseUpdateOAuthClientOptions {
  onSuccess?: (client: ClientRecordNonSensitive) => void;
  onError?: (
    error: unknown,
    variables: {
      clientId: string;
      clientRequest: UpdateOAuthClientRequest;
    },
  ) => void;
}

async function updateOAuthClient(
  orgId: string,
  clientId: string,
  clientRequest: UpdateOAuthClientRequest,
): Promise<UpdateOAuthClientResponse | undefined> {
  const path = `apikeys/v1/organizations/${orgId}/oauthClients/${clientId}`;
  const { data } = await APIClientBase.put<UpdateOAuthClientResponse>(path, clientRequest);
  return data;
}

export function useUpdateOAuthClient(orgId: string, options: UseUpdateOAuthClientOptions = {}) {
  const { onSuccess, onError } = options;
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      clientId,
      clientRequest,
    }: {
      clientId: string;
      clientRequest: UpdateOAuthClientRequest;
    }) => updateOAuthClient(orgId, clientId, clientRequest),
    onSuccess: async (updatedClient) => {
      if (!updatedClient) return;
      queryClient.setQueryData(
        [QueryKey.oauth, updatedClient.clientRecordNonSensitive.clientId],
        updatedClient.clientRecordNonSensitive,
      );
      await queryClient.invalidateQueries([QueryKey.oauth]);
      onSuccess?.(updatedClient.clientRecordNonSensitive);
    },
    onError: (error, variables) => {
      onError?.(error, variables);
    },
  });
}
