import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, ButtonGroup } from '@cbhq/cds-web/buttons';
import { SelectOption } from '@cbhq/cds-web/controls';
import { Dropdown } from '@cbhq/cds-web/dropdown';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Box, Grid, HStack, VStack } from '@cbhq/cds-web/layout';
import { TabNavigation } from '@cbhq/cds-web/tabs';
import { TextDisplay3, TextTitle3, TextTitle4 } from '@cbhq/cds-web/typography';

import { ReactComponent as BaseDemoImg } from ':cloud/assets/Products/BaseDemo.svg';
import { ReactComponent as BaseGithubImg } from ':cloud/assets/Products/BaseGithub.svg';
import { ReactComponent as BaseQuickstartImg } from ':cloud/assets/Products/BaseQuickstart.svg';
import { useAppState } from ':cloud/contexts/AppStateContext';
import { PaymasterProvider } from ':cloud/contexts/PaymasterProvider';
import { useIsPaymasterAnalyticsEnabled } from ':cloud/hooks/featureFlags/useIsPaymasterAnalyticsEnabled';
import { useIsPaymasterGuideEnabled } from ':cloud/hooks/featureFlags/useIsPaymasterGuideEnabled';
import { useIsPaymasterRevampEnabled } from ':cloud/hooks/featureFlags/useIsPaymasterRevampEnabled';
import { useFindOrCreateToken } from ':cloud/hooks/useFindOrCreateToken';
import useLocalStorage from ':cloud/hooks/useLocalStorage';
import { EventName, logClick, useLogViewOnMount } from ':cloud/init/clientAnalytics/logging';
import { useGetPaymasterInfo } from ':cloud/queries/Base/useGetPaymasterInfo';
import { useGetPaymasterLogsDownload } from ':cloud/queries/Base/useGetPaymasterLogsDownload';
import { useGetPaymentMethod } from ':cloud/queries/BillingQueries/useGetPaymentMethod';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import {
  BASE_DEMO_APP_LINK,
  PAYMASTER_BUNDLER_DOCS_LINK,
  PAYMASTER_DISCORD_LINK,
  PAYMASTER_DOCS,
  PAYMASTER_GITHUB_LINK,
} from ':cloud/utils/links';
import { onExternalLinkPress } from ':cloud/utils/onExternalLinkPress';
import { useQueryParam } from ':cloud/utils/routes';
import { BaseNetworkDropdown } from ':cloud/widgets/base/BaseNetworkDropdown';
import { CreditBanner } from ':cloud/widgets/base/CreditBanner';
import { ExampleSection, ExampleSectionItem } from ':cloud/widgets/base/ExampleSection';
import { PaymasterAnalytics } from ':cloud/widgets/base/PaymasterAnalytics';
import { PaymasterGettingStarted } from ':cloud/widgets/base/PaymasterGettingStarted';
import { PaymasterSection } from ':cloud/widgets/base/PaymasterSection';
import { PaymasterV2 } from ':cloud/widgets/base/PaymasterV2';
import Playground from ':cloud/widgets/base/PlaygroundV2';
import { RPCEndpointSection } from ':cloud/widgets/base/RPCEndpointSection';
import { Layout } from ':cloud/widgets/layout';
import { ConfigurationItem } from ':cloud/widgets/product/ConfigurationItem';
import { baseConfig } from ':cloud/widgets/product/configurations/baseConfig';
import { FULL_PAGE_MAX_WIDTH, MAIN_CONTAINER_MIN_WIDTH } from ':cloud/widgets/product/constants';
import { KeyFeatureItem, KeyFeaturesSection } from ':cloud/widgets/product/KeyFeaturesSection';
import { PricingLandingTemplate } from ':cloud/widgets/product/PricingLandingTemplate';
import { PricingProps } from ':cloud/widgets/product/types';

const keyFeatures: KeyFeatureItem[] = [
  {
    iconName: 'gasFees',
    title: 'Sponsor gas',
    description: 'Improve user experience by removing gas fee friction.',
  },
  {
    iconName: 'browserTransaction',
    title: 'Batch transactions',
    description: 'Consolidate multi-step transactions into a single action to reduce gas fees.',
  },
  {
    iconName: 'security',
    title: 'Secure infrastructure',
    description: 'Comply with ERC-4337, providing a robust and standardized infrastructure.',
  },
];

const pricingSpecs: PricingProps[] = [
  {
    billingUnit: 'Testnet',
    price: 'Free',
  },
  {
    billingUnit: 'Mainnet',
    price: 'Gas +7% fee',
    tooltipContent: 'Invoiced monthly in arrears',
  },
];

// eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
const imageStyle = { maxWidth: '100%', height: 'auto', width: '100%' };

const exampleItems: ExampleSectionItem[] = [
  {
    title: 'Quickstart',
    description: 'Make your first sponsored transaction with Paymaster ',
    cta: 'Paymaster docs',
    onPress: onExternalLinkPress(PAYMASTER_BUNDLER_DOCS_LINK),
    image: <BaseQuickstartImg style={imageStyle} />,
  },
  {
    title: 'Demo',
    description: 'Learn how Paymaster & Bundler works with a fully functional demo app',
    cta: 'View demo',
    onPress: onExternalLinkPress(BASE_DEMO_APP_LINK),
    image: <BaseDemoImg style={imageStyle} />,
  },
  {
    title: 'More examples',
    description: 'Integrate with 3rd party SDKs',
    cta: 'Github',
    onPress: onExternalLinkPress(PAYMASTER_GITHUB_LINK),
    image: <BaseGithubImg style={imageStyle} />,
  },
];

const playgroundTabs = [
  { id: 'Paymaster', label: 'Paymaster' },
  { id: 'Bundler', label: 'Bundler' },
];

const paymasterTabs = [
  { id: 'overview', label: 'Overview' },
  { id: 'configuration', label: 'Configuration' },
  { id: 'playground', label: 'Playground' },
  { id: 'analytics', label: 'Analytics' },
  { id: 'logs', label: 'Logs' },
];

export function BundlerAndPaymasterLanding() {
  const { isDesktop } = useBreakpoints();
  const responsiveTemplateColumns = useMemo(() => {
    return isDesktop ? '2fr 1fr' : '1fr';
  }, [isDesktop]);

  const tabQueryParam = useQueryParam('tab');

  const isPaymasterRevampEnabled = useIsPaymasterRevampEnabled();
  const isPaymasterAnalyticsEnabled = useIsPaymasterAnalyticsEnabled();
  const isPaymasterGuideEnabled = useIsPaymasterGuideEnabled();

  // filter analytics tab out if experiment disabled
  const filteredPaymasterTabs = useMemo(() => {
    return paymasterTabs?.filter((tab) => tab.id !== 'analytics' || isPaymasterAnalyticsEnabled);
  }, [isPaymasterAnalyticsEnabled]);

  const { activeOrg } = useGetUser();
  const orgId = activeOrg?.organizationId;
  const { selectedProject } = useAppState();
  const { paymentMethod, isLoading: isLoadingPaymentMethod } = useGetPaymentMethod(orgId);
  const { token } = useFindOrCreateToken(
    activeOrg?.organizationId,
    selectedProject?.id,
    selectedProject?.type,
    selectedProject?.isTestnet,
  );

  const [completedSteps, setCompletedSteps] = useLocalStorage(
    `paymasterCompletedSteps-${selectedProject?.id}`,
    [''],
  );

  const getPaymasterLogsDownload = useGetPaymasterLogsDownload(orgId, selectedProject?.id);

  const [paymasterTab, setPaymasterTab] = useState(filteredPaymasterTabs[0].id);
  const [playgroundTab, setPlaygroundTab] = useState(playgroundTabs[0].label);
  const [selectedNetwork, setSelectedNetwork] = useState(
    baseConfig?.networkOptions?.[0].label || '',
  );

  useEffect(() => {
    if (paymasterTab === 'configuration') {
      logClick(EventName.paymaster_configuration_tab_click);
    }
    if (paymasterTab === 'logs') {
      logClick(EventName.paymaster_log_tab_click);
    }
    if (paymasterTab === 'playground') {
      logClick(EventName.paymaster_playground_tab_click);
    }
  }, [paymasterTab]);

  useEffect(() => {
    if (tabQueryParam) {
      setPaymasterTab(tabQueryParam);
    }
  }, [tabQueryParam]);

  useEffect(() => {
    if (paymentMethod && !completedSteps?.includes('payment')) {
      setCompletedSteps(completedSteps?.concat('payment'));
    }
  }, [completedSteps, paymentMethod, setCompletedSteps]);

  const handlePlaygroundTabChange = useCallback((tab: string) => {
    setPlaygroundTab(tab);
  }, []);

  const handleDownloadPress = useCallback(() => {
    logClick(EventName.paymaster_logs_download_click);
    getPaymasterLogsDownload.mutate();
  }, [getPaymasterLogsDownload]);

  useLogViewOnMount(EventName.paymaster_page_view);

  const { paymasterInfo: basePaymasterInfo } = useGetPaymasterInfo({
    organizationId: activeOrg?.organizationId,
    projectId: selectedProject?.id,
    isTestnet: false,
  });
  const { paymasterInfo: sepoliaPaymasterInfo } = useGetPaymasterInfo({
    organizationId: activeOrg?.organizationId,
    projectId: selectedProject?.id,
    isTestnet: true,
  });

  const hasSponsoredTxn =
    !!basePaymasterInfo?.sponsoredTransactionCount ||
    !!sepoliaPaymasterInfo?.sponsoredTransactionCount;

  // only show if user has not sponsored txn
  const shouldDisplayGuide = completedSteps && !hasSponsoredTxn && isPaymasterGuideEnabled;

  return (
    <PaymasterProvider>
      <Layout enableMaxWidth={false}>
        <Layout.MainContainer
          minWidth={MAIN_CONTAINER_MIN_WIDTH}
          spacingHorizontal={0}
          spacingTop={0}
          fullWidth
        >
          <Box display="block" width="100%" minWidth="100%">
            <VStack>
              <Box spacingStart={1}>
                <TabNavigation
                  value={paymasterTab}
                  tabs={filteredPaymasterTabs}
                  onChange={setPaymasterTab}
                  spacingHorizontal={5}
                  zIndex={0}
                />
              </Box>
              <VStack>
                <VStack maxWidth={FULL_PAGE_MAX_WIDTH} flexGrow={1} width="100%">
                  <VStack spacing={10} spacingTop={8} gap={5}>
                    {paymasterTab === 'overview' && (
                      <>
                        {!paymentMethod && !isLoadingPaymentMethod && (
                          <CreditBanner spacingTop={2} spacingHorizontal={0} />
                        )}
                        <HStack>
                          <Grid
                            maxWidth={FULL_PAGE_MAX_WIDTH}
                            templateColumns={responsiveTemplateColumns}
                            gap={9}
                            spacingTop={4}
                          >
                            <VStack justifyContent="center">
                              <TextDisplay3 as="h1">
                                Sponsor transactions for your users
                              </TextDisplay3>
                              <TextTitle4
                                as="h2"
                                color="foregroundMuted"
                                spacingTop={1}
                                spacingBottom={7}
                              >
                                Batch multi-step transactions and create custom gasless experiences.
                                Sponsor{' '}
                                <TextTitle4 as="span" color="positive">
                                  up to $10k monthly
                                </TextTitle4>{' '}
                                on mainnet (unlimited on testnet). To request an increase in limit,
                                reach out in Discord.
                              </TextTitle4>

                              <ButtonGroup>
                                <Button
                                  variant="primary"
                                  endIcon="diagonalUpArrow"
                                  onPress={onExternalLinkPress(PAYMASTER_DOCS)}
                                  accessibilityLabel="View docs + opens in new window"
                                >
                                  View docs
                                </Button>
                                <Button
                                  variant="secondary"
                                  endIcon="diagonalUpArrow"
                                  onPress={onExternalLinkPress(PAYMASTER_DISCORD_LINK)}
                                  accessibilityLabel="Discord channel + opens in new window"
                                >
                                  Discord channel
                                </Button>
                              </ButtonGroup>
                            </VStack>
                            <PricingLandingTemplate
                              specs={pricingSpecs}
                              pricingDescription="SPONSORED TRANSACTIONS"
                              displayRemainingCredits
                            />
                          </Grid>
                        </HStack>
                      </>
                    )}
                    {paymasterTab === 'configuration' && (
                      <>
                        <HStack gap={2} alignItems="center" justifyContent="space-between">
                          <TextTitle3 as="h1">Configuration</TextTitle3>
                          <BaseNetworkDropdown
                            setSelectedNetwork={setSelectedNetwork}
                            selectedNetwork={selectedNetwork}
                          />
                        </HStack>
                        <RPCEndpointSection
                          selectedNetwork={selectedNetwork}
                          title="Paymaster & Bundler endpoint"
                        />

                        {isPaymasterRevampEnabled && token ? (
                          <PaymasterV2
                            token={token}
                            isTestnet={selectedNetwork !== baseConfig?.networkOptions?.[0].label}
                          />
                        ) : (
                          <PaymasterSection />
                        )}
                      </>
                    )}
                    {paymasterTab === 'playground' && (
                      <>
                        <HStack gap={2} alignItems="center" justifyContent="space-between">
                          <TextTitle3 as="h1">Playground</TextTitle3>
                          <HStack gap={2}>
                            <HStack gap={2} alignItems="center" justifyContent="space-between">
                              <BaseNetworkDropdown
                                setSelectedNetwork={setSelectedNetwork}
                                selectedNetwork={selectedNetwork}
                              />
                            </HStack>

                            <Dropdown
                              value={playgroundTab}
                              onChange={handlePlaygroundTabChange}
                              content={playgroundTabs.map((tab) => {
                                return (
                                  <SelectOption key={tab.id} value={tab.label} title={tab.label} />
                                );
                              })}
                              enableMobileModal
                            >
                              <Button compact variant="secondary" endIcon="caretDown">
                                {playgroundTab}
                              </Button>
                            </Dropdown>
                          </HStack>
                        </HStack>
                        <ConfigurationItem
                          title="Make a request"
                          description="Try the request below or copy it into your terminal to run your first request."
                          iconName="arrowsHorizontal"
                          expandedContent={
                            <Playground
                              requestID={playgroundTab}
                              isTestnet={selectedNetwork !== baseConfig?.networkOptions?.[0].label}
                            />
                          }
                        />
                      </>
                    )}
                    {paymasterTab === 'analytics' && (
                      <PaymasterAnalytics
                        selectedNetwork={selectedNetwork}
                        setSelectedNetwork={setSelectedNetwork}
                      />
                    )}
                    {paymasterTab === 'logs' && (
                      <VStack gap={5}>
                        <TextTitle3 as="h1" spacingTop={0.5}>
                          Logs
                        </TextTitle3>
                        <ConfigurationItem
                          title="Download Paymaster Logs"
                          description="Download Paymaster userops logs as a CSV file."
                          iconName="download"
                          expandedContent={
                            <Box spacingStart={3}>
                              <Button onPress={handleDownloadPress}>Download CSV</Button>
                            </Box>
                          }
                        />
                      </VStack>
                    )}
                  </VStack>
                </VStack>
              </VStack>
              {paymasterTab === 'overview' && shouldDisplayGuide && (
                <PaymasterGettingStarted
                  gasPolicy={token?.policies?.[0]}
                  paymentMethod={paymentMethod}
                />
              )}
              {paymasterTab !== 'analytics' && (
                <>
                  <ExampleSection exampleItems={exampleItems} />
                  <KeyFeaturesSection features={keyFeatures} />
                </>
              )}
            </VStack>
          </Box>
        </Layout.MainContainer>
      </Layout>
    </PaymasterProvider>
  );
}
