import { useMemo } from 'react';
import { Button, IconButton } from '@cbhq/cds-web/buttons';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Icon } from '@cbhq/cds-web/icons';
import { SpotIcon } from '@cbhq/cds-web/illustrations';
import { Box, Grid, GridColumn, HStack, VStack } from '@cbhq/cds-web/layout';
import { Pressable } from '@cbhq/cds-web/system';
import { Tag } from '@cbhq/cds-web/tag/Tag';
import {
  TextBody,
  TextCaption,
  TextHeadline,
  TextTitle1,
  TextTitle3,
} from '@cbhq/cds-web/typography';

import { LandingPageHero } from ':cloud/pages/Verifications/LandingPageHero';
import {
  heroContent,
  keyFeatures,
  resourcesContent,
  verificationsVisionContent,
  whatCanYouVerifyContent,
} from ':cloud/pages/Verifications/verificationsOverviewContent';
import { VERIFICATIONS_QUICKSTART_LINK } from ':cloud/utils/links';
import { onExternalLinkPress } from ':cloud/utils/onExternalLinkPress';
import { FULL_PAGE_MAX_WIDTH } from ':cloud/widgets/product/constants';
import { KeyFeaturesSection } from ':cloud/widgets/product/KeyFeaturesSection';

export function VerificationsOverview() {
  const { isDesktop } = useBreakpoints();
  const ninthsColumns = useMemo(() => {
    return isDesktop ? '4fr 5fr' : '1fr';
  }, [isDesktop]);
  const thirdsColumns = useMemo(() => {
    return isDesktop ? '1fr 1fr 1fr' : '1fr';
  }, [isDesktop]);

  return (
    <VStack>
      <LandingPageHero {...heroContent} spacingVertical={9} />
      <Grid templateColumns={ninthsColumns} maxWidth={FULL_PAGE_MAX_WIDTH}>
        <VStack borderedEnd spacing={7}>
          <TextCaption as="h2" color="primary" spacingBottom={3}>
            About
          </TextCaption>
          <TextTitle1 as="h3" spacingBottom={1}>
            What are Verifications?
          </TextTitle1>
          <TextBody as="p" color="foregroundMuted" spacingBottom={7}>
            Verifications let you verify that a user owns a wallet that&apos;s linked to a Coinbase
            trading account, the country they live in, and more. Once you have their address, you
            can easily collect the metadata associated with the attestation and confirm they&apos;re
            verified.
            <br />
            <br />
            We&apos;ll walk you through every step.
          </TextBody>
          <Box>
            <Button
              variant="secondary"
              onPress={onExternalLinkPress(VERIFICATIONS_QUICKSTART_LINK)}
            >
              Get started
            </Button>
          </Box>
        </VStack>
        <VStack spacing={7} gap={3}>
          <TextTitle3 as="h3">What can you verify?</TextTitle3>
          {whatCanYouVerifyContent.map((item) => (
            <HStack borderRadius="rounded" bordered justifyContent="space-between">
              <HStack gap={3} spacingVertical={3} spacingStart={3}>
                <SpotIcon name={item.icon} />
                <VStack>
                  <HStack gap={0.5} alignItems="center">
                    <TextHeadline as="p">{item.title}</TextHeadline>
                    {item.tag && (
                      <Tag intent="promotional" background="blue0" color="gray60">
                        {item.tag}
                      </Tag>
                    )}
                  </HStack>
                  <TextBody as="p" color="foregroundMuted">
                    {item.description}
                  </TextBody>
                </VStack>
              </HStack>
              {/* hacky div is required because CDS doesn't let you do partial border radii */}
              <div
                style={{
                  background: 'var(--secondary)',
                  borderRadius: '0 var(--border-radius-rounded) var(--border-radius-rounded) 0',
                }}
              >
                <VStack spacing={3}>
                  <TextHeadline as="p">{item.count}</TextHeadline>
                  <TextBody as="p" color="foregroundMuted">
                    wallets
                  </TextBody>
                </VStack>
              </div>
            </HStack>
          ))}
        </VStack>
      </Grid>
      <KeyFeaturesSection features={keyFeatures} borderedTop />
      <VStack borderedTop spacing={7}>
        <Grid templateColumns={thirdsColumns} gap={5} maxWidth={FULL_PAGE_MAX_WIDTH}>
          <GridColumn colSpan={2}>
            <VStack>
              <TextCaption as="h2" color="primary" spacingBottom={3}>
                Vision
              </TextCaption>
              <TextTitle1 as="h3" spacingBottom={1}>
                The Verifications vision
              </TextTitle1>
              <TextBody as="p" color="foregroundMuted">
                We&apos;re enabling developers to tap into a pool of Coinbase verified users without
                compromising their security. We plan to continue releasing more Verifications in the
                coming future to help create an identity layer which provides developers an
                opportunity to attract genuine interactions with addresses by abstracting the
                identification process.
              </TextBody>
            </VStack>
          </GridColumn>
          <Box>
            <VStack
              background="primaryWash"
              borderRadius="rounded"
              spacing={2}
              alignSelf="flex-end"
              width="100%"
            >
              <Icon name="info" color="primary" size="m" />
              <TextHeadline as="p" spacingTop={1}>
                Latest update
              </TextHeadline>
              <TextBody as="p" color="foregroundMuted">
                Coinbase One member verification added
              </TextBody>
            </VStack>
          </Box>
          {verificationsVisionContent.map((item) => (
            <VStack spacing={5} background="backgroundAlternate" borderRadius="rounded">
              <Box borderRadius="rounded" overflow="hidden" width={32} height={32}>
                <img src={item.media} alt={item.title} width="32px" height="32px" />
              </Box>
              <TextHeadline as="p" spacingTop={3} spacingBottom={0.5}>
                {item.title}
              </TextHeadline>
              <TextBody as="p" color="foregroundMuted">
                {item.description}
              </TextBody>
            </VStack>
          ))}
        </Grid>
      </VStack>
      <VStack spacing={7} borderedTop>
        <TextTitle3 as="h3" spacingBottom={5}>
          Resources
        </TextTitle3>
        <Grid templateColumns={thirdsColumns} gap={3} maxWidth={FULL_PAGE_MAX_WIDTH}>
          {resourcesContent.map((item) => (
            <Pressable
              onPress={onExternalLinkPress(item.to)}
              background="transparent"
              accessibilityLabel={`${item.title} + opens in a new window`}
            >
              <HStack spacing={5} gap={3} bordered borderRadius="rounded">
                <IconButton name={item.icon} />
                <VStack>
                  <TextHeadline as="p">{item.title}</TextHeadline>
                  <TextBody as="p" color="foregroundMuted">
                    {item.description}
                  </TextBody>
                </VStack>
              </HStack>
            </Pressable>
          ))}
        </Grid>
      </VStack>
    </VStack>
  );
}
