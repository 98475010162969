import { CDSAlias, cdsAliasToRgba, CDSSpectrum, rgbaToHexA } from ':cloud/brand/colors';

export const PAYMENT_METHOD_DISPLAY_CARD_HEIGHT = 56;

/**
 * Root level CSS variables are not available in the Stripe Elements iframe, plus
 * Stripe Elements doesn't allow rgba, so we need to provide the colors explicitly
 * as hexA strings.
 */
export function getCdsPaletteForStripe(spectrum: CDSSpectrum): Record<CDSAlias, string> {
  return {
    primary: rgbaToHexA(cdsAliasToRgba('primary', spectrum)),
    primaryForeground: rgbaToHexA(cdsAliasToRgba('primaryForeground', spectrum)),
    primaryWash: rgbaToHexA(cdsAliasToRgba('primaryWash', spectrum)),
    secondary: rgbaToHexA(cdsAliasToRgba('secondary', spectrum)),
    secondaryForeground: rgbaToHexA(cdsAliasToRgba('secondaryForeground', spectrum)),
    negative: rgbaToHexA(cdsAliasToRgba('negative', spectrum)),
    negativeForeground: rgbaToHexA(cdsAliasToRgba('negativeForeground', spectrum)),
    negativeWash: rgbaToHexA(cdsAliasToRgba('negativeWash', spectrum)),
    positive: rgbaToHexA(cdsAliasToRgba('positive', spectrum)),
    positiveForeground: rgbaToHexA(cdsAliasToRgba('positiveForeground', spectrum)),
    warning: rgbaToHexA(cdsAliasToRgba('warning', spectrum)),
    warningForeground: rgbaToHexA(cdsAliasToRgba('warningForeground', spectrum)),
    foreground: rgbaToHexA(cdsAliasToRgba('foreground', spectrum)),
    foregroundMuted: rgbaToHexA(cdsAliasToRgba('foregroundMuted', spectrum)),
    background: rgbaToHexA(cdsAliasToRgba('background', spectrum)),
    backgroundAlternate: rgbaToHexA(cdsAliasToRgba('backgroundAlternate', spectrum)),
    backgroundInverse: rgbaToHexA(cdsAliasToRgba('backgroundInverse', spectrum)),
    backgroundOverlay: rgbaToHexA(cdsAliasToRgba('backgroundOverlay', spectrum)),
    line: rgbaToHexA(cdsAliasToRgba('line', spectrum)),
    lineHeavy: rgbaToHexA(cdsAliasToRgba('lineHeavy', spectrum)),
    transparent: rgbaToHexA(cdsAliasToRgba('transparent', spectrum)),
  };
}
