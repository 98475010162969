import { css } from '@linaria/core';
import { fromUnixTime, sub } from 'date-fns';
import _findKey from 'lodash/findKey';
import _last from 'lodash/last';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { VStack } from '@cbhq/cds-web/layout';
import { deviceBreakpoints } from '@cbhq/cds-web/layout/breakpoints';
import { palette, spacing } from '@cbhq/cds-web/tokens';

import { ChartWidgetType } from ':cloud/types/helper';
import { formatDate } from ':cloud/utils/date';
import {
  CustomTooltip,
  CustomXAxisTick,
  getGraphMetricsByKey,
  getTooltipConfig,
  getXAxisTickLabels,
  IChartPointType,
  tooltipProps,
  xAxisProps,
  yAxisProps,
} from ':cloud/widgets/charts/ChartUtils';
import { TextLabelGray } from ':cloud/widgets/sharedcomponents';

const lineChartMargin = { top: 10, right: 30, left: 0, bottom: 0 };

const nodeUptimeStyles = css`
  margin-left: -${spacing[3]};
  margin-right: 0;
`;

// Handling MQs this way because CDS doesn't use margins and I want to maintain
// parity with the chart that already exists.
// Don't do this otherwise! Use responsiveConfig or useSimpleBreakpoints instead.
const chartLabelStyles = css`
  margin-left: 10%;
  @media (min-width: ${deviceBreakpoints.phone}px}) {
    margin-left: 0;
  }
`;

interface StatusDotProps {
  cx: number;
  cy: number;
  payload: IChartPointType;
}
/*
createUptimeChart returned an uptime chart with minimum totalCount of graphs points
timestamp are 12 hr intervals
*/
function createUptimeChart(
  data: IChartPointType[],
  uptimeKey: string,
  totalCount = 5,
): IChartPointType[] {
  const currentDate = new Date();
  if (data && data.length > 0 && data.length < totalCount) {
    const { unixTimestamp: lastTimestamp } = _last(data) as any;
    const newChart: IChartPointType[] = [...new Array(totalCount - data.length).fill(null)];
    const placeholderChart: IChartPointType[] = newChart.map((_, idx) => ({
      idx,
      [uptimeKey]: '100',
      placeholder: true,
      unixTimestamp: sub(fromUnixTime(lastTimestamp), { hours: (totalCount - idx) * 12 }), // debug timestamp
      timestamp: formatDate(
        sub(fromUnixTime(lastTimestamp), { hours: (totalCount - idx) * 12 }),
        'MMM d h:mma',
      ),
    }));
    return placeholderChart.concat(data);
  }
  if (data && data.length >= totalCount) {
    return data;
  }
  const newChart: IChartPointType[] = [...new Array(totalCount).fill(null)];
  const placeholderChart = newChart.map((_, idx) => ({
    idx,
    uptime: '100',
    placeholder: true,
    unixTimestamp: sub(currentDate, { hours: (totalCount - idx) * 12 }), // debug timestamp
    timestamp: formatDate(sub(currentDate, { hours: (totalCount - idx) * 12 }), 'MMM d h:mma'),
  }));
  return placeholderChart;
}

const domain = [0, 100];

export function UptimeChartWidget({ sources, options, metricsData }: ChartWidgetType) {
  const metricOptions = options.sources.metrics;
  const uptimeKey = _findKey(metricOptions, { type: 'uptime_chart' }) || 'uptime';
  const formatUptime = (x: string): string => (parseFloat(x) * 100).toFixed(2);
  const uptimeValues = getGraphMetricsByKey(metricsData, uptimeKey, undefined, formatUptime);
  // @ts-expect-error TODO: resolve after 12/22 repo migration
  const uptimeData = createUptimeChart(uptimeValues, uptimeKey);
  const XAxisTicks = getXAxisTickLabels(uptimeData);

  function renderStatusDot({ cx, cy, payload }: StatusDotProps) {
    return (
      <svg
        key={String(payload.timestamp)}
        x={cx - 4}
        y={cy - 4}
        width={8}
        height={8}
        viewBox="0 0 8 8"
      >
        <circle cx="4" cy="4" r="2" fill={payload.placeholder ? palette.line : palette.primary} />
      </svg>
    );
  }

  function renderActiveDot({ cx, cy, payload }: StatusDotProps) {
    const colorFill = payload.placeholder ? palette.line : palette.primary;
    return (
      <svg x={cx - 10} y={cy - 10} width="23" height="23" viewBox="0 0 23 23">
        <circle cx="11.5" cy="11.5" r="11.5" fill={colorFill} fillOpacity="0.4" />
        <circle cx="11.5" cy="11.5" r="4.5 " fill={colorFill} />
      </svg>
    );
  }

  const renderCustomTick = (props) => <CustomXAxisTick {...props} ticks={XAxisTicks} />;

  // TODO(arti.villa):  tooltip is not visible outside the wrapper, bug from porting charts
  const isColoredStroke = uptimeValues ? uptimeValues.length >= 5 : undefined;
  return (
    <VStack spacing={2} width="100%" height={135}>
      <TextLabelGray as="div" align="center" className={chartLabelStyles}>
        48 hr uptime
      </TextLabelGray>
      <ResponsiveContainer className={nodeUptimeStyles}>
        <LineChart margin={lineChartMargin} data={uptimeData}>
          <XAxis {...xAxisProps} ticks={XAxisTicks} tick={renderCustomTick} />
          <YAxis {...yAxisProps} tickCount={2} dataKey={uptimeKey} domain={domain} unit="%" />
          <Tooltip
            {...tooltipProps}
            content={<CustomTooltip active mapPayloadKeys={getTooltipConfig(options, sources)} />}
          />
          <Line
            dataKey={uptimeKey}
            dot={(props) => renderStatusDot(props)}
            activeDot={(props) => renderActiveDot(props as StatusDotProps)}
            stroke={isColoredStroke ? palette.primary : palette.line}
            strokeWidth={1}
            type="monotone"
            strokeDasharray={isColoredStroke ? undefined : '5 5'}
          />
        </LineChart>
      </ResponsiveContainer>
    </VStack>
  );
}
