import { ChangeEvent, ReactElement, useCallback, useState } from 'react';
import type { ButtonVariant } from '@cbhq/cds-common/types/ButtonBaseProps';
import { Button } from '@cbhq/cds-web/buttons';
import { TextInput } from '@cbhq/cds-web/controls';
import { VStack } from '@cbhq/cds-web/layout';
import { Modal, ModalBody, ModalFooter } from '@cbhq/cds-web/overlays';
import { TextBody } from '@cbhq/cds-web/typography';

import { CloudModalHeader } from './CloudModalHeader';

interface AlertConfirmationProps {
  title: string;
  visible: boolean;
  body: string | ReactElement;
  preferredActionLabel: string;
  onPreferredActionPress: () => void;
  dismissActionLabel?: string;
  onDismissActionPress: () => void;
  variant?: Exclude<ButtonVariant, 'foregroundMuted'>;
}

interface AlertConfirmationInputProps extends AlertConfirmationProps {
  isSubmitting?: boolean;
  inputLabel?: string;
  typeConfirm?: string;
}
/* only used in Participate */
export function AlertConfirmation({
  preferredActionLabel,
  onPreferredActionPress,
  dismissActionLabel = 'Cancel',
  onDismissActionPress,
  body,
  visible = false,
  title,
  typeConfirm,
  inputLabel = '',
  isSubmitting,
  variant = 'primary',
}: AlertConfirmationInputProps) {
  const [confirmText, setConfirmText] = useState('');

  const onCancel = useCallback(() => {
    setConfirmText('');
    onDismissActionPress();
  }, [onDismissActionPress]);

  const onAction = useCallback(() => {
    setConfirmText('');
    onPreferredActionPress();
  }, [onPreferredActionPress]);

  return (
    <Modal visible={visible} onRequestClose={onCancel} hideDividers>
      <CloudModalHeader title={title} />
      <ModalBody>
        <VStack gap={2} spacingBottom={2}>
          {typeof body === 'string' ? <TextBody as="span">{body}</TextBody> : body}
          {typeConfirm && (
            <TextInput
              spellCheck={false}
              label={inputLabel}
              placeholder={typeConfirm}
              value={confirmText}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setConfirmText(event.target.value)
              }
            />
          )}
        </VStack>
      </ModalBody>
      <ModalFooter
        primaryAction={
          <Button
            onPress={onAction}
            disabled={isSubmitting || typeConfirm ? typeConfirm !== confirmText : false}
            variant={variant}
          >
            {preferredActionLabel}
          </Button>
        }
        secondaryAction={
          <Button variant="secondary" onPress={onCancel}>
            {dismissActionLabel}
          </Button>
        }
      />
    </Modal>
  );
}
