import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Banner } from '@cbhq/cds-web/banner/Banner';
import { IconButton } from '@cbhq/cds-web/buttons';
import { Button } from '@cbhq/cds-web/buttons/Button';
import { SearchInput, SelectOption } from '@cbhq/cds-web/controls';
import { Dropdown } from '@cbhq/cds-web/dropdown';
import { Divider, HStack, VStack } from '@cbhq/cds-web/layout';
import { Tooltip } from '@cbhq/cds-web/overlays/Tooltip/Tooltip';
import { Table, TableBody, TableCell, TableRow } from '@cbhq/cds-web/tables';
import { TextBody, TextHeadline, TextLabel1, TextTitle3 } from '@cbhq/cds-web/typography';

import { wordBreakAll } from ':cloud/brand/utilityClasses';
import { useOAuthInfo } from ':cloud/contexts/OAuthInfoContext';
import { useGetOAuthClients } from ':cloud/queries/OAuthClientQueries/useGetOAuthClients';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { ClientRecordNonSensitive } from ':cloud/types/service_proto';
import { AppRoute } from ':cloud/utils/routes';
import { SELECT_OPTION_WIDTH } from ':cloud/widgets/access/constants';
import { getKeyId } from ':cloud/widgets/access/helpers';
import OAuthTableEmptyState from ':cloud/widgets/access/oauth/OAuthTableEmptyState';
import OAuthTableHeader from ':cloud/widgets/access/oauth/OAuthTableHeader';
import OAuthTableLoadingState from ':cloud/widgets/access/oauth/OAuthTableLoadingState';
import { ClipboardIcon } from ':cloud/widgets/sharedcomponents/ClipboardIcon';

interface OAuthTableProps {
  onDeletePress: (clientId: string) => void;
}

export function OAuthTable({ onDeletePress }: OAuthTableProps) {
  const { activeOrg } = useGetUser();
  const history = useHistory();

  const { clients, isLoading } = useGetOAuthClients(activeOrg?.organizationId);
  const { sensitiveInfo, setSensitiveInfo, setSelectedClient } = useOAuthInfo();
  const [isCreated, setIsCreated] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const editClient = useCallback(
    (client: ClientRecordNonSensitive) => {
      setSelectedClient(client);
      history.push(AppRoute.Access.OAuthEdit);
    },
    [history, setSelectedClient],
  );

  useEffect(() => {
    if (sensitiveInfo) {
      setIsCreated(true);
    }
  }, [sensitiveInfo]);

  const handleDismissBanner = useCallback(() => {
    setIsCreated(false);
    setSensitiveInfo(null);
  }, [setSensitiveInfo]);

  const handleSearchChange = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  const filteredClients = useMemo(() => {
    return clients
      .filter((client) => (client.name || '').toLowerCase().includes(searchValue.toLowerCase()))
      .sort((a, b) => (a.name || '').localeCompare(b.name || ''));
  }, [clients, searchValue]);

  const renderTableContent = useMemo(() => {
    if (isLoading) {
      return <OAuthTableLoadingState />;
    }

    if (filteredClients.length === 0) {
      return <OAuthTableEmptyState />;
    }

    return (
      <Table tableLayout="auto">
        <OAuthTableHeader />
        <TableBody>
          {filteredClients.map((client) => (
            <TableRow key={client.clientId}>
              <TableCell>
                <TextHeadline as="p">{client.name}</TextHeadline>
              </TableCell>
              <TableCell overflow="truncate">
                <Tooltip content={client.redirectUris}>
                  <TextBody as="p">{client.redirectUris}</TextBody>
                </Tooltip>
              </TableCell>
              <TableCell overflow="truncate">
                <Tooltip content={client.developerWebsite}>
                  <TextBody as="p">{client.developerWebsite}</TextBody>
                </Tooltip>
              </TableCell>{' '}
              <TableCell>
                <HStack gap={1} alignItems="center">
                  <VStack background="primaryWash" spacingHorizontal={1}>
                    <TextBody as="p" mono>
                      {getKeyId(client.clientId || '').formattedKeyId}
                    </TextBody>
                  </VStack>
                  <ClipboardIcon text={client.clientId || ''} iconSize="s" stopPropagation />
                </HStack>
              </TableCell>
              <TableCell>
                <Dropdown
                  content={
                    <>
                      <SelectOption
                        onPress={() => {
                          editClient(client);
                        }}
                        value="edit"
                        title="Edit"
                      />
                      <SelectOption
                        onPress={() => {
                          onDeletePress(client.clientId || '');
                        }}
                        title={
                          <TextHeadline as="p" color="negative">
                            Delete
                          </TextHeadline>
                        }
                        value="delete"
                      />
                    </>
                  }
                >
                  <IconButton name="more" />
                </Dropdown>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }, [isLoading, filteredClients, editClient, onDeletePress]);

  return (
    <VStack gap={2}>
      <HStack width="100%" justifyContent="space-between">
        <VStack width="66%" gap={3} spacingBottom={3}>
          <TextTitle3 as="p">OAuth Clients</TextTitle3>
          <TextBody as="p" color="foregroundMuted">
            The Coinbase App API uses OAuth2 to grant OAuth client access to user accounts without
            sharing API keys or login credentials. The Retail Advanced Trade API also utilizes OAuth
            for authentication.
          </TextBody>
        </VStack>
      </HStack>
      <HStack gap={4} justifyContent="space-between">
        <SearchInput
          width={SELECT_OPTION_WIDTH}
          placeholder="Search OAuth clients"
          value={searchValue}
          onChangeText={handleSearchChange}
        />
        <HStack gap={2}>
          <Button
            onPress={() => {
              history.push(AppRoute.Access.OAuthCreate);
              // navigate to OAuth create page
            }}
          >
            Create client
          </Button>
        </HStack>
      </HStack>
      <Divider />
      {/* ON CREATE BANNER */}
      {isCreated && (
        <Banner
          title="Successfully created a new OAuth client"
          startIcon="circleCheckmark"
          showDismiss
          variant="promotional"
          onClose={handleDismissBanner}
        >
          <VStack gap={1}>
            <TextBody as="p">
              Store your client secret securely; it won&apos;t be visible again.
            </TextBody>
            <VStack gap={0.5} spacingBottom={2}>
              <TextLabel1 as="p">Client ID</TextLabel1>
              <HStack
                justifyContent="space-between"
                gap={10}
                spacing={2}
                alignItems="center"
                background="secondary"
                borderRadius="roundedSmall"
              >
                <VStack alignItems="center">
                  <TextBody as="p" mono color="foregroundMuted" className={wordBreakAll}>
                    {sensitiveInfo?.oauthClientID}
                  </TextBody>
                </VStack>
                <ClipboardIcon text={sensitiveInfo?.oauthClientID || ''} />
              </HStack>
            </VStack>
            <VStack gap={0.5} spacingBottom={2}>
              <TextLabel1 as="p">Secret</TextLabel1>
              <HStack
                justifyContent="space-between"
                gap={10}
                spacing={2}
                alignItems="center"
                background="secondary"
                borderRadius="roundedSmall"
              >
                <VStack alignItems="center">
                  <TextBody as="p" mono color="foregroundMuted" className={wordBreakAll}>
                    {sensitiveInfo?.oauthClientSecret}
                  </TextBody>
                </VStack>
                <ClipboardIcon text={sensitiveInfo?.oauthClientSecret || ''} />
              </HStack>
            </VStack>
          </VStack>
        </Banner>
      )}
      {renderTableContent}
    </VStack>
  );
}
