import { logError } from ':cloud/init/bugsnag/logging';
import { BillingInterval } from ':cloud/types/ts_types';

const formatters = new Map<string, Intl.NumberFormat>([
  [
    'en-US__USD__nodecimal',
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
  ],
  [
    'en-US__USD__decimal',
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
  ],
]);

export function currencyFormatter(amount: number, currencyUnit = 'USD', locale = 'en-US'): string {
  const decimalKey = amount % 1 !== 0 ? 'decimal' : 'nodecimal';
  const formatterKey = `${locale}__${currencyUnit}__${decimalKey}`;

  try {
    if (!formatters.has(formatterKey)) {
      formatters.set(
        formatterKey,
        new Intl.NumberFormat(locale, {
          style: 'currency',
          currency: currencyUnit,
          minimumFractionDigits: 0,
        }),
      );
    }

    const formatter = formatters.get(formatterKey) as Intl.NumberFormat;
    return formatter.format(amount);
  } catch (error) {
    logError(error, {
      context: 'currencyFormatter',
    });
    // eslint-disable-next-line no-console
    console.error(`Error formatting currency: ${error}`);
    return `${amount.toFixed(2)} ${currencyUnit}`;
  }
}

function formatPriceCentis(value: bigint): string {
  const units = Number(value / 100n);
  const decimals = Number(value % 100n) / 100;
  return currencyFormatter(units + decimals);
}

function formatPeriodShort(value: BillingInterval): string {
  switch (value) {
    case 'month':
      return 'mo';
    case 'year':
      return 'yr';
    default:
      return value;
  }
}

export function formatRecurringPriceCentis(
  value: bigint,
  period: BillingInterval = 'month',
): string {
  return `${formatPriceCentis(value)}/${formatPeriodShort(period)}`;
}
