import { useCallback } from 'react';
import { noop } from '@cbhq/cds-utils';
import { BoxProps } from '@cbhq/cds-web/layout';
import { Modal, ModalBody, ModalHeader } from '@cbhq/cds-web/overlays';
import { ConfirmTwoFactor } from '@cbhq/two-factor-web';
import {
  TwoFactorConstraints,
  TwoFactorConstraintsMode,
  TwoFactorError,
  TwoFactorErrorHandler,
  TwoFactorType,
} from '@cbhq/two-factor-web/types';

import config from ':cloud/config';
import { logError } from ':cloud/init/bugsnag/logging';

const API_KEY_CREATION_UNIFIED_TWO_FACTOR_ACTION = 'cloud-api-key-creation';

const UNIFIED_TWO_FACTOR_CONSTRAINTS: TwoFactorConstraints = {
  mode: TwoFactorConstraintsMode.Allow,
  types: [
    TwoFactorType.SMS,
    TwoFactorType.TOTP,
    TwoFactorType.U2F,
    TwoFactorType.Passkey,
    TwoFactorType.RecoveryCode,
    TwoFactorType.IDV,
    TwoFactorType.No2FA,
  ],
};

const UNIFIED_TWO_FACTOR_BOX_PROPS: BoxProps = {
  spacingHorizontal: 4,
  spacingVertical: 4,
  alignItems: 'center',
  maxWidth: 'none',
};

type APIKeyTwoFactorModalProps = {
  onRequestClose: () => void;
  onSuccess: (proofToken: string) => void;
  onFailure: (error: TwoFactorError) => void;
  title: string;
};

export function APIKeyTwoFactorModal({
  onRequestClose,
  onSuccess,
  onFailure,
  title,
}: APIKeyTwoFactorModalProps) {
  const handleSuccess = useCallback(
    (token: string) => {
      onSuccess(token);
      onRequestClose();
    },
    [onRequestClose, onSuccess],
  );

  const handleError = useCallback(
    (error: TwoFactorError, next?: TwoFactorErrorHandler) => {
      onFailure(error);
      logError(
        error.message,
        {
          context: 'api_key_two_factor_modal',
        },
        { name: 'API Key Creation Two Factor Error', error: error.message },
      );
      void next?.(error);
    },
    [onFailure],
  );

  return (
    <Modal onRequestClose={onRequestClose} visible>
      <ModalHeader title={title} />
      <ModalBody>
        <ConfirmTwoFactor
          onToast={noop}
          projectId={config.confirmTwoFactorProjectId}
          action={API_KEY_CREATION_UNIFIED_TWO_FACTOR_ACTION}
          onCancel={onRequestClose}
          onSuccess={handleSuccess}
          onError={handleError}
          constraints={UNIFIED_TWO_FACTOR_CONSTRAINTS}
          rootBoxProps={UNIFIED_TWO_FACTOR_BOX_PROPS}
        />
      </ModalBody>
    </Modal>
  );
}
