import participateTerms from ':cloud/content/ParticipateTerms/default.json';
import eth2Terms from ':cloud/content/ParticipateTerms/eth2.json';
import solanaTerms from ':cloud/content/ParticipateTerms/solana.json';
import { IMetricsRoot, IWorkflowMap, ProtocolKey, ProtocolMetricKeys } from ':cloud/types/ts_types';
import { type TermDefinition } from ':cloud/widgets/sharedcomponents/Terminology';

/**  alternate to useGetProtocolDisplayName hook when only pure function is applicable  */
export function getProtocolDisplayName(workflows: IWorkflowMap, protocolKey: ProtocolKey): string {
  return workflows[protocolKey]?.display || 'Missing Name';
}

export function getNodeTerminology(protocolKey: ProtocolKey): TermDefinition[] {
  switch (protocolKey) {
    case 'eth2':
      return eth2Terms;
    case 'solana':
      return participateTerms.concat(solanaTerms);
    default:
      return participateTerms;
  }
}

export function extractMetricsKeys(rawMetrics: IMetricsRoot): ProtocolMetricKeys {
  return {
    blockHeightKey: rawMetrics?.keys?.block_height ?? 'block_height',
    earningsKey: rawMetrics?.keys?.earnings ?? 'earnings',
    totalStakedKey: rawMetrics?.keys?.total_staked ?? 'total_staked',
    uptimeKey: rawMetrics?.keys?.uptime ?? 'uptime',
  };
}
