import { useCallback, useMemo, useState } from 'react';
import { useToggler } from '@cbhq/cds-common';
import { Button } from '@cbhq/cds-web/buttons';
import { Pictogram } from '@cbhq/cds-web/illustrations';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { Table, TableBody, TableCell, TableHeader, TableRow } from '@cbhq/cds-web/tables';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

import { useAppState } from ':cloud/contexts/AppStateContext';
import { usePaymasterContext } from ':cloud/contexts/PaymasterProvider';
import { EventName, logClick } from ':cloud/init/clientAnalytics/logging';
import { useUpdateGasPolicy } from ':cloud/queries/Base/useUpdateGasPolicy';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { GasPolicy, WhitelistedAddressAndMethod } from ':cloud/types/ts_types';
import { getWhitelistItemKey } from ':cloud/utils/paymaster';

import { PaymasterViewAllowlistModal } from './PaymasterViewAllowlistModal';

type PaymasterAllowlistSectionProps = {
  whitelistedAddressAndMethods: WhitelistedAddressAndMethod[];
  setWhitelistedAddressAndMethods: (whitelist: WhitelistedAddressAndMethod[]) => void;
  numChangesUnsaved: number;
  setNumChangesUnsaved: (changes: number) => void;
  gasPolicy: GasPolicy;
};

export function PaymasterAllowlistSection({
  whitelistedAddressAndMethods,
  setWhitelistedAddressAndMethods,
  numChangesUnsaved,
  setNumChangesUnsaved,
  gasPolicy,
}: PaymasterAllowlistSectionProps) {
  const { activeOrg } = useGetUser();
  const { selectedProject } = useAppState();

  const { setCompletedStep } = usePaymasterContext();

  const updateGasPolicy = useUpdateGasPolicy({
    organizationId: activeOrg?.organizationId || '',
    projectId: selectedProject?.id || '',
    onSuccess: () => {
      setCompletedStep('allowlist');
      setNumChangesUnsaved(0);
    },
    successMsg: 'Updated contract allowlist',
  });

  const [
    allowlistItemModalVisible,
    { toggleOn: showAllowlistItemModal, toggleOff: hideAllowlistItemModal },
  ] = useToggler();
  const [modalItem, setModalItem] = useState<WhitelistedAddressAndMethod | undefined>();

  const handleRemoveContractPress = useCallback(
    (key: string) => {
      const currentWhitelist = gasPolicy?.contractAllowlist?.map((item) =>
        getWhitelistItemKey(item.address, item?.methods, item?.name),
      );
      // if whitelist item currently saved in gas policy
      if (currentWhitelist?.includes(key)) {
        setNumChangesUnsaved(numChangesUnsaved + 1);
        // if whitelist item not saved in gas policy
      } else if (numChangesUnsaved > 0) {
        setNumChangesUnsaved(numChangesUnsaved - 1);
      }
      // remove item from whitelist
      const filteredContracts = whitelistedAddressAndMethods.filter(
        (item) => getWhitelistItemKey(item.address, item?.methods, item?.name) !== key,
      );
      setWhitelistedAddressAndMethods(filteredContracts);
      setModalItem(undefined);
      hideAllowlistItemModal();
    },
    [
      gasPolicy?.contractAllowlist,
      hideAllowlistItemModal,
      numChangesUnsaved,
      setNumChangesUnsaved,
      setWhitelistedAddressAndMethods,
      whitelistedAddressAndMethods,
    ],
  );

  const handleSavePress = useCallback(() => {
    if (!gasPolicy) {
      return;
    }

    const newGasPolicy = {
      ...gasPolicy,
      contractAllowlist: whitelistedAddressAndMethods,
    };

    logClick(EventName.paymaster_add_contract_allowlist_click);

    updateGasPolicy.mutate({ policy: newGasPolicy });
  }, [gasPolicy, whitelistedAddressAndMethods, updateGasPolicy]);

  const handleViewPress = useCallback(
    (whitelistItem: WhitelistedAddressAndMethod) => {
      setModalItem(whitelistItem);
      showAllowlistItemModal();
    },
    [showAllowlistItemModal],
  );

  const handleRequestClose = useCallback(() => {
    setModalItem(undefined);
    hideAllowlistItemModal();
  }, [hideAllowlistItemModal]);

  const whitelistedContracts = useMemo(() => {
    return whitelistedAddressAndMethods.map((whitelistItem, index) => {
      const functionTitle = whitelistItem.methods?.length
        ? `${whitelistItem.methods?.length} function${
            whitelistItem.methods?.length === 1 ? '' : 's'
          }`
        : 'All functions';
      return (
        <TableRow
          // eslint-disable-next-line react/no-array-index-key -- duplicate entries hasn't been handled yet
          key={`${getWhitelistItemKey(
            whitelistItem.address,
            whitelistItem?.methods,
            whitelistItem?.name,
          )}-${index}`}
        >
          <TableCell title={whitelistItem?.name || '--'} width={250} overflow="truncate" />
          <TableCell title={whitelistItem.address} width={380} />
          <TableCell title={functionTitle} />
          <TableCell alignItems="flex-end" width={150}>
            <Button variant="secondary" compact onPress={() => handleViewPress(whitelistItem)}>
              View
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  }, [handleViewPress, whitelistedAddressAndMethods]);

  return (
    <VStack>
      {allowlistItemModalVisible && modalItem && (
        <PaymasterViewAllowlistModal
          onRemovePress={() =>
            handleRemoveContractPress(
              getWhitelistItemKey(modalItem?.address, modalItem?.methods, modalItem?.name),
            )
          }
          onRequestClose={handleRequestClose}
          whitelistItem={modalItem}
        />
      )}
      {whitelistedAddressAndMethods?.length ? (
        <Box spacingTop={5}>
          <Table maxHeight={360}>
            <TableHeader>
              <TableRow>
                <TableCell title="Name" />
                <TableCell title="Contract Address" />
                <TableCell title="Functions" />
                <TableCell title="" />
              </TableRow>
            </TableHeader>
            <TableBody>{whitelistedContracts}</TableBody>
          </Table>
        </Box>
      ) : (
        <VStack alignItems="center" justifyContent="center" spacingTop={10}>
          <Pictogram name="settings" />
          <TextHeadline as="p">No contracts allowlisted</TextHeadline>
        </VStack>
      )}
      <HStack gap={2} justifyContent="flex-end" alignItems="center" spacingTop={8}>
        <TextBody as="p" color="foregroundMuted">{`${numChangesUnsaved} unsaved change${
          numChangesUnsaved === 1 ? '' : 's'
        }`}</TextBody>
        <Button variant="primary" onPress={handleSavePress} disabled={!numChangesUnsaved}>
          Save
        </Button>
      </HStack>
    </VStack>
  );
}
