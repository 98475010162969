import { MouseEvent, ReactNode, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useToggler } from '@cbhq/cds-common/hooks/useToggler';
import { AvatarButton } from '@cbhq/cds-web/buttons';
import { ListCell } from '@cbhq/cds-web/cells';
import { Switch } from '@cbhq/cds-web/controls';
import { Dropdown } from '@cbhq/cds-web/dropdown';
import { Divider, HStack, VStack } from '@cbhq/cds-web/layout';
import { Avatar } from '@cbhq/cds-web/media';
import { Pressable } from '@cbhq/cds-web/system';
import { Tag } from '@cbhq/cds-web/tag/Tag';
import { Link, TextBody, TextHeadline } from '@cbhq/cds-web/typography';

import config from ':cloud/config';
import { useAppState } from ':cloud/contexts/AppStateContext';
import { useIsDeveloperMode } from ':cloud/hooks/useIsDeveloperMode';
import { useGetOrganization } from ':cloud/queries/OrganizationQueries/useGetOrganization';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { openUrlInNewWindow } from ':cloud/utils/openUrlInNewWindow';
import { SessionContext } from ':cloud/utils/SessionProvider';
import { OrgSwitcher } from ':cloud/widgets/navigation/OrgSwitcher';

interface ProfileMenuItemProps {
  text: string;
  variant?: 'negative';
  to?: string;
  detail?: ReactNode;
  onPress?: () => void;
  openInNewWindow?: boolean;
  action?: ReactNode;
}

function ProfileMenuItem(props: ProfileMenuItemProps) {
  const history = useHistory();

  const isExternalLink =
    props.to && (props.to.startsWith('http://') || props.to.startsWith('https://'));

  const handlePress = useCallback(
    (event: MouseEvent) => {
      if (isExternalLink) {
        if (props.openInNewWindow) {
          event.preventDefault();
          openUrlInNewWindow(props.to || '');
        }
      } else {
        if (props.to) history.push(props.to);
        props?.onPress?.();
      }
    },
    [history, isExternalLink, props],
  );

  return (
    <ListCell
      title={
        props.variant === 'negative' ? (
          <TextHeadline as="div" color="negative">
            {props.text}
          </TextHeadline>
        ) : (
          props.text
        )
      }
      as="li"
      priority="middle"
      compact
      onPress={handlePress}
      to={isExternalLink ? props.to : undefined}
      action={props.action}
      detail={props.detail}
    />
  );
}

export function ProfileHeader() {
  const { activeOrg } = useGetUser();
  const { truncatedName: truncatedOrgName } = useGetOrganization(activeOrg?.organizationId);
  const { user } = useGetUser();

  return (
    <VStack alignItems="center" spacingTop={5} spacingBottom={3} spacingHorizontal={1} gap={2}>
      <AvatarButton
        alt={user.name || user.email}
        name={user.name}
        src={user?.avatarUrl || undefined}
        to={config.accountsURL}
      />
      <VStack alignItems="center" gap={1}>
        <TextHeadline align="center" as="p" overflow="wrap">
          {user.name} {user.familyName}
        </TextHeadline>
        <Link to={`mailto:${user.email}`}>
          <TextBody color="foregroundMuted" align="center" as="p" overflow="wrap">
            {user.email}
          </TextBody>
        </Link>

        {truncatedOrgName && <Tag colorScheme="gray">{truncatedOrgName}</Tag>}
      </VStack>
    </VStack>
  );
}

export function ProfileItems() {
  const { isDarkMode, toggleDarkMode } = useAppState();
  const { isDeveloperMode, shouldShowDevModeToggle, mutateDevMode } = useIsDeveloperMode();
  const [isDevToggleOn, { toggle: toggleDevMode }] = useToggler(isDeveloperMode);
  const { user } = useGetUser();
  const { logUserOut } = useContext(SessionContext);

  const handleToggleDevMode = useCallback(async () => {
    try {
      toggleDevMode();
      await mutateDevMode.mutateAsync(!isDevToggleOn);
    } catch (error) {
      toggleDevMode();
    }
  }, [isDevToggleOn, mutateDevMode, toggleDevMode]);

  return (
    <VStack as="ul" spacing={0}>
      <ProfileMenuItem text="Profile" to={config.accountsURL} openInNewWindow />
      <ListCell
        title="Dark mode"
        compact
        action={<Switch checked={isDarkMode} onChange={toggleDarkMode} />}
      />
      {user.isInternalUser && shouldShowDevModeToggle && (
        <ListCell
          title="Developer mode"
          compact
          action={<Switch checked={isDevToggleOn} onChange={handleToggleDevMode} />}
        />
      )}
      <ProfileMenuItem text="Sign out" variant="negative" onPress={logUserOut} />
    </VStack>
  );
}

function ProfileDropdownContent() {
  const [isOrgSwitcherOpen, { toggle: toggleOrgSwitcher }] = useToggler(false);

  if (isOrgSwitcherOpen) {
    return <OrgSwitcher handleOrgSwitcherClose={toggleOrgSwitcher} />;
  }

  return (
    <>
      <ProfileHeader />
      <Divider spacingBottom={0} />
      <ProfileItems />
    </>
  );
}

export function ProfileDropdown() {
  const { user } = useGetUser();
  const { isDeveloperMode } = useIsDeveloperMode();

  return (
    <Dropdown content={<ProfileDropdownContent />} width={304} maxHeight={538}>
      <Pressable background="transparent">
        <HStack gap={1} alignItems="center">
          <Avatar
            selected={isDeveloperMode}
            alt="Profile menu"
            size="xl"
            src={user?.avatarUrl || undefined}
          />
          {user.name && (
            <TextHeadline as="h2" testID="current-user-name">
              {user.name}
            </TextHeadline>
          )}
        </HStack>
      </Pressable>
    </Dropdown>
  );
}
