import { Fragment } from 'react';
import { FieldProps, utils } from '@rjsf/core';
import { Grid, VStack } from '@cbhq/cds-web/layout';

import { threeColumnGrid, twoColumnGrid } from ':cloud/utils/responsiveConfigs';
import {
  IOptionType,
  ISchemaType,
  IWidgetOption,
} from ':cloud/widgets/sharedcomponents/JSONSchemaForm/fields/types';

import { BoxSelectWidget } from '../widgets';

function renderBoxSelectWidget(
  option: IWidgetOption,
  widgetType: string,
  onChange: (val?: string | number) => void,
  formData: string | number,
  fieldType: string,
  hasError: boolean,
): React.ReactElement {
  return (
    <BoxSelectWidget
      title={option.title}
      description={option.description}
      image={option.image}
      selected={formData === option.value}
      hasError={hasError}
      widgetType={widgetType}
      fieldType={fieldType}
      onClick={(): void =>
        option.value === formData ? onChange(undefined) : onChange(option.value)
      }
    />
  );
}

/* only used in Participate */
export function BoxSelectField({
  schema: { options },
  onChange,
  formData,
  uiSchema,
  rawErrors = [],
}: Omit<FieldProps, 'schema'> & { schema: ISchemaType }) {
  // TODO: @artivlla add widgetType to getUiOptions type
  // @ts-expect-error TODO: remove MUI or fix types
  const { widgetType = 'default', field: fieldType } = utils.getUiOptions(uiSchema);
  const hasError = rawErrors.length > 0;
  return (
    <VStack spacingTop={5}>
      {widgetType !== 'wide' && (
        <Grid gap={3} responsiveConfig={threeColumnGrid}>
          {options.map((option: IOptionType) => (
            <Fragment key={option.value}>
              {renderBoxSelectWidget(option, widgetType, onChange, formData, fieldType, hasError)}
            </Fragment>
          ))}
        </Grid>
      )}
      {widgetType === 'wide' && (
        <Grid gap={3} responsiveConfig={twoColumnGrid}>
          {options.map((option: IOptionType) => (
            <Fragment key={option.value}>
              {renderBoxSelectWidget(option, widgetType, onChange, formData, fieldType, hasError)}
            </Fragment>
          ))}
        </Grid>
      )}
    </VStack>
  );
}
