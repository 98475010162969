import { VStack } from '@cbhq/cds-web/layout';

import { ChartWidgetType } from ':cloud/types/helper';
import { deriveValueFromMetrics } from ':cloud/widgets/charts/ChartUtils';
import { ConnectedPeers } from ':cloud/widgets/charts/ConnectedPeers';
import { TextLabelGray } from ':cloud/widgets/sharedcomponents';

export function PeerWidget({
  sources,
  options,
  metricsData,
  configuration,
  isDynamic,
  pod,
}: ChartWidgetType) {
  const peersVal = deriveValueFromMetrics(sources[0], configuration, isDynamic, metricsData, pod);
  return (
    <VStack justifyContent="space-between" width={88} height={135}>
      <TextLabelGray align="center">{options.label}</TextLabelGray>
      <ConnectedPeers active={peersVal ? Number(peersVal) : 0} />
    </VStack>
  );
}
