import { TableCell, TableHeader, TableRow } from '@cbhq/cds-web/tables';

export default function OAuthTableHeader() {
  return (
    <TableHeader>
      <TableRow>
        <TableCell title="Name" />
        <TableCell title="Redirect URIs" />
        <TableCell title="Website" />
        <TableCell title="Client ID" />
        <TableCell title="Actions" />
      </TableRow>
    </TableHeader>
  );
}
