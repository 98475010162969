import { useCallback, useEffect, useMemo, useState } from 'react';
import { useToggler } from '@cbhq/cds-common';
import { Button, ButtonGroup } from '@cbhq/cds-web/buttons';
import { Icon } from '@cbhq/cds-web/icons';
import { Grid, HStack, VStack } from '@cbhq/cds-web/layout';
import { Tooltip } from '@cbhq/cds-web/overlays';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { Tag } from '@cbhq/cds-web/tag/Tag';
import {
  Link,
  TextBody,
  TextDisplay3,
  TextHeadline,
  TextTitle3,
  TextTitle4,
} from '@cbhq/cds-web/typography';

import { useAppState } from ':cloud/contexts/AppStateContext';
import { useGetProductVariants } from ':cloud/hooks/useGetProductVariants';
import useHasPaymentMethod from ':cloud/hooks/useHasPaymentMethod';
import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { logError } from ':cloud/init/bugsnag/logging';
import { useCancelAccountItem } from ':cloud/queries/BillingQueries/useCancelAccountItem';
import { useChangeAccountItemVariant } from ':cloud/queries/BillingQueries/useChangeAccountItemVariant';
import { useCreateAccountItem } from ':cloud/queries/BillingQueries/useCreateAccountItem';
import { useGetAccountItems } from ':cloud/queries/BillingQueries/useGetAccountItems';
import { useGetPaymentMethod } from ':cloud/queries/BillingQueries/useGetPaymentMethod';
import { useGetOrganization } from ':cloud/queries/OrganizationQueries/useGetOrganization';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { ProductVariant } from ':cloud/types/ts_types';
import { BUSINESS_SIGNUP_LINK, EXCHANGE_DOCS_LINK } from ':cloud/utils/links';
import { onExternalLinkPress } from ':cloud/utils/onExternalLinkPress';
import { Layout } from ':cloud/widgets/layout';
import {
  FULL_PAGE_MAX_WIDTH,
  MAIN_CONTAINER_MIN_WIDTH,
  RIGHT_RAIL_MIN_WIDTH,
} from ':cloud/widgets/product/constants';
import { KeyFeatureItem, KeyFeaturesSection } from ':cloud/widgets/product/KeyFeaturesSection';
import { LoadingSpinner } from ':cloud/widgets/sharedcomponents';
import { TwoFactorModal } from ':cloud/widgets/sharedcomponents/TwoFactorModal';

import { ExchangeLandingBanners } from './ExchangeLandingBanners';
import { ExchangePlanModal, formatPlanTitle } from './ExchangePlanModal';
import { ExchangeQuickstartSection } from './ExchangeQuickstartSection';

const keyFeatures: KeyFeatureItem[] = [
  {
    iconName: 'usdtToUSDC',
    iconType: 'heroSquare',
    title: 'Deep liquidity',
    description:
      'Institutional access to one of the deepest pools of liquidity of any regulated crypto spot exchange through high throughput APIs and an advanced trading interface.',
  },
  {
    iconName: 'futures',
    iconType: 'pictogram',
    title: 'Institutional-grade trading APIs',
    description:
      'Get full control over your order and account management through our high performance, scalable FIX and REST APIs.',
  },
  {
    iconName: 'recommendInvest',
    iconType: 'heroSquare',
    title: 'Pricing and Incentives',
    description: (
      <>
        Competitive, volume-based price tiers for maker and taker orders. Extra discounts are
        provided through our{' '}
        <Link
          to="https://coinbase.bynder.com/m/2ffb9e1d7bd41fc9/original/Coinbase-Exchange-Liquidity-Program-Overview.pdf"
          underline={false}
          openInNewWindow
        >
          Liquidity Program
        </Link>
        .
      </>
    ),
  },
  {
    iconName: 'timingCheck',
    iconType: 'pictogram',
    title: 'Real-time market data',
    description: 'Utilize our FIX and WebSocket feeds for the most up-to-date market data. ',
  },
];

const errorMsg = 'Failed to save changes. Please retry';

function SubscriptionBoxTooltip() {
  const isBeforeJune24 = new Date() < new Date('2024-06-24');
  const tooltipContent = (
    <VStack gap={2}>
      <TextBody as="p">
        Exchange users have a default limit of 10 subscriptions per product channel. Subscribe to a
        higher tier for a higher subscription limit.
      </TextBody>
      {/* TODO(go/j/CDPPS-250): Remove this content after June 24, 2024. */}
      {isBeforeJune24 && (
        <TextBody as="p">
          Note: Tier selection will affect a user&apos;s limits in Exchange sandbox. On June 24th,
          this will affect production and start to accrue billing.
        </TextBody>
      )}
    </VStack>
  );
  return (
    <Tooltip content={tooltipContent}>
      <Icon name="info" size="m" color="foreground" />
    </Tooltip>
  );
}

/**
 * The change plan modal has two steps:
 * 1. `options`, where the user selects a plan
 * 2. `summary`, where the user confirms the change and payment method info
 * */
type ExchangeModalStep = 'options' | 'summary';

export function ExchangeLanding() {
  const { isDesktop } = useSimpleBreakpoints();
  const toast = useToast();
  const [
    isChangePlanModalVisible,
    { toggleOn: toggleChangePlanModalOn, toggleOff: changePlanModalOff },
  ] = useToggler();
  const [isTwoFactorModalVisible, { toggleOn: twoFactorModalOn, toggleOff: twoFactorModalOff }] =
    useToggler();
  const [changePlanModalStep, setChangePlanModalStep] = useState<ExchangeModalStep>('options');

  const { selectedProject } = useAppState();
  const {
    activeOrg,
    user: { exchangeEnabled },
  } = useGetUser();
  const orgId = activeOrg?.organizationId;
  const { orgHasMultipleUsers } = useGetOrganization(orgId);

  const createAccountItemMutation = useCreateAccountItem(orgId);
  const changeAccountItemVariantMutation = useChangeAccountItemVariant(orgId);
  const cancelAccountItemMutation = useCancelAccountItem(orgId);

  const { paymentMethod } = useGetPaymentMethod(orgId);
  const hasPaymentMethod = useHasPaymentMethod();

  const { isLoading: isProductVariantsLoading, productVariants: exchangePlans } =
    useGetProductVariants('exchange');
  const { accountItems, isLoading: isAccountItemsLoading } = useGetAccountItems(orgId);
  const exchangeProductId = exchangePlans[0]?.productId;

  /**
   * Exchange product account items are only created for one project, ever.
   * This project's Exchange subscription serves as the account item used
   * across the entire org, regardless of which project is currently active.
   */
  const exchangeAccountItems = useMemo(
    () => accountItems.filter((item) => item.productVariant?.productId === exchangeProductId),
    [accountItems, exchangeProductId],
  );

  /**
   * Only one Exchange acct item should ever be active. All others should
   * have `cancelTime` set to a non-null value.
   */
  const activeAccountItem = useMemo(
    () => exchangeAccountItems.find((item) => item.cancelTime == null),
    [exchangeAccountItems],
  );

  const freePlan = useMemo(
    () => exchangePlans.find((variant) => variant.price === '0'),
    [exchangePlans],
  );

  /**
   * Exchange users can either have:
   * 1. No active Exchange account item at all (free)
   * 2. An active Exchange account item with a price > 0 (paid)
   */
  const currentPlan = useMemo(() => {
    const foundPlan = exchangePlans.find(
      (variant) => activeAccountItem?.productVariantId === variant.id,
    );

    // Default to freePlan if no plan is found
    return foundPlan ?? freePlan;
  }, [exchangePlans, activeAccountItem, freePlan]);

  const [selectedPlan, setSelectedPlan] = useState<ProductVariant>();
  useEffect(() => {
    if (exchangeEnabled && currentPlan) {
      setSelectedPlan(currentPlan);
    }
  }, [currentPlan, exchangeEnabled, freePlan]);

  const headerResponsiveTemplateColumns = useMemo(() => {
    return isDesktop ? '3fr 1fr' : '1fr';
  }, [isDesktop]);

  const handleChange = useCallback((plan: ProductVariant) => {
    setSelectedPlan(plan);
  }, []);

  const handleClose = useCallback(() => {
    changePlanModalOff();
    setChangePlanModalStep('options');
    setSelectedPlan(currentPlan || freePlan);
  }, [changePlanModalOff, currentPlan, freePlan]);

  const submitPlanChanges = useCallback(() => {
    if (!selectedPlan || !selectedProject) {
      logError(new Error('missing required data'), { context: 'change_exchange_plan' });
      return;
    }
    if (currentPlan?.price === selectedPlan.price) {
      toast.show('Plan successfully changed', { variant: 'positive' });
      handleClose();
      return;
    }
    if (currentPlan?.price === '0' || !activeAccountItem) {
      // Create a new account item if user is going from a free plan to a paid one
      createAccountItemMutation.mutate(
        { productVariantId: selectedPlan.id, projectId: selectedProject?.id },
        {
          onSuccess: () => {
            handleClose();
            toast.show('Plan successfully upgraded', { variant: 'positive' });
          },
          onError: () => {
            toast.show(errorMsg, { variant: 'negative' });
          },
        },
      );
    } else if (selectedPlan.price === '0') {
      // Cancel the account item if user is going from a paid plan to the free plan
      cancelAccountItemMutation.mutate(activeAccountItem.id, {
        onSuccess: () => {
          handleClose();
          toast.show('Plan successfully downgraded', { variant: 'positive' });
        },
        onError: () => {
          toast.show(errorMsg, { variant: 'negative' });
        },
      });
    } else {
      // Change the account item product variant if user is going from one paid plan to another
      changeAccountItemVariantMutation.mutate(
        {
          productVariantId: selectedPlan.id,
          itemId: activeAccountItem.id,
        },
        {
          onSuccess: () => {
            handleClose();
            toast.show('Plan successfully changed', { variant: 'positive' });
          },
          onError: () => {
            toast.show(errorMsg, { variant: 'negative' });
          },
        },
      );
    }
  }, [
    selectedProject,
    currentPlan,
    selectedPlan,
    createAccountItemMutation,
    cancelAccountItemMutation,
    changeAccountItemVariantMutation,
    handleClose,
    toast,
    activeAccountItem,
  ]);

  const handleTwoFactorSuccess = useCallback(() => {
    twoFactorModalOff();
    submitPlanChanges();
  }, [twoFactorModalOff, submitPlanChanges]);

  const handleTwoFactorError = useCallback(() => {
    // do not submit plan change; show error toast; close modal
  }, []);

  if (isAccountItemsLoading || isProductVariantsLoading) {
    return <LoadingSpinner />;
  }
  return (
    <Layout enableMaxWidth={false}>
      <Layout.MainContainer minWidth={MAIN_CONTAINER_MIN_WIDTH} spacingHorizontal={0} fullWidth>
        <VStack>
          <ExchangeLandingBanners
            {...{
              exchangeEnabled,
              hasPaymentMethod,
              orgHasMultipleUsers,
            }}
          />
          <HStack borderedBottom>
            <Grid
              maxWidth={FULL_PAGE_MAX_WIDTH}
              spacingHorizontal={9}
              spacingVertical={9}
              gap={4}
              templateColumns={headerResponsiveTemplateColumns}
            >
              <VStack justifyContent="center">
                <TextDisplay3 as="h1">
                  An API for direct access to our exchange for trading and market data
                </TextDisplay3>
                <TextTitle4 as="h2" color="foregroundMuted" spacingTop={1} spacingBottom={7}>
                  For Institutional users who want direct access to one of the deepest pools of
                  liquidity of any regulated regulated crypto spot exchange. Get started with our
                  high throughput REST, FIX, and WebSocket APIs
                </TextTitle4>

                <ButtonGroup>
                  <Button
                    variant="primary"
                    endIcon="diagonalUpArrow"
                    onPress={onExternalLinkPress(EXCHANGE_DOCS_LINK)}
                    accessibilityLabel="opens in new window"
                  >
                    View docs
                  </Button>
                  {!exchangeEnabled && (
                    <Button
                      variant="secondary"
                      endIcon="diagonalUpArrow"
                      onPress={onExternalLinkPress(BUSINESS_SIGNUP_LINK)}
                      accessibilityLabel="opens in new window"
                    >
                      Sign up through business onboarding
                    </Button>
                  )}
                </ButtonGroup>
              </VStack>
              {exchangeEnabled && currentPlan && (
                <VStack
                  height="100%"
                  minWidth={RIGHT_RAIL_MIN_WIDTH}
                  spacing={5}
                  gap={3}
                  borderColor="line"
                  borderRadius="roundedLarge"
                >
                  <HStack gap={1} alignItems="center">
                    <TextTitle3 as="h2">Market Data subscription</TextTitle3>
                    <SubscriptionBoxTooltip />
                  </HStack>
                  <VStack justifyContent="space-between" height="100%" gap={4}>
                    <VStack>
                      <HStack gap={1} alignContent="center">
                        <TextHeadline as="p">{formatPlanTitle(currentPlan)}</TextHeadline>
                        <Tag colorScheme="green">Current</Tag>
                      </HStack>
                      <TextBody as="p" color="foregroundMuted">
                        Up to {currentPlan?.meters[0].unit} subscriptions per product channel
                      </TextBody>
                    </VStack>

                    <Button
                      variant="secondary"
                      block
                      onPress={toggleChangePlanModalOn}
                      disabled={
                        orgHasMultipleUsers || (!hasPaymentMethod && currentPlan?.price === '0')
                      }
                    >
                      Change plan
                    </Button>
                  </VStack>
                </VStack>
              )}
            </Grid>
          </HStack>
          {exchangeEnabled && (
            <VStack
              maxWidth={FULL_PAGE_MAX_WIDTH}
              spacingVertical={7}
              gap={1}
              flexGrow={1}
              width="100%"
              borderedBottom
            >
              <ExchangeQuickstartSection />
            </VStack>
          )}

          <VStack maxWidth={FULL_PAGE_MAX_WIDTH} width="100%">
            <KeyFeaturesSection features={keyFeatures} />
          </VStack>
        </VStack>
      </Layout.MainContainer>
      {selectedPlan && currentPlan && (
        <ExchangePlanModal
          visible={isChangePlanModalVisible}
          currentPlan={currentPlan}
          selectedPlan={selectedPlan}
          handleSelectPlan={handleChange}
          handleSubmitPlan={twoFactorModalOn}
          onRequestClose={handleClose}
          paymentMethod={paymentMethod}
          modalStep={changePlanModalStep}
          setModalStep={setChangePlanModalStep}
        />
      )}
      <TwoFactorModal
        action="web-CloudBridge-ChangeExchangePlan"
        visible={isTwoFactorModalVisible}
        onRequestClose={twoFactorModalOff}
        onSuccess={handleTwoFactorSuccess}
        onFailure={handleTwoFactorError}
      />
    </Layout>
  );
}
