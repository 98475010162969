import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientCOMSLegacy } from ':cloud/state/Api';
import { GetProjectsResponse } from ':cloud/types/service_proto';
import { Project } from ':cloud/types/ts_types';

// queries COMS endpoint, returns old response shape
async function fetchProjects(organizationId?: string): Promise<GetProjectsResponse> {
  const path = `/organization-management/v1/organizations/${organizationId}/projects`;
  const response = await APIClientCOMSLegacy.get<GetProjectsResponse>(path);
  return response?.data || [];
}

type GenerateProjectQueryProps = {
  organizationId?: string;
};

export function generateProjectsQuery({ organizationId }: GenerateProjectQueryProps) {
  return {
    queryKey: [QueryKey.projects, organizationId],
    queryFn: async () => fetchProjects(organizationId),
    staleTime: Infinity,
    enabled: !!organizationId,
  };
}

// TODO: Migrate to new COMS response data shape
export function useGetProjects(organizationId?: string): {
  isLoading: boolean;
  projects: Project[];
} {
  const { isLoading, data } = useQuery(generateProjectsQuery({ organizationId }));

  const filteredProjects = useMemo(() => {
    return (
      data
        ?.filter((project) => project.active)
        ?.sort(
          (a, b) =>
            new Date(String(b.createdAt)).getTime() - new Date(String(a.createdAt)).getTime(),
        ) || []
    );
  }, [data]);

  return {
    isLoading,
    projects: filteredProjects,
  };
}
