import { useEffect, useState } from 'react';
/**
 * Barebones debounce hook. Accepts any value and returns a debounced version of that value.
 * The debounced value will only be updated after `wait` milliseconds have elapsed since the last time
 * `value` was changed. This hook is useful for preventing API endpoints from being spammed, or for preventing
 * expensive computations from ocurring more often than they need to.
 * @see useThrottle for the throttle version of this hook
 * @param value - any value to be debounced
 * @param wait - the number of milliseconds to wait before updating the debounced value
 */

export function useDebounce<T>(value: T, wait: number) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timeout = setTimeout(() => setDebouncedValue(value), wait);
    return () => clearTimeout(timeout);
  }, [value, wait]);

  return debouncedValue;
}
