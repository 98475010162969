import { useMemo } from 'react';
import { FieldProps, UiSchema, utils } from '@rjsf/core';
import { Box as CDSBox } from '@cbhq/cds-web/layout';

/* only used in Participate */
export function InputField({
  schema,
  registry = utils.getDefaultRegistry(),
  uiSchema,
  idSchema,
  formData,
  required,
  disabled,
  readonly,
  autofocus,
  onChange,
  onFocus,
  onBlur,
  rawErrors,
}: FieldProps) {
  const { widgets, formContext } = registry;
  const enumOptions = utils.isSelect(schema) && utils.optionsList(schema);
  let defaultWidget = enumOptions ? 'select' : 'text';

  if (schema.format && utils.hasWidget(schema, schema.format, widgets)) {
    defaultWidget = schema.format;
  }
  const {
    title,
    description,
    widget = defaultWidget,
    placeholder = '',
    isPercentage = false,
    label,
    ...options
  } = utils.getUiOptions(uiSchema) as UiSchema;
  const Widget = utils.getWidget(schema, widget, widgets);
  const widgetOptions = useMemo(() => ({ ...options, enumOptions }), [enumOptions, options]);

  return (
    <CDSBox spacingTop={5} width="100%">
      {/* @ts-expect-error TODO: fix after 12/22 migration */}
      <Widget
        options={widgetOptions}
        schema={schema}
        id={idSchema?.$id}
        label={label === undefined ? title : label}
        value={formData}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        required={required}
        disabled={disabled}
        readonly={readonly}
        formContext={formContext}
        autofocus={autofocus}
        registry={registry}
        rawErrors={rawErrors}
        placeholder={placeholder}
        isPercentage={isPercentage}
        spellCheck="false"
      />
    </CDSBox>
  );
}
