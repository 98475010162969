import { Box } from '@cbhq/cds-web/layout';
import { palette } from '@cbhq/cds-web/tokens';
import { TextBody } from '@cbhq/cds-web/typography';

import { ReactComponent as FiftyPlusPeers } from ':cloud/assets/Objects/fifty-plus-peers.svg';

type DotProps = { fill?: string };

function Dot({ fill = palette.line }: DotProps) {
  return (
    <Box spacing={0.5}>
      <svg display="block" width="5" height="5" viewBox="0 0 5 5" fill="none">
        <ellipse cx="2.15385" cy="2.15789" rx="2.15385" ry="2.15789" fill={fill} />
      </svg>
    </Box>
  );
}

type ConnectedPeersProps = {
  active?: number;
  total?: number;
  width?: number;
};

export function ConnectedPeers({ active = 0, total = 50, width }: ConnectedPeersProps) {
  const coloredNodes = active > total ? total : active;
  const nodeStatus = new Array(coloredNodes)
    .fill(1)
    .concat(new Array(total - coloredNodes).fill(0));

  if (active >= 50) {
    return (
      <>
        <Box flexWrap="wrap" width={width || 91} height={64}>
          <FiftyPlusPeers />
        </Box>
        <TextBody as="span" align="center" mono>
          {active}
        </TextBody>
      </>
    );
  }

  return (
    <>
      <Box display="flex" flexWrap="wrap" width={width || 91} height={64}>
        {nodeStatus.map((node, i) => (
          <Dot
            key={node ? `connected-${i}` : `disconnected-${i}`}
            fill={node ? palette.positive : palette.line}
          />
        ))}
      </Box>
      <TextBody as="span" align="center" mono>
        {active}
      </TextBody>
    </>
  );
}
