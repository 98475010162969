import { useCallback } from 'react';

import { useDeleteResource } from ':cloud/queries/ResourceQueries/useDeleteResource';
import { ICluster } from ':cloud/types/ts_types';
import { participateAdapter } from ':cloud/utils/clusters';
import { DestroyCluster } from ':cloud/widgets/clusters/DestroyCluster';
import { Layout } from ':cloud/widgets/layout';

type ManageTabProps = { cluster: ICluster };

function ManageTab({ cluster }: ManageTabProps) {
  const redirectRoute = participateAdapter.clusterRoute;
  const deleteResourceMutation = useDeleteResource();

  const handleDeleteResource = useCallback(() => {
    deleteResourceMutation.mutate({ name: cluster.name, type: cluster.type });
  }, [cluster.name, cluster.type, deleteResourceMutation]);

  return (
    <Layout.ContentWrapper>
      <DestroyCluster
        redirectOnDestroy={redirectRoute}
        cluster={cluster}
        onHandleDestroy={handleDeleteResource}
      />
    </Layout.ContentWrapper>
  );
}

export default ManageTab;
