import { StringSchema, ValidationError } from 'yup';

/**
 * Validates a string based on a given Yup string schema. Returns the first error or an empty string.
 *
 * https://github.com/jquense/yup#schema-basics
 */
export function generateStringValidationError(str: string, schema: StringSchema) {
  try {
    schema.validateSync(str);
  } catch (e) {
    if (e instanceof ValidationError) {
      return e.errors[0];
    }
  }
  return '';
}
