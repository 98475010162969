import { ReactNode } from 'react';
import { Icon } from '@cbhq/cds-web/icons/Icon';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextBody } from '@cbhq/cds-web/typography';

type AlertType = 'success' | 'info' | 'warning' | 'alert';

interface InlineCalloutProps {
  type: AlertType;
  alertAction?: JSX.Element;
  children: JSX.Element | ReactNode;
}

type CalloutIconProps = { type: AlertType };

function CalloutIcon({ type }: CalloutIconProps) {
  switch (type) {
    case 'success':
      return <Icon color="positive" name="circleCheckmark" size="m" />;
    case 'info':
      return <Icon color="foregroundMuted" name="info" size="m" />;
    case 'alert':
      return <Icon color="negative" name="error" size="m" />;
    case 'warning':
    default:
      return <Icon dangerouslySetColor="#7D4800" name="warning" size="m" />;
  }
}

function backgroundColor(type: AlertType): string {
  switch (type) {
    case 'success':
      return '#EEF0F3';
    case 'info':
      return '#EEF0F3';
    case 'alert':
      return '#FFF5F6';
    case 'warning':
      return '#FEF8D9';
    default:
      throw new Error(`Unexpected AlertType: ${type}`);
  }
}

export function InlineCallout({ type, children, alertAction }: InlineCalloutProps) {
  return (
    <VStack width="100%">
      <VStack borderRadius="rounded" spacing={3} dangerouslySetBackground={backgroundColor(type)}>
        <HStack alignItems="center">
          <HStack spacingEnd={3}>
            <CalloutIcon type={type} />
          </HStack>
          <Box flexGrow={1}>
            <TextBody color={type === 'alert' ? 'negative' : 'currentColor'} as="div">
              {children}
            </TextBody>
          </Box>
          {alertAction}
        </HStack>
      </VStack>
    </VStack>
  );
}
