import { useState } from 'react';
import { VStack } from '@cbhq/cds-web/layout';
import { TabNavigation } from '@cbhq/cds-web/tabs';
import { TextBody, TextTitle3 } from '@cbhq/cds-web/typography';

import { QuickstartItem } from ':cloud/widgets/product/QuickstartItem';
import { CodeBlock } from ':cloud/widgets/sharedcomponents';

import {
  ExchangeQuickstartStepProps,
  fixMarketDataSteps,
  fixOrderEntrySteps,
  restSteps,
  websocketMarketDataSteps,
} from './exchangeQuickstartContent';

const tabs = [
  { id: 'rest', label: 'REST' },
  { id: 'fixOrderEntry', label: 'FIX Order Entry' },
  { id: 'fixMarketData', label: 'FIX Market Data' },
  { id: 'websocketMarketData', label: 'WebSocket Market Data' },
];

const tabContent = {
  rest: {
    description: 'Perfect for lower-frequency trading and general requests.',
    steps: restSteps,
  },
  fixOrderEntry: {
    description: 'Perfect for higher-frequency trading.',
    steps: fixOrderEntrySteps,
  },
  fixMarketData: {
    description: 'Perfect for low-latency market data.',
    steps: fixMarketDataSteps,
  },
  websocketMarketData: {
    description: 'Perfect for comprehensive market data feeds.',
    steps: websocketMarketDataSteps,
  },
};

export function ExchangeQuickstartSection() {
  const [tabId, setTabId] = useState('rest');
  const { description, steps }: { description: string; steps: ExchangeQuickstartStepProps[] } =
    tabContent[tabId];
  return (
    <VStack spacingHorizontal={9} gap={3}>
      <TextTitle3 as="h3">Quickstart</TextTitle3>
      <TabNavigation value={tabId} tabs={tabs} onChange={setTabId} />
      <VStack
        gap={6}
        spacingVertical={2}
        role="tabpanel"
        id={`tabpanel--${tabId}`}
        accessibilityLabelledBy={`tab--${tabId}`}
      >
        <TextBody as="p">{description}</TextBody>
        {steps.map((step) => (
          <QuickstartItem
            key={step.stepNumber}
            stepNumber={step.stepNumber}
            title={step.title}
            codeBlock={
              step.codeBlockContent.length ? (
                <CodeBlock
                  codeArr={step.codeBlockContent}
                  showLanguageSelect={false}
                  showCopy
                  enableLineWrap
                  title="shell"
                />
              ) : undefined
            }
          />
        ))}
      </VStack>
    </VStack>
  );
}
