import { useCallback } from 'react';
import { noop } from '@cbhq/cds-utils';
import { Button } from '@cbhq/cds-web/buttons';
import { HeroSquare } from '@cbhq/cds-web/illustrations/HeroSquare';
import { VStack } from '@cbhq/cds-web/layout';
import { Modal, ModalBody, ModalHeader, ModalProps } from '@cbhq/cds-web/overlays';
import { TextBody } from '@cbhq/cds-web/typography';

type APIKeyTwoFactorModalProps = Partial<ModalProps> & {
  onSubmit: () => void;
  title: string;
};

export function APIKeyCompleteTwoFactorModal(props: APIKeyTwoFactorModalProps): JSX.Element {
  const { onRequestClose, onSubmit, title } = props;

  const handleSubmit = useCallback(() => {
    onSubmit();
    onRequestClose?.();
  }, [onRequestClose, onSubmit]);

  return (
    <Modal onRequestClose={onRequestClose || noop} visible {...props} width={448}>
      <ModalHeader title={title} />
      <ModalBody>
        <VStack alignItems="center">
          <HeroSquare name="phoneNumber" />
          <TextBody as="p" color="foregroundMuted" spacingTop={5} spacingBottom={7}>
            To ensure security, please note that you are required to complete 2-factor
            authentication in order to edit your API key.
          </TextBody>
          <Button type="submit" onPress={handleSubmit} testID="submit">
            Complete 2-factor authentication
          </Button>
        </VStack>
      </ModalBody>
    </Modal>
  );
}
