/* eslint-disable react-perf/jsx-no-new-array-as-prop */

import '@cbhq/cds-web/globalStyles';

import { ReactElement, StrictMode, useCallback, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { Redirect, Route, Router, Switch as RouterSwitch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { zIndex } from '@cbhq/cds-common/tokens/zIndex';
import { Box, Divider, HStack, Spacer, VStack } from '@cbhq/cds-web/layout';
import { ClientKillSwitchProvider, defaultClientKillSwitchConfig } from '@cbhq/client-kill-switch';
import { ClientContextProvider as CBErrorVitalsClientContextProvider } from '@cbhq/error-vitals-web';
import { getIsLocalDev } from '@cbhq/session-manager-auth';
import { TwoFactorGlobalConfigProvider } from '@cbhq/two-factor-web';
import { TwoFactorBoundaryError, TwoFactorTargetEnv } from '@cbhq/two-factor-web/types';

import config from ':cloud/config';
import { AppStateProvider, useAppState } from ':cloud/contexts/AppStateContext';
import { ExperimentsProvider } from ':cloud/contexts/ExperimentsProvider';
import FeatureFlagProvider from ':cloud/contexts/FeatureFlagProvider';
import NavigationProvider from ':cloud/contexts/NavigationProvider';
import NewClusterProvider from ':cloud/contexts/NewClusterProvider';
import { useBOAT } from ':cloud/hooks/featureFlags/useBOAT';
import { useFaucet } from ':cloud/hooks/featureFlags/useFaucet';
import { useIsBasePlaygroundEnabled } from ':cloud/hooks/featureFlags/useIsBasePlaygroundEnabled';
import { useIsBillingUsageEnabled } from ':cloud/hooks/featureFlags/useIsBillingUsageEnabled';
import { useIsEarningsEnabled } from ':cloud/hooks/featureFlags/useIsEarningsEnabled';
import { useIsExchangeOnboardingEnabled } from ':cloud/hooks/featureFlags/useIsExchangeOnboardingEnabled';
import { useIsOnCBPayConsumerAllowlist } from ':cloud/hooks/featureFlags/useIsOnCBPayConsumerAllowlist';
import { useIsOnParticipateAllowlist } from ':cloud/hooks/featureFlags/useIsOnParticipateAllowlist';
import { useIsOnStakingAllowlist } from ':cloud/hooks/featureFlags/useIsOnStakingAllowlist';
import { useIsVerificationsOnboardingEnabled } from ':cloud/hooks/featureFlags/useIsVerificationsOnboardingEnabled';
import { useIsWalletHistoryEnabled } from ':cloud/hooks/featureFlags/useIsWalletHistoryEnabled';
import { useWebhooks } from ':cloud/hooks/featureFlags/useWebhooks';
import useLocalStorage from ':cloud/hooks/useLocalStorage';
import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { errorVitalsClientContext } from ':cloud/init/bugsnag/init';
import { logError } from ':cloud/init/bugsnag/logging';
import { initKillSwitch } from ':cloud/init/killSwitch/init';
import { initSprig } from ':cloud/init/sprig/init';
import { Access } from ':cloud/pages/Access';
import { AdvancedTradeLanding } from ':cloud/pages/AdvancedTradeLanding';
import { BaseLanding } from ':cloud/pages/BaseLanding';
import { BaseNodeTermsOfService } from ':cloud/pages/BaseNodeTermsOfService';
import { BillingPage } from ':cloud/pages/Billing';
import { BillingWithTabs } from ':cloud/pages/BillingWithTabs';
import { BundlerAndPaymasterLanding } from ':cloud/pages/BundlerAndPaymaster';
import { ClusterDetailsRoute } from ':cloud/pages/ClusterDetailsRoute';
import { CoinbaseAppLanding } from ':cloud/pages/CoinbaseAppLanding';
import { EarningsPage } from ':cloud/pages/EarningsPage';
import { ErrorPage } from ':cloud/pages/ErrorPage';
import { ExchangeLanding } from ':cloud/pages/Exchange/ExchangeLanding';
import { Faucet } from ':cloud/pages/Faucet';
import { Indexers } from ':cloud/pages/Indexers';
import { OnrampLanding } from ':cloud/pages/Onramp/OnrampLanding';
import { ParticipateDashboard } from ':cloud/pages/ParticipateDashboard';
import { PlatformWallets } from ':cloud/pages/PlatformWallets';
import { Projects } from ':cloud/pages/Projects';
import { ProjectSettings } from ':cloud/pages/ProjectSettings';
import { StakingAPILanding } from ':cloud/pages/StakingAPILanding';
import { TeamPage } from ':cloud/pages/TeamPage';
import { TemplateLanding } from ':cloud/pages/Template';
import { VerificationsLanding } from ':cloud/pages/Verifications/VerificationsLanding';
import { WaasConsumerLanding } from ':cloud/pages/WaasProjects/WaasConsumerLanding';
import { WalletsLanding } from ':cloud/pages/WalletsLanding';
import { WebhooksPage } from ':cloud/pages/Webhooks';
import { useGetAccount } from ':cloud/queries/BillingQueries/useGetAccount';
import { useIsKillSwitchLoaded } from ':cloud/queries/KillSwitchQueries/useIsKillSwitchLoaded';
import { useGetAppStartData } from ':cloud/queries/useGetAppStartData';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { useGetUserPermissions } from ':cloud/queries/UserQueries/useGetUserPermissions';
import { CloudThemeProvider } from ':cloud/utils/CloudThemeProvider';
import { CookieProvider } from ':cloud/utils/CookieProvider';
import { history } from ':cloud/utils/history';
import { AppRoute, useQueryParam } from ':cloud/utils/routes';
import { SessionProvider } from ':cloud/utils/SessionProvider';
import { CreateCluster } from ':cloud/widgets/clusters/CreateCluster';
import { AppHelmet } from ':cloud/widgets/global/AppHelmet';
import { ADVANCED_API_REFERRER, REFERRER_KEY } from ':cloud/widgets/global/constants';
import { EnvBanner } from ':cloud/widgets/global/EnvBanner';
import { QueryClientProviderWrapper } from ':cloud/widgets/global/QueryClientProviderWrapper';
import { SSORedirect } from ':cloud/widgets/global/SSORedirect';
import { LeftNav } from ':cloud/widgets/navigation/LeftNav';
import { MobileTabNav } from ':cloud/widgets/navigation/MobileTabNav';
import { TopNav } from ':cloud/widgets/navigation/TopNav';
import { DiscordBanner } from ':cloud/widgets/product/DiscordBanner';
import { HiringBanner } from ':cloud/widgets/product/HiringBanner';
import { CloudErrorBoundary } from ':cloud/widgets/sharedcomponents/CloudErrorBoundary';
import { FullAppLoadingSpinner } from ':cloud/widgets/sharedcomponents/FullAppLoadingSpinner';

import { UnauthenticatedApp } from './UnauthenticatedApp';

function getTwoFactorTargetEnv(): TwoFactorTargetEnv {
  if (getIsLocalDev()) {
    return TwoFactorTargetEnv.Mock;
  }
  switch (process.env.AUTH_ENVIRONMENT) {
    case 'development':
      return TwoFactorTargetEnv.Development;
    case 'staging':
      return TwoFactorTargetEnv.Staging;
    case 'production':
    default:
      return TwoFactorTargetEnv.Production;
  }
}

function AppRouter(): ReactElement {
  const { isDarkMode, toggleDarkMode } = useAppState();
  const referrerQueryParam = useQueryParam(REFERRER_KEY);
  useEffect(() => {
    if (referrerQueryParam === ADVANCED_API_REFERRER && !isDarkMode) {
      toggleDarkMode();
    }
  }, [isDarkMode, referrerQueryParam, toggleDarkMode]);

  const { user, activeOrg, isLoading: isUserLoading } = useGetUser();
  const { isLoading: isKillSwitchLoading } = useIsKillSwitchLoaded();
  const { permissions, isLoading: isPermissionsLoading } = useGetUserPermissions(
    activeOrg?.organizationId,
  );
  const { isLoading: isAccountLoading } = useGetAccount(activeOrg?.organizationId);

  // useGetAppStartData is required to be loaded so we don't have to make individual page calls
  const { isLoading: isAppStartLoading } = useGetAppStartData({
    userId: user.userId,
    orgId: activeOrg?.organizationId,
  });

  const [hasClosedDiscordBanner] = useLocalStorage('has-closed-discord-banner', false);

  const { isPhone } = useSimpleBreakpoints();

  const isEarningsEnabled = useIsEarningsEnabled();
  const isOnStakingAllowlist = useIsOnStakingAllowlist();
  const isOnParticipateAllowlist = useIsOnParticipateAllowlist();
  const isOnCBPayConsumerAllowlist = useIsOnCBPayConsumerAllowlist();
  const isOnFaucetAllowList = useFaucet();
  const isBasePlaygroundEnabled = useIsBasePlaygroundEnabled();
  const isOnBOATAllowlist = useBOAT();
  const isWalletHistoryEnabled = useIsWalletHistoryEnabled();
  const isBillingUsageEnabled = useIsBillingUsageEnabled();
  const isExchangeOnboardingEnabled = useIsExchangeOnboardingEnabled();
  const isWebhooksEnabled = useWebhooks();
  const isVerificationsOnboardingEnabled = useIsVerificationsOnboardingEnabled();

  /** check for access to activeOrg, currentUser, user permissions, experiments and organizations top level */
  /** page level checks are not required */
  if (
    isUserLoading ||
    isAppStartLoading ||
    isKillSwitchLoading ||
    isAccountLoading ||
    isPermissionsLoading
  ) {
    return <FullAppLoadingSpinner />;
  }

  return (
    <Router history={history}>
      <NavigationProvider>
        <AppHelmet />
        <HStack background="background" height="100vh" overflow="hidden">
          <LeftNav />
          <VStack
            width="100%"
            height="100vh"
            overflow="auto"
            flexGrow={1}
            className="main-container"
          >
            <TopNav />
            <CloudErrorBoundary name="RouterSwitch">
              <TransitionGroup>
                <CSSTransition
                  timeout={350}
                  onEntering={(): void => {
                    window.scrollTo(0, 0);
                  }}
                >
                  <HStack width="100%" justifyContent="flex-start" spacingBottom={2}>
                    <RouterSwitch>
                      <Route exact path={AppRoute.Legal.BaseNodeTOS}>
                        <BaseNodeTermsOfService />
                      </Route>
                      {/* settings */}
                      <Route exact path={AppRoute.Settings.Project}>
                        <ProjectSettings />
                      </Route>
                      {permissions.manageBilling && isBillingUsageEnabled && (
                        <Route exact path={[AppRoute.Billing.Home, AppRoute.Billing.CurrentTab]}>
                          <BillingWithTabs />
                        </Route>
                      )}
                      {permissions.manageBilling && !isBillingUsageEnabled && (
                        <Route exact path={AppRoute.Billing.Home}>
                          <BillingPage />
                        </Route>
                      )}
                      {isEarningsEnabled && (
                        <Route path={[AppRoute.Earnings.Home]}>
                          <EarningsPage />
                        </Route>
                      )}
                      <Route exact path={AppRoute.Team.Home}>
                        <TeamPage />
                      </Route>
                      <Route exact path={[AppRoute.Access.Home, AppRoute.Access.CurrentTab]}>
                        <Access />
                      </Route>
                      <Route exact path={[AppRoute.Products.Verifications]}>
                        {isVerificationsOnboardingEnabled ? (
                          <VerificationsLanding />
                        ) : (
                          <Redirect to={AppRoute.Home} />
                        )}
                      </Route>
                      {/* participate dashboard */}
                      {isOnParticipateAllowlist && (
                        <Route exact path={AppRoute.Participate.Home}>
                          <NewClusterProvider>
                            <ParticipateDashboard />
                          </NewClusterProvider>
                        </Route>
                      )}
                      {/* create participate  */}
                      {isOnParticipateAllowlist && (
                        <Route path={AppRoute.Participate.Create}>
                          <CreateCluster />
                        </Route>
                      )}
                      {isOnParticipateAllowlist && (
                        <Route
                          exact
                          path={[
                            AppRoute.Participate.DetailsByClusterName,
                            AppRoute.Participate.DetailsByClusterNameAndTabName,
                          ]}
                        >
                          {/* Create stacking context that keeps CDS TabNavigation underneath the top nav bar */}
                          <Box zIndex={zIndex.interactable} position="relative">
                            <ClusterDetailsRoute />
                          </Box>
                        </Route>
                      )}
                      <Route path="/sso">
                        <SSORedirect />
                      </Route>
                      <Route path={[AppRoute.Projects.Details, AppRoute.Projects.DetailsTab]}>
                        <Projects />
                      </Route>
                      <Route exact path={AppRoute.Home}>
                        <Redirect to={AppRoute.Projects.Overview} />
                      </Route>
                      {/* Placeholder for the cookie preferences dialog. */}
                      <Route exact path={AppRoute.CookiePreferences} />
                      <Route path={AppRoute.Products.AdvancedTrade}>
                        <AdvancedTradeLanding />
                      </Route>
                      <Route exact path={AppRoute.Products.WaasConsumer}>
                        <WaasConsumerLanding />
                      </Route>
                      <Route exact path={AppRoute.Products.Wallets}>
                        <WalletsLanding />
                      </Route>
                      {isBasePlaygroundEnabled && (
                        <Route path={AppRoute.Products.BundlerAndPaymaster}>
                          <BundlerAndPaymasterLanding />
                        </Route>
                      )}
                      {isWalletHistoryEnabled && (
                        <Route path={AppRoute.Products.Indexers}>
                          <Indexers />
                        </Route>
                      )}
                      {isOnCBPayConsumerAllowlist && (
                        <Route path={AppRoute.Products.Onramp}>
                          <OnrampLanding />
                        </Route>
                      )}
                      <Route path={AppRoute.Products.PlatformWallets}>
                        <PlatformWallets />
                      </Route>
                      {isOnStakingAllowlist && (
                        <Route path={AppRoute.Products.Staking}>
                          <StakingAPILanding />
                        </Route>
                      )}
                      <Route path={AppRoute.Products.Base}>
                        <BaseLanding />
                      </Route>
                      <Route path={AppRoute.Products.Faucet}>
                        {isOnFaucetAllowList ? <Faucet /> : <Redirect to={AppRoute.Home} />}
                      </Route>
                      <Route path={AppRoute.Products.Webhooks}>
                        {isWebhooksEnabled ? <WebhooksPage /> : <Redirect to={AppRoute.Home} />}
                      </Route>
                      <Route path={AppRoute.Products.Boat}>
                        {isOnBOATAllowlist ? <TemplateLanding /> : <Redirect to={AppRoute.Home} />}
                      </Route>
                      <Route path={AppRoute.Products.Exchange}>
                        {isExchangeOnboardingEnabled ? (
                          <ExchangeLanding />
                        ) : (
                          <Redirect to={AppRoute.Home} />
                        )}
                      </Route>
                      <Route exact path={AppRoute.Products.App}>
                        <CoinbaseAppLanding />
                      </Route>
                      <Route exact path={AppRoute.Products.Participate}>
                        <Redirect to={AppRoute.Participate.Home} />
                      </Route>
                      {/* redirect home if user enters a non-existent route */}
                      <Redirect to={AppRoute.Home} />
                      <Route>
                        <ErrorPage />
                      </Route>
                    </RouterSwitch>
                  </HStack>
                </CSSTransition>
              </TransitionGroup>
            </CloudErrorBoundary>
            {/* add spacer to prevent content from being cutoff by mobile navbar */}
            {isPhone && <Divider height={60} />}
            {isPhone && <MobileTabNav />}
            <Spacer />
            {hasClosedDiscordBanner ? <HiringBanner /> : <DiscordBanner />}
          </VStack>
        </HStack>
      </NavigationProvider>
    </Router>
  );
}

function AuthenticatedApp() {
  return (
    <QueryClientProviderWrapper>
      <EnvBanner />
      {/* removing free credits banner for base launch */}
      {/* <GlobalModal /> */}
      <ClientKillSwitchProvider
        initialKillSwitches={defaultClientKillSwitchConfig}
        scope={config.appAnalyticsName}
      >
        <ExperimentsProvider>
          <FeatureFlagProvider>
            <AppRouter />
            <ReactQueryDevtools initialIsOpen={false} />
          </FeatureFlagProvider>
        </ExperimentsProvider>
      </ClientKillSwitchProvider>
    </QueryClientProviderWrapper>
  );
}

type AppProps = {
  locale: string;
  messages?: Record<string, string>;
};

export function App({ locale, messages }: AppProps) {
  useEffect(() => {
    initKillSwitch();
    initSprig();
  }, []);

  const clientId = process.env.TWO_FACTOR_CLIENT_ID;
  if (!clientId) throw new Error(`Could not read 2FA clientId from env`);

  const handleBoundaryError = useCallback((boundaryError: TwoFactorBoundaryError) => {
    const { error, errorInfo, context, details } = boundaryError;

    logError(
      error.message,
      {
        context,
      },
      {
        name: 'API Key Creation Two Factor Error',
        error: error.message,
        errorInfo: errorInfo.componentStack,
        details: details?.toString(),
      },
    );
  }, []);

  return (
    <StrictMode>
      <AppStateProvider>
        <CloudThemeProvider>
          <CBErrorVitalsClientContextProvider value={errorVitalsClientContext}>
            <CloudErrorBoundary name="AuthenticatedApp">
              <CookieProvider>
                <IntlProvider locale={locale} defaultLocale="en" messages={messages}>
                  <SessionProvider fallback={<UnauthenticatedApp />}>
                    <TwoFactorGlobalConfigProvider
                      clientId={clientId || ''}
                      target={getTwoFactorTargetEnv()}
                      onBoundaryError={handleBoundaryError}
                    >
                      <AuthenticatedApp />
                    </TwoFactorGlobalConfigProvider>
                  </SessionProvider>
                </IntlProvider>
              </CookieProvider>
            </CloudErrorBoundary>
          </CBErrorVitalsClientContextProvider>
        </CloudThemeProvider>
      </AppStateProvider>
    </StrictMode>
  );
}
