import { ReactNode } from 'react';
import { css } from '@linaria/core';
import { FieldTemplateProps } from '@rjsf/core';
import _isEmpty from 'lodash/isEmpty';
import _partition from 'lodash/partition';
import _snakeCase from 'lodash/snakeCase';
import _split from 'lodash/split';
import { VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextTitle3 } from '@cbhq/cds-web/typography';

const lastChildNoPadding = css`
  &:last-child {
    padding-bottom: 0;
  `;

// printLabel will print the label with an "label (optional)" in the fields if field key is not specfiied under ui:schema[required] prop
function printLabel(label: string, id: string, required: boolean): React.ReactNode | null {
  // remove any number fields for backward compatibility
  const cleanLabel = _split(label, '.')[1] || label;
  // hide label from subsections
  if (Number.isInteger(parseInt(id.slice(-1)))) {
    return undefined;
  }
  const stripLabel = cleanLabel.endsWith(':') ? cleanLabel.replace(/:/g, '') : cleanLabel;
  // present cleanLabel (optional) to optional fields
  const formatLabel = required ? cleanLabel : `${stripLabel} (optional)`;
  // return label with or without number
  return formatLabel;
}

export function FieldTemplate({
  id,
  children,
  label,
  displayLabel,
  schema,
  uiSchema,
  rawErrors,
  required,
}: FieldTemplateProps): JSX.Element | null {
  const [validationErrors] = _partition(rawErrors, (e) => e === 'required');

  if (id === 'root') {
    return children;
  }
  const showLabel = displayLabel || uiSchema['ui:field'] || schema.type === 'array';
  const description = uiSchema['ui:description'];
  const errorOnTop =
    uiSchema['ui:field'] !== 'InputField' && uiSchema['ui:field'] !== 'FullWidthInputField';

  function renderErrors(errors: string[], onTop = true): ReactNode[] | undefined {
    if (_isEmpty(errors)) {
      return undefined;
    }
    return errors.map((error: any) => (
      <VStack spacingTop={onTop ? undefined : 0.5} key={error}>
        <TextBody as="p" color="negative" key={_snakeCase(error)}>
          {error}
        </TextBody>
      </VStack>
    ));
  }

  return uiSchema['ui:widget'] !== 'hidden' ? (
    <VStack spacingBottom={10} className={lastChildNoPadding}>
      {showLabel && <TextTitle3 as="h3">{printLabel(label, id, required)}</TextTitle3>}
      {Boolean(description) && (
        <TextBody spacingTop={1} as="p">
          {description}
        </TextBody>
      )}
      {errorOnTop && renderErrors(validationErrors)}
      {children}
      {!errorOnTop && renderErrors(validationErrors, false)}
    </VStack>
  ) : null;
}
