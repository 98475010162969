import { BoxProps, HStack } from '@cbhq/cds-web/layout';

import { PaymentMethod } from ':cloud/types/service_proto';

import { PaymentMethodDisplayCard } from './PaymentMethodDisplayCard';
import { PaymentMethodDisplaySepaDebit } from './PaymentMethodDisplaySepaDebit';
import { PaymentMethodDisplayUsBankAccount } from './PaymentMethodDisplayUsBankAccount';
import { PAYMENT_METHOD_DISPLAY_CARD_HEIGHT } from './utils';

type PaymentMethodDisplayerContentProps = {
  paymentMethod: PaymentMethod;
  showIcon?: boolean;
  expanded?: boolean;
};

function PaymentMethodDisplayerContent({
  paymentMethod,
  showIcon,
  expanded,
}: PaymentMethodDisplayerContentProps) {
  const paymentMethodType = paymentMethod.type;
  switch (paymentMethodType) {
    case 'card':
      return (
        <PaymentMethodDisplayCard
          displayedMethod={paymentMethod.creditCard}
          showIcon={showIcon}
          expanded={expanded}
        />
      );
    case 'sepa_debit':
      return (
        <PaymentMethodDisplaySepaDebit
          displayedMethod={paymentMethod.sepaDebit}
          showIcon={showIcon}
          expanded={expanded}
        />
      );
    case 'us_bank_account':
      return (
        <PaymentMethodDisplayUsBankAccount
          displayedMethod={paymentMethod.usBankAccount}
          showIcon={showIcon}
          expanded={expanded}
        />
      );
    default:
      return <div />;
  }
}

type PaymentMethodDisplayerProps = {
  paymentMethod: PaymentMethod;
  showIcon?: boolean;
  transparent?: boolean;
  expanded?: boolean;
  isLoading?: boolean;
} & BoxProps;

export function PaymentMethodDisplayer({
  paymentMethod,
  showIcon = true,
  transparent = false,
  expanded = false,
  isLoading,
  ...boxProps
}: PaymentMethodDisplayerProps) {
  return (
    <HStack
      alignItems="center"
      background={transparent ? 'transparent' : 'backgroundAlternate'}
      borderRadius="rounded"
      height={PAYMENT_METHOD_DISPLAY_CARD_HEIGHT}
      justifyContent="space-between"
      spacingHorizontal={boxProps.spacingHorizontal !== undefined ? boxProps.spacingHorizontal : 2}
      spacingVertical={boxProps.spacingVertical !== undefined ? boxProps.spacingVertical : 0}
    >
      <PaymentMethodDisplayerContent {...{ paymentMethod, showIcon, expanded }} />
    </HStack>
  );
}
