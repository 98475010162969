import { FormEvent, useCallback } from 'react';
import { Button } from '@cbhq/cds-web/buttons';
import { VStack } from '@cbhq/cds-web/layout';
import { Link, TextBody, TextTitle3 } from '@cbhq/cds-web/typography';

import { CLOUD_HELP } from ':cloud/utils/links';
import { TermsCheckbox, useTermsCheckboxState } from ':cloud/widgets/billing/TermsCheckbox';
import { StripeActionHandler } from ':cloud/widgets/billing/types';
import { FormBtnRef } from ':cloud/widgets/forms/form-btn-ref';
import { InlineCallout } from ':cloud/widgets/sharedcomponents';

function BillingNotice() {
  return (
    <VStack spacingTop={2}>
      <InlineCallout type="info">
        <VStack spacingBottom={2}>
          <TextTitle3 as="h3">
            An invoice will be sent monthly for all active nodes in the prior month. The bill will
            be prorated if the nodes were only active part of the month.
          </TextTitle3>
        </VStack>
        <TextBody as="p" color="foregroundMuted">
          Your fee per node is listed in your Master Services Agreement.
        </TextBody>
      </InlineCallout>
    </VStack>
  );
}

interface DisplayInvoiceViewProps {
  isLoading: boolean;
  changeView: () => void;
  submitAction?: StripeActionHandler;
  submitBtnName: string;
  enableClusterSummaryRef?: boolean;
}

function DisplayInvoiceView({
  isLoading,
  changeView,
  submitAction,
  submitBtnName,
  enableClusterSummaryRef,
}: DisplayInvoiceViewProps) {
  const { hasAgreedToTerms, setHasAgreedToTerms, hasCheckboxError, setHasCheckboxError } =
    useTermsCheckboxState();

  const onSubmitAction = useCallback(
    async (e: FormEvent<HTMLFormElement>): Promise<void> => {
      e.preventDefault();
      if (!hasAgreedToTerms) {
        setHasCheckboxError(true);
        return;
      }

      await submitAction?.(e, { changeView });
    },
    [changeView, hasAgreedToTerms, setHasCheckboxError, submitAction],
  );

  return (
    <form onSubmit={onSubmitAction}>
      <VStack gap={5}>
        <TextBody as="p">
          You will be billed monthly at the price per RPC node listed in your Master Service
          Agreement.
        </TextBody>
        <BillingNotice />
        <VStack gap={2}>
          <TextBody as="p">
            If you have any questions related to billing,{' '}
            <Link to={CLOUD_HELP} openInNewWindow>
              contact support
            </Link>
          </TextBody>
          <TermsCheckbox
            hasCheckboxError={hasCheckboxError}
            setHasCheckboxError={setHasCheckboxError}
            hasAgreedToTerms={hasAgreedToTerms}
            setHasAgreedToTerms={setHasAgreedToTerms}
          />
        </VStack>
        {submitAction &&
          (enableClusterSummaryRef ? (
            <FormBtnRef disabled={isLoading} />
          ) : (
            <Button block disabled={isLoading} type="submit">
              {submitBtnName}
            </Button>
          ))}
      </VStack>
    </form>
  );
}

export default DisplayInvoiceView;
