import { memo, useCallback, useMemo } from 'react';
import { isAxiosError } from 'axios';
import { ResponsiveProps } from '@cbhq/cds-web';
import { Button } from '@cbhq/cds-web/buttons/Button';
import { Icon } from '@cbhq/cds-web/icons/Icon';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { Tooltip } from '@cbhq/cds-web/overlays/Tooltip/Tooltip';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { TextHeadline, TextTitle2 } from '@cbhq/cds-web/typography';

import useHasExperiment from ':cloud/hooks/useHasExperiment';
import { useCreateCommerceCharge } from ':cloud/queries/BillingQueries/useCreateCommerceCharge';
import { useGetAccount } from ':cloud/queries/BillingQueries/useGetAccount';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { getNextBillDate } from ':cloud/utils/billing';
import { currencyFormatter } from ':cloud/utils/formatters/currency';
import { FULL_PAGE_MAX_WIDTH } from ':cloud/widgets/product/constants';

type ItemsProps = {
  headline: string;
  tooltip?: string;
  // Children are assumed to be FAQ content -- if present, they allow
  // a "View More" button to render which expands to show the children.
  children?: React.ReactNode;
};

const responsiveConfig: ResponsiveProps = {
  phone: { flexDirection: 'column' },
};

const responsiveConfigItem: ResponsiveProps = {
  phone: { spacingEnd: 0, spacingBottom: 4 },
};

function Item({ headline, tooltip, children }: ItemsProps) {
  return (
    <VStack responsiveConfig={responsiveConfigItem} spacingEnd={10} spacingBottom={0}>
      <HStack gap={1} alignItems="center">
        <TextHeadline as="p" color="foregroundMuted" spacingBottom={1}>
          {headline}
        </TextHeadline>
        {tooltip && (
          <Tooltip content={tooltip}>
            <Icon spacingBottom={1} name="info" size="s" color="foregroundMuted" />
          </Tooltip>
        )}
      </HStack>
      {children}
    </VStack>
  );
}

type BillingStatsSectionProps = {
  accountCreditsRemaining?: number;
  additionalCredits?: Record<string, number>;
};

function BillingStatsSection({
  accountCreditsRemaining,
  additionalCredits,
}: BillingStatsSectionProps) {
  const { activeOrg } = useGetUser();
  const orgId = activeOrg?.organizationId;
  const account = useGetAccount(orgId)?.account;
  const balance = account?.balance ? currencyFormatter(account?.balance) : '-';
  const creditsRemaining =
    accountCreditsRemaining || accountCreditsRemaining === 0
      ? currencyFormatter(accountCreditsRemaining)
      : '-';
  const nextBillDate = useMemo(() => {
    return balance !== '-' ? getNextBillDate() : 'No upcoming bill';
  }, [balance]);
  const toast = useToast();

  const isPayWithCryptoEnabled = useHasExperiment('jun_2024_pay_with_crypto', 'treatment');
  const createCommerceCharge = useCreateCommerceCharge(orgId);

  const handleBuyCredits = useCallback(() => {
    createCommerceCharge.mutate(undefined, {
      onSuccess: (data) => {
        window.open(data.hostedUrl, '_self');
      },
      onError: (error) => {
        if (isAxiosError(error)) {
          toast.show('An error occurred while processing your request. Please try again.');
        }
      },
    });
  }, [createCommerceCharge, toast]);

  const hasPaymaster = !!additionalCredits?.additional_paymaster;

  return (
    <Box borderedBottom>
      <Box maxWidth={FULL_PAGE_MAX_WIDTH}>
        <Box responsiveConfig={responsiveConfig} spacing={7} spacingTop={2}>
          <Item headline="Next bill date">
            <TextTitle2 as="p">{nextBillDate}</TextTitle2>
          </Item>
          {hasPaymaster && (
            <Item
              headline="Paymaster credits"
              tooltip="This credit will be auto applied to any applicable Paymaster product usage."
            >
              <TextTitle2 as="p">
                {currencyFormatter(additionalCredits.additional_paymaster)}
              </TextTitle2>
            </Item>
          )}
          <Item
            headline={hasPaymaster ? 'Other credits' : 'Credits'}
            tooltip="These credits will be automatically applied to your next applicable bill and apply to all products you use on Coinbase Developer products."
          >
            <TextTitle2 as="p">{creditsRemaining}</TextTitle2>
          </Item>
          <Item headline="Current balance">
            <TextTitle2 as="p">{balance}</TextTitle2>
          </Item>
          {isPayWithCryptoEnabled && (
            <Box>
              <Button onPress={handleBuyCredits}>Buy credits with crypto</Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default memo(BillingStatsSection);
