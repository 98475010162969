import { Button } from '@cbhq/cds-web/buttons';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

type PaymasterGettingStartedItemProps = {
  stepNumber: string;
  title: string;
  description: string;
  cta: string;
  onPress: () => void;
  completed?: boolean;
};

export function PaymasterGettingStartedItem({
  stepNumber,
  title,
  description,
  cta,
  onPress,
  completed = false,
}: PaymasterGettingStartedItemProps) {
  return (
    <HStack
      gap={2}
      alignItems="center"
      bordered
      borderRadius="rounded"
      spacingVertical={4}
      spacingHorizontal={6}
      width="100%"
      justifyContent="space-between"
    >
      <HStack gap={2} alignItems="center">
        <Box
          dangerouslySetBackground="var(--foreground)"
          borderRadius="roundedFull"
          alignItems="center"
          justifyContent="center"
          display="flex"
          width={24}
          minWidth={24}
          height={24}
        >
          <TextHeadline as="h1" color="secondary">
            {stepNumber}
          </TextHeadline>
        </Box>
        <VStack gap={0.5}>
          <TextHeadline as="h1">{title}</TextHeadline>
          <TextBody as="p" color="foregroundMuted">
            {description}
          </TextBody>
        </VStack>
      </HStack>
      {completed ? (
        <Icon size="m" name="checkmark" color="positive" />
      ) : (
        <Button variant="secondary" onPress={onPress}>
          {cta}
        </Button>
      )}
    </HStack>
  );
}
