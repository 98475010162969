import { useCallback, useMemo } from 'react';
import { Button } from '@cbhq/cds-web/buttons';
import { Select, SelectOption, TextInput } from '@cbhq/cds-web/controls';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Grid, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextBody } from '@cbhq/cds-web/typography';

import { useAppState } from ':cloud/contexts/AppStateContext';
import { usePaymasterContext } from ':cloud/contexts/PaymasterProvider';
import { EventName, logClick } from ':cloud/init/clientAnalytics/logging';
import { useUpdateGasPolicy } from ':cloud/queries/Base/useUpdateGasPolicy';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { GasPolicy } from ':cloud/types/ts_types';
import { formatTxnAmount, getKeyByValue } from ':cloud/utils/paymaster';

import { LIMIT_CYCLE_MAP } from './constants';
import { ContractAllowlistBanner } from './ContractAllowlistBanner';

type PaymasterPerUserLimitSectionProps = {
  maxGasPerAddrUsd: string;
  onMaxGasPerAddressChange: (gas: React.ChangeEvent<HTMLInputElement>) => void;
  maxTxnPerAddr: string;
  onMaxTxnPerAddressChange: (txn: React.ChangeEvent<HTMLInputElement>) => void;
  perAddressLimitCycle: string;
  onPerAddressLimitCycleChange: (cycle: string) => void;
  maxGasError?: string;
  gasPolicy: GasPolicy;
};

export function PaymasterPerUserLimitSection({
  maxGasPerAddrUsd,
  onMaxGasPerAddressChange,
  maxTxnPerAddr,
  onMaxTxnPerAddressChange,
  perAddressLimitCycle,
  onPerAddressLimitCycleChange,
  maxGasError,
  gasPolicy,
}: PaymasterPerUserLimitSectionProps) {
  const { isPhone } = useBreakpoints();
  const responsiveTemplateColumns = useMemo(() => {
    return isPhone ? '1fr' : '1fr 1fr';
  }, [isPhone]);
  const { activeOrg } = useGetUser();
  const { selectedProject } = useAppState();

  const { setCompletedStep } = usePaymasterContext();

  const handleSuccess = useCallback(() => {
    setCompletedStep('gas');
  }, [setCompletedStep]);

  const updateGasPolicy = useUpdateGasPolicy({
    organizationId: activeOrg?.organizationId || '',
    projectId: selectedProject?.id || '',
    previousPolicy: gasPolicy,
    onSuccess: handleSuccess,
  });

  const numChangesUnsaved = useMemo(() => {
    let count = 0;
    if (gasPolicy?.maxGasPerAddrUsd !== parseFloat(maxGasPerAddrUsd)) {
      count += 1;
    }
    if (gasPolicy?.maxTxnPerAddr !== maxTxnPerAddr) {
      count += 1;
    }
    if (
      gasPolicy?.intervalType &&
      getKeyByValue(LIMIT_CYCLE_MAP, gasPolicy?.intervalType) !== perAddressLimitCycle
    ) {
      count += 1;
    }
    return count;
  }, [gasPolicy, maxGasPerAddrUsd, maxTxnPerAddr, perAddressLimitCycle]);

  const handleSavePress = useCallback(() => {
    if (!gasPolicy) {
      return;
    }

    const newGasPolicy = {
      ...gasPolicy,
      maxGasPerAddrUsd: parseFloat(maxGasPerAddrUsd),
      maxTxnPerAddr: formatTxnAmount(maxTxnPerAddr),
      intervalType: perAddressLimitCycle
        ? LIMIT_CYCLE_MAP[perAddressLimitCycle]
        : gasPolicy?.intervalType,
    };

    logClick(EventName.paymaster_update_user_limit);

    updateGasPolicy.mutate({ policy: newGasPolicy });
  }, [gasPolicy, maxGasPerAddrUsd, maxTxnPerAddr, perAddressLimitCycle, updateGasPolicy]);

  const isDisabled = !gasPolicy?.contractAllowlist?.length;
  const isSaveDisabled =
    !numChangesUnsaved || !!maxGasError || !maxGasPerAddrUsd || maxGasPerAddrUsd === '.';

  return (
    <VStack gap={3} spacingTop={3}>
      {isDisabled && <ContractAllowlistBanner />}
      <Grid spacingTop={isDisabled ? 0 : 4} gap={1} templateColumns={responsiveTemplateColumns}>
        <TextInput
          type="text"
          label="Maximum USD"
          placeholder="0.00"
          helperText={maxGasError || 'Max amount of USD this policy will sponsor per user'}
          value={maxGasPerAddrUsd}
          onChange={onMaxGasPerAddressChange}
          suffix="USD"
          variant={maxGasError ? 'negative' : 'foregroundMuted'}
        />
        <TextInput
          type="text"
          label="Maximum Number of User Operations"
          placeholder="0"
          helperText="Max number of User Operations this policy will sponsor per user"
          value={maxTxnPerAddr}
          onChange={onMaxTxnPerAddressChange}
        />
      </Grid>
      <Select
        value={perAddressLimitCycle}
        onChange={onPerAddressLimitCycleChange}
        label="Limit cycle"
        helperText="The limit will be reset every cycle, starting from when the change is saved"
      >
        {Object.keys(LIMIT_CYCLE_MAP).map((option) => (
          <SelectOption value={option} key={option} title={option} />
        ))}
      </Select>

      <HStack gap={2} justifyContent="flex-end" alignItems="center" spacingTop={7}>
        <TextBody as="p" color="foregroundMuted">{`${numChangesUnsaved} unsaved change${
          numChangesUnsaved === 1 ? '' : 's'
        }`}</TextBody>
        <Button variant="primary" onPress={handleSavePress} disabled={isSaveDisabled}>
          Save
        </Button>
      </HStack>
    </VStack>
  );
}
