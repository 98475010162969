import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { IPlatformAPIKey } from ':cloud/types/ts_types';

const errorMsg = 'An error occurred editing API key. Please retry';
const successMsg = 'API key successfully updated';

type UpdateKeyProps = {
  orgId: string;
  tokenId: string;
  nickname?: string;
  scopes?: string[];
  allowedIps?: string[];
  proofToken?: string;
  enabled?: boolean;
  updateMask: string;
};

async function updateKey({
  orgId,
  tokenId,
  nickname,
  scopes,
  allowedIps,
  enabled,
  proofToken,
  updateMask,
}: UpdateKeyProps): Promise<IPlatformAPIKey | undefined> {
  const path = `/apikeys/v1/organizations/${orgId}/apiKeys/${tokenId}`;
  const body = {
    apiKey: {
      name: `organizations/${orgId}/apiKeys/${tokenId}`,
      ...(nickname && { nickname }),
      ...(scopes !== undefined && { scopes }),
      ...(allowedIps !== undefined && { allowedIps }),
      ...(enabled !== undefined && { enabled }),
    },
    updateMask,
  };

  const additionalHeaders = proofToken
    ? {
        headers: {
          'second-factor-proof-token': proofToken,
        },
      }
    : {};

  const { data } = await APIClientBase.put<IPlatformAPIKey>(path, body, additionalHeaders);
  return data;
}

type UpdateAPIKeyMutate = {
  tokenId: string;
  nickname?: string;
  scopes?: string[];
  allowedIps?: string[];
  proofToken?: string;
  enabled?: boolean;
  updateMask: string;
};

export function useUpdateApiKey(
  orgId = '',
  showToast = true,
  onSuccess?: (token: IPlatformAPIKey) => void,
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      tokenId,
      nickname,
      scopes,
      allowedIps,
      proofToken,
      enabled,
      updateMask,
    }: UpdateAPIKeyMutate) =>
      updateKey({ orgId, tokenId, nickname, scopes, allowedIps, proofToken, enabled, updateMask }),
    onSuccess: async (updatedKey) => {
      if (!updatedKey) return;
      queryClient.setQueryData([QueryKey.apiKeys, updatedKey.name], updatedKey);
      await queryClient.invalidateQueries([QueryKey.apiKeys]);
      onSuccess?.(updatedKey);
    },
    meta: {
      errorMsg,
      ...(showToast && { successMsg }),
    },
  });
}
