import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { ResponsiveProps } from '@cbhq/cds-common';
import { useToggler } from '@cbhq/cds-common/hooks/useToggler';
import { Button, ButtonGroup } from '@cbhq/cds-web/buttons';
import { Collapsible } from '@cbhq/cds-web/collapsible/Collapsible';
import { TextInput } from '@cbhq/cds-web/controls';
import { NavigationIcon } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { ThemeProvider } from '@cbhq/cds-web/system';
import { palette } from '@cbhq/cds-web/tokens';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { useFindOrCreateProductDetails } from ':cloud/queries/Projects/useFindOrCreateProductDetails';
import { useUpdateProductDetailsWithMessage } from ':cloud/queries/Projects/useUpdateProductDetails';
import { ProductDetails, ProductTypeMap, Project } from ':cloud/types/ts_types';
import { ERROR_SUCCESS_MESSAGES } from ':cloud/widgets/waasConsumer/common';

type ProductDetailsSectionProps = {
  project: Project;
};

const CUSTOM_PALETTE_NAV_ICON = {
  foreground: 'gray0' as const,
} as const;

const productType = ProductTypeMap.cbpay;

const walletNameResponsiveConfig: ResponsiveProps = {
  phone: {
    display: 'none',
  },
  tablet: {
    display: 'none',
  },
};

export function WalletNameSection({ project }: ProductDetailsSectionProps) {
  const [walletName, setWalletName] = useState<string>('');
  const { isPhone } = useSimpleBreakpoints();
  const { productDetails } = useFindOrCreateProductDetails(project, productType);

  const [isFormEditable, { toggle }] = useToggler();
  useEffect(() => {
    if (productDetails) {
      setWalletName(productDetails.cbpay?.displayName || '');
    }
  }, [productDetails]);

  const updateProductDetails = useUpdateProductDetailsWithMessage(ERROR_SUCCESS_MESSAGES, {
    updateMask: 'displayName',
  });

  const updateWalletDisplayName = useCallback(
    (updatedDetails: string, onSettled) => {
      if (!updatedDetails) {
        return;
      }

      const newProductDetails = {
        cbpay: {
          ...productDetails?.cbpay,
          displayName: walletName,
        },
      } as ProductDetails;

      const mutation = updateProductDetails;
      mutation.mutate({ project, productType, productDetails: newProductDetails }, { onSettled });
    },
    [productDetails, walletName, updateProductDetails, project],
  );

  const isWalletNameValid = useMemo(() => {
    return walletName && walletName.length <= 50;
  }, [walletName]);

  const formValid = isWalletNameValid;

  const trySubmitFormData = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      updateWalletDisplayName(walletName, () => {
        toggle();
      });
    },
    [updateWalletDisplayName, toggle, walletName],
  );

  return (
    <VStack bordered borderRadius="rounded" spacing={4}>
      <HStack alignItems="center" gap={2}>
        {!isPhone && (
          <Box
            dangerouslySetBackground={palette.foreground}
            height={24}
            width={24}
            borderRadius="roundedFull"
            alignItems="center"
            justifyContent="center"
          >
            <ThemeProvider palette={CUSTOM_PALETTE_NAV_ICON}>
              <NavigationIcon name="wallet" size="s" />
            </ThemeProvider>
          </Box>
        )}
        <VStack gap={2} flexGrow={1}>
          <VStack>
            <TextHeadline as="h1">Display wallet name</TextHeadline>
            <TextBody as="p" color="foregroundMuted">
              This is how your wallet name is displayed when a user reviews their order.
            </TextBody>
          </VStack>
        </VStack>
        <HStack alignItems="center">
          {productDetails?.cbpay?.displayName && (
            <TextBody
              as="p"
              color="foreground"
              spacingEnd={3}
              responsiveConfig={walletNameResponsiveConfig}
            >
              {productDetails?.cbpay?.displayName}
            </TextBody>
          )}
          {!isFormEditable && (
            <Button variant="primary" onPress={toggle} transparent>
              Edit
            </Button>
          )}
        </HStack>
      </HStack>
      <Collapsible collapsed={!isFormEditable}>
        <form style={{ width: '100%' }} onSubmit={trySubmitFormData}>
          <VStack gap={3} spacingStart={8} spacingTop={3}>
            <TextInput
              onChange={(e) => setWalletName(e.target.value)}
              placeholder="Enter wallet name"
              name="name"
              value={walletName}
              maxLength={50}
              variant={!isWalletNameValid && walletName ? 'negative' : undefined}
              helperText={!isWalletNameValid && walletName ? '50 characters maximum in length' : ''}
            />
            <HStack justifyContent="flex-end" gap={2} spacingTop={0}>
              <ButtonGroup>
                <Button variant="secondary" onPress={toggle}>
                  Cancel
                </Button>
                <Button type="submit" disabled={!formValid}>
                  Save
                </Button>
              </ButtonGroup>
            </HStack>
          </VStack>
        </form>
      </Collapsible>
    </VStack>
  );
}
