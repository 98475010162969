import { Redirect, Route, Switch } from 'react-router-dom';

import LegacyTokensProvider from ':cloud/contexts/LegacyTokensProvider';
import OAuthInfoProvider from ':cloud/contexts/OAuthInfoProvider';
import { useIsLegacyAPITokenManagementEnabled } from ':cloud/hooks/featureFlags/useIsLegacyAPITokenManagementEnabled';
import { useIsOAuthEnabled } from ':cloud/hooks/featureFlags/useIsOAuthEnabled';
import { EventName, useLogViewOnMount } from ':cloud/init/clientAnalytics/logging';
import { APIKeys } from ':cloud/pages/APIKeys';
import { LegacyAPITokenManagement } from ':cloud/pages/LegacyAPITokenManagement';
import { OAuth } from ':cloud/pages/OAuth';
import { OAuthCreateClient } from ':cloud/pages/OAuthCreateClient';
import OAuthEditClient from ':cloud/pages/OAuthEditClient';
import { PermissionsPage } from ':cloud/pages/Permissions';
import { Teams } from ':cloud/pages/Teams';
import { AppRoute } from ':cloud/utils/routes';

export function Access() {
  const isLegacyAPIManagementEnabled = useIsLegacyAPITokenManagementEnabled();
  const isOAuthEnabled = useIsOAuthEnabled();

  useLogViewOnMount(EventName.access_page_view);
  return (
    <OAuthInfoProvider>
      <Switch>
        <Route exact path={AppRoute.Access.Permissions}>
          <PermissionsPage />
        </Route>
        <Route exact path={AppRoute.Access.Teams}>
          <Teams />
        </Route>
        {isLegacyAPIManagementEnabled ? (
          <Route exact path={AppRoute.Access.LegacyAPI}>
            <LegacyTokensProvider>
              <LegacyAPITokenManagement />
            </LegacyTokensProvider>
          </Route>
        ) : null}
        <Route exact path={AppRoute.Access.API}>
          <APIKeys />
        </Route>
        {isOAuthEnabled && (
          <Route exact path={AppRoute.Access.OAuth}>
            <OAuth />
          </Route>
        )}
        {isOAuthEnabled && (
          <Route exact path={AppRoute.Access.OAuthCreate}>
            <OAuthCreateClient />
          </Route>
        )}
        {isOAuthEnabled && (
          <Route exact path={AppRoute.Access.OAuthEdit}>
            <OAuthEditClient />
          </Route>
        )}

        <Redirect to={AppRoute.Access.API} />
      </Switch>
    </OAuthInfoProvider>
  );
}
