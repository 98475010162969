import { useCallback } from 'react';
import {
  Area,
  AreaChart,
  BarChart,
  Customized,
  Line,
  LineChart,
  Rectangle,
  ResponsiveContainer,
  XAxis,
} from 'recharts';
import { Box } from '@cbhq/cds-web/layout';
import { TextHeadline } from '@cbhq/cds-web/typography';

import { ChartType as MonitoringCharType } from ':cloud/widgets/earnings/types';
import { TotalOverlay } from ':cloud/widgets/monitoring/TotalOverlay';
import { ChartType as EarningsChartType, DashboardRange } from ':cloud/widgets/monitoring/types';

import { formatTickTimestamp } from '../monitoring/utils';

type ChartType = MonitoringCharType | EarningsChartType;

const placeholderBarData = [
  {
    name: 'Jun 17',
    value: 3,
  },
  {
    name: 'Jun 18',
    value: 6,
  },
  {
    name: 'Jun 19',
    value: 8,
  },
  {
    name: 'Jun 20',
    value: 6,
  },
  {
    name: 'Jun 21',
    value: 5,
  },
  {
    name: 'Jun 22',
    value: 10,
  },
  {
    name: 'Jun 23',
    value: 8,
  },
  {
    name: 'Jun 24',
    value: 6,
  },
];

const placeholderLinearData = [
  {
    name: ' ',
    value: 3,
  },
  {
    name: ' ',
    value: 4,
  },
  {
    name: ' ',
    value: 6,
  },
  {
    name: ' ',
    value: 7,
  },
  {
    name: ' ',
    value: 6,
  },
  {
    name: ' ',
    value: 10,
  },
  {
    name: ' ',
    value: 10,
  },
  {
    name: ' ',
    value: 12,
  },
  {
    name: ' ',
    value: 13,
  },
  {
    name: ' ',
    value: 14,
  },
  {
    name: ' ',
    value: 13,
  },
  {
    name: ' ',
    value: 14,
  },
  {
    name: ' ',
    value: 15,
  },
  {
    name: ' ',
    value: 14,
  },
  {
    name: ' ',
    value: 18,
  },
  {
    name: ' ',
    value: 18,
  },
  {
    name: ' ',
    value: 17,
  },
  {
    name: ' ',
    value: 20,
  },
];

const xAxisProps = { padding: { left: 0, right: 0 }, tickLine: false, stroke: 'var(--line)' };

type EmptyChartProps = {
  chartType: ChartType;
  showOverlay?: boolean;
  range?: DashboardRange;
};

type EmptyChartBodyProps = {
  data: Record<string, string | number>[];
  range?: DashboardRange;
} & Pick<EmptyChartProps, 'showOverlay'>;

function EmptyChartTextOverlay() {
  return (
    <Box position="relative" display="block" top="-50%">
      <TextHeadline as="h3" align="center">
        No data to show
      </TextHeadline>
    </Box>
  );
}

function EmptyChartGradient() {
  return (
    <linearGradient id="fade">
      <stop offset="30%" stopColor="rgba(255, 255, 255, 0)" />
      <stop offset="80%" stopColor="rgba(255, 255, 255, 0.25)" />
      <stop offset="100%" stopColor="rgba(255, 255, 255, 1)" />
    </linearGradient>
  );
}

function EmptyAreaChart({ data, showOverlay }: EmptyChartBodyProps) {
  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart width={150} height={40} data={data}>
          <defs>
            <EmptyChartGradient />
          </defs>
          <Area type="monotone" dataKey="value" fill="var(--secondary)" stroke="var(--secondary)" />
          <XAxis dataKey="time" {...xAxisProps} />
          <Customized
            component={<Rectangle x={0} y={0} width={150} height={40} fill="url(#fade)" />}
          />
        </AreaChart>
      </ResponsiveContainer>
      {showOverlay && <EmptyChartTextOverlay />}
    </>
  );
}

function EmptyBarChart({ data, showOverlay, range = 7 }: EmptyChartBodyProps) {
  const xAxisTickFormatter = useCallback(
    (tickItem: string) => {
      return formatTickTimestamp(tickItem, range);
    },
    [range],
  );
  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart width={150} height={40} data={data}>
          {/* <defs>
            <EmptyChartGradient />
          </defs>
          <Bar dataKey="value" fill="var(--secondary)" /> */}
          <XAxis dataKey="name" {...xAxisProps} tickFormatter={xAxisTickFormatter} />
          <Customized
            component={<Rectangle x={0} y={0} width={150} height={40} fill="url(#fade)" />}
          />
        </BarChart>
      </ResponsiveContainer>
      {showOverlay && <EmptyChartTextOverlay />}
      {showOverlay && <TotalOverlay value="" />}
    </>
  );
}

function EmptyLineChart({ data, showOverlay }: EmptyChartBodyProps) {
  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart width={150} height={40} data={data}>
          <defs>
            <EmptyChartGradient />
          </defs>
          <Line type="monotone" dataKey="value" stroke="var(--line)" strokeWidth={2} />
          <XAxis dataKey="time" {...xAxisProps} />
          <Customized
            component={<Rectangle x={0} y={0} width={150} height={40} fill="url(#fade)" />}
          />
        </LineChart>
      </ResponsiveContainer>
      {showOverlay && <EmptyChartTextOverlay />}
    </>
  );
}

type EmptyChartComponentMap = {
  [key in EmptyChartProps['chartType']]: JSX.Element;
};

export function EmptyChart({ chartType, range, showOverlay = true }: EmptyChartProps) {
  const EmptyChartComponent: EmptyChartComponentMap = {
    area: <EmptyAreaChart data={placeholderLinearData} showOverlay={showOverlay} />,
    bar: <EmptyBarChart data={placeholderBarData} showOverlay={showOverlay} range={range} />,
    line: <EmptyLineChart data={placeholderLinearData} showOverlay={showOverlay} />,
    // TODO: CCPLT-1001 earnings balance chart empty graph
    pie: <EmptyLineChart data={placeholderLinearData} showOverlay={showOverlay} />,
  };

  return EmptyChartComponent[chartType];
}
