import { useCallback, useState } from 'react';
import { noop } from '@cbhq/cds-utils';
import { Banner } from '@cbhq/cds-web/banner/Banner';
import { Button } from '@cbhq/cds-web/buttons';
import { TextInput } from '@cbhq/cds-web/controls';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from '@cbhq/cds-web/overlays';
import { TextBody, TextLabel1 } from '@cbhq/cds-web/typography';

import { ClipboardIcon } from ':cloud/widgets/sharedcomponents/ClipboardIcon';

type ProjectDeleteModalProps = Partial<ModalProps> & {
  onSuccess?: () => void;
  onSubmit?: () => void;
  projectId?: string;
  projectName?: string;
};

export function ProjectDeleteModal(props: ProjectDeleteModalProps): JSX.Element {
  const { onRequestClose, onSubmit, projectId, projectName } = props;
  const [inputProjectName, setCopiedProjectId] = useState('');
  if (!projectId) {
    throw new Error('Project ID not available');
  }

  const handleInputChange = useCallback((event) => {
    setCopiedProjectId(event.target.value);
  }, []);

  return (
    <Modal onRequestClose={onRequestClose || noop} visible {...props}>
      <ModalHeader title="Delete project" />
      <ModalBody>
        <VStack gap={5}>
          <Banner title="Warning" variant="warning" startIcon="warning">
            This project will be permanently and irreversibly deleted and will stop working
            immediately after it is deleted. If you are using wallets, your users will no longer
            have access to their assets.
          </Banner>
          <TextBody as="p">Type the project name to confirm deletion</TextBody>
          <VStack gap={1}>
            <TextLabel1 as="p">Project Name</TextLabel1>
            <HStack gap={1} alignItems="center">
              <VStack alignItems="center" background="primaryWash" spacingHorizontal={0.5}>
                <TextBody as="p" mono>
                  {projectName}
                </TextBody>
              </VStack>
              <ClipboardIcon text={projectName as string} />
            </HStack>
          </VStack>
          <VStack>
            <TextInput label="Project Name" onChange={handleInputChange} />
          </VStack>
        </VStack>
      </ModalBody>
      <ModalFooter
        primaryAction={
          <Button
            type="submit"
            variant="negative"
            onPress={onSubmit}
            testID="submit-delete-project"
            disabled={inputProjectName !== projectName}
          >
            Delete project
          </Button>
        }
        secondaryAction={
          <Button variant="secondary" onPress={onRequestClose}>
            Cancel
          </Button>
        }
      />
    </Modal>
  );
}
