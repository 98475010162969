import { Redirect, Route, Switch } from 'react-router-dom';

import { AppRoute } from ':cloud/utils/routes';

import { BillingPage } from './Billing';
import { UsagePage } from './UsagePage';

export function BillingWithTabs() {
  return (
    <Switch>
      <Route exact path={AppRoute.Billing.Details}>
        <BillingPage />
      </Route>
      <Route exact path={AppRoute.Billing.Usage}>
        <UsagePage />
      </Route>
      <Redirect to={AppRoute.Billing.Details} />
    </Switch>
  );
}
