import { useMutation } from '@tanstack/react-query';

import { APIClientBase } from ':cloud/state/Api';
import { DownloadInvoiceResponse } from ':cloud/types/service_proto';

async function getInvoiceDownload(
  stripeInvoiceId: string,
  orgId?: string,
): Promise<DownloadInvoiceResponse> {
  const { data } = await APIClientBase.post<DownloadInvoiceResponse>(
    `/billing/v1/accounts/${orgId}:generateStripeInvoiceUri`,
    { stripeInvoiceId },
  );
  return data;
}

export function useGetInvoiceDownload(orgId = '') {
  return useMutation({
    mutationFn: async (stripeInvoiceId: string) => getInvoiceDownload(stripeInvoiceId, orgId),
    meta: {
      errorMsg: `There was an error generating a download link`,
    },
  });
}
