import { css, cx } from '@linaria/core';
import { Icon } from '@cbhq/cds-web/icons';
import { HStack } from '@cbhq/cds-web/layout';
import { deviceBreakpoints } from '@cbhq/cds-web/layout/breakpoints';
import { palette, spacing } from '@cbhq/cds-web/tokens';
import { Link, type LinkProps, TextBody } from '@cbhq/cds-web/typography';

const linkStyles = css`
  &:hover {
    & div,
    & span {
      color: ${palette.primary} !important;
    }
  }
  & svg {
    width: ${spacing[5]};
    height: ${spacing[5]};
    ${deviceBreakpoints.tablet} {
      width: ${spacing[5]};
      height: ${spacing[5]};
    }
    & path {
      stroke-width: 0.6;
      ${deviceBreakpoints.tablet} {
        stroke-width: 1;
      }
    }
  }
`;

interface LinkIconProps {
  icon: React.ReactNode;
  text?: string;
}

function LinkIconWithText({ icon, text }: LinkIconProps) {
  return (
    <HStack gap={1} alignItems="center">
      <span role="img" aria-hidden style={{ position: 'relative', top: 2 }}>
        {icon}
      </span>
      <TextBody as="span" color="currentColor">
        {text}
      </TextBody>
    </HStack>
  );
}

interface ExternalLinkProps extends Omit<LinkProps, 'children'> {
  className?: string;
  display?: 'initial' | 'block' | 'inline';
  icon?: React.ReactNode;
  iconText?: string;
  showExternalLinkIcon?: boolean;
  underline?: boolean;
  children?: NonNullable<React.ReactNode>;
}

/**
 * @deprecated Use CDS Link component instead:
 * {@link https://cds.cbhq.net/components/link}
 */
export function ExternalLink({
  className,
  icon,
  iconText,
  showExternalLinkIcon = false,
  to,
  underline = false,
  children,
  ...rest
}: ExternalLinkProps) {
  return (
    <Link
      to={to}
      variant="body"
      color="primary"
      className={cx(linkStyles, className)}
      underline={underline}
      openInNewWindow
      {...rest}
    >
      {Boolean(icon) && <LinkIconWithText icon={icon} text={iconText} />}
      {children}
      {showExternalLinkIcon && <Icon name="externalLink" size="l" aria-hidden />}
    </Link>
  );
}
