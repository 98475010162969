import { useCallback, useRef, useState } from 'react';

import { UploadedFile } from ':cloud/widgets/access/oauth/types';

const MAX_FILE_SIZE = 500 * 1024; // 500KB in bytes
const MAX_DIMENSIONS = 500; // 500 pixels

interface UseLogoUploadProps {
  onFileUpload?: (file: UploadedFile) => void;
  onFileRemove?: () => void;
}

type SizeError = 'fileSize' | 'dimensions' | null;

export function useLogoUpload({ onFileUpload, onFileRemove }: UseLogoUploadProps) {
  const [uploadedFile, setUploadedFile] = useState<UploadedFile | null>(null);
  const [sizeError, setSizeError] = useState<SizeError>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const readFileAsBytes = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string); // Base64 encoded string of the file
      };
      reader.onerror = () => reject(reader.error);
      reader.readAsDataURL(file); // Read the file as Data URL (base64)
    });
  };

  const getImageDimensions = async (file: File): Promise<{ width: number; height: number }> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = reject;
      img.src = URL.createObjectURL(file);
    });
  };

  const checkFileValidity = useCallback(async (file: File): Promise<boolean> => {
    if (file.size > MAX_FILE_SIZE) {
      setSizeError('fileSize');
      return false;
    }

    const dimensions = await getImageDimensions(file);
    if (dimensions.width > MAX_DIMENSIONS || dimensions.height > MAX_DIMENSIONS) {
      setSizeError('dimensions');
      return false;
    }

    setSizeError(null);
    return true;
  }, []);

  const handleFileChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files ? event.target.files[0] : null;
      if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
        const isValid = await checkFileValidity(file);
        if (!isValid) return;

        const fileUrl = URL.createObjectURL(file);
        const bytes = await readFileAsBytes(file);
        const newUploadedFile = { file, name: file.name, url: fileUrl, bytes: bytes.split(',')[1] };
        setUploadedFile(newUploadedFile);
        if (onFileUpload) {
          onFileUpload(newUploadedFile);
        }
      }
    },
    [checkFileValidity, onFileUpload],
  );

  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault(); // Necessary to allow for a drop
  }, []);

  const handleDrop = useCallback(
    async (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      if (!fileInputRef.current) return;

      if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
        const file = event.dataTransfer.files[0]; // Get the first file
        if (file.type === 'image/png' || file.type === 'image/jpeg') {
          const isValid = await checkFileValidity(file);
          if (!isValid) return;

          fileInputRef.current.files = event.dataTransfer.files; // Set the file manually if needed
          await handleFileChange({ target: { files: event.dataTransfer.files } } as any); // Manually trigger the change handler
        }
      }
    },
    [checkFileValidity, handleFileChange],
  );

  const handleUploadClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleFileRemove = useCallback(() => {
    if (uploadedFile) {
      setUploadedFile(null);
      setSizeError(null);
      if (onFileRemove) {
        onFileRemove();
      }
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  }, [onFileRemove, uploadedFile]);

  return {
    uploadedFile,
    handleFileChange,
    handleUploadClick,
    handleFileRemove,
    handleDragOver,
    handleDrop,
    fileInputRef,
    sizeError,
  };
}
