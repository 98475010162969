// eslint-disable-next-line import/no-unused-modules
import './css/index.css';
import '@cbhq/two-factor-web/styles';

import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { SetupWorker } from 'msw';
import { convertToCBSupported, fetchMessagesFromCDN, getClientLocale } from '@cbhq/intl';
import { polyfillIntl } from '@cbhq/intl/polyfills';

import config from ':cloud/config';
import { initBugsnag } from ':cloud/init/bugsnag/init';
import { initClientAnalytics } from ':cloud/init/clientAnalytics/init';

import { App } from './app/App';
import reportWebVitals from './reportWebVitals';

// workaround to serialize BigInts https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/BigInt#use_within_json
// @ts-expect-error workaround to serialize BigInts
// eslint-disable-next-line no-extend-native
BigInt.prototype.toJSON = function serializeBigIntToString() {
  return this.toString();
};

async function bootstrap() {
  // Start mocks
  if (
    config.isMocksEnabled() &&
    !(process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'production')
  ) {
    // eslint-disable-next-line global-require
    const { worker } = require('./mocks/browser');
    void (worker as SetupWorker).start({
      quiet: config.disableMockLogging,
    });
    /* print mocked handlers */
    void (worker as SetupWorker).printHandlers();
  }

  // Get the locale from a cookie or the browser
  const locale = convertToCBSupported(getClientLocale());

  const [, messages] = await Promise.all([
    // Start polyfilling `Intl` as soon as possible
    polyfillIntl(locale),

    // Load translated messages for the defined locale
    process.env.SMARTLING_PROJECT_ID
      ? fetchMessagesFromCDN(locale, process.env.SMARTLING_PROJECT_ID)
      : Promise.resolve({}),
  ]);

  initClientAnalytics();
  initBugsnag();

  // Render the application with the locale and messages
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <StrictMode>
      <App locale={locale} messages={messages} />
    </StrictMode>,
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  // eslint-disable-next-line -- Existing code
  reportWebVitals(console.log);
}

void bootstrap();

if (module.hot) {
  module.hot.accept();
}
