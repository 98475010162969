import { logError } from ':cloud/init/bugsnag/logging';

const featuresKey = 'featureFlags';

export function setFeatureFlagsInLocalStorage(features) {
  if (features) {
    localStorage.setItem(featuresKey, JSON.stringify(features));
    // Dispatch a StorageEvent so that listeners on the current tab receive an event
    // Why: https://stackoverflow.com/a/65348883
    window.dispatchEvent(new StorageEvent('storage', { key: featuresKey }));
  }
}

// Prefer to use hooks/useIsFeatureEnabled. Only use this function when not inside a React component.
// eslint-disable-next-line import/no-unused-modules
export function isFeatureEnabled(feature: string): boolean {
  const features = localStorage.getItem(featuresKey);
  if (features) {
    try {
      const enabledFeatures: string[] = JSON.parse(features);
      return enabledFeatures.includes(feature);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`Error parsing localStorage key “${featuresKey}”:`, error);
      logError(error, { context: 'feature_flag_hooks' });
    }
  }
  return false;
}
