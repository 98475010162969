import { CodeBlockLanguage } from ':cloud/types/ts_types';
import { CodeBlockCode } from ':cloud/widgets/sharedcomponents/CodeBlock/CodeBlock';

export const generateUIDRequest: CodeBlockCode[] = [
  {
    language: 'javascript' as CodeBlockLanguage,
    code: `
async function getAttestationUID(walletAddress,schemaID) {
  try {
    const attestationUID = await indexerContract.getAttestationUid(walletAddress, schemaID);
    return attestationUID;
  } catch (error) {
    console.error("Error fetching Attestation UID:", error);
  }
}
`,
  },
];

export const generateUIDResponse: CodeBlockCode[] = [
  {
    language: 'javascript' as CodeBlockLanguage,
    code: `UID: 0x9c2108e7683176078b834068c0a8e6539213a56c3c4ae029d999f69840149911`,
  },
];

export const generateAttestiationDataRequest: CodeBlockCode[] = [
  {
    language: 'javascript' as CodeBlockLanguage,
    code: `async function getAttestationData(uid){
  try{
    const EASContractAddress = "0x4200000000000000000000000000000000000021"
    const eas = new EAS(EASContractAddress);
    eas.connect(provider);
    const attestation = await eas.getAttestation(uid);
    return attestation
  } catch(error){
    console.error("Error fetching Metadata: ", error)
  }
}
`,
  },
];

export const generateAttestiationDataResponse: CodeBlockCode[] = [
  {
    language: 'javascript' as CodeBlockLanguage,
    code: `Metadata: Result(10) [
//uid
  '0x9c2108e7683176078b834068c0a8e6539213a56c3c4ae029d999f69840149911',
//schemaID
  '0xf8b05c79f090979bf4a80270aba232dff11a10d9ca55c4f88de95317970f0de9',
//time
  1721434067n,
//expirationTime
  0n,
//revocationTime
  0n,
//refUID
'0x0000000000000000000000000000000000000000000000000000000000000000',
//recipient
  '0x115aBfDa6b101bDC813B90c2780952E89E185F54',
//attester
  '0x357458739F90461b99789350868CD7CF330Dd7EE',
//revocable[?]
  true,
//data
  '0x0000000000000000000000000000000000000000000000000000000000000001'
]`,
  },
];
