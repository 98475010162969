import { useMemo } from 'react';
import { FallbackColor } from '@cbhq/cds-common';
import { Box } from '@cbhq/cds-web/layout';
import { Avatar } from '@cbhq/cds-web/media/Avatar';
import { TextLabel2 } from '@cbhq/cds-web/typography';

type AvatarItemProps = {
  initial: string;
  index?: number;
  avatarUrl?: string;
  avatarColor?: FallbackColor;
};

export function AvatarItem({ initial, avatarUrl, avatarColor, index = 0 }: AvatarItemProps) {
  const dangerousStyle = useMemo(
    () => ({
      left: index * 20,
      backgroundColor: avatarColor,
    }),
    [avatarColor, index],
  );

  return (
    <Box
      borderRadius="roundedFull"
      height={32}
      width={32}
      borderColor="primaryWash"
      bordered
      alignItems="center"
      justifyContent="center"
      position={index ? 'absolute' : undefined}
      style={dangerousStyle}
    >
      {avatarUrl ? (
        <Avatar
          alt={initial}
          src={avatarUrl}
          colorScheme={avatarColor}
          size="l"
          borderColor="primaryWash"
        />
      ) : (
        <TextLabel2 as="p" color="secondary">
          {initial}
        </TextLabel2>
      )}
    </Box>
  );
}
