import { useAppState } from ':cloud/contexts/AppStateContext';

export default function Icon() {
  const { isDarkMode } = useAppState();
  const fill = isDarkMode ? 'white' : '#0A0B0D';

  return (
    <svg
      width="142"
      height="32"
      viewBox="0 0 142 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0301 24C11.6018 24 8.01503 20.42 8.01503 16C8.01503 11.58 11.6018 8 16.0301 8C19.9975 8 23.2903 10.8867 23.9249 14.6667H32C31.3187 6.45333 24.4325 0 16.0301 0C7.18013 0 0 7.16667 0 16C0 24.8333 7.18013 32 16.0301 32C24.4325 32 31.3187 25.5467 32 17.3333H23.9249C23.2903 21.1133 19.9975 24 16.0301 24Z"
        fill={isDarkMode ? 'white' : '#0052FF'}
      />
      <path
        d="M47.3651 0.256772C51.0113 0.256772 52.9285 2.99567 52.9285 6.28235C52.9285 9.58615 51.0284 12.3079 47.3651 12.3079H43V0.256772H47.3651ZM47.3651 1.83164H44.8145V10.7331H47.3651C49.3851 10.7331 51.0626 9.27802 51.0626 6.67607V5.88864C51.0626 3.28668 49.3851 1.83164 47.3651 1.83164Z"
        fill={fill}
      />
      <path
        d="M55.2702 0.256772H63.5383V1.83164H57.0847V5.32374H63.0247V6.86437H57.0847V10.7331H63.5383V12.3079H55.2702V0.256772Z"
        fill={fill}
      />
      <path
        d="M69.067 12.3251L64.5821 0.256772H66.5164L70.0256 10.1853H70.0599L73.5862 0.256772H75.435L71.0527 12.3251H69.067Z"
        fill={fill}
      />
      <path
        d="M77.1192 0.256772H85.3873V1.83164H78.9338V5.32374H84.8737V6.86437H78.9338V10.7331H85.3873V12.3079H77.1192V0.256772Z"
        fill={fill}
      />
      <path d="M87.8348 0.256772H89.6493V10.7331H95.7091V12.3079H87.8348V0.256772Z" fill={fill} />
      <path
        d="M101.907 12.5476C98.2437 12.5476 96.3436 9.7231 96.3436 6.28235C96.3436 2.85873 98.2437 0 101.907 0C105.553 0 107.453 2.85873 107.453 6.28235C107.453 9.7231 105.57 12.5476 101.907 12.5476ZM101.907 10.9727C103.927 10.9727 105.604 9.41497 105.604 6.65895V5.88864C105.604 3.13262 103.927 1.57487 101.907 1.57487C99.8699 1.57487 98.1923 3.13262 98.1923 5.88864V6.65895C98.1923 9.41497 99.8699 10.9727 101.907 10.9727Z"
        fill={fill}
      />
      <path
        d="M109.818 12.3079V0.256772H115.021C117.623 0.256772 119.078 1.71181 119.078 3.83446C119.078 5.95711 117.623 7.42927 115.021 7.42927H111.632V12.3079H109.818ZM117.23 3.93717V3.73175C117.23 2.5506 116.476 1.81452 114.97 1.81452H111.632V5.87152H114.97C116.476 5.87152 117.23 5.13544 117.23 3.93717Z"
        fill={fill}
      />
      <path
        d="M121.085 0.256772H129.353V1.83164H122.899V5.32374H128.839V6.86437H122.899V10.7331H129.353V12.3079H121.085V0.256772Z"
        fill={fill}
      />
      <path
        d="M131.8 12.3079V0.256772H137.021C139.623 0.256772 141.061 1.71181 141.061 3.81734C141.061 5.93999 139.657 7.29232 137.107 7.29232H136.063L141.643 12.3079H139.058L133.615 7.29232V12.3079H131.8ZM139.23 3.95429V3.74887C139.23 2.53348 138.459 1.7974 136.97 1.7974H133.615V5.88864H136.97C138.459 5.88864 139.23 5.15256 139.23 3.95429Z"
        fill={fill}
      />
      <path
        d="M43 31.7603V19.7092H48.2039C50.8059 19.7092 52.2609 21.1642 52.2609 23.2869C52.2609 25.4095 50.8059 26.8817 48.2039 26.8817H44.8145V31.7603H43ZM50.4121 23.3896V23.1842C50.4121 22.003 49.659 21.2669 48.1526 21.2669H44.8145V25.3239H48.1526C49.659 25.3239 50.4121 24.5879 50.4121 23.3896Z"
        fill={fill}
      />
      <path d="M54.2672 19.7092H56.0817V30.1855H62.1415V31.7603H54.2672V19.7092Z" fill={fill} />
      <path
        d="M72.0065 31.7603L70.9281 28.8845H65.5359L64.4746 31.7603H62.5916L67.2135 19.7092H69.2847L73.958 31.7603H72.0065ZM68.1892 21.6435L66.1008 27.3268H70.3461L68.2234 21.6435H68.1892Z"
        fill={fill}
      />
      <path
        d="M76.5633 21.2841H72.5748V19.7092H82.3663V21.2841H78.3778V31.7603H76.5633V21.2841Z"
        fill={fill}
      />
      <path
        d="M84.1236 19.7092H92.289V21.2841H85.9381V24.9816H91.7925V26.5564H85.9381V31.7603H84.1236V19.7092Z"
        fill={fill}
      />
      <path
        d="M99.2322 32C95.569 32 93.6688 29.1755 93.6688 25.7348C93.6688 22.3111 95.569 19.4524 99.2322 19.4524C102.878 19.4524 104.779 22.3111 104.779 25.7348C104.779 29.1755 102.896 32 99.2322 32ZM99.2322 30.4251C101.252 30.4251 102.93 28.8674 102.93 26.1114V25.3411C102.93 22.585 101.252 21.0273 99.2322 21.0273C97.1952 21.0273 95.5176 22.585 95.5176 25.3411V26.1114C95.5176 28.8674 97.1952 30.4251 99.2322 30.4251Z"
        fill={fill}
      />
      <path
        d="M107.143 31.7603V19.7092H112.364C114.966 19.7092 116.404 21.1642 116.404 23.2698C116.404 25.3924 115 26.7447 112.449 26.7447H111.405L116.986 31.7603H114.401L108.957 26.7447V31.7603H107.143ZM114.572 23.4067V23.2013C114.572 21.9859 113.802 21.2498 112.312 21.2498H108.957V25.3411H112.312C113.802 25.3411 114.572 24.605 114.572 23.4067Z"
        fill={fill}
      />
      <path
        d="M118.728 31.7603V19.7092H121.398L124.924 29.0557H124.942L128.434 19.7092H131.087V31.7603H129.324V21.6949H129.307L125.558 31.7775H124.188L120.439 21.7462H120.422V31.7603H118.728Z"
        fill={fill}
      />
    </svg>
  );
}
