import { useMemo } from 'react';

import { useDashboardContext } from ':cloud/contexts/DashboardProvider';

import { TransformedMetricDataPoint } from '../types';
import { formatTickTimestamp } from '../utils';

type XAxisTickProps = {
  x: number;
  y: number;
  payload: {
    value: string;
    index: number;
  };
  data: TransformedMetricDataPoint[];
};

export function XAxisWeekly(props) {
  const { range } = useDashboardContext();
  const { x, y, payload, data } = props as XAxisTickProps;

  // show the tick if it's the first of the day
  const shouldShowTick = useMemo(() => {
    const currentTickDate = new Date(payload.value).getDate();
    const previousTickDate = new Date(data[payload.index - 1]?.time).getDate();

    return currentTickDate !== previousTickDate;
  }, [data, payload.index, payload.value]);

  return shouldShowTick ? (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fill="var(--foreground-muted)">
        {formatTickTimestamp(payload.value, range)}
      </text>
    </g>
  ) : null;
}
