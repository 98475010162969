import { Box as CDSBox } from '@cbhq/cds-web/layout';
import { BoxProps } from '@cbhq/cds-web/layout/Box';

export function BorderedBox({ children, ...props }: BoxProps) {
  return (
    <CDSBox
      flexDirection="column"
      bordered
      borderRadius="roundedSmall"
      borderColor="line"
      {...props}
    >
      {children}
    </CDSBox>
  );
}
