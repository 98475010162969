import { useCallback } from 'react';
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { useDashboardContext } from ':cloud/contexts/DashboardProvider';
import { CHART_COLORS, RANGE } from ':cloud/widgets/monitoring/monitoringConfig';
import { ChartType, TransformedMetricDataPoint } from ':cloud/widgets/monitoring/types';
import { formatTickTimestamp } from ':cloud/widgets/monitoring/utils';

import { defaultYAxisProps, defaultYAxisTickFormatter } from './chartDefaults';
import { MonitoringChartTooltip } from './MonitoringChartTooltip';
import { XAxisWeekly } from './MonitoringXAxisWeekly';

type MonitoringLineChartProps = {
  data: TransformedMetricDataPoint[];
  segments: string[];
  unit?: string;
};

export function MonitoringLineChart({ data, segments, unit }: MonitoringLineChartProps) {
  const { range } = useDashboardContext();

  const xAxisTickFormatter = useCallback(
    (tickItem: string) => {
      return formatTickTimestamp(tickItem, range);
    },
    [range],
  );

  const chartType = 'line' as ChartType;

  return (
    <ResponsiveContainer width="100%" height="100%" key={range}>
      <LineChart data={data}>
        <YAxis tickFormatter={defaultYAxisTickFormatter} {...defaultYAxisProps} />
        <XAxis
          dataKey="time"
          tickFormatter={xAxisTickFormatter}
          tickLine={false}
          tick={range === RANGE.week ? <XAxisWeekly data={data} /> : undefined}
          interval={range === RANGE.week ? 0 : undefined}
        />
        <Tooltip content={<MonitoringChartTooltip chartType={chartType} />} />
        {segments.length > 1 && <Legend />}
        {segments?.map((segment: string, i: number) => (
          <Line
            type="monotone"
            dataKey={segment}
            stroke={CHART_COLORS[i]}
            strokeWidth={2}
            unit={unit}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
}
