import { memo, useMemo } from 'react';
import { CellSpacing } from '@cbhq/cds-common';
import { Pictogram } from '@cbhq/cds-web/illustrations';
import { VStack } from '@cbhq/cds-web/layout';
import {
  Table,
  TableBody,
  TableCell,
  TableCellFallback,
  TableHeader,
  TableRow,
} from '@cbhq/cds-web/tables';
import { TextBody, TextHeadline, TextTitle3 } from '@cbhq/cds-web/typography';

import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { useGetBillingHistoryItems } from ':cloud/queries/BillingQueries/useGetBillingHistoryItems';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { FULL_PAGE_MAX_WIDTH } from ':cloud/widgets/product/constants';
import BillingHistoryItem from ':cloud/widgets/settings/billing/BillingHistoryItem';
import { CloudErrorBoundary, EmptyTable } from ':cloud/widgets/sharedcomponents';
import { ErrorView } from ':cloud/widgets/sharedcomponents/ErrorView';

type BillingHistorySectionLoadingStateProps = {
  isPhone: boolean;
};
function BillingHistorySectionLoadingState({ isPhone }: BillingHistorySectionLoadingStateProps) {
  return (
    <TableRow>
      <TableCellFallback title subtitle />
      <TableCellFallback width={isPhone ? '25%' : '162px'} title />
      <TableCellFallback width={isPhone ? '25%' : '162px'} title />
      <TableCellFallback width={isPhone ? '25%' : '142px'} title />
    </TableRow>
  );
}

function BillingHistorySection() {
  const { activeOrg } = useGetUser();
  const orgId = activeOrg?.organizationId;
  const { billingHistoryItems, isLoading } = useGetBillingHistoryItems(orgId);
  const { isPhone } = useSimpleBreakpoints();

  const billingItems = useMemo(() => {
    if (isLoading) {
      return <BillingHistorySectionLoadingState isPhone={!!isPhone} />;
    }
    if (!billingHistoryItems?.length || !orgId) {
      return (
        <TableCell alignItems="center" justifyContent="center" colSpan={4}>
          <VStack spacing={10} justifyContent="center" alignItems="center" gap={3}>
            <Pictogram name="priceTracking" />
            <TextHeadline as="p">No billing history</TextHeadline>
          </VStack>
        </TableCell>
      );
    }

    return billingHistoryItems.map((item) => {
      return <BillingHistoryItem {...item} orgId={orgId} key={item.id} />;
    });
  }, [billingHistoryItems, orgId, isLoading, isPhone]);

  return <TableBody>{billingItems}</TableBody>;
}
const outerSpacing: CellSpacing = { spacingHorizontal: 0 };

function BillingHistorySectionWrapper() {
  const { isPhone } = useSimpleBreakpoints();
  return (
    <VStack spacing={7} maxWidth={FULL_PAGE_MAX_WIDTH}>
      <TextTitle3 as="h3" spacingBottom={1}>
        Billing History
      </TextTitle3>
      <TextBody as="p" spacingBottom={4} color="foregroundMuted">
        View and download your past invoices, payment history, and account balances.
      </TextBody>
      <Table tableLayout={isPhone ? 'fixed' : 'auto'} compact maxHeight="280px">
        <TableHeader sticky>
          <TableRow>
            <TableCell outerSpacing={outerSpacing} title="Billing period" />
            <TableCell width={isPhone ? '25%' : '162px'} title="Amount" />
            <TableCell width={isPhone ? '25%' : '162px'} title="Status" />
            <TableCell width={isPhone ? '25%' : '142px'} title="Invoice" />
          </TableRow>
        </TableHeader>
        <CloudErrorBoundary
          name="BillingHistorySection"
          fallback={
            <TableBody>
              <EmptyTable>
                <ErrorView />
              </EmptyTable>
            </TableBody>
          }
        >
          <BillingHistorySection />
        </CloudErrorBoundary>
      </Table>
    </VStack>
  );
}

export default memo(BillingHistorySectionWrapper);
