// In order to encourage separation between UI-specific types vs. types defined
// on the backend, this file *should only contain* types that relate to data defined
// on the server.
// Ideally, this file will become obsolete once we are to capture server types
// via protobuf --> TypeScript generator.

import {
  Account,
  AccountCredit,
  AccountItem,
  ActiveMembers,
  BaseNetworkEnum,
  BillingHistoryItem,
  CoinbaseWebhook,
  GasPolicy,
  ICluster,
  IClusterLimits,
  InvitedMembers,
  IPlatformAPIKey,
  IPlatformToken,
  ITradePortfolio,
  LegacyUser,
  Product,
  ProductDetails,
  ProductType,
  ProductTypeCOMS,
  Project,
  ProjectCOMS,
  ProtocolKey,
  User,
} from './ts_types';

export type GetAccountResponse = Account;

export type CancelAccountItemResponse = {
  canceledAt: string;
};

export type GetAccountItemsResponse = {
  accountItems: AccountItem[];
  nextPageToken: string;
};

export type GetBillingHistoryItemsResponse = {
  accountInvoices: BillingHistoryItem[];
};

export type DownloadInvoiceResponse = {
  downloadUri: string;
};

export type CreateAccountItemRequest = {
  productVariantId: string;
  projectId: string;
};

export type CreateAccountItemResponse = AccountItem;

export type ChangeAccountItemRequest = {
  productVariantId: string;
  itemId: string;
};

export type ChangeAccountItemResponse = AccountItem;

export type GetAccountCreditsResponse = {
  accountCredits: AccountCredit[];
  nextPageToken: string;
};

export type CreateCommerceChargeResponse = {
  hostedUrl: string;
};

export type GetProductsResponse = { products: Product[] };

// TODO: Migrate project to new COMS Read repsonse shape
export type GetProjectsResponse = Project[];

export type CreateProjectRequest = {
  name?: string;
  organizationId: string;
  products?: ProductType[];
  baseNetwork?: BaseNetworkEnum;
};

export type DeleteProjectRequest = {
  organizationId: string;
  projectId: string;
};

export type UpdateProjectRequest = Pick<ProjectCOMS, 'id' | 'name' | 'organizationId'>;

export type CreateOrUpdateProjectResponse = Project;

export type CreatePlatformAPIKeyResponse = IPlatformAPIKey;

export type CreatePlatformTokenResponse = IPlatformToken;

export type CreateWebhookResponse = CoinbaseWebhook;

export type DeleteWebhookRequest = {
  orgId: string;
  projectId: string;
  webhookId: string;
};

export type DeleteWebhookResponse = {
  success: boolean;
};

export type GetWebhooksResponse = {
  webhooks: (CoinbaseWebhook & { id: string })[];
  nextPageToken: string;
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DeletePlatformAPIKeyResponse {}

export interface DeletePlatformAPIKeyRequest {
  name: string;
}

export interface GetTradePortfoliosResponse {
  portfolios: ITradePortfolio[];
}

export interface GetPlatformAPIKeyResponse {
  apiKeys: IPlatformAPIKey[];
  nextPageToken: string;
}

export interface GetPlatformTokenResponse {
  tokens: IPlatformToken[];
  nextPageToken: string;
}

type PaymentType = 'card' | 'sepa_debit' | 'us_bank_account';

export interface GetPaymentSetupSecretResponse {
  type: PaymentType;
  paymentSecret: string;
}

export type PaymentCard = {
  cardholder: string;
  network: 'visa' | 'mastercard' | 'amex' | string;
  lastFour: string;
  expirationMonth: number;
  expirationYear: number;
};

export type PaymentUsBankAccount = {
  bank: string;
  lastFour: string;
};

export type PaymentSepaDebit = {
  bankCode: string;
  branchCode: string;
  country: string;
  lastFour: string;
};

interface PaymentMethodDetails {
  type: PaymentType;
  /**
   * @deprecated This is a legacy field to maintain backwards compatibility. Use the `creditCard` field on `PaymentMethodCard` instead.
   */
  card?: PaymentCard;
  externalId: string;
}

interface PaymentMethodCard {
  type: 'card';
  creditCard: PaymentCard;
}
interface PaymentMethodUsBankAccount {
  type: 'us_bank_account';
  usBankAccount: PaymentUsBankAccount;
}

interface PaymentMethodSepaDebit {
  type: 'sepa_debit';
  sepaDebit: PaymentSepaDebit;
}

export type PaymentMethod = PaymentMethodDetails &
  (PaymentMethodCard | PaymentMethodSepaDebit | PaymentMethodUsBankAccount);

export type GetPaymentMethodResponse = PaymentMethod;

export type DeletePaymentMethodResponse = {
  type?: PaymentType;
  card?: PaymentCard;
  id: string;
};

/** Organization IAM Request Response Codes */

export type InviteMemberResponse = {
  success: boolean;
};
export type RemoveMemberResponse = {
  success: boolean;
};
export type SwitchMemberResponse = {
  success: boolean;
};

// @zack.wilder comment back once consuming COMS response
// export type GetOrganizationInvitationResponse = { invites: OrganizationInvite[] };
export interface OrganizationInvite {
  id: string;
  organization: {
    id: string;
    name: string;
  };
  email: string;
  inviteToken: string;
  role: string;
  accepted: boolean;
  hasRetrieved: boolean;
  invitedByUser: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
  };
  createdAt: Date;
  updatedAt: Date;
}

export type GetOrganizationsResponse = GetOrganization[];

export interface GetOrganization {
  userId: string;
  organizationId: string;
  role: string;
  created: string;
  organizationDetails: OrganizationDetails;
}

export interface OrganizationDetails {
  users: ActiveMembers[];
  invites: InvitedMembers[];
  name: string;
  tagTypeId: string;
  billingId: string;
}

/** Resource Request Response Codes */
export type GetResourceCustomerLimitsResponse = IClusterLimits[];

export type DeleteResourceRequest = {
  name: string;
  type: string;
};

export type GetResourceResponse = {
  resources: ICluster[];
};

export type CreateResourceResponse = {
  id: string;
  name: string;
  type: ProtocolKey;
};

/** Self */ export type GetUserResponse = User & {
  legacyResponse: LegacyUser;
};

export type GasPolicyResponse = {
  policy: GasPolicy;
};

export type PaymasterBalanceResponse = {
  remainingCredits: number;
};

/* Product Details */
export type GetProductDetailsResponse = {
  productDetails: ProductDetails;
};

export type CreateOrUpdateProductDetailsRequest = {
  project: Project;
  productType: ProductTypeCOMS;
  productDetails: ProductDetails;
  payload?: Record<string, any>;
};

export type CreateOrUpdateProductDetailsResponse = ProductDetails;

type CreateClientFormData = Pick<
  ClientRecordNonSensitive,
  | 'name'
  | 'owner'
  | 'redirectUris'
  | 'appType'
  | 'servicesOffered'
  | 'description'
  | 'notificationsUrl'
  | 'developerWebsite'
  | 'icon'
>;
export interface CreateOAuthClientRequest {
  parent: string;
  clientRecordNonSensitive: CreateClientFormData;
}

export interface CreateOAuthClientResponse {
  clientInfo: ClientRecordNonSensitive;
  clientRecordSensitive: ClientRecordSensitive;
  fragment: ClientFragment;
}

export interface GetOAuthClientResponse {
  oauthClients: {
    clientRecordNonSensitive: ClientRecordNonSensitive;
    clientRecordSensitive: ClientRecordSensitive;
    clientFragment: ClientFragment;
  }[];
}

export interface UpdateOAuthClientRequest {
  clientRecord: { name: string; clientRecordNonSensitive: ClientRecordNonSensitive };
}

export interface UpdateOAuthClientResponse {
  clientRecordNonSensitive: ClientRecordNonSensitive;
  clientFragment: ClientFragment;
  clientRecordSensitive: ClientRecordSensitive;
  name: string;
}

export interface DeleteOAuthClientResponse {
  deleteType: ClientDeleteType;
  clientId: string;
}

// Supporting Types
export interface ClientRecordNonSensitive {
  disabled: boolean;
  clientId: string;
  name: string;
  owner: string;
  redirectUris: string;
  grantTypes: string;
  responseTypes: string;
  scope: string;
  audience: string;
  termsOfServiceUri: string;
  clientUri: string;
  logoUri: string;
  tokenEndpointAuthMethod: string;
  createdAt: string;
  updatedAt: string;
  metadata: any;
  appType: ApplicationType;
  servicesOffered: ServicesOffered;
  description: string;
  notificationsUrl: string;
  developerName: string;
  developerWebsite: string;
  icon: string; // Base64 encoded string
}

export interface ClientRecordSensitive {
  clientId: string;
  clientSecret: string;
  secretExpiresAt: string;
}

enum ClientFragment {
  CLIENT_RECORD_INFO = 0,
  CLIENT_RECORD_WITH_SENSITIVE_INFO = 1,
  CLIENT_PROFILE_INFO = 2,
}

export enum ClientDeleteType {
  SOFT_DELETE = 0,
  HARD_DELETE = 1,
}

export enum ApplicationType {
  OTHER = '0',
  ECOMMERCE = '1',
  MICROPAYMENTS = '2',
  BITCOIN_WALLET = '3',
  GAMING = '4',
  FINANCIAL_SERVICES = '5',
}

export interface ServicesOffered {
  remittances: boolean;
  financialServices: boolean;
  gambling: boolean;
  currencyExchange: boolean;
}
