import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { APIClientCOMSLegacy } from ':cloud/state/Api';

export const errorMsg = 'Failed to invite. Please retry';

async function acceptInvite(organizationId: string, email: string): Promise<boolean> {
  const path = `/organization-management/v1/organizations/${organizationId}/invite/accept`;
  const { data } = await APIClientCOMSLegacy.post<boolean>(path, { email });
  return data;
}

export function useAcceptInvite() {
  const queryClient = useQueryClient();
  const { user } = useGetUser();

  return useMutation<boolean, Error, string>({
    mutationFn: async (organizationId) => acceptInvite(organizationId, user.email),
    meta: { errorMsg },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKey.self, 'invites']);
      /* org swicher needs to show newly added org */
      await queryClient.invalidateQueries([QueryKey.organizations]);
    },
  });
}
