import { css } from '@linaria/core';
import { format as formatDate } from 'date-fns';
import _has from 'lodash/has';
import { ResponsiveProps } from '@cbhq/cds-common';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { Tooltip } from '@cbhq/cds-web/overlays/Tooltip/Tooltip';
import { TextLabel2, TextLegal, TextTitle1 } from '@cbhq/cds-web/typography';

import { useIsDeveloperMode } from ':cloud/hooks/useIsDeveloperMode';
import { useSimpleBreakpoints } from ':cloud/hooks/useSimpleBreakpoints';
import { useGetProtocolDisplayName } from ':cloud/queries/WorkflowQueries/useGetProtocolDisplayName';
import { ICluster, MetricRegionKey } from ':cloud/types/ts_types';
import { getProviderIconSrc } from ':cloud/utils/assets';
import { getClusterRegion, getClusterStatus, isDeployedByPipelines } from ':cloud/utils/clusters';
import { Layout } from ':cloud/widgets/layout';
import { ProtocolIcon, StatusIndicator } from ':cloud/widgets/sharedcomponents';

const clusterNameStyles = css`
  max-width: -webkit-fill-available;
`;

const regionInfoWrapperResponsiveConfig: ResponsiveProps = {
  phone: {
    justifyContent: 'flex-start',
  },
  tablet: {
    justifyContent: 'flex-end',
  },
  desktop: {
    justifyContent: 'flex-end',
  },
};

interface ClusterTopProps {
  cluster: ICluster;
  region?: string;
}

export function ClusterTop({ cluster, region }: ClusterTopProps) {
  const protocolKey = cluster.type;
  const { isDeveloperMode } = useIsDeveloperMode();
  const { displayName } = useGetProtocolDisplayName(cluster.type);
  const clusterStatus = getClusterStatus(cluster.state);
  const { isPhone } = useSimpleBreakpoints();

  return (
    <Layout>
      <Layout.MainContainer fullWidth>
        <VStack gap={2} spacingBottom={3}>
          <HStack spacing={1} borderRadius="roundedSmall" bordered maxWidth="fit-content" gap={0.5}>
            <StatusIndicator status={clusterStatus} />
            <TextLabel2 as="span">
              {`${displayName} ${clusterStatus.status}`}
              {_has(cluster, 'artifacts.version') && ` ${cluster.artifacts.version}`}
              {_has(cluster, 'artifacts.network') && ` ON ${cluster.artifacts.network}`}
            </TextLabel2>
          </HStack>
          {isDeveloperMode && (
            <Box bordered borderRadius="roundedSmall" spacing={1} maxWidth="fit-content">
              <TextLabel2 as="span" dangerouslySetColor="rgb(var(--purple60))">
                Workflow Version: {_has(cluster, 'version') && ` ${cluster.version}`}
              </TextLabel2>
              {isDeployedByPipelines(cluster) && (
                <TextLabel2 as="span" dangerouslySetColor="rgb(var(--purple60))">
                  Rendering PR Version
                </TextLabel2>
              )}
            </Box>
          )}
        </VStack>
      </Layout.MainContainer>
      <Layout.MainContainer fullWidth>
        <HStack justifyContent="space-between" flexWrap="wrap">
          <HStack gap={3} spacingBottom={1}>
            <ProtocolIcon protocolKey={protocolKey} size={36} />
            <Box justifyContent="center" className={clusterNameStyles}>
              {isPhone ? (
                <Tooltip content={cluster.name}>
                  <TextTitle1 as="h1" noWrap>
                    {cluster.name}
                  </TextTitle1>
                </Tooltip>
              ) : (
                <TextTitle1 as="h1" noWrap={false}>
                  {cluster.name}
                </TextTitle1>
              )}
            </Box>
          </HStack>

          {region && (
            <HStack
              gap={1}
              alignItems="center"
              responsiveConfig={regionInfoWrapperResponsiveConfig}
            >
              <VStack alignItems="flex-end">
                <TextLegal as="p">{getClusterRegion(region as MetricRegionKey).display}</TextLegal>
                <TextLegal as="p">
                  {formatDate(new Date(cluster.created), "'Created 'P' at 'p")}
                </TextLegal>
              </VStack>
              <Box width={36} height={36} bordered borderRadius="roundedSmall" spacing={0.5}>
                <img src={getProviderIconSrc(region)} alt={region} />
              </Box>
            </HStack>
          )}
        </HStack>
      </Layout.MainContainer>
    </Layout>
  );
}
