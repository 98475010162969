import { useMemo } from 'react';
import {
  HeroSquareName,
  PictogramName,
  SpotIconName,
  SpotSquareName,
} from '@cbhq/cds-illustrations';
import { Button } from '@cbhq/cds-web/buttons';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { HeroSquare, Pictogram, SpotIcon, SpotSquare } from '@cbhq/cds-web/illustrations';
import { Grid, VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextTitle3 } from '@cbhq/cds-web/typography';

import { FULL_PAGE_MAX_WIDTH } from './constants';

function getIconComponent(
  iconType: KeyFeatureItem['iconType'],
  iconName: PictogramName | HeroSquareName | SpotSquareName | SpotIconName,
) {
  if (iconType === 'pictogram') {
    return <Pictogram name={iconName as PictogramName} dimension="48x48" />;
  }
  if (iconType === 'heroSquare') {
    return (
      <HeroSquare name={iconName as HeroSquareName} dimension="200x200" scaleMultiplier={0.24} />
    );
  }
  if (iconType === 'spotIcon') {
    return <SpotIcon name={iconName as SpotIconName} dimension="24x24" scaleMultiplier={2} />;
  }
  return <SpotSquare name={iconName as SpotSquareName} scaleMultiplier={0.5} />;
}

export type KeyFeatureItem = {
  iconName: PictogramName | HeroSquareName | SpotSquareName | SpotIconName;
  title: string;
  description: string | JSX.Element;
  cta?: string;
  onPress?: () => void;
  iconType?: 'pictogram' | 'heroSquare' | 'spotSquare' | 'spotIcon';
};

function KeyFeatureSectionItem({
  iconName,
  title,
  description,
  cta,
  onPress,
  iconType,
}: KeyFeatureItem) {
  return (
    <VStack
      alignItems="flex-start"
      background="backgroundAlternate"
      spacingVertical={5}
      spacingStart={3}
      spacingEnd={5}
      borderRadius="rounded"
    >
      <VStack spacingStart={2}>
        {getIconComponent(iconType, iconName)}
        <TextTitle3 as="h3" spacingTop={3} spacingBottom={1}>
          {title}
        </TextTitle3>
        <TextBody as="p" color="foregroundMuted" spacingBottom={1}>
          {description}
        </TextBody>
      </VStack>
      {cta && onPress && (
        <Button
          compact
          variant="primary"
          transparent
          endIcon="diagonalUpArrow"
          accessibilityLabel={`${cta} + opens in new window`}
          onPress={onPress}
        >
          {cta}
        </Button>
      )}
    </VStack>
  );
}

type KeyFeaturesSectionProps = {
  features: KeyFeatureItem[];
  borderedTop?: boolean;
};

export function KeyFeaturesSection({ features, borderedTop = false }: KeyFeaturesSectionProps) {
  const { isDesktop } = useBreakpoints();
  const responsiveTemplateColumns = useMemo(() => {
    const desktopColumns = Array.from({ length: features.length }, () => '1fr').join(' ');
    return isDesktop ? desktopColumns : '1fr';
  }, [features.length, isDesktop]);

  return (
    <VStack alignItems="flex-start" borderedTop={borderedTop}>
      <VStack gap={9} maxWidth={FULL_PAGE_MAX_WIDTH} spacing={9} width="100%">
        <TextTitle3 as="h3">Key features</TextTitle3>
        <Grid templateColumns={responsiveTemplateColumns} gap={4}>
          {features.map(
            ({ iconName, title, description, cta, onPress, iconType = 'pictogram' }) => {
              return (
                <KeyFeatureSectionItem
                  key={title}
                  iconName={iconName}
                  title={title}
                  description={description}
                  cta={cta}
                  onPress={onPress}
                  iconType={iconType}
                />
              );
            },
          )}
        </Grid>
      </VStack>
    </VStack>
  );
}
