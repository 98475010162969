import config from ':cloud/config';
import useHasExperiment from ':cloud/hooks/useHasExperiment';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';

// reach out to Rishi Patel or Aaron Owusu w/ questions
const ALLOWLISTED_ORGS_FOR_PARTICIPATE = [
  'f85610ed-1b02-4f15-8974-0401e3243e35',
  'fa72b571-a345-4808-9493-ae7220950093',
  'ff85c6fe-a73a-461b-a70d-248f08ea38a0',
  'ff9c10b9-037c-4e58-bdd5-7988a60cd7b2',
  '2c02052f-686a-463e-9549-8d3b8efbac9b',
  '2e94f6b2-10f0-4917-843c-f0e5c68c2dec',
  '3365854f-da03-4b58-b6ad-c469571be49c',
  '39087756-eff2-4afd-be5b-c37b1dfdcc7a',
  '3a5fa611-0025-4c58-9360-0cc215938105',
  '3e3841e6-fbe7-4c60-849f-c5903090871e',
  'e0d45f17-8f99-4c63-bda5-cb2214e72d7c',
  'e2f8aa53-59a2-4ba2-b955-5b825ed0f642',
  'e5173eba-bb18-4454-837e-ab3ee4a88b24',
  'e590700b-1dcd-4c23-a522-b56e31ac5614',
  'f37fdee3-2853-4529-bfa2-fa605a931199',
  '82a449d7-854c-44a0-a2fd-6a8e6bc7a607',
  '83219e62-8b69-47ee-b121-41d614b4c511',
  '891b9542-660f-4676-bb8f-1223d6a92bfb',
  '89d68f94-8efc-5064-95a8-43da7c5e4f14',
  '8d6d2417-9cef-473e-8ec6-d9800f84cb71',
  '8ec0673a-8a99-4f30-805d-4b598faf832a',
  '909fc775-8c10-42be-9d42-d6821a24fd61',
  '93bfeec3-1903-4f1b-919a-9bbcebb59f51',
  '995efa34-31ed-4f85-8556-a16084475913',
  'f455fc53-8113-4e97-a133-3b31ec9780f3',
  '01ee7b9e-dcc4-42fb-a186-b7acfb992423',
  '04401fab-26b2-4d00-8fa5-98585154f900',
  '07ca577f-0837-4f8c-9bb8-417749a4aca2',
  '084ec2c9-e845-4d1e-bb96-b67ff3ecedc9',
  '0962a6ef-a6ec-4dd1-9175-5c7468464362',
  '0c61b1a6-269d-4acb-9d19-9df265a9a7a7',
  '53e8102f-8ac0-40ba-8058-a066067785c9',
  '549dc442-43dc-490d-814f-8a3961a4ea2f',
  '0ff471f3-4383-4311-804a-66d337d4eac8',
  '113fb713-c065-4fe5-9a8b-ce40dedf20b4',
  '56f640ac-a971-4b20-a966-06738bfa8ecb',
  '5ce9bd0b-65da-4d46-aae8-c56ee8d9cb59',
  '16b046b4-437f-444f-963b-4c2487625b95',
  '17680d2d-eae3-4125-9ae2-4fbf70ba958a',
  '18366151-dfbc-4b12-bc58-751f3efecc63',
  '1abac554-ceb0-4b7d-98c4-afc560975cf1',
  '1ac7c04f-f660-4d4e-8935-a2989faf2571',
  '1b318628-7014-4be8-b0ca-710d8be3745b',
  '1eca9655-2be6-4de4-8883-ae297840c9a7',
  '1f2caa74-51d0-5106-8854-28835c779083',
  '63d57993-abb3-4cd7-9374-1fec737cd0f9',
  '1fc40b5f-d4e8-431d-bd77-bc91c3465de6',
  '642b7fbb-433f-4b8d-80a2-43db8634b9cd',
  '66a38c78-0bad-41cb-9962-63d937c5d861',
  '6754db2a-839d-400c-9b29-5cf86f810716',
  '69b1b02b-c3a1-4cf2-8c1f-2a30bd954c01',
  '7540009b-770f-4d1f-a930-c057ad8733c6',
  '27542001-db08-443a-874d-d9101b13d434',
  'a6be9695-e295-49da-8528-44df78abcec8',
  'af669814-e345-4d85-998c-c4fd4ca35d13',
  'b0e54b22-950d-4217-aba9-ad6a705bf7b5',
  'b48fcc87-9bc1-46bb-aacb-d4bd096f3c71',
  'b7366124-d642-46eb-bd42-998327bc8bab',
  'b7f0123e-ebb2-42dd-be7a-03c46b9b73cb',
  'bbf10ff1-23a3-4d33-8501-b2818929c299',
  'be509b7a-c113-4d77-8670-45d4aade8f0c',
  'c1701918-de14-405f-8814-fbd4cd37cf28',
  'c48c19ff-b304-4d2e-8915-7704b46b5e24',
  'c61832dc-a878-4f7b-9443-dc09084edf5e',
  '5257ff04-4191-5334-8a84-6cf9a1ccb524',
];

export function useIsOnParticipateAllowlist() {
  const { activeOrg } = useGetUser();
  const isAllowlistedOrg = ALLOWLISTED_ORGS_FOR_PARTICIPATE.includes(
    activeOrg?.organizationId || '',
  );

  return (
    useHasExperiment('mar_2024_participate_allowlist', 'treatment') ||
    isAllowlistedOrg ||
    !config.isProduction()
  );
}
