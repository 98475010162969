import _get from 'lodash/get';
import { defaultPalette } from '@cbhq/cds-common/palette/constants';
import { paletteAliasToRgbaString } from '@cbhq/cds-common/palette/paletteAliasToRgbaString';
import { palette } from '@cbhq/cds-web/tokens';

export type CDSAlias = keyof typeof defaultPalette;
export type CDSSpectrum = 'light' | 'dark';

export function cdsAliasToRgba(alias: CDSAlias, spectrum: CDSSpectrum = 'light'): string {
  return paletteAliasToRgbaString(alias, spectrum);
}

export function cdsAliasToCssVar(alias: CDSAlias): string {
  const paletteValue = defaultPalette[alias];
  if (typeof paletteValue === 'string') {
    return `rgb(var(--${paletteValue}))`;
  }
  const [value, alpha] = paletteValue;
  return `rgba(var(--${value}), ${alpha})`;
}

/** For use with Stripe Elements API. It only accepts hex & rgb. */
export function rgbaToHexA(rgba: string, forceRemoveAlpha = false) {
  return `#${rgba
    .replace(/^rgba?\(|\s+|\)$/g, '') // Get rgba / rgb string values
    .split(',') // splits them at ","
    .filter((_, index) => !forceRemoveAlpha || index !== 3)
    .map((string) => parseFloat(string)) // Converts them to numbers
    .map((number, index) => (index === 3 ? Math.round(number * 255) : number)) // Converts alpha to 255 number
    .map((number) => number.toString(16)) // Converts numbers to hex
    .map((string) => (string.length === 1 ? `0${string}` : string)) // Adds 0 when length of one number is 1
    .join('')}`; // Puts the array to together to a string
}

type MUItoCDSVariantType = {
  palette: Record<
    string,
    {
      main: CDSAlias;
      contrastText: CDSAlias;
    }
  >;
};

// For use with participate metrics
const MUItoCDSVariantMap: MUItoCDSVariantType = {
  palette: {
    primary: {
      main: 'primary',
      contrastText: 'primaryForeground',
    },
    secondary: {
      main: 'secondary',
      contrastText: 'secondaryForeground',
    },
    error: {
      main: 'negative',
      contrastText: 'negativeForeground',
    },
    warning: {
      main: 'primary',
      contrastText: 'primaryForeground',
    },
    success: {
      main: 'positive',
      contrastText: 'positiveForeground',
    },
    info: {
      main: 'primary',
      contrastText: 'primaryForeground',
    },
    progress: {
      main: 'primary',
      contrastText: 'primaryForeground',
    },
  },
};

function isValidVariant(variant: any): variant is CDSAlias {
  return typeof variant === 'string' && variant in palette;
}

export function getCDSVariantFromWorkflow(workflowColorPath: string): CDSAlias {
  const variant = _get<MUItoCDSVariantType, string>(MUItoCDSVariantMap, workflowColorPath);
  return isValidVariant(variant) ? variant : 'foregroundMuted';
}

// CDS colors
const cdsLine = 'var(--line)';
const gray80 = '#32353D';
const gray90 = '#1E2025';
const green10 = '#A3EBCD';
const green20 = '#65D6A7';
const pink20 = '#F4B2F0';
const pink60 = '#B33AA2';
const orange15 = '#FBC293';
const red15 = '#FBBABF';
const red40 = '#ED5966';
const teal15 = '#5DE2F8';
const yellow30 = '#E7C95B';

export const colors = {
  cdsLine,
  green10,
  green20,
  gray80,
  gray90,
  orange15,
  pink20,
  pink60,
  red15,
  red40,
  teal15,
  yellow30,
};
