import { useMemo, useState } from 'react';
import { Button, ButtonGroup } from '@cbhq/cds-web/buttons';
import { SelectOption } from '@cbhq/cds-web/controls';
import { Dropdown } from '@cbhq/cds-web/dropdown';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextDisplay3, TextTitle3, TextTitle4 } from '@cbhq/cds-web/typography';

import { PaymasterProvider } from ':cloud/contexts/PaymasterProvider';
import { useIsBasePlaygroundEnabled } from ':cloud/hooks/featureFlags/useIsBasePlaygroundEnabled';
import { EventName, useLogViewOnMount } from ':cloud/init/clientAnalytics/logging';
import { INDEXERS_DISCORD_LINK, INDEXERS_DOCS_LINK } from ':cloud/utils/links';
import { onExternalLinkPress } from ':cloud/utils/onExternalLinkPress';
import Playground from ':cloud/widgets/base/PlaygroundV2';
import { RPCEndpointSection } from ':cloud/widgets/base/RPCEndpointSection';
import { Layout } from ':cloud/widgets/layout';
import { ConfigurationItem } from ':cloud/widgets/product/ConfigurationItem';
import { baseConfig } from ':cloud/widgets/product/configurations/baseConfig';
import { FULL_PAGE_MAX_WIDTH, MAIN_CONTAINER_MIN_WIDTH } from ':cloud/widgets/product/constants';
import { KeyFeatureItem, KeyFeaturesSection } from ':cloud/widgets/product/KeyFeaturesSection';

// this prevents odd text breakpoints (one word in a sentence on a line by itself)
const HEADER_TEXT_MAX_WIDTH = 950;

const keyFeatures: KeyFeatureItem[] = [
  {
    iconName: 'browserTransaction',
    title: 'Transaction API',
    description: 'List transactions for an address.',
    iconType: 'pictogram',
  },
  {
    iconName: 'bank',
    title: 'Balance API',
    description: 'Get the latest balances for an address.',
    iconType: 'spotIcon',
  },
  {
    iconName: 'tradeHistory',
    title: 'Historic data points',
    description: 'Get the balance histories for an asset of an address.',
    iconType: 'heroSquare',
  },
];

const walletHistoryAPIMethods: { label: string; value: string }[] = [
  { label: 'List Balances', value: 'cdp_listBalances' },
  { label: 'List Balance Details', value: 'cdp_listBalanceDetails' },
  { label: 'List Balance Histories', value: 'cdp_listBalanceHistories' },
  { label: 'List Address Transactions', value: 'cdp_listAddressTransactions' },
];

export function Indexers() {
  const isBasePlaygroundEnabled = useIsBasePlaygroundEnabled();

  const [selectedNetwork, setSelectedNetwork] = useState(
    baseConfig?.networkOptions?.[0].label || '',
  );

  const [selectedMethod, setSelectedMethod] = useState(walletHistoryAPIMethods?.[0].value || '');

  const content = useMemo(() => {
    return baseConfig?.networkOptions?.map((option) => (
      <SelectOption key={option.value} value={option.label} title={option.label} />
    ));
  }, []);

  const methodsDropdownContent = useMemo(() => {
    return walletHistoryAPIMethods.map((option) => (
      <SelectOption key={option.value} value={option.value} title={option.label} />
    ));
  }, []);

  useLogViewOnMount(EventName.indexers_page_view);

  const selectedMethodLabel = useMemo(() => {
    return walletHistoryAPIMethods.find((method) => method.value === selectedMethod)?.label || '';
  }, [selectedMethod]);

  return (
    <PaymasterProvider>
      <Layout enableMaxWidth={false}>
        <Layout.MainContainer
          minWidth={MAIN_CONTAINER_MIN_WIDTH}
          spacingHorizontal={0}
          spacingTop={0}
          fullWidth
        >
          <Box display="block" width="100%" minWidth="100%">
            <VStack>
              <HStack borderedBottom>
                <VStack justifyContent="center" spacing={9} maxWidth={HEADER_TEXT_MAX_WIDTH}>
                  <TextDisplay3 as="h1" spacingBottom={3}>
                    Access Onchain Data{' '}
                    <TextDisplay3 as="span" color="primary">
                      for Free
                    </TextDisplay3>
                  </TextDisplay3>
                  <TextTitle4 as="h2" color="foregroundMuted" spacingTop={1} spacingBottom={7}>
                    Easily let your users see their wallet balances, balance history, and
                    transaction history directly within your app. API access is{' '}
                    <TextTitle4 as="span" color="positive">
                      free
                    </TextTitle4>
                    , and{' '}
                    <TextTitle4 as="span" color="positive">
                      rate limited at 50 RPS
                    </TextTitle4>
                    . To request an increase in rate limits, reach out in Discord.
                  </TextTitle4>

                  <ButtonGroup>
                    <Button
                      variant="primary"
                      endIcon="diagonalUpArrow"
                      onPress={onExternalLinkPress(INDEXERS_DOCS_LINK)}
                      accessibilityLabel="View docs + opens in new window"
                    >
                      View docs
                    </Button>
                    <Button
                      variant="secondary"
                      endIcon="diagonalUpArrow"
                      onPress={onExternalLinkPress(INDEXERS_DISCORD_LINK)}
                      accessibilityLabel="Discord channel + opens in new window"
                    >
                      Discord channel
                    </Button>
                  </ButtonGroup>
                </VStack>
              </HStack>
              <VStack>
                <VStack maxWidth={FULL_PAGE_MAX_WIDTH} flexGrow={1} width="100%">
                  <VStack spacing={10} gap={5}>
                    <HStack gap={2} alignItems="center" justifyContent="space-between">
                      <TextTitle3 as="h1">Configuration</TextTitle3>
                      <Dropdown
                        value={selectedNetwork}
                        onChange={setSelectedNetwork}
                        content={content}
                        enableMobileModal
                      >
                        <Button compact variant="secondary" endIcon="caretDown">
                          {selectedNetwork}
                        </Button>
                      </Dropdown>
                    </HStack>
                    <RPCEndpointSection selectedNetwork={selectedNetwork} title="RPC Endpoint" />
                    <HStack
                      gap={2}
                      alignItems="center"
                      justifyContent="space-between"
                      spacingTop={6}
                    >
                      <TextTitle3 as="h1">Playground</TextTitle3>
                      <Dropdown
                        value={selectedMethod}
                        onChange={setSelectedMethod}
                        content={methodsDropdownContent}
                        enableMobileModal
                      >
                        <Button compact variant="secondary" endIcon="caretDown">
                          {selectedMethodLabel}
                        </Button>
                      </Dropdown>
                    </HStack>
                    <ConfigurationItem
                      title="Make a request"
                      description="Try the request below or copy it into your terminal to run your first request."
                      iconName="arrowsHorizontal"
                      expandedContent={
                        <Playground
                          method={selectedMethod}
                          isTestnet={selectedNetwork !== baseConfig?.networkOptions?.[0].label}
                        />
                      }
                    />
                  </VStack>
                </VStack>
              </VStack>
              <KeyFeaturesSection features={keyFeatures} borderedTop={isBasePlaygroundEnabled} />
            </VStack>
          </Box>
        </Layout.MainContainer>
      </Layout>
    </PaymasterProvider>
  );
}
