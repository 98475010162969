import { PlatformName } from '@cbhq/client-analytics';
import { initClientKillSwitch } from '@cbhq/client-kill-switch';

import config from ':cloud/config';
import { logError } from ':cloud/init/bugsnag/logging';

/**
 * NB: The kill switch service only returns the kill switches that have
 * a value of `true`, not those which are `false`. Therefore if we set a `true`
 * default value here we'd never be able to turn off the switch.
 */
const defaultKillSwitchConfig = {
  // eslint-disable-next-line camelcase
  cloud_console_test_dev: false,
};

export const serviceUrl = `${config.apiBaseURL}/v3/coinbase.killswitch.KillSwitchService/KillSwitches`;

const initConfig = {
  serviceUrl,
  disabled: false,
  platform: PlatformName.web,
  scope: config.appAnalyticsName,
  version: config.appVersion,
};

export function initKillSwitch() {
  try {
    void initClientKillSwitch(defaultKillSwitchConfig, initConfig);
  } catch (error) {
    logError(error, { context: 'kill_switch_init' });
  }
}
