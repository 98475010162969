import { Link as RouterLink } from 'react-router-dom';
import { Banner } from '@cbhq/cds-web/banner/Banner';
import { VStack } from '@cbhq/cds-web/layout';
import { Link } from '@cbhq/cds-web/typography';

import { AppRoute } from ':cloud/utils/routes';
import { FULL_PAGE_MAX_WIDTH } from ':cloud/widgets/product/constants';

type ExchangeLandingBannersProps = {
  exchangeEnabled: boolean;
  hasPaymentMethod: boolean;
  orgHasMultipleUsers: boolean;
};

export function ExchangeLandingBanners({
  exchangeEnabled,
  hasPaymentMethod,
  orgHasMultipleUsers,
}: ExchangeLandingBannersProps) {
  return (
    <VStack
      flexGrow={1}
      width="100%"
      maxWidth={FULL_PAGE_MAX_WIDTH}
      spacingHorizontal={9}
      spacingBottom={0}
      gap={1}
    >
      {!exchangeEnabled && (
        <Banner
          title="Looking for Advanced Trade?"
          variant="promotional"
          startIcon="info"
          primaryAction={
            <RouterLink to={AppRoute.Products.AdvancedTrade}>
              <Link variant="headline" underline={false}>
                Go to Advanced Trade
              </Link>
            </RouterLink>
          }
        >
          You aren&apos;t provisioned for Exchange, our direct access trading product for
          Institutions.
        </Banner>
      )}
      {exchangeEnabled && !hasPaymentMethod && (
        <Banner
          title="Add a payment method to upgrade"
          variant="promotional"
          startIcon="info"
          primaryAction={
            <RouterLink to={AppRoute.Billing.Home}>
              <Link variant="headline" underline={false}>
                Add a payment method
              </Link>
            </RouterLink>
          }
        >
          Before upgrading your Market Data subscription, add a preferred payment method.
        </Banner>
      )}
      {exchangeEnabled && orgHasMultipleUsers && (
        <Banner
          title="Your organization has too many users"
          variant="warning"
          startIcon="warning"
          // primaryAction={
          //   <RouterLink to={AppRoute.Team.Home}>
          //     <Link variant="headline" underline={false}>
          //       Remove users
          //     </Link>
          //   </RouterLink>
          // }
        >
          CDP organizations cannot have additional participants in order to be eligible for a paid
          Market Data subscription. Please remove all members or contact support.
        </Banner>
      )}
    </VStack>
  );
}
