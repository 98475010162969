import { QueryOptions, useQuery } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { useGetUser } from ':cloud/queries/UserQueries/useGetUser';
import { APIClientCOMSLegacy } from ':cloud/state/Api';
import { OrganizationInvite } from ':cloud/types/service_proto';
import {
  GetOrganizationInvitationResponseLEGACY,
  OrganizationInviteLEGACY,
} from ':cloud/utils/comsMigrationUtils';

/**
 * accepted=true, deletedAt=null => accepted invitation
 * accepted=false, deletedAt=null => pending active invitation
 * accepted=true, deletedAt=timestamp => shouldn't be possible
 * accepted=false, deletedAt=timestamp => rejected invitation or admin deleted invitation
 */

// Convert legacy data to new data structure for use with UI
function generateOrganizationInvitesResponseLEGACY(
  legacyData?: OrganizationInviteLEGACY[],
): OrganizationInvite[] {
  if (!legacyData) {
    return [];
  }
  return legacyData.map(
    (item): OrganizationInvite => ({
      id: item.inviteToken,
      organization: {
        id: item.organizationId,
        name: item.organizationName,
      },
      email: item.email,
      inviteToken: item.inviteToken,
      role: item.role,
      accepted: item.accepted,
      hasRetrieved: item.hasRetrieved,
      invitedByUser: {
        id: item.invitedByUserId,
        email: item.invitedByUserEmail,
        firstName: item.invitedByUserName,
        lastName: item.invitedByUserFamilyName,
      },
      createdAt: item.created,
      updatedAt: item.created,
    }),
  );
}

async function fetchOrganizationInvites(): Promise<GetOrganizationInvitationResponseLEGACY> {
  const path = `/organization-management/v1/customers/invites`;
  const { data } = await APIClientCOMSLegacy.get<GetOrganizationInvitationResponseLEGACY>(path);
  return data;
}

function generateOrganizationInvitesQuery(userId: string) {
  return {
    queryKey: [QueryKey.self, 'invites', userId],
    queryFn: async () => fetchOrganizationInvites(),
    enabled: !!userId,
    // returns a list of pending active invites only
    select: (data: GetOrganizationInvitationResponseLEGACY) =>
      data?.filter((item) => !item.accepted && item.deletedAt === null),
  };
}

export function useGetOrganizationInvites(): {
  data: OrganizationInvite[];
  isLoading: boolean;
} {
  const { user } = useGetUser();

  const query: QueryOptions = generateOrganizationInvitesQuery(user.userId);

  const { isLoading, data } = useQuery({ ...query });

  const dataToReturn = generateOrganizationInvitesResponseLEGACY(
    data as OrganizationInviteLEGACY[],
  );
  return {
    data: dataToReturn ?? [],
    isLoading,
  };
}
