import { CellSpacing } from '@cbhq/cds-common';
import { Cell, overflowClassName } from '@cbhq/cds-web/cells/Cell';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { palette } from '@cbhq/cds-web/tokens';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

import { ClipboardIcon } from '../sharedcomponents';

type CopyProjectIdSectionProps = {
  projectId: string;
  description?: string;
};
const spacing: CellSpacing = { spacing: 0, spacingHorizontal: 2 };

export function CopyProjectIdSection({ projectId, description }: CopyProjectIdSectionProps) {
  return (
    <VStack bordered borderRadius="rounded" spacing={4} gap={3}>
      <HStack justifyContent="space-between">
        <HStack alignItems="center" gap={2}>
          <Box
            dangerouslySetBackground={palette.foreground}
            height={24}
            width={24}
            borderRadius="roundedFull"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Icon name="chainLink" size="s" color="primaryForeground" />
          </Box>
          <VStack>
            <TextHeadline as="h1">Project ID</TextHeadline>
            <TextBody as="p" color="foregroundMuted">
              {description}
            </TextBody>
          </VStack>
        </HStack>
      </HStack>
      <HStack
        width="100%"
        gap={6}
        alignItems="center"
        background="backgroundAlternate"
        borderRadius="rounded"
      >
        <Cell
          priority="end"
          innerSpacing={spacing}
          outerSpacing={spacing}
          detail={
            <Box justifyContent="flex-end">
              <ClipboardIcon text={projectId} iconSize="s" />
            </Box>
          }
        >
          <Box borderRadius="roundedSmall" display="flex" spacing={2} flexGrow={1}>
            <TextBody as="p" color="foregroundMuted" overflow="wrap" className={overflowClassName}>
              {projectId}
            </TextBody>
          </Box>
        </Cell>
      </HStack>
    </VStack>
  );
}
