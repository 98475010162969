import { useQuery } from '@tanstack/react-query';

import { QueryKey } from ':cloud/queries/types';
import { APIClientBase } from ':cloud/state/Api';
import { GetProductDetailsResponse } from ':cloud/types/service_proto';
import { ProductDetails, ProductTypeCOMS, Project } from ':cloud/types/ts_types';

// Fetches product details for a given product scoped to a project in COMS.
async function fetchProductDetails(
  organizationId: string,
  projectId: string,
  productType: ProductTypeCOMS,
): Promise<GetProductDetailsResponse> {
  const path = `/organization-management/v1/organizations/${organizationId}/projects/${projectId}/products?productType=${productType}`;
  const { data } = await APIClientBase.get<GetProductDetailsResponse>(path);
  return data;
}

export function useGetProductDetails(
  project: Project,
  productType: ProductTypeCOMS,
): { isLoading: boolean; productDetails?: ProductDetails } {
  const { isLoading, data } = useQuery({
    queryKey: [QueryKey.productDetails, project.organizationId, project.id, productType],
    queryFn: async () => {
      return fetchProductDetails(project.organizationId, project.id, productType);
    },
    staleTime: Infinity,
    enabled: !!project && !!productType,
  });

  return {
    isLoading,
    productDetails: data?.productDetails,
  };
}
