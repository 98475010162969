import { ResponsiveGridProps } from '@cbhq/cds-web';

export const threeColumnGrid: ResponsiveGridProps = {
  phone: {
    columns: 1,
  },
  tablet: {
    columns: 2,
  },
  desktop: {
    columns: 3,
  },
};

export const twoColumnGrid: ResponsiveGridProps = {
  phone: {
    columns: 1,
  },
  tablet: {
    columns: 2,
  },
  desktop: {
    columns: 2,
  },
};
